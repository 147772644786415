<div class="ddd-cont">
    <div *ngIf="loading" class="loader-wrapper circular-spinners text-center">
        <!-- <i class="pi pi-spin pi-spinner"></i> -->
        <p>Loading data...</p>
    </div>

    <div *ngIf="!loading && errorMessage" class="error-message">
        <p>{{ errorMessage }}</p>
    </div>

    <div *ngIf="!loading && !errorMessage && dataLoaded">
        <div *ngIf="configure; else dashboard">
            <h2>Overall Description</h2>
            <textarea rows="4" cols="50" [placeholder]="'Enter the Overall Description'"
                [(ngModel)]="overallDescription"></textarea>

            <p-tabView (onChange)="onTabChange($event)">
                <p-tabPanel *ngFor="let table of renderData" [header]="table.tableName">
                    <p-table [value]="selectedColumns">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Column</th>
                                <th>Data Type</th>
                                <th>Description</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-column>
                            <tr>
                                <td>{{ column.column }}</td>
                                <td>{{ column.dataType }}</td>
                                <td>
                                    <input class="form-control" [placeholder]="'Enter Description'" type="text" pInputText
                                        [(ngModel)]="column.description" placeholder="Description">
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </p-tabPanel>
            </p-tabView>

            <h2 class="mt-2">Prompt</h2>
            <textarea rows="4" cols="50" [placeholder]="'Enter the Prompt'" [(ngModel)]="prompt"></textarea>

            <div class="submit-button">
                <button pButton type="button" class="btn btn-primary" (click)="submit()" 
                        [disabled]="isSubmitting">
                    <i class="pi pi-spin pi-spinner" *ngIf="isSubmitting"></i>
                    {{ isSubmitting ? 'Submitting...' : 'Confirm' }}
                </button>
            </div>
        </div>

        <ng-template #dashboard>
            <div class="dashboard-container">
                <ng-container *ngIf="result['response']">
                    <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
                        <div class="col" *ngFor="let r of result['response']">
                            <div *ngIf="r" class="card h-100 shadow-sm">
                                <div class="card-body">
                                    <h5 class="card-title text-center mb-4">{{r['plotTitle']}}</h5>
                                    <div class="chart-container" style="position: relative; height: 300px;">
                                        <p-chart [type]="r['type']" [data]="r['data']" [options]="r['options']"></p-chart>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-template>
    </div>

    <div *ngIf="!loading && !errorMessage && !dataLoaded" class="no-data-message">
        <p>No data available. Please try again later.</p>
    </div>
</div>
