import { Component, Input } from '@angular/core';
import { FinancialAnalytixService } from 'src/app/_services/financial-analytix.service';
import { SharedService } from 'src/app/_services/shared.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html'
})
export class ProductComponent {

  @Input() jobId;
  productDetails: Object = {}
  productChartDetails = []
  brand = '';
  product = '';
  brandOptions = [];
  productOptions = [];
  @Input() jobDetails;
  eventsOptions = [
    {name: 'Holidays', value: 'holidays'},
    // {name: 'Temperature', value: 'temperature'}
  ]
  eventsValue;
  timelineOptions = [
    {name: 'Monthly', value: 'monthly'}
  ]
  timeline;
  number;
  salesForecastingPlot = {} ;
  displayInsights: boolean = false;
  insights = []
  insightsTitle = '';

  loading: boolean = false;

  constructor(
    private financialService: FinancialAnalytixService,
    private sharedService: SharedService
  ) {

  }
  ngOnInit() {
    this.fetchDashboard("", "");
  }

  fetchDashboard(brand, product) {
    this.financialService.financialDashboard({
      job_id: this.jobId,
      brand: brand,
      product: product,
      sublevel: '',
      year: '',
      month: '',
      category4: ''
    }).subscribe({
      next: res => {
        this.productDetails = res;
        if (this.productDetails && this.productDetails['filter-values'] && this.productDetails['filter-values']['Brand'] && this.brandOptions.length == 0) {
          this.brandOptions.push({
            name: "All", value: ""
          })
          this.productDetails['filter-values']['Brand'].forEach(e => {
            this.brandOptions.push({
              name: e, value: e
            });
          })
        }
        if (this.productDetails && this.productDetails['filter-values'] && this.productDetails['filter-values']['Product'] && this.productOptions.length == 0) {
          this.productOptions.push({
            name: "All", value: ""
          })
          this.productDetails['filter-values']['Product'].forEach(e => {
            this.productOptions.push({
              name: e, value: e
            });
          })
        }
        console.log('resu', res)
        this.chartMapping();
      }, error: err => {

      }
    })
  }

  changeInFilter(event, type) {
    if (type == 'brand') {
      this.product = ''
      this.brand = event.value
      this.productOptions = [];
    } else {
      this.product = event.value
    }
    this.fetchDashboard(this.brand, this.product)
  }

  chartMapping() {
    this.productChartDetails = []
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    let arr = [
      {
        name: 'Sales, Inventory and Sales Ratio', plot: 'plot1', type: 'line', options: {
          maintainAspectRatio: false,
          aspectRatio: 0.6,
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder
              }
            },
            y: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder
              }
            }
          }
        }
      },
      {
        name: 'Sales Forecast', plot: 'plot2', type: 'line', options: {
          maintainAspectRatio: false,
          aspectRatio: 0.6,
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            },
            y: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            }
          }
        }
      },
      {
        name: 'Purchase Trend - Year, Quarter, Month', plot: 'plot3', type: 'line', options: {
          maintainAspectRatio: false,
          aspectRatio: 0.6,
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            },
            y: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            }
          }
        }
      },
      {
        name: 'Days Inventory Outstanding', plot: 'plot4', type: 'bar', options: {
          maintainAspectRatio: false,
          aspectRatio: 0.8,
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: textColorSecondary,
                font: {
                  weight: 500
                }
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            },
            y: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            }

          }
        }
      },
      {
        name: 'Sales Trend - Year, Quarter, Month', plot: 'plot5', type: 'line', options: {
          maintainAspectRatio: false,
          aspectRatio: 0.6,
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            },
            y: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            }
          }
        }
      },
    ]
    for (let i = 0; i < arr.length; i++) {
      let obj = {
        title: arr[i]['name'],
        datasets: this.productDetails['charts'][arr[i]['plot']],
        options: arr[i]['options'],
        type: arr[i]['type']
      }
      this.productChartDetails.push(obj)
    }
  }



  submit() {
    this.financialService.salesForecasting({job_id: this.jobId, selected_product: this.product, periods: this.number}).subscribe({next: res => {
      console.log('res', res)
      if (res['forecast_plot'] && res['forecast_plot']['datasets']) {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
        this.salesForecastingPlot['datasets'] = res['forecast_plot'];
        this.salesForecastingPlot['options'] = {
          maintainAspectRatio: false,
          aspectRatio: 0.6,
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            },
            y: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            }
          }
        }
      }
    }, error: err => {}})
  }

  invokeChatSliding(type) {
    this.sharedService.sendClickEvent({jobId: this.jobId, type: 'product'}, type);
  }

  getInsights(chart) {
    this.loading= true;
    let body = {}
    this.displayInsights = true;
    this.insightsTitle = chart['title']
    body['plot_data'] = chart['datasets']

    body['filters'] = {   brand: this.brand,
      product: this.product, }

    this.financialService.fetchInsights(body).subscribe({
        next: res => {
          this.loading= false;
            console.log('insights', res)
            this.insights = res['insight']
        },
        error: err => {
          this.loading= false;
         }
    })
}
}
