import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CustomValidators } from 'src/app/_helpers/custom-validators';
import { Toast } from 'src/app/_helpers/toast';
import { RoleService } from 'src/app/_services/role.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-role-management',
  templateUrl: './role-management.component.html'
})
export class RoleManagementComponent implements OnInit, OnChanges {

  @Input() type: string = '';
  @Input() id: string = '';
  @Input() refresh: boolean = false;
  @Input() projectdetail: boolean = false;
  @Input() settings: boolean = false;
  members: any[] = [];
  displayManageMember: boolean = false;
  selectedMember: Object = {};
  @Output() addTeamClick: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private roleService: RoleService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['refresh']) {
      if (this.refresh) {
        this.fetchMembers();
        this.refresh = false;
      }
    }
  }

  ngOnInit(): void {
    this.fetchMembers();
  }

  getInitials(name: string): string {
    const index = name.indexOf(' ');
    if (index === -1) {
      return name.charAt(0).toUpperCase();
    }
    return name.charAt(0).toUpperCase() + name.charAt(index + 1).toUpperCase();
  }

  fetchMembers() {
    if (this.type === 'Project') {
      if (this.id != '') {
        this.roleService.getProjectMembers(this.id).subscribe({
          next: res => {
            this.members = res;
          },
          error: err => {
            console.error(err)
          }
        })
      }
    } else if (this.type === 'Org') {
      if (this.id != '') {
        this.roleService.getOrgMembers(this.id).subscribe({
          next: res => {
            console.log(res, "this.members")
            this.members = res;
          },
          error: err => {
            console.error(err)
          }
        })
      }
    }
  }

  readable(str) {
    return CustomValidators.unCamelCase(str)
  }

  updateMember(res) {
    if (res.id) {
      this.selectedMember = res;
      this.displayManageMember = true;
    }
  }

  updateMemberDetails(result) {
    if (this.type === 'Project') {
      if (result.userId) {
        this.roleService.updateProjectMember(this.selectedMember['id'], result).subscribe({
          next: res => {
            this.fetchMembers();
            this.displayManageMember = false;
          },
          error: err => {
            Toast.fire({
              icon: 'error',
              html: err.error.error.message
            });
          }
        })
      }
    } else if (this.type === 'Org') {
      if (result.userId) {
        this.roleService.updateOrgMember(this.selectedMember['id'], result).subscribe({
          next: res => {
            this.fetchMembers();
            this.displayManageMember = false;
            Toast.fire({
              icon: 'success',


              title: 'Updated',
              html: 'You are successfully Updated Org Member'
            })
          },
          error: err => {
            Toast.fire({
              icon: 'error',
              html: err.error.error.message
            });
          }
        })
      }
    }
  }

  removeMember(res) {
    if (res.id) {
      Swal.fire({
        title: `Remove ${this.type === 'Project' ? 'Team' : 'Org'} Member`,
        text: `Are you sure you want to remove ${this.type === 'Project' ? 'Team' : 'Org'} member from this ${this.type === 'Project' ? 'Project' : 'Organization'}?`,
        icon: 'warning',
        confirmButtonColor: '#FF6501',
        confirmButtonText: 'Yes. Remove it!',
        showCancelButton: true,
        backdrop: true
      }).then((result) => {
        if (result.isConfirmed) {
          if (this.type === 'Project') {
            this.roleService.removeProjectMember(res.id).subscribe({
              next: res => {
                Toast.fire({
                  icon: 'success',
                  html: `Removed Successfully`
                });
                this.fetchMembers();
              },
              error: err => {
                Toast.fire({
                  icon: 'error',
                  html: err.error.error.message
                });
              }
            })
          }
          if (this.type === 'Org') {
            this.roleService.removeOrgMember(res.id).subscribe({
              next: res => {
                Toast.fire({
                  icon: 'success',
                  html: `Removed Successfully`
                });
                this.fetchMembers();
              },
              error: err => {
                Toast.fire({
                  icon: 'error',
                  html: err.error.error.message
                });
              }
            })
          }
        }
      })
    }
  }

  close() {
    this.displayManageMember = false;
  }

  addTeamClicked(): void {
    this.addTeamClick.emit();
  }
}
