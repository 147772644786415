import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  public languages: string[] = ['en'];
  private dataUrl = 'assets/i18n/en.json';
  constructor(
    public translate: TranslateService,
    private cookieService: CookieService,
    private http: HttpClient
  ) {
    let browserLang;
    this.translate.addLangs(this.languages);
    if (this.cookieService.check('lang')) {
      browserLang = this.cookieService.get('lang');
    }
    else {
      browserLang = translate.getBrowserLang();
    }
    translate.use(browserLang = 'en');
  }

  public setLanguage(lang) {
    this.translate.use(lang);
    this.cookieService.set('lang', lang);
  }
  getData<T>(): Observable<T> {
    return this.http.get<T>(this.dataUrl);
  }

}