<div class="form-group" [formGroup]="form">
    <label>{{ field['label'] }}</label>
    <div class="d-flex">
        <input id="float-input" class="form-control mr-1" onkeydown="return event.keyCode !== 69" type="number"
            [formControlName]="field['lowerName']" [placeholder]="'inputforms.placeholder.lowerlimit' | translate"
            pInputText>
        <input id="float-input" class="form-control" onkeydown="return event.keyCode !== 69" type="number"
            [formControlName]="field['upperName']" [placeholder]="'inputforms.placeholder.upperlimit' | translate"
            pInputText>
    </div>
</div>