import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  private NodeAPIUrl = environment.apiAuthUrl;
  private subject = new Subject<any>();

  constructor(
    private http: HttpClient
  ) { }

  sendClickEvent(data, type) {
    this.subject.next({
      data: data,
      type: type
    });
  }

  getClickEvent(): Observable<any> {
    return this.subject.asObservable();
  }
}
