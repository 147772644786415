import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Toast } from "src/app/_helpers/toast";
import { AuthService } from "src/app/_services/auth.service";
import { SyntheticDataService } from "src/app/_services/synthetic-data.service";
import { trigger, transition, animate, style } from "@angular/animations";
import Swal from "sweetalert2";
import { CustomStrategyService } from "src/app/_services/custom-strategy.service";
import { PatternService } from "src/app/_services/pattern.service";



@Component({
  selector: "app-assign-semantics",
  templateUrl: "./assign-semantics.component.html",
  animations: [
    trigger("slideInFromLeft", [
      transition(":enter", [
        style({ transform: "translateX(-70%)" }),
        animate("500ms ease-out", style({ transform: "translateX(0)" })),
      ]),
      transition(":leave", [
        animate("500ms ease-out", style({ transform: "translateX(-100%)" })),
      ]),
    ]),
  ],
})
export class AssignSemanticsComponent implements OnChanges, OnDestroy {
  @Input() jobDetails: Object = {};
  tablesInfo: Object[] = [];
  selectedTable: string = "";
  showSelectedColumns: string[] = [];
  displayedColumnIndex: number[] = [];
  columnsData: Object[] = [];
  showSemantics: boolean = false;
  selectedColumnDetails: any = {};
  @Output() selectedTableAndColumnDetails = new EventEmitter<Object>();
  @Output() columnsDataDetails = new EventEmitter<any>();
  @Input() projectId: string = "";
  topRecords: any[] = [];
  showRecords: boolean = false;
  @Output() patternEmitter = new EventEmitter<Object>();
  showPIIData: Object[] = [];
  showAnalysisDialog: boolean = false;
  tablesPIIData: {}[] = [];
  selTable: {} = null;
  schema: string = "";
  showRules: boolean = false;
  tableLength: number = 0;
  @Output() ruleEmitter = new EventEmitter();
  @Output() tableEmitter = new EventEmitter();
  @Input() fileJob: Object = {};
  @Input() fragment: string = ''
  fileColumns = [];
  currentTabIndex: number = -1;
  isChecked: boolean = false;
  @Output() piiDataEmitter = new EventEmitter();
  @Input() routeType: string = "";
  @Output() piiReportEmitter = new EventEmitter();
  piiReportData: Object[] = [];
  spinner: boolean = false;
  showAnomaly: boolean = false;
  @Output() anomalyEmitter = new EventEmitter();
  @Output() autoMLEmitter = new EventEmitter();
  anomalyData = [];
  showAutoML: boolean = false;
  autoMLData = {};
  @Input() editJobProfile = {}
  @Input() isEdit = false;
  isBrewNowFills: boolean = false;

  constructor(
    private syntheticDataService: SyntheticDataService,
    private authService: AuthService,
    private patternService: PatternService,
    private customStrategyService: CustomStrategyService
  ) { }


  ngOnDestroy(): void {
    this.routeType = "";
    this.showSelectedColumns = []
  }


  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes["jobDetails"]) {
      if (
        (this.fileJob && Object.keys(this.fileJob).length === 0) &&
        this.jobDetails["selectSchema"] !== null &&
        (this.jobDetails["selectSource"] && Object.keys(this.jobDetails["selectSource"]).length > 0) &&
        this.jobDetails["sourceType"] === "database"
      ) {
        this.tablesInfo = [];
        this.fileColumns = [];
        this.backToTables();
        this.showSemantics = false;
        this.showAnomaly = false;
        this.showAutoML = false;
        this.columnsData = [];
        this.showSelectedColumns = [];
        this.columnsDataDetails.emit(this.columnsData);
        await this.verifySchema();
        await this.fetchTables();
        if (this.routeType == "pii") {
          this.fetchPIIReport();
        }
      }
    }

    if (changes["fileJob"]) {
      if (
        this.fileJob &&
        this.fileJob["job_id"] &&
        this.jobDetails["sourceType"] !== "database"
      ) {
        this.tablesInfo = [];
        this.backToTables();
        this.fileColumns = [];
        this.showSemantics = false;
        this.showAnomaly = false;
        this.showAutoML = false;
        this.columnsData = [];
        this.showSelectedColumns = [];
        this.columnsDataDetails.emit(this.columnsData);
        this.fetchFileColumn();
        if (this.routeType == "pii") {
          this.fetchPIIReport();
        } else if (this.routeType == 'rules' && this.jobDetails && this.jobDetails['sourceType'] != 'database') {
          this.showRules = true;
          this.showSemantics = false;
          this.showAnomaly = false;
          this.showAutoML = false;
        }
      }
    }

    if (changes["routeType"]) {
      this.selTable = null;
      this.showSemantics = false;
      this.showAnomaly = false;
      this.showAutoML = false;
      this.showAnalysisDialog = false;
      this.selectedColumnDetails = {};
      this.showRules = false;
      if (this.columnsData.length == 0) {
        this.selectedTable = "";
      }
      if (this.routeType == "pii") {
        this.fetchPIIReport();
      } else if (
        this.routeType == "rules" &&
        this.jobDetails &&
        this.jobDetails["sourceType"] != "database"
      ) {
        this.showRules = true;
        this.showSemantics = false;
        this.showAnomaly = false;
        this.showAutoML = false;
      } else if (this.fileJob && this.fileJob['job_id']) {
        this.fetchPII(null);
      }
    }

    // if (this.isEdit && Object.keys(this.editJobProfile).length > 0) {
    //   this.patches();
    // }
  }

  fetchFileColumn() {
    if (this.fileJob && this.fileJob["job_id"]) {
      this.syntheticDataService
        .getColumns({ job_id: this.fileJob["job_id"] })
        .subscribe({
          next: (res) => {
            this.fileColumns = res;
            this.fetchPII(null);
            if (this.isEdit) {
              this.patches();
            }
          },
          error: (err) => { },
        });
    }
  }

  navigate(direction: string, currentIndex: number): void {
    const newIndex = direction === "down" ? currentIndex + 1 : currentIndex - 1;
    const totalItems = this.tablesInfo.length;
    const wrappedIndex = (newIndex + totalItems) % totalItems;
    const element = document.querySelector(
      `li.list-table[tabindex="${wrappedIndex}"]`
    ) as HTMLElement;
    if (element) {
      element.focus();
    }
  }

  async patches() {
    if (Object.keys(this.editJobProfile).length > 0 && this.editJobProfile && this.editJobProfile['file_extension']) {
      await this.constructColumnsDataForEdit();
      if (Array.isArray(this.editJobProfile['column_data']) && this.editJobProfile['column_data'].length) {
        for (let i = 0; i < this.editJobProfile['column_data'].length; i++) {
          this.showSelectedColumns.push(this.editJobProfile['column_data'][i]['source_column'])
        }
        this.selectedColumnDetails = {
          table: { tableName: 'file', columns: this.fileColumns },
          selectedColumn: this.editJobProfile['column_data'][0]['source_column'],
        }
        this.selectedTable = 'file'
        this.displaySemanticsAndConstraints(this.selectedColumnDetails['table'], this.selectedColumnDetails['selectedColumn'])
        this.showSemantics = true;
      }
    } else {
      if (Object.keys(this.editJobProfile).length > 0 && this.tablesInfo.length > 0) {
        await this.constructColumnsDataForEdit();
        this.selTable = this.tablesInfo.find(obj => obj['tableName'] === this.editJobProfile['source_table']);
        if (Array.isArray(this.editJobProfile['column_data']) && this.editJobProfile['column_data'].length) {
          for (let i = 0; i < this.editJobProfile['column_data'].length; i++) {
            this.showSelectedColumns.push(this.editJobProfile['column_data'][i]['source_column'])
          }
          for (let j = 0; j < this.tablesInfo.length; j++) {
            if (this.editJobProfile['source_table'] == this.tablesInfo[j]['tableName']) {
              this.selectedColumnDetails = {
                table: this.tablesInfo[j],
                selectedColumn: this.editJobProfile['column_data'][0]['source_column'],
              }
              break;
            }
          }
          this.selectedTable = this.selectedColumnDetails['table']['tableName']
          this.fetchPII(this.selectedColumnDetails['table'])
          this.displaySemanticsAndConstraints(this.selectedColumnDetails['table'], this.selectedColumnDetails['selectedColumn'])
          this.showSemantics = true;
        }
      }
    }
  }

  navigateToDiv(divId: string): void {
    const element = document.getElementById(divId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      setTimeout(() => {
        element.setAttribute("tabIndex", "0");
        element.focus();
      }, 500);
    }
  }

  async verifySchema() {
    if (this.schema == "") {
      this.schema = this.jobDetails["selectSchema"];
    } else if (this.schema !== this.jobDetails["selectSchema"]) {
      this.tablesInfo = [];
      this.schema = this.jobDetails["selectSchema"];
    }
  }

  onDragStart(event: DragEvent, item: string) {
    event.dataTransfer?.setData("text/plain", item);
  }

  async fetchTables() {
    if (this.jobDetails["selectSource"] && this.jobDetails["jobName"]) {
      this.tablesInfo = [];
      this.syntheticDataService.getTables({
        connection_id: this.jobDetails["selectSource"]["id"],
        schema_name: this.jobDetails["selectSchema"],
      }).subscribe({
        next: (res) => {
          this.tableLength = res.length;
          for (let i = 0; i < res.length; i++) {
            if (
              this.jobDetails &&
              this.jobDetails["selectSource"] &&
              this.jobDetails["selectSource"]["id"] &&
              this.jobDetails["selectSchema"]
            ) {
              this.syntheticDataService.getColumns({
                connection_id: this.jobDetails["selectSource"]["id"],
                schema_name: this.jobDetails["selectSchema"],
                table_name: res[i],
              }).subscribe({
                next: (colres) => {
                  this.tablesInfo.push({
                    tableName: res[i],
                    columns: colres,
                  });
                  if (i == res.length - 1) {
                    this.fetchViews();
                  }
                },
                error: (err) => {
                  Toast.fire({
                    icon: "error",
                    html: err.error.error.message,
                  });
                },
              });
            }
          }
        },
        error: (err) => {
          Toast.fire({
            icon: "error",
            html: err.error.error.message,
          });
        },
      });
    }
  }

  fetchViews() {
    if (
      this.jobDetails && this.jobDetails["selectSource"] &&
      this.jobDetails["selectSource"]["driver_name"] !== "salesforce"
    ) {
      this.syntheticDataService.getViews({
        connection_id: this.jobDetails["selectSource"]["id"],
        schema_name: this.jobDetails["selectSchema"],
      })
        .subscribe({
          next: (res) => {
            for (let i = 0; i < res.length; i++) {
              if (
                this.jobDetails &&
                this.jobDetails["selectSource"] &&
                this.jobDetails["selectSource"]["id"] &&
                this.jobDetails["selectSchema"]
              ) {
                this.syntheticDataService
                  .getColumns({
                    connection_id: this.jobDetails["selectSource"]["id"],
                    schema_name: this.jobDetails["selectSchema"],
                    table_name: res[i],
                  })
                  .subscribe({
                    next: (colres) => {
                      this.tablesInfo.push({
                        tableName: res[i],
                        columns: colres,
                      });
                    },
                    error: (err) => {
                      Toast.fire({
                        icon: "error",
                        html: err.error.error.message,
                      });
                    },
                  });
              }
            }
            this.patches();
          },
          error: (err) => { },
        });
    }
  }

  async constructColumnsDataForEdit() {
    if (this.editJobProfile && this.editJobProfile['column_data'] && Array.isArray(this.editJobProfile['column_data'])) {
      for (let i = 0; i < this.editJobProfile['column_data'].length; i++) {
        if (this.editJobProfile['column_data'][i]['strategy_id']) {
          let stratRes = await this.customStrategyService.getStrategiesAsync({
            isbias: true,
            locale: this.editJobProfile['locale'],
            project_id: this.editJobProfile['project_id'],
            org_id: this.editJobProfile['org_id'],
            id: this.editJobProfile['column_data'][i]['strategy_id']
          })
          if (stratRes) {
            this.columnsData.push({
              columnlet_type: this.editJobProfile['column_data'][i]['strategy_id'] ? 'NA' : '',
              delimiter_value: null,
              destination_column: this.editJobProfile['column_data'][i]['destination_column'],
              source_column: this.editJobProfile['column_data'][i]['source_column'],
              columnlets_data: [{
                columnlet_name: null,
                dependent_fields: [],
                position_range_end: null,
                position_range_start: null,
                semantic_group: stratRes[0]['semantic_group'],
                sk: null,
                strategy_id: this.editJobProfile['column_data'][i]['strategy_id'],
                variable_position: null
              }]
            })
          }
        } else if (this.editJobProfile['column_data'][i]['pattern_id']) {
          let patRes = await this.patternService.getPatternsAsync({
            org_id: this.editJobProfile['org_id'],
            project_id: this.editJobProfile['project_id'],
            id: this.editJobProfile['column_data'][i]['pattern_id']
          })
          let columnlet_data = []
          let c = JSON.parse(patRes[0]['columnlets_pattern'])
          if (Array.isArray(c) && c.length) {
            for (let k = 0; k < c.length; k++) {
              columnlet_data.push({
                columnlet_name: c[k]['columnlet_name'],
                dependent_fields: c[k]['dependent_fields'],
                position_range_end: c[k]['position_range_end'],
                position_range_start: c[k]['position_range_start'],
                semantic_group: c[k]['semantic_group'],
                sk: c[k]['sk'],
                strategy_id: c[k]['strategy_id'],
                variable_position: c[k]['variable_position']
              })
            }
          }
          this.columnsData.push({
            columnlet_type: patRes[0]['tokenization_type'],
            delimiter_value: patRes[0]['delimiter_value'],
            destination_column: this.editJobProfile['column_data'][i]['destination_column'],
            source_column: this.editJobProfile['column_data'][i]['source_column'],
            columnlets_data: columnlet_data
          })
        }
      }
    }
  }

  toggle() {
    if (!this.showAutoML) {
      if (!this.showAnomaly) {
        if (this.columnsData.length === 0) {
          this.showSelectedColumns = [];
        } else {
          this.showSelectedColumns = this.showSelectedColumns.filter(column => {
            return this.columnsData.some(record => record["source_column"] === column);
          });
        }
      } else {
        // Logic to be built here when the column is cleared and should not be highlighted for the anomaly flow
        let changes = [];
        let target = [];
        let columns = []
        let prevArr = [...this.showSelectedColumns]
        if (this.anomalyData['anomalies'] && this.anomalyData['anomalies']['changes']) {
          changes = [];
          ['numeric', 'categorical'].forEach(changeType => {
            if (this.anomalyData['anomalies']['changes'][changeType]) {
              Object.keys(this.anomalyData['anomalies']['changes'][changeType]).forEach((e, index) => {
                if (changeType == 'numeric' && index == 0) {
                  changes = prevArr.filter(column => column === e);
                } else {
                  changes.push(...prevArr.filter(column => column === e));
                }
              });
            }
          });
        }
        prevArr = [...this.showSelectedColumns]
        if (Array.isArray(this.anomalyData['columnData']) && this.anomalyData['columnData'].length) {
          for (let i = 0; i < this.anomalyData['columnData'].length; i++) {
            if (this.anomalyData['columnData'][i]['source_column']) {
              const sourceColumnValue = this.anomalyData['columnData'][i]['source_column'];
              columns.push(...prevArr.filter(column => column === sourceColumnValue)) ;
            }
          }
        }
        if (this.anomalyData['anomalies'] && this.anomalyData['anomalies']['target']) {
          target = []
          target.push(this.anomalyData['anomalies']['target'])
        }
        this.showSelectedColumns = [...columns, ...target, ...changes]
      }
    } else {
      // toggle here for AutoML Flow
      let col = []
      let target = []
      if (this.autoMLData && this.autoMLData['meta']) {
        col = this.showSelectedColumns.filter(key => this.autoMLData['meta'].hasOwnProperty(key));
      }
      if (this.autoMLData['target']) {
        target.push(this.autoMLData['target'])
      }
      this.showSelectedColumns = [...col, ...target]
    }
  }

  getTableIndex(selectedTable: string) {
    return this.tablesInfo.findIndex(table => table['tableName'] === selectedTable);
  }

  displaySemanticsAndConstraints(table, column) {
    this.isChecked = false;
    if (this.selectedTable === "") {
      this.selectedTable = table["tableName"];
      this.toggle();
      this.highlightedColumn(column);
      this.selectedColumnDetails = {
        table: table,
        selectedColumn: column,
      };
      this.selectedTableAndColumnDetails.emit(this.selectedColumnDetails);
      this.showRules = false;
      if (this.routeType == "synthesis") {
        this.showSemantics = true;
      } else if (this.routeType == 'anomaly') {
        this.showAnomaly = true
      } else if (this.routeType == 'automl') {
        this.showAutoML = true;
      }
    } else if (this.selectedTable !== table["tableName"]) {
      if (this.columnsData.length > 0) {
        Swal.fire({
          icon: "info",
          title: "Info",
          html: "The application currently supports only one table. Would you like to discard the previous changes",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        }).then((result) => {
          if (result.isConfirmed) {
            this.columnsData = [];
            this.selectedTable = "";
          } else if (result.isDismissed) {
            this.backToTables();
            let tIndex = this.getTableIndex(this.selectedTable);
            this.toggleColumns(this.selectedTable, tIndex);
          }
        });
      } else {
        this.selectedTable = "";
      }
    } else {
      this.toggle();
      this.highlightedColumn(column);
      this.selectedColumnDetails = {
        table: table,
        selectedColumn: column,
      };
      this.selectedTableAndColumnDetails.emit(this.selectedColumnDetails);
      this.showRules = false;
      if (this.routeType == "synthesis") {
        this.showSemantics = true;
      } else if (this.routeType == 'anomaly') {
        this.showAnomaly = true;
      } else if (this.routeType == 'automl') {
        this.showAutoML = true;
      }
    }
  }

  backToTables() {
    this.selTable = null;
    this.showSemantics = false;
    this.showAnomaly = false;
    this.showAutoML = false;
    this.selectedColumnDetails = {};
    this.showRules = false;
    if (this.columnsData.length == 0) {
      this.selectedTable = "";
    }
    if (this.isBrewNowFills) {
      this.columnsData = []
      this.columnsDataDetails.emit(this.columnsData);
    }
  }

  toggleColumns(table, index) {
    // The variable "displayColumnIndex" stores the indexes of the open tables.
    // if (this.displayedColumnIndex.includes(index)) {
    //   this.displayedColumnIndex.splice(this.displayedColumnIndex.indexOf(index), 1)
    // } else {
    //   this.displayedColumnIndex.push(index)
    // }

    // new Logic
    this.selTable = this.tablesInfo[index]
    this.tableEmitter.emit(this.selTable)
    this.fetchPIIDecider(table)
  }

  highlightedColumn(column) {
    if (this.showSelectedColumns.includes(column)) {
      // TODO document why this block is empty
    } else {
      this.showSelectedColumns.push(column);
    }
  }

  columnsDataEmitter(event) {
    if (this.columnsData.length > 0) {
      let index = this.columnsData.findIndex(
        (x) => x["source_column"] === event[0]["source_column"]
      );
      if (index === -1) {
        this.columnsData.push(event[0]);
      } else {
        this.columnsData[index] = event[0];
      }
    } else {
      this.columnsData = event;
    }
    this.isBrewNowFills = false;
    this.columnsDataDetails.emit(this.columnsData);
  }

  clearColumnsHandler(obj) {
    for (let i = 0; i < this.columnsData.length; i++) {
      if (
        this.selectedColumnDetails["selectedColumn"] ==
        this.columnsData[i]["source_column"]
      ) {
        if (this.columnsData[i]["columnlets_data"].length == 1) {
          this.columnsData.splice(i, 1);
        } else {
          this.columnsData[i]["columnlets_data"].splice(obj["index"], 1);
        }
      }
    }
    this.isBrewNowFills = false
    this.columnsDataDetails.emit(this.columnsData);
  }

  getTop10Records() {
    if (this.fileJob && this.fileJob["job_id"]) {
      this.syntheticDataService
        .getTopRecordsFile({
          column: this.selectedColumnDetails["selectedColumn"],
          job_id: this.fileJob["job_id"],
        })
        .subscribe({
          next: (res) => {
            this.topRecords = res["top_records"];
            this.showRecords = true;
          },
        });
    } else {
      this.syntheticDataService
        .getTopRecords({
          connection_id: this.jobDetails["selectSource"]["id"],
          schema_name: this.jobDetails["selectSchema"],
          table_name: this.selectedColumnDetails["table"]["tableName"],
          column_name: this.selectedColumnDetails["selectedColumn"],
        })
        .subscribe({
          next: (res) => {
            this.topRecords = Object.values(
              res[this.selectedColumnDetails["selectedColumn"]]
            );
            this.showRecords = true;
          },
          error: (err) => {
            Toast.fire({
              icon: "error",
              html: err.error.text,
            });
          },
        });
    }
  }

  patternEvent(event) {
    this.patternEmitter.emit(event);
  }

  handleCheckedStatusChange(value: boolean) {
    this.isChecked = value;
  }

  fetchPIIDecider(table) {
    if (this.tablesPIIData.length > 0) {
      let index = this.tablesPIIData.findIndex(
        (r) => r["table"] === table["tableName"]
      );
      if (index != -1) {
        this.showPIIData = this.tablesPIIData[index]["pii"];
      } else {
        this.fetchPII(table);
      }
    } else {
      this.fetchPII(table);
    }
  }

  fetchPII(table) {
    if (this.routeType != "pii" && this.routeType != "automl" && this.routeType != "rules") {
      this.showAnalysisDialog = true;
      const currentUser = this.authService.currentUserValue;
      let params = {};
      this.showPIIData = []
      if (this.fileJob["job_id"]) {
        params = {
          org_id: currentUser["orgId"],
          job_id: this.fileJob["job_id"],
          locale: this.jobDetails["locale"],
        };
      } else {
        params = {
          org_id: currentUser["orgId"],
          connection_id: this.jobDetails["selectSource"]["id"],
          source_schema: this.jobDetails["selectSchema"],
          source_table: table["tableName"],
          locale: this.jobDetails["locale"],
        };
      }
      this.syntheticDataService.getPIIInfo(params).subscribe({
        next: (res) => {
          this.showAnalysisDialog = false;
          if (Array.isArray(res)) {
            this.showPIIData = res;
            if (this.fragment == 'synthesis') {
              this.brewNowHandler();
            }
            this.piiDataEmitter.emit(this.showPIIData);
            // if (Array.isArray(this.fileColumns) && this.fileColumns.length) {
            //   this.showSelectedColumns = [...this.fileColumns]
            // }
            this.tablesPIIData.push({
              table: table ? table["tableName"] : "file",
              pii: res,
            });
          } else {
            Toast.fire({
              icon: "error",
              title: "Failed to Fetch PII Data",
              html: ``,
            });
          }
        },
        error: (err) => {
          Toast.fire({
            icon: "error",
            html: err.message,
          });
        },
      });
    }
  }

  showPIIIcon(col) {
    if (Array.isArray(this.showPIIData)) {
      let index = this.showPIIData.findIndex(
        (r) => r["column_name"]?.toLowerCase() == col?.toLowerCase()
      );
      if (index !== -1) {
        if (this.showPIIData[index]["PII"]) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  }

  ruleSlide() {
    this.showSelectedColumns = [];
    this.showSemantics = false;
    this.showAnomaly = false;
    this.showAutoML = false;
    if (this.routeType == "rules") {
      this.showRules = true;
    }
  }

  ruleEvent(event) {
    this.ruleEmitter.emit(event);
  }

  fetchPIIReport() {
    setTimeout(() => {
      this.showAnalysisDialog = true;
      const currentUser = this.authService.currentUserValue;
      let params = {}
      if (this.fileJob && this.fileJob['job_id']) {
        params = { org_id: currentUser['orgId'], job_id: this.fileJob['job_id'], locale: this.jobDetails['locale'] }
      } else if (this.jobDetails['selectSource'] && this.jobDetails['selectSource']['id'] && this.jobDetails['locale']) {
        if (this.jobDetails['selectSchema'] == "-") {
          params = { org_id: currentUser['orgId'], connection_id: this.jobDetails['selectSource']['id'], locale: this.jobDetails['locale'] }
        } else {
          params = { org_id: currentUser['orgId'], connection_id: this.jobDetails['selectSource']['id'], source_schema: this.jobDetails['selectSchema'], locale: this.jobDetails['locale'] }
        }
      }
      if (Object.keys(params).length) {
        this.syntheticDataService.getPIIReport(params).subscribe({
          next: res => {
            this.showAnalysisDialog = false;
            this.piiReportData = res;
            this.piiReportEmitter.emit(res)
            if (res['message'] && typeof res['message'] == 'string') {
              Toast.fire({
                icon: 'error',
                html: res.message
              });
            }
          }, error: err => {
  
          }
        })
      }
    }, 2000)

  }

  anomalyEmit(event) {
    this.anomalyData = event
    this.anomalyEmitter.emit(event)
  }

  autoMLEmit(event) {
    this.autoMLData = event;
    this.autoMLEmitter.emit(event)
  }

  async brewNowHandler() {
    const currentUser = this.authService.currentUserValue;
    let strategies = await this.customStrategyService.getStrategiesAsync({ org_id: currentUser.orgId, is_based_on_org: true })
    if (Array.isArray(this.showPIIData) && this.showPIIData.length > 0) {
      // this.columnsData = []
      for (let i = 0; i < this.showPIIData.length; i++) {
        let strat = null;
        if (Array.isArray(strategies)) {
          strat = strategies.findIndex(r => {
            return (r['strategy_name'] == this.showPIIData[i]['semantic_strategy'] && this.showPIIData[i]['semantic_type'] == r['semantic_group'])
          })
        }
        if (strat != null && strat != -1) {
          this.columnsData.push({
            columnlet_type: 'NA',
            delimiter_value: null,
            destination_column: this.showPIIData[i]['column_name'],
            source_column: this.showPIIData[i]['column_name'],
            columnlets_data: [{
              columnlet_name: null,
              dependent_fields: [],
              position_range_end: null,
              position_range_start: null,
              semantic_group: this.showPIIData[i]['semantic_type'],
              sk: null,
              strategy_id: strategies[strat]['id'],
              variable_position: null
            }]
          })
        }
        if (this.isBrewNowFills) {
          this.selectedTable = ''
        }
        if (Array.isArray(this.fileColumns) && this.fileColumns.length > 0) {
          this.fileColumns.forEach(e => {
            this.showSelectedColumns.push(e)
          })
          this.displaySemanticsAndConstraints({ tableName: 'file', columns: this.fileColumns }, this.showPIIData[0]['column_name'])
        } else {
          this.selTable['columns'].forEach(e => {
            this.showSelectedColumns.push(e)
          })
          this.displaySemanticsAndConstraints(this.selTable, this.showPIIData[0]['column_name'])
        }
        this.isBrewNowFills = true;
      }
      this.columnsDataDetails.emit(this.columnsData);
    }


  }
}
