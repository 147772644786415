<div class="pop-up-title">
    <h2 class="popup-title text-center">{{ 'updateorg.header2popuptitle' | translate }}</h2>
</div>
<div class="design-modal modal-body edit-profile-popup add-projct-pop-up">
    <form [formGroup]="orgForm" class="idForm" aria-label="'Org Form All required input feilds are denoted by star'">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label>{{ 'inputforms.labels.orgname' | translate }} <span class="mendatory">*</span></label>
                    <input id="txtOrgName" type="text" [placeholder]="'inputforms.placeholder.orgname' | translate "
                        pInputText class="p-inputtext form-control" aria-label="'Please enter your Org Name'"
                        formControlName="name" [ngClass]="{'is-invalid': f.name.errors}">
                    <ng-container *ngIf="f.name.touched && f.name.invalid">
                        <span *ngIf="f.name.errors.required" class="p-error">{{ 'inputforms.formerrors.requiredorgname'
                            | translate }}
                        </span>
                    </ng-container>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>{{ 'inputforms.labels.website' | translate }} <span class="mendatory">*</span></label>
                    <input id="txtAddress" pInputText [placeholder]="'inputforms.placeholder.website' | translate "
                        class="form-control" required formControlName="website"
                        aria-label="'Please enter your org website'" [ngClass]="{'is-invalid': f.website.errors}">
                    <ng-container *ngIf="f.website.touched && f.website.invalid">
                        <span *ngIf="f.website.errors.required" class="p-error">{{
                            'inputforms.formerrors.requiredwebsite' | translate }}
                        </span>
                    </ng-container>
                </div>
            </div>
            <div class="col-md-12">
                <div class="form-group">
                    <label>{{ 'inputforms.labels.address' | translate }} <span class="mendatory">*</span></label>
                    <textarea id="txtAddress" [placeholder]="'inputforms.placeholder.address' | translate"
                        pInputTextarea class="form-control" required formControlName="address"
                        aria-label="'Please enter your org address'"
                        [ngClass]="{'is-invalid': f.address.errors}"></textarea>
                    <ng-container *ngIf="f.address.touched && f.address.invalid">
                        <span *ngIf="f.address.errors.required" class="p-error">{{
                            'inputforms.formerrors.requiredaddress' | translate }}
                        </span>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="row user-img-add">
            <div class="col-md-12 text-right  bottom-end-btn ">
                <button id="btnUpdateProfil" type="button" (click)="updateOrg()"
                    class=" btn btn-raised btn-warning btn-small">
                    <span *ngIf="!isLoading" class="btn-text">{{ 'buttontitle.updatebutton' | translate }}</span>
                    <span *ngIf="isLoading" class="btn-text">{{ 'buttontitle.updatingbutton' | translate }}</span>
                </button>
            </div>
        </div>
    </form>
</div>