import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RuleService {

  private NodeAPIUrl = environment.apiAuthUrl;
  constructor(
    private http: HttpClient
  ) { }

  getRules(params) {
    return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
      method: 'GET',
      path: '/get_rules',
      params: params
    })
  }

  addDRLFile(formData) {
    return this.http.post(`${this.NodeAPIUrl}/drools`, formData, { responseType: 'text', headers: {} })
  }

  getDRLFileRules(params) {
    return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
      method: 'GET',
      path: '/get_droolsfile',
      params: params
    })
  }

  validateDRL(data) {
    let headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'text/plain'
    });
    return this.http.post(`${this.NodeAPIUrl}/rule-engine/validate-drl`, data, {
      responseType: 'text',
      headers: headers
    });
  }

  deleteDRLFileRules(params) {
    return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
      method: 'DELETE',
      path: '/delete_droolsfile',
      params: params
    });
  }

  getDRL(ruleId) {
    return this.http.post(`${this.NodeAPIUrl}/rule-engine/get-drl`, {
      ruleId
    });
  }

}
