import { Component, Input } from '@angular/core';
import { FinancialAnalytixService } from 'src/app/_services/financial-analytix.service';
import { SharedService } from 'src/app/_services/shared.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
})
export class AccountComponent {

  @Input() jobId;
  sublevel = 'ar_dashboard';
  @Input() jobDetails;
  index = 0
  salesman = '';
  salesmanOptions = [];
  category = '';
  categoryOptions = [];
  customer = '';
  customerOptions = [];
  displayInsights: boolean = false;
  insights = []
  insightsTitle = '';
  loading: boolean = false;

  constructor(
    private financialService: FinancialAnalytixService,
    private sharedService: SharedService
  ) {

  }

  ngOnInit() {
    this.onTabChange({ index: 0 });
  }

  onTabChange(e) {
    this.index = e.index;
    console.log('s', this.index)
    this.salesman = '';
    this.category = '';
    this.customer = '';
    this.salesmanOptions = [];
    this.categoryOptions = [];
    this.customerOptions = [];
    switch (this.index) {
      case 0:
        this.sublevel = 'ar_dashboard';
        this.fetchDashboard({ sales_man: this.salesman, category: this.category, customer: this.customer });
        break;
      case 1:
        this.sublevel = 'customer_overview';
        this.fetchDashboard({ sales_man: this.salesman, category: this.category });
        break;
      case 2:
        this.sublevel = 'sales_credit_utilization';
        this.fetchDashboard({ "sales_man": this.salesman });
        break;
      case 3:
        this.sublevel = 'ap_dashboard';
        this.fetchDashboard({});
        break;

    }
  }


  fetchDashboard(obj) {
    obj['job_id'] = this.jobId;
    obj['sublevel'] = this.sublevel;
    this.financialService.financialDashboard(obj).subscribe({
      next: res => {
        console.log(this.sublevel, res)
        switch (this.sublevel) {
          case "ar_dashboard":
            this.aRDashboard(res);
            break;
          case "customer_overview":
            this.coMapping(res);
            break;
          case "sales_credit_utilization":
            this.scuMapping(res);
            break;
          case "ap_dashboard":
            this.apMapping(res);
            break;
        }
      }, error: err => {

      }
    })
  }

  changeInFilter(event, type) {
    if (type == 'salesman') {
      this.salesman = event.value
    } else if (type == 'customer') {
      this.customer = event.value
    } else if (type == 'category') {
      this.category = event.value
    } else if (type == 'supplierName') {
      this.supplierName = event.value
    }

    switch (this.sublevel) {
      case 'ar_dashboard':
        this.fetchDashboard({ salesman: this.salesman, category: this.category, customer: this.customer });
        break;
      case 'customer_overview':
        this.fetchDashboard({ salesman: this.salesman, category: this.category });
        break;
      case 'sales_credit_utilization':
        this.fetchDashboard({ salesman: this.salesman });
        break;
      case 'ap_dashboard':
        this.fetchDashboard({ supplierName: this.supplierName });
        break;
    }
  }

  arDetails = {};
  arChartDetails = [];
  aRDashboard(data) {
    this.arDetails = data;
    if (this.arDetails && this.arDetails['filter-values'] && this.arDetails['filter-values']['SalesMan'] && this.salesmanOptions.length == 0) {
      this.salesmanOptions.push({
        name: "All", value: ""
      })
      this.arDetails['filter-values']['SalesMan'].forEach(e => {
        this.salesmanOptions.push({
          name: e, value: e
        });
      })
    }

    if (this.arDetails && this.arDetails['filter-values'] && this.arDetails['filter-values']['Customer'] && this.customerOptions.length == 0) {
      this.customerOptions.push({
        name: "All", value: ""
      })
      this.arDetails['filter-values']['Customer'].forEach(e => {
        this.customerOptions.push({
          name: e, value: e
        });
      })
    }

    if (this.arDetails && this.arDetails['filter-values'] && this.arDetails['filter-values']['Category'] && this.categoryOptions.length == 0) {
      this.categoryOptions.push({
        name: "All", value: ""
      })
      this.arDetails['filter-values']['Category'].forEach(e => {
        this.categoryOptions.push({
          name: e, value: e
        });
      })
    }
    this.chartMappingForARDashboard();
  }

  coDetails = {};
  coChartDetails = [];
  coMapping(data) {
    this.coDetails = data;
    if (this.coDetails && this.coDetails['filter-values'] && this.coDetails['filter-values']['SalesMan'] && this.salesmanOptions.length == 0) {
      this.salesmanOptions.push({
        name: "All", value: ""
      })
      this.coDetails['filter-values']['SalesMan'].forEach(e => {
        this.salesmanOptions.push({
          name: e, value: e
        });
      })
    }

    if (this.coDetails && this.coDetails['filter-values'] && this.coDetails['filter-values']['Category'] && this.categoryOptions.length == 0) {
      this.categoryOptions.push({
        name: "All", value: ""
      })
      this.coDetails['filter-values']['Category'].forEach(e => {
        this.categoryOptions.push({
          name: e, value: e
        });
      })
    }
    this.chartMappingForCO();
  }

  scuDetails = {};
  scuChartDetails = [];
  scuMapping(data) {
    this.scuDetails = data;
    if (this.scuDetails && this.scuDetails['filter-values'] && this.scuDetails['filter-values']['SalesMan'] && this.salesmanOptions.length == 0) {
      this.salesmanOptions.push({
        name: "All", value: ""
      })
      this.scuDetails['filter-values']['SalesMan'].forEach(e => {
        this.salesmanOptions.push({
          name: e, value: e
        });
      })
    }

    this.chartMappingForSCU();
  }

  apDetails = {};
  apChartDetails = [];
  supplierName = '';
  supplierNameOptions = []
  apMapping(data) {
    this.apDetails = data;

    if (this.apDetails && this.apDetails['filter-values'] && this.apDetails['filter-values']['Supplier Name'] && this.supplierNameOptions.length == 0) {
      this.supplierNameOptions.push({
        name: "All", value: ""
      })
      this.apDetails['filter-values']['Supplier Name'].forEach(e => {
        this.supplierNameOptions.push({
          name: e, value: e
        });
      })
    }

    this.chartMappingForAP();
  }

  chartMappingForARDashboard() {
    this.arChartDetails = []
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    let arr = [
      {
        name: 'Age Analysis of Due Balance', plot: 'plot1', type: 'bar', preType: 'chart', options: {
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            },
            x: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            }
          }
        }
      },
      {
        name: 'Overdue by Business Category', plot: 'plot3', type: "doughnut", preType: 'chart', options: {
          cutout: '60%',
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          }
        }
      },
      {
        name: 'Credit vs Balance Due Trend', plot: 'plot2', type: "line", preType: 'chart', options: {
          maintainAspectRatio: false,
          aspectRatio: 0.6,
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder
              }
            },
            y: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder
              }
            },
            y1: {
              type: 'linear',
              display: true,
              position: 'right',
              ticks: {
                color: textColorSecondary
              },
              grid: {
                drawOnChartArea: false,
                color: surfaceBorder
              }
            }
          }
        }
      },
    
      {
        name: 'Account Receivables Details', plot: 'plot4', type: 'line', preType: 'table', options: {}
      }
    ]
    for (let i = 0; i < arr.length; i++) {
      if (Object.keys(this.arDetails['charts'][arr[i]['plot']]).length) {
        let obj = {
          title: arr[i]['name'],
          datasets: arr[i]['preType'] == 'table' ? [this.arDetails['charts'][arr[i]['plot']]] : this.arDetails['charts'][arr[i]['plot']],
          options: arr[i]['options'],
          type: arr[i]['type'],
          preType: arr[i]['preType'] ? arr[i]['preType'] : ''
        }
        this.arChartDetails.push(obj)
      }
    }
    console.log('pnlover', this.arChartDetails)
  }

  chartMappingForSCU() {
    this.scuChartDetails = []
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    let arr = [
      {
        name: 'Credit Excess Utilistion', plot: 'plot1', type: 'line', preType: 'chart', options: {
          maintainAspectRatio: false,
          aspectRatio: 0.6,
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            },
            y: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            }
          }
        }
      },
      {
        name: 'Credit Availability', plot: 'plot2', type: "bar", preType: 'chart', options: {
          indexAxis: 'y',
          maintainAspectRatio: false,
          aspectRatio: 0.8,
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: textColorSecondary,
                font: {
                  weight: 500
                }
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            },
            y: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            }
          }
        }
      },
      {
        name: 'Credit Availability Details', plot: 'plot3', type: 'line', preType: 'table', options: {}
      }
    ]
    for (let i = 0; i < arr.length; i++) {
      if (Object.keys(this.scuDetails['charts'][arr[i]['plot']]).length) {
        let obj = {
          title: arr[i]['name'],
          datasets: arr[i]['preType'] == 'table' ? [this.scuDetails['charts'][arr[i]['plot']]] : this.scuDetails['charts'][arr[i]['plot']],
          options: arr[i]['options'],
          type: arr[i]['type'],
          preType: arr[i]['preType'] ? arr[i]['preType'] : ''
        }
        this.scuChartDetails.push(obj)
      }
    }
    console.log('pnlover', this.scuChartDetails)
  }

  chartMappingForCO() {
    this.coChartDetails = []
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    let arr = [
      {
        name: 'Customer Overdue', plot: 'plot1', type: 'bar', preType: 'chart', options: {
          indexAxis: 'y',
          maintainAspectRatio: false,
          aspectRatio: 0.8,
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: textColorSecondary,
                font: {
                  weight: 500
                }
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            },
            y: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            }
          }
        }
      },
      {
        name: 'Overdue and With in Due by Customer', plot: 'plot2', type: "bar", preType: 'chart', options: {
          indexAxis: 'y',
          maintainAspectRatio: false,
          aspectRatio: 0.8,
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: textColorSecondary,
                font: {
                  weight: 500
                }
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            },
            y: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            }
          }
        }
      },
      {
        name: 'Customer OverDue Details', plot: 'plot3', type: 'line', preType: 'table', options: {}
      }
    ]
    for (let i = 0; i < arr.length; i++) {
      if (Object.keys(this.coDetails['charts'][arr[i]['plot']]).length) {
        let obj = {
          title: arr[i]['name'],
          datasets: arr[i]['preType'] == 'table' ? [this.coDetails['charts'][arr[i]['plot']]] : this.coDetails['charts'][arr[i]['plot']],
          options: arr[i]['options'],
          type: arr[i]['type'],
          preType: arr[i]['preType'] ? arr[i]['preType'] : ''
        }
        this.coChartDetails.push(obj)
      }
    }
    console.log('pnlover', this.coChartDetails)
  }

  chartMappingForAP() {
    this.apChartDetails = []
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    let arr = [
      {
        name: 'Age Analysis of Due balance', plot: 'plot1', type: 'bar', preType: 'chart', options: {
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            },
            x: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            }
          }
        }
      },
      {
        name: 'Vendor wise Over Due', plot: 'plot2', type: 'bar', preType: 'chart', options: {
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            },
            x: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            }
          }
        }
      },
      {
        name: 'Invoice paid After/Before Due Date', plot: 'plot3', type: 'bar', preType: 'chart', options: {
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            },
            x: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            }
          }
        }
      },
      {
        name: 'Monthly Due Balance', plot: 'plot4', type: 'bar', preType: 'chart', options: {
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            },
            x: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            }
          }
        }
      },
      {
        name: 'Currency Breakup by Age', plot: 'plot5', type: 'bar', preType: 'chart', options: {
          maintainAspectRatio: false,
          aspectRatio: 0.8,
          plugins: {
              tooltips: {
                  mode: 'index',
                  intersect: false
              },
              legend: {
                  labels: {
                      color: textColor
                  }
              }
          },
          scales: {
              x: {
                  stacked: true,
                  ticks: {
                      color: textColorSecondary
                  },
                  grid: {
                      color: surfaceBorder,
                      drawBorder: false
                  }
              },
              y: {
                  stacked: true,
                  ticks: {
                      color: textColorSecondary
                  },
                  grid: {
                      color: surfaceBorder,
                      drawBorder: false
                  }
              }
          }
      }
      },
      {
        name: 'Monthly Number of Invoices with Due Date', plot: 'plot6', type: 'line', preType: 'chart', options: {
          maintainAspectRatio: false,
          aspectRatio: 0.6,
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            },
            y: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            }
          }
        }
      },

    ]
    for (let i = 0; i < arr.length; i++) {
      if (Object.keys(this.apDetails['charts'][arr[i]['plot']]).length) {
        let obj = {
          title: arr[i]['name'],
          datasets: arr[i]['preType'] == 'table' ? [this.apDetails['charts'][arr[i]['plot']]] : this.apDetails['charts'][arr[i]['plot']],
          options: arr[i]['options'],
          type: arr[i]['type'],
          preType: arr[i]['preType'] ? arr[i]['preType'] : ''
        }
        this.apChartDetails.push(obj)
      }
    }
    console.log('pnlover', this.apChartDetails)
  }


  invokeChatSliding(type) {
    this.sharedService.sendClickEvent({ jobId: this.jobId, type: 'account' }, type);
  }

  getInsights(chart) {
    this.loading = true;
    let body = {}
    this.displayInsights = true;
    this.insightsTitle = chart['title']
    body['plot_data'] = chart['datasets']
    body['filter'] = { salesman: this.salesman, category: this.category, customer: this.customer }
    this.financialService.fetchInsights(body).subscribe({
      next: res => {
        this.loading = false;
        console.log('insights', res)
        this.insights = res['insight'];
      },
      error: err => {
        this.loading = false;
      }
    })
  }
}
