<div class="phi-data">
    <h2 class="col-12 label-control text-center popup-title">PII Report</h2>
    <div class="row mb-2">
        <div class="col-8">
            <div class="row" *ngIf="jobDetails && jobDetails['sourceType']">
                <div class="col-4">
                    <h3>Source Type</h3>
                </div>
                <div class="col-8">
                    <p>{{jobDetails['sourceType']}}</p>
                </div>
            </div>
            <div class="row" *ngIf="jobDetails && jobDetails['sourceType'] && jobDetails['sourceType'] == 'database'">
                <div class="col-4">
                    <h3>Database</h3>
                </div>
                <div class="col-8">
                    <p>{{jobDetails['selectSource']['driver_name']}}</p>
                </div>
            </div>
        </div>
        <div class="col-4 text-right">
            <a href="javascript:void(0)" class="download-link" (click)="getPDFReport()"><i class="feather ft-download">
                </i>Download</a>
        </div>
    </div>
    <div class="phi-box mb-2" *ngFor="let item of piiReportData">
        <div class="row phi-table-name">
            <div class="col-12">
                <span>{{item && item['table_name'] ? item['table_name'] : item['file_name']}}</span>
            </div>
        </div>
        <div class="row phi-table-data">
            <div class="col-4">
                <span class="phi-table-data-head">Column Name</span>
            </div>
            <div class="col-4">
                <span class="phi-table-data-head">Semantic Type Identified</span>
            </div>
            <div class="col-2">
                <span class="phi-table-data-head">PII</span>
            </div>
            <div class="col-2">
                <span class="phi-table-data-head">Confidence Score</span>
            </div>
        </div>
        <div class="row phi-table-value" *ngFor="let col of item['details']">
            <div class="col-4">
                <span>{{col['column_name']}}</span>
            </div>
            <div class="col-4">
                <span>{{col['semantic_type']}}</span>
            </div>
            <div class="col-2">
                <span>{{col['PII']}}</span>
            </div>
            <div class="col-2">
                <span>{{col['PII_score'] == "-" ? "-" : ((1 - col['PII_score']) * 100).toFixed(2) }}</span>
            </div>
        </div>
    </div>

</div>