import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})
export class SubscriptionService {

    private NodeAPIUrl = environment.apiAuthUrl;

    constructor(private http: HttpClient) {
    }

    initialCheckoutPage(body) {
        return this.http.post<any>(`${this.NodeAPIUrl}/billing`, {
            method: 'POST',
            path: 'create-checkout-session',
            body: body
        });
    }

    subscriptionDetailsById(id: string) {
        return this.http.post<any>(`${this.NodeAPIUrl}/billing`, {
            method: 'GET',
            path: 'subscription/' + id,
            body: {}
        });
    }

    upcomingInvoice(id: string) {
        return this.http.post<any>(`${this.NodeAPIUrl}/billing`, {
            method: 'POST',
            path: 'invoices/upcoming-invoice',
            body: { "customerId": id }
        });
    }




}