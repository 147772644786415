import { Component, EventEmitter, Output } from '@angular/core';
import { GoogleMapsService } from 'src/app/_services/google-maps.service';
import { OspService } from 'src/app/_services/osp.service';

declare var google: any;

@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html'
})
export class MapsComponent {

  lat = 52.489471;
  lng = -1.898575;
  map: any;
  markers = [];
  historicMarkers = []
  google: any;
  @Output() coordinatesEvent = new EventEmitter();
  streetView: any;

  constructor (
    private mapsService: GoogleMapsService,
    private ospService: OspService,
  ) {

  }

  ngOnInit() {
    this.loadMap();
    this.fetch();
  }

  async loadMap() {
    this.mapsService.loadMapsApi().then(async (google) => {
      this.map = new google.maps.Map(document.getElementById('map'), {
        center: { lat: this.lat, lng: this.lng },
        zoom: 16
      });

      google.maps.event.addListener(this.map, 'click', (event: any) => {
        this.lat = event.latLng.lat();
        this.lng = event.latLng.lng();
        // this.streetViewMap();
        this.coordinatesEvent.emit({lng: this.lng, lat: this.lat})
        this.clearMarkers();
        const marker = new google.maps.Marker({
          position: { lat: this.lat, lng: this.lng },
          map: this.map
        });
        this.markers.push(marker);
      });
    });
  }

  streetViewMap() {
         // Add street view control to the map
         this.streetView = new google.maps.StreetViewPanorama(
          document.getElementById('street-view'),
          {
            position: { lat: this.lat, lng: this.lng },
            pov: { heading: 165, pitch: 0 },
            zoom: 1
          }
        );
  
        this.map.setStreetView(this.streetView);
  }

  clearMarkers() {
    // Remove each marker from the map
    this.markers.forEach(marker => {
      marker.setMap(null);
    });

    // Clear the markers array
    this.markers = [];
  }

  fetch() { 
    this.ospService.fetchGeoJSON().subscribe({
      next: res => {
        console.log('qwerty', res)
        this.renderLocations(res)
      }, error: err => {

      }
    })
    
  }

  renderLocations(data) {
    this.mapsService.loadMapsApi().then((google) => {
      data.forEach(obj => {
        const marker = new google.maps.Marker({
          position: { lat: obj?.location?.coordinates[1], lng: obj?.location?.coordinates[0] },
          map: this.map,
          title: 'Location',
          icon: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png', // Example custom icon URL
          label: 'A' // Example label
        });
  
        marker.addListener('click', () => {
          // this.showObjectsInfo(location.objects);
        });

        this.historicMarkers.push(marker);
      });
    })
  }

  showObjectsInfo(objects: any[]) {
    console.log('Objects:', objects);
    // Show objects in a modal or sidebar
  }
}
