import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PatternService {

  private NodeAPIUrl = environment.apiAuthUrl;

  constructor(
    private http: HttpClient
  ) { }

  async addPatternAsync(body) {
    return new Promise(resolve => {
      this.addPattern(body).subscribe({
        next: result => resolve(result),
        error: err => resolve(err)
      })
    })
  }

  addPattern(body) {
    return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
      method: 'POST',
      path: '/add_pattern',
      body: body
    })
  }

  getPatterns(params) {
    return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
      method: 'GET',
      path: '/get_pattern',
      params: params
    })
  }

  async getPatternsAsync(params) {
    return new Promise(resolve => {
      this.getPatterns(params).subscribe({
        next: result => resolve(result),
        error: err => resolve(err)
      })
    })
  }

}
