import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { PublicLayoutComponent } from './_layout/public-layout/public-layout.component';
import { PrivateLayoutComponent } from './_layout/private-layout/private-layout.component';
import { AuthGuard } from './_guards/auth.guard';
import { RegisterComponent } from './authentication/register';
import { LoginComponent } from './authentication/login';
import { FullLayoutComponent } from './_layout/full-layout/full-layout.component';
import { PrivacyPolicyComponent } from './authentication/privacy-policy/privacy-policy.component';
import { TermsConditionComponent } from './authentication/terms-condition/terms-condition.component';
import { FinalizeRegistrationComponent } from './authentication/finalize-registration/finalize-registration.component';
import { ForgotPasswordComponent } from './authentication/forgot-password/forgot-password.component';
import { SetupComponent } from './authentication/org/setup/setup.component';
import { NoAuthGuard } from './_guards/noAuth.guard';
const routes: Routes = [
  { path: 'privacypolicy', component: PrivacyPolicyComponent },
  { path: 'termCondition', component: TermsConditionComponent },
  // Public layout
  {
    path: '',
    component: PublicLayoutComponent,
    children: [
      { path: 'org-setup', component: SetupComponent, canActivate: [AuthGuard] },
      { path: 'forgot-password', component: ForgotPasswordComponent },
      { path: 'finalize-registration', component: FinalizeRegistrationComponent, canActivate: [NoAuthGuard] },
      { path: 'register', component: RegisterComponent, canActivate: [NoAuthGuard] },
      { path: 'login', component: LoginComponent, canActivate: [NoAuthGuard] },
      { path: '', redirectTo: '/login', pathMatch: 'full' }
    ]
  },
  {
    path: '',
    component: FullLayoutComponent,
    children: [
      {
        path: 'error', loadChildren: () => import('../app/content/pages/error/error.module').then(m => m.ErrorModule),
        canActivate: [AuthGuard]
      }
    ]
  },
  // Private layout
  {
    path: '',
    component: PrivateLayoutComponent,
    children: [
      {
        path: 'logout',
        component: LoginComponent,
        canActivate: [AuthGuard]
      },
      {
        path: '',
        loadChildren: () => import('../app/content/pages/pages.module').then(m => m.PagesModule),
        canActivate: [AuthGuard]
      }
    ],
  },
  // otherwise redirect to home
  { path: '**', redirectTo: 'error/error400WithNavbar' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollOffset: [0, 0], scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }