<div class="setup-job">
    <form [formGroup]="setupParametersForm">
        <div class="row">
            <div class="col-lg-12">
                <div class="form-group page-content row mx-auto">
                    <h2 class="col-md-3 label-control" for="projectinput1">{{ 'Advanced Settings' }}
                    </h2>
                    <div class="col-lg-8 col-md-7 setup-info">
                        <div class="row">
                            <div class="col-lg-6">
                                <label class="label-control label-llm" for="projectinput1">
                                    <span class="labelName">{{ 'LoRA Rank' }}</span>
                                    <span class="labelName sub">{{'Determines how much the model adapts to new data,
                                        with lower
                                        ranks
                                        producing smaller models.'}}</span>
                                </label>
                                <!-- <span>Complexity of training task (higher for more complexity)</span> -->
                                <input type="text" id="projectinput1" class="form-control" formControlName="lora_rank"
                                    [placeholder]="'optional' | translate" (change)="emitterHandler()">
                                <div
                                    *ngIf="setupParametersForm.get('lora_rank').invalid &&  setupParametersForm.get('lora_rank').touched">
                                    <div class="invalid-feedback">
                                        {{ 'Lora Rank is required' }}</div>
                                </div>

                            </div>
                            <div class="col-lg-6">
                                <label class=" label-control label-llm" for="projectinput1">
                                    <span class="labelName">{{ 'Learning Rate' }}</span>
                                    <span class="labelName sub">{{' Controls the size of steps taken during training,
                                        influencing how quickly
                                        the model learns.'}}</span>
                                </label>
                                <!-- <span>How quickly the model adjusts to training data (based on sum loss)</span> -->
                                <input type="text" id="projectinput1" class="form-control"
                                    formControlName="learning_rate" [placeholder]="'optional' | translate"
                                    (change)="emitterHandler()">
                                <div
                                    *ngIf="setupParametersForm.get('learning_rate').invalid &&  setupParametersForm.get('learning_rate').touched">
                                    <div class="invalid-feedback">
                                        {{ 'Learning Rate is required' }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group page-content row mx-auto">
                    <h2 class="col-md-3 label-control" for="projectinput1">
                    </h2>
                    <div class="col-lg-8 col-md-7 setup-info">
                        <div class="row">
                            <div class="col-lg-6">
                                <label class="label-control label-llm" for="projectinput1">
                                    <span class="labelName">{{ 'Batch Size' }}</span>
                                    <span class="labelName sub">{{'The number of training examples processed,
                                        affecting training
                                        speed and memory usage.'}}</span>
                                </label>
                                <!-- <span>What is the batch size </span> -->
                                <input type="text" id="projectinput1" class="form-control" formControlName="batch"
                                    [placeholder]="'optional' | translate" (change)="emitterHandler()">
                                <div
                                    *ngIf="setupParametersForm.get('batch').invalid &&  setupParametersForm.get('batch').touched">
                                    <div class="invalid-feedback">
                                        {{ 'Batch Size is required' }}</div>
                                </div>

                            </div>
                            <div class="col-lg-6">
                                <label class="label-control label-llm" for="projectinput1">
                                    <span class="labelName">{{ 'Epochs' }}</span>
                                    <span class="labelName sub">{{'The number of times the model goes through the
                                        training data, affecting
                                        learning and performance.'}}</span>
                                </label>
                                <!-- <span>No of training epochs</span> -->
                                <input type="text" id="projectinput1" class="form-control" formControlName="epochs"
                                    [placeholder]="'optional' | translate" (change)="emitterHandler()">
                                <div
                                    *ngIf="setupParametersForm.get('epochs').invalid &&  setupParametersForm.get('epochs').touched">
                                    <div class="invalid-feedback">
                                        {{ 'Epochs is required' }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group page-content row mx-auto">
                    <h2 class="col-md-3 label-control" for="projectinput1">
                        Dataset
                    </h2>
                    <div class="col-lg-8 col-md-7 setup-info">
                        <div class="row">
                            <div class="col-lg-6">
                                <label class=" label-control" for="projectinput1">{{ 'Training Dataset' }} <span
                                        class="mendatory">*</span></label>
                                <p-fileUpload *ngIf="!files[0]" class="mr-1" mode="basic" name="file[]"
                                    [customUpload]="true" [ariaLabel]="'please upload'"
                                    accept=".csv, application/vnd.ms-excel" [auto]="true" chooseLabel="Upload File"
                                    (uploadHandler)="trainingUploader($event)"></p-fileUpload>
                                <p *ngIf="files[0]" style="list-style: none;">{{files[0].name}} -
                                    {{files[0].size}}
                                    {{ 'setupjob.libytes' | translate }} <a href="javascript:void(0)"
                                        [ariaLabel]="'clear your selected file'" (click)="clearTrainingData()"><i
                                            class="feather ft-x cursor-pointer ml-1"></i></a>
                                </p>
                                <!-- <p class="doc-msg">Data file must follow the format given in the
                                    <span>documentation</span>
                                </p> -->
                            </div>
                            <div class="col-lg-6">
                                <label class=" label-control" for="projectinput1">{{ 'Evalution Dataset' }} <span
                                        class="mendatory">*</span></label>
                                <p-fileUpload *ngIf="!files[1]" class="mr-1" mode="basic" name="file[]"
                                    [customUpload]="true" [ariaLabel]="'please upload'"
                                    accept=".csv, application/vnd.ms-excel" [auto]="true" chooseLabel="Upload File"
                                    (uploadHandler)="evalUploader($event)"></p-fileUpload>
                                <p *ngIf="files[1]" style="list-style: none;">{{files[1].name}} -
                                    {{files[1].size}}
                                    {{ 'setupjob.libytes' | translate }} <a href="javascript:void(0)"
                                        [ariaLabel]="'clear your selected file'" (click)="clearEvalData()"><i
                                            class="feather ft-x cursor-pointer ml-1"></i></a>
                                </p>
                                <!-- <p class="doc-msg">Data file must follow the format given in the
                                    <span>documentation</span>
                                </p> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>