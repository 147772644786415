import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, Renderer2, SimpleChanges } from '@angular/core';
import { CustomValidators } from 'src/app/_helpers/custom-validators';
import { Toast } from 'src/app/_helpers/toast';
import { AuthService } from 'src/app/_services/auth.service';
import { CustomStrategyService } from 'src/app/_services/custom-strategy.service';
import {
  dateSchema,
  OAIAddressSchema,
  baseAddressStrategy,
  OAINameSchema,
  defaultNameSchema,
  baseNameStrategy,
  basePhoneStrategy,
  baseCreditCard,
  baseDOB,
  baseRandomCharacters,
  baseRandomNumbers,
  randomCharactersSchema,
  creditCardStrategySchema,
  phoneSchema,
  randomNumbersSchema,
  defaultDateNumber,
  regexPatternSchema,
  baseList,
  listSchema,
  dateRangeSchema,
  baseDataPrivacy,
  roundingSchema,
  bucketingSchema,
  maskingSchema
} from '../../../_helpers/schemas';

@Component({
  selector: 'app-strategies-handler',
  templateUrl: './strategies-handler.component.html'
})
export class StrategiesHandlerComponent implements OnChanges, OnDestroy {

  @Input() public strategySchema: Object[] = [];
  @Input() public status: string = '';
  @Input() public selectedAssignValue = '';
  @Input() public projectId = '';
  @Input() public jobDetails: Object = {};
  @Output() strategyEvent = new EventEmitter<any>();


  schema: Object[] = [];
  showSchema: boolean;
  base: Object[] = []
  strategy: number;


  constructor(
    private authService: AuthService,
    private customStrategyService: CustomStrategyService,
    private renderer: Renderer2, private elementRef: ElementRef
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    const inputElement = this.elementRef.nativeElement.querySelector('input');
    this.renderer.selectRootElement(inputElement).focus();
  }

  ngOnDestroy(): void {
    this.schema = [];
    this.strategySchema = null
    this.status = null;
    this.selectedAssignValue = ''
    this.showSchema = false
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes['selectedAssignValue']) {
      this.showStrategiesBasedOnSemantic();
    }

  }



  showStrategiesBasedOnSemantic() {
    this.base = [];
    switch (this.selectedAssignValue) {
      case "name":
        this.base = baseNameStrategy.map(obj => ({ ...obj }));
        this.schema = [];
        break;
      case "address":
        this.base = baseAddressStrategy.map(obj => ({ ...obj }));
        this.schema = [];
        break;
      case "street_name":
        this.base = baseAddressStrategy.map(obj => ({ ...obj }));
        this.schema = [];
        break;
      case "city":
        this.base = baseAddressStrategy.map(obj => ({ ...obj }));
        this.schema = [];
        break;
      case "state":
        this.base = baseAddressStrategy.map(obj => ({ ...obj }));
        this.schema = [];
        break;
      case "random_number":
        this.base = baseRandomNumbers.map(obj => ({ ...obj }));
        this.schema = [];
        break;
      case "random_letters":
        this.base = baseRandomCharacters.map(obj => ({ ...obj }));
        this.schema = [];
        break;
      case "date":
        this.base = baseDOB.map(obj => ({ ...obj }));
        this.schema = [];
        break;
      case "credit_card":
        this.base = baseCreditCard.map(obj => ({ ...obj }));
        this.schema = [];
        break;
      case "phone_number":
        this.base = basePhoneStrategy.map(obj => ({ ...obj }));
        this.schema = [];
        break;
      case "list":
        this.base = baseList.map(obj => ({ ...obj }));
        this.schema = [];
        break;
      case "data_privacy":
        this.base =baseDataPrivacy.map(obj => ({ ...obj }));
        this.schema = [];
        break;
      default:
        break;
    }

  }
  
  emitterHandler(event) {
    this.showSchema = false
    this.strategy = event['value']
    if (event['pattern']) {
      if (event['value'] == 5 || event['value'] == 6 || event['value'] == 7) {
        this.schema = [];
        this.schema = defaultNameSchema.map(obj => ({ ...obj }));
        this.showSchema = true
      } else if (event['value'] == 54 || event['value'] == 55 || event['value'] == 56) {
        this.schema = [];
        this.schema = OAINameSchema.map(obj => ({ ...obj }));
        this.showSchema = true
      } else if (event['value'] == 50 || event['value'] == 51 || event['value'] == 52 || event['value'] == 53) {
        this.schema = [];
        this.schema = OAIAddressSchema.map(obj => ({ ...obj }));
        this.showSchema = true
      } else if (event['value'] == 9) {
        this.schema = [];
        this.schema = phoneSchema.map(obj => ({ ...obj }));
        this.showSchema = true
      } else if (event['value'] == 23) {
        this.schema = [];
        this.schema = randomCharactersSchema.map(obj => ({ ...obj }));
        this.showSchema = true
      } else if (event['value'] == 22) {
        this.schema = [];
        this.schema = randomNumbersSchema.map(obj => ({ ...obj }));
        this.showSchema = true
      } else if (event['value'] == 8) {
        this.schema = [];
        this.schema = dateSchema.map(obj => ({ ...obj }));
        this.showSchema = true
      } else if (event['value'] == 19) {
        this.schema = [];
        this.schema = creditCardStrategySchema.map(obj => ({ ...obj }));
        this.showSchema = true
      } else if (event['value'] == 33) {
        this.schema = [];
        this.schema = defaultDateNumber.map(obj => ({ ...obj }));
        this.showSchema = true
      } else if (event['value'] == 47) {
        this.schema = [];
        this.schema = regexPatternSchema.map(obj => ({ ...obj }));
        this.showSchema = true
      } else if (event['value'] == 57) {
        this.schema = [];
        this.schema = listSchema.map(obj => ({ ...obj }));
        this.showSchema = true
      } else if (event['value'] == 92) {
        this.schema = [];
        this.schema = dateRangeSchema.map(obj => ({ ...obj }));
        this.showSchema = true
      } else if(event['value'] == 89) {
        this.schema = [];
        this.schema = roundingSchema.map(obj => ({ ...obj }));
        this.showSchema = true;
      } else if(event['value'] == 90) {
        this.schema = [];
        this.schema = bucketingSchema.map(obj => ({ ...obj }));
        this.showSchema = true;
      } else if(event['value'] == 91) {
        this.schema = [];
        this.schema = maskingSchema.map(obj => ({ ...obj }));
        this.showSchema = true;
      } else {
        this.showSchema = false
      }
    }
  }

  dateDelimiterHandler(data) {
    if (data && (data['dateDelimiter'] == "-" || data['dateDelimiter'] == "/")) {
      data['dateFormat'] = data['dateFormat'].replace(/\s+/g, data['dateDelimiter']);
    }
  }

  async strategyHandler(event) {
    let res = CustomValidators.removeNullFromObject(event.value)
    const currentUser = this.authService.currentUserValue;
    let name = res['strategyName'];
    delete res['strategyName'];
    let st = this.strategy
    if (res['daysAfter'] || res['daysBefore']) {
      res['dataType'] = ""
      await this.dateDelimiterHandler(res)
    }
    if (res['startDate'] || res['endDate']) {
      await this.dateDelimiterHandler(res)
    }
    if (res['list']) {
      res = res['list']
    }
    if (res['rounding']) {
      res = res['rounding']
    } 
    if (res['bucketing']) {
      res =res['bucketing']
    }
    if (this.selectedAssignValue && currentUser.id && this.projectId && this.jobDetails['locale']) {
      this.customStrategyService.addStrategy({
        org_id: currentUser.orgId,
        strategy_name: name,
        strategy: st,
        semantic_group: this.selectedAssignValue,
        locale: this.jobDetails['locale'],
        project_id: this.projectId,
        user_id: currentUser.id,
        constraints: res
      }).subscribe({
        next: r => {
          this.strategyEvent.emit(r)
        },
        error: err => {
          Toast.fire({
            icon: 'error',
            html: err.error.error.message
          });
        }
      })
    } else {
      // TODO document why this block is empty
    }
  }
}