import { Component, Input } from '@angular/core';
import { FinancialAnalytixService } from 'src/app/_services/financial-analytix.service';
import { SharedService } from 'src/app/_services/shared.service';

@Component({
  selector: 'app-account-payables',
  templateUrl: './account-payables.component.html',
})
export class AccountPayablesComponent {


  @Input() jobId;
  @Input() jobDetails;
  sublevel = 'ap_dashboard';
  index = 0
  displayInsights: boolean = false;
  insights = []
  insightsTitle = '';
  loading: boolean = false;

  constructor(
    private financialService: FinancialAnalytixService,
    private sharedService: SharedService
  ) {

  }

  ngOnInit() {
    this.onTabChange({ index: 0 });
  }

  onTabChange(e) {
    this.sublevel = 'ap_dashboard';
    this.fetchDashboard({ supplierName: this.supplierName });
  }


  fetchDashboard(obj) {
    obj['job_id'] = this.jobId;
    obj['sublevel'] = this.sublevel;
    this.financialService.financialDashboard(obj).subscribe({
      next: res => {
        this.apMapping(res);
      }, error: err => {

      }
    })
  }

  changeInFilter(event, type) {
   if (type == 'supplierName') {
      this.supplierName = event.value
    }

    switch (this.sublevel) {
    
      case 'ap_dashboard':
        this.fetchDashboard({ supplierName: this.supplierName });
        break;
    }
  }

 

  apDetails = {};
  apChartDetails = [];
  supplierName = '';
  supplierNameOptions = []
  apMapping(data) {
    this.apDetails = data;

    if (this.apDetails && this.apDetails['filter-values'] && this.apDetails['filter-values']['Supplier Name'] && this.supplierNameOptions.length == 0) {
      this.supplierNameOptions.push({
        name: "All", value: ""
      })
      this.apDetails['filter-values']['Supplier Name'].forEach(e => {
        this.supplierNameOptions.push({
          name: e, value: e
        });
      })
    }

    this.chartMappingForAP();
  }

  chartMappingForAP() {
    this.apChartDetails = []
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    let arr = [
      {
        name: 'Age Analysis of Due balance', plot: 'plot1', type: 'bar', preType: 'chart', options: {
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            },
            x: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            }
          }
        }
      },
      {
        name: 'Vendor wise Over Due', plot: 'plot2', type: 'bar', preType: 'chart', options: {
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            },
            x: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            }
          }
        }
      },
      {
        name: 'Invoice paid After/Before Due Date', plot: 'plot3', type: 'bar', preType: 'chart', options: {
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            },
            x: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            }
          }
        }
      },
      {
        name: 'Monthly Due Balance', plot: 'plot4', type: 'bar', preType: 'chart', options: {
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            },
            x: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            }
          }
        }
      },
      {
        name: 'Currency Breakup by Age', plot: 'plot5', type: 'bar', preType: 'chart', options: {
          maintainAspectRatio: false,
          aspectRatio: 0.8,
          plugins: {
            tooltips: {
              mode: 'index',
              intersect: false
            },
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            x: {
              stacked: true,
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            },
            y: {
              stacked: true,
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            }
          }
        }
      },
      {
        name: 'Monthly Number of Invoices with Due Date', plot: 'plot6', type: 'line', preType: 'chart', options: {
          maintainAspectRatio: false,
          aspectRatio: 0.6,
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            },
            y: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            }
          }
        }
      },

    ]
    for (let i = 0; i < arr.length; i++) {
      if (Object.keys(this.apDetails['charts'][arr[i]['plot']]).length) {
        let obj = {
          title: arr[i]['name'],
          datasets: arr[i]['preType'] == 'table' ? [this.apDetails['charts'][arr[i]['plot']]] : this.apDetails['charts'][arr[i]['plot']],
          options: arr[i]['options'],
          type: arr[i]['type'],
          preType: arr[i]['preType'] ? arr[i]['preType'] : ''
        }
        this.apChartDetails.push(obj)
      }
    }
    console.log('pnlover', this.apChartDetails)
  }


  invokeChatSliding(type) {
    this.sharedService.sendClickEvent({ jobId: this.jobId, type: 'account' }, type);
  }

  getInsights(chart) {
    this.loading = true;
    let body = {}
    this.displayInsights = true;
    this.insightsTitle = chart['title']
    body['plot_data'] = chart['datasets']
    body['filter'] = { supplierName: this.supplierName }  
    this.financialService.fetchInsights(body).subscribe({
      next: res => {
        this.loading = false;
        console.log('insights', res)
        this.insights = res['insight'];
      },
      error: err => {
        this.loading = false;
      }
    })
  }
}
