import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../_services/auth.service';
import Swal from 'sweetalert2';
import { UserService } from 'src/app/_services/user.service';
import { Toast } from 'src/app/_helpers/toast';

@Component({
    templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    password: string = '';
    showPassword: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private userService: UserService
    ) {
        if (sessionStorage.getItem('currentUser')) {
            this.authService.doLogout();
        }
    }

    ngOnInit() {
        this.loginForm = new FormGroup({
            email: new FormControl(null, [Validators.required, Validators.email]),
            password: new FormControl(null, Validators.required)
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    tryLogin() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }
        const value = {
            email: this.f.email.value,
            password: this.f.password.value
        };
        this.authService.doLogin(value).subscribe({
            next: _result => {
                this.userService.getUserProfile().subscribe({
                    next: res => {
                        if (!res.isEmailVerified) {
                            Swal.fire({
                                title: 'Verify your Email Address',
                                text: 'In order to start using your account, you need to confirm your email address',
                                icon: 'warning',
                                confirmButtonColor: '#FF6501',
                                confirmButtonText: 'Send Verification Mail',
                                showCancelButton: true,
                                cancelButtonColor: '#3085d6',
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    this.authService.doResendEmailVerificationCode({ email: res.email }).subscribe({
                                        next: res => {
                                            this.router.navigate(['/finalize-registration'], { queryParams: { id: res.id, email: res.email }, skipLocationChange: true });
                                        },
                                        error: err => {
                                            Toast.fire({
                                                icon: 'error',
                                                html: err.error.error.message
                                            });
                                        }
                                    });
                                }
                            });
                        } else if (res.isEmailVerified && res.orgId && !res.isOrgVerified) {
                            Toast.fire({
                                icon: 'info',
                                title: 'Verification is required',
                                html: "Please wait until your org admin accepted you"
                            });
                            sessionStorage.removeItem('currentUser');
                        } else if (res.isEmailVerified && !res.orgId && !res.isOrgVerified) {
                            this.router.navigate(['/org-setup'])
                        } else if (res.isEmailVerified && res.isOrgVerified) {
                            let userData = this.authService.currentUserValue
                            if (userData['email'] && userData['id'] && userData['orgId']) {
                                this.router.navigate(['/projects'])
                            } else {
                                // Logout
                                this.authService.doLogout()
                            }
                        }
                    },
                    error: err => {
                        Toast.fire({

                            icon: 'error',

                            html: err.error.error.message
                        });
                    }
                })
            },
            error: err => {
                this.submitted = false;
                if (err.error.error.message === "Your email is not verified") {
                    Swal.fire({
                        title: 'Verify your Email Address',
                        text: 'In order to start using your account, you need to confirm your email address',
                        icon: 'warning',
                        confirmButtonColor: '#FF6501',
                        confirmButtonText: 'Send Verification Mail',
                        showCancelButton: true,
                        cancelButtonColor: '#3085d6',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.authService.doResendEmailVerificationCode({ email: this.f.email.value }).subscribe({
                                next: res => {
                                    this.router.navigate(['/finalize-registration'], { queryParams: { id: res.success.id, email: res.success.email }, skipLocationChange: true });
                                },
                                error: err => {
                                    Toast.fire({

                                        icon: 'error',

                                        html: err.error.error.message
                                    });
                                }
                            });
                        }
                    });
                } else {
                    Toast.fire({

                        icon: 'error',

                        html: err.error.error.message
                    });
                }
            }
        });
    }
    togglePasswordVisibility() {
        this.showPassword = !this.showPassword;
    }
}
