import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomStrategyService {

  private NodeAPIUrl = environment.apiAuthUrl;

  constructor(
    private http: HttpClient
  ) { }

  // http://127.0.0.1:5001/v1/get_strategies?semantic_group=grp_name&project_id=p1&user_id=u1

  getStrategies(params) {
    params['openai'] = 1; // OpenAI Settings need to be controlled with Admin in Org Page
    return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
      method: 'GET',
      path: '/get_strategies',
      params: params
    })
  }

  async getStrategiesAsync(params) {
    return new Promise(resolve => {
      this.getStrategies(params).subscribe({
        next: result => resolve(result),
        error: err => resolve(err)
      })
    })
  }

  addStrategy(body) {
    return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
      method: 'POST',
      path: '/add_strategy',
      body: body
    })
  }

  getStrategiesBySemanticGroup(params) {
    return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
      method: 'GET',
      path: '/get_strategies_by_semantic_groups',
      params: params
    })
  }
}
