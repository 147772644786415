import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-invoice-template',
  templateUrl: './invoice-template.component.html'
})
export class InvoiceTemplateComponent implements OnInit {
  public breadcrumb: any;
  constructor() { }

  ngOnInit() {
  }

}
