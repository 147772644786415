import { Component, Input } from '@angular/core';
import { DocumentationSlideService } from 'src/app/_services/documentation-slide.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html'
})
export class BreadcrumbComponent {


  @Input() breadcrumb: object;

  constructor(
    private documentSlideService: DocumentationSlideService,
  ) { }

  documentationLink(link: any) {
    this.documentSlideService.customDocumentName(link.name);
  }


}
