<div class="add-agents-step-one" *ngIf="!showToolSection">
    <h2 class="popup-title text-center">Configure Agents</h2>
    <div class="form-builder">
        <div class="row">
            <div class="form-group">
                <label>{{ 'Name' }}<span class="mendatory">*</span></label>
                <input [ngClass]="'form-control'" placeholder="{{ 'Enter here'}}" [(ngModel)]="agentName">
            </div>

            <div class="form-group">
                <label>{{ 'Description' }}<span class="mendatory">*</span></label>
                <input [ngClass]="'form-control'" placeholder="{{ 'Enter here'}}" [(ngModel)]="agentDescription">
            </div>

            <div class="form-group">
                <label>{{ 'Prompt File' }}<span class="mendatory">*</span></label>
                <p-fileUpload *ngIf="!agentsFiles.length" mode="basic" name="demo[]"
                    [ariaLabel]="'please upload md or txt file'" accept=".md,.txt" maxFileSize="20000000"
                    [customUpload]="true" (uploadHandler)="myUploader($event)" [auto]="true"
                    [chooseLabel]="'Upload File'">
                </p-fileUpload>
                <ul *ngIf="agentsFiles.length" style="padding-left: 0px;">
                    <li *ngFor="let file of agentsFiles" style="list-style: none;word-break: break-all;">
                        {{file.name}} -
                        {{file.size}}
                        {{ 'setupjob.libytes' | translate }} <a href="javascript:void(0)"
                            [ariaLabel]="'clear your selected file'" (click)="clearFile(file)"><i
                                class="feather ft-x cursor-pointer ml-1"></i></a>
                    </li>

                </ul>
            </div>

            <div class="form-group d-flex align-items-center">
                <p-checkbox [(ngModel)]="checked" [binary]="true" inputId="binary"></p-checkbox>
                <label class="mb-0 ml-1">{{ 'Is main-agent' }}</label>
            </div>
        </div>

        <div class="text-end">
            

            <button class="btn btn-secondary mr-1 mt-2 text-end" (click)="close()"> Cancel </button>
            <button class="btn btn-primary mt-2 text-end"
                [disabled]="!(agentName && agentDescription && agentsFiles.length)"
                (click)="showToolSection = true">Next</button>
        </div>
    </div>
</div>






<!-- *ngIf="showToolSection"  NgClass -->

<div *ngIf="showToolSection"> 
    <h2 class="popup-title text-center">Configure Tools</h2>
    <div class="form-group">
        <label>{{ 'Selected Tool' }}<span class="mendatory">*</span></label>
        <div class="selected-tool">
            <span class="selected-tool-text" *ngFor="let tool of tools; let index as i"> {{tool['toolName']}}
                <i class="pi pi-times pointer" (click)="clearTool(i)"></i> </span>
            <span class="selected-tool-text pointer" (click)="showTool = true">{{'Add Tool '}}<i class="pi pi-plus"></i>
            </span>
        </div>
    </div>

    <div *ngIf="showTool">
        <div class="form-group">
            <label>{{ 'Select Tool' }}<span class="mendatory">*</span></label>
            <p-dropdown [filter]="true" styleClass="allDropdown" filterBy="name" [options]="toolOptions"
                optionLabel="name" optionValue="value" [(ngModel)]="selectedTool" [placeholder]="'Select Tool'">
            </p-dropdown>
        </div>

        <div class="form-group" *ngIf="selectedTool == 'RAG Tool'">
            <app-add-configure-rag-tool (ragEmitter)="ragEvent($event)"></app-add-configure-rag-tool>
        </div>
        <div class="form-group" *ngIf="selectedTool == 'API Tool'">
            <app-add-configure-api-tool (apiEmitter)="apiEvent($event)"></app-add-configure-api-tool>
        </div>
        <div class="form-group" *ngIf="selectedTool == 'Image-RAG Tool'">
            <app-add-configure-image-rag-tool
                (imageRAGEmitter)="imageRAGEvent($event)"></app-add-configure-image-rag-tool>
        </div>


    </div>

    <div class="text-end" *ngIf="!showTool">
        <button class="btn btn-secondary mt-2 text-end" (click)="showToolSection = false">Previous</button>
        <button class="btn btn-primary ml-1 mt-2 text-end" (click)="saveAgentsnTools()">Confirm </button>
    </div>
</div>