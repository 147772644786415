// Default theme settings configurations

export const ThemeSettingsConfig = {
  colorTheme: "light",
  layout: {
    style: "vertical",
    pattern: "fixed",
  },
  menuColor: "menu-light",
  navigation: "menu-collapsible",
  menu: "expand",
  header: "static",
  footer: "static",
  customizer: "on",
  chat: "on",
  headerIcons: {
    maximize: "off",
    search: "off",
    internationalization: "off",
    notification: "off",
    email: "off",
  },
  brand: {
    brand_name: '',
    logo: {
      type: "internal",
      value: "assets/images/logo/new-logo.svg",
      /*     type:'url',
       value:'google.com' */
    },
  },
  defaultTitleSuffix: 'Zero to Sixty'
};
