import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'keys'
})
export class KeysPipe implements PipeTransform {
    transform(value: any): any {
        if (value === undefined || value === null) {
            return []; // or handle appropriately for your use case
        }
        return Object.keys(value);
    }
}
