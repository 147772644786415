import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Toast } from 'src/app/_helpers/toast';
import { AuthService } from 'src/app/_services/auth.service';
import { AutoDashboardService } from 'src/app/_services/auto-dashboard.service';
import { DbConnectionsService } from 'src/app/_services/db-connections.service';
import { SyntheticDataService } from 'src/app/_services/synthetic-data.service';

@Component({
  selector: 'app-data-driven-details',
  templateUrl: './data-driven-details.component.html'
})
export class DataDrivenDetailsComponent implements OnChanges {

  constructor(
    private syntheticDataService: SyntheticDataService,
    private authService: AuthService,
    private autoDashService: AutoDashboardService,
    private dbConnectionsService: DbConnectionsService
  ) {

  }

  ngOnInit() {
    this.fetchJobDashRelation();
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('ddd', this.jobDetails)
  }

  displayModal: boolean = false;
  prompt = ''
  overallDescription: string = '';
  columns = [];
  loading: boolean = true;
  @Input() jobId;
  @Input() jobDetails;
  @Input() projectId
  tables = [];
  configure : boolean = false;
  renderData = []
  selectedColumns: any[] = [];
  result = {}
  dataLoaded: boolean = false;
  errorMessage: string = '';
  isSubmitting: boolean = false;

  fetchDashboardDetails(id) {
    this.loading = true;
    this.autoDashService.getDashboardInfo({ user_id: this.authService.currentUserValue.id, dashboard_id: id }).subscribe({
      next: res => {
        this.result['dashboard_id'] = id
        this.result['response'] = res['plot_info']
        this.handleResult();
        console.log('df', res)
        this.loading = false;
        this.dataLoaded = true;
      }, error: err => {
        this.loading = false;
        this.errorMessage = 'Failed to fetch dashboard details. Please try again.';
      }
    })
  }

  fetchJobDashRelation() {
    this.loading = true;
    this.autoDashService.getDashJob(this.jobId).subscribe({
      next: res => {
        console.log('r', res)
        if (res.length) {
          this.configure = false;
          this.fetchDashboardDetails(res[0]['dashboardId'])
        } else {
          this.configure = true;
          this.fetchColumnData();
        }
      }, error: err => {
        this.loading = false;
        this.errorMessage = 'Failed to fetch job-dashboard relation. Please try again.';
      }
    })
  }

  submit() {
    this.isSubmitting = true;
    console.log('Overall Description:', this.overallDescription);
    console.log('Columns Data:', this.columns);
    this.dbConnectionsService.getDBConnections({ project_id: this.projectId, org_id: this.authService.currentUserValue.orgId, id: this.jobDetails['source_connection'] }).subscribe({
      next: (res: any) => {
        console.log('conn', res)
        this.autoDashService.saveAndCreateDashboard({
          user_id: this.authService.currentUserValue.id,
          general_query: this.prompt,
          overall_description: this.overallDescription,
          query_by_col: {},
          connector_details: res[0]
        }).subscribe({
          next: finRES => {
            this.result = finRES
            if (this.result && this.result['dashboard_id']) {
              this.autoDashService.postDashJob({jobId: this.jobId, dashboardId: this.result['dashboard_id']}).subscribe({
                next: resAutoDash => {
                  console.log('res', resAutoDash)
                  this.configure = false;
                  this.handleResult();
                  this.displayModal = true;
                  this.isSubmitting = false;
                }, error: err => {
                  this.isSubmitting = false;
                  // Handle error
                }
              })
            } else {
              this.isSubmitting = false;
            }
          }, error: err => {
            this.isSubmitting = false;
            // Handle error
          }
        })
      },
      error: err => {
        this.isSubmitting = false;
        Toast.fire({
          icon: 'error',
          html: err.error.text
        });
      }
    })
    
    console.log('r', this.result)
  }

  fetchColumnData() {
    this.loading = true;
    this.tables = this.jobDetails[0]["source_table"].split(",")
    let completedRequests = 0;

    for (let i = 0; i < this.tables.length; i++) {
      this.syntheticDataService.getColumns({
        connection_id: this.jobDetails[0]["source_connection"],
        schema_name: this.jobDetails[0]["source_schema"],
        table_name: this.tables[i],
      }).subscribe({
        next: res => {
          console.log('tb', res)
          let cols = []
          for (let j = 0; j < res.length; j++) {
            cols.push({ column: res[j], dataType: '-', description: '' })
          }
          this.renderData.push({
            tableName: this.tables[i],
            column: cols
          })
          console.log('re', this.renderData)
          completedRequests++;
          if (completedRequests === this.tables.length) {
            this.fetchDescription();
          }
        }, error: err => {
          this.loading = false;
          this.errorMessage = 'Failed to fetch column data. Please try again.';
        }
      })
    }
  }

  colDes: any = []
  fetchDescription() {
    this.syntheticDataService.getColDescriptions({ org_id: this.authService.currentUserValue.orgId, job_id: this.jobId, project_id: this.projectId }).subscribe({
      next: res => {
        this.colDes = res
        this.updateDescriptions()
      }, error: err => {

      }
    })
  }

  updateDescriptions() {
    console.log('colDes:', this.colDes);
    console.log('renderData before update:', this.renderData);

    this.renderData.forEach(table => {
      let resTable = this.colDes.find(t => t.table_name === table.tableName);
      console.log('resTable:', resTable);

      if (resTable) {
        // Flatten the column_description array
        let flattenedColumns = resTable.column_description.flat();

        table.column.forEach(renderColumn => {
          let resColumn = flattenedColumns.find(resCol => resCol.column === renderColumn.column);
          console.log('resColumn:', resColumn);

          if (resColumn) {
            renderColumn.description = resColumn.description;
            renderColumn.dataType = resColumn.data_type;
          }
        });
      }
    });

    console.log('renderData after update:', this.renderData);

    if (this.renderData.length > 0) {
      this.selectedColumns = this.renderData[0].column;
    }
    this.loading = false;
    this.dataLoaded = true;
  }

  onTabChange(event) {
    // Update selectedColumns based on the selected tab
    if (this.renderData[event.index]) {
      this.selectedColumns = this.renderData[event.index].column;
    }
  }

  async handleResult() {
    if (this.result && this.result['response']) {
      // Parse the response if it's a string
      if (typeof this.result['response'] === 'string') {
        try {
          this.result['response'] = JSON.parse(this.result['response']);
        } catch (error) {
          console.error('Error parsing response:', error);
          this.errorMessage = 'Error parsing dashboard data. Please try again.';
          return;
        }
      }

      if (Array.isArray(this.result['response'])) {
        for (let i = 0; i < this.result['response'].length; i++) {
          if (typeof this.result['response'][i] == 'object') {
            console.log(this.result['response'][i]['plotType'])
            this.result['response'][i]['options'] = await this.getChartOptions(this.result['response'][i]['plotType']);
            this.result['response'][i]['type'] = await this.getChartType(this.result['response'][i]['plotType']);
          }
        }
        console.log('fin', this.result)
      } else {
        console.error('Response is not an array:', this.result['response']);
        this.errorMessage = 'Invalid dashboard data format. Please try again.';
      }
    }
  }

  async getChartOptions(plotType: string) {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
    switch (plotType.toLocaleLowerCase()) {
      case 'pie':
        console.log('pie opt')
        return {
          plugins: {
            legend: {
              labels: {
                usePointStyle: true,
                color: textColor
              }
            }
          }
        }
      case 'doughnut':
        return {
          cutout: '60%',
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          }
        }
      case 'vertical bar':
        return {
          maintainAspectRatio: false,
          aspectRatio: 0.8,
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: textColorSecondary,
                font: {
                  weight: 500
                }
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            },
            y: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            }

          }
        }
      case 'horizontal bar':
        return {
          indexAxis: 'y',
          maintainAspectRatio: false,
          aspectRatio: 0.8,
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: textColorSecondary,
                font: {
                  weight: 500
                }
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            },
            y: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            }
          }
        }
      case 'stacked bar':
        return {
          maintainAspectRatio: false,
          aspectRatio: 0.8,
          plugins: {
            tooltips: {
              mode: 'index',
              intersect: false
            },
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            x: {
              stacked: true,
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            },
            y: {
              stacked: true,
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            }
          }
        }
      case 'line':
        return {
          maintainAspectRatio: false,
          aspectRatio: 0.6,
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            },
            y: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            }
          }
        }
      case 'multiaxis':
        return {
          stacked: false,
          maintainAspectRatio: false,
          aspectRatio: 0.6,
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder
              }
            },
            y: {
              type: 'linear',
              display: true,
              position: 'left',
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder
              }
            },
            y1: {
              type: 'linear',
              display: true,
              position: 'right',
              ticks: {
                color: textColorSecondary
              },
              grid: {
                drawOnChartArea: false,
                color: surfaceBorder
              }
            }
          }
        }
      case 'polar area':
        return {
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            r: {
              grid: {
                color: surfaceBorder
              }
            }
          }
        }
      case 'radar':
        return {
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            r: {
              grid: {
                color: textColorSecondary
              },
              pointLabels: {
                color: textColorSecondary
              }
            }
          }
        }
      case 'combo':
        return {
          maintainAspectRatio: false,
          aspectRatio: 0.6,
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder
              }
            },
            y: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder
              }
            }
          }
        }
      default:
        console.log('default')
        return {}
    }
  }

  async getChartType(type) {
    switch (type.toLocaleLowerCase()) {
      case 'bar' || 'vertical bar' || 'horizontal bar' || 'stacked bar':
        return 'bar'
      case 'line' || 'multiaxis' || 'combo':
        return 'line'
      case 'pie':
        return 'pie'
      case 'doughnut':
        return 'doughnut'
      case 'polar area':
        return 'polarArea'
      case 'Radar':
        return 'radar'
      default:
        return 'line'
    }
  }

}
