import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OspService {
  private NodeAPIUrl = environment.apiAuthUrl;

  constructor(
    private http: HttpClient
  ) { }

  predictOSP(body) {
    return this.http.post<any>(`${this.NodeAPIUrl}/p/osp`, {
      method: 'POST',
      path: '/osp_predict_p',
      body: body
    })
  }

  predictOSPWires(body) {
    return this.http.post<any>(`${this.NodeAPIUrl}/p/osp`, {
      method: 'POST',
      path: '/osp_detect_wires',
      body: body
    })
  }

  createGeoJSON(body) {
    return this.http.post<any>(`${this.NodeAPIUrl}/geo-spatial-data`, body)
  }

  fetchGeoJSON() {
    return this.http.get<any>(`${this.NodeAPIUrl}/geo-spatial-data`)
  }
}
