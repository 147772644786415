export enum dataTpes {
    BIGINT = "Integer",
    NVARCHAR = "String",
    VARCHAR = "String",
    DATE = "String",
    BOOLEAN = "Boolean",
    INTEGER = "Integer",
    string = "String",
    int64 = "Integer",
    float64 = "Integer",
    FLOAT = "Double"
}