import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { WizardComponent } from 'angular-archwizard';
import { LoanRequest } from 'src/app/_helpers/schemas';
import { Toast } from 'src/app/_helpers/toast';
import { AuthService } from 'src/app/_services/auth.service';
import { DocumentationSlideService } from 'src/app/_services/documentation-slide.service';
import { RuleService } from 'src/app/_services/rule.service';
import { SyntheticDataService } from 'src/app/_services/synthetic-data.service';
import Swal from 'sweetalert2';
import { AddJobComponent } from '../add-job/add-job.component';

@Component({
  selector: 'app-rule-handler',
  templateUrl: './rule-handler.component.html',
})
export class RuleHandlerComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

  @Input() jobDetails: Object = {};
  @Input() tableInfo;
  @Input() fileJob: Object = {};
  ruleForm: FormGroup;
  droppedItems: string;
  selectRule: string;
  @Input() projectId: string;
  rulesList: Object[] = [];
  ruleAttributes: Object[] = [];
  showRuleTest: boolean = false;
  testData;
  @Output() ruleEmitter = new EventEmitter();
  showCreateRuleModal: boolean = false;
  showCreateRule: boolean = false;
  jsonData: string;
  editRuleEvent: boolean = false;
  @ViewChild(WizardComponent)
  public wizard: WizardComponent;
  _parent;

  constructor(
    private ruleService: RuleService,
    private authService: AuthService,
    private syntheticDataService: SyntheticDataService,
    private documentSlideService: DocumentationSlideService,
    private viewContainerRef: ViewContainerRef,
  ) {
    this.ruleForm = new FormGroup({
      selectRule: new FormControl(null, Validators.required),
      outputTableName: new FormControl(null),
    });
    const _injector = this.viewContainerRef.injector;
    const _parent: AddJobComponent = _injector.get<AddJobComponent>(AddJobComponent);
    this._parent = _parent;

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['fileJob']) {
      if (this.fileJob && Object.keys(this.fileJob).length != 0) {
        this.emitAddRuleEvent();
      }
    }
  }
  receiveBooleanFromChild(event) {
    this.showCreateRuleModal = false;
    this.fetchDRLRules();
    this.showCreateRule = false;
  }
  async ngOnInit() {
    // await this.fetchRules(null)
    await this.fetchDRLRules();
    // console.log(this.tableInfo, 'tableInfo')
    if (this.fileJob && Object.keys(this.fileJob).length != 0) {
      this.emitAddRuleEvent();
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  onDrop(event: DragEvent, index: number) {
    event.preventDefault();
    const item = event.dataTransfer?.getData('text/plain');
    if (item) {
      // this.droppedItems = item;
      if (index >= 0 && index < this.ruleAttributes.length) {
        const attributeKey = Object.keys(this.ruleAttributes[index])[0];
        this.ruleAttributes[index][attributeKey] = item;
      }
    }
  }

  async inputFieldHandler() {
    // Intentional Commented 
    // await this.fetchRules(this.selectRule)
  }

  removeDragItem(att, i) {
    const keys = Object.keys(att);
    this.ruleAttributes[i][keys[0]] = ""
  }

  async fetchRules(id) {
    const currentUser = this.authService.currentUserValue;
    let params = {}
    if (id) {
      params = { org_id: currentUser.orgId, id: id }
    } else {
      params = { org_id: currentUser.orgId }
    }
    this.ruleService.getRules(params).subscribe({
      next: res => {
        if (id) {
          if (res && res[0] && res[0]['url'] && (res[0]['url'] == '/cdpe_sahl_ruleengine')) {
            this.ruleAttributes = LoanRequest.map(obj => ({ ...obj }));
          } else {

          }
        } else {
          if (res.length > 0) {
            res.forEach(element => {
              this.rulesList.push({
                name: element.rule_name,
                value: element.id,
                url: element.url
              })
            });
          }
        }
      }, error: err => {

      }
    })
  }

  async fetchDRLRules() {
    const currentUser = this.authService.currentUserValue;
    if (this.jobDetails && this.jobDetails['sourceType'] == "database") {
      this.ruleService.getRules({ org_id: currentUser.orgId, tableName: this.tableInfo['tableName'] }).subscribe({
        next: res => {
          if (res && res.map) {
            this.rulesList = res.map(element => {
              if (element.tableName == this.tableInfo['tableName']) {
                return {
                  name: element.ruleName,
                  value: element.id,
                  ...element
                };
              } else {
                return undefined;
              }
            }).filter(val => val != undefined);
          }

        }
      });
    }

    if (this.jobDetails && this.jobDetails['sourceType'] != "database") {

      if (this.fileJob && this.fileJob['job_id']) {

        let params = {
          job_id: this.fileJob["job_id"]
        }

        this.syntheticDataService.getColumnTypes(params).subscribe({
          next: res => {
            if (res && res.length > 0) {
              let isNestedColumn = false;

              for (let ind = 0; ind < res.length; ind++) {
                let column = res[ind];
                if (column && column.column_name && column.column_name.indexOf(".") != -1) {
                  isNestedColumn = true;
                  break;
                }
              }

              if (isNestedColumn) {

                Swal.fire({
                  title: 'Rule configuration does not support nested structures. Please click "Back" to upload a flattened file.',
                  showDenyButton: false,
                  confirmButtonText: 'Back',
                  denyButtonText: `No`,
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.documentSlideService.customDocumentName("AddJob");
                    if (this.wizard) {
                      this.wizard.goToPreviousStep();
                    }

                    if (this._parent) {
                      this._parent.wizard.goToPreviousStep();
                    }

                  }
                });

              }

            }
          },
          error: err => {
            Toast.fire({
              icon: 'error',
              html: err
            });
          }

        });


      }
      this.ruleService.getDRLFileRules({ org_id: currentUser.orgId, tableName: 'File' }).subscribe({
        next: res => {
          if (res && res.map) {
            this.rulesList = res.map(element => {
              return {
                name: element.ruleName,
                value: element.id,
                ...element
              };
            });
          }

        },
        error: err => {
          Toast.fire({
            icon: 'error',
            html: err
          });
        }
      });
    }


  }

  ruleTest() {
    let tableData = {
      rule_id: this.ruleForm.get('selectRule').value,
      input_fields: this.ruleAttributes
    }
    let body = {
      locale: this.jobDetails['locale'],
      source_connection: this.jobDetails['selectSource']['id'],
      source_database: this.jobDetails['selectSource']['database_name'],
      source_schema: this.jobDetails['selectSchema'],
      source_table: this.tableInfo['tableName'],
      table_data: tableData,
      columns_data: null
    }
    this.syntheticDataService.syntheticTestData(body).subscribe({
      next: res => {
        this.testData = res['message'];
        this.showRuleTest = true;
      }, error: err => {
        Toast.fire({
          icon: 'error',
          html: err
        });
      }
    })
  }

  close() {
    this.showRuleTest = false
    this.showCreateRuleModal = false;
  }

  saveTest() {
    let tableData = {
      rule_id: this.ruleForm.get('selectRule').value,
      input_fields: this.ruleAttributes,
      output_table_name: this.ruleForm.get('outputTableName').value,
      table: this.tableInfo
    }
    Toast.fire({
      icon: 'success',
      title: 'Success',
      html: 'Rule Configured Successfully'
    });
    this.ruleEmitter.emit(tableData)
  }

  addRuleEmitter(event) {
    if (event == 'Inserted successfully') {
      Toast.fire({
        icon: 'success',
        title: 'Success',
        html: 'Rule Added Successfully'
      });
      this.showCreateRuleModal = false;
      this.fetchDRLRules()
    } else {
      Toast.fire({
        icon: 'success',
        html: event
      });
    }
  }

  clearForm() {
    this.ruleForm.reset()
  }

  editRule(rule) {
    this.ruleService.getDRL(rule).subscribe({
      next: (ruleData: any) => {
        if (ruleData) {
          this.jsonData = ruleData;
          this.showCreateRule = true;
          this.editRuleEvent = true;

        }
      },
      error: err => {
        Toast.fire({
          icon: 'error',
          html: err
        });
      }
    })
  }

  deleteRule(rule) {
    Swal.fire({
      title: 'Are you sure you want to delete this Rule?',
      showDenyButton: true,
      confirmButtonText: 'Delete',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        const currentUser = this.authService.currentUserValue;
        let selectedRule = this.rulesList.find(rul => rul['value'] == rule)
        this.ruleService.deleteDRLFileRules({ project_id: this.projectId, ids: selectedRule['value'], org_id: currentUser['orgId'] })
          .subscribe({
            next: res => {
              Toast.fire({
                icon: 'success',
                title: 'Success',
                html: res && res.message ? res.message : ''
              });
              this.showCreateRuleModal = false;
              this.fetchDRLRules();
            },
            error: err => {
              Toast.fire({
                icon: 'error',
                html: err
              });
              this.showCreateRuleModal = false;
              this.fetchDRLRules();
            }
          })
      }
    });

  }

  emitAddRuleEvent() {
    this.showCreateRule = true;
    this.editRuleEvent = false;
  }

  ngOnDestroy(): void {
    console.log("On Destroy");
  }

  ngAfterViewInit(): void {
    console.log("On ngAfterViewInit");
  }






}
