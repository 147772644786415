<h2 class="popup-title">{{ 'privacypolicy.popuptitle' | translate }}</h2>
<p *ngFor="let paragraph of 'privacypolicy.paragraphs' | translate ">
  {{ paragraph }}
</p>
<h1>{{ 'privacypolicy.informationheader' | translate }}</h1>
<p>
  {{ 'privacypolicy.infopara1' | translate }}
</p>
<div>
  <p *ngFor="let paragraph of 'privacypolicy.infopara2' | translate ">
    {{ paragraph }}
  </p>

</div>
<h1>{{ 'privacypolicy.logdataheader' | translate }}</h1>
<p>
  {{ 'privacypolicy.logpara' | translate }}
</p>
<h1>{{ 'privacypolicy.cookiesheader' | translate }}</h1>
<p *ngFor="let paragraph of 'privacypolicy.cookiespara' | translate ">
  {{ paragraph }}
</p>
<h1>{{ 'privacypolicy.serviceprovidersheader' | translate }}</h1>
<p>
  {{ 'privacypolicy.providerspara1' | translate }}
</p>
<ul>
  <li *ngFor="let listItem of 'privacypolicy.providerslistItems' | translate ">{{ listItem }}</li>
</ul>
<p>
  {{ 'privacypolicy.providerspara2' | translate }}
</p>
<h1>{{ 'privacypolicy.securityheader' | translate }}</h1>
<p>
  {{ 'privacypolicy.securitypara' | translate }}
</p>
<h1>{{ 'privacypolicy.linktositesheader' | translate }}</h1>
<p>
  {{ 'privacypolicy.linktositepara' | translate }}
</p>
<h1>{{ 'privacypolicy.childrenpolicyheader' | translate }}</h1>
<p>
  {{ 'privacypolicy.childrenpolicypara' | translate }}
</p>
<h1>{{ 'privacypolicy.changespolicyheader' | translate }}</h1>
<p *ngFor="let paragraph of 'privacypolicy.changespolicypara' | translate ">
  {{paragraph}}
</p>
<h1>{{ 'privacypolicy.contactusheader' | translate }}<h1>
    <p>
      {{ 'privacypolicy.contactuspara' | translate }}
    </p>