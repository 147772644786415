import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Toast } from 'src/app/_helpers/toast';
import { AuthService } from 'src/app/_services/auth.service';
import { CustomStrategyService } from 'src/app/_services/custom-strategy.service';
import { RuleService } from 'src/app/_services/rule.service';
import { SyntheticDataService } from 'src/app/_services/synthetic-data.service';

@Component({
    selector: 'app-confirm-job',
    templateUrl: './confirm-job.component.html'
})
export class ConfirmJobComponent implements OnInit, OnChanges {

    @Input() columnsData: Object[] = [];
    @Input() jobDetails: Object = {};
    @Input() selectedColumnDetails: Object = {};
    displayColumnData: Object = {};
    tabIndex = 0;
    occurenceCol: Object = {};
    occurence: Object = {};
    displayOccurence: boolean = false;
    showData: boolean = false;
    limitForm: FormGroup;
    @Output() rowLimit = new EventEmitter();
    highlightedIndex = 0;
    @Input() showRuleTest: boolean = false;
    @Input() ruleData: Object;
    @Input() projectId: string
    ruleName: string = "";
    @Input() tableInfo: {};
    currentTabIndex: number = -1;
    @Input() fileJob: {};
    displayCheckBias: boolean = false;
    showBias: boolean = false;
    @Input() step: string;
    @Output() biasEmitterHandler = new EventEmitter()
    rowCount: number;
    fetchedRowCount = '';
    @Input() anomalyData = [];
    dataArray = [];
    showAnomalyData = {};
    @Output() modelTypeEvent = new EventEmitter();
    modelType: string;
    @Input() routeType = ''
    @Input() editJobProfile = {}
    @Input() isEdit = false;

    constructor(
        private syntheticDataService: SyntheticDataService,
        private customStrategyService: CustomStrategyService,
        private ruleService: RuleService,
        private authService: AuthService,
        private router: Router,
    ) { }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        // this.fetchFileRowCount();
        await this.biasDisplayHandler();

        if (changes) {
            // if (!this.showRuleTest) {
            this.fetchRowCount();
            // }
        }

        if (this.step === 'third') {
            await this.biasDisplayHandler();
        }

        if (changes && changes.fileJob) {
            this.fetchFileRowCount();
            this.displayColumnData = this.columnsData[0];
        }

        if (changes && changes.ruleData && this.ruleData && this.ruleData['rule_id']) {
            this.fetchRule(this.ruleData['rule_id']);
        }

        if (this.isEdit && this.editJobProfile) {
            this.limitForm.get('limit').patchValue(this.editJobProfile['row_limit']);
        }

        if (this.anomalyData) {

            const anomalies = this.anomalyData['anomalies'];
            const columnData = this.anomalyData['columnData'];

            if (anomalies && columnData) {
                const numericKeys = Object.keys(anomalies?.changes?.numeric || {});
                const categoricalKeys = Object.keys(anomalies?.changes?.categorical || {});
                const targetValue = anomalies?.target;
                const sourceColumnValue = columnData[0]?.source_column;
                if (numericKeys.length > 0 || categoricalKeys.length > 0 || targetValue !== undefined || sourceColumnValue !== undefined) {
                    this.dataArray = [...numericKeys, ...categoricalKeys, targetValue, sourceColumnValue];
                }
                this.showAnomalies(this.dataArray[0], 0)
            }
        }
    }

    showAnomalies(col, r) {
        if (this.highlightedIndex !== -1) {
            this.highlightedIndex = r;
        }
        this.highlightedIndex = r;
        this.showData = true;
        const anomalies = this.anomalyData['anomalies'];
        const columnData = this.anomalyData['columnData'];
        this.showAnomalyData = {}
        if (anomalies && columnData) {
            if (anomalies['changes'] && anomalies['changes']['numeric'] && anomalies['changes']['numeric'][col]) {
                this.showAnomalyData['value'] = anomalies['changes']['numeric'][col]
                this.showAnomalyData['type'] = 'numeric'
            } else if (anomalies['changes'] && anomalies['changes']['categorical'] && anomalies['changes']['categorical'][col]) {
                this.showAnomalyData['value'] = anomalies['changes']['categorical'][col]
                this.showAnomalyData['type'] = 'categorical'
            } else if (anomalies['target'] == col) {
                this.showAnomalyData['type'] = 'Anomaly Class Column'
            } else {
                let index = -1;

                if (Array.isArray(columnData) && columnData.length) {
                    for (let i = 0; i < columnData.length; i++) {
                        if (columnData[i]?.source_column === col) {
                            index = i;
                            break; // exit the loop once the index is found
                        }
                    }
                }

                if (index !== -1) {
                    this.showAnomalyData['value'] = columnData[index]
                    this.showAnomalyData['type'] = 'column'
                }
            }
        }
    }

    async biasDisplayHandler() {
        const currentUser = this.authService.currentUserValue;
        if (Array.isArray(this.columnsData) && this.columnsData.length) {
            let b = 0
            for (let i = 0; i < this.columnsData.length; i++) {
                if (this.columnsData[i]['columnlet_type'] == 'NA' && Array.isArray(this.columnsData[i]['columnlets_data']) && this.columnsData[i]['columnlets_data'][0]['semantic_group'] == 'categorical') {
                    let result = await this.customStrategyService.getStrategiesAsync({
                        locale: this.jobDetails['locale'],
                        project_id: this.projectId,
                        org_id: currentUser.orgId,
                        id: this.columnsData[i]['columnlets_data'][0]['strategy_id'],
                        isbias: true
                    })
                    if (Array.isArray(result) && result.length > 0 && (result[0]['strategy_name'] == 'CTGAN Neural Network' || result[0]['strategy_name'] == 'CopulaGAN Neural Network')) {
                        b += 1
                        if (b > 1) {
                            this.showBias = true;
                            break;
                        }
                    }
                }
            }
        }
    }

    fetchFileRowCount() {
        if (this.fileJob && this.fileJob['job_id']) {
            this.syntheticDataService.getFileRowCount({ job_id: this.fileJob['job_id'] }).subscribe({
                next: res => {
                    if (this.routeType == 'anomaly') {
                        this.rowLimit.emit('1');
                        this.fetchedRowCount = '1'
                        if (!this.isEdit) {
                            this.limitForm.get('limit').patchValue('1');
                        }
                    } else {
                        res['row_count'] = res['row_count'] == 0 ? 1 : res['row_count']
                        this.fetchedRowCount = res['row_count']
                        this.rowLimit.emit(res['row_count']);
                        if (!this.isEdit) {
                            this.limitForm.get('limit').patchValue(res['row_count']);
                        }
                    }
                }, error: err => {

                }
            })
        }
    }

    ngOnInit(): void {
        if (this.columnsData.length > 0) {
            this.displayColumnData = this.columnsData[0]
            this.showData = true;
        }

        this.limitForm = new FormGroup({
            limit: new FormControl(null, Validators.required),
        });

    }

    showOccurence(col, r) {
        if (this.highlightedIndex !== -1) {
            this.highlightedIndex = r;
        }
        this.highlightedIndex = r;
        this.displayColumnData = this.columnsData[r];
        this.occurenceCol = col;
        this.showData = true;
        if (!(this.fileJob && this.fileJob['job_id'])) {
            let body = {
                connection_id: this.jobDetails['selectSource']['id'],
                schema_name: this.jobDetails['selectSchema'],
                table_name: this.selectedColumnDetails['table']['tableName'],
                column_name: col['source_column'],
                requesting_relationship: 'child'
            };
            this.syntheticDataService.getColumnRelations(body).subscribe({
                next: (res) => {
                    this.occurence['child'] = res;
                    body['requesting_relationship'] = 'parent';
                    this.syntheticDataService.getColumnRelations(body).subscribe({
                        next: (res) => {
                            this.occurence['parent'] = res;
                        },
                        error: (err) => {
                            Toast.fire({
                                icon: 'error',
                                html: err.error.text,
                            });
                        },
                    });
                },
                error: (err) => {
                    Toast.fire({
                        icon: 'error',
                        html: err.error.text,
                    });
                },
            });
        }
        this.tabDecider(0)
    }


    tabDecider(i) {
        this.tabIndex = i
    }

    navigate(direction: string, currentIndex: number): void {
        const newIndex = direction === 'down' ? currentIndex + 1 : currentIndex - 1;
        const totalItems = this.columnsData.length;
        const wrappedIndex = (newIndex + totalItems) % totalItems;
        const element = document.querySelector(`li.cursor-pointer[tabindex="${wrappedIndex}"]`) as HTMLElement;
        if (element) {
            element.focus();
        }
    }

    navigateToDiv(divId: string): void {
        const element = document.getElementById(divId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
            setTimeout(() => {
                element.setAttribute('tabIndex', '0');
                element.focus();
            }, 500);
        }
    }

    fetchRowCount() {
        if (this.jobDetails['selectSource'] && this.jobDetails['selectSource']['id'] && this.jobDetails['selectSchema'] && ((this.selectedColumnDetails['table'] && this.selectedColumnDetails['table']['tableName']) || this.tableInfo['tableName'])) {
            this.syntheticDataService.getRowCount({ connection_id: this.jobDetails['selectSource']['id'], schema_name: this.jobDetails['selectSchema'], table_name: this.selectedColumnDetails['table'] && this.selectedColumnDetails['table']['tableName'] ? this.selectedColumnDetails['table']['tableName'] : this.tableInfo['tableName'] }).subscribe({
                next: res => {
                    if (this.routeType == 'anomaly') {
                        this.rowLimit.emit('1');
                        this.fetchedRowCount = '1'
                        this.limitForm.get('limit').patchValue('1');
                    } else {
                        this.rowLimit.emit(res['Rows count']);
                        this.fetchedRowCount = res['Rows count']
                        if (!this.isEdit) {
                            this.limitForm.get('limit').patchValue(res['Rows count']);
                        }
                    }
                }, error: err => {
                    Toast.fire({
                        icon: 'error',
                        title: 'Error',
                        html: err.message
                    });
                }
            })
        }
    }

    setUpLimit() {
        const url = 'https://docs.0to60.ai/jobs/confirm-synthesis-strategy';
        window.open(url, '_blank', 'noopener,noreferrer');
    }

    setUpRuleLimit() {
        // this.documentSlideService.toggleCustomizer("limits");
    }

    limitEvent() {
        if (this.routeType != 'anomaly') {
            if (this.limitForm['value']['limit'] > this.fetchedRowCount) {
                if (this.selectedColumnDetails['table']['columns'].length === this.columnsData.length) {
                    this.rowLimit.emit(this.limitForm['value']['limit'])
                } else {
                    Toast.fire({
                        icon: 'warning',
                        html: 'Configure every column before increasing the row count.'
                    });
                    if (!this.isEdit) {
                        this.limitForm.get('limit').patchValue(this.fetchedRowCount);
                    }
                }
            } else {
                this.rowLimit.emit(this.limitForm['value']['limit'])
            }
        } else {
            this.rowLimit.emit(this.limitForm['value']['limit'])
        }
    }


    fetchRule(id) {
        const currentUser = this.authService.currentUserValue;
        let params = { org_id: currentUser.orgId, id: id, tableName: this.tableInfo['tableName'] }
        this.ruleService.getDRLFileRules(params).subscribe({
            next: res => {
                if (res && res[0]) {
                    this.ruleName = res[0]['ruleName']
                }
            }
        })
    }

    biasEvent(event) {
        this.biasEmitterHandler.emit(event)
        this.displayCheckBias = false;
    }

    anomalyObjectProps() {
        return Object.keys(this.anomalyData).length
    }

    modelSelection() {
        this.modelTypeEvent.emit(this.modelType)
    }
}
