<div class="analytix-box">
    <div class="d-flex justify-content-between align-items-center">
        <h4>Account Payables</h4>
        <i class="feather ft-message-square msg-icon"
            (click)="invokeChatSliding('cfo-chat')"></i>
    </div>
    <div class="dashboard-info d-flex">
        <div class="dashboard-filter">
            <label for="" class="label-control">Supplier Name</label>
            <span class="p-fluid">
                <p-dropdown (onChange)="changeInFilter($event, 'supplierName')"
                    [(ngModel)]="supplierName" styleClass="allDropdown" appendTo="body"
                    filterBy="name" [placeholder]="'Select Supplier'"
                    [options]="supplierNameOptions" optionLabel="name" optionValue="value">
                </p-dropdown>
            </span>
        </div>
       
    </div>

    <div class="loader-wrapper circular-spinners text-center"
        *ngIf="!apDetails || !apDetails['cards']; else apDashboard">
        <i class="pi pi-spin pi-spinner"></i>
    </div>

    <ng-template #apDashboard>
        <div class="dashboard-info d-flex">
            <div class="dashboard-card"
                *ngIf="apDetails && apDetails['cards'] && apDetails['cards']['No of Customers']">
                <h5>No of Suppliers</h5>
                <p>{{apDetails['cards']['No of Customers'] }}</p>
            </div>
            <div class="dashboard-card"
                *ngIf="apDetails && apDetails['cards'] && apDetails['cards']['Outstanding']">
                <h5>Outstanding</h5>
                <p>{{apDetails['cards']['Outstanding'] | currency: jobDetails[0]['locale']+ ' '
                    :'symbol':'1.0-0' }}</p>
            </div>
            <div class="dashboard-card"
                *ngIf="apDetails && apDetails['cards'] && apDetails['cards']['Overdue']">
                <h5>Overdue</h5>
                <p>{{apDetails['cards']['Overdue'] | currency: jobDetails[0]['locale']+ ' '
                    :'symbol':'1.0-0' }}</p>
            </div>
            <div class="dashboard-card"
                *ngIf="apDetails && apDetails['cards'] && apDetails['cards']['Total Payables']">
                <h5>Total Payables</h5>
                <p>{{apDetails['cards']['Total Payables'] | currency: jobDetails[0]['locale']+ ' '
                    :'symbol':'1.0-0' }}</p>
            </div>
        </div>

        <div *ngIf="apChartDetails.length">
            <ng-container *ngFor="let chart of apChartDetails">
                <div
                    [ngClass]="chart['preType'] === 'chart' && chart['type'] != 'line' ? 'chart-area mt-2' : 'chart-area mt-2'">
                    <div class="d-flex justify-content-between align-items-center mb-2">
                        <h5 class="m-0" *ngIf="chart['preType'] == 'chart'">{{ chart['title'] }}
                        </h5>
                        <img class="pointer bulb-light" *ngIf="chart['preType'] != 'table'"
                            src="../../../../assets/images/lightbulb.png" width="32" height="32"
                            alt="" (click)="getInsights(chart)">
                    </div>

                    <span class="p-fluid" *ngIf="chart['preType'] == 'chart'">
                        <p-chart [type]="chart && chart['type'] ? chart['type'] : 'line'"
                            [data]="chart['datasets']" [options]="chart['options']" />
                    </span>



                    <h5
                        *ngIf="chart['preType'] == 'table' && chart['datasets'][0]['data'].length">
                        {{
                        chart['title']}}</h5>
                    <p-table
                        *ngIf="chart['preType'] == 'table' && chart['datasets'][0]['data'].length"
                        [value]="chart['datasets'][0]['data']">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>SalesMan</th>
                                <th>Category</th>
                                <th>Customer</th>
                                <th>Credit Days</th>
                                <th>Credit Limit</th>
                                <th>Overdue</th>
                                <th>Total</th>
                                <th>Within Due</th>
                                
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData>
                            <tr>
                                <td>{{ rowData['SalesMan'] }}</td>
                                <td>{{ rowData['Category'] }}</td>
                                <td>{{ rowData['Customer'] }}</td>
                                <td>{{ rowData['CRD'] }}</td>
                                <td>{{ rowData['Credit Limit'] }}</td>
                                <td>{{ rowData['Overdue'] }}</td>
                                <td>{{ rowData['Total'] }}</td>
                                <td>{{ rowData['Within_due'] }}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </ng-container>

        </div>

    </ng-template>
</div>

<p-dialog [(visible)]="displayInsights" [resizable]="false" [draggable]="false" [modal]="true" [blockScroll]="true"
[style]="{width: '55vw'}" appendTo="body">
<div class="p-dialog-titlebar">
    <button class="p-dialog-titlebar-icon p-dialog-titlebar-close" autofocus="true" aria-label="Close Dialog"
        (click)="displayInsights=false">
        <span class="pi pi-times"></span>
    </button>
</div>
<div class="row subscription-plans info-cards">
    <div class="col-12 mb-2">
        <div class="d-flex justify-content-center align-items-center">
            <h3 class="m-0 ml-1"> {{'Insights Based on ' + insightsTitle }}</h3>
        </div>
    </div>
</div>

<div *ngIf="loading" class="d-flex justify-content-center mt-5">
    <i class="pi pi-spin pi-spinner" style="font-size: 1.5rem"></i>
</div>

<ul *ngIf="!loading" style="list-style: none;">
    <li class="mb-1" *ngFor="let ins of insights"> {{ins}} </li>
</ul>
</p-dialog>