import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

export interface ForecastResponse {
  job_id: string;
  status: string;
  message: string;
}

export interface ColumnInfo {
  column_name: string;
  dtype: string;
}

export interface DataSegmentConfig {
  column: string[];
  top_n_segments: number;
}

export interface TimeIndexConfig {
  column_name: string;
  frequency: string;
}

export interface TargetConfig {
  column_name: string;
  aggregation: string;
}

export interface FeatureConfig {
  column_name: string;
  aggregation: string;
}

export interface ForecastConfigRequest {
  data_segment_config: DataSegmentConfig;
  time_index_config: TimeIndexConfig;
  target_config: TargetConfig;
  feature_config: FeatureConfig[];
}

interface SaveConfigResponse {
  status: string;
  message: string;
}

interface DataTransformation {
  replace_null_value: string;
  apply_log_transformation: number;
  drop_negative: number;
  quantile_clip: {
    upper_limit: number;
    lower_limit: number;
  };
}

interface FeatureGeneration {
  lag_feature: {
    starting_lag: number;
    ending_lag: number;
    jump: number;
  };
  rolling_feature: {
    starting_window: number;
    ending_window: number;
    jump: number;
    aggregation_type: string;
  };
}

interface TimeFeatures {
  add_year_number: boolean;
  add_month_number: boolean;
  add_day_number_in_month: boolean;
  add_day_number_in_week: boolean;
  add_week_number_in_year: boolean;
  add_is_weekend: boolean;
  add_holiday: boolean;
  holiday_country: string;
}

interface TransformationConfig {
  column_name: string;
  is_time_idx: number;
  transformation: {
    data_transformation: DataTransformation;
    feature_generation: FeatureGeneration;
    time_features: TimeFeatures;
  };
}

interface SaveTransformationResponse {
  status: string;
  message: string;
}

interface ValidationResponse {
  status: string;
  message?: string;
  details?: string;
  is_transformation_validated: boolean;
  data?: {
    [key: string]: {
      [date: string]: number | null;
    };
  };
}

interface ModelConfigRequest {
  train_test_split: string;
  future_horizon: number;
  forecast_model: string;
  metrics: string[];
  hyper_parameters: Record<string, any>;
  target_metrics: string;
}

interface ModelConfigResponse {
  status: string;
  message: string;
}

interface SubmitForecastResponse {
  status: string;
  message: string;
}

interface InferenceResponse {
  status: string;
  data: {
    dates: string[];
    predictions: number[];
    confidence_intervals?: {
      lower: number[];
      upper: number[];
    };
    metrics: {
      MAE?: number;
      MSE?: number;
      SMAPE?: number;
    };
  };
}

interface DataConfigResponse {
  data_segment_config: {
    column: string[];
    top_n_segments: number;
  };
  time_index_config: {
    column_name: string;
    frequency: string;
  };
  target_config: {
    column_name: string;
    aggregation: string;
  };
  feature_config: Array<{
    column_name: string;
    aggregation: string;
  }>;
}

interface getModelConfigResponse {
  train_test_split: string;
  future_horizon: number;
  forecast_model: string;
  metrics: string[];
  hyper_parameters: {
    n_folds: number;
    num_trials: number;
    [key: string]: any; // For any additional hyper parameters
  };
  target_metrics: string;
}

interface DataPreparationResponse {
  transformation_config: TransformationConfig[];
}

interface TrainingDetailsResponse {
  job_status: {
    job_id: string;
    created_at: string;
    status: string;
    training_done: boolean;
    training_error: string;
    hatchet_job_id: string | null;
  };
}

interface ValidationDataResponse {
  status: string;
  validation_result: {
    validation_result: {
      data: {
        [segment: string]: {
          actual: { [key: string]: number };
          forecast: { [key: string]: number };
        };
      };
      timestamps: string[];
    };
  };
}

interface TrainedModelResponse {
  status: string;
  message: string;
  data: {
    pipeline_path: string;
    pipeline_metrics: {
      [metric: string]: {
        [segment: string]: number;
      };
    };
    pipeline_hyperparams: {
      depth?: number;
      kwargs?: {
        random_strength?: number;
      };
      l2_leaf_reg?: number;
      learning_rate?: number;
      logging_level?: string;
      [key: string]: any;
    };
  };
}

// Add this interface for the selected columns response
export interface SelectedColumnInfo extends ColumnInfo {
  transformations?: any; // Add other specific properties from your API response
}

// Update the interface to include workspace and job name
export interface ForecastJobRequest {
  file: File;
  workspace_id: string;
  job_name: string;
}

// Add new interface for forecast jobs
export interface ForecastJob {
  job_id: string;
  job_name: string;
  status: string;
  created_by: string;
  created_time: string;
  created_name?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ForecastService {

  apiURL = environment.apiForecast

  constructor(private http: HttpClient) { }

  uploadAndCreateForecastJob(data: ForecastJobRequest): Observable<ForecastResponse> {
    const formData = new FormData();
    formData.append('file', data.file);

    const params = new HttpParams()
      .set('workspace_id', data.workspace_id)
      .set('job_name', data.job_name);

    return this.http.post<ForecastResponse>(
      `${this.apiURL}/forecast/upload_and_create_job`, 
      formData,
      { params }
    );
  }

  getColumns(jobId: string): Observable<ColumnInfo[]> {
    return this.http.get<ColumnInfo[]>(`${this.apiURL}/forecast/get-columns/${jobId}`);
  }

  getSelectedColumns(jobId: string): Observable<SelectedColumnInfo[]> {
    return this.http.get<SelectedColumnInfo[]>(
      `${this.apiURL}/forecast/get-selected-columns/${jobId}`
    ).pipe(
      catchError(error => {
        console.error('Error getting selected columns:', error);
        throw error;
      })
    );
  }

  saveConfiguration(jobId: string, config: ForecastConfigRequest): Observable<SaveConfigResponse> {
    return this.http.post<SaveConfigResponse>(
      `${this.apiURL}/forecast/save-configuration/${jobId}`,
      config
    ).pipe(
      catchError(error => {
        console.error('Error in saveConfiguration:', error);
        // You might want to add more specific error handling here
        throw error;
      })
    );
  }

  saveTransformation(jobId: string, config: TransformationConfig[]): Observable<SaveTransformationResponse> {
    return this.http.post<SaveTransformationResponse>(
      `${this.apiURL}/forecast/save-transformation/${jobId}`,
      config
    );
  }

  validateTransformation(jobId: string): Observable<ValidationResponse> {
    return this.http.put<ValidationResponse>(
      `${this.apiURL}/forecast/validate_transformation/${jobId}`,
      null
    );
  }

  addModelConfig(jobId: string, config: ModelConfigRequest): Observable<ModelConfigResponse> {
    return this.http.put<ModelConfigResponse>(
      `${this.apiURL}/forecast/add-model-config/${jobId}`,
      config
    );
  }

  submitForecastJob(jobId: string): Observable<SubmitForecastResponse> {
    return this.http.post<SubmitForecastResponse>(
      `${this.apiURL}/forecast/submit-forecast-job/${jobId}`,
      null
    );
  }

  submitInference(jobId: string, file: File): Observable<InferenceResponse> {
    const formData = new FormData();
    formData.append('inference_file', file);

    return this.http.post<InferenceResponse>(
      `${this.apiURL}/forecast/inference/${jobId}`,
      formData
    );
  }

  getDataConfig(jobId: string): Observable<DataConfigResponse> {
    return this.http.get<DataConfigResponse>(
      `${this.apiURL}/forecast/data-config/${jobId}`
    );
  }

  getDataPreparationConfig(jobId: string): Observable<DataPreparationResponse> {
    return this.http.get<DataPreparationResponse>(
      `${this.apiURL}/forecast/data-preparation-config/${jobId}`
    );
  }

  getModelConfig(jobId: string): Observable<getModelConfigResponse> {
    return this.http.get<getModelConfigResponse>(
      `${this.apiURL}/forecast/model-config/${jobId}`
    );
  }

  getTrainingDetails(jobId: string): Observable<TrainingDetailsResponse> {
    return this.http.get<TrainingDetailsResponse>(
      `${this.apiURL}/forecast/training-details/${jobId}`
    );
  }

  getValidationData(jobId: string): Observable<ValidationDataResponse> {
    return this.http.get<ValidationDataResponse>(
      `${this.apiURL}/forecast/validation-data/${jobId}`
    );
  }

  getTrainedModel(jobId: string): Observable<TrainedModelResponse> {
    return this.http.get<TrainedModelResponse>(
      `${this.apiURL}/forecast/get-trained-model/${jobId}`
    );
  }

  // Add new method to get forecast jobs
  getForecastJobs(workspaceId: string): Observable<ForecastJob[]> {
    const params = new HttpParams().set('workspace_id', workspaceId);
    return this.http.get<ForecastJob[]>(
      `${this.apiURL}/forecast/jobs`,
      { params }
    );
  }

}
