<div class="app-content content">
    <div class="content-wrapper">
        <div class="container-xxl">
            <div class="row">
                <div class="col-12">
                    <div class=" page-title mb-2">
                        <h1>{{ 'Best Trained Model Info' }}</h1>
                    </div>
                </div>
            </div>
            <div class="page-main pages oto60-chat">
                <div class="">
                    <!-- Metrics Section -->
                    <div class="metrics-section mb-3">
                        <h5 class="" style="font-size: 22px; margin-bottom: 16px;">Metrics</h5>
                        <div class="row">
                            <div class="col-md-3" *ngFor="let metricType of modelMetrics | keyvalue">
                                <div class="card" style="border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 16px;">
                                    <div class="card-body" style="padding: 25px 20px;">
                                        <h6 class="mb-2" style="font-size: 20px;">{{metricType.key}}</h6>
                                        <div class="metric-values">
                                            <div class="d-flex justify-content-between align-items-center mb-2"
                                                *ngFor="let segment of metricType.value | keyvalue">
                                                <span class="text-muted">{{segment.key}}</span>
                                                <span class="fw-bold">{{segment.value | number:'1.2-2'}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Hyperparams Section -->
                    <div class="hyperparams-section mb-3">
                        <h5 class="" style="font-size: 22px; margin-bottom: 16px;">Hyperparameters</h5>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="card" style="border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 16px;">
                                    <div class="card-body" style="    padding: 25px 20px;">
                                        <h6 class="mb-2" style="font-size: 20px;">Model Parameters</h6>
                                        <div class="d-flex justify-content-between align-items-center mb-2">
                                            <span class="text-muted">Depth</span>
                                            <span class="fw-bold">{{hyperParams?.depth}}</span>
                                        </div>
                                        <div class="d-flex justify-content-between align-items-center mb-2">
                                            <span class="text-muted">Learning Rate</span>
                                            <span class="fw-bold">{{hyperParams?.learning_rate | number:'1.4-4'}}</span>
                                        </div>
                                        <div class="d-flex justify-content-between align-items-center mb-2">
                                            <span class="text-muted">L2 Leaf Reg</span>
                                            <span class="fw-bold">{{hyperParams?.l2_leaf_reg | number:'1.4-4'}}</span>
                                        </div>
                                        <div class="d-flex justify-content-between align-items-center mb-2">
                                            <span class="text-muted">Random Strength</span>
                                            <span class="fw-bold">{{hyperParams?.kwargs?.random_strength |
                                                number:'1.4-4'}}</span>
                                        </div>
                                        <div class="d-flex justify-content-between align-items-center mb-2">
                                            <span class="text-muted">Logging Level</span>
                                            <span class="fw-bold">{{hyperParams?.logging_level}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <hr>

                <div class="prediction-section mt-3 mb-3">
                    <div class="mb-2">
                        <h5 style="font-size: 22px;">Prediction</h5>
                        <p>Upload data to get forecast on trained model</p>
                    </div>
                    <div class="upload-section">
                        <label>Upload Forecast Data</label>
                        <div class="d-flex align-items-center">
                            <p-fileUpload mode="basic" chooseLabel="Upload File" [auto]="true" accept=".csv"
                                [maxFileSize]="1000000" (onSelect)="onFileSelect($event)" [showUploadButton]="false">
                            </p-fileUpload>
                            <button class="btn btn-primary ml-2" [disabled]="!selectedFile || loading"
                                (click)="onPredict()">
                                <i class="pi pi-spin pi-spinner" *ngIf="loading"></i>
                                Predict
                            </button>
                        </div>
                    </div>
                </div>

                <hr>

                <div class="d-flex justify-content-between align-items-center mt-3 mb-3">
                    <span>
                        <h5 style="font-size: 22px;">Forecast</h5>
                        <p>Forecast Results</p>
                    </span>
                    <button class="btn btn-primary">Download</button>
                </div>

                <!-- Add segment selector above the chart -->
                <div class="row mb-3">
                    <div class="col-md-4">
                        <label class="label-control mb-2">Select Segment</label>
                        <p-dropdown [options]="segments" [(ngModel)]="selectedSegment"
                            (onChange)="onSegmentChange($event)" placeholder="Select Segment" styleClass="w-100">
                        </p-dropdown>
                    </div>
                </div>

                <!-- Chart and Table Section -->
                <div class="row mb-5">
                    <div class="col-md-8">
                        <div class="forecast-chart">
                            <div class="d-flex justify-content-between align-items-center">
                                <h5 class="forecast-table-heading">Forecast Plot - {{selectedSegment}}</h5>
                            </div>
                            <p-chart type="line" [data]="chartData" [options]="chartOptions"></p-chart>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="dashboard-card">
                            <h5 class="forecast-table-heading">Forecast Values - {{selectedSegment}}</h5>
                            <p-table [value]="forecastTableData" styleClass="p-datatable-sm"
                                [paginator]="forecastTableData.length > 10" [rows]="10">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>Date</th>
                                        <th *ngIf="!isInferenceData">Actual</th>
                                        <th>Forecast</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-data>
                                    <tr>
                                        <td>{{data.date | date:'mediumDate'}}</td>
                                        <td *ngIf="!isInferenceData">{{data.actual | number:'1.2-2'}}</td>
                                        <td>{{data.forecast | number:'1.2-2'}}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>




<!-- <div class="app-content content"></div> -->
<!-- <div class="content-wrapper"> -->
<!-- <div class="container-xxl">
        <div class="row">
            <div class="col-12 d-flex justify-content-between align-items-center">
                <div class=" page-title mb-2">
                    <h1>{{ 'Forecast Model Hub' }}</h1>
                </div>
                <button class="btn btn-primary">Train New Model</button>
            </div>
        </div> -->


<!-- <div class="row">
            <div class="col-md-4" *ngFor="let model of models">
                <div style="border: 1px solid #ccc; border-radius: 5px; padding: 15px; text-align: center;">
                    <h5 style="margin-bottom: 10px;">{{ model.name }}</h5>
                    <p style="margin: 0;">Date</p>
                    <p style="margin: 0; font-weight: bold;">{{ model.date | date:'dd-MM-yyyy' }}</p>
                    <p style="margin: 0;">Author</p>
                    <p style="margin: 0; font-weight: bold;">{{ model.author }}</p>
                    <button
                        style="margin-top: 10px; background-color: #007bff; color: white; border: none; border-radius: 5px; padding: 5px 10px;">view</button>
                </div>
            </div>
        </div> -->


<!-- </div> -->
<!-- </div> -->