import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../_services/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (sessionStorage.getItem('currentUser')) {
      // Logged in so return true
      const currentUser = this.authService.currentUserValue;
      if (currentUser) {
        if (currentUser.exp && new Date(currentUser.exp * 1000).valueOf() > new Date().valueOf()) {
          return true;
        } else {
          sessionStorage.removeItem('currentUser');
          sessionStorage.removeItem('remember');
          this.router.navigate(['/login']);
          return false;
        }
      }
      return true;
    }
    // Not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}