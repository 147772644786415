<div *ngIf="displayFindOrg && !displayInviteMembers">
    <app-find-org (nextStep)="changeStep($event)"></app-find-org>
</div>

<div class="app-content content mt-5" *ngIf="!displayFindOrg && !displayInviteMembers">
    <div class="content-wrapper">
        <div class="content-header row mb-1">
        </div>
        <div class="content-body">
            <section class="flexbox-container">
                <div class="col-12 d-flex align-items-center justify-content-center">
                    <div class="col-md-4 login-box col-10 box-shadow-2  ">
                        <div class="card login border-grey border-lighten-3   py-1 m-0">
                            <div class="d-flex justify-content-end icon-cross">
                                <i class="feather ft-x"></i>
                            </div>
                            <div class="card-content">
                                <div class="card-body">
                                    <h2>{{ 'setupform.header2setuporg' | translate }}</h2>
                                </div>

                                <form class="form-horizontal" [formGroup]="setupOrgForm"
                                    aria-label="'Setup form All required input feilds are denoted by star'">
                                    <fieldset class="form-group position-relative has-icon-left">
                                        <legend></legend>
                                        <label>{{ 'inputforms.labels.organizationname' | translate }}<span
                                                class="mendatory">*</span></label>
                                        <input type="text" formControlName="name" class="form-control" id="txtName"
                                            [placeholder]="'inputforms.placeholder.organizationname' | translate "
                                            required>
                                    </fieldset>

                                    <fieldset class="form-group position-relative has-icon-left">
                                        <legend></legend>
                                        <label>{{ 'inputforms.labels.website' | translate }}<span
                                                class="mendatory">*</span></label>
                                        <input type="text" formControlName="website" class="form-control"
                                            id="txtWebsite"
                                            [placeholder]="'inputforms.placeholder.website' | translate " required>
                                    </fieldset>

                                    <fieldset class="form-group position-relative has-icon-left">
                                        <legend></legend>
                                        <label>{{ 'inputforms.labels.address' | translate }}<span
                                                class="mendatory">*</span>
                                        </label>
                                        <textarea type="text" formControlName="address" class="form-control"
                                            id="txtAddress"
                                            [placeholder]="'inputforms.placeholder.address' | translate "
                                            required></textarea>
                                    </fieldset>

                                    <div class="card-body d-flex justify-content-center ">
                                        <button class="btn btn-raised back-btn mr-1 btn-small">
                                            <span><i class="feather ft-chevron-left"></i></span>
                                            {{ 'buttontitle.backbutton' | translate }}</button>
                                        <button type="submit" class="btn btn-raised btn-warning btn-small"
                                            (click)="setupOrg()">
                                            {{ 'buttontitle.createbutton' | translate }}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>

<div *ngIf="displayInviteMembers && !displayFindOrg" class="mt-5">
    <app-invite-team-members></app-invite-team-members>
</div>