<div class="customizer border-left-blue-grey border-left-lighten-4 d-xl-block" id="customizer">
  <a class="customizer-close" (click)="toggleCustomizer($event)"><i class="feather ft-x font-medium-3"></i></a>
  <a class="customizer-toggle box-shadow-3 hide-content-mob" (click)="toggleCustomizer($event)"><i
      class="feather ft-book-open font-medium-5"></i></a>
</div>

<p-sidebar [(visible)]="isChatOpen" position="right" [baseZIndex]="10000" [style]="{width: '40vw'}">
  <div class="customizer-content p-2" fxFlex="auto">
    <h4 class="text-uppercase mb-0">Documentation</h4>
    <hr>

    <ng-container>
      <p-accordion [activeIndex]="activeIndex">
        <p-accordionTab *ngFor="let tab of accordions" [header]="tab.header">
          <div [innerHTML]="tab.content"></div>
        </p-accordionTab>
      </p-accordion>
    </ng-container>

    <div *ngIf="showDynamicHtml">
      <div [innerHTML]="getDynamicHtml()"></div>
    </div>
  </div>
</p-sidebar>