import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AgentsService } from 'src/app/_services/agents.service';
import { AuthService } from 'src/app/_services/auth.service';

@Component({
  selector: 'app-agent-hub',
  templateUrl: './agent-hub.component.html'
})
export class AgentHubComponent {

  @Output() addAgentsEmitter = new EventEmitter()
  @Input() workflowId; 
  agentsCont = [];
  @Output() selectAgentEmitter = new EventEmitter();

  constructor (
    private agentsService: AgentsService,
    private authService: AuthService
  ) {

  }

  ngOnInit() {
    this.fetchAgents();
  }

  fetchAgents() {
    this.agentsService.getAvailableAgents(this.authService.currentUserValue.id).subscribe({
      next: res => {
        console.log('avaliable agents', res)
        this.agentsCont = res['agent_details'];
      },
      error: err => {}
    })
  }

  emitter() {
    this.addAgentsEmitter.emit({modal: true})
  }

  fetchToolDetails (id) {
    if (id) {
      this.agentsService.getToolDetailsByToolId(id).subscribe({
        next: res => {
          console.log('tool ddd', res)
        },
        error: err => {
  
        }
      })
    }
  }

  deleteAgent(agent) {
    console.log(agent)
    this.agentsService.deleteAgent(this.workflowId, agent['agent_id']).subscribe({
      next: res => {
        console.log('del', res)
      },
      error: err => {

      }
    })
  }

  selectedAgent(agent) {
    this.selectAgentEmitter.emit(agent)
  }
}
