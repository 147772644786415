<div (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)" id="main-menu"
	class="main-menu menu-fixed menu-dark menu-accordion menu-shadow" data-scroll-to-active="true">
	<div id="main-menu-content" class="main-menu-content ps-container ps-theme-light" fxFlex="auto"
		[perfectScrollbar]="config">
		<div class="side-panel-logo">
			<a [routerLink]="['/projects']" class="navbar-brand" routerLink="/dashboard">
				<img class="brand-logo" alt="Zero to Sixty logo" [src]="_themeSettingsConfig.brand.logo.value">
			</a>
		</div>
		<ul class="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">
			<!-- Menu -->
			{{child?child.title:''}}
			<li *ngFor="let child of _menuSettingsConfig.vertical_menu.items.slice(0, 10)" class="" [ngClass]="{
            'has-sub': child.submenu,
            'open': child.isOpen && child.submenu,
            'nav-item': child.title,
            'navigation-header':child.section,
            'active': child.isSelected || active[child['title']],
            'menu-collapsed-open': child.isSelected && child.submenu
               }">
				<!-- Section -->
				<span class="menu-title" *ngIf="child.section">{{child.section}}</span>
				<i class="la" *ngIf="child.section" [ngClass]="child.icon" data-toggle="tooltip" data-placement="right"
					data-original-title="Support"></i>

				<!-- Root Menu -->
				<a *ngIf="child.title && !child.submenu && !child.excludeInVertical && !child.isExternalLink && !child.issupportExternalLink && !child.isStarterkitExternalLink"
					routerLink="{{child.page !== 'null'?child.page:router.url}}"
					[fragment]="child?.fragment ? child.fragment : null" (click)="toggleMenu($event, child)">
					<i class="la" [ngClass]="child.icon"></i>
					<!-- {{ child.isSelected}} -->
					<span class="menu-title" data-i18n="">{{child.title}}</span>
				</a>
				<a *ngIf="child.title && !child.submenu && !child.excludeInVertical && child.isExternalLink"
					[href]="child.page" target="_blank" (click)="toggleMenu($event, child)">
					<i class="la" [ngClass]="child.icon"></i>
					<span class="menu-title" data-i18n="">{{child.title}}</span>
				</a>
				<a *ngIf="child.title && !child.submenu && !child.excludeInVertical && child.isStarterkitExternalLink"
					[href]="child.page" target="_blank" (click)="toggleMenu($event, child)">
					<i class="la" [ngClass]="child.icon"></i>
					<span class="menu-title" data-i18n="">{{child.title}}</span>
				</a>


				<!-- Submenu -->
				<a *ngIf="child.title && child.submenu && !child.excludeInVertical"
					routerLink="{{child.page !== 'null'?child.page:router.url}}" (click)="toggleMenu($event, child)">
					<i class="la" [ngClass]="child.icon"></i>
					<span *ngIf="orgsData && orgsData.length > 0 && orgsData[0].name" class="menu-title"
						data-i18n="">{{orgsData[0]["name"] | truncate:16}}</span>
				</a>
				<ul *ngIf="child.submenu" class="menu-content" [@popOverState]="child.isOpen">
					<!-- Submenu of Submenu -->
					<li *ngFor="let subchild of child.submenu.items" class="isShown"
						[ngClass]="{'has-sub':(subchild.submenu),'active': subchild.isSelected && !subchild.submenu, 'open': subchild.isOpen && subchild.submenu}">
						<a class="menu-item"
							*ngIf="!subchild.submenu && !subchild.excludeInVertical && orgsData && orgsData.length > 0 && orgsData[0].name"
							(click)="toggleMenu($event, subchild, true)"
							routerLink="{{subchild.page !== 'null'?subchild.page:router.url}}">{{orgsData[0]["name"]}}</a>
						<a class="menu-item" *ngIf="subchild.submenu && !subchild.excludeInVertical"
							(click)="toggleMenu($event, subchild, true)"
							routerLink="{{subchild.page !== 'null'?subchild.page:router.url}}">{{subchild.title}}</a>
						<ul *ngIf="subchild.submenu && !subchild.excludeInVertical" class="menu-content">
							<li *ngFor="let subchild of subchild.submenu.items"
								[ngClass]="{'active': subchild.isSelected && !subchild.submenu}">
								<a class="menu-item" *ngIf="!subchild.event" routerLink="{{subchild.page}}"
									(click)="toggleMenu($event, subchild, true)">{{subchild.title}}</a>
								<a class="menu-item" *ngIf="subchild.event"
									(click)="callFunction(subchild.event, subchild)">{{subchild.title}}</a>
							</li>
						</ul>
					</li>
				</ul>
			</li>
		</ul>
		<div class="pro-version-side" *ngIf="!subscriptionData">
			<div class="px-md">
				<div class="inline default text-sm font-medium">
					<h5>Try Pro</h5>
				</div>
				<div class="text">
					<p>Upgrade to access Pro features and increase processing limits.</p>
				</div>
				<div class="mt-xs">
					<button type="button" class="btn btn-secondary" (click)="priceDialog()">
						<div class="d-flex justify-center gap-xs">
							<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="arrow-up-right"
								class="svg-inline--fa" role="img" xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 384 512">
								<path fill="currentColor"
									d="M328 96c13.3 0 24 10.7 24 24V360c0 13.3-10.7 24-24 24s-24-10.7-24-24V177.9L73 409c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l231-231H88c-13.3 0-24-10.7-24-24s10.7-24 24-24H328z">
								</path>
							</svg>
							<div class="text-align-center learn-more">Learn More</div>
						</div>
					</button>
				</div>
			</div>
		</div>
		<!-- <div class="pro-version-side" *ngIf="subscriptionData!=null &&  subscriptionData?.items?.length > 0 ">
			<div class="px-md">
				<div class="inline default text-sm font-medium">
					<h5>Subscription is Active</h5>
				</div>
				<div class="text">
					<p>Click below to see the upcoming invoice</p>
				</div>
				<div class="mt-xs">
					<button type="button" class="btn btn-secondary" (click)="navigateToInvoiceDetails()">
						<div class="d-flex justify-center gap-xs">
							<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="arrow-up-right"
								class="svg-inline--fa" role="img" xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 384 512">
								<path fill="currentColor"
									d="M328 96c13.3 0 24 10.7 24 24V360c0 13.3-10.7 24-24 24s-24-10.7-24-24V177.9L73 409c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l231-231H88c-13.3 0-24-10.7-24-24s10.7-24 24-24H328z">
								</path>
							</svg>
							<div class="text-align-center learn-more">Upcoming Invoice</div>
						</div>
					</button>
				</div>
			</div>
		</div> -->
		<div class="nav-org-block">
			<div class="line" *ngFor="let child of _menuSettingsConfig.vertical_menu.items.slice(9, 11)">
				<a *ngIf="child.title && !child.submenu && !child.excludeInVertical && !child.isExternalLink && !child.issupportExternalLink && !child.isStarterkitExternalLink"
					routerLink="{{ child.page !== 'null' ? child.page : router.url }}"
					(click)="toggleMenu($event, child)" class="d-flex justify-content-between">
					<span class="menu-title" data-i18n="">{{ child.title }}</span>
					<i class="la" [ngClass]="child.icon"></i>
				</a>
				<!-- <a *ngIf="child.title && !child.submenu && !child.excludeInVertical && !child.isExternalLink && !child.issupportExternalLink && !child.isStarterkitExternalLink"
					routerLink="{{ child.page !== 'null' ? child.page : router.url }}"
					(click)="toggleMenu($event, child)" class="d-flex justify-content-between">
					<span class="menu-title" data-i18n="">{{ child.title }}</span>
					<i class="la" [ngClass]="child.icon"></i>
				</a> -->
			</div>
		</div>
	</div>
</div>

<div class="subscription">
	<p-dialog [(visible)]="priceModel" [modal]="true" [style]="{ width: '35vw' }" [draggable]="false"
		[resizable]="false" [dismissableMask]="false" [responsive]="true" [blockScroll]="true" [closeOnEscape]="true"
		[focusTrap]="true" showCloseIcon="true">
		<button class="p-dialog-titlebar-icon p-dialog-titlebar-close" aria-label="Close Dialog"
			(click)="priceModel=false">
			<span class="pi pi-times"></span>
		</button>
		<div>
			<div class="chat-logo text-center mb-3">
				<img style="width: 100px;" src="assets/images/logo/logo.svg">
			</div>

			<!-- <div class="text-center mt-5">
			<h3 class="mb-4">Subscription plans Coming Soon</h3>
			<button class="btn btn-secondary" (click)="priceModel = false">Go Back</button>
		</div> -->

			<div class="row subscription-plans info-cards">
				<div class="col-6 mb-2">
					<div class="d-flex align-items-center">
						<!-- <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="star-christmas"
							class="svg-inline--fa fa-star-christmas fa-fw fa-lg " role="img"
							xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
							<path fill="currentColor"
								d="M279.2 17.9C276.5 7.4 266.9 0 256 0s-20.5 7.4-23.2 17.9L188.3 188.3 17.9 232.8C7.4 235.5 0 245.1 0 256s7.4 20.5 17.9 23.2l170.4 44.4 44.4 170.4c2.8 10.6 12.3 17.9 23.2 17.9s20.5-7.4 23.2-17.9l44.4-170.4 170.4-44.4c10.6-2.8 17.9-12.3 17.9-23.2s-7.4-20.5-17.9-23.2L323.7 188.3 279.2 17.9zm-48 196.1l24.8-95 24.8 95c2.2 8.4 8.8 15 17.2 17.2l95 24.8-95 24.8c-8.4 2.2-15 8.8-17.2 17.2l-24.8 95-24.8-95c-2.2-8.4-8.8-15-17.2-17.2l-95-24.8 95-24.8c8.4-2.2 15-8.8 17.2-17.2zM71 71c-9.4 9.4-9.4 24.6 0 33.9l48 48c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L105 71c-9.4-9.4-24.6-9.4-33.9 0zM441 71c-9.4-9.4-24.6-9.4-33.9 0l-48 48c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l48-48c9.4-9.4 9.4-24.6 0-33.9zm0 336l-48-48c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l48 48c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9zM71 441c9.4 9.4 24.6 9.4 33.9 0l48-48c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L71 407c-9.4 9.4-9.4 24.6 0 33.9z">
							</path>
						</svg> -->
						<h3 class="m-0 ml-1">Auto ML</h3>
					</div>
					<p class="subscription-item">Advanced Auto ML features, Intricate model customization, Benchmarking,
						Monitoring, $1 per model pricing.</p>
				</div>
				<div class="col-6 mb-2">
					<div class="d-flex align-items-center">
						<!-- <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="star-christmas"
							class="svg-inline--fa fa-star-christmas fa-fw fa-lg " role="img"
							xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
							<path fill="currentColor"
								d="M279.2 17.9C276.5 7.4 266.9 0 256 0s-20.5 7.4-23.2 17.9L188.3 188.3 17.9 232.8C7.4 235.5 0 245.1 0 256s7.4 20.5 17.9 23.2l170.4 44.4 44.4 170.4c2.8 10.6 12.3 17.9 23.2 17.9s20.5-7.4 23.2-17.9l44.4-170.4 170.4-44.4c10.6-2.8 17.9-12.3 17.9-23.2s-7.4-20.5-17.9-23.2L323.7 188.3 279.2 17.9zm-48 196.1l24.8-95 24.8 95c2.2 8.4 8.8 15 17.2 17.2l95 24.8-95 24.8c-8.4 2.2-15 8.8-17.2 17.2l-24.8 95-24.8-95c-2.2-8.4-8.8-15-17.2-17.2l-95-24.8 95-24.8c8.4-2.2 15-8.8 17.2-17.2zM71 71c-9.4 9.4-9.4 24.6 0 33.9l48 48c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L105 71c-9.4-9.4-24.6-9.4-33.9 0zM441 71c-9.4-9.4-24.6-9.4-33.9 0l-48 48c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l48-48c9.4-9.4 9.4-24.6 0-33.9zm0 336l-48-48c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l48 48c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9zM71 441c9.4 9.4 24.6 9.4 33.9 0l48-48c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L71 407c-9.4 9.4-9.4 24.6 0 33.9z">
							</path>
						</svg> -->
						<h3 class="m-0 ml-1">Configure LLM</h3>
					</div>
					<p class="subscription-item">Upload documents, Images, and more, supported by the latest AI models
						like Claude 2.1 and multimodal GPT-4-Visual.</p>
				</div>
				<div class="col-6 mb-2">
					<div class="d-flex align-items-center">
						<!-- <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="star-christmas"
							class="svg-inline--fa fa-star-christmas fa-fw fa-lg " role="img"
							xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
							<path fill="currentColor"
								d="M279.2 17.9C276.5 7.4 266.9 0 256 0s-20.5 7.4-23.2 17.9L188.3 188.3 17.9 232.8C7.4 235.5 0 245.1 0 256s7.4 20.5 17.9 23.2l170.4 44.4 44.4 170.4c2.8 10.6 12.3 17.9 23.2 17.9s20.5-7.4 23.2-17.9l44.4-170.4 170.4-44.4c10.6-2.8 17.9-12.3 17.9-23.2s-7.4-20.5-17.9-23.2L323.7 188.3 279.2 17.9zm-48 196.1l24.8-95 24.8 95c2.2 8.4 8.8 15 17.2 17.2l95 24.8-95 24.8c-8.4 2.2-15 8.8-17.2 17.2l-24.8 95-24.8-95c-2.2-8.4-8.8-15-17.2-17.2l-95-24.8 95-24.8c8.4-2.2 15-8.8 17.2-17.2zM71 71c-9.4 9.4-9.4 24.6 0 33.9l48 48c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L105 71c-9.4-9.4-24.6-9.4-33.9 0zM441 71c-9.4-9.4-24.6-9.4-33.9 0l-48 48c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l48-48c9.4-9.4 9.4-24.6 0-33.9zm0 336l-48-48c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l48 48c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9zM71 441c9.4 9.4 24.6 9.4 33.9 0l48-48c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L71 407c-9.4 9.4-9.4 24.6 0 33.9z">
							</path>
						</svg> -->
						<h3 class="m-0 ml-1">Synthesize Data</h3>
					</div>
					<p class="subscription-item">Artificial generation mimicking real datasets. Enhances model training
						by diversifying input. Improves robustness and generalization. $1 per 100 rows.</p>
				</div>
				<div class="col-6 mb-2">
					<div class="d-flex align-items-center">
						<!-- <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="star-christmas"
							class="svg-inline--fa fa-star-christmas fa-fw fa-lg " role="img"
							xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
							<path fill="currentColor"
								d="M279.2 17.9C276.5 7.4 266.9 0 256 0s-20.5 7.4-23.2 17.9L188.3 188.3 17.9 232.8C7.4 235.5 0 245.1 0 256s7.4 20.5 17.9 23.2l170.4 44.4 44.4 170.4c2.8 10.6 12.3 17.9 23.2 17.9s20.5-7.4 23.2-17.9l44.4-170.4 170.4-44.4c10.6-2.8 17.9-12.3 17.9-23.2s-7.4-20.5-17.9-23.2L323.7 188.3 279.2 17.9zm-48 196.1l24.8-95 24.8 95c2.2 8.4 8.8 15 17.2 17.2l95 24.8-95 24.8c-8.4 2.2-15 8.8-17.2 17.2l-24.8 95-24.8-95c-2.2-8.4-8.8-15-17.2-17.2l-95-24.8 95-24.8c8.4-2.2 15-8.8 17.2-17.2zM71 71c-9.4 9.4-9.4 24.6 0 33.9l48 48c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L105 71c-9.4-9.4-24.6-9.4-33.9 0zM441 71c-9.4-9.4-24.6-9.4-33.9 0l-48 48c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l48-48c9.4-9.4 9.4-24.6 0-33.9zm0 336l-48-48c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l48 48c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9zM71 441c9.4 9.4 24.6 9.4 33.9 0l48-48c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L71 407c-9.4 9.4-9.4 24.6 0 33.9z">
							</path>
						</svg> -->
						<h3 class="m-0 ml-1">Rules</h3>
					</div>
					<p class="subscription-item">Enjoy create, edit, delete rules that suits for your business needs on
						your data. $5 per 100 rules.
					</p>
				</div>
				<div class="col-6 mb-2">
					<div class="d-flex align-items-center">
						<!-- <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="star-christmas"
							class="svg-inline--fa fa-star-christmas fa-fw fa-lg " role="img"
							xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
							<path fill="currentColor"
								d="M279.2 17.9C276.5 7.4 266.9 0 256 0s-20.5 7.4-23.2 17.9L188.3 188.3 17.9 232.8C7.4 235.5 0 245.1 0 256s7.4 20.5 17.9 23.2l170.4 44.4 44.4 170.4c2.8 10.6 12.3 17.9 23.2 17.9s20.5-7.4 23.2-17.9l44.4-170.4 170.4-44.4c10.6-2.8 17.9-12.3 17.9-23.2s-7.4-20.5-17.9-23.2L323.7 188.3 279.2 17.9zm-48 196.1l24.8-95 24.8 95c2.2 8.4 8.8 15 17.2 17.2l95 24.8-95 24.8c-8.4 2.2-15 8.8-17.2 17.2l-24.8 95-24.8-95c-2.2-8.4-8.8-15-17.2-17.2l-95-24.8 95-24.8c8.4-2.2 15-8.8 17.2-17.2zM71 71c-9.4 9.4-9.4 24.6 0 33.9l48 48c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L105 71c-9.4-9.4-24.6-9.4-33.9 0zM441 71c-9.4-9.4-24.6-9.4-33.9 0l-48 48c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l48-48c9.4-9.4 9.4-24.6 0-33.9zm0 336l-48-48c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l48 48c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9zM71 441c9.4 9.4 24.6 9.4 33.9 0l48-48c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L71 407c-9.4 9.4-9.4 24.6 0 33.9z">
							</path>
						</svg> -->
						<h3 class="m-0 ml-1">Chat</h3>
					</div>
					<p class="subscription-item">Chat effortlessly using cutting-edge models over custom data like PDF,
						TXT, DOCX. Enjoy 100 free messages, 10MB storage; go Pro for 500 messages, 100MB size.
					</p>
				</div>
				<div class="col-6 mb-2">
					<div class="d-flex align-items-center">
						<!-- <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="star-christmas"
							class="svg-inline--fa fa-star-christmas fa-fw fa-lg " role="img"
							xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
							<path fill="currentColor"
								d="M279.2 17.9C276.5 7.4 266.9 0 256 0s-20.5 7.4-23.2 17.9L188.3 188.3 17.9 232.8C7.4 235.5 0 245.1 0 256s7.4 20.5 17.9 23.2l170.4 44.4 44.4 170.4c2.8 10.6 12.3 17.9 23.2 17.9s20.5-7.4 23.2-17.9l44.4-170.4 170.4-44.4c10.6-2.8 17.9-12.3 17.9-23.2s-7.4-20.5-17.9-23.2L323.7 188.3 279.2 17.9zm-48 196.1l24.8-95 24.8 95c2.2 8.4 8.8 15 17.2 17.2l95 24.8-95 24.8c-8.4 2.2-15 8.8-17.2 17.2l-24.8 95-24.8-95c-2.2-8.4-8.8-15-17.2-17.2l-95-24.8 95-24.8c8.4-2.2 15-8.8 17.2-17.2zM71 71c-9.4 9.4-9.4 24.6 0 33.9l48 48c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L105 71c-9.4-9.4-24.6-9.4-33.9 0zM441 71c-9.4-9.4-24.6-9.4-33.9 0l-48 48c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l48-48c9.4-9.4 9.4-24.6 0-33.9zm0 336l-48-48c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l48 48c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9zM71 441c9.4 9.4 24.6 9.4 33.9 0l48-48c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L71 407c-9.4 9.4-9.4 24.6 0 33.9z">
							</path>
						</svg> -->
						<h3 class="m-0 ml-1">PII Reports</h3>
					</div>
					<p class="subscription-item">Generate PII reports effortlessly. Free plan includes up to 5 reports;
						go Pro for up to 20, ensuring robust tabular data security.
					</p>
				</div>
				<div class="col-6 mb-2">
					<div class="d-flex align-items-center">
						<!-- <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="star-christmas"
							class="svg-inline--fa fa-star-christmas fa-fw fa-lg " role="img"
							xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
							<path fill="currentColor"
								d="M279.2 17.9C276.5 7.4 266.9 0 256 0s-20.5 7.4-23.2 17.9L188.3 188.3 17.9 232.8C7.4 235.5 0 245.1 0 256s7.4 20.5 17.9 23.2l170.4 44.4 44.4 170.4c2.8 10.6 12.3 17.9 23.2 17.9s20.5-7.4 23.2-17.9l44.4-170.4 170.4-44.4c10.6-2.8 17.9-12.3 17.9-23.2s-7.4-20.5-17.9-23.2L323.7 188.3 279.2 17.9zm-48 196.1l24.8-95 24.8 95c2.2 8.4 8.8 15 17.2 17.2l95 24.8-95 24.8c-8.4 2.2-15 8.8-17.2 17.2l-24.8 95-24.8-95c-2.2-8.4-8.8-15-17.2-17.2l-95-24.8 95-24.8c8.4-2.2 15-8.8 17.2-17.2zM71 71c-9.4 9.4-9.4 24.6 0 33.9l48 48c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L105 71c-9.4-9.4-24.6-9.4-33.9 0zM441 71c-9.4-9.4-24.6-9.4-33.9 0l-48 48c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l48-48c9.4-9.4 9.4-24.6 0-33.9zm0 336l-48-48c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l48 48c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9zM71 441c9.4 9.4 24.6 9.4 33.9 0l48-48c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L71 407c-9.4 9.4-9.4 24.6 0 33.9z">
							</path>
						</svg> -->
						<h3 class="m-0 ml-1">Anomaly</h3>
					</div>
					<p class="subscription-item">Detect anomalies in your tables with cutting-edge ML magic. Free plan
						supports max 250 rows; Pro plan supports upto 5000 rows.

					</p>
				</div>
				<div class="col-6 mb-2">
					<div class="d-flex align-items-center">
						<!-- <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="star-christmas"
							class="svg-inline--fa fa-star-christmas fa-fw fa-lg " role="img"
							xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
							<path fill="currentColor"
								d="M279.2 17.9C276.5 7.4 266.9 0 256 0s-20.5 7.4-23.2 17.9L188.3 188.3 17.9 232.8C7.4 235.5 0 245.1 0 256s7.4 20.5 17.9 23.2l170.4 44.4 44.4 170.4c2.8 10.6 12.3 17.9 23.2 17.9s20.5-7.4 23.2-17.9l44.4-170.4 170.4-44.4c10.6-2.8 17.9-12.3 17.9-23.2s-7.4-20.5-17.9-23.2L323.7 188.3 279.2 17.9zm-48 196.1l24.8-95 24.8 95c2.2 8.4 8.8 15 17.2 17.2l95 24.8-95 24.8c-8.4 2.2-15 8.8-17.2 17.2l-24.8 95-24.8-95c-2.2-8.4-8.8-15-17.2-17.2l-95-24.8 95-24.8c8.4-2.2 15-8.8 17.2-17.2zM71 71c-9.4 9.4-9.4 24.6 0 33.9l48 48c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L105 71c-9.4-9.4-24.6-9.4-33.9 0zM441 71c-9.4-9.4-24.6-9.4-33.9 0l-48 48c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l48-48c9.4-9.4 9.4-24.6 0-33.9zm0 336l-48-48c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l48 48c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9zM71 441c9.4 9.4 24.6 9.4 33.9 0l48-48c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L71 407c-9.4 9.4-9.4 24.6 0 33.9z">
							</path>
						</svg> -->
						<h3 class="m-0 ml-1">Users</h3>
					</div>
					<p class="subscription-item">Transform collaboration! Basic plan supports 2 users; Pro plan
						amplifies teamwork with support for up to 10 users.
					</p>
				</div>
			</div>

			<div class="row subscription-plans">
				<div class="col-6 mb-2">
					<div class="subscription-cards">
						<h3 class="head">Basic</h3>
						<h4>$3</h4>
						<p>BILLED PER MONTH</p>
						<button class="btn btn-warning" (click)="proceedToCheckout('monthly')">Get started</button>
					</div>
				</div>
				<div class="col-6 mb-2">
					<div class="subscription-cards">
						<h3 class="head d-flex">Pro</h3>
						<h4>$30</h4>
						<p>BILLED PER YEAR</p>
						<button class="btn btn-warning" (click)="proceedToCheckout('yearly')">Get started</button>
					</div>
				</div>
			</div>
		</div>
	</p-dialog>
</div>