import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-occurence',
  templateUrl: './occurence.component.html'
})
export class OccurenceComponent {

  @Input() occurence: Object = {};
  @Input() occurenceCol: Object = {};
  @Input() selectedColumnDetails;

  constructor() { }

  ngOnInit(): void {
  }



}
