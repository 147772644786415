<div class="row m-0 assign-semantic" *ngIf="jobDetails['jobName']">
    <div class="col-md-4 col-lg-4 col-sm-6 table-box customer  p-0">

        <div *ngIf="routeType == 'pii'">
            <div class="" *ngIf="!(fileJob && fileJob['job_id']); else file">
                <div *ngIf="selTable === null; else elseBlock">
                    <div class="customer-add ">{{ 'assignsemantic.customertable' | translate }} </div>
                    <perfect-scrollbar class="perfect-scrollbar" fxFlex="auto">
                        <div class="fields-col">
                            <div class="semantic-dependent" *ngIf="jobDetails['selectSource']">
                                <div *ngIf="tablesInfo.length > 0">
                                    <ul class="table-data">
                                        <li *ngFor="let table of tablesInfo let i = index"
                                            (click)="toggleColumns(table, i)" (keydown.arrowDown)="navigate('down', i)"
                                            (keydown.arrowUp)="navigate('up', i)" class="table-name list-table"
                                            [tabIndex]="i" (keydown.enter)="toggleColumns(table, i)">
                                            <i
                                                [ngClass]="displayedColumnIndex.indexOf(i) != -1 ? 'feather ft-chevron-down' : 'feather ft-chevron-right'"></i>
                                            <div class="tabular-data"><span class="sr-only">{{ i + 1 }}</span>{{
                                                table['tableName']}}<span class="sr-only">table expendable</span></div>
                                            <h5 *ngIf="i == (tableLength - 1)" class="d-flex mt-4 mb-1 views">
                                                {{ 'assignsemantic.views' | translate }}</h5>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </perfect-scrollbar>
                </div>
                <ng-template #elseBlock>
                    <div class="customer-add ">
                        <i class="mr-1 feather ft-arrow-left cursor-pointer" (click)="backToTables()" [tabIndex]="0"
                            aria-label="Go back to Tables" role="button" (keydown.enter)="backToTables()"></i>
                        <span (click)="backToTables()">{{ 'assignsemantic.alltables' | translate }}</span>
                    </div>
                    <perfect-scrollbar [ngClass]="{'afterTokenize': isChecked}" class="perfect-scrollbar" fxFlex="auto">
                        <div class="fields-col">
                            <div class="semantic-dependent" *ngIf="jobDetails['selectSource']">
                                <ul class="table-data">
                                    <li class="table-name" [tabIndex]="0">
                                        <div class="tabular-data">{{ selTable['tableName']}}<span
                                                class="sr-only">table</span>
                                        </div>
                                    </li>
                                    <li class="list-table"
                                        [ngClass]="showSelectedColumns.includes(column) && selectedTable == selTable['tableName']  ? 'highlighted': ''"
                                        *ngFor="let column of selTable['columns']; index as i"
                                        (click)="displaySemanticsAndConstraints(selTable, column)" [tabIndex]="i"
                                        (keydown.enter)="displaySemanticsAndConstraints(selTable, column); navigateToDiv('semanticDiv')"
                                        (keydown.arrowDown)="navigate('down', i)" (keydown.arrowUp)="navigate('up', i)">
                                        <span [ngClass]="showPIIIcon(column, ind) ? 'ftuser': 'showIcon'"><em
                                                *ngIf="showPIIIcon(column, i)" class="feather ft-user"></em></span>
                                        <span class="sr-only">{{ i + 1 }}</span>{{column}}<span
                                            class="sr-only">column</span>
                                        <i [ngClass]="selectedColumnDetails && (selectedColumnDetails['selectedColumn'] === column) ? 'feather ft-eye mr-1' : ''"
                                            [tabIndex]="i" (keydown.enter)="getTop10Records(); $event.stopPropagation()"
                                            (click)="getTop10Records(); $event.stopPropagation()"></i>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </perfect-scrollbar>
                </ng-template>
            </div>
            <ng-template #file>
                <div class="col-md-12 col-lg-12 col-sm-6 customer p-0">
                    <div class="customer-add ">{{ 'assignsemantic.customeradd' | translate }} </div>
                    <perfect-scrollbar [ngClass]="{'afterTokenize': isChecked}" class="perfect-scrollbar" fxFlex="auto">
                        <div class="fields-col">
                            <div class="semantic-dependent">
                                <div *ngIf="fileColumns.length > 0">
                                    <ul class="table-data">
                                        <li [ngClass]="showSelectedColumns.includes(column) ? 'highlighted': ''"
                                            *ngFor="let column of fileColumns; index as i" [tabIndex]="i"
                                            (keydown.arrowDown)="navigate('down', i)"
                                            (keydown.arrowUp)="navigate('up', i)">
                                            <span [ngClass]="showPIIIcon(column, ind) ? 'ftuser': 'showIcon'"><em
                                                    *ngIf="showPIIIcon(column, i)" class="feather ft-user"></em></span>
                                            <a class="cursor-pointer"
                                                (click)="displaySemanticsAndConstraints({tableName: 'file', columns: fileColumns}, column)"
                                                href="javascript:void(0)">{{column}}</a>
                                            <i [ngClass]="selectedColumnDetails && (selectedColumnDetails['selectedColumn'] === column) ? 'feather ft-eye mr-1' : ''"
                                                (click)="getTop10Records()" [tabIndex]="0"></i>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </perfect-scrollbar>
                </div>
            </ng-template>
        </div>

        <div *ngIf="routeType == 'synthesis'">
            <div class="" *ngIf="!(fileJob && fileJob['job_id']); else file">
                <div *ngIf="selTable === null; else elseBlock">
                    <div class="customer-add ">{{ 'assignsemantic.customertable' | translate }}</div>
                    <perfect-scrollbar class="perfect-scrollbar" fxFlex="auto">
                        <div class="loader-wrapper spinners" *ngIf="tablesInfo.length == 0">
                            <div class="loader-container">
                                <div class="ball-grid-pulse loader-primary">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                        </div>
                        <div class="fields-col">
                            <div class="semantic-dependent">
                                <div *ngIf="jobDetails['selectSource']">
                                    <ul class="table-data" *ngIf="tablesInfo.length > 0">
                                        <li *ngFor="let table of tablesInfo let i = index"
                                            (click)="toggleColumns(table, i)" (keydown.arrowDown)="navigate('down', i)"
                                            (keydown.arrowUp)="navigate('up', i)" class="table-name list-table"
                                            [tabIndex]="i" (keydown.enter)="toggleColumns(table, i)">
                                            <i
                                                [ngClass]="displayedColumnIndex.indexOf(i) != -1 ? 'feather ft-chevron-down' : 'feather ft-chevron-right'"></i>
                                            <div class="tabular-data"><span class="sr-only">{{ i + 1 }}</span>{{
                                                table['tableName']}}<span class="sr-only">table expendable</span></div>
                                            <h5 *ngIf="i == (tableLength - 1)" class="d-flex mt-4 mb-1 views">
                                                {{ 'assignsemantic.views' | translate }}</h5>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </perfect-scrollbar>
                </div>
                <ng-template #elseBlock>
                    <div class="customer-add ">
                        <i class="mr-1 feather ft-arrow-left cursor-pointer" (click)="backToTables()" [tabIndex]="0"
                            aria-label="Go back to Tables" role="button" (keydown.enter)="backToTables()"></i>
                        <span (click)="backToTables()">{{ 'assignsemantic.alltables' | translate }}</span>
                    </div>
                    <perfect-scrollbar [ngClass]="{'afterTokenize': isChecked}" class="perfect-scrollbar" fxFlex="auto">
                        <div class="fields-col">
                            <div class="semantic-dependent" *ngIf="jobDetails['selectSource']">
                                <ul class="table-data">
                                    <li class="table-name" [tabIndex]="0">
                                        <div class="tabular-data">{{ selTable['tableName']}}<span
                                                class="sr-only">table</span>
                                        </div>
                                    </li>
                                    <li class="list-table" [draggable]="true" (dragstart)="onDragStart($event, column)"
                                        [ngClass]="showSelectedColumns.includes(column) && selectedTable == selTable['tableName']  ? 'highlighted': ''"
                                        *ngFor="let column of selTable['columns']; index as i"
                                        (click)="displaySemanticsAndConstraints(selTable, column)" [tabIndex]="i"
                                        (keydown.enter)="displaySemanticsAndConstraints(selTable, column); navigateToDiv('semanticDiv')"
                                        (keydown.arrowDown)="navigate('down', i)" (keydown.arrowUp)="navigate('up', i)">
                                        <span [ngClass]="showPIIIcon(column, ind) ? 'ftuser': 'showIcon'"><em
                                                *ngIf="showPIIIcon(column, i)" class="feather ft-user"></em></span>
                                        <span class="sr-only">{{ i + 1 }}</span>{{column}}<span
                                            class="sr-only">column</span>
                                        <i [ngClass]="selectedColumnDetails && (selectedColumnDetails['selectedColumn'] === column) ? 'feather ft-eye mr-1' : ''"
                                            [tabIndex]="i" (keydown.enter)="getTop10Records(); $event.stopPropagation()"
                                            (click)="getTop10Records(); $event.stopPropagation()"></i>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </perfect-scrollbar>
                </ng-template>
            </div>
            <ng-template #file>
                <div class="col-md-12 col-lg-12 col-sm-6 customer p-0">
                    <div class="customer-add ">{{ 'assignsemantic.customeradd' | translate }}</div>
                    <perfect-scrollbar [ngClass]="{'afterTokenize': isChecked}" class="perfect-scrollbar" fxFlex="auto">
                        <div class="fields-col">
                            <div class="semantic-dependent">
                                <div *ngIf="fileColumns.length > 0">
                                    <ul class="table-data">
                                        <li [ngClass]="showSelectedColumns.includes(column) ? 'highlighted': ''"
                                            (click)="displaySemanticsAndConstraints({tableName: 'file', columns: fileColumns}, column)"
                                            *ngFor="let column of fileColumns; index as i" [tabIndex]="i"
                                            (keydown.arrowDown)="navigate('down', i)"
                                            (keydown.arrowUp)="navigate('up', i)">
                                            <span [ngClass]="showPIIIcon(column, ind) ? 'ftuser': 'showIcon'"><em
                                                    *ngIf="showPIIIcon(column, i)" class="feather ft-user"></em></span>
                                            <a class="cursor-pointer" href="javascript:void(0)">{{column}}</a>
                                            <i [ngClass]="selectedColumnDetails && (selectedColumnDetails['selectedColumn'] === column) ? 'feather ft-eye mr-1' : ''"
                                                (click)="getTop10Records()" [tabIndex]="0"></i>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </perfect-scrollbar>
                </div>
            </ng-template>
        </div>

        <div *ngIf="routeType == 'rules'">
            <div class="" *ngIf="!(fileJob && fileJob['job_id']); else file">
                <div *ngIf="selTable === null; else elseBlock">
                    <div class="customer-add ">{{ 'assignsemantic.customertable' | translate }}</div>
                    <perfect-scrollbar class="perfect-scrollbar" fxFlex="auto">
                        <div class="fields-col">
                            <div class="semantic-dependent" *ngIf="jobDetails['selectSource']">
                                <div *ngIf="tablesInfo.length > 0">
                                    <ul class="table-data">
                                        <li *ngFor="let table of tablesInfo let i = index"
                                            (click)="toggleColumns(table, i)" (keydown.arrowDown)="navigate('down', i)"
                                            (keydown.arrowUp)="navigate('up', i)" class="table-name list-table"
                                            [tabIndex]="i" (keydown.enter)="toggleColumns(table, i)">
                                            <i
                                                [ngClass]="displayedColumnIndex.indexOf(i) != -1 ? 'feather ft-chevron-down' : 'feather ft-chevron-right'"></i>
                                            <div class="tabular-data" (click)="ruleSlide()"><span class="sr-only">{{ i +
                                                    1 }}</span>{{
                                                table['tableName']}}<span class="sr-only">table expendable</span></div>
                                            <h5 *ngIf="i == (tableLength - 1)" class="d-flex mt-4 mb-1 views">
                                                {{ 'assignsemantic.views' | translate }}</h5>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </perfect-scrollbar>
                </div>
                <ng-template #elseBlock>
                    <div class="customer-add ">
                        <i class="mr-1 feather ft-arrow-left cursor-pointer" (click)="backToTables()" [tabIndex]="0"
                            aria-label="Go back to Tables" role="button" (keydown.enter)="backToTables()"></i>
                        <span (click)="backToTables()">{{ 'assignsemantic.alltables' | translate }}</span>
                    </div>
                    <perfect-scrollbar [ngClass]="{'afterTokenize': isChecked}" class="perfect-scrollbar" fxFlex="auto">
                        <div class="fields-col">
                            <div class="semantic-dependent" *ngIf="jobDetails['selectSource']">
                                <ul class="table-data">
                                    <li class="table-name" [tabIndex]="0" (keydown.enter)="ruleSlide()"
                                        (click)="ruleSlide()">
                                        <div class="tabular-data">{{ selTable['tableName']}}<span
                                                class="sr-only">table</span>
                                        </div>
                                    </li>
                                    <li class="list-table" [draggable]="true" (dragstart)="onDragStart($event, column)"
                                        [ngClass]="showSelectedColumns.includes(column) && selectedTable == selTable['tableName']  ? 'highlighted': ''"
                                        *ngFor="let column of selTable['columns']; index as i" [tabIndex]="i"
                                        (keydown.arrowDown)="navigate('down', i)" (keydown.arrowUp)="navigate('up', i)">
                                        <span [ngClass]="showPIIIcon(column, ind) ? 'ftuser': 'showIcon'"><em
                                                *ngIf="showPIIIcon(column, i)" class="feather ft-user"></em></span>
                                        <span class="sr-only">{{ i + 1 }}</span>{{column}}<span
                                            class="sr-only">column</span>
                                        <i [ngClass]="selectedColumnDetails && (selectedColumnDetails['selectedColumn'] === column) ? 'feather ft-eye mr-1' : ''"
                                            [tabIndex]="i" (keydown.enter)="getTop10Records(); $event.stopPropagation()"
                                            (click)="getTop10Records(); $event.stopPropagation()"></i>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </perfect-scrollbar>
                </ng-template>
            </div>
            <ng-template #file>
                <div class="col-md-12 col-lg-12 col-sm-6 customer p-0">
                    <div class="customer-add ">{{ 'assignsemantic.customeradd' | translate }}</div>
                    <perfect-scrollbar [ngClass]="{'afterTokenize': isChecked}" class="perfect-scrollbar" fxFlex="auto">
                        <div class="fields-col">
                            <div class="semantic-dependent">
                                <div *ngIf="fileColumns.length > 0">
                                    <ul class="table-data">
                                        <li [ngClass]="showSelectedColumns.includes(column) ? 'highlighted': ''"
                                            *ngFor="let column of fileColumns; index as i" [tabIndex]="i"
                                            (keydown.arrowDown)="navigate('down', i)"
                                            (keydown.arrowUp)="navigate('up', i)">
                                            <span [ngClass]="showPIIIcon(column, ind) ? 'ftuser': 'showIcon'"><em
                                                    *ngIf="showPIIIcon(column, i)" class="feather ft-user"></em></span>
                                            <a class="cursor-pointer" href="javascript:void(0)">{{column}}</a>
                                            <i [ngClass]="selectedColumnDetails && (selectedColumnDetails['selectedColumn'] === column) ? 'feather ft-eye mr-1' : ''"
                                                (click)="getTop10Records()" [tabIndex]="0"></i>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </perfect-scrollbar>
                </div>
            </ng-template>
        </div>
        <div *ngIf="routeType == 'anomaly'">
            <div class="" *ngIf="!(fileJob && fileJob['job_id']); else file">
                <div *ngIf="selTable === null; else elseBlock">
                    <div class="customer-add ">{{ 'assignsemantic.customertable' | translate }}</div>
                    <perfect-scrollbar class="perfect-scrollbar" fxFlex="auto">
                        <div class="loader-wrapper spinners" *ngIf="tablesInfo.length == 0">
                            <div class="loader-container">
                                <div class="ball-grid-pulse loader-primary">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                        </div>
                        <div class="fields-col">
                            <div class="semantic-dependent">
                                <div *ngIf="jobDetails['selectSource']">
                                    <ul class="table-data" *ngIf="tablesInfo.length > 0">
                                        <li *ngFor="let table of tablesInfo let i = index"
                                            (click)="toggleColumns(table, i)" (keydown.arrowDown)="navigate('down', i)"
                                            (keydown.arrowUp)="navigate('up', i)" class="table-name list-table"
                                            [tabIndex]="i" (keydown.enter)="toggleColumns(table, i)">
                                            <i
                                                [ngClass]="displayedColumnIndex.indexOf(i) != -1 ? 'feather ft-chevron-down' : 'feather ft-chevron-right'"></i>
                                            <div class="tabular-data"><span class="sr-only">{{ i + 1 }}</span>{{
                                                table['tableName']}}<span class="sr-only">table expendable</span></div>
                                            <h5 *ngIf="i == (tableLength - 1)" class="d-flex mt-4 mb-1 views">
                                                {{ 'assignsemantic.views' | translate }}</h5>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </perfect-scrollbar>
                </div>
                <ng-template #elseBlock>
                    <div class="customer-add ">
                        <i class="mr-1 feather ft-arrow-left cursor-pointer" (click)="backToTables()" [tabIndex]="0"
                            aria-label="Go back to Tables" role="button" (keydown.enter)="backToTables()"></i>
                        <span (click)="backToTables()">{{ 'assignsemantic.alltables' | translate }}</span>
                    </div>
                    <perfect-scrollbar [ngClass]="{'afterTokenize': isChecked}" class="perfect-scrollbar" fxFlex="auto">
                        <div class="fields-col">
                            <div class="semantic-dependent" *ngIf="jobDetails['selectSource']">
                                <ul class="table-data">
                                    <li class="table-name" [tabIndex]="0" (keydown.enter)="ruleSlide()"
                                        (click)="ruleSlide()">
                                        <div class="tabular-data">{{ selTable['tableName']}}<span
                                                class="sr-only">table</span>
                                        </div>
                                    </li>
                                    <li class="list-table" [draggable]="true" (dragstart)="onDragStart($event, column)"
                                        [ngClass]="showSelectedColumns.includes(column) && selectedTable == selTable['tableName']  ? 'highlighted': ''"
                                        *ngFor="let column of selTable['columns']; index as i"
                                        (click)="displaySemanticsAndConstraints(selTable, column)" [tabIndex]="i"
                                        (keydown.enter)="displaySemanticsAndConstraints(selTable, column); navigateToDiv('semanticDiv')"
                                        (keydown.arrowDown)="navigate('down', i)" (keydown.arrowUp)="navigate('up', i)">
                                        <span [ngClass]="showPIIIcon(column, ind) ? 'ftuser': 'showIcon'"><em
                                                *ngIf="showPIIIcon(column, i)" class="feather ft-user"></em></span>
                                        <span class="sr-only">{{ i + 1 }}</span>{{column}}<span
                                            class="sr-only">column</span>
                                        <i [ngClass]="selectedColumnDetails && (selectedColumnDetails['selectedColumn'] === column) ? 'feather ft-eye mr-1' : ''"
                                            [tabIndex]="i" (keydown.enter)="getTop10Records(); $event.stopPropagation()"
                                            (click)="getTop10Records(); $event.stopPropagation()"></i>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </perfect-scrollbar>
                </ng-template>
            </div>
            <ng-template #file>
                <div class="col-md-12 col-lg-12 col-sm-6 customer p-0">
                    <div class="customer-add ">{{ 'assignsemantic.customeradd' | translate }}</div>
                    <perfect-scrollbar [ngClass]="{'afterTokenize': isChecked}" class="perfect-scrollbar" fxFlex="auto">
                        <div class="fields-col">
                            <div class="semantic-dependent">
                                <div *ngIf="fileColumns.length > 0">
                                    <ul class="table-data">
                                        <li [ngClass]="showSelectedColumns.includes(column) ? 'highlighted': ''"
                                            (click)="displaySemanticsAndConstraints({tableName: 'file', columns: fileColumns}, column)"
                                            *ngFor="let column of fileColumns; index as i" [tabIndex]="i"
                                            (keydown.arrowDown)="navigate('down', i)"
                                            (keydown.arrowUp)="navigate('up', i)">
                                            <span [ngClass]="showPIIIcon(column, ind) ? 'ftuser': 'showIcon'"><em
                                                    *ngIf="showPIIIcon(column, i)" class="feather ft-user"></em></span>
                                            <a class="cursor-pointer" href="javascript:void(0)">{{column}}</a>
                                            <i [ngClass]="selectedColumnDetails && (selectedColumnDetails['selectedColumn'] === column) ? 'feather ft-eye mr-1' : ''"
                                                (click)="getTop10Records()" [tabIndex]="0"></i>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </perfect-scrollbar>
                </div>
            </ng-template>
        </div>


        <div *ngIf="routeType == 'automl'">
            <div class="" *ngIf="!(fileJob && fileJob['job_id']); else file">
                <div *ngIf="selTable === null; else elseBlock">
                    <div class="customer-add ">{{ 'assignsemantic.customertable' | translate }}</div>
                    <perfect-scrollbar class="perfect-scrollbar" fxFlex="auto">
                        <div class="loader-wrapper spinners" *ngIf="tablesInfo.length == 0">
                            <div class="loader-container">
                                <div class="ball-grid-pulse loader-primary">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                        </div>
                        <div class="fields-col">
                            <div class="semantic-dependent">
                                <div *ngIf="jobDetails['selectSource']">
                                    <ul class="table-data" *ngIf="tablesInfo.length > 0">
                                        <li *ngFor="let table of tablesInfo let i = index"
                                            (click)="toggleColumns(table, i)" (keydown.arrowDown)="navigate('down', i)"
                                            (keydown.arrowUp)="navigate('up', i)" class="table-name list-table"
                                            [tabIndex]="i" (keydown.enter)="toggleColumns(table, i)">
                                            <i
                                                [ngClass]="displayedColumnIndex.indexOf(i) != -1 ? 'feather ft-chevron-down' : 'feather ft-chevron-right'"></i>
                                            <div class="tabular-data"><span class="sr-only">{{ i + 1 }}</span>{{
                                                table['tableName']}}<span class="sr-only">table expendable</span></div>
                                            <h5 *ngIf="i == (tableLength - 1)" class="d-flex mt-4 mb-1 views">
                                                {{ 'assignsemantic.views' | translate }}</h5>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </perfect-scrollbar>
                </div>
                <ng-template #elseBlock>
                    <div class="customer-add ">
                        <i class="mr-1 feather ft-arrow-left cursor-pointer" (click)="backToTables()" [tabIndex]="0"
                            aria-label="Go back to Tables" role="button" (keydown.enter)="backToTables()"></i>
                        <span (click)="backToTables()">{{ 'assignsemantic.alltables' | translate }}</span>
                    </div>
                    <perfect-scrollbar [ngClass]="{'afterTokenize': isChecked}" class="perfect-scrollbar" fxFlex="auto">
                        <div class="fields-col">
                            <div class="semantic-dependent" *ngIf="jobDetails['selectSource']">
                                <ul class="table-data">
                                    <li class="table-name" [tabIndex]="0" (keydown.enter)="ruleSlide()"
                                        (click)="ruleSlide()">
                                        <div class="tabular-data">{{ selTable['tableName']}}<span
                                                class="sr-only">table</span>
                                        </div>
                                    </li>
                                    <li class="list-table" [draggable]="true" (dragstart)="onDragStart($event, column)"
                                        [ngClass]="showSelectedColumns.includes(column) && selectedTable == selTable['tableName']  ? 'highlighted': ''"
                                        *ngFor="let column of selTable['columns']; index as i"
                                        (click)="displaySemanticsAndConstraints(selTable, column)" [tabIndex]="i"
                                        (keydown.enter)="displaySemanticsAndConstraints(selTable, column); navigateToDiv('semanticDiv')"
                                        (keydown.arrowDown)="navigate('down', i)" (keydown.arrowUp)="navigate('up', i)">
                                        <span [ngClass]="showPIIIcon(column, ind) ? 'ftuser': 'showIcon'"><em
                                                *ngIf="showPIIIcon(column, i)" class="feather ft-user"></em></span>
                                        <span class="sr-only">{{ i + 1 }}</span>{{column}}<span
                                            class="sr-only">column</span>
                                        <i [ngClass]="selectedColumnDetails && (selectedColumnDetails['selectedColumn'] === column) ? 'feather ft-eye mr-1' : ''"
                                            [tabIndex]="i" (keydown.enter)="getTop10Records(); $event.stopPropagation()"
                                            (click)="getTop10Records(); $event.stopPropagation()"></i>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </perfect-scrollbar>
                </ng-template>
            </div>
            <ng-template #file>
                <div class="col-md-12 col-lg-12 col-sm-6 customer p-0">
                    <div class="customer-add ">{{ 'assignsemantic.customeradd' | translate }}</div>
                    <perfect-scrollbar [ngClass]="{'afterTokenize': isChecked}" class="perfect-scrollbar" fxFlex="auto">
                        <div class="fields-col">
                            <div class="semantic-dependent">
                                <div *ngIf="fileColumns.length > 0">
                                    <ul class="table-data">
                                        <li [ngClass]="showSelectedColumns.includes(column) ? 'highlighted': ''"
                                            (click)="displaySemanticsAndConstraints({tableName: 'file', columns: fileColumns}, column)"
                                            *ngFor="let column of fileColumns; index as i" [tabIndex]="i"
                                            (keydown.arrowDown)="navigate('down', i)"
                                            (keydown.arrowUp)="navigate('up', i)">
                                            <span [ngClass]="showPIIIcon(column, ind) ? 'ftuser': 'showIcon'"><em
                                                    *ngIf="showPIIIcon(column, i)" class="feather ft-user"></em></span>
                                            <a class="cursor-pointer" href="javascript:void(0)">{{column}}</a>
                                            <i [ngClass]="selectedColumnDetails && (selectedColumnDetails['selectedColumn'] === column) ? 'feather ft-eye mr-1' : ''"
                                                (click)="getTop10Records()" [tabIndex]="0"></i>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </perfect-scrollbar>
                </div>
            </ng-template>
        </div>
    </div>






    <div class="col-md-8 col-lg-8 col-sm-6 before-selecting"
        *ngIf="!showSemantics && !showRules && !showAnomaly && !showAutoML">
        <p *ngIf="!(fileJob && fileJob['job_id']) && !tablesInfo.length == 0">{{ 'assignsemantic.selectsynthesizetext' |
            translate }}</p>
        <p *ngIf="(fileJob && fileJob['job_id'])&& routeType != 'automl'">{{ 'assignsemantic.selectsynthesizetext2' |
            translate }}</p>
        <p *ngIf="(fileJob && fileJob['job_id']) && routeType == 'automl'">{{ 'assignsemantic.selectsynthesizetext4' |
            translate }}</p>
        <p *ngIf="tablesInfo.length == 0 && !(fileJob && fileJob['job_id'])"> {{ 'assignsemantic.selectsynthesizetext3'
            | translate }}</p>
        <div class="detacting-loader" *ngIf="showAnalysisDialog">
            <app-loading-spinner></app-loading-spinner>
            <div class="container-box text-center mt-2 mb-3 loader-text">
                <div class="text-line top">{{ 'assignsemantic.analyzingtext' | translate }}</div>
                <div class="text-line bottom">{{ 'assignsemantic.piitext' | translate }}</div>
                <div *ngIf="routeType != 'pii'" class="text-line third">{{ 'assignsemantic.suggestingtext' | translate
                    }}</div>
            </div>
        </div>
    </div>
    <div class="col-md-8 col-lg-8 col-sm-12  show-semantic"
        *ngIf="showSemantics && selectedColumnDetails['table'] && !showRules">
        <div [@slideInFromLeft]>
            <app-custom-synthesis-pattern [fileJob]="fileJob" [PIIData]="showPIIData" [projectId]="projectId"
                [jobDetails]="jobDetails" id="semanticDiv" [selectedColumnDetails]="selectedColumnDetails"
                [finalColumnsData]="columnsData" (checkedStatusChange)="handleCheckedStatusChange($event)"
                (jobColumns)="columnsDataEmitter($event)" (clearColumn)="clearColumnsHandler($event)"
                (patternsData)="patternEvent($event)">
            </app-custom-synthesis-pattern>
        </div>
    </div>
    <div class="col-md-12 col-lg-8 col-sm-12  show-semantic" *ngIf="showRules && !showSemantics">
        <div [@slideInFromLeft]>
            <app-rule-handler [projectId]="projectId" [jobDetails]="jobDetails" [tableInfo]="selTable"
                (ruleEmitter)="ruleEvent($event)" *ngIf="showRules" [fileJob]="fileJob"></app-rule-handler>
            <!-- <app-rule-editor [projectId]="projectId" [jobDetails]="jobDetails" [tableInfo]="selTable"
                (ruleEmitter)="ruleEvent($event)" *ngIf="showRules"></app-rule-editor> -->
        </div>
    </div>
    <div class="col-md-12 col-lg-8 col-sm-12  show-semantic" *ngIf="showAnomaly">
        <div [@slideInFromLeft]>
            <app-anomaly [projectId]="projectId" [PIIData]="showPIIData" [fileColumns]="fileColumns"
                [jobDetails]="jobDetails" [fileJob]="fileJob" [selectedColumnDetails]="selectedColumnDetails"
                (anomalyEvent)="anomalyEmit($event)"></app-anomaly>
        </div>
    </div>
    <div class="col-md-12 col-lg-8 col-sm-12  show-semantic" *ngIf="showAutoML">
        <div [@slideInFromLeft]>
            <app-automl-handler [projectId]="projectId" [PIIData]="showPIIData" [jobDetails]="jobDetails"
                [fileJob]="fileJob" [selectedColumnDetails]="selectedColumnDetails"
                (autoMLEvent)="autoMLEmit($event)"></app-automl-handler>
        </div>
    </div>

</div>
<p-dialog [(visible)]="showRecords" [resizable]="false" [draggable]="false" [modal]="true" [blockScroll]="true"
    [style]="{width: '45vw'}">
    <div class="p-dialog-titlebar">
        <button class="p-dialog-titlebar-icon p-dialog-titlebar-close" aria-label="Close Dialog" [autofocus]="true"
            (click)="showRecords=false">
            <span class="pi pi-times"></span>
        </button>
    </div>
    <div *ngIf="showRecords">
        <h2 class="col-12 label-control popup-title" for="projectinput1"
            *ngIf="selectedColumnDetails['table']['tableName'] && selectedColumnDetails['selectedColumn']">
            {{ selectedColumnDetails['table']['tableName'] + "-" +
            selectedColumnDetails['selectedColumn'] }}
        </h2>
    </div>

    <div class="form-group sub-title row mx-auto">
        <div class="col-md-12 page-content mb-2" *ngFor="let item of topRecords">
            <p class="dialogdata">{{item}}</p>
        </div>
    </div>
    <button class="sr-only" autofocus></button>
</p-dialog>