import { Injectable } from '@angular/core';

declare var google: any;

@Injectable({
  providedIn: 'root'
})
export class GoogleMapsService {
  
  private googleInstance: any;
  private isLoaded = false;

  constructor() { }

  loadMapsApi(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.isLoaded && this.googleInstance) {
        resolve(this.googleInstance);
      } else {
        const script = document.createElement('script');
        script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCoN7BLZ15vs4TLdtRAGElhelcY8Mbs6fo';
        script.async = true;
        script.defer = true;
        script.onload = () => {
          this.isLoaded = true;
          this.googleInstance = google;
          resolve(this.googleInstance);
        };
        script.onerror = (error: any) => reject(error);
        document.body.appendChild(script);
      }
    });
  }

  getGoogleInstance(): any {
    return this.googleInstance;
  }
}
