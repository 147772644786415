import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { KeysPipe } from '../../../_pipes/keys.pipe';
import { zip } from 'lodash';

@Component({
  selector: 'app-file-test',
  templateUrl: './file-test.component.html',
})
export class FileTestComponent implements OnInit, OnChanges {

  @Input() message: string;
  parsedData: any;
  @Input() type: string;
  showBefore: boolean = false;
  data: any[] = []; // Initialize data as an empty array
  dataBefore: any[] = []; // Initialize dataBefore as an empty array
  dataAfter: any[] = []; // Initialize dataAfter as an empty array
  keysBefore: any[] = []
  keysAfter: any[] = [];

  constructor(
  ) {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.message) {
      // this.parseXML(this.message);
      if (this.type === 'json') {
        const beforeJson = this.message['before'].replace(/NaN/g, 'null');
        const afterJson = this.message['after'].replace(/NaN/g, 'null');
        this.data = [
          JSON.parse(beforeJson), // Before data
          JSON.parse(afterJson)  // After data
        ];
      }
      else if (this.type == 'xml') {
        this.data = [
          this.message['before'], // Before data
          this.message['after'] // After data
        ];
      } else if (this.type == 'csv') {
        this.message['before'] = JSON.parse(this.message['before']);
        this.message['after'] = JSON.parse(this.message['after']);

        this.keysBefore = Object.keys(this.message['before']);
        this.dataBefore = zip(...Object.values(this.message['before'])).map(arr =>
          arr.reduce((obj, curr, i) => {
            obj[this.keysBefore[i]] = curr;
            return obj;
          }, {})
        );

        this.keysAfter = Object.keys(this.message['after']);
        this.dataAfter = zip(...Object.values(this.message['after'])).map(arr =>
          arr.reduce((obj, curr, i) => {
            obj[this.keysAfter[i]] = curr;
            return obj;
          }, {})
        );
      }
    }
  }

  toggleData() {
    this.showBefore = !this.showBefore;
  }

  ngOnInit(): void {
  }

}
