<!-- fixed-top-->
<nav
  class="top-header header-navbar navbar-expand-md navbar navbar-with-menu navbar-without-dd-arrow navbar-static-top navbar-light navbar-brand-center"
  id="top-header" [ngClass]="selectedHeaderNavBarClass">
  <div class="navbar-wrapper d-flex justify-content-between container-fluid">
    <div id="navbar-header" class="navbar-header">
      <ul class="nav navbar-nav flex-row align-items-center">
        <li class="nav-item mobile-menu d-md-none mr-auto"><a class="nav-link nav-menu-main menu-toggle hidden-xs11"
            (click)="toggleNavigation($event)"><i class="feather ft-menu font-large-1"></i></a></li>
        <li class="nav-item"><a [routerLink]="['/projects']" class="navbar-brand"><img class="brand-logo"
              title="Go to Homepage" alt="Zero to Sixty logo" src="../../../../assets/images/logo/new-logo.svg">
          </a></li>
        <li class="nav-item ml-5">
          <p-dropdown id="orgDropdown" [filter]="true" styleClass="allDropdown" filterBy="name"
            [options]="orgsData.length > 0 ? orgsData : null" [(ngModel)]="selectedCity" optionLabel="name"
            optionValue="id"
            [ariaLabel]="'Selected org: ' + selectedCity ? selectedCity : null +'Press enter to switch Org'"></p-dropdown>
        </li>
        <li>
          <a href="javascript:void(0)" class="nav-link dropdown-user-link menu-dropdown  project-list"
            [routerLink]="['/projects']">{{ 'header.headerdetails.projects' | translate }}</a>
        </li>
        <li class="nav-item d-md-none"><a class="nav-link open-navbar-container" data-toggle="collapse"
            data-target="#navbar-mobile" (click)="toggleNavbar($event)"><i class="la la-ellipsis-v m-0"></i></a></li>

      </ul>
    </div>
    <div class="navbar-container content">

      <div class="collapse navbar-collapse show" id="navbar-mobile">

        <ul class="nav navbar-nav align-item-center float-right">
          <li class="menu-dropdown nav-menu mobile-menu">
            <div class="content-header-right">
              <div ngbDropdown class="d-inline-block float-md-right">
                <button class="btn" id="dropdownBasic1" title="Menu" ngbDropdownToggle>
                  <i class="las la-ellipsis-v m-0"></i>
                </button>
                <div ngbDropdownMenu class="arrow _dropdown_mob dropdown-menu-right" aria-labelledby="dropdownBasic1">
                  <button class="dropdown-item" [routerLink]="['/projects']">{{ 'header.headerdetails.projects' |
                    translate }}</button>
                  <button class="dropdown-item" (click)="goToOrg()">{{ 'header.headerdetails.orgdetails' | translate
                    }}</button>
                  <button class="dropdown-item">{{ 'header.headerdetails.alerts' | translate }}</button>
                  <button class="dropdown-item">{{ 'header.headerdetails.access' | translate }}</button>
                  <button class="dropdown-item">{{ 'header.headerdetails.billing' | translate }}</button>
                  <button class="dropdown-item">{{ 'header.headerdetails.support' | translate }}</button>
                </div>
              </div>

            </div>
          </li>
          <li class="nav-menu-item">
            <a href="javascript:void(0)" class="nav-link dropdown-user-link new-list" (click)="goToOrg()">{{
              'header.headerdetails.orgdetails' | translate }}</a>
          </li>
          <li class="nav-menu-item"><a href="javascript:void(0)"
              class="nav-link dropdown-user-link new-list menu-dropdown">{{ 'header.headerdetails.alerts' | translate
              }}</a>
          </li>
          <li class="nav-menu-item"><a href="javascript:void(0)"
              class="nav-link dropdown-user-link new-list menu-dropdown">{{ 'header.headerdetails.access' | translate
              }}</a>
          </li>
          <li class="nav-menu-item"><a href="javascript:void(0)"
              class="nav-link dropdown-user-link new-list menu-dropdown">{{ 'header.headerdetails.billing' | translate
              }}</a>
          </li>
          <li class="nav-menu-item"><a href="javascript:void(0)"
              class="nav-link dropdown-user-link new-list menu-dropdown">{{ 'header.headerdetails.support' | translate
              }}</a>
          </li>
          <li class="dropdown-user nav-item" ngbDropdown title="User-action">
            <a class="nav-link menu-dropdown dropdown-user-link dropdown-toggle" href="javascript:void(0)"
              ngbDropdownToggle id="dropdownProfileMenu">
              <img *ngIf="currentUser.photoURL" src="{{currentUser.photoURL}}" alt="Profile">
              <em class="fa fa-user profile-picture" *ngIf="!currentUser.photoURL"></em>
              <span *ngIf="currentUser.displayName" class="mr-1 user-name">{{currentUser.displayName}}</span>
              <span *ngIf="!currentUser.displayName" class="mr-1 user-name">{{currentUser.name}}</span>
            </a>
            <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownProfileMenu">
              <a class="dropdown-item" href="javascript:void(0)" tabindex="0" [routerLink]="['/user/user-profile']"
                (click)="editProfileNavigation()"><i class="feather ft-user"></i> {{ 'header.useraction.editprofile' |
                translate
                }} </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="javascript:void(0)" tabindex="0" [routerLink]="" (click)="logout()"><i
                  class="feather ft-power"></i> {{ 'header.useraction.logout' | translate }} </a>
            </div>
          </li>
        </ul>
      </div>
      <ul class="d-md-none">
        <li class="dropdown-user " ngbDropdown title="User-action">
          <a class="nav-link dropdown-user-link dropdown-toggle" href="javascript:void(0)" ngbDropdownToggle
            id="dropdownProfileMenu">
            <img *ngIf="!currentUser.photoURL" class="profile-picture"
              src="../../../../../assets/images/alt-user-pic.png" alt="Profile">
            <i></i>
            <span *ngIf="currentUser.displayName" class="mr-1 user-name">{{currentUser.displayName}}</span>
          </a>
          <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownProfileMenu">
            <a class="dropdown-item" href="javascript:void(0)" tabindex="0" [routerLink]="['/user/user-profile']"
              (click)="editProfileNavigation()"><i class="feather ft-user"></i> {{ 'header.useraction.editprofile' |
              translate }}
            </a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="javascript:void(0)" tabindex="0" [routerLink]="" (click)="logout()"><i
                class="feather ft-power"></i> {{ 'header.useraction.logout' | translate }} </a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>