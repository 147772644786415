<div class="setup-job">
    <form [formGroup]="setupJobForm">
        <div class="row">
            <div class="col-lg-12">
                <div class="form-group page-content row mx-auto">
                    <!-- <h2 class="col-md-3 label-control" for="projectinput1">{{ 'Workspace' }}
                    </h2> -->
                    <div class="col-lg-8 col-md-7 setup-info">
                        <div class="row">
                            <div class="col-lg-8">
                                <label class=" label-control" for="projectinput1">{{ 'Select Model to select policy' }}
                                    <span class="mendatory">*</span></label>
                                <span class="p-fluid">
                                    <p-dropdown [filter]="true" formControlName="selectpolicy" [options]=""
                                        filterBy="name" optionLabel="name" optionValue="value"
                                        placeholder="Select policy" [resetFilterOnHide]="true">
                                        <ng-template let-option pTemplate="item">
                                            <div class="country-item">
                                                <div class="d-flex justify-content-between">
                                                    {{option.name}}
                                                </div>
                                            </div>
                                        </ng-template>
                                    </p-dropdown>
                                </span>

                                <div class="mt-1">
                                    <a href="javascript:void(0)" class="a" #aAddNewPattern tabIndex="0"
                                        (click)="addnewModal()">
                                        <i class="feather ft-settings"></i>
                                        {{ 'Add New model' | translate }}
                                    </a>
                                </div>
                            </div>
                            <div class="col-lg-4"> </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="form-group page-content row mx-auto">
                    <!-- <h2 class="col-md-3 label-control" for="projectinput1">{{ 'Workspace' }}
                    </h2> -->
                    <div class="col-lg-8 col-md-7 setup-info">
                        <div class="row">
                            <div class="col-lg-8">
                                <label class=" label-control" for="projectinput1">{{'Choose a workspace'}}
                                    <span class="mendatory">*</span></label>
                                <span class="p-fluid">
                                    <p-dropdown [filter]="true" formControlName="selectworkspace" [options]=""
                                        filterBy="name" optionLabel="name" optionValue="value"
                                        placeholder="Choose a workspace" [resetFilterOnHide]="true">
                                        <ng-template let-option pTemplate="item">
                                            <div class="country-item">
                                                <div class="d-flex justify-content-between">
                                                    {{option.name}}
                                                </div>
                                            </div>
                                        </ng-template>
                                    </p-dropdown>
                                </span>
                            </div>
                            <div class="col-lg-4"> </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="form-group page-content row mx-auto">
                    <div class="col-lg-8 col-md-8 setup-info">
                        <div class="row">
                            <div class="col-6">
                                <div class="field-radiobutton d-flex align-items-center">
                                    <p-radioButton formControlName="finetunepurpose"
                                        inputId="modelType2"></p-radioButton>
                                    <label class="m-0 pl-1" for="modelType2">Finetune-purpose</label>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="field-radiobutton d-flex align-items-center">
                                    <p-radioButton formControlName="charragpurpose"
                                        inputId="modelType2"></p-radioButton>
                                    <label class="m-0 pl-1" for="modelType2">Chat-RAG-purpose</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="text-right first-step-button space-20 mb-5 first-step-button row">
    <div class="col-md-3"> </div>
    <div class="col-lg-9 col-md-9 setup-info">
        <div class="row">
            <div class="col-lg-6"></div>
            <div class="col-lg-6 text-right mt-2">
                <button class="btn btn-primary">Save</button>
            </div>
        </div>
    </div>
</div>


<p-dialog header="" [(visible)]="createnewmodel" [resizable]="false" [modal]="true" [style]="{width: '45vw'}"
    [draggable]="false" [resizable]="false" [blockScroll]="true">
    <div class="p-dialog-titlebar">
        <button class="p-dialog-titlebar-icon p-dialog-titlebar-close" autofocus="true" aria-label="Close Dialog"
            (click)="createnewmodel = false">
            <span class="pi pi-times"></span>
        </button>
    </div>
    <div>
        <app-org-new-model></app-org-new-model>
    </div>
</p-dialog>