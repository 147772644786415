import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Toast } from 'src/app/_helpers/toast';
import { AuthService } from 'src/app/_services/auth.service';
import { OrgService } from 'src/app/_services/org.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-find-org',
  templateUrl: './find-org.component.html'
})
export class FindOrgComponent implements OnInit {

  findOrgForm: FormGroup;
  orgList: Object[] = [];
  submitted = false;
  @Output() nextStep = new EventEmitter<number>();

  constructor(
    private orgService: OrgService,
    private authService: AuthService,
    private userService: UserService,
    private router: Router
  ) {
    this.findOrgForm = new FormGroup({
      findOrg: new FormControl(null, [Validators.required])
    })
  }

  ngOnInit(): void {
    this.getOrgs();
  }

  getOrgs() {
    this.orgService.getAllOrgs().subscribe({
      next: res => {
        this.orgList = res;
        this.orgList.push({ name: "Create New Org", id: 'createOrg' });
      },
      error: err => {
        Toast.fire({
          icon: 'error',
          html: err.error.error.message
        })
      }
    })
  }

  joinOrg() {
    let tokenData = this.authService.currentUserValue;
    this.userService.updateUserProfile({ orgId: this.findOrgForm.value.findOrg }, tokenData.id).subscribe({
      next: res => {
        if (res.isOrgVerified) {
          this.router.navigate(['/projects']);
        } else {
          Toast.fire({
            icon: 'warning',

            title: 'Your request is still being processed, please wait',
            html: 'You will receive an email once verification is complete.'
          });
        }
      },
      error: err => {
        Toast.fire({
          icon: 'error',
          html: err.error.error.message
        });
      }
    })
  }

  changeToStepUpOrg() {
    if (this.findOrgForm.value.findOrg == 'createOrg') {
      this.nextStep.emit(1)
    }
  }

}
