import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Toast } from 'src/app/_helpers/toast';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html'
})
export class EditProfileComponent implements OnInit {

  userData: Object = {};
  resetForm: boolean;
  userId: string;
  displayEditProfile: boolean;
  name: string;
  email: string;
  profile: any;
  orgName: string;
  loader: boolean;
  profileForm: FormGroup;
  isLoading: boolean = false;
  profileData: any;
  // @Input() orgId: string
  @Input() orgData: string;
  @Input() clear: boolean;
  @Output() updatedOrg = new EventEmitter<Object>();
  @Output() updatedProfile = new EventEmitter<Object>();

  constructor(
    private fb: FormBuilder,
    private userService: UserService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.orgData['id']) {
      this.getprofile();
    }

    if (this.clear) {
      this.profileForm.reset();
    }
  }

  ngOnInit(): void {
    this.profileForm = this.fb.group({
      firstName: ['', Validators.required],
      phoneNo: ['', Validators.required],
      orgName: [''],
      email: ['']
    });
    this.getprofile();
  }

  get f() {
    return this.profileForm.controls;
  }

  getprofile() {
    this.userService.getUserProfile().subscribe({
      next: (res) => {
        if (res) {
          this.userData = res;
          this.profileForm.patchValue({
            firstName: this.userData['name'],
            phoneNo: this.userData['phoneNumber'],
            orgName: this.orgData['name'],
            email: this.userData['email'],
          });
        }
      },
      error: err => {
        Toast.fire({
          icon: 'error',
          html: err.error.error.message
        });
      }
    })
  }

  updateProfile() {
    if (this.profileForm.valid) {
      this.userData['name'] = this.profileForm.value.firstName
      this.userData['phoneNumber'] = this.profileForm.value.phoneNo
      delete this.userData['resetCount'];
      delete this.userData['resetTimestamp'];
      delete this.userData['resetKeyTimestamp'];
      this.isLoading = true;
      this.userService.updateUserProfile(this.userData, this.userData['id']).subscribe({
        next: response => {
          this.updatedProfile.emit({ updated: true })
          this.getprofile();
          this.isLoading = false;
          this.profileForm.reset();
        },
        error: err => {

        }
      });
    } else {
      // TODO: this
    }

  }
}
