import { Component, Input, OnInit } from '@angular/core';
import { FinancialAnalytixService } from 'src/app/_services/financial-analytix.service';
import { SharedService } from 'src/app/_services/shared.service';

@Component({
  selector: 'app-financial',
  templateUrl: './financial.component.html',
})
export class FinancialComponent implements OnInit {

  data: any;
  files = [
    { name: 'Document1.docx', size: '1.2 MB', type: 'Document' },
  ];
  options: any;
  year = '';
  month = '';
  yearOptions = [];
  monthOptions = [];
  index: number;
  @Input() jobId;
  sublevel = 'pnl-overview';
  cat4 = ''
  cat4Options = [];
  @Input() jobDetails;
  pnlOverviewDetails = {};
  pnlOverviewChartDetails = [];
  yoyOverviewDetails = {};
  yoyOverviewChartDetails = [];
  actvsbudAnalysisOverviewDetails = {};
  actvsbudAnalysisOverviewChartDetails = [];
  AvsBDetails = {};
  AvsBChartDetails = [];
  plStatement = {};
  plStatementChartDetails = [];
  sales_percent = 0;
  cogs_percent = 0;
  expense_percent = 0;
  displayInsights: boolean = false;
  insights = []
  insightsTitle = '';
  loading: boolean = false;

  constructor(
    private financialService: FinancialAnalytixService,
    private sharedService: SharedService
  ) {

  }

  ngOnInit() {
    this.onTabChange({ index: 0 });
  }

  onTabChange(e) {
    this.index = e.index;
    this.yearOptions = [];
    this.monthOptions = []
    this.year = "";
    this.month = ""
    this.cat4 = '';
    this.cat4Options = [];
    this.sales_percent = 0;
    this.cogs_percent = 0;
    this.expense_percent = 0;
    switch (this.index) {
      case 0:
        this.sublevel = 'pnl-overview';
        this.fetchDashboard(this.year, this.month);
        break;
      case 1:
        this.sublevel = 'yoy';
        this.fetchDashboard(this.year, this.month);
        break;
      case 2:
        this.sublevel = 'actvsbud-analysis';
        this.fetchDashboard(this.year, this.month);
        break;
      case 3:
        this.sublevel = 'actvsbud-details';
        this.fetchDashboard(this.year, this.month);
        break;
      case 4:
        this.sublevel = 'pnl-statement';
        this.fetchDashboard(this.year, this.month);
        break;
      case 5:
        this.sublevel = 'what_if_analysis';
        this.fetchDashboard(this.year, this.month);
        break;
      case 6:
        this.sublevel = 'balance_sheet';
        this.fetchDashboard(this.year, this.month);
        break;
      case 7:
        this.sublevel = 'cash_flow';
        this.fetchDashboard(this.year, this.month);
        break;
    }
  }


  fetchDashboard(year, month) {
    let params = {}
    if (this.sublevel == 'actvsbud-details') {
      params = {
        job_id: this.jobId,
        year: year,
        month: month,
        category4: this.cat4,
        sublevel: this.sublevel
      }
    } else if (this.sublevel == 'what_if_analysis') {
      params = {
        job_id: this.jobId,
        year: year,
        sublevel: this.sublevel,
        sales_percent: this.sales_percent,
        cogs_percent: this.cogs_percent,
        expense_percent: this.expense_percent
      }
    } else {
      params = {
        job_id: this.jobId,
        year: year,
        month: month,
        sublevel: this.sublevel
      }
    }
    this.financialService.financialDashboard(params).subscribe({
      next: res => {
        console.log(this.sublevel, res)
        switch (this.sublevel) {
          case "pnl-overview":
            this.pnlMapping(res);
            break;
          case "yoy":
            this.yoyMapping(res);
            break;
          case "actvsbud-analysis":
            this.actvsbudAnalysisMapping(res);
            break;
          case "actvsbud-details":
            this.aVsbDetailsMapping(res);
            break;
          case "pnl-statement":
            this.pnlStatementMapping(res);
            break;
          case "what_if_analysis":
            this.whatIfMapping(res);
            break;
          case "balance_sheet":
            this.bsMapping(res)
            break;
          case "cash_flow":
            this.cfMapping(res);
            break;
        }
      }, error: err => {

      }
    })
  }

  changeInFilter(event, type) {
    if (type == 'year') {
      this.year = event.value
    } else if (type == 'month') {
      this.month = event.value
    }
    this.fetchDashboard(this.year, this.month)
  }

  pnlMapping(data) {
    this.pnlOverviewDetails = data;
    if (this.pnlOverviewDetails && this.pnlOverviewDetails['filter-values'] && this.pnlOverviewDetails['filter-values']['Year'] && this.yearOptions.length == 0) {
      this.yearOptions.push({
        name: "All", value: ""
      })
      this.pnlOverviewDetails['filter-values']['Year'].forEach(e => {
        this.yearOptions.push({
          name: e, value: e
        });
      })
    }
    this.chartMappingForPnLOverview();
  }

  yoyMapping(data) {
    this.yoyOverviewDetails = data;
    if (this.yoyOverviewDetails && this.yoyOverviewDetails['filter-values'] && this.yoyOverviewDetails['filter-values']['Year'] && this.yearOptions.length == 0) {
      this.yearOptions.push({
        name: "All", value: ""
      })
      this.yoyOverviewDetails['filter-values']['Year'].forEach(e => {
        this.yearOptions.push({
          name: e, value: e
        });
      })
    }
    this.chartMappingForYoYOverview();
  }

  actvsbudAnalysisMapping(data) {
    this.actvsbudAnalysisOverviewDetails = data;
    if (this.actvsbudAnalysisOverviewDetails && this.actvsbudAnalysisOverviewDetails['filter-values'] && this.actvsbudAnalysisOverviewDetails['filter-values']['Year'] && this.yearOptions.length == 0) {
      this.yearOptions.push({
        name: "All", value: ""
      })
      this.actvsbudAnalysisOverviewDetails['filter-values']['Year'].forEach(e => {
        this.yearOptions.push({
          name: e, value: e
        });
      })
    }
    this.chartMappingForActVsBudAnalysisOverview();
  }

  aVsbDetailsMapping(data) {
    this.AvsBDetails = data;
    if (this.AvsBDetails && this.AvsBDetails['filter-values'] && this.AvsBDetails['filter-values']['Year'] && this.yearOptions.length == 0) {
      this.yearOptions.push({
        name: "All", value: ""
      })
      this.AvsBDetails['filter-values']['Year'].forEach(e => {
        this.yearOptions.push({
          name: e, value: e
        });
      })
    }
    if (this.AvsBDetails && this.AvsBDetails['filter-values'] && this.AvsBDetails['filter-values']['Category4'] && this.cat4Options.length == 0) {
      this.cat4Options.push({
        name: "All", value: ""
      })
      this.AvsBDetails['filter-values']['Category4'].forEach(e => {
        this.cat4Options.push({
          name: e, value: e
        });
      })
    }
    this.chartMappingForAvsBDetailsOverview();
  }

  pnlStatementMapping(data) {
    this.plStatement = data;
    if (this.plStatement && this.plStatement['filter-values'] && this.plStatement['filter-values']['Year'] && this.yearOptions.length == 0) {
      this.yearOptions.push({
        name: "All", value: ""
      })
      this.plStatement['filter-values']['Year'].forEach(e => {
        this.yearOptions.push({
          name: e, value: e
        });
      })
    }
    this.chartMappingForPLStatement();
  }

  whatIf = {}
  whatIfChartDetails = [];
  whatIfMapping(data) {
    this.whatIf = data;
    if (this.whatIf && this.whatIf['filter-values'] && this.whatIf['filter-values']['Year'] && this.yearOptions.length == 0) {
      this.yearOptions.push({
        name: "All", value: ""
      })
      this.whatIf['filter-values']['Year'].forEach(e => {
        this.yearOptions.push({
          name: e, value: e
        });
      })
    }

    this.chartMappingForWhatIf();
  }

  bs = {}
  bsChartDetails = []
  bsMapping(data) {
    this.bs = data;
    if (this.bs && this.bs['filter-values'] && this.bs['filter-values']['Year'] && this.yearOptions.length == 0) {
      this.yearOptions.push({
        name: "All", value: ""
      })
      this.bs['filter-values']['Year'].forEach(e => {
        this.yearOptions.push({
          name: e, value: e
        });
      })
    }

    this.chartMappingForBS();
  }

  cf = {};
  cfChartDetails = []
  cfMapping(data) {
    this.cf = data;
    if (this.cf && this.cf['filter-values'] && this.cf['filter-values']['Year'] && this.yearOptions.length == 0) {
      this.yearOptions.push({
        name: "All", value: ""
      })
      this.cf['filter-values']['Year'].forEach(e => {
        this.yearOptions.push({
          name: e, value: e
        });
      })
    }

    this.chartMappingForCF();
  }


  chartMappingForPnLOverview() {
    this.pnlOverviewChartDetails = []
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    let arr = [
      {
        name: 'Revenue Trend', plot: 'plot1', type: 'line', options: {
          maintainAspectRatio: false,
          aspectRatio: 0.6,
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            },
            y: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            }
          }
        }
      },
      {
        name: 'Revenue vs Expense', plot: 'plot2', type: "bar", options: {
          maintainAspectRatio: false,
          aspectRatio: 0.8,
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: textColorSecondary,
                font: {
                  weight: 500
                }
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            },
            y: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            }

          }
        }
      },
      {
        name: 'Top Revenue Accounts', plot: 'plot4', type: "doughnut", options: {
          cutout: '60%',
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          }
        }
      },
      {
        name: 'Net Profit Trend', plot: 'plot5', type: 'line', options: {
          maintainAspectRatio: false,
          aspectRatio: 0.6,
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder
              }
            },
            y: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder
              }
            }
          }
        }
      }
    ]
    for (let i = 0; i < arr.length; i++) {
      let obj = {
        title: arr[i]['name'],
        datasets: this.pnlOverviewDetails['charts'][arr[i]['plot']],
        options: arr[i]['options'],
        type: arr[i]['type']
      }
      this.pnlOverviewChartDetails.push(obj)

    }
    console.log('pnlover', this.pnlOverviewChartDetails)
  }

  chartMappingForYoYOverview() {
    this.yoyOverviewChartDetails = []
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    let arr = [
      {
        name: 'Revenue Comparison', plot: 'plot1', type: 'line', options: {
          maintainAspectRatio: false,
          aspectRatio: 0.6,
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder
              }
            },
            y: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder
              }
            },
            y1: {
              type: 'linear',
              display: true,
              position: 'right',
              ticks: {
                color: textColorSecondary
              },
              grid: {
                drawOnChartArea: false,
                color: surfaceBorder
              }
            }
          }
        }
      },
      {
        name: 'Expense Comparison', plot: 'plot2', type: "line", options: {
          maintainAspectRatio: false,
          aspectRatio: 0.6,
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder
              }
            },
            y: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder
              }
            },
            y1: {
              type: 'linear',
              display: true,
              position: 'right',
              ticks: {
                color: textColorSecondary
              },
              grid: {
                drawOnChartArea: false,
                color: surfaceBorder
              }
            }
          }
        }
      },
      {
        name: 'Net Profit - YoY Analysis', plot: 'plot3', type: "line", options: {
          maintainAspectRatio: false,
          aspectRatio: 0.6,
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder
              }
            },
            y: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder
              }
            },
            y1: {
              type: 'linear',
              display: true,
              position: 'right',
              ticks: {
                color: textColorSecondary
              },
              grid: {
                drawOnChartArea: false,
                color: surfaceBorder
              }
            }
          }
        }
      },
      {
        name: 'Revenue Comparison', plot: 'plot4', type: 'bar', options: {
          indexAxis: 'y',
          maintainAspectRatio: false,
          aspectRatio: 0.8,
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: textColorSecondary,
                font: {
                  weight: 500
                }
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            },
            y: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            }
          }
        }
      },
      {
        name: 'Expense Comparison', plot: 'plot5', type: 'bar', options: {
          indexAxis: 'y',
          maintainAspectRatio: false,
          aspectRatio: 0.8,
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: textColorSecondary,
                font: {
                  weight: 500
                }
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            },
            y: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            }
          }
        }
      }
    ]
    for (let i = 0; i < arr.length; i++) {
      let obj = {
        title: arr[i]['name'],
        datasets: this.yoyOverviewDetails['charts'][arr[i]['plot']],
        options: arr[i]['options'],
        type: arr[i]['type']
      }
      this.yoyOverviewChartDetails.push(obj)

    }
    console.log('pnlover', this.yoyOverviewChartDetails)
  }

  chartMappingForActVsBudAnalysisOverview() {
    this.actvsbudAnalysisOverviewChartDetails = []
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    let arr = [
      {
        name: 'Revenue - Actual vs Budget', plot: 'plot1', type: 'line', preType: 'chart', options: {
          maintainAspectRatio: false,
          aspectRatio: 0.6,
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder
              }
            },
            y: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder
              }
            }
          }
        }
      },
      {
        name: 'Expense - Actual vs Budget', plot: 'plot2', type: "line", preType: 'chart', options: {
          maintainAspectRatio: false,
          aspectRatio: 0.6,
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder
              }
            },
            y: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder
              }
            }
          }
        }
      },
      {
        name: 'Revenue', plot: 'plot3', type: "line", preType: 'table', options: {

        }
      },
      {
        name: 'Expense', plot: 'plot4', type: 'bar', preType: 'table', options: {

        }
      },

    ]
    for (let i = 0; i < arr.length; i++) {
      let obj = {
        title: arr[i]['name'],
        datasets: arr[i]['preType'] == 'table' ? [this.actvsbudAnalysisOverviewDetails['charts'][arr[i]['plot']]] : this.actvsbudAnalysisOverviewDetails['charts'][arr[i]['plot']],
        options: arr[i]['options'],
        type: arr[i]['type'],
        preType: arr[i]['preType'] ? arr[i]['preType'] : ''
      }
      this.actvsbudAnalysisOverviewChartDetails.push(obj)

    }
    console.log('pnlover', this.actvsbudAnalysisOverviewChartDetails)
  }

  chartMappingForAvsBDetailsOverview() {
    this.AvsBChartDetails = []
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    let arr = [
      {
        name: 'Revenue Budget Forecast', plot: 'plot1', type: 'line', preType: 'chart', options: {
          maintainAspectRatio: false,
          aspectRatio: 0.6,
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            },
            y: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            }
          }
        }
      },
      {
        name: 'Expense Budget Forecast', plot: 'plot2', type: "line", preType: 'chart', options: {
          maintainAspectRatio: false,
          aspectRatio: 0.6,
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            },
            y: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            }
          }
        }
      },
      {
        name: 'Revenue Forecast', plot: 'plot3', type: "line", preType: 'chart', options: {
          maintainAspectRatio: false,
          aspectRatio: 0.6,
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            },
            y: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            }
          }
        }
      },

      {
        name: 'Expense Forecast', plot: 'plot5', type: 'line', preType: 'chart', options: {
          maintainAspectRatio: false,
          aspectRatio: 0.6,
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            },
            y: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            }
          }
        }
      },
      {
        name: 'Actual Vs Budget Detail', plot: 'plot4', type: 'line', preType: 'table', options: {}
      }
    ]
    for (let i = 0; i < arr.length; i++) {
      if (Object.keys(this.AvsBDetails['charts'][arr[i]['plot']]).length) {
        let obj = {
          title: arr[i]['name'],
          datasets: arr[i]['preType'] == 'table' ? [this.AvsBDetails['charts'][arr[i]['plot']]] : this.AvsBDetails['charts'][arr[i]['plot']],
          options: arr[i]['options'],
          type: arr[i]['type'],
          preType: arr[i]['preType'] ? arr[i]['preType'] : ''
        }
        this.AvsBChartDetails.push(obj)
      }
    }
    console.log('pnlover', this.AvsBChartDetails)
  }

  chartMappingForPLStatement() {
    this.plStatementChartDetails = this.plStatement['charts']['data']
  }

  chartMappingForWhatIf() {
    this.whatIfChartDetails = []
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    let arr = [
      {
        name: 'Monthly', plot: 'plot1', type: 'line', preType: 'chart', options: {
          maintainAspectRatio: false,
          aspectRatio: 0.6,
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder
              }
            },
            y: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder
              }
            },
            y1: {
              type: 'linear',
              display: true,
              position: 'right',
              ticks: {
                color: textColorSecondary
              },
              grid: {
                drawOnChartArea: false,
                color: surfaceBorder
              }
            }
          }
        }
      }
    ]

    for (let i = 0; i < arr.length; i++) {
      if (Object.keys(this.whatIf['charts'][arr[i]['plot']]).length) {
        let obj = {
          title: arr[i]['name'],
          datasets: arr[i]['preType'] == 'table' ? [this.whatIf['charts'][arr[i]['plot']]] : this.whatIf['charts'][arr[i]['plot']],
          options: arr[i]['options'],
          type: arr[i]['type'],
          preType: arr[i]['preType'] ? arr[i]['preType'] : ''
        }
        this.whatIfChartDetails.push(obj)
      }
    }
    console.log('pnlover', this.whatIfChartDetails)
  }

  chartMappingForBS() {
    this.bsChartDetails = []
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    let arr = [
      {
        name: 'Assets by Category', plot: 'plot1', type: 'line', preType: 'chart', options: {
          maintainAspectRatio: false,
          aspectRatio: 0.6,
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder
              }
            },
            y: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder
              }
            },
            y1: {
              type: 'linear',
              display: true,
              position: 'right',
              ticks: {
                color: textColorSecondary
              },
              grid: {
                drawOnChartArea: false,
                color: surfaceBorder
              }
            }
          }
        }
      },
      {
        name: 'Liabilities by Category', plot: 'plot2', type: 'line', preType: 'chart', options: {
          maintainAspectRatio: false,
          aspectRatio: 0.6,
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder
              }
            },
            y: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder
              }
            },
            y1: {
              type: 'linear',
              display: true,
              position: 'right',
              ticks: {
                color: textColorSecondary
              },
              grid: {
                drawOnChartArea: false,
                color: surfaceBorder
              }
            }
          }
        }
      },
      {
        name: 'Current Assets', plot: 'plot3', type: 'bar', preType: 'chart', options: {
          maintainAspectRatio: false,
          aspectRatio: 0.8,
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: textColorSecondary,
                font: {
                  weight: 500
                }
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            },
            y: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            }

          }
        }
      },
      {
        name: 'Non Current Assets', plot: 'plot4', type: 'bar', preType: 'chart', options: {
          maintainAspectRatio: false,
          aspectRatio: 0.8,
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: textColorSecondary,
                font: {
                  weight: 500
                }
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            },
            y: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            }

          }
        }
      },
      {
        name: 'Current Liabilities', plot: 'plot5', type: 'bar', preType: 'chart', options: {
          maintainAspectRatio: false,
          aspectRatio: 0.8,
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: textColorSecondary,
                font: {
                  weight: 500
                }
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            },
            y: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            }

          }
        }
      },
      {
        name: 'Non Current Liabilities', plot: 'plot6', type: 'bar', preType: 'chart', options: {
          maintainAspectRatio: false,
          aspectRatio: 0.8,
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: textColorSecondary,
                font: {
                  weight: 500
                }
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            },
            y: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            }

          }
        }
      },
      {
        name: 'Assets Var %', plot: 'plot7', type: 'bar', preType: 'chart', options: {
          maintainAspectRatio: false,
          aspectRatio: 0.8,
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: textColorSecondary,
                font: {
                  weight: 500
                }
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            },
            y: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            }

          }
        }
      },
      {
        name: 'Liabilities Var %', plot: 'plot8', type: 'bar', preType: 'chart', options: {
          maintainAspectRatio: false,
          aspectRatio: 0.8,
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: textColorSecondary,
                font: {
                  weight: 500
                }
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            },
            y: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false
              }
            }

          }
        }
      },
      {
        name: 'Current Assets', plot: 'plot9', type: 'line', preType: 'table', options: {}
      },
      {
        name: 'Non Current Assets', plot: 'plot10', type: 'line', preType: 'table', options: {}
      },
      {
        name: 'Current Liabilities', plot: 'plot11', type: 'line', preType: 'table', options: {}
      },
      {
        name: 'Non Current Liabilities', plot: 'plot12', type: 'line', preType: 'table', options: {}
      },
    ]

    for (let i = 0; i < arr.length; i++) {
      if (Object.keys(this.bs['charts'][arr[i]['plot']]).length) {
        let obj = {
          title: arr[i]['name'],
          datasets: arr[i]['preType'] == 'table' ? [this.bs['charts'][arr[i]['plot']]] : this.bs['charts'][arr[i]['plot']],
          options: arr[i]['options'],
          type: arr[i]['type'],
          preType: arr[i]['preType'] ? arr[i]['preType'] : ''
        }
        this.bsChartDetails.push(obj)
      }
    }
    console.log('pnlover', this.bsChartDetails)
  }

  chartMappingForCF() {
    this.cfChartDetails = []
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    let arr = [
      {
        name: 'Accounts Receivable', plot: 'plot1', type: 'pie', preType: 'chart', options: {
          plugins: {
            legend: {
              labels: {
                usePointStyle: true,
                color: textColor
              }
            }
          }
        }
      },
      {
        name: 'Accounts Payable', plot: 'plot2', type: 'doughnut', preType: 'chart', options: {
          cutout: '60%',
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          }
        }
      },
      {
        name: 'Cash Flow', plot: 'plot3', type: 'line', preType: 'chart', options: {
          maintainAspectRatio: false,
          aspectRatio: 0.6,
          plugins: {
            legend: {
              labels: {
                color: textColor
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder
              }
            },
            y: {
              ticks: {
                color: textColorSecondary
              },
              grid: {
                color: surfaceBorder
              }
            },
            y1: {
              type: 'linear',
              display: true,
              position: 'right',
              ticks: {
                color: textColorSecondary
              },
              grid: {
                drawOnChartArea: false,
                color: surfaceBorder
              }
            }
          }
        }
      },
      {
        name: 'Cash Flow From Operations', plot: 'plot4', type: 'line', preType: 'table', options: {}
      },
    ]

    for (let i = 0; i < arr.length; i++) {
      if (Object.keys(this.cf['charts'][arr[i]['plot']]).length) {
        let obj = {
          title: arr[i]['name'],
          datasets: arr[i]['preType'] == 'table' ? [this.cf['charts'][arr[i]['plot']]] : this.cf['charts'][arr[i]['plot']],
          options: arr[i]['options'],
          type: arr[i]['type'],
          preType: arr[i]['preType'] ? arr[i]['preType'] : ''
        }
        this.cfChartDetails.push(obj)
      }
    }
    console.log('pnlover', this.cfChartDetails)
  }

  onChangleSlider() {
    this.fetchDashboard(this.year, this.month)
  }

  invokeChatSliding(type) {
    this.sharedService.sendClickEvent({ jobId: this.jobId, type: 'financial' }, type);
  }

  getInsights(chart) {
    this.loading = true;
    let body = {}
    this.displayInsights = true;
    this.insightsTitle = chart['title']
    body['plot_data'] = chart['datasets']
    if (this.sublevel == 'what_if_analysis') {
      body['filters'] = {
        year: '', sales_percent: this.sales_percent,
        cogs_percent: this.cogs_percent,
        expense_percent: this.expense_percent
      }
    } else if (this.sublevel == 'actvsbud-details') {
      body['filters'] = {
        year: '', sales_percent: this.sales_percent,
        category4: this.cat4,
      }
    } else {
      body['filters'] = { year: this.year, month: this.month }
    }
    this.financialService.fetchInsights(body).subscribe({
      next: res => {
        this.loading = false;
        console.log('insights', res)
        this.insights = res['insight'];
      },
      error: err => {
        this.loading = false;
      }
    })
  }
}
