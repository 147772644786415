import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Toast } from 'src/app/_helpers/toast';
import { AuthService } from 'src/app/_services/auth.service';
import { BrewChatService } from 'src/app/_services/brew-chat.service';
import { LlmService } from 'src/app/_services/llm.service';
import { OrgService } from 'src/app/_services/org.service';
import { UserService } from 'src/app/_services/user.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-bot-chat',
  templateUrl: './bot-chat.component.html'
})
export class BotChatComponent implements OnChanges {

  jobId;
  question: string = '';
  botName: string;
  chatMessages = [];
  loading: boolean = false;
  userData: Object = {};
  orgData: Object = {};
  chatDetails: Object = {};
  latestQues: string = '';
  activeThread: string = ''
  threads = []
  loadThreads: boolean = false;
  queriesData: Object[] = []
  @Input() chatbotData;

  constructor(
    private LLMService: LlmService,
    private authService: AuthService,
    private userService: UserService,
    private orgService: OrgService,
    private brewchatService: BrewChatService
  ) {

  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.chatbotData) {
      this.jobId = this.chatbotData['job_id']
    }
  }

  ngOnInit(): void {
    this.getprofile();
    this.fetchChatDetails();
    this.getAllThreads();
  }

  fetchChatDetails() {
    this.LLMService.botHandler({
      method: 'POST',
      path: '/get_details_chatbot',
      params: { job_id: this.jobId }
    }).subscribe({
      next: res => {
        this.chatDetails = JSON.parse(res['details'])
        this.botName = this.chatDetails['bot_name']
      }, error: err => {

      }
    })
  }

  getAllThreads() {
    this.loadThreads = true;
    const currentUser = this.authService.currentUserValue;
    this.LLMService.fetchThreads({
      jobId: this.jobId,
      orgId: currentUser.orgId
    }).subscribe({
      next: async res => {
        this.queriesData = res;
        this.threads = await this.findUniqueThreads(res);
        this.loadThreads = false;
      }, error: err => {
        this.loadThreads = false;
      }
    })
  }

  async findUniqueThreads(data) {
    const uniqueThreadIdsSet = new Set();
    await data.forEach(async obj => {
      uniqueThreadIdsSet.add(obj.threadId);
    });
    return Array.from(uniqueThreadIdsSet);
  }

  findObjectByThreadId(threadId, mode: string) {
    const filteredObjects = this.queriesData.filter(obj => obj['threadId'] === threadId);
    const sortedObjects = filteredObjects.sort((a, b) => a['created'] - b['created']);
    if (mode == 'single') {
      return sortedObjects[0]['question'];
    } else {
      return sortedObjects
    }
  }

  deleteThread(event, id) {
    if (id == this.activeThread) {
      this.activeThread = '';
      this.chatMessages = []
    }
    this.brewchatService.delThread(id).subscribe({
      next: res => {
        this.threads = this.threads.filter(thread => thread !== id);
      }, error: err => {

      }
    })
  }

  fetchThread() {
    const currentUser = this.authService.currentUserValue;
    this.LLMService.fetchThreads({
      jobId: this.jobId,
      orgId: currentUser.orgId,
      threadId: this.activeThread,
    }).subscribe({
      next: res => {
        this.chatMessages = res;
      }, error: err => {

      }
    })
  }

  validateImg(data) {
    if (typeof data == 'string' && data.startsWith('i')) {
      return true
    } else {
      return false
    }
  }

  getprofile() {
    this.userService.getUserProfile().subscribe({
      next: (res) => {
        if (res) {
          this.userData = res;
          this.orgService.getOrgById(res.orgId).subscribe({
            next: r => {
              this.orgData = r
            },
            error: err => {
              Toast.fire({
                icon: 'error',
                html: err.error.error.message
              });
            }
          })
        }
      },
      error: err => {
        Toast.fire({
          icon: 'error',
          html: err.error.error.message
        });
      }
    })
  }

  async postQuery() {
    if (this.activeThread == '') {
      this.activeThread = await uuidv4()
    }
    this.loading = true;
    this.latestQues = this.question
    const currentUser = this.authService.currentUserValue;
    this.question = ''
    this.LLMService.chatbotQuery({
      orgId: currentUser.orgId,
      userId: currentUser.id,
      jobId: this.jobId,
      threadId: this.activeThread,
      question: this.latestQues,
      botName: this.botName
    }).subscribe({
      next: res => {
        this.fetchThread()
        this.loading = false;
      }, error: err => {
        this.loading = false;
      }
    })
  }

  newChat() {
    this.activeThread = '';
    this.getAllThreads()
  }

  setUserActive(thread) {
    this.activeThread = thread;
    this.fetchThread();
  }

}
