import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Toast } from 'src/app/_helpers/toast';
import { AuthService } from 'src/app/_services/auth.service';
import { LlmService } from 'src/app/_services/llm.service';
import { OrgService } from 'src/app/_services/org.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-llm-chat',
  templateUrl: './llm-chat.component.html'
})
export class LlmChatComponent implements OnChanges {

  jobId;
  userData: Object = {};
  orgData: Object = {};
  llmDetails: Object = {};
  chatMessages = [];
  loading: boolean = false;
  question: string = ''
  @Input() llmData;
  latestQues: string = ''

  constructor(
    private userService: UserService,
    private LLMService: LlmService,
    private authService: AuthService,
    private orgService: OrgService
  ) {
   
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.llmData) {
      this.jobId = this.llmData['job_id']
    }
  }

  ngOnInit(): void {
    this.fetchLLMs();
    this.getprofile();
  }

  goback() {
    window.history.back();
  }

  getprofile() {
    this.userService.getUserProfile().subscribe({
      next: (res) => {
        if (res) {
          this.userData = res;
          this.orgService.getOrgById(res.orgId).subscribe({
            next: r => {
              this.orgData = r
            },
            error: err => {
              Toast.fire({
                icon: 'error',
                html: err.error.error.message
              });
            }
          })
        }
      },
      error: err => {
        Toast.fire({
          icon: 'error',
          html: err.error.error.message
        });
      }
    })
  }

  validateImg(data) {
    if (typeof data == 'string' && data.startsWith('i')) {
      return true
    } else {
      return false
    }
  }

  fetchLLMs() {
    const currentUser = this.authService.currentUserValue;
    this.LLMService.getAllLLMs({ org_id: currentUser.orgId }).subscribe({
      next: res => {
        let genModels = JSON.parse(res['details'])
        for (let i = 0; i < genModels.length; i++) {
          if (this.jobId == genModels[i]['job_id']) {
            this.llmDetails = genModels[i];
            break;
          }
        }
      }, error: err => {
        console.error(err)
      }
    })
  }

  postQuery() {
    this.loading = true;
    this.latestQues = this.question;
    setTimeout(()=>{
      this.question
    }, 1000)
    this.LLMService.chatWithFineTuneLLM({job_id: this.jobId, prompt: this.question}).subscribe({
      next: res => {
        this.chatMessages.push({
          question: this.latestQues,
          answer: res['response']
        })
        this.loading = false;
      }, error: err => {
        this.loading = false;
      }
    })
  }

}
