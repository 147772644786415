<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-body">
            <section class="flexbox-container">
                <!-- <div class="card-title text-center mb-4 mt-5">
                    <img src="../../../assets/images/logo/new-logo.svg" alt="Zero to Sixty logo" [tabIndex]="1"
                        style="height: 60px;">
                </div> -->
                <div class="col-12 d-flex justify-content-center forgot-password-container">
                    <div class="login-box ">
                        <div class="card-title text-center mb-4 mt-5">
                            <img src="../../../assets/images/logo/new-logo.svg" alt="Zero to Sixty logo" [tabIndex]="1"
                                style="height: 60px;">
                        </div>
                        <div class=" box-shadow-2">
                            <div class="card login border-grey border-lighten-3   py-1 m-0">
                                <div class="card-content" *ngIf="!showResetForm">
                                    <div class="card-body">
                                        <h1 class="text-center log-head mb-2">{{ 'confirmemail.emailheading' |
                                            translate}}</h1>
                                    </div>

                                    <form class="form-horizontal" name="resetForm" [formGroup]="resetForm"
                                        [ariaLabel]="'resetForm form'" (ngSubmit)="submit()">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <span class="p-fluid">
                                                        <label>{{ 'inputforms.labels.confirmemail' | translate
                                                            }}</label>
                                                        <input type="email" formControlName="email"
                                                            [placeholder]=" 'inputforms.placeholder.confirmemail' | translate "
                                                            class="form-control mw-100p"
                                                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                                                            aria-describedby="email-description" />
                                                        <div id="email-description" *ngIf="submitted && f.email.errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="f.email.errors.required">
                                                                {{ 'inputforms.formerrors.requiredemail' | translate }}
                                                            </div>
                                                            <div *ngIf="f.email.errors.email">
                                                                {{ 'inputforms.formerrors.invalidemail' | translate }}
                                                            </div>
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="form-group d-flex justify-content-end">
                                                    <button type="submit" class="btn btn-raised btn-warning btn-small"
                                                        (click)="forgotPassword()">
                                                        {{ 'buttontitle.nextbutton' | translate }}
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="form-group card-body text-center existing-user mt-1">
                                                    <p>{{ 'confirmemail.backto' | translate }} <a
                                                            [routerLink]="['/login']" class=" underline"> {{
                                                            'confirmemail.loginlink' | translate }}</a></p>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div *ngIf="showResetForm">
                                    <div class="user-change-pwd">
                                        <app-change-password [key]="this.key"></app-change-password>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center mt-3">
                            <span class="copyright">{{ 'footer.copyright' | translate }} &copy; {{ 'footer.brudatainc' |
                                translate }} </span>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>