import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Toast } from 'src/app/_helpers/toast';
import { OrgService } from 'src/app/_services/org.service';

@Component({
  selector: 'app-update-org',
  templateUrl: './update-org.component.html'
})
export class UpdateOrgComponent implements OnInit, OnChanges {

  orgForm: FormGroup;
  @Input() orgId: string
  orgDetails: Object = {};
  @Output() updatedOrg = new EventEmitter<Object>();
  @Input() clear: boolean;
  isLoading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private orgService: OrgService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.orgId) {
      this.fetchOrgDetails();
    }

    if (this.clear) {
      this.orgForm.reset();
    }
  }

  ngOnInit(): void {
    this.orgForm = this.fb.group({
      name: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(50),]),
      address: new FormControl('', [Validators.required, Validators.maxLength(500),]),
      website: new FormControl('', [Validators.required, Validators.maxLength(50),]),
    });
  }

  get f() { return this.orgForm.controls }

  fetchOrgDetails() {
    if (this.orgId) {
      this.orgService.getOrgById(this.orgId).subscribe({
        next: res => {
          this.orgDetails = res;
          this.orgForm.patchValue({
            name: this.orgDetails['name'],
            address: this.orgDetails['address'],
            website: this.orgDetails['website']

          });

        },
        error: err => {
          Toast.fire({
            icon: 'error',
            html: err.error.error.message
          });
        }
      })
    }
  }

  updateOrg() {
    this.orgDetails['name'] = this.orgForm.value.name;
    this.orgDetails['address'] = this.orgForm.value.address;
    this.orgDetails['website'] = this.orgForm.value.website;
    this.isLoading = true;
    if (this.orgDetails && this.orgDetails['id']) {
      this.orgService.updateOrgById(this.orgDetails, this.orgDetails['id']).subscribe({
        next: res => {
          this.updatedOrg.emit({ updated: true });
          this.orgForm.reset();
          this.isLoading = false;
        }, error: err => {
          this.updatedOrg.emit({ updated: false, error: err });
        }
      })
    }
  }
}
