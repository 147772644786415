export const locales = [
  { name: "Albanian (sq_AL)", value: "sq_AL" },
  { name: "Arabic Bahrain (ar_BH)", value: "ar_BH" },
  { name: "Arabic Egypt (ar_EG)", value: "ar_EG" },
  { name: "Arabic Jordan (ar_JO)", value: "ar_JO" },
  { name: "Arabic Palestinian Territories (ar_PS)", value: "ar_PS" },
  { name: "Arabic Saudi Arabia (ar_SA)", value: "ar_SA" },
  { name: "Arabic Standard (ar_AA)", value: "ar_AA" },
  { name: "Arabic United Arab Emirates (ar_AE)", value: "ar_AE" },
  { name: "Azerbaijani (az_AZ)", value: "az_AZ" },
  { name: "Bengali (bn_BD)", value: "bn_BD" },
  { name: "Bosnian (bs_BA)", value: "bs_BA" },
  { name: "Bulgarian (bg_BG)", value: "bg_BG" },
  { name: "Chinese Simplified (zh_CN)", value: "zh_CN" },
  { name: "Chinese Taiwan (zh_TW)", value: "zh_TW" },
  { name: "Croatian (hr_HR)", value: "hr_HR" },
  { name: "Czech (cs_CZ)", value: "cs_CZ" },
  { name: "Danish (da_DK)", value: "da_DK" },
  { name: "Danish Denmark (dk_DK)", value: "dk_DK" },
  { name: "Dutch (nl)", value: "nl" },
  { name: "Dutch Belgium (nl_BE)", value: "nl_BE" },
  { name: "Dutch Netherlands (nl_NL)", value: "nl_NL" },
  { name: "English (en)", value: "en" },
  { name: "English Australia (en_AU)", value: "en_AU" },
  { name: "English Canada (en_CA)", value: "en_CA" },
  { name: "English India (en_IN)", value: "en_IN" },
  { name: "English Ireland (en_IE)", value: "en_IE" },
  { name: "English New Zealand (en_NZ)", value: "en_NZ" },
  { name: "English Thailand (en_TH)", value: "en_TH" },
  { name: "English United Kingdom (en_GB)", value: "en_GB" },
  { name: "English United States (en_US)", value: "en_US" },
  { name: "Finnish (fi_FI)", value: "fi_FI" },
  { name: "French Canada (fr_CA)", value: "fr_CA" },
  { name: "French France (fr_FR)", value: "fr_FR" },
  { name: "French Switzerland (fr_CH)", value: "fr_CH" },
  { name: "German (de)", value: "de" },
  { name: "German Austria (de_AT)", value: "de_AT" },
  { name: "German Germany (de_DE)", value: "de_DE" },
  { name: "German Switzerland (de_CH)", value: "de_CH" },
  { name: "Greek Cyprus (el_CY)", value: "el_CY" },
  { name: "Greek Greece (el_GR)", value: "el_GR" },
  { name: "Hebrew (he_IL)", value: "he_IL" },
  { name: "Hindi (hi_IN)", value: "hi_IN" },
  { name: "Hungarian (hu_HU)", value: "hu_HU" },
  { name: "Indonesian (id_ID)", value: "id_ID" },
  { name: "Italian (it)", value: "it" },
  { name: "Italian Italy (it_IT)", value: "it_IT" },
  { name: "Italian Switzerland (it_CH)", value: "it_CH" },
  { name: "Japanese (ja_JP)", value: "ja_JP" },
  { name: "Korean (ko_KR)", value: "ko_KR" },
  { name: "Lithuanian (lt_LT)", value: "lt_LT" },
  { name: "Persian (fa_IR)", value: "fa_IR" },
  { name: "Polish (pl_PL)", value: "pl_PL" },
  { name: "Portuguese Brazil (pt_BR)", value: "pt_BR" },
  { name: "Portuguese Portugal (pt_PT)", value: "pt_PT" },
  { name: "Republic of South Africa (en_ZA)", value: "en_ZA" },
  { name: "Romanian (ro_RO)", value: "ro_RO" },
  { name: "Russian (ru_RU)", value: "ru_RU" },
  { name: "Slovak (sk_SK)", value: "sk_SK" },
  { name: "Spanish (es)", value: "es" },
  { name: "Spanish Canada (es_CA)", value: "es_CA" },
  { name: "Spanish Chile (es_CL)", value: "es_CL" },
  { name: "Spanish Colombia (es_CO)", value: "es_CO" },
  { name: "Spanish Mexico (es_MX)", value: "es_MX" },
  { name: "Spanish Spain (es_ES)", value: "es_ES" },
  { name: "Swedish (sv_SE)", value: "sv_SE" },
  { name: "Thai (th_TH)", value: "th_TH" },
  { name: "Turkish (tr_TR)", value: "tr_TR" },
  { name: "Ukrainian (uk_UA)", value: "uk_UA" },
  { name: "Vietnamese (vi_VN)", value: "vi_VN" },
];

export const strategy = [
  { name: "address", value: 1 },
  { name: "street_name", value: 2 },
  { name: "city", value: 3 },
  { name: "state", value: 4 },
  { name: "license_plate", value: 10 },
  { name: "ssn", value: 11 },
  { name: "said", value: 12 },
  { name: "job", value: 13 },
  { name: "personal_email", value: 14 },
  { name: "company_email", value: 15 },
  { name: "ip_address", value: 16 },
  { name: "domain_name", value: 17 },
  { name: "device_address", value: 18 },
  { name: "bank_account", value: 20 },
  { name: "swift_code", value: 21 },
  { name: "random_letter", value: 23 },
  { name: "country", value: 24 },
  { name: "checksum", value: 25 },
  { name: "barcode", value: 26 },
  { name: "color", value: 27 },
  { name: "currency", value: 28 },
  { name: "date_time", value: 29 },
  { name: "land_coordinates", value: 30 },
  { name: "personal_profile", value: 31 },
  { name: "gender", value: 32 },
  { name: "building_number", value: 34 },
  { name: "street_address", value: 35 },
  { name: "address_line2", value: 36 },
  { name: "company_name", value: 37 },
  { name: "postal_code", value: 38 },
  { name: "zip_code", value: 39 },
  { name: "credit_card_type", value: 40 },
  { name: "credit_card_expiry_date", value: 41 },
  // { name: "marital_status", value: 42 },
  // { name: "age", value: 43 },
  { name: "year", value: 44 },
  { name: "month_number", value: 45 },
  { name: "day_number", value: 46 },
  { name: "list", value: 57 },
];

export const baseCreditCard = [
  {
    type: "dropdown",
    name: "pattern",
    label: "Strategy",
    selectLabel: "Select Strategy",
    value: null,
    callbackPatternEvent: true,
    validations: {
      required: true,
    },
    options: [{ name: "Credit Card (Default)", value: 19 }],
  },
];

export const baseDOB = [
  {
    type: "dropdown",
    name: "pattern",
    label: "Strategy",
    selectLabel: "Select Strategy",
    value: null,
    callbackPatternEvent: true,
    validations: {
      required: true,
    },
    options: [
      { name: "Date", value: 8 },
      { name: "Date Number", value: 33 },
      { name: "Date Range", value: 92 }
    ],
  },
];

export const baseRandomNumbers = [
  {
    type: "dropdown",
    name: "pattern",
    label: "Strategy",
    selectLabel: "Select Strategy",
    value: null,
    callbackPatternEvent: true,
    validations: {
      required: true,
    },
    options: [{ name: "Random Number", value: 22 }],
  },
];

export const baseRandomCharacters = [
  {
    type: "dropdown",
    name: "pattern",
    label: "Strategy",
    selectLabel: "Select Strategy",
    value: null,
    callbackPatternEvent: true,
    validations: {
      required: true,
    },
    options: [
      { name: "Random Letter", value: 23 },
      { name: "RegEx Pattern", value: 47 },
    ],
  },
];

export const baseList = [
  {
    type: "dropdown",
    name: "pattern",
    label: "Strategy",
    selectLabel: "Select Strategy",
    value: null,
    callbackPatternEvent: true,
    validations: {
      required: true,
    },
    options: [{ name: "Controlled List", value: 57 }],
  },
];

export const baseDataPrivacy = [
  {
    type: "dropdown",
    name: "pattern",
    label: "Strategy",
    selectLabel: "Select Strategy",
    value: null,
    callbackPatternEvent: true,
    validations: {
      required: true,
    },
    options: [
      { name: "Rounding", value: 89 },
      { name: "Bucketing", value: 90 },
      { name: "Masking", value: 91 }
    ],
  },
];

export const basePhoneStrategy = [
  {
    type: "dropdown",
    name: "pattern",
    label: "Strategy",
    selectLabel: "Select Strategy",
    value: null,
    callbackPatternEvent: true,
    validations: {
      required: true,
    },
    options: [{ name: "Phone Number (Default)", value: 9 }],
  },
];

export const baseAddressStrategy = [
  {
    type: "dropdown",
    name: "pattern",
    label: "Strategy",
    selectLabel: "Select Strategy",
    value: null,
    callbackPatternEvent: true,
    validations: {
      required: true,
    },
    options: [
      { name: "Address (Advanced)", value: 50 },
      { name: "Street Name (Advanced)", value: 51 },
      { name: "City (Advanced)", value: 52 },
      { name: "Province (Advanced)", value: 53 },
    ],
  },
];

export const baseNameStrategy = [
  {
    type: "dropdown",
    name: "pattern",
    label: "Strategy",
    selectLabel: "Select Strategy",
    value: null,
    callbackPatternEvent: true,
    validations: {
      required: true,
    },
    options: [
      { name: "Person Name (Default)", value: 5 },
      { name: "First Name (Default)", value: 6 },
      { name: "Last Name (Default)", value: 7 },
      { name: "Person Name (Advanced)", value: 54 },
      { name: "First Name (Advanced)", value: 55 },
      { name: "Last Name (Advanced)", value: 56 },
    ],
  },
];

export const customStrategyDefaultSchema = [
  {
    type: "dropdown",
    name: "assignSemantics",
    label: "Assign Semantics",
    selectLabel: "Select Assign Semantics",
    callbackAssignEvent: true,
    validations: {
      required: true,
    },
    options: [],
  },
  {
    type: "dropdown",
    name: "synthesisStrategy",
    label: "Pattern",
    selectLabel: "Select Pattern",
    placeholder: "Select Pattern",
    validations: {
      required: true,
    },
    group: false,
    options: [],
  },
  {
    type: "dependentField",
    name: "dependentField",
    label: "Dependent Field",
    options: [],
    value: [],
    tabIndex: 0,
  },
  {
    type: "btn",
    validations: {},
    name: [
      { name: "Clear", disabled: false },
      { name: "Test", disabled: false },
      { name: "Save", disabled: false, tabIndex: 0 },
    ],
  },
];

export const enUS = [
  { name: "National ID", value: "national_id" },
  { name: "Date", value: "date" },
  { name: "Name", value: "name" },
  { name: "Gender", value: "gender" },
  { name: "Address", value: "address" },
  { name: "Phone Number", value: "phone_number" },
  { name: "Random Number", value: "random_number" },
  { name: "Random Letters", value: "random_letters" },
  { name: "Job", value: "job" },
  { name: "Email", value: "email" },
  { name: "IP Address", value: "ip_address" },
  { name: "Domain Name", value: "domain_name" },
  { name: "Device Address", value: "device_address" },
  { name: "Bank Account", value: "bank_account" },
  { name: "Swift Code", value: "swift_code" },
  { name: "License Plate", value: "license_plate" },
  { name: "Credit Card", value: "credit_card" },
  { name: "Barcode", value: "barcode" },
  { name: "Color", value: "color" },
  { name: "Land Coordinates", value: "land_coordinates" },
  { name: "Company Name", value: "company" },
  { name: "Numeric", value: "numeric" },
  { name: "Categorical", value: "categorical" },
  { name: "List", value: "list" },
  { name: "List (Auto)", value: "list_(auto)" },
  { name: "PII PHI Redaction", value: "PII_PHI_Redaction" },
  { name: "Retain", value: "retain" },
  { name: "Random Alpha Numeric", value: "random_alpha_numeric" },
  { name: "Data Privacy", value: "data_privacy" }
];

export const enZA = [
  { name: "National ID", value: "national_id" },
  { name: "Date", value: "date" },
  { name: "Name", value: "name" },
  { name: "Address", value: "address" },
  { name: "Phone Number", value: "phone_number" },
  { name: "Random Number", value: "random_number" },
  { name: "Random Letters", value: "random_letters" },
  { name: "Job", value: "job" },
  { name: "Email", value: "email" },
  { name: "IP Address", value: "ip_address" },
  { name: "Gender", value: "gender" },
  { name: "Domain Name", value: "domain_name" },
  { name: "Device Address", value: "device_address" },
  { name: "Bank Account", value: "bank_account" },
  { name: "Swift Code", value: "swift_code" },
  { name: "License Plate", value: "license_plate" },
  { name: "Credit Card", value: "credit_card" },
  { name: "Barcode", value: "barcode" },
  { name: "Color", value: "color" },
  { name: "Land Coordinates", value: "land_coordinates" },
  { name: "Company Name", value: "company" },
  { name: "Numeric", value: "numeric" },
  { name: "Categorical", value: "categorical" },
  { name: "List", value: "list" },
  { name: "List (Auto)", value: "list_(auto)" },
  { name: "PII PHI Redaction", value: "PII_PHI_Redaction" },
  { name: "Retain", value: "retain" },
  { name: "Random Alpha Numeric", value: "random_alpha_numeric" },
  { name: "Data Privacy", value: "data_privacy" }
];

export const loc = [
  { name: "National ID", value: "national_id" },
  { name: "Date", value: "date" },
  { name: "Name", value: "name" },
  { name: "Address", value: "address" },
  { name: "Phone Number", value: "phone_number" },
  { name: "Random Number", value: "random_number" },
  { name: "Random Letters", value: "random_letters" },
  { name: "Gender", value: "gender" },
  { name: "Job", value: "job" },
  { name: "Email", value: "email" },
  { name: "IP Address", value: "ip_address" },
  { name: "Domain Name", value: "domain_name" },
  { name: "Device Address", value: "device_address" },
  { name: "Bank Account", value: "bank_account" },
  { name: "Swift Code", value: "swift_code" },
  { name: "License Plate", value: "license_plate" },
  { name: "Credit Card", value: "credit_card" },
  { name: "Barcode", value: "barcode" },
  { name: "Color", value: "color" },
  { name: "Land Coordinates", value: "land_coordinates" },
  { name: "Company Name", value: "company" },
  { name: "Numeric", value: "numeric" },
  { name: "Categorical", value: "categorical" },
  { name: "List", value: "list" },
  { name: "List (Auto)", value: "list_(auto)" },
  { name: "PII PHI Redaction", value: "PII_PHI_Redaction" },
  { name: "Retain", value: "retain" },
  { name: "Random Alpha Numeric", value: "random_alpha_numeric" },
  { name: "Data Privacy", value: "data_privacy" }
];

export const anomalyOtherTypes = [
  { name: "National ID", value: "national_id" },
  { name: "Date", value: "date" },
  { name: "Name", value: "name" },
  { name: "Address", value: "address" },
  { name: "Phone Number", value: "phone_number" },
  { name: "Random Number", value: "random_number" },
  { name: "Random Letters", value: "random_letters" },
  { name: "Gender", value: "gender" },
  { name: "Job", value: "job" },
  { name: "Email", value: "email" },
  { name: "IP Address", value: "ip_address" },
  { name: "Domain Name", value: "domain_name" },
  { name: "Device Address", value: "device_address" },
  { name: "Bank Account", value: "bank_account" },
  { name: "Swift Code", value: "swift_code" },
  { name: "License Plate", value: "license_plate" },
  { name: "Credit Card", value: "credit_card" },
  { name: "Barcode", value: "barcode" },
  { name: "Color", value: "color" },
  { name: "Land Coordinates", value: "land_coordinates" },
  { name: "Company Name", value: "company" },
  { name: "List", value: "list" },
  { name: "List (Auto)", value: "list_(auto)" },
  { name: "PII PHI Redaction", value: "PII_PHI_Redaction" },
  { name: "Retain", value: "retain" },
  { name: "Random Alpha Numeric", value: "random_alpha_numeric" },
];

export const fixedSemanticTypes = [
  { name: "Date", value: "date" },
  { name: "Random Number", value: "random_number" },
  { name: "Random Letters", value: "random_letters" },
  { name: "Checksum", value: "checksum" },
  { name: "List", value: "list" },
  { name: "Retain", value: "retain" },
  { name: "Random Alpha Numeric", value: "random_alpha_numeric" },
];

export const variableSemanticTypes = [
  { name: "Random Number", value: "random_number" },
  { name: "Random Letters", value: "random_letters" },
  { name: "Name", value: "name" },
  { name: "Date", value: "date" },
  { name: "Address", value: "address" },
  { name: "Checksum", value: "checksum" },
  { name: "List", value: "list" },
  { name: "Retain", value: "retain" },
  { name: "Random Alpha Numeric", value: "random_alpha_numeric" },
];

export const customStrategyFixedSchema = [
  {
    type: "text",
    name: "columnlet",
    label: "Columnlet Name",
    class: "ClassCol",
    validations: {
      required: true,
      maxLength: 30,
      minLength: 1,
    },
  },
  {
    type: "range",
    lowerName: "lower",
    upperName: "upper",
    lowerValue: 1,
    upperValue: 1,
    label: "Position Range",
    validations: {
      required: true,
    },
  },
  {
    type: "dropdown",
    name: "assignSemantics",
    label: "Assign Semantics",
    selectLabel: "Select Assign Semantics",
    callbackAssignEvent: true,
    validations: {
      required: true,
    },
    options: fixedSemanticTypes,
  },
  {
    type: "dropdown",
    name: "synthesisStrategy",
    label: "Pattern",
    selectLabel: "Select Pattern",
    placeholder: "Select Pattern",
    validations: {
      required: true,
    },
    // group: false,
    options: [],
  },
  {
    type: "dependentField",
    name: "dependentField",
    label: "Dependent Field",
    tabIndex: 0,
    options: [],
    value: [],
  },
  {
    type: "btn",
    validations: {},
    name: [
      { name: "Clear", disabled: false },
      { name: "Test", disabled: false },
      { name: "Save", disabled: false, tabIndex: 0 },
    ],
  },
];

export const customStrategyVariableSchema = [
  {
    type: "text",
    name: "columnlet",
    label: "Columnlet",
    validations: {
      required: true,
      maxLength: 30,
      minLength: 3,
    },
  },
  {
    type: "dropdown",
    name: "assignSemantics",
    label: "Assign Semantics",
    selectLabel: "Select Assign Semantics",
    callbackAssignEvent: true,
    validations: {
      required: true,
    },
    options: variableSemanticTypes,
  },
  {
    type: "dropdown",
    name: "synthesisStrategy",
    label: "Pattern",
    selectLabel: "Select Pattern",
    placeholder: "Select Pattern",
    validations: {
      required: true,
    },
    // group: false,
    options: [],
  },
  {
    type: "dependentField",
    name: "dependentField",
    label: "Dependent Field",
    options: [],
    value: [],
    tabIndex: 0,
  },
  {
    type: "btn",
    validations: {},
    name: [
      { name: "Clear", disabled: false },
      { name: "Test", disabled: false },
      { name: "Save", disabled: false, tabIndex: 0 },
    ],
  },
];

export const strategySchema = [
  {
    type: "dropdown",
    name: "pattern",
    label: "Semantic Group",
    selectLabel: "Select Semantic Group",
    value: null,
    callbackPatternEvent: true,
    validations: {
      required: true,
    },
    options: [
      { name: "Random Characters", value: "randomCharacters" },
      { name: "Random Numbers", value: "randomNumbers" },
      { name: "Address", value: "address" },
      { name: "Date", value: "date" },
      { name: "Name", value: "name" },
      { name: "Phone Number", value: "phoneNumber" },
      { name: "Street Name", value: "address" },
      { name: "City", value: "address" },
      { name: "State", value: "address" },
      { name: "National ID", value: "national_id" },
      { name: "Job", value: "job" },
      { name: "Personal Email", value: "personal_email" },
      { name: "Company Email", value: "company_email" },
      { name: "IP Address", value: "ip_address" },
      { name: "Domain Name", value: "domain_name" },
      { name: "Device Address", value: "device_address" },
      { name: "Bank Account", value: "bank_account" },
      { name: "Swift Code", value: "swift_code" },
      { name: "License Plate", value: "license_plate" },
      { name: "SAID", value: "said" },
      { name: "Credit Card", value: "credit_card" },
      { name: "Checksum", value: "checksum" },
      { name: "Barcode", value: "barcode" },
      { name: "Color", value: "color" },
      { name: "Land Coordinates", value: "land_coordinates" },
    ],
  },
];

export const commonStrategySchema: Object[] = [
  {
    type: "dropdown",
    name: "strategy",
    value: null,
    label: "Strategy",
    selectLabel: "Select Strategy",
    options: strategy,
  },
  {
    type: "text",
    name: "strategyName",
    value: null,
    label: "Pattern Name",
    validations: {
      required: true,
      maxLength: 30,
      minLength: 3,
    },
  },
  {
    type: "btn",
    name: [{ name: "Save", disabled: false }],
  },
];

export const creditCardStrategySchema: Object[] = [
  {
    type: "dropdown",
    name: "cardType",
    value: null,
    label: "Card Type",
    selectLabel: "Select Card Type",
    options: [
      { name: "Maestro", value: "maestro" },
      { name: "Mastercard", value: "mastercard" },
      { name: "Amex", value: "amex" },
      { name: "Discover", value: "discover" },
      { name: "Diners", value: "diners" },
    ],
  },
  {
    type: "dropdown",
    name: "creditCardDelimiter",
    value: null,
    label: "Credit Card Delimiter",
    selectLabel: "Select Credit Card Delimiter",
    options: [
      { name: '" " (Space)', value: " " },
      { name: "- (Hypen)", value: "-" },
    ],
  },
  {
    type: "text",
    name: "strategyName",
    value: null,
    label: "Pattern Name",
    validations: {
      required: true,
      maxLength: 30,
      minLength: 3,
    },
  },
  {
    type: "btn",
    name: [{ name: "Save", disabled: false }],
  },
];

export const randomCharactersSchema: Object[] = [
  {
    type: "dropdown",
    name: "limitTo",
    value: null,
    label: "Limit to",
    selectLabel: "Select Limit to",
    options: [
      { name: "Upper_Case", value: "uppercase" },
      { name: "Lower_Case", value: "lowercase" },
      { name: "Both", value: "both" },
    ],
    validations: {
      required: true,
    },
  },
  {
    type: "text",
    name: "characters",
    value: null,
    label: "Enter Specific Characters",
    validations: {
      required: true,
    },
  },
  {
    type: "text",
    name: "strategyName",
    value: null,
    label: "Pattern Name",
    validations: {
      required: true,
      maxLength: 30,
    },
  },
  {
    type: "btn",
    name: [{ name: "Save", disabled: false }],
  },
];

export const randomNumbersSchema: Object[] = [
  {
    type: "number",
    name: "numbers",
    value: null,
    label: "Enter Specific Numbers",
    validations: {},
  },
  {
    type: "text",
    name: "strategyName",
    value: null,
    label: "Pattern Name",
    validations: {
      required: true,
      maxLength: 30,
    },
  },
  {
    type: "btn",
    name: [{ name: "Save", disabled: false }],
  },
];

export const OAIAddressSchema: Object[] = [
  {
    type: "dropdown",
    name: "street",
    value: null,
    label: "Street",
    selectLabel: "Select Street",
    options: [{ name: "Retain", value: "retain" }],
  },
  {
    type: "dropdown",
    name: "city",
    value: null,
    label: "City",
    selectLabel: "Select City",
    options: [{ name: "Retain", value: "retain" }],
  },
  {
    type: "dropdown",
    name: "state",
    value: null,
    label: "State",
    selectLabel: "Select State",
    options: [{ name: "Retain", value: "retain" }],
  },
  {
    type: "dropdown",
    name: "country",
    value: null,
    label: "Country",
    selectLabel: "Select Country",
    options: [{ name: "Retain", value: "retain" }],
  },
  {
    type: "text",
    name: "distance",
    value: null,
    label: "Distance",
    placeholder: "Enter the distance (e.g., 100 miles, 30 meters):",
    validations: {},
  },
  {
    type: "text",
    name: "strategyName",
    value: null,
    label: "Pattern Name",
    validations: {
      required: true,
      maxLength: 30,
    },
  },
  {
    type: "btn",
    name: [{ name: "Save", disabled: false }],
  },
];

export const phoneSchema: Object[] = [
  {
    type: "dropdown",
    name: "countryCode",
    value: null,
    label: "Country Code",
    selectLabel: "Select Country Code",
    options: [{ name: "Retain", value: "retain" }],
  },
  {
    type: "text",
    name: "strategyName",
    value: null,
    label: "Pattern Name",
    validations: {
      required: true,
      maxLength: 30,
      minLength: 3,
    },
  },
  {
    type: "btn",
    name: [{ name: "Save", disabled: false }],
  },
];

export const dateSchema: Object[] = [
  {
    type: "number",
    name: "daysBefore",
    value: null,
    label: "Days Before",
    validations: {
      required: true,
    },
  },
  {
    type: "number",
    name: "daysAfter",
    value: null,
    label: "Days After",
    validations: {
      required: true,
    },
  },
  {
    type: "dropdown",
    name: "dateFormat",
    value: null,
    label: "Date Format",
    selectLabel: "Select Date Format",
    options: [
      { name: "DD MM YYYY", value: "DD MM YYYY" },
      { name: "DD MM YY", value: "DD MM YY" },
      { name: "MM DD YYYY", value: "MM DD YYYY" },
      { name: "MM DD YY", value: "MM DD YY" },
      { name: "YYYY MM DD", value: "YYYY MM DD" },
      { name: "YY MM DD", value: "YY MM DD" },
      { name: "DD YYYY MM", value: "DD YYYY MM" },
      { name: "DD YY MM", value: "DD YY MM" },
      { name: "MM YYYY DD", value: "MM YYYY DD" },
      { name: "MM YY DD", value: "MM YY DD" },
      { name: "YYYY DD MM", value: "YYYY DD MM" },
      { name: "YY DD MM", value: "YY DD MM" },
      { name: "DD MMM YYYY", value: "DD MMM YYYY" },
      { name: "DD MMM YY", value: "DD MMM YY" },
      { name: "DD Month YYYY", value: "DD Month YYYY" },
      { name: "DD Month YY", value: "DD Month YY" },
      { name: "Month DD YYYY", value: "Month DD YYYY" },
      { name: "Month DD YY", value: "Month DD YY" },
      { name: "YYYY Month DD", value: "YYYY Month DD" },
      { name: "YY Month DD", value: "YY Month DD" },
      { name: "DD YYYY Month", value: "DD YYYY Month" },
      { name: "DD YY Month", value: "DD YY Month" },
      { name: "Month YYYY DD", value: "Month YYYY DD" },
      { name: "Month YY DD", value: "Month YY DD" },
      { name: "YYYY DD Month", value: "YYYY DD Month" },
      { name: "YY DD Month", value: "YY DD Month" },
      { name: "DD Month YYYY", value: "DD Month YYYY" },
      { name: "DD Month YY", value: "DD Month YY" },
    ],
    validations: {
      required: true,
    },
  },
  {
    type: "dropdown",
    name: "dateDelimiter",
    value: null,
    label: "Date Delimiter",
    selectLabel: "Select Date Delimiter",
    options: [
      { name: "- (Hypen)", value: "-" },
      { name: "/ (Slash)", value: "/" },
    ],
    validations: {
      required: true,
    },
  },
  {
    type: "checkbox",
    name: "limitToCurrentDate",
    value: null,
    label: "No Future Dates",
  },
  {
    type: "text",
    name: "strategyName",
    value: null,
    label: "Pattern Name",
    validations: {
      required: true,
      maxLength: 30,
      minLength: 3,
    },
  },
  {
    type: "btn",
    name: [{ name: "Save", disabled: false }],
  },
];

export const dateRangeSchema: Object[] = [
  {
    type: "text",
    name: "startDate",
    value: null,
    label: "Start Date",
    validations: {
      required: true,
    },
  },
  {
    type: "text",
    name: "endDate",
    value: null,
    label: "End Date",
    validations: {
      required: true,
    },
  },
  {
    type: "dropdown",
    name: "dateFormat",
    value: null,
    label: "Date Format",
    selectLabel: "Select Date Format",
    options: [
      { name: "DD MM YYYY", value: "DD MM YYYY" },
      { name: "DD MM YY", value: "DD MM YY" },
      { name: "MM DD YYYY", value: "MM DD YYYY" },
      { name: "MM DD YY", value: "MM DD YY" },
      { name: "YYYY MM DD", value: "YYYY MM DD" },
      { name: "YY MM DD", value: "YY MM DD" },
      { name: "DD YYYY MM", value: "DD YYYY MM" },
      { name: "DD YY MM", value: "DD YY MM" },
      { name: "MM YYYY DD", value: "MM YYYY DD" },
      { name: "MM YY DD", value: "MM YY DD" },
      { name: "YYYY DD MM", value: "YYYY DD MM" },
      { name: "YY DD MM", value: "YY DD MM" },
      { name: "DD MMM YYYY", value: "DD MMM YYYY" },
      { name: "DD MMM YY", value: "DD MMM YY" },
      { name: "DD Month YYYY", value: "DD Month YYYY" },
      { name: "DD Month YY", value: "DD Month YY" },
      { name: "Month DD YYYY", value: "Month DD YYYY" },
      { name: "Month DD YY", value: "Month DD YY" },
      { name: "YYYY Month DD", value: "YYYY Month DD" },
      { name: "YY Month DD", value: "YY Month DD" },
      { name: "DD YYYY Month", value: "DD YYYY Month" },
      { name: "DD YY Month", value: "DD YY Month" },
      { name: "Month YYYY DD", value: "Month YYYY DD" },
      { name: "Month YY DD", value: "Month YY DD" },
      { name: "YYYY DD Month", value: "YYYY DD Month" },
      { name: "YY DD Month", value: "YY DD Month" },
      { name: "DD Month YYYY", value: "DD Month YYYY" },
      { name: "DD Month YY", value: "DD Month YY" },
    ],
    validations: {
      required: true,
    },
  },
  {
    type: "dropdown",
    name: "dateDelimiter",
    value: null,
    label: "Date Delimiter",
    selectLabel: "Select Date Delimiter",
    options: [
      { name: "- (Hypen)", value: "-" },
      { name: "/ (Slash)", value: "/" },
    ],
    validations: {
      required: true,
    },
  },
  {
    type: "text",
    name: "strategyName",
    value: null,
    label: "Pattern Name",
    validations: {
      required: true,
      maxLength: 30,
      minLength: 3,
    },
  },
  {
    type: "btn",
    name: [{ name: "Save", disabled: false }],
  },
];

export const OAINameSchema: Object[] = [
  {
    type: "dropdown",
    name: "gender",
    value: null,
    label: "Gender",
    selectLabel: "Select Gender",
    options: [
      { name: "Male", value: "male" },
      { name: "Female", value: "female" },
      { name: "Retain", value: "retain" },
    ],
  },
  {
    type: "dropdown",
    name: "religion",
    value: null,
    label: "Religion",
    selectLabel: "Select Religion",
    options: [{ name: "Retain", value: "retain" }],
  },
  {
    type: "dropdown",
    name: "nativity",
    value: null,
    label: "Nativity",
    selectLabel: "Select Nativity",
    options: [{ name: "Retain", value: "retain" }],
  },
  {
    type: "text",
    name: "strategyName",
    value: null,
    label: "Pattern Name",
    validations: {
      required: true,
      maxLength: 30,
      minLength: 3,
    },
  },
  {
    type: "btn",
    name: [{ name: "Save", disabled: false }],
  },
];

export const defaultNameSchema: Object[] = [
  {
    type: "dropdown",
    name: "gender",
    value: null,
    label: "Gender",
    selectLabel: "Select Gender",
    options: [
      { name: "Male", value: "male" },
      { name: "Female", value: "female" },
    ],
  },
  {
    type: "text",
    name: "strategyName",
    value: null,
    label: "Pattern Name",
    validations: {
      required: true,
    },
  },
  {
    type: "btn",
    name: [{ name: "Save", disabled: false }],
  },
];

export const defaultDateNumber: Object[] = [
  {
    type: "dropdown",
    name: "dateformat",
    value: null,
    label: "Date Format",
    selectLabel: "Select Date Format",
    options: [
      { name: "yyyymmdd", value: "yyyymmdd" },
      { name: "ddmmyyyy", value: "ddmmyyyy" },
      { name: "mmddyyyy", value: "mmddyyyy" },
      { name: "yymmdd", value: "yymmdd" },
      { name: "ddmmyy", value: "ddmmyy" },
      { name: "mmddyy", value: "mmddyy" },
    ],
  },
  {
    type: "text",
    name: "strategyName",
    value: null,
    label: "Pattern Name",
    validations: {
      required: true,
    },
  },
  {
    type: "btn",
    name: [{ name: "Save", disabled: false }],
  },
];

export const listSchema: Object[] = [
  {
    type: "text",
    name: "list",
    value: null,
    label: "Specify List",
    validations: {
      required: true,
    },
  },
  {
    type: "text",
    name: "strategyName",
    value: null,
    label: "Pattern Name",
    validations: {
      required: true,
    },
  },
  {
    type: "btn",
    name: [{ name: "Save", disabled: false }],
  },
];

export const roundingSchema: Object[] = [
  {
    type: "text",
    name: "rounding",
    value: null,
    label: "Rounding Digits",
    // validations: {
    //   required: true,
    // },
  },
  {
    type: "text",
    name: "strategyName",
    value: null,
    label: "Pattern Name",
    validations: {
      required: true,
    },
  },
  {
    type: "btn",
    name: [{ name: "Save", disabled: false }],
  },
];

export const bucketingSchema: Object[] = [
  {
    type: "text",
    name: "bucketing",
    value: null,
    label: "Bucketing Digits",
    // validations: {
    //   required: true,
    // },
  },
  {
    type: "text",
    name: "strategyName",
    value: null,
    label: "Pattern Name",
    validations: {
      required: true,
    },
  },
  {
    type: "btn",
    name: [{ name: "Save", disabled: false }],
  },
];

export const maskingSchema: Object[] = [
  {
    type: "text",
    name: "number_char",
    value: null,
    label: "Number of Characters",
    // validations: {
    //   required: true,
    // },
  },
  {
    type: "text",
    name: "masked_char",
    value: null,
    label: "Masked Character",
    // validations: {
    //   required: true,
    // },
  },
  {
    type: "text",
    name: "strategyName",
    value: null,
    label: "Pattern Name",
    validations: {
      required: true,
    },
  },
  {
    type: "btn",
    name: [{ name: "Save", disabled: false }],
  },
];

export const regexPatternSchema: Object[] = [
  {
    type: "regex",
    name: "pattern",
    value: null,
    label: "Regex Pattern",
    validations: {
      required: true,
    },
  },
  {
    type: "text",
    name: "strategyName",
    value: null,
    label: "Pattern Name",
    validations: {
      required: true,
    },
  },
  {
    type: "btn",
    name: [{ name: "Save", disabled: false }],
  },
];

export const LoanRequest: Object[] = [
  { baseInterestRateAmount: "" },
  { creditScore: "" },
  { mapRating: "" },
  { loanSize: "" },
  { householdIncome: "" },
  { propertyValue: "" },
  { term: "" },
  { areaClass: "" },
  { employmentType: "" },
  { paymentMethod: "" },
  { isGEPF: "" },
  { loanPurpose: "" },
  { oldestApplicantAge: "" },
  { youngestApplicantAge: "" },
  { occupationType: "" },
  { isEdge: "" },
  { isManagerOverride: "" },
  { isStaffLoan: "" },
  { isStopOrderEligible: "" },
  { baseInterestRateSource: "" },
];

export const dateFormats: Object[] = [
  { name: "DD MM YYYY", value: "DD MM YYYY" },
  { name: "DD MM YY", value: "DD MM YY" },
  { name: "MM DD YYYY", value: "MM DD YYYY" },
  { name: "MM DD YY", value: "MM DD YY" },
  { name: "YYYY MM DD", value: "YYYY MM DD" },
  { name: "YY MM DD", value: "YY MM DD" },
  { name: "DD YYYY MM", value: "DD YYYY MM" },
  { name: "DD YY MM", value: "DD YY MM" },
  { name: "MM YYYY DD", value: "MM YYYY DD" },
  { name: "MM YY DD", value: "MM YY DD" },
  { name: "YYYY DD MM", value: "YYYY DD MM" },
  { name: "YY DD MM", value: "YY DD MM" },
  { name: "DD MMM YYYY", value: "DD MMM YYYY" },
  { name: "DD MMM YY", value: "DD MMM YY" },
  { name: "DD Month YYYY", value: "DD Month YYYY" },
  { name: "DD Month YY", value: "DD Month YY" },
  { name: "Month DD YYYY", value: "Month DD YYYY" },
  { name: "Month DD YY", value: "Month DD YY" },
  { name: "YYYY Month DD", value: "YYYY Month DD" },
  { name: "YY Month DD", value: "YY Month DD" },
  { name: "DD YYYY Month", value: "DD YYYY Month" },
  { name: "DD YY Month", value: "DD YY Month" },
  { name: "Month YYYY DD", value: "Month YYYY DD" },
  { name: "Month YY DD", value: "Month YY DD" },
  { name: "YYYY DD Month", value: "YYYY DD Month" },
  { name: "YY DD Month", value: "YY DD Month" },
  { name: "DD Month YYYY", value: "DD Month YYYY" },
  { name: "DD Month YY", value: "DD Month YY" },
];
