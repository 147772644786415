import { Component, Input, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Toast } from 'src/app/_helpers/toast';
import { AuthService } from 'src/app/_services/auth.service';
import { InstantAnalyticsService } from 'src/app/_services/instant-analytics.service';
import { OrgService } from 'src/app/_services/org.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-analytix-plots',
  templateUrl: './analytix-plots.component.html',
})
export class AnalytixPlotsComponent {
  jobId;
  userData: Object = {};
  orgData: Object = {};
  chatMessages = [];
  loading: boolean = false;
  question: string = ''
  @Input() analytixData;
  latestQues: string = ''

  constructor (
    private userService: UserService,
    private orgService: OrgService,
    private analytixService: InstantAnalyticsService,
    private sanitizer: DomSanitizer,
  ) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.analytixData) {
      this.jobId = this.analytixData['data']['jobId']
    }
  }

  ngOnInit(): void {
    this.getprofile();
  }

  goback() {
    window.history.back();
  }

  getprofile() {
    this.userService.getUserProfile().subscribe({
      next: (res) => {
        if (res) {
          this.userData = res;
          this.orgService.getOrgById(res.orgId).subscribe({
            next: r => {
              this.orgData = r
            },
            error: err => {
              Toast.fire({
                icon: 'error',
                html: err.error.error.message
              });
            }
          })
        }
      },
      error: err => {
        Toast.fire({
          icon: 'error',
          html: err.error.error.message
        });
      }
    })
  }

  validateImg(data) {
    if (typeof data == 'string' && data.startsWith('i')) {
      return true
    } else {
      return false
    }
  }

  postQuery() {
    this.loading = true;
    this.latestQues = this.question;
    setTimeout(()=>{
      this.question = ''
    }, 1000)
    this.analytixService.getPlotsBasedOnPrompt({job_id: this.jobId, query: this.question}).subscribe({
      next: res => {
        if (res && res['Plot'] && res['Plot']['custom_plot']) {
          this.chatMessages.push({
            question: this.latestQues,
            // answer: res['response']
            source: this.sanitizer.bypassSecurityTrustHtml(res['Plot']['custom_plot'])
          })
        } else {
          this.chatMessages.push({
            question: this.latestQues,
            // answer: res['response']
            answer: "Please refine your query with dataset"
          })
        }
        this.loading = false;
      }, error: err => {
        this.loading = false;
      }
    })
  }
}
