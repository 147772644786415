import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ProjectService } from 'src/app/_services/project.service';
import { Toast } from 'src/app/_helpers/toast';

@Component({
  selector: 'app-add-project',
  templateUrl: './add-project.component.html'
})
export class AddProjectComponent implements OnChanges {

  public formSchema: Object[] = [
    {
      type: "text",
      name: "projectName",
      value: null,
      label: "Workspace Name",
      class: 'list',
      validations: {
        required: true,
        maxLength: 30,
        minLength: 3
      }
    },
    {
      type: "textarea",
      name: "description",
      value: null,
      label: "Description",
      class: 'list',
      // validations: {
      //   required: true,
      //   maxLength: 500
      // }
    },
    {
      type: 'btn',
      name: [
        { name: 'Save' }
      ]
    },
  ];
  @Output() addedProject = new EventEmitter<any>();
  @Input() project: any = {};


  constructor(
    private projectService: ProjectService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['project']) {

      this.formSchema[0]['value'] = this.project?.projectName;
      this.formSchema[1]['value'] = this.project?.description;
    }
  }




  formConstructorData(event: any) {

    let o = Object.fromEntries(Object.entries(event.value).filter(([_, v]) => v != null));
    if (this.project?.id) {
      // removing nulls below
      this.projectService.updateProjectById(Object.fromEntries(Object.entries(o).filter(([_, v]) => v != null)), this.project?.id).subscribe({
        next: res => {
          this.addedProject.emit(res);
          Toast.fire({

            icon: 'success',

            title: "Updated",
            html: "Workspace Updated successfully"
          });
        },
        error: err => {
          this.addedProject.emit(err);
        }
      })
    } else {
      this.projectService.createProject(o).subscribe({
        next: res => {
          this.addedProject.emit(res);
          Toast.fire({

            icon: 'success',

            title: "Created",
            html: "Workspace Created successfully"
          });
        },
        error: err => {
          this.addedProject.emit(err);
        }
      });
    }
  }

}
