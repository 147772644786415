import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, inject } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { anomalyOtherTypes } from 'src/app/_helpers/schemas';
import { AuthService } from 'src/app/_services/auth.service';
import { CustomStrategyService } from 'src/app/_services/custom-strategy.service';
import { SyntheticDataService } from 'src/app/_services/synthetic-data.service';
import { Toast } from "src/app/_helpers/toast";
import { CustomValidators } from 'src/app/_helpers/custom-validators';


@Component({
  selector: 'app-anomaly',
  templateUrl: './anomaly.component.html'
})
export class AnomalyComponent implements OnInit, OnChanges {
  anomalyForm: FormGroup;
  semanticTypeOptions = [
    { name: 'Numeric', value: 'numeric' },
    { name: 'Categorical', value: 'categorical' },
    { name: 'Others', value: 'others' }
  ];
  @Input() projectId: string = '';
  @Input() jobDetails = {};
  @Input() fileJob: any = {};
  @Input() fileColumns = []
  @Input() selectedColumnDetails: any = {}
  @Input() PIIData = []
  uniqueRecords = {}
  anomalies: any = { changes: null, target: null }
  @Output() anomalyEvent = new EventEmitter();
  finalAnomalies = {}
  otherTypes = anomalyOtherTypes;
  patterns = [];
  columnData = [];
  backCalled: string;

  constructor(
    private fb: FormBuilder,
    private syntheticDataService: SyntheticDataService,
    private authService: AuthService,
    private customStrategy: CustomStrategyService,
  ) {
    this.anomalyForm = this.fb.group({
      anomalyClass: new FormControl(false),
      confirmSemanticType: new FormControl('', Validators.required),
      columnRange: new FormControl('', CustomValidators.rangeValidator),
      columnRecord: new FormControl(''),
      semanticType: new FormControl(''),
      pattern: new FormControl(''),
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedColumnDetails']) {
      this.patchTheFields();
    }
  }

  ngOnInit(): void {
    this.fetchUniqueRecords()
  }

  validateColumn = false;
  validateClassColumn() {
    const currentUser = this.authService.currentUserValue;
    let params = {};
    if (this.fileJob && this.fileJob["job_id"]) {
      params = {
        org_id: currentUser.orgId,
        user_id: currentUser.id,
        locale: this.jobDetails["locale"],
        job_id: this.fileJob["job_id"],
        column: this.selectedColumnDetails["selectedColumn"],
      };
    } else {
      params = {
        org_id: currentUser.orgId,
        user_id: currentUser.id,
        locale: this.jobDetails["locale"],
        connection_id: this.jobDetails["selectSource"]["id"],
        source_schema: this.jobDetails["selectSchema"],
        source_table: this.selectedColumnDetails["table"]["tableName"],
        column: this.selectedColumnDetails["selectedColumn"],
      };
    }
    this.syntheticDataService.validateAnomalyColumn(params).subscribe({
      next: res => {
        console.log('poi', res)
        if (res['message']== 'Class column added successfuly') {
          this.validateColumn = true;
        } else {
          this.validateColumn = false;
          Toast.fire({
            icon: "error",
            html: `Class column must have a maximum of 2 unique values!`,
          });
        }
      }, error: err => {
        console.log('asd', err)
      }
    })
  }

  validateSubmit () {
    if (this.anomalyForm.value['anomalyClass']) {
      if (this.validateColumn) {
        return false;
      } 
      return true
    } else if (this.anomalyForm.value['confirmSemanticType'] && this.anomalyForm.value['columnRange'] && this.anomalyForm.valid) {
      return false;
    } else if (this.anomalyForm.value['confirmSemanticType'] && this.anomalyForm.value['columnRecord']) {
      return false;
    } else if (this.anomalyForm.value['confirmSemanticType'] && this.anomalyForm.value['semanticType'] && this.anomalyForm.value['pattern']) {
      return false;
    }
    return true;
  }

  resetForm() {
    // Reset the form
    this.anomalyForm.reset();

    // Check if the selected column exists in the 'changes' object before attempting deletion
    const selectedColumn = this.selectedColumnDetails['selectedColumn'];

    if (this.anomalies['target'] == selectedColumn) {
      this.anomalies['target'] = null;
    }

    if (selectedColumn && this.anomalies['changes']) {
      // Check and delete the 'numeric' and 'categorical' changes for the selected column
      if (this.anomalies['changes']['numeric'] && selectedColumn in this.anomalies['changes']['numeric']) {
        delete this.anomalies['changes']['numeric'][selectedColumn];
      }

      if (this.anomalies['changes']['categorical'] && selectedColumn in this.anomalies['changes']['categorical']) {
        delete this.anomalies['changes']['categorical'][selectedColumn];
      }
    }

    let index = -1;

    if (Array.isArray(this.columnData) && this.columnData.length) {
      for (let i = 0; i < this.columnData.length; i++) {
        if (this.columnData[i]['source_column'] == selectedColumn) {
          index = i;
          break; // exit the loop once the index is found
        }
      }
    }

    if (index !== -1) {
      this.columnData.splice(index, 1);
    }

    this.anomalyEvent.emit({ anomalies: this.anomalies, columnData: this.columnData });
  }

  patchTheFields() {
    const selectedColumn = this.selectedColumnDetails?.selectedColumn;
    let exist = false;
    if (selectedColumn) {
      if (this.anomalies?.changes?.numeric?.[selectedColumn]) {
        // Do something specific when numeric changes exist for the selected column
        exist = true;
        this.anomalyForm.get('confirmSemanticType').patchValue('numeric');
        this.anomalyForm.get('columnRange').patchValue(this.anomalies?.changes?.numeric?.[selectedColumn]);
      } else if (this.anomalies?.changes?.categorical?.[selectedColumn]) {
        // Do something specific when categorical changes exist for the selected column
        exist = true;
        this.anomalyForm.get('confirmSemanticType').patchValue('categorical');
        this.anomalyForm.get('columnRecord').patchValue(this.anomalies?.changes?.categorical?.[selectedColumn]);
      } else {
        let index = -1;

        if (Array.isArray(this.columnData) && this.columnData.length) {
          for (let i = 0; i < this.columnData.length; i++) {
            if (this.columnData[i]?.source_column === selectedColumn) {
              index = i;
              break; // exit the loop once the index is found
            }
          }
        }

        if (index !== -1) {
          // Do something specific when the selected column is found in columnData
          exist = true;
          this.anomalyForm.get('confirmSemanticType').patchValue('others');
          this.anomalyForm.get('semanticType').patchValue(this.columnData[index]['type']);
          this.fetchPatterns(this.columnData[index]['type']);
          this.anomalyForm.get('pattern').patchValue(this.columnData[index]['strategy_id']);
        }
      }
    }

    if (!exist) {
      this.anomalyForm.reset();
      this.patchPIIData();
    }
  }

  patchPIIData() {
    if (Array.isArray(this.PIIData) && this.PIIData.length > 0) {
      let index = this.PIIData.findIndex(
        (r) =>
          r["column_name"].toLowerCase() ==
          this.selectedColumnDetails["selectedColumn"].toLowerCase()
      );
      if (this.PIIData && this.PIIData[index] && this.PIIData[index]["semantic_type"]) {
        let type = this.PIIData[index]["semantic_type"];
        switch (type) {
          case 'numeric':
            this.anomalyForm.get('confirmSemanticType').patchValue('numeric');
            break;

          case 'categorical':
            this.anomalyForm.get('confirmSemanticType').patchValue('categorical');
            break;

          default:
            this.anomalyForm.get('confirmSemanticType').patchValue('others');
            this.anomalyForm.get('semanticType').patchValue(this.PIIData[index]["semantic_type"]);
            this.fetchPatterns(this.PIIData[index]["semantic_type"]);
            break;
        }
      }
    }
  }

  fetchPatterns(value) {
    const currentUser = this.authService.currentUserValue;
    let params = {};
    if (this.fileJob && this.fileJob["job_id"]) {
      params = {
        semantic_group: value,
        org_id: currentUser.orgId,
        user_id: currentUser.id,
        locale: this.jobDetails["locale"],
        job_id: this.fileJob["job_id"],
        source_column: this.selectedColumnDetails["selectedColumn"],
      };
    } else {
      params = {
        semantic_group: value,
        org_id: currentUser.orgId,
        user_id: currentUser.id,
        locale: this.jobDetails["locale"],
        connection_id: this.jobDetails["selectSource"]["id"],
        source_schema: this.jobDetails["selectSchema"],
        source_table: this.selectedColumnDetails["table"]["tableName"],
        source_column: this.selectedColumnDetails["selectedColumn"],
      };
    }
    this.customStrategy.getStrategies(params).subscribe({
      next: async (res) => {
        this.patterns = []
        res.forEach(r => {
          this.patterns.push({
            name: r.strategy_name,
            value: r.id,
            id: r.id,
          })
        })
      }
    })
  }

  fetchUniqueRecords() {
    let params = {}
    if (this.fileJob && this.fileJob['job_id']) {
      params = {
        job_id: this.fileJob?.job_id, // Use optional chaining for safety
        columns_list: this.fileColumns.join(", ")
      }
    } else {
      params = {
        columns_list: this.fileColumns.join(", "),
        connection_id: this.jobDetails["selectSource"]["id"],
        schema_name: this.jobDetails["selectSchema"],
        table_name: this.selectedColumnDetails['table']['tableName']
      }
    }
    this.syntheticDataService.getUniqueRecords(params).subscribe({
      next: (res: { top_records?: any }) => {
        if (res?.top_records) {
          this.uniqueRecords = res.top_records;
        }
      },
      error: (err) => {
        console.error('Error fetching unique records:', err);
        // Handle the error or provide user feedback
      }
    });
  }


  getOptions() {
    const arr = [];

    if (this.selectedColumnDetails?.selectedColumn && this.uniqueRecords[this.selectedColumnDetails.selectedColumn]) {
      arr.push(
        ...this.uniqueRecords[this.selectedColumnDetails.selectedColumn].map(ele => ({ name: ele, value: ele }))
      );
    }

    return arr;
  }


  saveAnomalies() {
    const type = this.anomalyForm.get('confirmSemanticType').value;
    const selectedColumn = this.selectedColumnDetails['selectedColumn'];

    if (!this.anomalies['changes']) {
      this.anomalies['changes'] = {};
    }

    if (this.anomalyForm.get('anomalyClass').value) {
      this.anomalies['target'] = selectedColumn
    } else {
      switch (type) {
        case 'numeric':
          if (!this.anomalies['changes']['numeric']) {
            this.anomalies['changes']['numeric'] = {};
          }
          this.anomalies['changes']['numeric'][selectedColumn] = JSON.parse(this.anomalyForm.get('columnRange').value);
          break;

        case 'categorical':
          if (!this.anomalies['changes']['categorical']) {
            this.anomalies['changes']['categorical'] = {};
          }
          this.anomalies['changes']['categorical'][selectedColumn] = this.anomalyForm.get('columnRecord').value;
          break;

        default:
          const obj = {
            source_column: selectedColumn,
            destination_column: selectedColumn,
            pattern_id: null,
            strategy_id: this.anomalyForm.get('pattern').value,
            dependent_fields: null,
            type: this.anomalyForm.get('semanticType').value,
            is_set_strategy: 1
          };
          this.columnData.push(obj);
          break;
      }
    }
    Toast.fire({
      icon: "success",
      html: `Column configuration saved`,
    });
    this.finalAnomalies = { anomalies: this.anomalies, columnData: this.columnData }
    this.anomalyEvent.emit({ anomalies: this.anomalies, columnData: this.columnData });
  }


}
