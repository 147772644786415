import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'key' })
export class KeyPipe implements PipeTransform {
    transform(value: any): string | null {
        if (value && typeof value === 'object') {
            return Object.keys(value)[0];
        }
        return null;
    }
}
