import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html'
})
export class ButtonComponent implements OnChanges {

  @Input() form!: FormGroup;
  @Input() field: any = {};
  @Input() btnChange: "";
  @Output() status = new EventEmitter<string>()
  @ViewChild('aButton', { static: false }) aButton: ElementRef;
  @Input() setFocusTestButton: boolean = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['setFocusTestButton'] && this.setFocusTestButton) {
      if (this.field['name'] == 'Test') {
        this.focusHandler()
      }
    }
  }


  buttonStatus() {
    this.status.emit(this.field)
  }

  focusHandler() {
    if (this.aButton && this.aButton.nativeElement) {
      this.aButton.nativeElement.focus();
    }
  }

}
