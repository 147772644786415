import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Toast } from 'src/app/_helpers/toast';
import { SyntheticDataService } from 'src/app/_services/synthetic-data.service';

@Component({
  selector: 'app-semantics-constraints',
  templateUrl: './semantics-constraints.component.html'
})
export class SemanticsConstraintsComponent implements OnInit, OnChanges {

  assignSemanticsForm: FormGroup;
  saidConstraintsForm: FormGroup;
  @Input() selectedColumnDetails;
  validAttributeTypes: Object[] = [
    { name: 'person_name', value: 'person_name' },
    { name: 'address', value: 'address' },
    { name: 'date', value: 'date' },
    { name: 'phone_number', value: 'phone_number' }
  ];
  @Input() jobDetails: Object = {};
  selectedSource: Object = {};
  topRecords: any[] = [];
  strategies: Object[] = [
    { name: 'System Default', value: 'systemDefault' }
  ];
  showTest: boolean = false;
  columnsData: Object[] = [];
  colData: Object = {};
  @Output() columnsDataEmitter = new EventEmitter<any>();
  confirmSyntheticData: Object = {};
  clearTest: boolean = false;
  clearAttribute: boolean = false;
  staticConstraints = {
    limit_to_current_date: true,
    days_after: 30,
    days_before: 30
  }

  constructor(
    private syntheticDataService: SyntheticDataService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes['jobDetails']) {

      if (this.jobDetails && this.jobDetails['selectSource'] && this.jobDetails['jobName']) {
        this.selectedSource = this.jobDetails['selectSource']
      }
      if (this.jobDetails['locale'] == 'en-ZA') {
        this.validAttributeTypes = [
          { name: 'address', value: 'address' },
          { name: 'phone_number', value: 'phone_number' }
        ];
      }
    }

    if (changes['selectedColumnDetails']) {
      if (this.selectedColumnDetails && this.selectedColumnDetails['table'] && this.selectedColumnDetails['selectedColumn']) {

        if (this.selectedSource) {
          this.getRecords();
        }
        if (this.columnsData.length > 0) {
          for (let i = 0; i < this.columnsData.length; i++) {
            if (this.selectedColumnDetails['selectedColumn'] == this.columnsData[i]['column_name']) {
              if (this.columnsData[i]['attribute_type'] == 'date') {
                this.assignSemanticsForm.get('strategy').patchValue(this.strategies[0]['value']);
                this.assignSemanticsForm.patchValue(this.columnsData[i])
                this.assignSemanticsForm.get('constraints').patchValue(this.columnsData[i]);
              } else {
                this.assignSemanticsForm.get('strategy').patchValue(this.strategies[0]['value']);
                this.assignSemanticsForm.patchValue(this.columnsData[i])
              }
            }
          }
        }
      }
    }
  }

  ngOnInit(): void {
    this.assignSemanticsForm = new FormGroup({
      attribute_type: new FormControl(null),
      constraints: new FormGroup({
        limit_to_current_date: new FormControl(null),
        days_after: new FormControl(null),
        days_before: new FormControl(null),
      }),
      strategy: new FormControl(null),
    });

    this.saidConstraintsForm = new FormGroup({
      dobColumnExists: new FormControl(null),
      dobColumn: new FormControl(null),
      changeGender: new FormControl(null),
      genderColumnExists: new FormControl(null),
      genderColumn: new FormControl(null),
    })

    this.assignSemanticsForm.get('strategy').setValue(this.strategies[0]['value']);

  }

  getRecords() {
    if (this.columnsData.length > 0) {
      this.assignSemanticsForm.reset();
      this.assignSemanticsForm.get('strategy').setValue(this.strategies[0]['value']);
    } else if (this.clearTest) {          // if user first time tested and doesn't saved. columnsData length is 0; => form clearing logic
      this.assignSemanticsForm.reset();
      this.assignSemanticsForm.get('strategy').setValue(this.strategies[0]['value']);
      this.clearTest = false;
    } else if (this.clearAttribute) {
      this.assignSemanticsForm.reset();
      this.assignSemanticsForm.get('strategy').setValue(this.strategies[0]['value']);
      this.clearAttribute = false;
    }

    if (this.jobDetails && this.jobDetails['selectSource'] && this.jobDetails['selectSource']['id'] && this.jobDetails['selectSchema'] && this.selectedColumnDetails['table'] && this.selectedColumnDetails['table']['tableName'] && this.selectedColumnDetails['selectedColumn']) {
      this.syntheticDataService.getTopRecords({
        connection_id: this.jobDetails['selectSource']['id'],
        schema_name: this.jobDetails['selectSchema'],
        table_name: this.selectedColumnDetails['table']['tableName'],
        column_name: this.selectedColumnDetails['selectedColumn']
      }).subscribe({
        next: res => {
          if (res[this.selectedColumnDetails['selectedColumn']]) {
            this.topRecords = Object.values(res[this.selectedColumnDetails['selectedColumn']])
          }
        },
        error: err => {
          Toast.fire({
            icon: 'error',
            html: err.error.text
          });
        }
      })
    }
  }

  testMethod() {

    this.confirmSyntheticData = {};
    if (this.assignSemanticsForm.get('attribute_type').value == 'date') {
      this.confirmSyntheticData = {
        column_name: this.selectedColumnDetails['selectedColumn'],
        attribute_type: this.assignSemanticsForm.get('attribute_type').value,
        days_before: this.assignSemanticsForm.get('constraints.days_before').value,
        days_after: this.assignSemanticsForm.get('constraints.days_after').value,
        limit_to_current_date: this.assignSemanticsForm.get('constraints.limit_to_current_date').value,
      }
      let objDate = {
        source_connection: this.selectedSource['id'],
        input_schema: this.selectedSource['database_name'],
        input_table: this.selectedColumnDetails['table']['tableName'],
        configuration: {
          locale: this.jobDetails['locale'],
          columns_data: this.confirmSyntheticData
        }
      }
      this.syntheticDataService.syntheticTestData(objDate).subscribe({
        next: res => {
          this.confirmSyntheticData['input_table'] = this.selectedColumnDetails['table']['tableName'];
          Object.assign(this.confirmSyntheticData, res)
          this.clearTest = true;
          this.showTest = true;
        },
        error: err => {
          Toast.fire({
            icon: 'error',
            html: err.message ? err.message : err.error.text
          });
        }
      })
    } else {
      this.confirmSyntheticData = {
        column_name: this.selectedColumnDetails['selectedColumn'],
        attribute_type: this.assignSemanticsForm.get('attribute_type').value,
      }
      let obj = {
        source_connection: this.selectedSource['id'],
        input_schema: this.selectedSource['database_name'],
        input_table: this.selectedColumnDetails['table']['tableName'],
        configuration: {
          locale: this.jobDetails['locale'],
          columns_data: this.confirmSyntheticData
        }
      }
      this.syntheticDataService.syntheticTestData(obj).subscribe({
        next: res => {
          Object.assign(this.confirmSyntheticData, res)
          this.confirmSyntheticData['input_table'] = this.selectedColumnDetails['table']['tableName'];
          this.clearTest = true;
          this.showTest = true;
        },
        error: err => {
          Toast.fire({
            icon: 'error',
            html: err.message ? err.message : err.error.text
          });
        }
      })
    }
  }

  saveSemantic() {
    if (this.assignSemanticsForm.get('attribute_type').value === 'date') {
      let obj2 = {};
      Object.assign(obj2, this.assignSemanticsForm.value);
      this.colData = {};
      this.colData['column_name'] = this.selectedColumnDetails['selectedColumn'];
      this.colData['out_column_name'] = this.selectedColumnDetails['selectedColumn'];
      this.colData['attribute_type'] = obj2['attribute_type'];
      this.colData['days_before'] = obj2['constraints']['days_before'];
      this.colData['days_after'] = obj2['constraints']['days_after'];
      this.colData['limit_to_current_date'] = obj2['constraints']['limit_to_current_date'];
      this.columnsData.push(this.colData);
      this.columnsDataEmitter.emit(this.columnsData);
    } else {
      let obj = {};
      Object.assign(obj, this.assignSemanticsForm.value);
      this.colData = {};
      this.colData['column_name'] = this.selectedColumnDetails['selectedColumn'];
      this.colData['out_column_name'] = this.selectedColumnDetails['selectedColumn'];
      this.colData['attribute_type'] = obj['attribute_type'];
      this.columnsData.push(this.colData);
      this.columnsDataEmitter.emit(this.columnsData);
    }
  }

  clearSemantic() {
    let index: number;
    for (let i = 0; i < this.columnsData.length; i++) {
      if (this.selectedColumnDetails['selectedColumn'] == this.columnsData[i]['column_name']) {
        index = i;
      }
    }
    this.columnsData.splice(index, 1);
    this.colData = {};
    this.assignSemanticsForm.reset();
    this.columnsDataEmitter.emit(this.columnsData);
  }

  close(data) {
    if (data) {
      this.showTest = false;
    }
  }

  preField() {
    this.clearAttribute = true;
    if (this.assignSemanticsForm.get('attribute_type').value === 'date') {
      this.assignSemanticsForm.get('constraints').setValue(this.staticConstraints);
    }

  }
  setUp() {
    const url = 'https://docs.0to60.ai/jobs/assign-semantics-and-synthesis-strategy/assign-semantics';
    window.open(url, '_blank', 'noopener,noreferrer');
  }
}
