import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, inject } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { locales } from "src/app/_helpers/schemas";
import { Toast } from "src/app/_helpers/toast";
import { AuthService } from "src/app/_services/auth.service";
import { DbConnectionsService } from "src/app/_services/db-connections.service";
import { DocumentationSlideService } from "src/app/_services/documentation-slide.service";
import { ProjectService } from "src/app/_services/project.service";

@Component({
  selector: "app-setup-job",
  templateUrl: "./setup-job.component.html",
})
export class SetupJobComponent implements OnInit, OnChanges, OnDestroy {
  connections: Object[] = [];
  displayDBModal: boolean = false;
  setupJobForm: FormGroup;
  @Output() setupJob = new EventEmitter<any>();
  dbDetails: Object = {};
  clearDBForm: boolean = false;
  localeList: Object[] = locales.map((obj) => ({ ...obj }));
  @Input() projectId: string = null;
  showSelectSchema: boolean = false;
  schemaList: string[] = [];
  submitted = false;
  selectedConnection: any;
  @Input() editJobProfile = {}
  @Input() isEdit = false;
  sourceTypeList = [
    { name: "Database", value: "database" },
    { name: "XML File", value: "xml" },
    { name: "JSON File", value: "json" },
    { name: "CSV/XLS File", value: "csv" },
  ];
  type: string;
  uploadedFiles: any[] = [];
  xsdFiles: File[] = [];
  xmlFiles: File[] = [];
  filteredOptions: any[];
  selectedOption: any;
  spinner: boolean = false;
  selectedKey: string;
  @Input() routeType: string = ''
  workspacesOptions = [];
  hideWorkspaces: boolean;
  @Input() queryParamNull: boolean;
  @Input() clear: boolean;
  disabled: boolean = false;
  fileName: string = ''

  constructor(
    private formBuilder: FormBuilder,
    private dbConnectionsService: DbConnectionsService,
    private documentSlideService: DocumentationSlideService,
    private authService: AuthService,
    private projectService: ProjectService
  ) {
    this.setupJobForm = this.formBuilder.group({
      jobName: new FormControl(null, [Validators.required, Validators.pattern(/^[a-zA-Z0-9]+/)]),
      selectSource: new FormControl(null, Validators.required),
      projectId: new FormControl(null, Validators.required),
      locale: new FormControl(null, Validators.required),
      selectSchema: new FormControl(),
      sourceType: new FormControl(null),
    });
  }
  ngOnDestroy(): void {
    this.setupJobForm.reset();
  }

  noLeadingSpaces(control: FormControl) {
    if (control.value && control.value.trimLeft() !== control.value) {
      return { leadingSpaces: true }; // Return an error if leading spaces are found
    }
    return null; // Return null if no leading spaces are found
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (this.isEdit && Object.keys(this.editJobProfile).length > 0) {
      this.patchForm();
    }

    if (this.projectId) {
      this.hideWorkspaces = false;
    } else {
      this.hideWorkspaces = true;
    }

    if (this.clear) {
      this.reset()
    }

    if (changes['routeType']) {
      this.reset()
    }

    // console.log(changes, this.queryParamNull, "changes");
  }

  reset() {
    this.setupJobForm.reset();
    this.uploadedFiles = [];
    this.xmlFiles = [];
    this.xsdFiles = [];
    this.type = ''
  }

  ngOnInit(): void {
    this.getDBConnections();
    this.getWorkspaces();
  }

  patchForm() {
    if (this.editJobProfile && this.editJobProfile['file_extension']) {
      this.setupJobForm.patchValue({
        jobName: this.editJobProfile['job_name'],
        locale: this.editJobProfile['locale'],
        sourceType: this.editJobProfile['file_extension'] === '.csv' ? 'csv' : this.editJobProfile['file_extension'] === '.json' ? 'json' : 'xml',
        selectSource: null,
        selectSchema: null
      })
      this.type = this.editJobProfile['file_extension'] === '.csv' ? 'csv' : this.editJobProfile['file_extension'] === '.json' ? 'json' : 'xml'
      this.disabled = true;
      this.fileName = this.editJobProfile['name_file']
      this.emitter();
    } else {
      this.dbConnectionsService.getDBConnections({ project_id: this.projectId, org_id: this.authService.currentUserValue.orgId }).subscribe({
        next: (res: any) => {
          if (res) {
            this.connections = res;
            if (Object.keys(this.editJobProfile).length > 0) {
              this.type = 'database'
              let source = this.connections.find(obj => obj['id'] === this.editJobProfile['source_connection']);
              this.dbConnectionsService.getSchemas(this.editJobProfile['source_connection']).subscribe({
                next: (res: any) => {
                  this.schemaList = res;
                  this.showSelectSchema = true;
                  this.setupJobForm.patchValue({
                    jobName: this.editJobProfile['job_name'],
                    locale: this.editJobProfile['locale'],
                    sourceType: 'database',
                    selectSource: source,
                    selectSchema: this.editJobProfile['source_schema']
                  })
                  this.disabled = true;
                  this.emitter();
                }
              })
            }
          }
        },
        error: err => {
          Toast.fire({
            icon: 'error',
            html: err.error.text
          });
        }
      })
    }
  }

  fetchDBs() {
    this.dbConnectionsService.getDBConnections({ project_id: this.setupJobForm['value']['projectId'], org_id: this.authService.currentUserValue.orgId }).subscribe({
      next: (res: any) => {
        if (res) {
          this.connections = res;
          this.setupJobForm.patchValue({ selectSource: null })
          this.emitter();
        }
      },
      error: err => {
        Toast.fire({
          icon: 'error',
          html: err.error.text
        });
      }
    })
  }

  getWorkspaces() {
    this.projectService.getAllProject({}).subscribe({
      next: res => {
        if (res && res['records']) {
          this.workspacesOptions = []
          res['records'].forEach(ele => {
            this.workspacesOptions.push({
              name: ele['projectName'],
              value: ele['id']
            })
          })
        }
      }, error: err => {

      }
    })
  }


  typeHandler() {
    this.type = this.setupJobForm.value['sourceType']
    this.setupJobForm.patchValue({ selectSchema: null, selectSource: null })
    this.uploadedFiles = []
    this.xmlFiles = []
    this.xsdFiles = []
    this.showSelectSchema = false;
    this.emitter()
    this.displayDocument(this.type);
  }

  displayDocument(type: string) {
    if (type == "database") {
      this.documentSlideService.customDocumentName("AddJobWithDB");
    } else {
      this.documentSlideService.customDocumentName("AddJob");
    }
  }

  selectedOptionChanged(event: any) {
    if (event && typeof event === 'object') {
      this.setupJobForm.get('locale').setValue(event.value, { emitEvent: false });
    } else {
      this.setupJobForm.get('locale').setValue(null, { emitEvent: false });
    }
    this.setupJobEmitter('');
  }

  filterOptions(event: any) {
    if (event.query) {
      const filteredValues = this.localeList.filter((option) =>
        option['name'].toLowerCase().includes(event.query.toLowerCase())
      );
      this.filteredOptions = filteredValues;
    } else {
      this.filteredOptions = this.localeList;
    }
  }

  getDBConnections() {
    if (this.projectId) {
      this.dbConnectionsService.getDBConnections({ project_id: this.projectId, org_id: this.authService.currentUserValue.orgId }).subscribe({
        next: (res: any) => {
          if (res) {
            this.connections = res;
          }
        },
        error: err => {
          Toast.fire({
            icon: 'error',
            html: err.error.text
          });
        }
      })
    }
  }

  onDropdownClick(): void {
    if (this.connections.length === 0) {
      // Perform click operations or other actions
      // console.log('Dropdown clicked, but options are empty.');
      this.showDialog()
    }
  }

  setupJobEmitter(data) {
    if (data == 'change') {
      this.setupJobForm.value['selectSchema'] = null
    }
    if (this.setupJobForm.value['selectSchema'] == undefined) {
      this.showSelectSchema = false;
      if (this.setupJobForm.value['selectSource'] && (this.setupJobForm.value['selectSource']['driver_name'] == 'mssql' || this.setupJobForm.value['selectSource']['driver_name'] == 'postgresql' || this.setupJobForm.value['selectSource']['driver_name'] == 'ibmdb2')) {
        this.schemaList = []
        this.dbConnectionsService.getSchemas(this.setupJobForm.value['selectSource']['id']).subscribe({
          next: (res: any) => {
            this.setupJobForm.patchValue({ selectSchema: null })
            this.schemaList = res;
            this.showSelectSchema = true;
          }
        })
      } else if (this.setupJobForm.value['selectSource'] && this.setupJobForm.value['selectSource']['driver_name'] == 'oracle') {
        this.schemaList = []
        this.dbConnectionsService.getSchemas(this.setupJobForm.value['selectSource']['id']).subscribe({
          next: (res: any) => {
            this.setupJobForm.patchValue({ selectSchema: null })
            this.schemaList = res;
            this.showSelectSchema = true;
          }
        })
        // this.setupJobForm.get('selectSchema').patchValue(this.setupJobForm.value['selectSource']['username_name'])
      } else {
        if (this.setupJobForm.value['selectSource'] && this.setupJobForm.value['selectSource']['database_name']) {
          this.setupJobForm.get('selectSchema').patchValue(this.setupJobForm.value['selectSource']['database_name'])
        }
      }
    }

    if (
      this.setupJobForm.value["selectSource"] &&
      this.setupJobForm.value["selectSource"]["driver_name"] == "salesforce"
    ) {
      this.showSelectSchema = false;
      this.setupJobForm.patchValue({ selectSchema: "-" });
    }

    if (!this.setupJobForm.value["selectSource"]) {
      this.setupJobForm.get("selectSchema").patchValue(null);
    }

    this.emitter();
  }

  showDialog() {
    // (onHide)="onDropdownClick()"
    this.dbDetails = null;
    this.clearDBForm = true;
    this.displayDBModal = true;
  }

  close() {
    this.clearDBForm = false;
    this.displayDBModal = false;
    this.dbDetails = null;
  }

  selectConnection(option: any) {
    this.selectedConnection = option;
    this.setupJobForm.get("selectSource").setValue(option.connection_name);
  }

  savedDBConnections(event) {
    if (event.message === "new connection successfully added") {
      Toast.fire({
        icon: "success",
        html: event.message,
      });
      this.getDBConnections();
      this.close();
    } else if (event.message === "connection successfully updated") {
      this.getDBConnections();
      this.close();
    }
  }

  editDB(option) {
    this.dbDetails = option;
    this.clearDBForm = false;
    this.displayDBModal = true;
  }

  deleteDB(option) {
    if (option["id"]) {
      this.dbConnectionsService
        .deleteDBConnection({
          ids: option["id"],
          project_id: this.projectId,
          org_id: this.authService.currentUserValue.orgId,
        })
        .subscribe({
          next: (res) => {
            if (
              res.message ==
              "Cannot perform the requested action. The record cannot be deleted or updated because it is referenced by other jobs"
            ) {
              Toast.fire({
                icon: "warning",
                html: res.message,
              });
            } else {
              Toast.fire({
                icon: "success",
                html: res.message,
              });
            }
            this.setupJobForm.get("selectSource").patchValue(null);
            this.setupJobForm.get("selectSchema").patchValue(null);
            this.showSelectSchema = false;
            this.getDBConnections();
          },
          error: (err) => {
            Toast.fire({
              icon: "error",
              html: err.error.text,
            });
          },
        });
    }
  }

  myUploader(event: any) {
    this.uploadedFiles = [event.files[0]];
    this.emitter();
  }

  xmlUploader(event: any) {
    this.xmlFiles = [event.files[0]];
    this.emitter();
  }

  clearFile(file: File) {
    this.uploadedFiles = [];
    this.emitter();
  }

  xsdUploader(event: any) {
    this.xsdFiles = [event.files[0]];
    this.emitter();
  }

  clearXSDFile(file: File) {
    this.xsdFiles = this.xsdFiles.filter((f: File) => f !== file);
    this.emitter();
  }

  clearXMLFile(file: File) {
    this.xmlFiles = this.xmlFiles.filter((f: File) => f !== file);
    this.emitter();
  }

  emitter() {
    let basicJob = { ...this.setupJobForm.value };
    basicJob["file"] =
      this.uploadedFiles.length > 0 ? [this.uploadedFiles[0]] : [];
    basicJob["xmlFile"] = this.xmlFiles.length > 0 ? [this.xmlFiles[0]] : [];
    basicJob["xsdFile"] = this.xsdFiles.length > 0 ? [this.xsdFiles[0]] : [];
    this.setupJob.emit(basicJob);
  }
}
