import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { BehaviorSubject, catchError, map, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import jwt_decode from 'jwt-decode';

// User Model Needs to be Changed
export class User {
  id: number;
  username: string;
  token?: string;
  email: string;
  exp: any;
  orgId: string;
}

@Injectable()
export class AuthService {

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  private apiUrl = environment.apiAuthUrl

  constructor(
    public afAuth: AngularFireAuth,
    private http: HttpClient
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  // Facebook login
  doFacebookLogin() {
    return new Promise<any>((resolve, reject) => {
      const provider = new firebase.auth.FacebookAuthProvider();
      this.afAuth.signInWithPopup(provider).then(
        res => {
          resolve(res);
        },
        err => {

          reject(err);
        }
      );
    });
  }

  // Github login
  doGitHubLogin() {
    return new Promise<any>((resolve, reject) => {
      const provider = new firebase.auth.GithubAuthProvider();
      this.afAuth.signInWithPopup(provider).then(
        res => {
          resolve(res);
        },
        err => {

          reject(err);
        }
      );
    });
  }

  // Twitter login
  doTwitterLogin() {
    return new Promise<any>((resolve, reject) => {
      const provider = new firebase.auth.TwitterAuthProvider();
      this.afAuth.signInWithPopup(provider).then(
        res => {
          resolve(res);
        },
        err => {

          reject(err);
        }
      );
    });
  }

  // Google login
  doGoogleLogin() {
    return new Promise<any>((resolve, reject) => {
      const provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');
      this.afAuth.signInWithPopup(provider).then(
        res => {
          resolve(res);
        },
        err => {

          reject(err);
        }
      );
    });
  }

  // Register
  doRegister(value) {
    return this.http.post<any>(`${this.apiUrl}/register`, value)
  }

  doFinalizeRegistration(value) {
    return this.http.post<any>(`${this.apiUrl}/users/verify-email`, value)
  }

  // Login
  doLogin(value) {
    return this.http.post<any>(`${this.apiUrl}/signin`, value).pipe(catchError(this.handleError), map(jwt => {
      let user: User;
      if (jwt && jwt.token) {
        user = jwt_decode(jwt.token);
        user.token = jwt.token;
        sessionStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
      }
      return user;
    }));
  }

  changePassword(passwordData) {
    return this.http.put<any>(`${this.apiUrl + '/users/change-password'}`, passwordData)
  }

  checkingPassword(password) {
    return this.http.post<any>(`${this.apiUrl + '/users/check-password'}`, password)
  }

  forgotPasswordInit(email) {
    return this.http.post<any>(`${this.apiUrl + '/users/reset-password/init'}`, email)
  }

  verifyResetKey(key) {
    return this.http.post<any>(`${this.apiUrl + '/users/reset-password/verify'}`, { key: key })
  }

  resetPasswordFinish(value) {
    return this.http.put<any>(`${this.apiUrl + '/users/reset-password/finish'}`, value)
  }


  handleError(error: HttpErrorResponse) {
    return throwError(() => error);
  }

  doResendEmailVerificationCode(value) {
    return this.http.post<any>(`${this.apiUrl}/users/resend-verification-email`, value)
  }

  // Logout
  doLogout() {
    return new Promise<void>((resolve, reject) => {
      if (firebase.auth().currentUser) {
        sessionStorage.removeItem('currentUser');
        sessionStorage.removeItem('remember');
        this.afAuth.signOut();
        resolve();
      } else {
        sessionStorage.removeItem('currentUser');
        resolve();
      }
    });
  }
}
