import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-text-area',
  templateUrl: './text-area.component.html'
})
export class TextAreaComponent implements OnChanges {

  @Input() field: any = {};
  @Input() form !: FormGroup;


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['field']) {
      if (this.field['value'] !== null) {
        this.form.get(this.field?.name).patchValue(this.field?.value);
      } else {
        // TODO document why this block is empty
      }
    }
  }
  get f() {
    return this.form.controls;
  }
  inValid(control: any) {
    return control.invalid && (control.dirty || control.touched);
  }

}
