<div class="chat-msg-block">
    <ul>
        <ng-container *ngIf="chatMessages.length">
            <li class="thread mx-0" [class]="msg.type" *ngFor="let msg of chatMessages">
                <div *ngIf="msg?.question">
                    <div class="d-flex align-items-baseline">
                        <div class="pl-0 user-logo">{{userData['name'].charAt(0)}}
                        </div>
                        <h3>{{msg?.question}}</h3>
                    </div>
                    <div class="d-flex1">
                        <div class="chat-msg">
                            {{
                            msg?.answer ? msg?.answer : 'Something went wrong. Please retry'}}
                        </div>

                        <img *ngIf="validateImg(msg['attachment'])" [src]="'data:image/png;base64,' + msg['attachment']"
                            alt="Attachment">
                    </div>
                </div>
            </li>
        </ng-container>
        <li class="thread mx-0" *ngIf="loading">
            <div class="d-flex align-items-baseline">
                <div class="pl-0 user-logo">{{userData['name'].charAt(0)}}</div>
                <h3>{{ latestQues }}</h3>
            </div>
        </li>
        <li class="thread text-center" *ngIf="loading">
            <p style="color: #0073ff;font-size:14px !important;">Please wait for a
                few minutes for the LLM server to initialize.</p>
            <div class="loader-wrapper ">
                <div class="loader-container mt-1">
                    <div class="ball-pulse loader-primary">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        </li>
    </ul>
</div>

<div class="message-area">
    <div class="message-input-block">
        <div class="message-input-holder">
            <textarea placeholder="Upload documents in PDF or doc format" #msgInput [(ngModel)]="question"></textarea>
            <button class="submit btn-enable" (click)="postQuery()">
                <i class="fa fa-arrow-up" aria-hidden="true"></i>
            </button>
        </div>
    </div>
</div>