<div class="app-content content">
    <div class="content-wrapper">
        <div class="container-xxl">
            <div class="page-main pages oto60-chat">
                <div *ngIf="fragment == 'cleansing'; else elseBlock">
                    <div class="row">
                        <div class="col-lg-12 mb-2">
                            <div class="breadcrumb-container mb-2">
                                <app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>
                            </div>
                            <section id="main-content">
                                <div class="page-title">
                                    <ng-container mCardHeaderTitle>
                                        <h1 class="m-0">{{ 'Data Wrangler' }}</h1>
                                        <p class="m-0">{{ 'Cleanse and refine datasets.'
                                            }}
                                        </p>
                                    </ng-container>
                                </div>
                                <app-data-cleansing [queryParamNull]="queryParamNull"
                                    [projectId]="projectId"></app-data-cleansing>
                            </section>
                        </div>
                    </div>
                </div>
                <ng-template #elseBlock>
                    <div class="row">
                        <div class="col-lg-12 mb-2">
                            <div class="breadcrumb-container mb-2">
                                <app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>
                            </div>
                            <section id="main-content">
                                <div class="setup-job-step1 animate__animated"
                                    [ngClass]="{'first-step-block animate__fadeInDownBig': step === 'first', 'first-step-none animate__fadeInRight': step !== 'first'}">
                                    <ng-container mCardHeaderTitle>
                                        <div class="page-title">
                                            <ng-container *ngIf="routeType == 'automl'">
                                                <h1 class="m-0">{{ 'Train ML Model' | translate }}</h1>
                                                <p class="m-0">{{ 'Train and test machine learning models.' | translate
                                                    }}
                                                </p>
                                            </ng-container>

                                            <ng-container *ngIf="routeType == 'synthesis'">
                                                <h1 class="m-0">{{ 'addjob.pagetitlestep1' | translate }}</h1>
                                                <p class="m-0">{{ 'addjob.setupparatext' | translate }}</p>
                                            </ng-container>

                                            <ng-container *ngIf="routeType == 'pii'">
                                                <h1 class="m-0">{{ 'Check for PII' | translate }}</h1>
                                                <p class="m-0">{{ 'Setup workflow parameters' | translate }}</p>
                                            </ng-container>

                                            <ng-container *ngIf="routeType == 'anomaly'">
                                                <h1 class="m-0">{{ 'Manage Anomalies' | translate }}</h1>
                                                <p class="m-0">{{ 'Setup workflow parameters' | translate }}</p>
                                            </ng-container>

                                            <ng-container *ngIf="routeType == 'rules'">
                                                <h1 class="m-0">{{ 'Deploy Rule Sets' | translate }}</h1>
                                                <p class="m-0">{{
                                                    'Remove personally identifiable information and synthesize data' |
                                                    translate }}</p>
                                            </ng-container>

                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="projectId != ''">
                                        <app-setup-job [queryParamNull]="queryParamNull" [projectId]="projectId"
                                            (setupJob)="jobSetupDetails($event)" [routeType]="routeType"
                                            [clear]="clearSetupJob" [editJobProfile]="editJobProfile"
                                            [isEdit]="isEdit"></app-setup-job>
                                    </ng-container>
                                    <div class="text-right first-step-button space-20 mb-5 first-step-button row"
                                        *ngIf="!isButtonDisabled">
                                        <h2 class="col-md-3"> </h2>
                                        <div class="col-lg-9 col-md-9 setup-info">
                                            <div class="row">
                                                <div class="col-lg-6"></div>
                                                <div class="col-lg-6 text-right mt-2">
                                                    <button class="btn btn-primary" [disabled]="isButtonDisabled"
                                                        (click)="goToNextStep()">Confirm</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="setup-job-step2 animate__animated"
                                    [ngClass]="{'second-step-block animate__bounceInRight': step === 'second', 'second-step-none': step !== 'second'}">
                                    <ng-container mCardHeaderTitle>
                                        <div class="page-title mb-0"
                                            aria-label="'Step 2 out of 3 Assign Semantics And Synthesis Strategy Page'">
                                            <h1>
                                                {{
                                                routeType === 'synthesis' ? ('addjob.pagetitlestep2' |
                                                translate) :
                                                routeType === 'automl' ? ('Train ML Model' |
                                                translate) :
                                                routeType === 'rules' ? ('Setup Rules and Assign' |
                                                translate) :
                                                routeType === 'anomaly' ? ('Manage Anomalies' |
                                                translate) :
                                                routeType === 'pii' ? ('addjob.setUpPiiTitle' | translate) :
                                                ''
                                                }}
                                            </h1>
                                            <p class="mb-0">
                                                {{
                                                routeType === 'synthesis' ? ('addjob.step2paratext' |
                                                translate) :
                                                routeType === 'automl' ? ('Train and test machine learning models.' |
                                                translate) :
                                                routeType === 'rules' ? ('Upload rules expressions in DRL format and
                                                assign
                                                them to
                                                columns and elements' |
                                                translate) :
                                                routeType === 'anomaly' ? ('Manage Anomalies' |
                                                translate) :
                                                routeType === 'pii' ? ('addjob.setUpPiisDesc' | translate) :
                                                ''
                                                }}
                                            </p>
                                        </div>
                                        <div class="previous-data d-flex">
                                            <div class="d-flex align-items-center">
                                                <h4 class="m-0">Locale</h4>
                                                <span>{{jobDetails['locale']}}</span>
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <h4 class="m-0">Source</h4>
                                                <span>{{jobDetails && jobDetails['sourceType'] ?
                                                    jobDetails['sourceType'] :
                                                    ''}}</span>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <div>
                                        <app-assign-semantics [fileJob]="fileJob" [routeType]="routeType"
                                            [jobDetails]="jobDetails" [projectId]="projectId"
                                            (selectedTableAndColumnDetails)="selectedTableDetails($event)"
                                            (columnsDataDetails)="emitedColumnsDataDetails($event)"
                                            (patternEmitter)="patternHandler($event)" (ruleEmitter)="ruleEvent($event)"
                                            (tableEmitter)="tableInfo($event)" (piiDataEmitter)="piiInfoHandler($event)"
                                            (piiReportEmitter)="reportHandler($event)"
                                            (anomalyEmitter)="anomalyEvent($event)"
                                            (autoMLEmitter)="autoMLEvent($event)" [editJobProfile]="editJobProfile"
                                            [isEdit]="isEdit" [fragment]="fragment">
                                        </app-assign-semantics>
                                    </div>
                                    <div *ngIf="columnsData.length > 0 || showRuleTest || displayRulesinThird || showConfirmJob"
                                        class="mb-2">
                                        <app-confirm-job [anomalyData]="anomalyData" [step]="step"
                                            [columnsData]="columnsData" [jobDetails]="jobDetails"
                                            [routeType]="routeType" [selectedColumnDetails]="selectedColumnDetails"
                                            (rowLimit)="limitData($event)" [showRuleTest]="showRuleTest"
                                            [projectId]="projectId" [ruleData]="ruleData" [tableInfo]="selTable"
                                            [fileJob]="fileJob" (biasEmitterHandler)="biasEvent($event)"
                                            (modelTypeEvent)="modelTypeEmitter($event)"
                                            [editJobProfile]="editJobProfile" [isEdit]="isEdit"></app-confirm-job>
                                    </div>
                                    <div class="form-group d-flex justify-content-end mt-1 pt-2  space-20">
                                        <button uiSref="personal" type="button" [disabled]="jobLoading"
                                            class="btn btn-raised btn-secondary btn-small mr-1"
                                            (click)="previousbuttonNavigation()">
                                            <span><i class="feather ft-chevron-left"></i></span>
                                            {{ 'buttontitle.previousbutton' | translate }}
                                        </button>
                                        <button *ngIf="routeType=='pii'" uiSref="address" type="button"
                                            class=" btn btn-raised btn-warning  btn-small mr-1 first-screen-btn"
                                            [disabled]="hideViewReport" (click)="displayPIIReports = true">
                                            {{ 'View Report' }}
                                        </button>
                                        <button uiSref="personal" type="button"
                                            class=" btn btn-raised btn-secondary mr-1 " (click)="routerToJobs()">
                                            {{ 'buttontitle.discardexit' | translate }}
                                        </button>
                                        <button *ngIf="!['anomaly', 'pii', 'automl'].includes(routeType)"
                                            uiSref="address" type="button" class=" btn btn-raised btn-secondary mr-1"
                                            (click)="tableTest()" [disabled]="columnsData.length == 0 && !showRuleTest">
                                            <i *ngIf="!jobLoading" class="feather ft-zap"></i>
                                            <span class="spinner" *ngIf="jobLoading"></span>
                                            {{ 'buttontitle.testbutton' | translate }}
                                        </button>
                                        <button *ngIf="routeType!='pii'" uiSref="address" type="button"
                                            class=" btn btn-raised btn-warning btn-small"
                                            (click)="enterThirdStep('submit')"
                                            [disabled]="columnsData.length == 0 && !showRuleTest && anomalyObjectProps() == 0 && !showConfirmJob">
                                            {{ 'buttontitle.submitjob' | translate }}
                                        </button>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </ng-template>

            </div>
        </div>
    </div>
</div>

<p-dialog [(visible)]="showTest" [draggable]="false" [resizable]="false" [modal]="true" [blockScroll]="true"
    [style]="{width: '80vw'}" [styleClass]="'thirdtestpop'">
    <div *ngIf="showTest" class="test-popup">
        <div class="p-dialog-titlebar">
            <button class="p-dialog-titlebar-icon p-dialog-titlebar-close" aria-label="Close Dialog"
                (click)="showtest()">
                <span class="pi pi-times"></span>
            </button>
        </div>
        <app-confirm-synthesis-data [top]="true" [tableTestData]="tableTestData">
        </app-confirm-synthesis-data>
    </div>
</p-dialog>

<p-dialog [(visible)]="showRuleTestResults" [resizable]="false" [draggable]="false" [modal]="true" [blockScroll]="true"
    [style]="{width: '80vw'}" [styleClass]="'thirdtestpop'">
    <div class="p-dialog-titlebar">
        <button class="p-dialog-titlebar-icon p-dialog-titlebar-close" aria-label="Close Dialog"
            (click)="displayshowRuleTest()">
            <span class="pi pi-times"></span>
        </button>
    </div>
    <div *ngIf="showRuleTestResults" class="test-popup">
        <app-rule-test [messageData]="testData"></app-rule-test>
    </div>
</p-dialog>

<p-dialog [(visible)]="showFileTestResult" [resizable]="false" [draggable]="false" [modal]="true" [blockScroll]="true"
    [style]="{width: '80vw'}" [styleClass]="'thirdtestpop'">
    <div class="p-dialog-titlebar">
        <button class="p-dialog-titlebar-icon p-dialog-titlebar-close" aria-label="Close Dialog"
            (click)="showtfileTest()">
            <span class="pi pi-times"></span>
        </button>
    </div>
    <div *ngIf="showFileTestResult" class="test-popup">
        <app-file-test [message]="fileTestResult" [type]="jobDetails['sourceType']"></app-file-test>
    </div>
</p-dialog>

<p-dialog [(visible)]="displayPIIReports" [resizable]="false" [draggable]="false" [modal]="true" [blockScroll]="true"
    [style]="{width: '80vw'}" [styleClass]="'thirdtestpop'">
    <div class="p-dialog-titlebar">
        <button class="p-dialog-titlebar-icon p-dialog-titlebar-close" aria-label="Close Dialog"
            (click)="closePIIReport()">
            <span class="pi pi-times"></span>
        </button>
    </div>
    <div *ngIf="displayPIIReports" class="test-popup">
        <app-pii-phi-report [piiReportData]="piiReportData" [jobDetails]="jobDetails"></app-pii-phi-report>
    </div>
</p-dialog>