import { Component, OnInit, Renderer2, ViewChild } from "@angular/core";
import { ThemeSettingsService } from "../settings/theme-settings.service";
import { MenuSettingsService } from "../settings/menu-settings.service";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { PerfectScrollbarConfigInterface, PerfectScrollbarComponent, PerfectScrollbarDirective } from "ngx-perfect-scrollbar";
import { timer } from "rxjs";
import { UserService } from "../../_services/user.service";
import { Toast } from 'src/app/_helpers/toast';
import { SharedService } from "src/app/_services/shared.service";
declare let require: any;
const colors = require("../../../assets/data/customizer/customizerColor.json");

@Component({
  selector: "app-chat",
  templateUrl: "./chat.component.html",
})
export class ChatComponent implements OnInit {
  paragraphs: any[] = [];
  accordions: any[] = [];
  documentContent: any = {};
  showDynamicHtml: boolean = true;
  public documentValue = "";
  private subscription: Subscription;
  private _unsubscribeAll: Subject<any>;
  private _unsubscribeAllMenu: Subject<any>;
  public _themeChatConfig: any;
  public _menuChatConfig: any;
  public _themeSettingsConfig: any;
  public selectColorClass: string;
  public activebutton: any;

  public isCollapsedMenu = false;
  public isfixChecked = false;
  public isboxChecked = false;
  public isStaticLayout = false;

  public isRightSideIcons = false;
  public isBorderedNavigation = false;
  public isFlippedNavigation = false;
  public isCollapsibleNavigation = false;
  public isStaticNavigation = false;

  public isStaticTop = false;

  public config: PerfectScrollbarConfigInterface = { wheelPropagation: false };
  collapsed = true;

  docBrudataUrl = "";
  docsBruddataUrl: string = null;
  loading = true;
  isChatOpen: boolean = false;
  clickEventsubscription: Subscription;
  modeType: string = 'general';
  llmData;
  chatbotData;
  analytixData = {}
  cfoDetails = {};

  @ViewChild(PerfectScrollbarComponent)
  componentRef?: PerfectScrollbarComponent;
  @ViewChild(PerfectScrollbarDirective, { static: true })
  directiveRef?: PerfectScrollbarDirective;
  userData: Object = {};

  constructor(
    private _renderer: Renderer2,
    private _themeSettingsService: ThemeSettingsService,
    private _menuSettingsService: MenuSettingsService,
    private userService: UserService,
    private sharedService: SharedService
  ) {
    this._unsubscribeAll = new Subject();
    this._unsubscribeAllMenu = new Subject();
    this.clickEventsubscription = this.sharedService.getClickEvent().subscribe((data) => {
      this.dynamicIconSliding(data)
    })
  }

  ngOnInit() {
    this.loading = true;
    timer(5000).subscribe(() => {
    });
    // Subscribe to config changes
    this._themeSettingsService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this._themeChatConfig = config;
      });
    this._menuSettingsService.config
      .pipe(takeUntil(this._unsubscribeAllMenu))
      .subscribe((config) => {
        this._menuChatConfig = config;
      });
    this._themeSettingsService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this._themeSettingsConfig = config;
      });

    // document.addEventListener('click', (event) => {
    //   this.handleGlobalClick(event);
    // });
    this.getprofile();
  }

  getprofile() {
    this.userService.getUserProfile().subscribe({
      next: (res) => {
        if (res) {
          this.userData = res;
        }
      },
      error: err => {
        Toast.fire({
          icon: 'error',
          html: err.error.error.message
        });
      }
    })
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    document.removeEventListener('click', this.handleGlobalClick);
    this.isChatOpen = false;
  }


  toggleChat(event) {
    const element = document.getElementById("chat");
    const customizerElement = document.getElementById("customizer");

    if (element && element.classList.contains("open")) {
      this.isChatOpen = false
      this._renderer.removeClass(element, "open");
    } else {
      this.isChatOpen = true
      this._renderer.addClass(element, "open");
      this.modeType = 'general'
    }

    // Close the customizer component if it's open
    if (customizerElement && customizerElement.classList.contains("open")) {
      this._renderer.removeClass(customizerElement, "open");
      this.isChatOpen = false;
    }
  }

  public dynamicIconSliding(data) {
    console.log('chat', data)
    const element = document.getElementById("chat");
    this.isChatOpen = true
    this._renderer.addClass(element, "open");
    if (data && data['type'] == 'llm') {
      this.modeType = 'llm'
      this.llmData = data['data']
    } else if (data && data['type'] == 'chatbot') {
      this.modeType = 'chatbot'
      this.chatbotData = data['data']
    } else if (data && data['type'] == 'analytix') {
      this.modeType = 'analytix'
      this.analytixData = data['data']
    } else if (data && data['type'] == 'cfo-chat') {
      this.modeType = 'cfo-chat';
      this.cfoDetails = data['data']
    }
  }

  handleGlobalClick(event: Event) {
    const chatElement = document.getElementById("chat");
    const customizerElement = document.getElementById("customizer");

    // Check if the click event occurred outside the chat component
    if (chatElement && !chatElement.contains(event.target as Node) && chatElement.classList.contains("open")) {
      this._renderer.removeClass(chatElement, "open");
      this.isChatOpen = false;
    }

    // Check if the click event occurred outside the customizer component
    if (customizerElement && !customizerElement.contains(event.target as Node) && customizerElement.classList.contains("open")) {
      this._renderer.removeClass(customizerElement, "open");
    }
  }


}
