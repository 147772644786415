<p-messages [(value)]="messages1" [enableService]="false" [closable]="true"></p-messages>

<div class="page-main pages mt-2" *ngIf="subscriptionData!=null &&  subscriptionData?.items?.length > 0 ">
    <div class="mb-2">
        <h4 class="label-title">Details</h4>
        <div class="d-flex">
            <label class="detail-label">Name</label>
            <p class="datail-info">{{subscriptionData?.customer?.name}}</p>
        </div>
        <div class="d-flex">
            <label class="detail-label">Created</label>
            <p class="detail-info">{{(subscriptionData?.created*1000) | date }}</p>
        </div>
        <div class="d-flex">
            <label class="detail-label">Current Period</label>
            <p class="detail-info"> {{(subscriptionData?.current_period_start*1000) | date }} to
                {{(subscriptionData?.current_period_end*1000) | date }} </p>
        </div>
    </div>

    <div class="mb-4">
        <p-table [value]="products">
            <ng-template pTemplate="header">
                <tr>
                    <th>Products</th>
                    <th>Quantity</th>
                    <th class="text-end">Total</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
                <tr>
                    <td>{{ item.price?.product?.name }}</td>
                    <td *ngIf="item.plan?.usage_type=='metered'">View usage</td>
                    <td *ngIf="item.plan?.usage_type!='metered'">{{ item.quantity }}</td>
                    <td *ngIf="item.plan?.usage_type=='metered'" class="text-end">Varies with usage</td>
                    <td *ngIf="item.plan?.usage_type!='metered'" class="text-end">{{
                        ( item.price?.tiers[0].up_to >= item.quantity ?
                        ((item.price?.tiers[0].unit_amount/100) * item.quantity) :
                        ((item.price?.tiers[1].unit_amount/100) * item.quantity) ) | currency:
                        (subscriptionData?.currency | uppercase)
                        }} / {{item.price?.recurring?.interval}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <div class="mb-2">
        <h4 class="label-title">Upcomming Invoice</h4>
        <p>This is a preview of the inovice that will be billed on {{ (upcomingInvoice?.next_payment_attempt*1000) |
            date}}. It may change if the
            subscription is updated. </p>
    </div>

    <div class="mb-4">
        <p-table [value]="upcomingInvoice?.lines?.data">
            <ng-template pTemplate="header">
                <tr>
                    <th>Products</th>
                    <th>Quantity</th>
                    <th class="text-end">UNIT PRICE</th>
                    <th class="text-end">Amount</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
                <tr>
                    <td>{{ item.description }}</td>
                    <td>{{ item.quantity }}</td>
                    <td class="text-end" *ngIf="item.plan.usage_type!='metered'">{{
                        ((item.unit_amount_excluding_tax*1)/100)+' ' | currency: (upcomingInvoice.currency | uppercase)
                        }}</td>
                    <td class="text-end" *ngIf="item.plan.usage_type=='metered'">View Usage</td>
                    <td class="text-end">{{ (item.amount_excluding_tax/100)+' ' | currency: (upcomingInvoice.currency |
                        uppercase) }}</td>
                </tr>
                <!-- <tr *ngIf="upcomingInvoice?.lines?.data?.length-1 == rowIndex">
                    <td class="text-end" colspan="3"  style="border: none;">subtotal excluding tax</td>
                    <td class="text-end" style="border: none;">{{ (upcomingInvoice?.subtotal_excluding_tax/100)+' ' | currency: (upcomingInvoice.currency | uppercase) }}</td>
                </tr> -->
                <tr *ngIf="upcomingInvoice?.lines?.data?.length-1 == rowIndex">
                    <td class="text-end" colspan="3" style="border: none;">Subtotal</td>
                    <td class="text-end" style="border: none;">{{ (upcomingInvoice?.subtotal/100)+' ' | currency:
                        (upcomingInvoice.currency | uppercase) }}</td>
                </tr>
                <tr *ngIf="upcomingInvoice?.lines?.data?.length-1 == rowIndex">
                    <td class="text-end" colspan="3" style="border: none;">Total excluding tax</td>
                    <td class="text-end" style="border: none;">{{ (upcomingInvoice?.total_excluding_tax/100)+' ' |
                        currency: (upcomingInvoice.currency | uppercase) }}</td>
                </tr>
                <tr *ngIf="upcomingInvoice?.lines?.data?.length-1 == rowIndex">
                    <td class="text-end" colspan="3" style="border: none;">Total</td>
                    <td class="text-end" style="border: none;">{{ (upcomingInvoice?.total/100)+' ' | currency:
                        (upcomingInvoice.currency | uppercase) }}</td>
                </tr>
                <tr *ngIf="upcomingInvoice?.lines?.data?.length-1 == rowIndex">
                    <td class="text-end" colspan="3" style="border: none;">Amount due</td>
                    <td class="text-end" style="border: none;">{{ (upcomingInvoice?.amount_due/100)+' ' | currency:
                        (upcomingInvoice.currency | uppercase) }}</td>
                </tr>

            </ng-template>
        </p-table>
    </div>
</div>

<div class="row" *ngIf="!subscriptionData">
    <div class="col-9 mt-2">
        <!-- <div class="page-main pages d-flex justify-content-between align-items-center mt-2"
        *ngIf="subscriptionData!=null &&  subscriptionData?.items?.length == 0 "> -->
        <div class="mb-2">
            <h4 class="label-title">Upgrade to pro</h4>
            <!-- <p>No Active Subscription found, Upgrade to access Pro features and increase processing limits. </p> -->
            <p>No Active Subscription found. Unlock a world of possibilities by upgrading to our Pro plan. Access
                premium
                features, enhance your experience, and enjoy increased processing limits. Elevate your usage to the next
                level with Pro!</p>
        </div>
        <div class="pro-version-side version-main">
            <button type="button" class="btn btn-secondary" (click)="priceDialog()">
                <div class="d-flex justify-center gap-xs">
                    <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="arrow-up-right"
                        class="svg-inline--fa" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                        <path fill="currentColor"
                            d="M328 96c13.3 0 24 10.7 24 24V360c0 13.3-10.7 24-24 24s-24-10.7-24-24V177.9L73 409c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l231-231H88c-13.3 0-24-10.7-24-24s10.7-24 24-24H328z">
                        </path>
                    </svg>
                    <div class="text-align-center learn-more">Try Pro</div>
                </div>
            </button>
        </div>
    </div>
</div>

<div class="subscription">
    <p-dialog [(visible)]="priceModel" [modal]="true" [style]="{ width: '35vw' }" [draggable]="false"
        [resizable]="false" [dismissableMask]="false" [responsive]="true" [blockScroll]="true" [closeOnEscape]="true"
        [focusTrap]="true" showCloseIcon="true">
        <button class="p-dialog-titlebar-icon p-dialog-titlebar-close" aria-label="Close Dialog"
            (click)="priceModel=false">
            <span class="pi pi-times"></span>
        </button>
        <div>
            <div class="chat-logo text-center mb-3">
                <img style="width: 100px;" src="assets/images/logo/logo.svg">
            </div>

            <!-- <div class="text-center mt-5">
			<h3 class="mb-4">Subscription plans Coming Soon</h3>
			<button class="btn btn-secondary" (click)="priceModel = false">Go Back</button>
		</div> -->

            <div class="row subscription-plans info-cards">
                <div class="col-6 mb-2">
                    <div class="d-flex align-items-center">
                        <!-- <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="star-christmas"
							class="svg-inline--fa fa-star-christmas fa-fw fa-lg " role="img"
							xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
							<path fill="currentColor"
								d="M279.2 17.9C276.5 7.4 266.9 0 256 0s-20.5 7.4-23.2 17.9L188.3 188.3 17.9 232.8C7.4 235.5 0 245.1 0 256s7.4 20.5 17.9 23.2l170.4 44.4 44.4 170.4c2.8 10.6 12.3 17.9 23.2 17.9s20.5-7.4 23.2-17.9l44.4-170.4 170.4-44.4c10.6-2.8 17.9-12.3 17.9-23.2s-7.4-20.5-17.9-23.2L323.7 188.3 279.2 17.9zm-48 196.1l24.8-95 24.8 95c2.2 8.4 8.8 15 17.2 17.2l95 24.8-95 24.8c-8.4 2.2-15 8.8-17.2 17.2l-24.8 95-24.8-95c-2.2-8.4-8.8-15-17.2-17.2l-95-24.8 95-24.8c8.4-2.2 15-8.8 17.2-17.2zM71 71c-9.4 9.4-9.4 24.6 0 33.9l48 48c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L105 71c-9.4-9.4-24.6-9.4-33.9 0zM441 71c-9.4-9.4-24.6-9.4-33.9 0l-48 48c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l48-48c9.4-9.4 9.4-24.6 0-33.9zm0 336l-48-48c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l48 48c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9zM71 441c9.4 9.4 24.6 9.4 33.9 0l48-48c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L71 407c-9.4 9.4-9.4 24.6 0 33.9z">
							</path>
						</svg> -->
                        <h3 class="m-0 ml-1">Auto ML</h3>
                    </div>
                    <p class="subscription-item">Advanced Auto ML features, Intricate model customization, Benchmarking,
                        Monitoring, $1 per model pricing.</p>
                </div>
                <div class="col-6 mb-2">
                    <div class="d-flex align-items-center">
                        <!-- <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="star-christmas"
							class="svg-inline--fa fa-star-christmas fa-fw fa-lg " role="img"
							xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
							<path fill="currentColor"
								d="M279.2 17.9C276.5 7.4 266.9 0 256 0s-20.5 7.4-23.2 17.9L188.3 188.3 17.9 232.8C7.4 235.5 0 245.1 0 256s7.4 20.5 17.9 23.2l170.4 44.4 44.4 170.4c2.8 10.6 12.3 17.9 23.2 17.9s20.5-7.4 23.2-17.9l44.4-170.4 170.4-44.4c10.6-2.8 17.9-12.3 17.9-23.2s-7.4-20.5-17.9-23.2L323.7 188.3 279.2 17.9zm-48 196.1l24.8-95 24.8 95c2.2 8.4 8.8 15 17.2 17.2l95 24.8-95 24.8c-8.4 2.2-15 8.8-17.2 17.2l-24.8 95-24.8-95c-2.2-8.4-8.8-15-17.2-17.2l-95-24.8 95-24.8c8.4-2.2 15-8.8 17.2-17.2zM71 71c-9.4 9.4-9.4 24.6 0 33.9l48 48c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L105 71c-9.4-9.4-24.6-9.4-33.9 0zM441 71c-9.4-9.4-24.6-9.4-33.9 0l-48 48c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l48-48c9.4-9.4 9.4-24.6 0-33.9zm0 336l-48-48c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l48 48c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9zM71 441c9.4 9.4 24.6 9.4 33.9 0l48-48c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L71 407c-9.4 9.4-9.4 24.6 0 33.9z">
							</path>
						</svg> -->
                        <h3 class="m-0 ml-1">Configure LLM</h3>
                    </div>
                    <p class="subscription-item">Upload documents, Images, and more, supported by the latest AI models
                        like Claude 2.1 and multimodal GPT-4-Visual.</p>
                </div>
                <div class="col-6 mb-2">
                    <div class="d-flex align-items-center">
                        <!-- <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="star-christmas"
							class="svg-inline--fa fa-star-christmas fa-fw fa-lg " role="img"
							xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
							<path fill="currentColor"
								d="M279.2 17.9C276.5 7.4 266.9 0 256 0s-20.5 7.4-23.2 17.9L188.3 188.3 17.9 232.8C7.4 235.5 0 245.1 0 256s7.4 20.5 17.9 23.2l170.4 44.4 44.4 170.4c2.8 10.6 12.3 17.9 23.2 17.9s20.5-7.4 23.2-17.9l44.4-170.4 170.4-44.4c10.6-2.8 17.9-12.3 17.9-23.2s-7.4-20.5-17.9-23.2L323.7 188.3 279.2 17.9zm-48 196.1l24.8-95 24.8 95c2.2 8.4 8.8 15 17.2 17.2l95 24.8-95 24.8c-8.4 2.2-15 8.8-17.2 17.2l-24.8 95-24.8-95c-2.2-8.4-8.8-15-17.2-17.2l-95-24.8 95-24.8c8.4-2.2 15-8.8 17.2-17.2zM71 71c-9.4 9.4-9.4 24.6 0 33.9l48 48c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L105 71c-9.4-9.4-24.6-9.4-33.9 0zM441 71c-9.4-9.4-24.6-9.4-33.9 0l-48 48c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l48-48c9.4-9.4 9.4-24.6 0-33.9zm0 336l-48-48c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l48 48c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9zM71 441c9.4 9.4 24.6 9.4 33.9 0l48-48c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L71 407c-9.4 9.4-9.4 24.6 0 33.9z">
							</path>
						</svg> -->
                        <h3 class="m-0 ml-1">Synthesize Data</h3>
                    </div>
                    <p class="subscription-item">Artificial generation mimicking real datasets. Enhances model training
                        by diversifying input. Improves robustness and generalization. $1 per 100 rows.</p>
                </div>
                <div class="col-6 mb-2">
                    <div class="d-flex align-items-center">
                        <!-- <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="star-christmas"
							class="svg-inline--fa fa-star-christmas fa-fw fa-lg " role="img"
							xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
							<path fill="currentColor"
								d="M279.2 17.9C276.5 7.4 266.9 0 256 0s-20.5 7.4-23.2 17.9L188.3 188.3 17.9 232.8C7.4 235.5 0 245.1 0 256s7.4 20.5 17.9 23.2l170.4 44.4 44.4 170.4c2.8 10.6 12.3 17.9 23.2 17.9s20.5-7.4 23.2-17.9l44.4-170.4 170.4-44.4c10.6-2.8 17.9-12.3 17.9-23.2s-7.4-20.5-17.9-23.2L323.7 188.3 279.2 17.9zm-48 196.1l24.8-95 24.8 95c2.2 8.4 8.8 15 17.2 17.2l95 24.8-95 24.8c-8.4 2.2-15 8.8-17.2 17.2l-24.8 95-24.8-95c-2.2-8.4-8.8-15-17.2-17.2l-95-24.8 95-24.8c8.4-2.2 15-8.8 17.2-17.2zM71 71c-9.4 9.4-9.4 24.6 0 33.9l48 48c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L105 71c-9.4-9.4-24.6-9.4-33.9 0zM441 71c-9.4-9.4-24.6-9.4-33.9 0l-48 48c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l48-48c9.4-9.4 9.4-24.6 0-33.9zm0 336l-48-48c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l48 48c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9zM71 441c9.4 9.4 24.6 9.4 33.9 0l48-48c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L71 407c-9.4 9.4-9.4 24.6 0 33.9z">
							</path>
						</svg> -->
                        <h3 class="m-0 ml-1">Rules</h3>
                    </div>
                    <p class="subscription-item">Enjoy create, edit, delete rules that suits for your business needs on
                        your data. $5 per 100 rules.
                    </p>
                </div>
                <div class="col-6 mb-2">
                    <div class="d-flex align-items-center">
                        <!-- <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="star-christmas"
							class="svg-inline--fa fa-star-christmas fa-fw fa-lg " role="img"
							xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
							<path fill="currentColor"
								d="M279.2 17.9C276.5 7.4 266.9 0 256 0s-20.5 7.4-23.2 17.9L188.3 188.3 17.9 232.8C7.4 235.5 0 245.1 0 256s7.4 20.5 17.9 23.2l170.4 44.4 44.4 170.4c2.8 10.6 12.3 17.9 23.2 17.9s20.5-7.4 23.2-17.9l44.4-170.4 170.4-44.4c10.6-2.8 17.9-12.3 17.9-23.2s-7.4-20.5-17.9-23.2L323.7 188.3 279.2 17.9zm-48 196.1l24.8-95 24.8 95c2.2 8.4 8.8 15 17.2 17.2l95 24.8-95 24.8c-8.4 2.2-15 8.8-17.2 17.2l-24.8 95-24.8-95c-2.2-8.4-8.8-15-17.2-17.2l-95-24.8 95-24.8c8.4-2.2 15-8.8 17.2-17.2zM71 71c-9.4 9.4-9.4 24.6 0 33.9l48 48c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L105 71c-9.4-9.4-24.6-9.4-33.9 0zM441 71c-9.4-9.4-24.6-9.4-33.9 0l-48 48c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l48-48c9.4-9.4 9.4-24.6 0-33.9zm0 336l-48-48c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l48 48c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9zM71 441c9.4 9.4 24.6 9.4 33.9 0l48-48c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L71 407c-9.4 9.4-9.4 24.6 0 33.9z">
							</path>
						</svg> -->
                        <h3 class="m-0 ml-1">Chat</h3>
                    </div>
                    <p class="subscription-item">Chat effortlessly using cutting-edge models over custom data like PDF,
                        TXT, DOCX. Enjoy 100 free messages, 10MB storage; go Pro for 500 messages, 100MB size.
                    </p>
                </div>
                <div class="col-6 mb-2">
                    <div class="d-flex align-items-center">
                        <!-- <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="star-christmas"
							class="svg-inline--fa fa-star-christmas fa-fw fa-lg " role="img"
							xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
							<path fill="currentColor"
								d="M279.2 17.9C276.5 7.4 266.9 0 256 0s-20.5 7.4-23.2 17.9L188.3 188.3 17.9 232.8C7.4 235.5 0 245.1 0 256s7.4 20.5 17.9 23.2l170.4 44.4 44.4 170.4c2.8 10.6 12.3 17.9 23.2 17.9s20.5-7.4 23.2-17.9l44.4-170.4 170.4-44.4c10.6-2.8 17.9-12.3 17.9-23.2s-7.4-20.5-17.9-23.2L323.7 188.3 279.2 17.9zm-48 196.1l24.8-95 24.8 95c2.2 8.4 8.8 15 17.2 17.2l95 24.8-95 24.8c-8.4 2.2-15 8.8-17.2 17.2l-24.8 95-24.8-95c-2.2-8.4-8.8-15-17.2-17.2l-95-24.8 95-24.8c8.4-2.2 15-8.8 17.2-17.2zM71 71c-9.4 9.4-9.4 24.6 0 33.9l48 48c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L105 71c-9.4-9.4-24.6-9.4-33.9 0zM441 71c-9.4-9.4-24.6-9.4-33.9 0l-48 48c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l48-48c9.4-9.4 9.4-24.6 0-33.9zm0 336l-48-48c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l48 48c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9zM71 441c9.4 9.4 24.6 9.4 33.9 0l48-48c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L71 407c-9.4 9.4-9.4 24.6 0 33.9z">
							</path>
						</svg> -->
                        <h3 class="m-0 ml-1">PII Reports</h3>
                    </div>
                    <p class="subscription-item">Generate PII reports effortlessly. Free plan includes up to 5 reports;
                        go Pro for up to 20, ensuring robust tabular data security.
                    </p>
                </div>
                <div class="col-6 mb-2">
                    <div class="d-flex align-items-center">
                        <!-- <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="star-christmas"
							class="svg-inline--fa fa-star-christmas fa-fw fa-lg " role="img"
							xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
							<path fill="currentColor"
								d="M279.2 17.9C276.5 7.4 266.9 0 256 0s-20.5 7.4-23.2 17.9L188.3 188.3 17.9 232.8C7.4 235.5 0 245.1 0 256s7.4 20.5 17.9 23.2l170.4 44.4 44.4 170.4c2.8 10.6 12.3 17.9 23.2 17.9s20.5-7.4 23.2-17.9l44.4-170.4 170.4-44.4c10.6-2.8 17.9-12.3 17.9-23.2s-7.4-20.5-17.9-23.2L323.7 188.3 279.2 17.9zm-48 196.1l24.8-95 24.8 95c2.2 8.4 8.8 15 17.2 17.2l95 24.8-95 24.8c-8.4 2.2-15 8.8-17.2 17.2l-24.8 95-24.8-95c-2.2-8.4-8.8-15-17.2-17.2l-95-24.8 95-24.8c8.4-2.2 15-8.8 17.2-17.2zM71 71c-9.4 9.4-9.4 24.6 0 33.9l48 48c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L105 71c-9.4-9.4-24.6-9.4-33.9 0zM441 71c-9.4-9.4-24.6-9.4-33.9 0l-48 48c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l48-48c9.4-9.4 9.4-24.6 0-33.9zm0 336l-48-48c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l48 48c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9zM71 441c9.4 9.4 24.6 9.4 33.9 0l48-48c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L71 407c-9.4 9.4-9.4 24.6 0 33.9z">
							</path>
						</svg> -->
                        <h3 class="m-0 ml-1">Anomaly</h3>
                    </div>
                    <p class="subscription-item">Detect anomalies in your tables with cutting-edge ML magic. Free plan
                        supports max 250 rows; Pro plan supports upto 5000 rows.

                    </p>
                </div>
                <div class="col-6 mb-2">
                    <div class="d-flex align-items-center">
                        <!-- <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="star-christmas"
							class="svg-inline--fa fa-star-christmas fa-fw fa-lg " role="img"
							xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
							<path fill="currentColor"
								d="M279.2 17.9C276.5 7.4 266.9 0 256 0s-20.5 7.4-23.2 17.9L188.3 188.3 17.9 232.8C7.4 235.5 0 245.1 0 256s7.4 20.5 17.9 23.2l170.4 44.4 44.4 170.4c2.8 10.6 12.3 17.9 23.2 17.9s20.5-7.4 23.2-17.9l44.4-170.4 170.4-44.4c10.6-2.8 17.9-12.3 17.9-23.2s-7.4-20.5-17.9-23.2L323.7 188.3 279.2 17.9zm-48 196.1l24.8-95 24.8 95c2.2 8.4 8.8 15 17.2 17.2l95 24.8-95 24.8c-8.4 2.2-15 8.8-17.2 17.2l-24.8 95-24.8-95c-2.2-8.4-8.8-15-17.2-17.2l-95-24.8 95-24.8c8.4-2.2 15-8.8 17.2-17.2zM71 71c-9.4 9.4-9.4 24.6 0 33.9l48 48c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L105 71c-9.4-9.4-24.6-9.4-33.9 0zM441 71c-9.4-9.4-24.6-9.4-33.9 0l-48 48c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l48-48c9.4-9.4 9.4-24.6 0-33.9zm0 336l-48-48c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l48 48c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9zM71 441c9.4 9.4 24.6 9.4 33.9 0l48-48c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L71 407c-9.4 9.4-9.4 24.6 0 33.9z">
							</path>
						</svg> -->
                        <h3 class="m-0 ml-1">Users</h3>
                    </div>
                    <p class="subscription-item">Transform collaboration! Basic plan supports 2 users; Pro plan
                        amplifies teamwork with support for up to 10 users.
                    </p>
                </div>
            </div>

            <div class="row subscription-plans">
                <div class="col-6 mb-2">
                    <div class="subscription-cards">
                        <h3 class="head">Basic</h3>
                        <h4>$3</h4>
                        <p>BILLED PER MONTH</p>
                        <button class="btn btn-warning" (click)="proceedToCheckout('monthly')">Get started</button>
                    </div>
                </div>
                <div class="col-6 mb-2">
                    <div class="subscription-cards">
                        <h3 class="head d-flex">Pro</h3>
                        <h4>$30</h4>
                        <p>BILLED PER YEAR</p>
                        <button class="btn btn-warning" (click)="proceedToCheckout('yearly')">Get started</button>
                    </div>
                </div>
            </div>
        </div>
    </p-dialog>
</div>