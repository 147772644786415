import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrgService {

  private apiUrl = environment.apiAuthUrl;

  constructor(
    private http: HttpClient
  ) { }

  createOrg(body) {
    return this.http.post<any>(`${this.apiUrl}/org`, body)
  }

  getAllOrgs() {
    return this.http.get<any>(`${this.apiUrl}/org`)
  }

  getOrgById(id) {
    return this.http.get<any>(`${this.apiUrl}/org/${id}`)
  }

  updateOrgById(body, id) {
    return this.http.patch<any>(`${this.apiUrl}/org/${id}`, body)
  }

  token() {
    return this.http.get<any>(`${this.apiUrl}//generate-api-key`)
  }
}
