<form [formGroup]="ruleForm" aria-label="'Rule handler Form'">
    <div class="add-rules-form">
        <div class="row">
            <div class="col-lg-12 col-md-12 set-job">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Rule Name</label>
                            <input type="text" placeholder="Enter Rule Name" formControlName="ruleName"
                                class="form-control" [readonly]="jsonData!=null">
                        </div>
                    </div>
                    <!-- <div class="col-lg-6">
                        <button class="submit" (click)="showCreateRuleModal = true" title="ADD RULE"><i
                                class="feather ft-plus" aria-hidden="true"></i></button>
                    </div> -->
                </div>
                <div class="when-block">
                    <div class="title-box">
                        <span>When</span>
                        <a href="javascript:void(0)" class="a" (click)="showCreateRuleModal = true;">
                            <!-- <i class="feather ft-plus"></i> Add  -->
                            {{ 'ruleEngine.addnew' | translate }}
                        </a>
                    </div>
                    <div class="row mb-1">
                        <div class="col-lg-3">
                            <span>Selected Table</span>
                        </div>
                        <div class="col-lg-9">
                            <span>{{tableInfo?.tableName}}</span>
                        </div>
                    </div>
                    <ng-container formArrayName="rules">
                        <ng-container *ngTemplateOutlet="rulesTemplate;context:{rules: rules}">

                        </ng-container>
                    </ng-container>
                </div>
                <div class="then-block">
                    <div class="d-flex justify-content-between then-block-title">
                        <span>Then</span>
                        <a href="javascript:void(0)" class="a" (click)="showCreateActionModal = true"><i
                                class="feather ft-plus"></i></a>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 set-job">
                            <ng-container formArrayName="actions">
                                <ng-container *ngFor="let actionForm of actions.controls; index as ind">
                                    <div class="row mb-1 align-items-center" [formGroup]="actionForm">

                                        <div class="col-lg-5">
                                            <label>Set {{actionForm?.get('propName').value}}<span
                                                    class="mendatory">*</span></label>
                                            <input type="text" placeholder="Enter value" class="form-control"
                                                *ngIf="condition?.propType!='boolean'" formControlName="operandValue">
                                            <span class="p-fluid">
                                                <p-dropdown [filter]="true" styleClass="allDropdown"
                                                    *ngIf="condition?.propType=='boolean'"
                                                    [options]="[{name: 'true', value: 'true'}, {name: 'false', value: 'false'}]"
                                                    formControlName="operandValue"
                                                    [placeholder]="('inputforms.placeholder.selectProp' | translate) ? ('inputforms.placeholder.selectProp' | translate) : ' '"
                                                    optionLabel="name" optionValue="value">
                                                </p-dropdown>
                                            </span>
                                        </div>


                                        <!-- <div class="col-lg-3">Set {{actionForm?.get('propName').value}}</div>
                                        <div class="col-lg-4">
                                            <input type="text" placeholder="Enter value" class="form-control"
                                                *ngIf="condition?.propType!='boolean'" formControlName="operandValue">
                                            <p-dropdown [filter]="true" styleClass="allDropdown"
                                                *ngIf="condition?.propType=='boolean'"
                                                [options]="[{name: 'true', value: 'true'}, {name: 'false', value: 'false'}]"
                                                formControlName="operandValue"
                                                [placeholder]="('inputforms.placeholder.selectProp' | translate) ? ('inputforms.placeholder.selectProp' | translate) : ' '"
                                                optionLabel="name" optionValue="value">
                                            </p-dropdown>
                                        </div> -->
                                        <div class="col-lg-2">
                                            <a href="javascript:void(0)" class="a remove-action"
                                                (click)="removeAction(ind)" title="Remove Action"><i
                                                    class="fa fa-times"></i></a>
                                        </div>


                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="d-flex justify-content-end">
                    <div class="justify-content-end dynamic-form mt-2">
                        <button uiSref="address" type="button" class=" btn btn-test btn-small"
                            (click)="backToSelectRule()">
                            <span><i class="feather ft-chevron-left"></i></span>
                            {{ 'buttontitle.backbutton' | translate }}
                        </button>
                    </div>
                    <div class="justify-content-end dynamic-form mt-2">
                        <button uiSref="address" type="button" class=" btn btn-test btn-small" (click)="clearForm()">
                            {{ 'buttontitle.clearbutton' | translate }}
                        </button>
                    </div>
                    <div class="justify-content-end dynamic-form mt-2">
                        <button uiSref="address" type="button" class="btn btn-test btn-small"
                            [disabled]="ruleForm.invalid" (click)="validate()">
                            <i class="feather ft-zap"></i>
                            {{ 'buttontitle.validate' | translate }}
                        </button>
                    </div>
                    <!-- <div class="justify-content-end dynamic-form mt-2">
                        <button uiSref="address" type="button" class=" btn btn-raised btn-warning btn-small"
                        [disabled]="ruleForm.invalid"
                            (click)="validate()">
                            {{ 'buttontitle.validate' | translate }}
                        </button>
                    </div> -->

                    <div class="justify-content-end dynamic-form mt-2">
                        <button uiSref="address" type="button" class=" btn btn-raised btn-warning btn-small"
                            [disabled]="ruleForm.invalid || !isDRLValidated" (click)="saveRule()">
                            {{ 'buttontitle.savebutton' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

<p-dialog [(visible)]="showCreateRuleModal" [resizable]="false" [draggable]="false" [modal]="true" [blockScroll]="true"
    [style]="{width: '45vw'}" [styleClass]="'thirdtestpop'">
    <form [formGroup]="createRuleForm" (ngSubmit)="addRule()">
        <div *ngIf="showCreateRuleModal" class="test-popup width-70p mx-auto create-rule-model">
            <div class="p-dialog-titlebar">
                <button class="p-dialog-titlebar-icon p-dialog-titlebar-close" aria-label="Close Dialog"
                    (click)="close()">
                    <span class="pi pi-times"></span>
                </button>
            </div>
            <div *ngIf="showCreateRuleModal">
                <h2 class="col-12 label-control popup-title text-center" for="projectinput1">
                    {{tableInfo?.tableName}}
                </h2>
            </div>
            <div class="form-group">
                <label for="field-restriction">Add a restriction on a field</label>
                <!-- <p-radioButton id="field-restriction" formControlName="isSingleField">
                </p-radioButton> -->
                <p-dropdown [filter]="true" styleClass="allDropdown" [options]="propsList" formControlName="selectRule"
                    [placeholder]="('inputforms.placeholder.selectProp' | translate) ? ('inputforms.placeholder.selectProp' | translate) : ' '"
                    optionLabel="name" optionValue="value">
                </p-dropdown>
            </div>
            <div class="form-group">
                <span class="form-group my-2">
                    <label for="multifield-restriction">Multiple field constraint</label>
                    <!-- <p-radioButton id="multifield-restriction" formControlName="isMultipleField">
                    </p-radioButton> -->
                </span>
                <p-dropdown [filter]="true" styleClass="allDropdown"
                    [options]="[{name: 'All of (And)', value: 'AND'},{name: 'Any of (Or)', value: 'OR'}]"
                    formControlName="selectRule"
                    [placeholder]="('inputforms.placeholder.selectProp' | translate) ? ('inputforms.placeholder.selectProp' | translate) : ' '"
                    optionLabel="name" optionValue="value">
                </p-dropdown>
            </div>
            <div class="text-right">
                <button type="submit" class="btn btn-warning">Ok</button>
            </div>
        </div>
    </form>

</p-dialog>


<p-dialog [(visible)]="showCreateActionModal" [resizable]="false" [draggable]="false" [modal]="true"
    [blockScroll]="true" [style]="{width: '45vw'}" [styleClass]="'thirdtestpop'">
    <form [formGroup]="createActionForm" (ngSubmit)="addAction()">
        <div *ngIf="showCreateActionModal" class="test-popup width-70p mx-auto create-rule-model">
            <div class="p-dialog-titlebar">
                <button class="p-dialog-titlebar-icon p-dialog-titlebar-close" aria-label="Close Dialog"
                    (click)="close()">
                    <span class="pi pi-times"></span>
                </button>
            </div>
            <div *ngIf="showCreateActionModal">
                <h2 class="col-12 label-control popup-title text-center" for="projectinput1">
                    {{tableInfo?.tableName}}
                </h2>
            </div>
            <div class="form-group">
                <label>Choose a field to modify</label>
                <p-dropdown [filter]="true" styleClass="allDropdown" [options]="propsList"
                    formControlName="selectedActionField"
                    [placeholder]="('inputforms.placeholder.selectProp' | translate) ? ('inputforms.placeholder.selectProp' | translate) : ' '"
                    optionLabel="name" optionValue="value">
                </p-dropdown>
            </div>
            <div class="text-right">
                <button type="submit" class="btn btn-warning">Ok</button>
            </div>
        </div>
    </form>
</p-dialog>

<p-dialog [(visible)]="showVariableModal" [resizable]="false" [draggable]="false" [modal]="true" [blockScroll]="true"
    [style]="{width: '45vw'}" [styleClass]="'thirdtestpop'">
    <form [formGroup]="createVariableForm">
        <div *ngIf="showVariableModal" class="test-popup width-70p mx-auto create-rule-model">
            <div class="p-dialog-titlebar">
                <button class="p-dialog-titlebar-icon p-dialog-titlebar-close" aria-label="Close Dialog"
                    (click)="close()">
                    <span class="pi pi-times"></span>
                </button>
            </div>
            <div *ngIf="showVariableModal">
                <h2 class="col-12 label-control popup-title text-center" for="projectinput1">
                    {{tableInfo?.tableName}}
                </h2>
            </div>
            <div class="form-group">
                <label>Variable name</label>
                <input type="text" placeholder="Enter Variable name" formControlName="variableNameField"
                    class="form-control">
            </div>
            <div class="text-right">
                <button (click)="setVariable()" class="btn btn-warning">Set</button>
            </div>
        </div>
    </form>
</p-dialog>


<ng-template #rulesTemplate let-rules="rules">
    <ng-container *ngFor="let conditionForm of rules.controls; index as ind">
        <div class="row mb-1 align-items-center" [formGroup]="conditionForm"
            *ngIf="conditionForm?.get('groupType')?.value=='single'">
            <!-- <div class="col-lg-3">{{conditionForm?.get('propName').value}}</div> -->

            <div class="col-lg-5">
                <!-- <p-dropdown [filter]="true" styleClass="allDropdown" [options]="getOperators(conditionForm)"
                    formControlName="operator"
                    [placeholder]="('inputforms.placeholder.selectProp' | translate) ? ('inputforms.placeholder.selectProp' | translate) : ' '"
                    optionLabel="name" optionValue="value">
                </p-dropdown> -->
                <label>
                    {{ conditionForm?.get('propName').value }}
                    <span class="mendatory">*</span>
                </label>
                <span class="p-fluid">
                    <p-dropdown [filter]="true" styleClass="allDropdown" class="dropdown"
                        [options]="getOperators(conditionForm)"
                        [placeholder]="('inputforms.placeholder.selectProp' | translate) ? ('inputforms.placeholder.selectProp' | translate) : ' '"
                        formControlName="operator" optionLabel="name" optionValue="value">
                    </p-dropdown>
                </span>
            </div>

            <div class="col-lg-5" *ngIf="shouldEnableInput(conditionForm)">
                <label>Value<span class="mendatory">*</span></label>
                <input type="text" placeholder="Enter value" class="form-control"
                    *ngIf="shouldEnableInput(conditionForm)" formControlName="operandValue">
                <span class="p-fluid">
                    <p-dropdown [filter]="true" styleClass="allDropdown" *ngIf="condition?.propType=='boolean'"
                        [options]="[{name: 'true', value: 'true'}, {name: 'false', value: 'false'}]"
                        formControlName="operandValue"
                        [placeholder]="('inputforms.placeholder.selectProp' | translate) ? ('inputforms.placeholder.selectProp' | translate) : ' '"
                        optionLabel="name" optionValue="value">
                    </p-dropdown>
                </span>

            </div>
            <div class="col-lg-1">
                <a href="javascript:void(0)" class="a remove-action" (click)="removeRuleByCtrl(rules, ind)" title="Remove Rule"><i
                        class="fa fa-times"></i></a>
            </div>

            <div class="col-lg-12">

                <!-- <label>{{ conditionForm?.get('propName').value }}</label>
                <span class="p-fluid">
                    <p-dropdown [filter]="true" styleClass="allDropdown" class="dropdown"
                        [options]="getOperators(conditionForm)"
                        [placeholder]="('inputforms.placeholder.selectProp' | translate) ? ('inputforms.placeholder.selectProp' | translate) : ' '"
                        [showClear]="true" formControlName="operator" optionLabel="name" optionValue="value">
                    </p-dropdown>
                </span> -->
            </div>

        </div>
        <div *ngIf="conditionForm?.get('groupType')?.value=='AND'">
            All of the following
            <a href="javascript:void(0)" class="a"
                (click)="showCreateRuleModalNested = true; currentControl = conditionForm?.get('rules'); ">
                <!-- <i class="feather ft-plus"></i> Add New (+) -->
                {{ 'ruleEngine.addnew' | translate }}
            </a>
            <a href="javascript:void(0)" class="a" (click)="removeAndOrRule(conditionForm, ind);">
                <!-- <i class="feather ft-plus"></i> Add New (+) -->
                {{ 'ruleEngine.remove' | translate }}
            </a>
            <div style="padding-left: 20px;">
                <ng-container *ngTemplateOutlet="rulesTemplate;context:{rules: conditionForm?.get('rules')}">

                </ng-container>
            </div>

            <!-- <a href="javascript:void(0)" class="a"
                (click)="showCreateRuleModalNested = true; currentControl = conditionForm?.get('rules'); ">
                <i class="feather ft-plus"></i> Add Condition {{ind}}</a> -->
        </div>
        <div *ngIf="conditionForm?.get('groupType')?.value=='OR'">
            Any of the following
            <a href="javascript:void(0)" class="a"
                (click)="showCreateRuleModalNested = true; currentControl = conditionForm?.get('rules'); ">
                <!-- <i class="feather ft-plus"></i> Add New (+) -->
                {{ 'ruleEngine.addnew' | translate }}
            </a>
            <a href="javascript:void(0)" class="a" (click)="removeAndOrRule(conditionForm, ind); ">
                <!-- <i class="feather ft-plus"></i> Add New (+) -->
                {{ 'ruleEngine.remove' | translate }}
            </a>
            <div style="padding-left: 20px;">
                <ng-container *ngTemplateOutlet="rulesTemplate;context:{rules: conditionForm?.get('rules')}">

                </ng-container>
            </div>


        </div>

    </ng-container>

</ng-template>


<p-dialog [(visible)]="showCreateRuleModalNested" [resizable]="false" [draggable]="false" [modal]="true"
    [blockScroll]="true" [style]="{width: '45vw'}" [styleClass]="'thirdtestpop'">
    <form [formGroup]="createRuleForm" (ngSubmit)="addRuleByCntrl()">
        <div *ngIf="showCreateRuleModalNested" class="test-popup width-70p mx-auto create-rule-model">
            <div class="p-dialog-titlebar">
                <button class="p-dialog-titlebar-icon p-dialog-titlebar-close" aria-label="Close Dialog"
                    (click)="close()">
                    <span class="pi pi-times"></span>
                </button>
            </div>
            <div *ngIf="showCreateRuleModalNested">
                <h2 class="col-12 label-control popup-title text-center" for="projectinput1">
                    {{tableInfo?.tableName}}
                </h2>
            </div>
            <div class="form-group">
                <label for="field-restriction">Add a restriction on a field</label>
                <!-- <p-radioButton id="field-restriction" formControlName="isSingleField">
                </p-radioButton> -->
                <p-dropdown [filter]="true" styleClass="allDropdown" [options]="propsList" formControlName="selectRule"
                    [placeholder]="('inputforms.placeholder.selectProp' | translate) ? ('inputforms.placeholder.selectProp' | translate) : ' '"
                    optionLabel="name" optionValue="value">
                </p-dropdown>
            </div>
            <div class="form-group">
                <span class="form-group my-2">
                    <label for="multifield-restriction">Multiple field constraint</label>
                    <!-- <p-radioButton id="multifield-restriction" formControlName="isMultipleField">
                    </p-radioButton> -->
                </span>
                <p-dropdown [filter]="true" styleClass="allDropdown"
                    [options]="[{name: 'All of (And)', value: 'AND'},{name: 'Any of (Or)', value: 'OR'}]"
                    formControlName="selectRule"
                    [placeholder]="('inputforms.placeholder.selectProp' | translate) ? ('inputforms.placeholder.selectProp' | translate) : ' '"
                    optionLabel="name" optionValue="value">
                </p-dropdown>
            </div>
            <div class="text-right">
                <button type="submit" class="btn btn-warning">Ok</button>
            </div>
        </div>
    </form>

</p-dialog>