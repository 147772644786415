<div *ngIf="id">
    <div class="app-content content">
        <div class="content-wrapper">
            <div class="content-body">
                <section class="flexbox-container">
                    <div class="card-title text-center mb-3 mt-5">
                        <img src="../../../assets/images/logo/new-logo.svg" alt="Zero to Sixty logo" style="height: 60px;">
                    </div>
                    <div class="col-12 d-flex align-items-center justify-content-center">
                        <div class="col-md-4 login-box">
                            <div class="card login border-grey border-lighten-3   py-1 m-0">
                                <div class="card-content">
                                    <div class="card-body">
                                        <h1 class="log-head mb-2">{{ 'finalizeregistration.title2complete' |
                                            translate }}</h1>
                                        <p class="text-center" *ngIf="email != ''">
                                            {{('finalizeregistration.verifytext' | translate) + email }}
                                        </p>
                                        <form class="form-horizontal" [formGroup]="finalizeRegistrationForm"
                                            (ngSubmit)="tryRegistrationCode()"
                                            [ariaLabel]="'finalizeRegistrationForm form All required input feilds are denoted by star'">

                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label>
                                                            {{ 'inputforms.labels.registrationcode' | translate }}<span
                                                                class="mendatory">*</span></label>
                                                        <input type="text" formControlName="registrationCode"
                                                            class="form-control mw-100p" id="user-name"
                                                            aria-label="Please enter your Registration Code*"
                                                            [placeholder]="'inputforms.placeholder.registrationcode' | translate ">
                                                    </div>
                                                </div>
                                                <div class="col-md-12 text-right">
                                                    <button type="submit" class="btn btn-raised btn-warning">
                                                        {{
                                                        'buttontitle.continuebutton' | translate}}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body d-flex justify-content-center mt-3 existing-user">
                        <p>{{ 'register.accountexisttext' | translate }} <a [routerLink]="['/login']"
                                class=" underline">
                                {{ 'register.logintext' | translate }}</a></p>
                    </div>
                </section>

            </div>
        </div>
    </div>
</div>