import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  updateEvent = new EventEmitter<any>();
  userUpdatedDetails: any;
  public currentUserProfile: any;
  private apiUrl = environment.apiAuthUrl

  constructor(
    private http: HttpClient
  ) { }

  getUserProfile() {
    return this.http.get<any>(`${this.apiUrl}/users/me`).pipe(
      map((profile) => {
        this.currentUserProfile = profile;
        this.updateEvent.emit(this.currentUserProfile);
        return profile;
      }));
  }

  updateUserProfile(body, id) {
    return this.http.post<any>(`${this.apiUrl}/users/${id}/update-profile`, body)
  }

  getUserById(id) {
    if (id) {
      return this.http.get<any>(`${this.apiUrl}/get-user/${id}`)
    }
  }
}
