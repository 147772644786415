<div class="app-content content mt-5 find-org">
    <div class="content-wrapper">
        <div class="content-header row mb-1">
        </div>
        <div class="content-body">
            <section class="flexbox-container">
                <div class="col-12 d-flex align-items-center justify-content-center">
                    <div class="col-md-4 col-10">
                        <div class=" login-box  box-shadow-2  ">
                            <div class="card login border-grey border-lighten-3   py-1 m-0">
                                <div class="d-flex justify-content-end icon-cross">
                                    <i class="feather ft-x"></i>
                                </div>
                                <div class="card-content">
                                    <div class="card-body">
                                        <h1 class="log-head mb-4">{{ 'findorg.title2fidorg' | translate}}</h1>
                                    </div>
                                    <form class="form-horizontal" [formGroup]="findOrgForm"
                                        aria-label="'Find Org form All required input feilds are denoted by star'">
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <legend></legend>
                                            <span class="p-fluid">
                                                <label>{{ 'inputforms.labels.organizationname' | translate }}<span
                                                        class="mendatory">*</span></label>
                                                <p-dropdown [filter]="true" styleClass="allDropdown" filterBy="name"
                                                    [placeholder]="('inputforms.placeholder.organizationname' | translate) ? ('inputforms.placeholder.organizationname' | translate) : ' '"
                                                    [options]="orgList" optionLabel="name" optionValue="id"
                                                    formControlName="findOrg" (onChange)="changeToStepUpOrg()"
                                                    [ngClass]="{ 'is-invalid': (submitted || findOrgForm.get('findOrg').touched) && findOrgForm.get('findOrg').invalid  }"></p-dropdown>
                                                <div
                                                    *ngIf="findOrgForm.get('findOrg').invalid &&  findOrgForm.get('findOrg').touched">
                                                    <div class="invalid-feedback">{{ 'inputforms.formerrors.chooseorg' |
                                                        translate }}</div>
                                                </div>
                                            </span>
                                        </fieldset>

                                        <div class="card-body d-flex justify-content-center ">
                                            <button type="submit" class="btn btn-raised btn-warning btn-small cont-btn"
                                                (click)="joinOrg()">
                                                {{ 'buttontitle.joinbutton' | translate }}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-center mt-5"><span class="copyright ">{{ 'footer.copyright' | translate }} &copy; {{
                        'footer.brudatainc' | translate }}</span></div>
            </section>

        </div>
    </div>
</div>