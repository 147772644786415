import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  private NodeAPIUrl = environment.apiAuthUrl;

  constructor(
    private http: HttpClient
  ) { }

  univariateAccuracy(params) {
    return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
      method: 'GET',
      path: '/univariate_accuracy',
      params: params
    })
  }

  correlationMatricesReport(params) {
    return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
      method: 'GET',
      path: '/correlation_matrices_report',
      params: params
    })
  }

  // http://127.0.0.1:5000/v1/single_feature_distribution_report?org_id=O1&job_id=6&execution_id=26&feature1_name=name

  singleFeatureDistributionReport(params) {
    return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
      method: 'GET',
      path: '/feature_distribution_report',
      params: params
    })
  }

  async singleFeatureDistributionReportAsync(params) {
    return new Promise(resolve => {
      this.singleFeatureDistributionReport(params).subscribe({
        next: result => resolve(result),
        error: err => resolve(err)
      })
    })
  }

  // http://127.0.0.1:5000/v1/correlation_heatmap_report?org_id=O1&job_id=20&execution_id=49

  correlationHeatmapReport(params) {
    return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
      method: 'GET',
      path: '/correlation_heatmap_report',
      params: params
    })
  }

  async correlationHeatmapReportAsync(params) {
    return new Promise(resolve => {
      this.correlationHeatmapReport(params).subscribe({
        next: result => resolve(result),
        error: err => resolve(err)
      })
    })
  }

  // http://127.0.0.1:5000/v1/NNDR_report?org_id=O1&job_id=20&execution_id=49

  NNDRReport(params) {
    return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
      method: 'GET',
      path: '/DCR_NNDR_report',
      params: params
    })
  }

  async NNDRReportAsync(params) {
    return new Promise(resolve => {
      this.NNDRReport(params).subscribe({
        next: result => resolve(result),
        error: err => resolve(err)
      })
    })
  }

  // http://127.0.0.1:5000/v1/pair_plot_report?org_id=O1&job_id=20&execution_id=49

  pairPlotReport(params) {
    return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
      method: 'GET',
      path: '/pair_plot_report',
      params: params
    })
  }

  async pairPlotReportAsync(params) {
    return new Promise(resolve => {
      this.pairPlotReport(params).subscribe({
        next: result => resolve(result),
        error: err => resolve(err)
      })
    })
  }

  // http://127.0.0.1:5000/v1/accuracy_charts_report?job_id=4&execution_id=5&org_id=O1
  getAccuracyCharts(params) {
    return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
      method: 'GET',
      path: '/accuracy_charts_report',
      params: params
    })
  }

  async getAccuracyChartsAsync(params) {
    return new Promise(resolve => {
      this.getAccuracyCharts(params).subscribe({
        next: result => resolve(result),
        error: err => resolve(err)
      })
    })
  }

  // http://127.0.0.1:5000/v1/accuracy_charts_report_columns?job_id=4&execution_id=5&org_id=O1&column=sepalWidth
  getInviAccuracyCharts(params) {
    return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
      method: 'GET',
      path: '/accuracy_charts_report_columns',
      params: params
    })
  }

  async getInviAccuracyChartsAsync(params) {
    return new Promise(resolve => {
      this.getInviAccuracyCharts(params).subscribe({
        next: result => resolve(result),
        error: err => resolve(err)
      })
    })
  }
}
