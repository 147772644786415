<div class="d-flex">
    <div *ngFor="let member of members" ngbDropdown>
        <div class="team" pTooltip="{{ member?.user?.name }}" tooltipPosition="bottom" [tabindex]="0" ngbDropdown>
            <span class="pointer" ngbDropdownToggle>{{ getInitials(member?.user?.name) }}</span>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button ngbDropdownItem (click)="approve(member)">{{ 'buttontitle.approvebutton' | translate }}</button>
                <button ngbDropdownItem (click)="decline(member)">{{ 'buttontitle.declinebutton' | translate }}</button>
            </div>
        </div>
    </div>
</div>