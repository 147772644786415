<div [formGroup]="form" class="dynamic-form">

    <div class="form-group mb-3 row set-job">
        <div class="col-lg-4 col-sm-12">
            <label for="float-input" class=" label-control">{{ field['label'] }}</label>
        </div>
        <div class="col-lg-8 col-sm-12 d-flex">
            <span class="fixed-pattern">/</span>
            <input [ngClass]="'form-control'" [type]="field['type']" [formControlName]="field['name']"
                placeholder="{{ field['label'] }}">
            <span class="end-pattern">/</span>
        </div>
    </div>
</div>