<div class="analytix-box">
    <div class="d-flex justify-content-between align-items-center">
        <h4>Inter Company Reconciliation</h4>
        <i class="feather ft-message-square msg-icon"
            (click)="invokeChatSliding('cfo-chat')"></i>
    </div>
    

    <div class="loader-wrapper circular-spinners text-center"
        *ngIf="!bankDetails; else apDashboard ">
        <i class="pi pi-spin pi-spinner"></i>
    </div>

    <ng-template #apDashboard>
        <div *ngIf="bankChartDetails.length">
            <ng-container *ngFor="let chart of bankChartDetails; let index as i">
                <div class="chart-area-full mt-2">
                    <h5 >
                        {{chart['title']}}
                    </h5>
                    <p-table *ngIf="chart['data'].length" [value]="chart['data']">
                        <ng-template pTemplate="header">
                            <tr *ngIf="chart['title'] === 'Inter Company Reconciliation'">
                                <th>Company1_Balance</th>
                                <th>Company2_Balance</th>
                                <th>Discrepancy</th>
                            </tr>
                            <tr *ngIf="chart['title'] !== 'Inter Company Reconciliation'">
                                <th>Company Name</th>
                                <th>Invoice No</th>
                                <th>Product</th>
                                <th>Units Ordered</th>
                                <th>Price Per Unit</th>
                                <th>Remarks</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData>
                            <tr *ngIf="chart['title'] === 'Inter Company Reconciliation'">
                                <td>{{ rowData['Company1_Balance'] }}</td>
                                <td>{{ rowData['Company2_Balance'] }}</td>
                                <td>{{ rowData['Discrepancy'] }}</td>
                            </tr>
                            <tr *ngIf="chart['title'] !== 'Inter Company Reconciliation'">
                                <td>{{ rowData['Company_Name'] }}</td>
                                <td>{{ rowData['Invoice No'] }}</td>
                                <td>{{ rowData['Product'] }}</td>
                                <td>{{ rowData['Units Ordered'] }}</td>
                                <td>{{ rowData['Price Per Unit'] }}</td>
                                <td>{{ rowData['Remarks'] }}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <h5 *ngIf="chart['data'].length == 0">
                        {{chart['message']}}
                    </h5>
                </div>
            </ng-container>

        </div>

    </ng-template>
</div>