
<div class="confirm-synthesis-data">
    <div class="confirm-data-title d-flex justify-content-between mb-2">
        <h2>{{ 'ruletest.confirmdatatitle' | translate }}</h2><!-- Kiran -->
    
        <div class="pop-action d-flex align-items-center">
            <span>{{ 'filetest.span1text' | translate }}</span>
            <span class="before-after"><label class="toggle">
                    <input class="toggle-input" type="checkbox" checked [(ngModel)]="showBefore">
                    <div class="toggle-track">
                        <div class="toggle-control"></div>
                    </div>
                </label>
            </span>
            <span>{{ 'filetest.span2text' | translate }}</span>
        </div>
    </div>
</div>

<!-- Don't Delete This -->
<!-- <div class="page-main p-0">
    <div class="page-data p-0">
        <div class="project-table profile-table">
            <p-table [value]="messageData">
                <ng-template pTemplate="header">
                    <tr>
                        <th *ngFor="let key of getColumnNames()" scope="col">{{ key }}</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                    <tr class="rule-row">
                        <td *ngFor="let key of getColumnNames()">
                            {{ rowData[key] }}
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div> -->



<div class="test-table">
    <!-- Display 'before' data table -->
    <p-table [value]="dataBefore" *ngIf="!showBefore">
        <ng-template pTemplate="header">
            <tr>
                <th style="min-width: 300px;" *ngFor="let key of keysBefore" scope="col">{{ key }}</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
            <tr>
                <td style="min-width: 300px;" [ngStyle]="{ 'height': '100px' }" *ngFor="let key of keysBefore">
                    <div class="bigdata">{{ rowData[key] }}</div>
                </td>
            </tr>
        </ng-template>
    </p-table>

    <!-- Display 'after' data table -->
    <p-table [value]="dataAfter" *ngIf="showBefore">
        <ng-template pTemplate="header">
            <tr>
                <th style="min-width: 300px;" *ngFor="let key of keysAfter" scope="col">{{ key }}</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
            <tr>
                <td style="min-width: 300px;" [ngStyle]="{ 'height': '100px' }" *ngFor="let key of keysAfter">
                    <div class="bigdata">{{ rowData[key] }}</div>
                </td>
            </tr>
        </ng-template>
    </p-table>

</div>