import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../_services/auth.service';
import Swal from 'sweetalert2';
import { Toast } from 'src/app/_helpers/toast';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent implements OnInit {
  resetForm: FormGroup;
  submitted = false;
  loading = false;
  showResetForm = false;
  error = '';
  success = '';
  key;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.resetForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });

    this.route.queryParams.subscribe((params) => {
      this.key = params.key;
      this.verifyResetKey();
    });
  }

  get f() { return this.resetForm.controls; }

  submit() {
    this.success = '';
    this.submitted = true;
    if (this.resetForm.invalid) {
      return;
    }

  }

  verifyResetKey() {
    if (this.key) {
      this.authService.verifyResetKey(this.key).subscribe({
        next: res => {
          this.showResetForm = true;
          this.success = res?.success;
        }, error: err => {
          Toast.fire({
            icon: 'error',
            html: err.message
          });
        }
      })
    }
  }

  forgotPassword() {
    if (this.resetForm.invalid) {
      return;
    }

    let forgotData = this.resetForm.value;
    this.authService.forgotPasswordInit(forgotData).subscribe({
      next: res => {
        this.resetForm.reset();
        Swal.fire({
          title: 'Forgot Password',
          text: 'Mail is sent to registered Email Id'
        })
      }, error: err => {
        this.resetForm.reset();
        Swal.fire({
          title: 'Error',
          text: 'Error'
        })
      }
    })
  }

}
