// Default menu settings configurations

export interface MenuItem {
  title: string;
  icon: string;
  page: string;
  fragment?: string;
  isExternalLink?: boolean;
  issupportExternalLink?: boolean;
  badge: { type: string; value: string };
  submenu: {
    items: Partial<MenuItem>[];
  };
  section: string;
}

export interface MenuConfig {
  horizontal_menu: {
    items: Partial<MenuItem>[];
  };
  vertical_menu: {
    items: Partial<MenuItem>[];
  };
}

export const MenuSettingsConfig: MenuConfig = {
  horizontal_menu: {
    items: [
      {
        title: "Dashboard",
        icon: "la-file",
        page: "/dashboard",
        badge: { type: "badge-danger", value: "2.9" },
      },
      {
        title: "Test 1",
        icon: "la-television",
        page: "null",
        submenu: {
          items: [
            {
              title: "Horizontal",
              page: "null",
            },
            {
              title: "Vertical",
              page: "null",
            },
          ],
        },
      },
      {
        title: "Test 2",
        icon: "la-support",
        page: "#.",
        isExternalLink: true,
      },
      {
        title: "Test 3",
        icon: "la-text-height",
        page: "#.",
        isExternalLink: true,
      },
    ],
  },
  vertical_menu: {
    items: [
      /* {
        title: "Select Org",
        icon: "la-users",
        page: "null",
        submenu: {
          items: [
            {
              title: "Select Org",
              page: "",
            }
          ],
        },
      }, */
      {
        title: "Workspaces",
        icon: "las la-project-diagram",
        page: "/projects",
      },
      {
        title: "Synthesize Data",
        icon: "las la-cogs",
      },
      {
        title: "Deploy Rule Set",
        icon: "las la-stream",
      },
      {
        title: "Instant Analytics",
        icon: "las la-chart-bar",
        page: "/projects/p/workflow/analytix",
      },
      {
        title: "Train ML Model",
        icon: "las la-brain",
      },
      {
        title: "Configure LLM",
        icon: "las la-network-wired",
        page: "/llm"
      },
      {
        title: "Model Library",
        icon: "las la-sitemap",
        page: "/model-hub"
      },
      {
        title: "Agents Library",
        icon: "las la-sitemap",
        page: "/agent-library"
      },
      {
        title: "Train Chatbot",
        icon: "las la-robot",
        page: "/chatbot"
      },
      {
        title: "Solutions",
        icon: "las la-lightbulb",
        // icon: "las la-chart-bar",
        page: "/projects/p/workflow",
      },
      /* {
        title: "Check PII",
        icon: "las la-project-diagram",
        page: "/projects",
      },
      {
        title: "Synthesis Data",
        icon: "las la-project-diagram",
        page: "/projects",
      },
      {
        title: "Rules",
        icon: "las la-project-diagram",
        page: "/projects",
      },
      {
        title: "Anomaly",
        icon: "las la-project-diagram",
        page: "/projects",
      }, */
      // {
      //   title: "Support",
      //   icon: "la-headset",
      //   page: "/support",
      // },
      {
        title: "Settings",
        icon: "las la-cog"
      }
    ],
  },
};
