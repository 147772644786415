<div class="app-content ">
    <div class="content-wrapper">
        <div class="container-xxl mt-2">
            <div class="page-main project-job-list job-list-page pages">

                <p-tabView (onChange)="onTabChange($event)" [(activeIndex)]="index">
                    <!-- P & L Dashboard Overview -->
                    <p-tabPanel>
                        <ng-template pTemplate="header">
                            <span> Account Receivables Overview </span>
                            <i class="pi pi-refresh ml-1"></i>
                        </ng-template>

                        <div class="analytix-box">
                            <div class="d-flex justify-content-between align-items-center">
                                <h4>Account Receivables</h4>
                                <i class="feather ft-message-square msg-icon"
                                    (click)="invokeChatSliding('cfo-chat')"></i>
                            </div>
                            <div class="dashboard-info d-flex">
                                <div class="dashboard-filter">
                                    <label for="" class="label-control">SalesMan</label>
                                    <span class="p-fluid">
                                        <p-dropdown (onChange)="changeInFilter($event, 'salesman')"
                                            [(ngModel)]="salesman" styleClass="allDropdown" appendTo="body"
                                            filterBy="name" [placeholder]="'Select SalesMan'"
                                            [options]="salesmanOptions" optionLabel="name" optionValue="value">
                                        </p-dropdown>
                                    </span>
                                </div>

                                <div class="dashboard-filter">
                                    <label for="" class="label-control">Category</label>
                                    <span class="p-fluid">
                                        <p-dropdown (onChange)="changeInFilter($event, 'category')"
                                            [(ngModel)]="category" styleClass="allDropdown" appendTo="body"
                                            filterBy="name" [placeholder]="'Select Category'"
                                            [options]="categoryOptions" optionLabel="name" optionValue="value">
                                        </p-dropdown>
                                    </span>
                                </div>

                                <div class="dashboard-filter">
                                    <label for="" class="label-control">Customer</label>
                                    <span class="p-fluid">
                                        <p-dropdown (onChange)="changeInFilter($event, 'customer')"
                                            [(ngModel)]="customer" styleClass="allDropdown" appendTo="body"
                                            filterBy="name" [placeholder]="'Select Customer'"
                                            [options]="customerOptions" optionLabel="name" optionValue="value">
                                        </p-dropdown>
                                    </span>
                                </div>
                            </div>

                            <div class="loader-wrapper circular-spinners text-center"
                                *ngIf="!arDetails || !arDetails['cards']; else dashboard">
                                <i class="pi pi-spin pi-spinner"></i>
                            </div>

                            <ng-template #dashboard>
                                <div class="dashboard-info d-flex">
                                    <div class="dashboard-card"
                                        *ngIf="arDetails && arDetails['cards'] && arDetails['cards']['Total Sales']">
                                        <h5>Total Sales</h5>
                                        <p>{{arDetails['cards']['Total Sales'] | currency: jobDetails[0]['locale']+ ' '
                                            :'symbol':'1.0-0' }}</p>
                                    </div>
                                    <div class="dashboard-card"
                                        *ngIf="arDetails && arDetails['cards'] && arDetails['cards']['With in Due']">
                                        <h5>With in Due</h5>
                                        <p>{{arDetails['cards']['With in Due'] | currency: jobDetails[0]['locale']+ ' '
                                            :'symbol':'1.0-0' }}</p>
                                    </div>
                                    <div class="dashboard-card"
                                        *ngIf="arDetails && arDetails['cards'] && arDetails['cards']['Over Due']">
                                        <h5>Over Due</h5>
                                        <p>{{arDetails['cards']['Over Due'] | currency: jobDetails[0]['locale']+ ' '
                                            :'symbol':'1.0-0'}}</p>
                                    </div>
                                    <div class="dashboard-card"
                                        *ngIf="arDetails && arDetails['cards'] && arDetails['cards']['No of Customers']">
                                        <h5>No of Customers</h5>
                                        <p>{{arDetails['cards']['No of Customers'] }}</p>
                                    </div>
                                </div>

                                <div *ngIf="arChartDetails.length">

                                    <ng-container *ngFor="let chart of arChartDetails">
                                        <div
                                            [ngClass]="chart['preType'] === 'chart' && chart['title'] != 'Credit vs Balance Due Trend' ? 'chart-area mt-2' : 'chart-area-full  mt-2'">
                                            <div class="d-flex justify-content-between align-items-center mb-2">
                                                <h5 class="m-0" *ngIf="chart['preType'] == 'chart'">{{ chart['title'] }}
                                                </h5>
                                                <img class="pointer bulb-light" *ngIf="chart['preType'] != 'table'"
                                                    src="../../../../assets/images/lightbulb.png" width="32" height="32"
                                                    alt="" (click)="getInsights(chart)">
                                            </div>

                                            <span class="p-fluid" *ngIf="chart['preType'] == 'chart'">
                                                <p-chart [type]="chart && chart['type'] ? chart['type'] : 'line'"
                                                    [data]="chart['datasets']" [options]="chart['options']" />
                                            </span>



                                            <h5
                                                *ngIf="chart['preType'] == 'table' && chart['datasets'][0]['data'].length">
                                                {{
                                                chart['title']}}</h5>
                                            <p-table
                                                *ngIf="chart['preType'] == 'table' && chart['datasets'][0]['data'].length"
                                                [value]="chart['datasets'][0]['data']">
                                                <ng-template pTemplate="header">
                                                    <tr>
                                                        <th>SalesMan</th>
                                                        <th>Category</th>
                                                        <th>Customer</th>
                                                        <th>Credit Days</th>
                                                        <th>Credit Limit</th>
                                                        <th>0-30</th>
                                                        <th>31-60</th>
                                                        <th>61-90</th>
                                                        <th>91-120</th>
                                                        <th>121-180</th>
                                                        <th>181-360</th>
                                                        <th>>361</th>
                                                        <th>Credit Available Sales</th>
                                                        <th>Overdue</th>
                                                        <th>Payable</th>
                                                        <th>Within Due</th>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="body" let-rowData>
                                                    <tr>
                                                        <td>{{ rowData['SalesMan'] }}</td>
                                                        <td>{{ rowData['Category'] }}</td>
                                                        <td>{{ rowData['Customer'] }}</td>
                                                        <td>{{ rowData['CRD'] }}</td>
                                                        <td>{{ rowData['Credit Limit'] }}</td>
                                                        <td>{{ rowData['0-30'] }}</td>
                                                        <td>{{ rowData['31-60'] }}</td>
                                                        <td>{{ rowData['61-90'] }}</td>
                                                        <td>{{ rowData['91-120'] }}</td>
                                                        <td>{{ rowData['121-180'] }}</td>
                                                        <td>{{ rowData['181-360'] }}</td>
                                                        <td>{{ rowData['361'] }}</td>
                                                        <td>{{ rowData['Credit_available_sales'] }}</td>
                                                        <td>{{ rowData['Overdue'] }}</td>
                                                        <td>{{ rowData['Payable'] }}</td>
                                                        <td>{{ rowData['Within_due'] }}</td>
                                                    </tr>
                                                </ng-template>
                                            </p-table>
                                        </div>
                                    </ng-container>

                                </div>

                            </ng-template>
                        </div>
                    </p-tabPanel>


                    <p-tabPanel>
                        <ng-template pTemplate="header">
                            <span> Customer Overdue </span>
                            <i class="pi pi-refresh ml-1"></i>
                        </ng-template>

                        <div class="analytix-box">
                            <div class="d-flex justify-content-between align-items-center">
                                <h4>Customer Overdue</h4>
                                <i class="feather ft-message-square msg-icon"
                                    (click)="invokeChatSliding('cfo-chat')"></i>
                            </div>
                            <div class="dashboard-info d-flex">
                                <div class="dashboard-filter">
                                    <label for="" class="label-control">SalesMan</label>
                                    <span class="p-fluid">
                                        <p-dropdown (onChange)="changeInFilter($event, 'salesman')"
                                            [(ngModel)]="salesman" styleClass="allDropdown" appendTo="body"
                                            filterBy="name" [placeholder]="'Select SalesMan'"
                                            [options]="salesmanOptions" optionLabel="name" optionValue="value">
                                        </p-dropdown>
                                    </span>
                                </div>

                                <div class="dashboard-filter">
                                    <label for="" class="label-control">Category</label>
                                    <span class="p-fluid">
                                        <p-dropdown (onChange)="changeInFilter($event, 'category')"
                                            [(ngModel)]="category" styleClass="allDropdown" appendTo="body"
                                            filterBy="name" [placeholder]="'Select Category'"
                                            [options]="categoryOptions" optionLabel="name" optionValue="value">
                                        </p-dropdown>
                                    </span>
                                </div>

                               
                            </div>

                            <div class="loader-wrapper circular-spinners text-center"
                                *ngIf="!coDetails || !coDetails['cards']; else coDashboard">
                                <i class="pi pi-spin pi-spinner"></i>
                            </div>

                            <ng-template #coDashboard>
                                <div class="dashboard-info d-flex">
                                    <div class="dashboard-card"
                                        *ngIf="coDetails && coDetails['cards'] && coDetails['cards']['Total Sales']">
                                        <h5>Total Sales</h5>
                                        <p>{{coDetails['cards']['Total Sales'] | currency: jobDetails[0]['locale']+ ' '
                                            :'symbol':'1.0-0' }}</p>
                                    </div>
                                    <div class="dashboard-card"
                                        *ngIf="coDetails && coDetails['cards'] && coDetails['cards']['With in Due']">
                                        <h5>With in Due</h5>
                                        <p>{{coDetails['cards']['With in Due'] | currency: jobDetails[0]['locale']+ ' '
                                            :'symbol':'1.0-0' }}</p>
                                    </div>
                                    <div class="dashboard-card"
                                        *ngIf="coDetails && coDetails['cards'] && coDetails['cards']['Over Due']">
                                        <h5>Over Due</h5>
                                        <p>{{coDetails['cards']['Over Due'] | currency: jobDetails[0]['locale']+ ' '
                                            :'symbol':'1.0-0' }}</p>
                                    </div>
                                    <div class="dashboard-card"
                                        *ngIf="coDetails && coDetails['cards'] && coDetails['cards']['No of Customers']">
                                        <h5>No of Customers</h5>
                                        <p>{{coDetails['cards']['No of Customers'] }}</p>
                                    </div>
                                </div>

                                <div *ngIf="coChartDetails.length">
                                    <ng-container *ngFor="let chart of coChartDetails">
                                        <div
                                            [ngClass]="chart['preType'] === 'chart' ? 'chart-area-full chart mt-2' : 'chart-area-full mt-2'">
                                            <div class="d-flex justify-content-between align-items-center mb-2">
                                                <h5 class="m-0" *ngIf="chart['preType'] == 'chart'">{{ chart['title'] }}
                                                </h5>
                                                <img class="pointer bulb-light" *ngIf="chart['preType'] != 'table'"
                                                    src="../../../../assets/images/lightbulb.png" width="32" height="32"
                                                    alt="" (click)="getInsights(chart)">
                                            </div>

                                            <span class="p-fluid" *ngIf="chart['preType'] == 'chart'">
                                                <p-chart [type]="chart && chart['type'] ? chart['type'] : 'line'"
                                                    [data]="chart['datasets']" [options]="chart['options']" />
                                            </span>



                                            <h5
                                                *ngIf="chart['preType'] == 'table' && chart['datasets'][0]['data'].length">
                                                {{
                                                chart['title']}}</h5>
                                            <p-table
                                                *ngIf="chart['preType'] == 'table' && chart['datasets'][0]['data'].length"
                                                [value]="chart['datasets'][0]['data']">
                                                <ng-template pTemplate="header">
                                                    <tr>
                                                        <th>SalesMan</th>
                                                        <th>Category</th>
                                                        <th>Customer</th>
                                                        <th>Credit Days</th>
                                                        <th>Credit Limit</th>
                                                        <th>Overdue</th>
                                                        <th>Total</th>
                                                        <th>Within Due</th>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="body" let-rowData>
                                                    <tr>
                                                        <td>{{ rowData['SalesMan'] }}</td>
                                                        <td>{{ rowData['Category'] }}</td>
                                                        <td>{{ rowData['Customer'] }}</td>
                                                        <td>{{ rowData['CRD'] }}</td>
                                                        <td>{{ rowData['Credit Limit'] }}</td>
                                                        <td>{{ rowData['Overdue'] }}</td>
                                                        <td>{{ rowData['Total'] }}</td>
                                                        <td>{{ rowData['Within_due'] }}</td>
                                                    </tr>
                                                </ng-template>
                                            </p-table>
                                        </div>
                                    </ng-container>

                                </div>

                            </ng-template>
                        </div>
                    </p-tabPanel>


                    <p-tabPanel>
                        <ng-template pTemplate="header">
                            <span> Sales Credit Utilization </span>
                            <i class="pi pi-refresh ml-1"></i>
                        </ng-template>

                        <div class="analytix-box">
                            <div class="d-flex justify-content-between align-items-center">
                                <h4>Sales Credit Utilization</h4>
                                <i class="feather ft-message-square msg-icon"
                                    (click)="invokeChatSliding('cfo-chat')"></i>
                            </div>
                            <div class="dashboard-info d-flex">
                                <div class="dashboard-filter">
                                    <label for="" class="label-control">SalesMan</label>
                                    <span class="p-fluid">
                                        <p-dropdown (onChange)="changeInFilter($event, 'salesman')"
                                            [(ngModel)]="salesman" styleClass="allDropdown" appendTo="body"
                                            filterBy="name" [placeholder]="'Select SalesMan'"
                                            [options]="salesmanOptions" optionLabel="name" optionValue="value">
                                        </p-dropdown>
                                    </span>
                                </div>
                               
                            </div>

                            <div class="loader-wrapper circular-spinners text-center"
                                *ngIf="!scuDetails || !scuDetails['cards']; else scuDashboard">
                                <i class="pi pi-spin pi-spinner"></i>
                            </div>

                            <ng-template #scuDashboard>
                                <div class="dashboard-info d-flex">
                                    <div class="dashboard-card"
                                        *ngIf="scuDetails && scuDetails['cards'] && scuDetails['cards']['No of Customers']">
                                        <h5>No of Customers</h5>
                                        <p>{{scuDetails['cards']['No of Customers'] }}</p>
                                    </div>
                                    <div class="dashboard-card"
                                        *ngIf="scuDetails && scuDetails['cards'] && scuDetails['cards']['Credit Available Sales']">
                                        <h5>Credit Available Sales</h5>
                                        <p>{{scuDetails['cards']['Credit Available Sales'] | currency: jobDetails[0]['locale']+ ' '
                                            :'symbol':'1.0-0' }}</p>
                                    </div>
                                    <div class="dashboard-card"
                                        *ngIf="scuDetails && scuDetails['cards'] && scuDetails['cards']['Credit Limit']">
                                        <h5>Credit Limit</h5>
                                        <p>{{scuDetails['cards']['Credit Limit']| currency: jobDetails[0]['locale']+ ' '
                                            :'symbol':'1.0-0' }}</p>
                                    </div>
                                    <div class="dashboard-card"
                                        *ngIf="scuDetails && scuDetails['cards'] && scuDetails['cards']['Total Sales']">
                                        <h5>Total Sales</h5>
                                        <p>{{scuDetails['cards']['Total Sales']| currency: jobDetails[0]['locale']+ ' '
                                            :'symbol':'1.0-0' }}</p>
                                    </div>
                                </div>

                                <div *ngIf="scuChartDetails.length">
                                    <ng-container *ngFor="let chart of scuChartDetails">
                                        <div
                                            [ngClass]="chart['preType'] === 'chart'  ? chart['title'] != 'Credit Availability' ? 'chart-area-full mt-2' : 'chart-area-full chart mt-2 ' : 'chart-area-full mt-2'">
                                            <div class="d-flex justify-content-between align-items-center mb-2">
                                                <h5 class="m-0" *ngIf="chart['preType'] == 'chart'">{{ chart['title'] }}
                                                </h5>
                                                <img class="pointer bulb-light" *ngIf="chart['preType'] != 'table'"
                                                    src="../../../../assets/images/lightbulb.png" width="32" height="32"
                                                    alt="" (click)="getInsights(chart)">
                                            </div>

                                            <span class="p-fluid" *ngIf="chart['preType'] == 'chart'">
                                                <p-chart [type]="chart && chart['type'] ? chart['type'] : 'line'"
                                                    [data]="chart['datasets']" [options]="chart['options']" />
                                            </span>



                                            <h5
                                                *ngIf="chart['preType'] == 'table' && chart['datasets'][0]['data'].length">
                                                {{
                                                chart['title']}}</h5>
                                            <p-table
                                                *ngIf="chart['preType'] == 'table' && chart['datasets'][0]['data'].length"
                                                [value]="chart['datasets'][0]['data']">
                                                <ng-template pTemplate="header">
                                                    <tr>
                                                        <th>SalesMan</th>
                                                        <th>Category</th>
                                                        <th>Customer</th>
                                                       
                                                        <th>Credit Limit</th>
                                                     
                                                        <th>Total</th>
                                                       
                                                        <th>Credit Available Sales</th>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="body" let-rowData>
                                                    <tr>
                                                        <td>{{ rowData['SalesMan'] }}</td>
                                                        <td>{{ rowData['Category'] }}</td>
                                                        <td>{{ rowData['Customer'] }}</td>
                                                       
                                                        <td>{{ rowData['Credit Limit'] }}</td>
                                                    
                                                        <td>{{ rowData['Total'] }}</td>
                                                        <td>{{ rowData['Credit_available_sales'] }}</td>
                                                    </tr>
                                                </ng-template>
                                            </p-table>
                                        </div>
                                    </ng-container>

                                </div>

                            </ng-template>
                        </div>
                    </p-tabPanel>

                </p-tabView>
            </div>
        </div>
    </div>

    <p-dialog [(visible)]="displayInsights" [resizable]="false" [draggable]="false" [modal]="true" [blockScroll]="true"
        [style]="{width: '55vw'}" appendTo="body">
        <div class="p-dialog-titlebar">
            <button class="p-dialog-titlebar-icon p-dialog-titlebar-close" autofocus="true" aria-label="Close Dialog"
                (click)="displayInsights=false">
                <span class="pi pi-times"></span>
            </button>
        </div>
        <div class="row subscription-plans info-cards">
            <div class="col-12 mb-2">
                <div class="d-flex justify-content-center align-items-center">
                    <h3 class="m-0 ml-1"> {{'Insights Based on ' + insightsTitle }}</h3>
                </div>
            </div>
        </div>

        <div *ngIf="loading" class="d-flex justify-content-center mt-5">
            <i class="pi pi-spin pi-spinner" style="font-size: 1.5rem"></i>
        </div>

        <ul *ngIf="!loading" style="list-style: none;">
            <li class="mb-1" *ngFor="let ins of insights"> {{ins}} </li>
        </ul>
    </p-dialog>