import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewContainerRef, inject } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RuleService } from 'src/app/_services/rule.service';
import { SyntheticDataService } from 'src/app/_services/synthetic-data.service';
import { debounceTime } from 'rxjs/operators';
import { dataTpes } from 'src/app/_models/rule-engine-dataTypes.model';
import { Toast } from 'src/app/_helpers/toast';
import { AuthService } from 'src/app/_services/auth.service';
import { WizardComponent } from 'angular-archwizard';
import Swal from 'sweetalert2';
import { AddJobComponent } from '../add-job/add-job.component';


@Component({
    selector: 'app-rule-editor',
    templateUrl: './rule-editor.component.html',
})
export class RuleEditorComponent implements OnInit, OnChanges {
    @Input() jobDetails: Object = {};
    @Input() tableInfo;
    ruleForm: FormGroup;
    createRuleForm: FormGroup;
    createActionForm: FormGroup;
    createVariableForm: FormGroup;
    droppedItems: string;
    selectRule: string;
    @Input() projectId: string;
    @Input() jsonData;
    @Input() fileJob: Object = {};
    selectedOperator;
    operatorsList = [
        { name: 'equal to', value: '==', applicableTo: ["String", "Boolean", "Integer", "Double"], enableInput: true },
        { name: 'not equal to', value: '!=', applicableTo: ["String", "Boolean", "Integer", "Double"], enableInput: true },
        { name: 'less than', value: '<', applicableTo: ["Integer", "Double"], enableInput: true },
        { name: 'greater than', value: '>', applicableTo: ["Integer", "Double"], enableInput: true },
        { name: 'less than or equal to', value: '<=', applicableTo: ["Integer", "Double"], enableInput: true },
        { name: 'greater than or equal to', value: '>=', applicableTo: ["Integer", "Double"], enableInput: true },
        { name: 'is null', value: '== null', applicableTo: ["String", "Boolean", "Integer", "Double"], enableInput: false },
        { name: 'is not null', value: '!= null', applicableTo: ["String", "Boolean", "Integer", "Double"], enableInput: false },
        { name: 'starts with', value: '.startsWith', applicableTo: ["String"], enableInput: true },
        { name: 'ends with', value: '.endsWith', applicableTo: ["String"], enableInput: true }
    ];
    baseConditionsList: Object[] = [
        { name: 'The following does not exist ...' },
        { name: 'The following does not exist ...' },
        { name: 'The following exists ...' },
        { name: 'Any of the following are true ...' },
    ];
    baseActionsList: Object[] = [

    ]
    ruleAttributes: Object[] = [];
    showRuleTest: boolean = false;
    testData;
    @Output() ruleEmitter = new EventEmitter();
    @Output() closeRuleEditor: EventEmitter<boolean> = new EventEmitter<boolean>();
    showCreateRuleModal: boolean = false;
    showCreateActionModal: boolean = false;
    propsList = [];
    whenClauseList = [];
    thenClauseList = [];
    selectedModels = [];
    drlString: string;
    selectedField;
    variableName: string;
    showVariableModal: boolean = false;
    isDRLValidated: boolean = false;
    @Input() editRuleEvent: boolean = false;
    @ViewChild(WizardComponent)
    public wizard: WizardComponent;
    _parent: AddJobComponent;
    ruleIndex = -1;
    currentControl;
    showCreateRuleModalNested: boolean = false;
    public formSchema: Object[] = [
        {
            type: "text",
            name: "ruleName",
            value: null,
            label: "Rule Name",
            class: 'list',
            validations: {
                required: true,
                maxLength: 30,
                minLength: 3
            }
        },
        {
            type: 'btn',
            name: [
                { name: 'ADD RULE' }
            ]
        },
    ];


    constructor(
        private ruleService: RuleService,
        private syntheticDataService: SyntheticDataService,
        private authService: AuthService,
        private fb: FormBuilder,
        private viewContainerRef: ViewContainerRef,
    ) {

        this.ruleForm = new FormGroup({
            ruleName: new FormControl(null, Validators.required),
            operator: new FormControl(),
            operandValue: new FormControl(),
            rules: this.fb.array([], Validators.required),
            actions: this.fb.array([], Validators.required)
        });
        if (this.jsonData && this.editRuleEvent) {
            this.ruleForm.patchValue(JSON.parse(this.jsonData.jsondata));
        }
        this.ruleForm.valueChanges.pipe(debounceTime(2000))
            .subscribe(data => {
                this.updateDRL(data);
            });
        const _injector = this.viewContainerRef.injector;
        const _parent: AddJobComponent = _injector.get<AddJobComponent>(AddJobComponent);
        this._parent = _parent;
    }

    async ngOnInit() {
        if (this.jsonData && this.editRuleEvent) {
            let rules = JSON.parse(this.jsonData.jsondata).rules;
            this.ruleForm.patchValue(JSON.parse(this.jsonData.jsondata));
            for (let rule in rules) {
                let ruleForm;

                if (rules[rule] && rules[rule]['groupType'] == "single") {
                    ruleForm = this.fb.group({
                        groupType: new FormControl('single'),
                        propName: new FormControl(rules[rule]['propName'], Validators.required),
                        propType: new FormControl(rules[rule]['propType'], Validators.required),
                        operator: new FormControl(rules[rule]['operator'], Validators.required),
                        operandValue: new FormControl(rules[rule]['operandValue'])
                    });
                } else {
                    ruleForm = this.getFormByRuleNested(rules[rule]);
                }

                (this.ruleForm.get('rules') as FormArray).push(ruleForm);
            }

            let actions = JSON.parse(this.jsonData.jsondata).actions;
            for (let action in actions) {

                let actionForm = this.fb.group({
                    propName: new FormControl(actions[action]['propName'], Validators.required),
                    propType: new FormControl(actions[action]['propType'], Validators.required),
                    operator: new FormControl(actions[action]['operator']),
                    operandValue: new FormControl(actions[action]['operandValue'])
                });
                (this.ruleForm.get('actions') as FormArray).push(actionForm);
            }

        } else {
            this.jsonData = null;
        }
        if (this.tableInfo) {
            this.propsList = [];
            await this.getTableMetadata();
        };
        this.createRuleForm = new FormGroup({
            selectRule: new FormControl(null),
            isSingleField: new FormControl(null),
            isMultipleField: new FormControl(null)
        });
        this.createActionForm = new FormGroup({
            selectedActionField: new FormControl(null),
        });
        this.createVariableForm = new FormGroup({
            variableNameField: new FormControl(null),
        });

    }

    getFormByRuleNested(rule) {
        let ruleForm;

        if (rule && rule['groupType'] == "single") {
            ruleForm = this.fb.group({
                groupType: new FormControl('single'),
                propName: new FormControl(rule['propName'], Validators.required),
                propType: new FormControl(rule['propType'], Validators.required),
                operator: new FormControl(rule['operator'], Validators.required),
                operandValue: new FormControl(rule['operandValue'])
            });

        } else if (rule && rule['groupType'] == "AND") {

            const andForm = this.fb.group({
                groupType: new FormControl('AND'),
                rules: this.fb.array([], Validators.required)
            });

            if (rule && rule.rules && rule.rules.length > 0) {
                rule.rules.forEach(iRule => {
                    (andForm.get('rules') as FormArray).push(this.getFormByRuleNested(iRule));
                });
            }
            ruleForm = andForm;

        } else if (rule && rule['groupType'] == "OR") {
            const orForm = this.fb.group({
                groupType: new FormControl('OR'),
                rules: this.fb.array([], Validators.required)
            });

            if (rule && rule.rules && rule.rules.length > 0) {
                rule.rules.forEach(iRule => {
                    (orForm.get('rules') as FormArray).push(this.getFormByRuleNested(iRule));
                });
            }

            ruleForm = orForm;
        }

        return ruleForm;
    }

    clearForm() {
        let ruleName = this.ruleForm.get('ruleName').value;
        this.ruleForm.reset();
        this.rules.clear();
        this.actions.clear();
        this.ruleForm.get('ruleName').patchValue(ruleName);
    }

    close() {
        this.showRuleTest = false
        this.showCreateRuleModal = false;
        this.showCreateActionModal = false;
        this.showVariableModal = false;
        this.showCreateRuleModalNested = false;
    }

    sendBooleanValue() {
        this.closeRuleEditor.emit(true);
    }

    addRule() {

        const selectedRuleControl = this.createRuleForm.get('selectRule');
        if (selectedRuleControl && selectedRuleControl.value && selectedRuleControl.value == "AND") {
            const andForm = this.fb.group({
                groupType: new FormControl('AND'),
                rules: this.fb.array([], Validators.required)
            });

            (this.ruleForm.get('rules') as FormArray).push(andForm);

        } else if (selectedRuleControl && selectedRuleControl.value && selectedRuleControl.value == "OR") {
            const orForm = this.fb.group({
                groupType: new FormControl('OR'),
                rules: this.fb.array([], Validators.required)
            });

            (this.ruleForm.get('rules') as FormArray).push(orForm);

        } else if (selectedRuleControl && selectedRuleControl.value) {
            const selectedRule = selectedRuleControl.value;
            const type = this.propsList.find((prop) => prop.name == selectedRule).type;

            const ruleForm = this.fb.group({
                groupType: new FormControl('single'),
                propName: new FormControl(selectedRule, Validators.required),
                propType: new FormControl(type, Validators.required),
                operator: new FormControl(null, Validators.required),
                operandValue: new FormControl(null)
            });



            (this.ruleForm.get('rules') as FormArray).push(ruleForm);
            this.whenClauseList.push({ propName: selectedRule, propType: type });
        }
        selectedRuleControl.reset(); // Reset the value in the form control

        this.close();
    }



    removeRule(ruleIndex) {
        (this.ruleForm.get('rules') as FormArray).removeAt(ruleIndex);
    }

    removeAction(ruleIndex) {
        (this.ruleForm.get('actions') as FormArray).removeAt(ruleIndex);
    }

    get rules() {
        return this.ruleForm.controls['rules'] as FormArray;
    }

    get actions() {
        return this.ruleForm.controls['actions'] as FormArray;
    }

    updateDRL(data) {
        this.isDRLValidated = false;
        if (this.ruleForm.valid && this.propsList.length > 0) {
            this.generateDRL();
        }

    }

    addAction() {
        const selectedActionField = this.createActionForm.get('selectedActionField');
        if (selectedActionField && selectedActionField.value) {
            const selectedField = selectedActionField.value;
            let type = this.propsList.find((prop) => prop.name == selectedField).type;
            let actionForm = this.fb.group({
                propName: new FormControl(selectedField, Validators.required),
                propType: new FormControl(type, Validators.required),
                operator: new FormControl(null),
                operandValue: new FormControl(null)
            });


            (this.ruleForm.get('actions') as FormArray).push(actionForm);
            this.thenClauseList.push(actionForm);

            this.createActionForm.get('selectedActionField').patchValue(null);
            this.selectedField = null;
            this.close();
        }
    }

    shouldEnableInput(conditionForm) {
        let selectedPropType = conditionForm.get('operator').value;
        let operator = this.operatorsList.find((operator) => operator.value == selectedPropType);
        return operator && operator.enableInput;
    }

    getOperators(conditionForm) {
        return this.operatorsList.filter((oper) => oper.applicableTo.indexOf(conditionForm?.get('propType').value) != -1);
    }


    generateDRL() {
        let drlString = "";

        if (this.fileJob && this.fileJob['job_id']) {
            this.tableInfo = this.tableInfo == null ? {} : this.tableInfo;
            if (this.jobDetails && this.jobDetails['file'] && this.jobDetails['file'][0]) {
                let file: File = this.jobDetails['file'][0];
                this.tableInfo['variable'] = file.name.toString()['replaceAll'](' ', '').substring(0, 3);
                this.tableInfo['tableName'] = 'File';
            }

            if (this.jobDetails && this.jobDetails['xmlFile'] && this.jobDetails['xmlFile'][0]) {
                let file: File = this.jobDetails['xmlFile'][0];
                this.tableInfo['variable'] = file.name.toString()['replaceAll'](' ', '').substring(0, 3);
                this.tableInfo['tableName'] = 'File';
            }


        }

        if (this.tableInfo && !this.tableInfo['variable']) {
            this.tableInfo['variable'] = this.tableInfo.tableName.replaceAll(' ', '').substring(0, 3);
        }
        drlString = "package com.zt060;\n" +
            "" + this.updateImports() + "\n" +
            "declare " + this.tableInfo.tableName + " \n" +
            "\n" + (this.generateDataTypes()) +
            "\nend\n" +
            "\nrule \"" + this.ruleForm.get('ruleName').value + "\"\n" +
            "when \n" +
            "" + this.tableInfo['variable'] + ": " + this.tableInfo.tableName + `(${this.generateWhenClause()})` +
            `\n
        then
                ${this.generateThenClause()}
        end`;
        console.log(drlString);
        return drlString;
    }

    generateDataTypes() {
        if (this.propsList.length == 0) {
            this.getTableMetadata();
        }
        let types = this.propsList.map((prop) => {
            return prop.name + ' : ' + prop.type
        }).join('\n\t\t');
        return types;
    }

    generateWhenClause() {
        let whenClause = "";
        let conditions = this.rules.value;

        whenClause = conditions.map((condition) => {
            if (condition.groupType == "single") {
                if (condition.operator && condition.operandValue) {
                    if (condition.propType == "String") {
                        let cond = "";
                        switch (condition.operator) {
                            case ".startsWith":
                            case ".endsWith":
                                cond = condition.propName + condition.operator + "(\"" + condition.operandValue + "\")";
                                break;
                            default:
                                cond = `${condition.propName}${condition.operator} "${condition.operandValue}"`;
                        }
                        return cond;

                    }
                    else
                        return `${condition.propName} ${condition.operator} ${condition.operandValue}`;
                } else {
                    return `${condition.propName} ${condition.operator}`;
                }
            } else {
                return this.generateWhenClauseNested(condition);
            }


        }).join(', ');

        return whenClause;
    }

    generateWhenClauseNested(row) {
        let whenClause = "";
        let conditions: any[] = row.rules;

        let whenClauseNested = [];

        if (conditions) {
            whenClauseNested = conditions.map((condition) => {
                if (condition.groupType == "single") {
                    if (condition.operator && condition.operandValue) {
                        if (condition.propType == "String") {
                            let cond = "";
                            switch (condition.operator) {
                                case ".startsWith":
                                case ".endsWith":
                                    cond = condition.propName + condition.operator + "(\"" + condition.operandValue + "\")";
                                    break;
                                default:
                                    cond = `${condition.propName}${condition.operator} "${condition.operandValue}"`;
                            }
                            return cond;

                        }
                        else
                            return `${condition.propName} ${condition.operator} ${condition.operandValue}`;
                    } else {
                        return `${condition.propName} ${condition.operator}`;
                    }
                } else {
                    return this.generateWhenClauseNested(condition);
                }


            });
        } else {
            whenClauseNested = [`${row.propName} ${row.operator} ${row.operandValue}`];
        }

        if (row.groupType == "AND") {
            whenClause = whenClauseNested.join(" && ");
        } else if (row.groupType == "OR") {
            whenClause = whenClauseNested.join(" || ");
        } else {
            whenClause = whenClauseNested.join(", ");
        }

        return "( " + whenClause + " )";
    }

    generateThenClause() {
        let thenClause = "";
        let actions = this.actions.value;

        thenClause = actions.map((action) => {
            let tClause = "";
            if (action.operandValue != null)
                if (action.propType == "String") {
                    tClause = `${this.tableInfo['variable']}.set${this.capitalizeFirstLetter(action.propName)}("${action.operandValue}");`;
                } else if (action.propType == "Double") {
                    tClause = `${this.tableInfo['variable']}.set${this.capitalizeFirstLetter(action.propName)}(${action.operandValue}d);`;
                } else {
                    tClause = `${this.tableInfo['variable']}.set${this.capitalizeFirstLetter(action.propName)}(${action.operandValue});`;
                }
            return tClause;
        }).join('\n');

        return thenClause;
    }



    capitalizeFirstLetter(str: string): string {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    async getTableMetadata() {
        let params = {
            connection_id: this.jobDetails["selectSource"]["id"],
            schema_name: this.jobDetails['selectSchema'],
            table_name: this.tableInfo.tableName
        }
        this.syntheticDataService.getColumnTypes(params).subscribe({
            next: (res) => {
                res.forEach((prop) => {
                    this.propsList.push({ name: prop.column_name, type: dataTpes[prop.type], value: prop.column_name });
                });

            },
            error: (err) => {
                Toast.fire({
                    icon: 'error',
                    html: err
                });
            }
        });

    }

    setVariable() {
        const vName = this.createVariableForm.get('variableNameField').value;
        this.showVariableModal = false;
        this.tableInfo['variable'] = vName;

    }


    updateImports() {
        let imports = "";
        let dateFound = this.propsList.find((prop) => prop.type == 'Date');
        if (dateFound) {
            imports += "import java.util.Date;"
        }

        return imports;
    }

    validate() {

        this.ruleService.validateDRL(this.generateDRL()).subscribe({
            next: (res) => {
                this.isDRLValidated = true;
                Toast.fire({
                    icon: 'success',
                    html: res
                });
            },
            error: (err) => {
                Toast.fire({
                    icon: 'error',
                    html: err
                });
            }
        });

    }

    saveRule() {
        const currentUser = this.authService.currentUserValue
        const formData = new FormData();
        formData.append('org_id', currentUser.orgId);
        formData.append('user_id', currentUser.id.toString());
        formData.append('ruleName', this.ruleForm.get('ruleName').value);
        formData.append('project_id', this.projectId);
        formData.append('tableName', this.tableInfo && this.tableInfo['tableName'] ? this.tableInfo['tableName'] : 'File');
        formData.append('jsondata', JSON.stringify(this.ruleForm.value));
        formData.append('file', new Blob([this.generateDRL()], { type: 'text/plain' }), 'sample.drl');
        this.ruleService.addDRLFile(formData).subscribe({
            next: res => {
                Toast.fire({
                    icon: 'success',
                    html: res
                });
                this.sendBooleanValue();
            },
            error: err => {
                Toast.fire({
                    icon: 'error',
                    html: err
                });
            }
        })

    }

    backToSelectRule() {
        this.clearForm();
        this.ruleForm.reset();
        this.sendBooleanValue();
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes['fileJob']) {
            await this.getFileMetadata();
        }
    }


    async getFileMetadata() {
        let params = {
            job_id: this.fileJob["job_id"]
        }
        if (this.fileJob && this.fileJob["job_id"])
            this.syntheticDataService.getColumnTypes(params).subscribe({
                next: (res) => {
                    let isNestedColumn = false;
                    if (res && res.forEach)
                        res.forEach((prop) => {
                            let column = prop;
                            if (column && column.column_name && column.column_name.indexOf(".") != -1) {
                                isNestedColumn = true;
                            }
                            this.propsList.push({ name: prop.column_name, type: dataTpes[prop.type], value: prop.column_name });
                        });

                    if (isNestedColumn) {
                        this.propsList = [];

                        Swal.fire({
                            title: 'Rule configuration does not support nested structures. Please click "Back" to upload a flattened file.',
                            showDenyButton: false,
                            confirmButtonText: 'Back',
                            denyButtonText: `No`,
                        }).then((result) => {
                            if (this.wizard) {
                                this.wizard.goToPreviousStep();
                            }

                            if (this._parent) {
                                this._parent.fileJob = {};
                                this._parent.wizard.goToPreviousStep();
                            }
                        });
                    }

                },
                error: (err) => {
                    Toast.fire({
                        icon: 'error',
                        html: err
                    });
                }
            });

    }

    addRuleByCntrl() {
        let formArray = this.currentControl;
        // this.showCreateRuleModal = true;
        const selectedRuleControl = this.createRuleForm.get('selectRule');
        if (selectedRuleControl && selectedRuleControl.value && selectedRuleControl.value == "AND") {
            const andForm = this.fb.group({
                groupType: new FormControl('AND'),
                rules: this.fb.array([], Validators.required)
            });

            (formArray).push(andForm);

        } else if (selectedRuleControl && selectedRuleControl.value && selectedRuleControl.value == "OR") {
            const orForm = this.fb.group({
                groupType: new FormControl('OR'),
                rules: this.fb.array([], Validators.required)
            });

            (formArray).push(orForm);

        } else if (selectedRuleControl && selectedRuleControl.value) {
            const selectedRule = selectedRuleControl.value;
            const type = this.propsList.find((prop) => prop.name == selectedRule).type;

            const ruleForm = this.fb.group({
                groupType: new FormControl('single'),
                propName: new FormControl(selectedRule, Validators.required),
                propType: new FormControl(type, Validators.required),
                operator: new FormControl(null, Validators.required),
                operandValue: new FormControl(null)
            });



            (formArray).push(ruleForm);
            this.whenClauseList.push({ propName: selectedRule, propType: type });
        }
        selectedRuleControl.reset(); // Reset the value in the form control

        this.close();
    }


    removeRuleByCtrl(rules: FormArray, ind: number) {
        rules.removeAt(ind);
    }

    removeAndOrRule(conditionForm: FormGroup, ind: number) {
        Swal.fire({
            title: 'Do you want to remove entire condition?',
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: `No`,
        }).then((result) => {
            if (result.isConfirmed) {
                (conditionForm.parent as FormArray).removeAt(ind);
            }
        });

    }



}