<!-- <div class="app-content content">
    <div class="content-wrapper">
        <div class="container-xxl mt-2">
            <div class="page-main pages oto60-chat">
                <div class="row">
                    <div class="col-lg-12 mb-2">
                        <div class="breadcrumb-container mb-2"> -->
<!-- <app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb> -->
<!-- </div> -->
<section id="main-content">
    <!-- <div class="setup-job-step1 animate__animated">
        <ng-container mCardHeaderTitle>
            <div class="page-title">
                <ng-container>
                    <h1 class="m-0" *ngIf="chatDetails && chatDetails['bot_name']">{{
                        chatDetails['bot_name'] }}</h1>
                    <p class="m-0" *ngIf="chatDetails && chatDetails['bot_name']">{{ 'Ask
                        anything related to ' + chatDetails['bot_name'] + ' data'}}</p>
                </ng-container>
            </div>
        </ng-container>
    </div> -->

    <div class="new-chat">
        <ng-container>
            <div class="dropdown-user " ngbDropdown title="User-action" #myDropdown="ngbDropdown">
                <a class="nav-link dropdown-user-link dropdown-toggle" href="javascript:void(0)" ngbDropdownToggle
                    id="dropdownProfileMenu">
                    <span class="user-name">
                        Review Chat Log</span>
                </a>
                <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownProfileMenu">

                    <ng-container>
                        <!-- <div class="dropdown-item d-flex justify-content-between align-items-center"
                            *ngFor="let thread of threads.reverse()">
                            <ng-container *ngIf="findObjectByThreadId(thread, 'single').trim() !== ''">
                            <a class="text-truncate" style="max-width: 18vw;" href="javascript:void(0)"
                                (click)="setUserActive(thread)">
                                {{findObjectByThreadId(thread, 'single')}}
                            </a>
                            <i class="feather ft-trash-2" (click)="deleteThread($event, thread)"></i>
                            </ng-container>
                        </div> -->
                        <ng-container *ngFor="let thread of threads.reverse()">
                            <ng-container *ngIf="findObjectByThreadId(thread, 'single').trim() !== ''">
                                <div class="dropdown-item d-flex justify-content-between align-items-center">
                                    <a class="text-truncate" style="max-width: 18vw;" href="javascript:void(0)"
                                        (click)="setUserActive(thread)">
                                        {{findObjectByThreadId(thread, 'single')}}
                                    </a>
                                    <i class="feather ft-trash-2" (click)="deleteThread($event, thread)"></i>
                                </div>
                            </ng-container>
                        </ng-container>

                        <div class="d-flex justify-content-center" *ngIf="threads.length == 0">
                            <p class="mt-2">No results found</p>
                        </div>
                    </ng-container>




                    <div class="circle__item d-flex justify-content-center align-items-center" *ngIf="loadThreads">
                        <svg class="circle__spinner" viewBox="0 0 50 50">
                            <circle class="path" cx="25" cy="25" r="10" fill="none" stroke="#93bfec" stroke-width="1">
                            </circle>
                        </svg>
                    </div>
                </div>
            </div>
            <div class="dropdown-user" *ngIf="activeThread != ''">
                <a class="nav-link dropdown-user-link a" href="javascript:void(0)">
                    <span class="user-name" (click)="newChat()">New Chat</span>
                </a>
            </div>
        </ng-container>
    </div>

    <div id="frame">
        <div class="content-chat m-0">
            <div class="chat-msg-block">

                <!-- <div class="chat-msg-btns">
                    <div class="chat-logo-block">
                        <div class="chat-logo">
                            <img src="assets/images/logo/logo.svg">
                        </div>
                        <p>
                            Ask anything related to data, documents and files</p>
                    </div>
                </div> -->

                <ul>
                    <ng-container *ngIf="activeThread != '' && chatMessages.length">
                        <li class="thread mx-0" [class]="msg.type" *ngFor="let msg of chatMessages">
                            <div *ngIf="msg?.question">
                                <div class="d-flex align-items-baseline">
                                    <div class="pl-0 user-logo">{{userData['name'].charAt(0)}}
                                    </div>
                                    <h3>{{msg?.question}}</h3>
                                </div>
                                <div class="d-flex1">
                                    <div class="chat-msg">
                                        {{msg?.answer ? msg?.answer :
                                        'Something went wrong. Please retry'}}
                                    </div>

                                    <img *ngIf="validateImg(msg['attachment'])"
                                        [src]="'data:image/png;base64,' + msg['attachment']" alt="Attachment">

                                </div>
                            </div>
                        </li>
                    </ng-container>
                    <li class="thread mx-0" *ngIf="loading">
                        <div class="d-flex align-items-baseline">
                            <div class="pl-0 user-logo">{{userData['name'].charAt(0)}}</div>
                            <h3>{{ latestQues }}</h3>
                        </div>
                    </li>
                    <li class="thread text-center" *ngIf="loading">
                        <p style="color: #0073ff;font-size:14px !important;">Generating Response</p>
                        <div class="loader-wrapper ">
                            <div class="loader-container mt-1">
                                <div class="ball-pulse loader-primary">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="message-area">
                <div class="message-input-block">
                    <div class="message-input-holder">
                        <textarea placeholder="Upload documents in PDF or doc format" #msgInput
                            (input)="question = $event.target.value.trim()" (keyup.enter)="postQuery()"
                            [(ngModel)]="question"></textarea>
                        <button [disabled]="!question" class="submit" (click)="postQuery()"
                            [ngClass]="{'btn-enable': question}">
                            <i class="fa fa-arrow-up" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>