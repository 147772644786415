<div class="">
  <div class="content-header-left breadcrumb-new" *ngIf="breadcrumb">
    <div class=" breadcrumbs-top d-inline-block">
      <div class="breadcrumb-wrapper">
        <ol class="breadcrumb">
          <li class="breadcrumb-item" *ngFor="let link of breadcrumb.links">
            <a *ngIf="link.isLink" (click)="documentationLink(link)" routerLink="{{link.link}}">{{link.name}}</a>
            <a *ngIf="!link.isLink">{{link.name}}</a>
          </li>
        </ol>
      </div>
    </div>
  </div>
</div>