import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';


@Component({
  selector: 'app-invite-team-members',
  templateUrl: './invite-team-members.component.html'
})
export class InviteTeamMembersComponent implements OnInit {

  inviteTeamMemberForm: FormGroup
  @Output() changeState = new EventEmitter<Number>();

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    this.inviteTeamMemberForm = new FormGroup({
      email: new FormControl(null, [Validators.required]),
      assignrole: new FormControl(null, [Validators.required])
    })
  }

  goTo() {
    this.router.navigate(['/projects']);
  }

}
