import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ForecastService } from 'src/app/_services/forecast.service';

interface ValidationResult {
  data: {
    [segment: string]: {
      actual: { [key: string]: number };
      forecast: { [key: string]: number };
    };
  };
  timestamps: string[];
}

interface InferenceResponse {
  status: string;
  data: {
    timestamp: string[];
    [key: string]: number[] | string[];
  };
}

@Component({
  selector: 'app-forecast-model-info',
  templateUrl: './forecast-model-info.component.html'
})
export class ForecastModelInfoComponent implements OnInit {
  jobId: string;
  modelMetrics: any = {};
  hyperParams: any = {};
  chartData: any;
  chartOptions: any;
  forecastTableData: any[] = [];
  selectedFile: File | null = null;
  loading: boolean = false;
  segments: string[] = [];
  selectedSegment: string;
  validationResult: ValidationResult;
  isInferenceData: boolean = false;
  formattedMetrics: { key: string, segments: { name: string, value: number }[] }[] = [];

  constructor(
    private route: ActivatedRoute,
    private forecastService: ForecastService
  ) {}

  ngOnInit() {
    this.jobId = this.route.snapshot.params['job_id'];
    this.initializeChartOptions();
    this.loadModelDetails();
    this.loadValidationData();
  }

  private loadModelDetails() {
    this.forecastService.getTrainedModel(this.jobId).subscribe({
      next: (response) => {
        console.log('model details', response)
        // if (response.status === 'success' && response.data) {
          this.modelMetrics = response['pipeline_metrics'];
          this.hyperParams = response['pipeline_hyperparams'];
          
          console.log('Model Metrics:', this.modelMetrics);
          console.log('Hyperparams:', this.hyperParams);
        // }
      },
      error: (error) => console.error('Error loading model details:', error)
    });
  }

  private loadValidationData() {
    this.forecastService.getValidationData(this.jobId).subscribe({
      next: (response) => {
        if (response.validation_result?.validation_result) {
          this.isInferenceData = false;
          this.validationResult = response.validation_result.validation_result;
          this.segments = Object.keys(this.validationResult.data);
          
          if (this.segments.length > 0) {
            this.selectedSegment = this.segments[0];
            this.updateChartAndTable(this.selectedSegment);
          }
        }
      },
      error: (error) => console.error('Error loading validation data:', error)
    });
  }

  onFileSelect(event: any) {
    this.selectedFile = event.files[0];
  }

  onPredict() {
    if (!this.selectedFile) return;

    this.loading = true;
    this.forecastService.submitInference(this.jobId, this.selectedFile).subscribe({
      next: (response) => {
        if (response.status === 'success' && response.data) {
          this.isInferenceData = true;
          
          const transformedData: { [key: string]: { actual: {}; forecast: { [key: string]: number } } } = {};
          
          const segments = Object.keys(response.data).filter(key => key !== 'timestamp');
          
          segments.forEach(segment => {
            const values = response.data[segment];
            if (Array.isArray(values) && values.every(v => typeof v === 'number')) {
              transformedData[segment] = {
                actual: {},
                forecast: values.reduce((acc, value, index) => {
                  acc[index.toString()] = value;
                  return acc;
                }, {})
              };
            }
          });

          this.validationResult = {
            data: transformedData,
            timestamps: response.data['timestamp'] as string[]
          };

          this.segments = segments;
          if (this.segments.length > 0) {
            this.selectedSegment = this.segments[0];
            this.updateChartAndTable(this.selectedSegment);
          }
        }
        this.loading = false;
      },
      error: (error) => {
        console.error('Error making prediction:', error);
        this.loading = false;
      }
    });
  }

  onSegmentChange(event: any) {
    this.selectedSegment = event.value;
    this.updateChartAndTable(this.selectedSegment);
  }

  private updateChartAndTable(segment: string) {
    if (!this.validationResult?.data || !this.validationResult?.timestamps) return;

    const segmentData = this.validationResult.data[segment];
    const timestamps = this.validationResult.timestamps;

    if (this.isInferenceData) {
      const forecastValues = Object.values(segmentData.forecast);
      
      this.chartData = {
        labels: timestamps,
        datasets: [
          {
            label: 'Forecast Values',
            data: forecastValues,
            fill: false,
            borderColor: '#FFA726',
            tension: 0.4
          }
        ]
      };

      this.forecastTableData = timestamps.map((date, index) => ({
        date: new Date(date),
        forecast: forecastValues[index]
      }));

    } else {
      const actualValues = Object.values(segmentData.actual);
      const forecastValues = Object.values(segmentData.forecast);

      const lastActualIndex = actualValues.length - 1;
      const forecastStartIndex = lastActualIndex - forecastValues.length + 1;

      const chartActualValues = actualValues.map(v => v);
      const chartForecastValues = Array(timestamps.length).fill(null);

      forecastValues.forEach((value, index) => {
        chartForecastValues[forecastStartIndex + index] = value;
      });

      this.chartData = {
        labels: timestamps,
        datasets: [
          {
            label: 'Actual Values',
            data: chartActualValues,
            fill: false,
            borderColor: '#42A5F5',
            tension: 0.4
          },
          {
            label: 'Forecast Values',
            data: chartForecastValues,
            fill: false,
            borderColor: '#FFA726',
            tension: 0.4
          }
        ]
      };

      this.forecastTableData = timestamps.map((date, index) => ({
        date: new Date(date),
        actual: chartActualValues[index],
        forecast: chartForecastValues[index]
      }));
    }
  }

  private initializeChartOptions() {
    this.chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'bottom'
        },
        title: {
          display: true,
          text: 'Forecast vs Actual Values'
        }
      },
      scales: {
        x: {
          title: {
            display: true,
            text: 'Date'
          }
        },
        y: {
          title: {
            display: true,
            text: 'Value'
          }
        }
      }
    };
  }

}
