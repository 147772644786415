import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AuthService } from 'src/app/_services/auth.service';
import { OrgService } from 'src/app/_services/org.service';
import { PdfService } from 'src/app/_services/pdf.service';

@Component({
  selector: 'app-pii-phi-report',
  templateUrl: './pii-phi-report.component.html'
})
export class PiiPhiReportComponent implements OnInit, OnChanges {

  @Input() piiReportData: Object[] = []
  @Input() jobDetails: Object = {}
  orgName: string = "";

  constructor(
    private pdfService: PdfService,
    private authService: AuthService,
    private orgService: OrgService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    
  }

  ngOnInit(): void {
    this.orgService.getOrgById(this.authService.currentUserValue.orgId).subscribe({
      next: res => {
        this.orgName = res['name']
      }
    })
  }

  getPDFReport() {
    this.pdfService.getReport(this.piiReportData, this.orgName)
  }

}
