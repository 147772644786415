import { Component, Inject, OnInit, Renderer2, ViewChild } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { NavbarService } from "../../../_services/navbar.service";
import { ThemeSettingsService } from "../../settings/theme-settings.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Router, NavigationEnd, Event } from "@angular/router";
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarComponent,
  PerfectScrollbarDirective,
} from "ngx-perfect-scrollbar";
import { MenuSettingsService } from "../../settings/menu-settings.service";
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from "@angular/animations";
import { AppConstants } from "src/app/_helpers/app.constants";
import { UserService } from "src/app/_services/user.service";
import { Toast } from "src/app/_helpers/toast";
import { OrgService } from "src/app/_services/org.service";
import { DocumentationSlideService } from "src/app/_services/documentation-slide.service";
import { SubscriptionService } from "src/app/_services/subscription.service";
import { Subscription } from "src/app/_models/subscription.model";
interface City {
  name: string;
  code: string;
}

@Component({
  selector: "app-verticalnav",
  templateUrl: "./verticalnav.component.html",
  animations: [
    trigger("popOverState", [
      state(
        "show",
        style({
          opacity: "1",
        })
      ),
      state(
        "hide",
        style({
          opacity: "0",
          height: "*",
        })
      ),
      transition("show => hide", animate("200ms ease-in-out")),
      transition("hide => show", animate("200ms ease-in-out")),
    ]),
  ],
})
export class VerticalnavComponent implements OnInit {
  child: any;
  insideTm: any;
  outsideTm: any;
  public _themeSettingsConfig: any;
  public _menuSettingsConfig: any;
  private _unsubscribeAll: Subject<any>;
  private _unsubscribeAllMenu: Subject<any>;
  public currentUser: any;
  orgsData: Object[] = [];
  selectedCity: City;
  public config: PerfectScrollbarConfigInterface = { wheelPropagation: true };
  active: Object = {};
  @ViewChild(PerfectScrollbarComponent, { static: false })
  componentRef?: PerfectScrollbarComponent;
  @ViewChild(PerfectScrollbarDirective, { static: true })
  directiveRef?: PerfectScrollbarDirective;
  priceModel: boolean = false;
  subscriptionData: Subscription;


  constructor(
    @Inject(DOCUMENT) private document: Document,
    private navbarService: NavbarService,
    private _themeSettingsService: ThemeSettingsService,
    private _menuSettingsService: MenuSettingsService,
    private _renderer: Renderer2,
    private router: Router,
    private userService: UserService,
    private documentSlideService: DocumentationSlideService,
    private orgService: OrgService,
    private subscriptionService: SubscriptionService
  ) {
    this._unsubscribeAll = new Subject();
    this._unsubscribeAllMenu = new Subject();
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.resetMainMenu();
        this.setActiveRouteInNavbar();
      }
    });
  }

  ngOnInit() {
    if (sessionStorage.getItem("currentUser")) {
      this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    }

    // Subscribe to config changes
    this._themeSettingsService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this._themeSettingsConfig = config;
        this.refreshView();
      });
    this._menuSettingsService.config
      .pipe(takeUntil(this._unsubscribeAllMenu))
      .subscribe((config) => {
        this._menuSettingsConfig = config;
      });
    // TODO Patch to reset menu after login
    this.resetMainMenu();
    this.setActiveRouteInNavbar();

    this.fetchOrg();
    this.getActiveSubscription();
  }

  priceDialog() {
    this.priceModel = true;
  }

  resetMainMenu() {
    const mainMenu = this.document.getElementById("main-menu-navigation");

    if (mainMenu) {
      const nodes = mainMenu.childNodes;

      for (let i = 0; i < nodes.length; i++) {
        this.resetCollapseMenu(nodes[i]);
      }

      for (let i = 0; i < this._menuSettingsConfig.vertical_menu.items.length; i++) {
        this._menuSettingsConfig.vertical_menu.items[i]["isSelected"] = false;
        this._menuSettingsConfig.vertical_menu.items[i]["hover"] = false;
        this._menuSettingsConfig.vertical_menu.items[i]["isOpen"] = false;
        this.resetSubmenuItems(this._menuSettingsConfig.vertical_menu.items[i]);
      }
    }
  }

  resetCollapseMenu(element) {
    if (
      element.classList &&
      element.classList.contains("has-sub") &&
      element.classList.contains("open")
    ) {
      element.classList.remove("hover");
      element.classList.remove("menu-collapsed-open");
    }
  }

  resetSubmenuItems(parentItem) {
    if (
      parentItem["submenu"] &&
      parentItem["submenu"]["items"] &&
      parentItem["submenu"]["items"].length > 0
    ) {
      parentItem["isOpen"] = false;
      for (let j = 0; j < parentItem["submenu"]["items"].length; j++) {
        parentItem["submenu"]["items"][j]["isSelected"] = false;
        this.resetSubmenuItems(parentItem["submenu"]["items"][j]);
      }
    }
  }

  refreshView() {
    const mainMenuElement = document.getElementsByClassName("main-menu");
    if (mainMenuElement && mainMenuElement.length > 0) {
      if (
        this._themeSettingsConfig.colorTheme === "semi-light" ||
        this._themeSettingsConfig.colorTheme === "light"
      ) {
        this._renderer.removeClass(mainMenuElement.item(0), "menu-dark");
        this._renderer.addClass(mainMenuElement.item(0), "menu-light");
      } else if (
        this._themeSettingsConfig.colorTheme === "semi-dark" ||
        this._themeSettingsConfig.colorTheme === "dark"
      ) {
        this._renderer.addClass(mainMenuElement.item(0), "menu-dark");
        this._renderer.removeClass(mainMenuElement.item(0), "menu-light");
      }
      if (this._themeSettingsConfig.layout.pattern === "static") {
        this._renderer.removeClass(mainMenuElement.item(0), "menu-fixed");
        this._renderer.addClass(mainMenuElement.item(0), "menu-static");
      } else if (this._themeSettingsConfig.layout.pattern === "fixed") {
        this._renderer.removeClass(mainMenuElement.item(0), "menu-static");
        this._renderer.addClass(mainMenuElement.item(0), "menu-fixed");
      }
    }
  }

  setActiveRouteInNavbar() {
    for (
      let i = 0;
      i < this._menuSettingsConfig.vertical_menu.items.length;
      i++
    ) {
      if (
        !this._menuSettingsConfig.vertical_menu.items[i].submenu &&
        this._menuSettingsConfig.vertical_menu.items[i].page === this.router.url
      ) {
        this._menuSettingsConfig.vertical_menu.items[i]["isSelected"] = true;
        break;
      } else if (this._menuSettingsConfig.vertical_menu.items[i].submenu) {
        // Level 1 menu
        for (
          let j = 0;
          j <
          this._menuSettingsConfig.vertical_menu.items[i].submenu.items.length;
          j++
        ) {
          if (
            !this._menuSettingsConfig.vertical_menu.items[i].submenu.items[j]
              .submenu &&
            this._menuSettingsConfig.vertical_menu.items[i].submenu.items[j]
              .page === this.router.url
          ) {
            this._menuSettingsConfig.vertical_menu.items[i]["isSelected"] =
              true;
            this._menuSettingsConfig.vertical_menu.items[i].submenu.items[j][
              "isSelected"
            ] = true;
            this._menuSettingsConfig.vertical_menu.items[i].isOpen = true;
            break;
          } else if (
            this._menuSettingsConfig.vertical_menu.items[i].submenu.items[j]
              .submenu
          ) {
            // Level 2 menu
            for (
              let k = 0;
              k <
              this._menuSettingsConfig.vertical_menu.items[i].submenu.items[j]
                .submenu.items.length;
              k++
            ) {
              if (
                this._menuSettingsConfig.vertical_menu.items[i].submenu.items[j]
                  .submenu.items[k].page === this.router.url
              ) {
                this._menuSettingsConfig.vertical_menu.items[i]["isSelected"] =
                  true;
                this._menuSettingsConfig.vertical_menu.items[i].submenu.items[
                  j
                ]["isSelected"] = true;
                this._menuSettingsConfig.vertical_menu.items[i].isOpen = true;

                this._menuSettingsConfig.vertical_menu.items[i].submenu.items[
                  j
                ]["isSelected"] = true;
                this._menuSettingsConfig.vertical_menu.items[i].submenu.items[
                  j
                ].submenu.items[k]["isSelected"] = true;
                this._menuSettingsConfig.vertical_menu.items[i].submenu.items[
                  j
                ].isOpen = true;
              }
            }
          }
        }
      }
    }
  }

  resetOpenMenu() {
    for (
      let i = 0;
      i < this._menuSettingsConfig.vertical_menu.items.length;
      i++
    ) {
      const menu = this._menuSettingsConfig.vertical_menu.items[i];
      if (!menu.submenu) {
        menu["isOpen"] = false;
        menu["isActive"] = false;
        menu["hover"] = false;
      } else if (menu.submenu) {
        for (let j = 0; j < menu.submenu.items.length; j++) {
          menu["isOpen"] = false;
          menu["isActive"] = false;
          menu["hover"] = false;
          menu.submenu.items[j]["isOpen"] = false;
        }
      }
    }
  }

  setOpenInNavbar(value) {
    for (
      let i = 0;
      i < this._menuSettingsConfig.vertical_menu.items.length;
      i++
    ) {
      const menu = this._menuSettingsConfig.vertical_menu.items[i];
      if (!menu.submenu && menu.page === this.router.url) {
        menu["isOpen"] = value;
        menu["isActive"] = value;
      } else if (menu.submenu) {
        for (let j = 0; j < menu.submenu.items.length; j++) {
          if (menu.submenu.items[j].page === this.router.url) {
            menu["isOpen"] = value;
            menu["isActive"] = value;
            menu.submenu.items[j]["isOpen"] = value;
            menu.submenu.items[j]["isActive"] = value;
            break;
          }
        }
      }
    }
  }

  callFunction(event, child, isSubmenuOfSubmenu) {
    const methodName = event.methodName;
    if (this[methodName]) {
      // method exists on the component
      const param = event.methodParam;
      if (!Array.isArray(param)) {
        this[methodName](param); // call it
      } else {
        this[methodName](param[0], param[1]); // call it
      }
    }
    this.resetOtherActiveMenu(child, isSubmenuOfSubmenu);
    child["isSelected"] = true;
  }

  setTheme(theme) {
    this._themeSettingsService.config = {
      colorTheme: theme, // semi-light, semi-dark
    };
  }

  setLayout(layout) {
    this._themeSettingsService.config = {
      layout: {
        pattern: layout,
      },
    };
  }

  fixComponent(component, value) {
    if (component === "header") {
      this._themeSettingsService.config = {
        header: value,
      };
    } else if (component === "footer") {
      this._themeSettingsService.config = {
        footer: value,
      };
    } else {
      this._themeSettingsService.config = {
        header: value,
        footer: value,
      };
    }
  }

  /**
   * Use for fixed left aside menu, to show menu on mouseenter event.
   * @param e Event
   */
  mouseEnter(e) {
    if (this.navbarService.isFixedMenu()) {
      return;
    }
    this.navbarService.setMouseInRegion(true);
    const navBar = this.document.getElementById("navbar-header");
    const mainMenu = this.document.getElementById("main-menu");

    // check if the left aside menu is fixed
    if (!navBar.classList.contains("expanded")) {
      this._renderer.addClass(navBar, "expanded-kiran");
      this._renderer.addClass(mainMenu, "expanded-kiran");
      this.resetOpenMenu();
      this.setOpenInNavbar(true);
    }
  }

  /**
   * Use for fixed left aside menu, to show menu on mouseenter event.
   * @param e Event
   */
  mouseLeave(event) {
    if (this.navbarService.isFixedMenu()) {
      return;
    }
    const _self = this;
    const navBar = this.document.getElementById("navbar-header");
    const mainMenu = this.document.getElementById("main-menu");
    if (navBar && navBar.classList.contains("expanded")) {
      this.insideTm = setTimeout(() => {
        if (!_self.navbarService.isMouseInRegion()) {
          this._renderer.removeClass(navBar, "expanded");
          this._renderer.removeClass(mainMenu, "expanded");
          this.resetOpenMenu();
          this.setOpenInNavbar(false);
        }
      }, 100);
    }
    this.navbarService.setMouseInRegion(false);
  }

  resetOtherActiveMenu(selectedChild, isSubmenuOfSubmenu) {
    for (
      let i = 0;
      i < this._menuSettingsConfig.vertical_menu.items.length;
      i++
    ) {
      this._menuSettingsConfig.vertical_menu.items[i]["isSelected"] = false;
      this._menuSettingsConfig.vertical_menu.items[i]["hover"] = false;
      this.handleSubmenuItems(
        this._menuSettingsConfig.vertical_menu.items[i],
        selectedChild,
        isSubmenuOfSubmenu
      );
    }
  }

  handleSubmenuItems(parentItem, selectedChild, isSubmenuOfSubmenu) {
    if (selectedChild["title"] === "Horizontal") {
      sessionStorage.setItem(
        "currentLayoutStyle",
        AppConstants.LAYOUT_STYLE_HORIZONTAL
      );
      window.location.reload();
    } else if (selectedChild["title"] === "Vertical") {
      sessionStorage.setItem(
        "currentLayoutStyle",
        AppConstants.LAYOUT_STYLE_VERTICAL
      );
      window.location.reload();
    } else if (
      parentItem["submenu"] &&
      parentItem["submenu"]["items"] &&
      parentItem["submenu"]["items"].length > 0
    ) {
      if (
        parentItem.title !== selectedChild.title &&
        parentItem["isOpen"] === true &&
        !isSubmenuOfSubmenu &&
        this._themeSettingsConfig.navigation ===
        AppConstants.NAVIGATION_TYPE_COLLAPSIBLE
      ) {
        parentItem["isOpen"] = false;
      }
      for (let j = 0; j < parentItem["submenu"]["items"].length; j++) {
        if (selectedChild.page !== "null") {
          parentItem["submenu"]["items"][j]["isSelected"] = false;
        }
        this.handleSubmenuItems(
          parentItem["submenu"]["items"][j],
          selectedChild,
          isSubmenuOfSubmenu
        );
      }
    } else if (
      parentItem.title !== selectedChild.title &&
      !selectedChild.submenu &&
      this._themeSettingsConfig.navigation ===
      AppConstants.NAVIGATION_TYPE_COLLAPSIBLE &&
      parentItem["isOpen"] === true
    ) {
      parentItem["isOpen"] = false;
    }
  }

  toggleMenu(event, child, isSubmenuOfSubmenu) {
    this.active = {}
    this.documentSlideService.customDocumentName(child.title);
    const toggle = document.getElementById("sidenav-overlay");
    this.resetOtherActiveMenu(child, isSubmenuOfSubmenu);
    if (child["isSelected"] === true) {
      child["isSelected"] = false;
    } else {
      child["isSelected"] = true;
    }

    if (child["hover"] === true) {
      child["hover"] = false;
    } else {
      child["hover"] = true;
    }

    if (child["isOpen"] === true) {
      child["isOpen"] = false;
    } else {
      child["isOpen"] = true;
    }

    if (this.router.url !== "") {
      this._renderer.addClass(toggle, "d-none");
      this._renderer.removeClass(toggle, "d-block");
    }
    if (child["title"] === "Settings") {
      this.orgname()
    }
    if (child["title"] === "Synthesize Data") {
      this.active['Synthesize Data'] = true;
      this.documentSlideService.customDocumentName("AddJob");
      this.routeType('synthesis', child)
    }
    if (child["title"] === "Deploy Rule Set") {
      this.active['Deploy Rule Set'] = true;
      this.documentSlideService.customDocumentName("AddJob");
      this.routeType('rules', child)
    }
    if (child["title"] === "Select Org") {
      child["title"] = this.orgsData[0]["name"].toString();
      // this.orgname()
    }

    if (child["title"] === "Train ML Model") {
      this.active['Train ML Model'] = true;
      this.documentSlideService.customDocumentName("AddJob");
      this.routeType('automl', child)
    }

    if (child["title"] === "Model Library") {
      this.documentSlideService.customDocumentName("AddJob");
    }

    if (child["title"] === "Train Chatbot") {
      this.documentSlideService.customDocumentName("AddJob");
    }

  }

  routeType(type, child) {
    this.router.navigate([`/projects/p/jobs/n`], { fragment: type });
    child["isSelected"] = true;
  }

  orgname() {
    this.router.navigate([`/org/${this.currentUser["orgId"]}`]);
  }

  fetchOrg() {
    this.orgsData = [];
    this.userService.getUserProfile().subscribe({
      next: (res) => {
        if (Array.isArray(res["orgId"])) {
          res["orgId"].forEach((l) => {
            this.orgService.getOrgById(l["orgId"]).subscribe({
              next: (r) => {
                this.orgsData.push(r);
              },
              error: (err) => {
                Toast.fire({
                  icon: "error",
                  html: err.error.error.message,
                });
              },
            });
          });
        } else {
          this.orgService.getOrgById(res["orgId"]).subscribe({
            next: (r) => {
              this.orgsData.push(r);
              this.selectedCity = this.orgsData[0]["name"];
            },
            error: (err) => {
              Toast.fire({
                icon: "error",
                html: err.error.error.message,
              });
            },
          });
        }
      },
      error: (err) => {
        Toast.fire({
          icon: "error",
          html: err.error.error.message,
        });
      },
    });
    this.userService.updateEvent.subscribe((data) => {
      this.currentUser.name = data.name;
    });
  }

  proceedToCheckout(interval: string) {
    if (!this.currentUser.orgId) {
      alert("User not logged in");
    }
    this.subscriptionService.initialCheckoutPage({ customerId: this.currentUser.orgId, interval }).subscribe({
      next: (data) => {
        if (data.url) {
          location.href = data.url;
        }
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  getActiveSubscription() {
    this.subscriptionService.subscriptionDetailsById(this.currentUser.orgId).subscribe({
      next: (res: Subscription) => {
        if (res && !res.errno) {
          this.subscriptionData = res;
        }
      },
      error: (err) => {
        console.log(err);
      }
    });
  }
  navigateToInvoiceDetails() {
    this.router.navigate([`/org/${this.currentUser["orgId"]}`], {
      queryParams: {
        tab: 'subscription'
      }
    });
  }
}
