import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Toast } from 'src/app/_helpers/toast';
import { AuthService } from 'src/app/_services/auth.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-finalize-registration',
  templateUrl: './finalize-registration.component.html'
})
export class FinalizeRegistrationComponent implements OnInit {

  res: Object = {};
  finalizeRegistrationForm: FormGroup;
  email: string = '';
  id: string = '';
  submitted: boolean;
  loading: boolean;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router
  ) {
    this.route.queryParamMap.subscribe((params) => {
      this.res = { ...params };
      this.email = this.res['params']['email']
      this.id = this.res['params']['id']
    }
    );
  }

  ngOnInit(): void {
    this.finalizeRegistrationForm = new FormGroup({
      registrationCode: new FormControl(null, [Validators.required])
    })

    if (!this.id) {
      this.router.navigate(['/login']);
    }
  }

  tryRegistrationCode() {
    this.submitted = true;
    if (this.finalizeRegistrationForm.invalid) {
      return;
    }
    this.loading = true;
    let body: Object = {}
    Object.assign(body, this.finalizeRegistrationForm.value);
    body['id'] = this.id;

    //Service
    this.authService.doFinalizeRegistration(body).subscribe({
      next: res => {
        this.loading = false;
        Swal.fire({
          title: 'Your Email Address is successfully verified!',
          text: 'Please wait for your org admin to accept your request.',
          icon: 'success',
          confirmButtonColor: '#FF6501',
          confirmButtonText: 'Back to Login'
        }).then((result) => {
          if (result.isConfirmed) {
            this.router.navigate(['/login'])
          }
        })
      },
      error: err => {
        this.loading = false;
        Toast.fire({
          icon: 'error',
          html: err.error.error.message
        })
      }
    })
  }
}
