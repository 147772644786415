<div class="form-builder add-rule-popup">
    <h2 class="popup-title text-center">Add New Rule</h2>
    <div class="row mb-3">
        <div class="col-4">
            <h3>Upload DRL file</h3>
        </div>
        <div class="col-8">
            <div *ngIf="uploadedFiles.length == 0">
                <p-fileUpload mode="basic" name="drl[]" accept=".drl" maxFileSize="20000000" [customUpload]="true"
                    [auto]="true" chooseLabel="Upload DRL" (onSelect)="handleFileUpload($event)"></p-fileUpload>
            </div>
            <div *ngIf="uploadedFiles.length">
                <ul style="padding-left: 0px;">
                    <li *ngFor="let file of uploadedFiles" style="list-style: none; padding-top: 5px;">
                        {{ file.name }} - {{ file.size }}
                        <a href="javascript:void(0)" [ariaLabel]="'clear your selected file'"
                            (click)="clearFile(file)"><i class="feather ft-x cursor-pointer ml-1"></i></a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-4">
            <h3>Rule Name</h3>
        </div>
        <div class="col-6">
            <form [formGroup]="ruleForm" class="idForm">
                <input type="text" id="projectinput1" class="form-control" formControlName="ruleName"
                    placeholder="Enter Rule Name">
            </form>
        </div>
    </div>
    <button class="btn btn-raised btn-warning btn-small float-right mt-3" (click)="submitRule()"
        [disabled]="!ruleForm.valid || uploadedFiles.length === 0">Save</button>
</div>