<div class="setup-job">
    <form [formGroup]="configLLMForm">
        <div class="row">
            <div class="col-lg-12">
                <div class="form-group page-content row mx-auto">
                    <h2 class="col-md-3 label-control" for="projectinput1">{{ 'General' }}</h2>
                    <div class="col-lg-8 col-md-7 setup-info">
                        <div class="row">
                            <div class="col-lg-6">
                                <label class=" label-control" for="projectinput1">{{ 'Model Name' }}
                                    <span class="mendatory">*</span></label>
                                <input type="text" id="projectinput1" class="form-control" formControlName="model_name"
                                    [placeholder]="'inputforms.placeholder.jobnameLLM' | translate"
                                    (change)="emitterHandler()">
                                <div
                                    *ngIf="configLLMForm.get('model_name').invalid &&  configLLMForm.get('model_name').touched">
                                    <div class="invalid-feedback">
                                        {{ 'Model name is required' }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group page-content row mx-auto">
                    <h2 class="col-md-3 label-control" for="projectinput1"></h2>
                    <div class="col-lg-8 col-md-7 setup-info">
                        <div class="row">
                            <div class="col-lg-6">
                                <label class=" label-control" for="projectinput1">{{ 'Base Model'}}<span
                                        class="mendatory"> *</span></label>
                                <span class="p-fluid">
                                    <p-dropdown [options]="dropdownOptions" [filter]="true" formControlName="base_model"
                                        appendTo="body" placeholder="Select an option" (onChange)="emitterHandler()">
                                    </p-dropdown>
                                </span>
                                <div
                                    *ngIf="configLLMForm.get('base_model').invalid &&  configLLMForm.get('base_model').touched">
                                    <div class="invalid-feedback">
                                        {{ 'Base Model is required' }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>