<footer id="footer" class="footer footer-static footer-light navbar-border navbar-shadow" *ngIf="showFooter">
  <p class="clearfix blue-grey lighten-2 text-sm-center mb-0 px-2">
    <span class="float-md-left d-block d-md-inline-block">
      {{ 'footer.copyright' | translate}} &copy; {{ 'footer.year' | translate}}
      <a [routerLink]="" class="text-bold-800 grey darken-2" href="https://www.cloudseed.io" target="_blank"
        rel="noopener">
        {{ 'footer.brudatalink' | translate}}
      </a>
    </span>
    <span class="float-md-right d-block d-md-inline-block d-none d-lg-block">{{ 'footer.brudatawebsite' | translate}}
      <span id="scroll-top"></span>
    </span>
  </p>
</footer>


<footer id="footer" class="footer fixed-bottom footer-dark navbar-border navbar-shadow" *ngIf="!showFooter">
  <p class="clearfix blue-grey lighten-2 text-sm-center mb-0 px-2"><span
      class="float-md-left d-block d-md-inline-block">{{ 'footer.copyright' | translate}} &copy; {{ 'footer.year' |
      translate}} <a [routerLink]="" class="text-bold-800 grey darken-2" href="https://www.cloudseed.io" target="_blank"
        rel="noopener">{{ 'footer.brudatalink' | translate}}</a></span><span class="float-md-right d-none d-lg-block">{{
      'footer.brudatawebsite' | translate}}<span id="scroll-top"></span></span></p>
</footer>