import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-add-configure-rag-tool',
  templateUrl: './add-configure-rag-tool.component.html'
})
export class AddConfigureRagToolComponent {

  toolName;
  toolDescription;
  ragFiles = [];
  @Output() ragEmitter = new EventEmitter();

  constructor () {

  }

  myUploader(event) {
    this.ragFiles = event.files;
  }

  clearFile(file) {
    this.ragFiles = [];
  }

  saveRagTool() {
    this.ragEmitter.emit({
      toolName: this.toolName,
      toolDescription: this.toolDescription,
      file: this.ragFiles
    })
  }

}
