<div class="form-group" [formGroup]="form">
    <label>{{ field['label'] }}</label>
    <span class="p-fluid">
        <p-dropdown [filter]="true" styleClass="allDropdown" class="dropdown" [options]="field['options']"
            filterBy="name" [placeholder]="field['selectLabel']" (onChange)="patternDialogs()" [showClear]="true"
            [formControlName]="field['name']" optionLabel="name" optionValue="value">
        </p-dropdown>
        <ng-template *ngIf="field['group']" let-group pTemplate="group">
            <div class="p-d-flex p-ai-center">
                <span>{{field['group']['label']}}</span>
            </div>
        </ng-template>

    </span>
    <div class="d-flex newStrategy new-connection" *ngIf="field['group']">
        <a href="javascript:void(0)" class="a" #aAddNewPattern tabIndex="0" (click)="newStrategyEmitter('new')">
            <i class="feather ft-settings"></i>
            {{ 'dropdown.addnewpattern' | translate }}
        </a>
    </div>
</div>