<div class="form-group sub-title row mx-auto mt-0 mb-0 confirm-synthesis-pattenn ">
    <form [formGroup]="customStrategyForm" aria-label="'customStrategyForm'">
        <div class="col-md-12 col-lg-12 page-content custom-synthesis-content mb-0 p-0">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group d-flex">
                        <label>{{ 'inputforms.labels.tokenize' | translate }}</label>
                        <div class="d-flex align-item-center ml-1 tokenize-cheeck">
                            <div class="form-check form-switch pl-0">
                                <input class="form-check-input" type="checkbox" id="binary" formControlName="tokenize"
                                    (change)="checkedStatus()" tabindex="0"
                                    aria-label="Tokenize please click space bar to check and uncheck">
                                <label [ngClass]="disableTokenize ? 'disablelabel': ''" class="form-check-label"
                                    for="binary">{{checked ? 'Yes' : 'No'}}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group" *ngIf="checked">
                        <!-- checked -->
                        <p-dropdown [filter]="true" styleClass="allDropdown" class="dropdown" [options]="patternList"
                            [placeholder]="('inputforms.placeholder.pattern' | translate) ? ('inputforms.placeholder.pattern' | translate) : ' '"
                            filterBy="name" (onChange)="patternDecider($event)" formControlName="pattern"
                            optionLabel="name" optionValue="value">
                        </p-dropdown>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <ng-container *ngIf="checked && selectPattern != null">
                        <div class="form-group" *ngIf="checked">
                            <label>{{ 'inputforms.labels.fieldlength' | translate }}</label>
                            <div class="d-flex">
                                <div *ngFor="let category of categories" class="p-field-checkbox d-flex">
                                    <p-radioButton formControlName="category" [inputId]="category.key" name="category"
                                        [value]="category.name" (onClick)="fieldLengthStatus($event)"></p-radioButton>
                                    <label [for]="category.key" class="field-length">{{category.name | titlecase
                                        }}</label>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="col-md-6 variable-feilds" *ngIf="checked && fieldLength == 'variable'">
                    <div class="form-group set-job delimiter">
                        <label for="float-input" class=" label-control">{{ 'inputforms.labels.delimiter' |
                            translate
                            }}</label>
                        <input formControlName="delimiter" (input)="delimiterHandler()" id="float-input"
                            class="form-control" [placeholder]="'inputforms.placeholder.delimiter' | translate"
                            type="text" pInputText maxlength="1">
                    </div>

                    <div class="row" *ngIf="splitArray.length > 0">
                        <div class="d-flex justify-content-around">
                            <p *ngFor="let item of splitArray">{{item}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="loader-wrapper spinners"
                        *ngIf="formSchema.length == 0 && positionForm.length == 0 && variableForm.length ==0">
                        <div class="loader-container">
                            <div class="chasing-dots loader-brown">
                                <div class="child dot1"></div>
                                <div class="child dot2"></div>
                            </div>
                        </div>
                    </div>
                    <app-form-builder *ngIf="!checked && formSchema.length > 0" [formSchema]="formSchema"
                        [setFocusTestButton]="setFocusTestButton" [setFocus]="setFocusAddNewPattern"
                        [assignStyle]="true" (onSubmit)="nonTokenize($event)" (popup)="emitterHandler($event)"
                        class="dynamic_dropdown" (onTest)="testSynthesisData($event)"
                        (onClear)="clearForm($event, 'NA')" (dependentFieldEvent)="handleDependentFieldEvent($event)"
                        [layout]="'grid'"></app-form-builder>
                </div>
                <div class="col-md-12 set-job" *ngIf="checked && selectPattern != null">
                    <div class="form-group" *ngIf="checked">
                        <label>{{ 'inputforms.labels.sample' | translate }}
                        </label>
                        <div class="sample-data">
                            <div class="input-field" *ngFor="let r of topRecords; let i=index">
                                <div *ngIf="showIndex == i">
                                    <div class="sampleLength">
                                        <p class="" *ngFor="let item of breakDown(topRecords[showIndex])">{{item}}
                                        </p>
                                    </div>
                                    <div class="d-flex justify-content-between prev-next">
                                        <a href="javascript:void(0)" (click)="slideData(showIndex, 'prev')"><i
                                                class="feather ft-chevron-left"></i>{{
                                            'customsynthesispattern.prevlink'
                                            | translate }}</a>
                                        <a href="javascript:void(0)" (click)="slideData(showIndex, 'next')">{{
                                            'customsynthesispattern.nextlink' | translate }}<i
                                                class="feather ft-chevron-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fixed -->
                <div class="col-md-12" *ngIf="checked && selectPattern != null">
                    <div class="columlet">
                        <div class="d-flex columlet-btn  mb-2" *ngIf="checked && fieldLength == 'fixed'">
                            <h6 [ngClass]="formIndex == i ? 'tab-highlighted' : ''"
                                *ngFor="let item of tabs; index as i" (click)="tabDecider(i)">{{item['label']}}
                            </h6>
                        </div>
                        <div *ngIf="validate('fixed') && checked && positionForm.length > 0 && fieldLength == 'fixed'">
                            <app-form-builder [ngClass]="checked ? 'checkedClass': 'highlighted'"
                                [formSchema]="positionForm[formIndex]" [setFocusTestButton]="setFocusTestButton"
                                [setFocus]="setFocusAddNewPattern" class="checkedClass dynamic_dropdown"
                                [assignStyle]="true" (popup)="emitterHandler($event)"
                                (onClear)="clearForm($event, 'fixed')" (onSubmit)="fixedTokenize($event)"
                                (onTest)="testSynthesisData($event)" [layout]="'grid'">
                            </app-form-builder>
                        </div>
                    </div>

                    <!-- Variable -->

                    <!-- Needs to be verified here -->
                    <div *ngIf="checked && fieldLength == 'variable'" class="variable-feilds">
                        <div class="columlet">
                            <div class="d-flex columlet-btn set-job mb-2" *ngIf="tabs.length">
                                <h6 [ngClass]="formIndex == i ? 'tab-highlighted' : ''"
                                    *ngFor="let item of tabs; index as i" (click)="tabDecider(i)">{{item['label']}}</h6>
                            </div>
                            <div
                                *ngIf="validate('variable') && customStrategyForm && customStrategyForm['value'] && customStrategyForm['value']['delimiter'] !== ''">
                                <app-form-builder [ngClass]="checked ? 'checkedClass': 'highlighted'"
                                    *ngIf="checked && fieldLength == 'variable'" class="checkedClass dynamic_dropdown"
                                    (popup)="emitterHandler($event)" [setFocusTestButton]="setFocusTestButton"
                                    [formSchema]="variableForm[formIndex]" [setFocus]="setFocusAddNewPattern"
                                    (onSubmit)="variableTokenize($event)" [assignStyle]="true"
                                    (onTest)="testSynthesisData($event)" (onClear)="clearForm($event, 'variable')"
                                    [layout]="'grid'" [variableLayout]="'variableLayout'"></app-form-builder>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <div class="mt-2 d-flex save-pattern justify-content-end " *ngIf="checked && selectPattern != null">
            <button uiSref="address" type="button" class=" btn btn-raised btn-secondary  btn-small mr-1"
                [disabled]="disabledTest || columnsData.length == 0" (click)="testSynthesisColumnlet()">
                {{ 'buttontitle.testbutton' | translate }}
            </button>
            <button uiSref="address" type="button" class=" btn btn-raised btn-secondary  btn-small"
                [disabled]="columnsData.length == 0" (click)="displayPattern()">
                {{ 'buttontitle.savepatternbutton' | translate }}
            </button>

        </div>

    </form>
</div>

<p-dialog [(visible)]="showStrategyHandler" [resizable]="false" [draggable]="false" [modal]="true" [blockScroll]="true"
    [style]="{width: '45vw' ,height:'630px'}" (onHide)="closeStrategyHandler()">
    <div class="p-dialog-titlebar">
        <button class="p-dialog-titlebar-icon p-dialog-titlebar-close" autofocus="true" aria-label="Close Dialog"
            (click)="closeStrategyHandler()">
            <span class="pi pi-times"></span>
        </button>
    </div>
    <div *ngIf="showStrategyHandler" class="add-project-popup">
        <app-strategies-handler [status]="strategyHandlerStatus" [strategySchema]="strategySchema"
            [selectedAssignValue]="selectedAssignValue" [projectId]="projectId" [jobDetails]="jobDetails"
            (strategyEvent)="strategyHandlerEvent($event)"></app-strategies-handler>
    </div>
</p-dialog>

<div class="test-popup">
    <p-dialog [(visible)]="showTest" [resizable]="false" [draggable]="false" [modal]="true" [blockScroll]="true"
        (onHide)="close(true)" [style]="{width: '45vw'}">
        <div class="p-dialog-titlebar">
            <button class="p-dialog-titlebar-icon p-dialog-titlebar-close" autofocus="true" aria-label="Close Dialog"
                (click)="close(true)">
                <span class="pi pi-times"></span>
            </button>
        </div>
        <div *ngIf="showTest" class="test-popup">
            <app-confirm-synthesis-data [testData]="confirmSyntheticData" [positionRange]="positionRange" [top]="false"
                [selectedColumn]="selectedColumnDetails" (closeConfirm)="close($event)">
            </app-confirm-synthesis-data>
        </div>
    </p-dialog>
</div>

<p-dialog [(visible)]="showPattern" [resizable]="false" [draggable]="false" [modal]="true" [blockScroll]="true"
    [style]="{width: '45vw'}">
    <div *ngIf="showPattern" class="add-project-popup add-ptn-pop">
        <div class="p-dialog-titlebar">
            <button class="p-dialog-titlebar-icon p-dialog-titlebar-close" autofocus="true" aria-label="Close Dialog"
                (click)="showPattern=false">
                <span class="pi pi-times"></span>
            </button>
        </div>
        <app-add-pattern [columnData]="columnsData" [projectId]="projectId"
            (patternEvent)="patternHandler($event)"></app-add-pattern>
    </div>
</p-dialog>

<div class="file-test-pop">
    <p-dialog [(visible)]="showFileTest" [resizable]="false" [draggable]="false" [modal]="true" [blockScroll]="true"
        (onHide)="close(true)" [style]="{width: '45vw'}">
        <div class="p-dialog-titlebar">
            <button class="p-dialog-titlebar-icon p-dialog-titlebar-close" autofocus="true" aria-label="Close Dialog"
                (click)="showFileTest=false">
                <span class="pi pi-times"></span>
            </button>
        </div>
        <ng-container *ngIf="showFileTest">
            <div class="test-popup">
                <div class="confirm-synthesis-data">
                    <h2 class="popup-title text-center">Synthesized Sample</h2>
                    <div class="row">
                        <div class="file-test">
                            <div class="col-12 mb-3"
                                *ngIf="fileTest && fileTest['source_column'] && fileTest['source_column'].length > 0">

                                <span class="table-col-box">
                                    Source Column
                                    <i class="feather ft-arrow-right mx-1"></i>
                                    <span>{{ fileTest['source_column'][0]['source_column_name'] }}</span>
                                </span>
                                <div class="row test-data">
                                    <div class="col-6">
                                        <div class="row">
                                            <div class="col-5">
                                                <h3>Before</h3>
                                            </div>
                                            <div class="col-7">
                                                <p>{{ fileTest['source_column'][0]['source_before'] }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="row test-data">
                                            <div class="col-5">
                                                <h3>Synthesized</h3>
                                            </div>
                                            <div class="col-7 d-flex flex-wrap">
                                                <p [ngClass]="{'space': item === ' ','mismatch': i < positionRange.upper}"
                                                    *ngFor="let item of  breakDown(fileTest['source_column'][0]['source_after'] ); let i = index">
                                                    {{ item}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- </div>

                        <div class="file-test"> -->
                            <div class="col-12" *ngIf="fileTest && fileTest?.dependent_col.length > 0">
                                <div *ngFor="let f of fileTest['dependent_col']">
                                    <!-- <div class="file-test"> -->
                                    <span class="table-col-box">
                                        Dependent Column
                                        <i class="feather ft-arrow-right mx-1"></i>
                                        <span>{{ f['dependent_col'] }}</span>
                                    </span>
                                    <div class="row test-data">
                                        <div class="col-6">
                                            <div class="row">
                                                <div class="col-5">
                                                    <h3>Before</h3>
                                                </div>
                                                <div class="col-7">
                                                    <p>{{ f['dep_before'] }}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="row test-data">
                                                <div class="col-5">
                                                    <h3>Synthesized</h3>
                                                </div>
                                                <div class="col-7">
                                                    <p>{{ f['dep_after'] }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </p-dialog>
</div>

<p-dialog [(visible)]="showGetKey" [resizable]="false" [draggable]="false" [modal]="true" [blockScroll]="true"
    [style]="{width: '45vw'}">
    <div *ngIf="showGetKey" class="add-project-popup add-ptn-pop">
        <div class="p-dialog-titlebar">
            <button class="p-dialog-titlebar-icon p-dialog-titlebar-close" autofocus="true" aria-label="Close Dialog"
                (click)="showGetKey=false">
                <span class="pi pi-times"></span>
            </button>
        </div>
        <div class="pop-up-title">
            <h2 class="popup-title text-center">Encryption/Decryption Key</h2>
        </div>
        <div class="design-modal modal-body edit-profile-popup add-projct-pop-up">
            <div class="row mt-2">
                <!-- <div class="col-3">
                </div> -->
                <div class="col-6">
                    <label>{{ 'Key' }}</label>
                    <input #inputField type="text" class="form-control" [placeholder]="'Enter the Key'">
                </div>
                <div class="d-flex justify-content-end dynamic-form mt-3">
                    <button uiSref="address" type="button" class=" btn btn-raised btn-warning btn-small"
                        (click)="getKey(inputField)">
                        {{ 'Save Key' }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</p-dialog>