import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CustomValidators } from 'src/app/_helpers/custom-validators';
import { Toast } from 'src/app/_helpers/toast';
import { RoleService } from 'src/app/_services/role.service';

@Component({
  selector: 'app-join-request',
  templateUrl: './join-request.component.html'
})
export class JoinRequestComponent implements OnInit, OnChanges {

  @Input() type: string = '';
  @Input() refreshMember: boolean = false;
  @Input() id: string = '';
  members: any[] = [];
  @Output() refresh = new EventEmitter<any>();
  @Output() hideJoinRequest = new EventEmitter<boolean>();

  constructor(
    private roleService: RoleService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['refreshMember']) {
      if (this.refreshMember) {
        this.fetchPendingMember();
      }
    }
  }

  ngOnInit(): void {
    this.fetchPendingMember();
  }
  getInitials(name: string): string {
    const index = name.indexOf(' ');
    if (index === -1) {
      return name.charAt(0).toUpperCase();
    }
    return name.charAt(0).toUpperCase() + name.charAt(index + 1).toUpperCase();
  }
  fetchPendingMember() {
    if (this.type === 'Project') {
      if (this.id != '') {
        this.roleService.getPendingProjectMembers(this.id).subscribe({
          next: res => {
            this.members = res;
          },
          error: err => {

          }
        })
      }
    } else if (this.type === 'Org') {
      if (this.id != '') {
        this.roleService.getPendingOrgMembers(this.id).subscribe({
          next: res => {
            this.members = res;
            if (!this.members.length) {
              this.hideJoinRequest.emit(true)
            }
          },
          error: err => {

          }
        })
      }
    }
  }

  readable(str) {
    return CustomValidators.unCamelCase(str)
  }

  approve(data) {
    if (this.type === 'Project') {
      if (data.id) {
        this.roleService.updateProjectMember(data.id, { status: "Approved" }).subscribe({
          next: res => {
            this.refresh.emit(true)
          },
          error: err => {
            Toast.fire({
              icon: 'error',
              html: err.error.error.message
            });
          }
        })
      }
    } else if (this.type === 'Org') {
      if (data.id) {
        this.roleService.updateOrgMember(data.id, { status: "Approved" }).subscribe({
          next: res => {
            this.refresh.emit(true)
          },
          error: err => {
            Toast.fire({
              icon: 'error',
              html: err.error.error.message
            });
          }
        })
      }
    }
  }

  decline(data) {
    if (this.type === "Project") {
      if (data.id) {
        this.roleService.updateProjectMember(data.id, { status: "Decline" }).subscribe({
          next: res => {
            this.refresh.emit(true)
          },
          error: err => {
            Toast.fire({
              icon: 'error',
              html: err.error.error.message
            });
          }
        })
      }
    } else if (this.type === 'Org') {
      if (data.id) {
        this.roleService.updateOrgMember(data.id, { status: "Decline" }).subscribe({
          next: res => {
            this.refresh.emit(true)
          },
          error: err => {
            Toast.fire({
              icon: 'error',
              html: err.error.error.message
            });
          }
        })
      }
    }
  }
}
