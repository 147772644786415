import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LlmService {
  private NodeAPIUrl = environment.apiAuthUrl;

  constructor(
    private http: HttpClient
  ) { }

  addLLM(body: FormData) {
    return this.http.post<any>(`${this.NodeAPIUrl}/llm`, body)
  }

  createLLMModel(params) {
    return this.http.post<any>(`${this.NodeAPIUrl}/llm-handler`, {
      method: 'POST',
      path: '/create_LLM_finetuning_model',
      body: {},
      params: params
    })
  }

  getAllLLMs(params) {
    return this.http.post<any>(`${this.NodeAPIUrl}/llm-handler`, {
      method: 'POST',
      path: '/get_all_llms',
      body: {},
      params: params
    })
  }

  deployFineTuneLLM(params) {
    return this.http.post<any>(`${this.NodeAPIUrl}/llm-handler`, {
      method: 'POST',
      path: '/deploy_llm',
      body: {},
      params: params,
      url: 'deploy'
    })
  }

  chatWithFineTuneLLM(params) {
    return this.http.post<any>(`${this.NodeAPIUrl}/llm-handler`, {
      method: 'POST',
      path: '/chat_with_fllm',
      body: {},
      params: params
    })
  }

  createTrainChatBot(body) {
    return this.http.post<any>(`${this.NodeAPIUrl}/train_new_chatbot`, body)
  }

  chatbotQuery(body) {
    return this.http.post<any>(`${this.NodeAPIUrl}/train_new_chatbot_handler`, body)
  }

  botHandler(body) {
    return this.http.post<any>(`${this.NodeAPIUrl}/bot-handler`, body)
  }

  fetchThreads(body) {
    return this.http.post<any>(`${this.NodeAPIUrl}/chat-bots`, body)
  }
}
