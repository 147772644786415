<div class="row  text-center mb-2">
    <h2 class="popup-title">{{ 'addsemanticdependent.popuptitle' | translate }}</h2>
</div>

<div class="row add-semantic-dependent">
    <div class="col-md-5 col-lg-5 col-sm-6 fields-block">
        <div class="row customer-add-table m-0">{{table && (table['tableName'] !== 'file') ? 'Tables' : 'Elements'}}
        </div>
        <div class="scrollable-content" [style.max-height]="'447px'" fxFlex="auto">

            <div *ngIf="table && table['tableName']" class="semantic-dependent">
                <ul class="table-data">
                    <div class="table-name semantic-table" *ngIf="table && (table['tableName'] !== 'file')">
                        <i (click)="showTableData = !showTableData" [tabIndex]="0"
                            [ngClass]="showTableData ? 'feather ft-chevron-down mr-1' : 'feather ft-chevron-right mr-1'"></i>
                        <h3 (click)="showTableData = !showTableData" [tabIndex]="0"
                            (keydown.enter)="showTableData = !showTableData">{{ table['tableName']}}</h3>
                    </div>
                    <div *ngIf="table && table['columns'] && table['columns'].length > 0 && showTableData">
                        <li [ngClass]="displayHighlight.includes(column) ? 'highlighted' : ''"
                            *ngFor="let column of table['columns']; index as i" (click)="columnsHandler(column)"
                            [tabindex]="i" (keydown.enter)="columnsHandler(column)">
                            {{column}}
                        </li>
                    </div>
                </ul>
            </div>
        </div>
    </div>
    <div class="col-md-7 col-lg-7 col-sm-6 px-3" *ngIf="showDropdown">
        <div class="mb-2">
            <label for="" class="label-control">{{ 'inputforms.labels.natureofdependence' | translate}}</label>
            <p-dropdown [filter]="true" styleClass="allDropdown" id="dependence" [tabIndex]="0" class="dropdown"
                [options]="options"
                [placeholder]="('inputforms.placeholder.semanticgroup' | translate) ? ('inputforms.placeholder.semanticgroup' | translate) : ' '"
                [(ngModel)]="selectValue" filterBy="name" (onChange)="dependentHandler($event)" optionLabel="name"
                optionValue="value">
            </p-dropdown>
        </div>
        <div *ngIf="showExpression">
            <label for="" class="label-control">{{ "Enter Your Expression"}}</label>
            <input type="textarea" placeholder="Enter Your Expression" class="form-control"
                (input)="expressionHandler($event)" [(ngModel)]="exp">
        </div>
    </div>


</div>
<div class="mt-2 mr-2">
    <button uiSref="address" [disabled]="dependentFields.length == 0" type="button"
        class=" btn btn-raised btn-warning float-right btn-small depentent-save" (click)="saveDependentFields()">
        {{ 'buttontitle.savebutton' | translate }}
    </button>
</div>