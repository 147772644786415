import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { zip } from 'lodash';

@Component({
  selector: 'app-rule-test',
  templateUrl: './rule-test.component.html',
})
export class RuleTestComponent implements OnInit, OnChanges {

  @Input() messageData: Object[] = [];
  dataBefore: any[] = []; // Initialize dataBefore as an empty array
  dataAfter: any[] = []; // Initialize dataAfter as an empty array
  keysBefore: any[] = []
  keysAfter: any[] = [];
  showBefore: boolean = false;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['messageData']) {
      this.csvFormat()
    }
  }

  ngOnInit(): void {

  }

  getColumnNames(): string[] {
    if (this.messageData.length > 0) {
      return Object.keys(this.messageData[0]);
    }
    return [];
  }

  csvFormat() {
      this.messageData['before'] = JSON.parse(this.messageData['before']);
      this.messageData['after'] = JSON.parse(this.messageData['after']);

      this.keysBefore = Object.keys(this.messageData['before']);
      this.dataBefore = zip(...Object.values(this.messageData['before'])).map(arr =>
        arr.reduce((obj, curr, i) => {
          obj[this.keysBefore[i]] = curr;
          return obj;
        }, {})
      );

      this.keysAfter = Object.keys(this.messageData['after']);
      this.dataAfter = zip(...Object.values(this.messageData['after'])).map(arr =>
        arr.reduce((obj, curr, i) => {
          obj[this.keysAfter[i]] = curr;
          return obj;
        }, {})
      );

  }

}
