import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-regex-field',
  templateUrl: './regex-field.component.html'
})
export class RegexFieldComponent implements OnInit, OnChanges {

  @Input() field: any = {};
  @Input() form !: FormGroup;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['field']) {
      if (this.field['value'] !== null) {
        this.form.get(this.field.name).patchValue(this.field.value);
      } else {
        // TODO document why this block is empty
      }
    }
  }

  ngOnInit(): void {
  }



  get f() {
    return this.form.controls
  }

  inValid(control: any) {
    return control.invalid && (control.dirty || control.touched);
  }

}
