import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InstantAnalyticsService {

  private NodeAPIUrl = environment.apiAuthUrl;

  constructor(
    private http: HttpClient
  ) { }

  generalAnalysis(params) {
    return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
      method: 'GET',
      path: '/autoDA/general',
      params: params
    })
  }

  univariateAnalysis(params) {
    return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
      method: 'GET',
      path: '/autoDA/univariate',
      params: params
    })
  }

  multivariateCat2CatAnalysis(params) {
    return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
      method: 'GET',
      path: '/autoDA/multivariate_cat2cat',
      params: params
    })
  }

  multivariateNum2NumAnalysis(params) {
    return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
      method: 'GET',
      path: '/autoDA/multivariate_num2num',
      params: params
    })
  }

  multivariateCat2NumAnalysis(params) {
    return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
      method: 'GET',
      path: '/autoDA/multivariate_cat2num',
      params: params
    })
  }

  getPlotsBasedOnPrompt(params) {
    return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
      method: 'GET',
      path: '/autoDA/get_plot_for_query',
      params: params
    })
  }

  multivariateDateTime2NumAnalysis(params) {
    return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
      method: 'GET',
      path: '/autoDA/multivariate_datetime2num',
      params: params
    })
  }

  multivariateDateTime2CatAnalysis(params) {
    return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
      method: 'GET',
      path: '/autoDA/multivariate_datetime2cat',
      params: params
    })
  }

  fetchInsights(params) {
    return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
      method: 'GET',
      path: '/autoDA/overall_insight',
      params: params
    })
  }

  getColumnTypesForAnalytics(params) {
    return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
      method: 'GET',
      path: '/autoDA/get_column_types',
      params: params
    })
  }

  fetchChartsBasedInsights(params) {
    return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
      method: 'GET',
      path: '/autoDA/get_plot_for_insight',
      params: params
    })
  }

  addDashboard(data) {
    return this.http.post<any>(`${this.NodeAPIUrl}/analytix/dashboard`, data)
  }

  fetchDashboard(id) {
    return this.http.get<any>(`${this.NodeAPIUrl}/dashboard/${id}`)
  }

  fetchCharts(body) {
    return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
      method: 'POST',
      path: '/autoDA/get_user_selected_plot_for_insight',
      body: body
    })
  }

  generatePlot(body, params) {
    return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
      method: 'POST',
      path: '/autoDA/generate_plot',
      body: body,
      params: params
    })
  }

  async generatePlotAsync(body, params) {
    return new Promise(resolve => {
      this.generatePlot(body, params).subscribe({
        next: result => resolve(result),
        error: err => resolve(err)
      })
    })
  }

  async avaliableChartTypesAsync(params) {
    return new Promise(resolve => {
      this.fetchChartsBasedInsights(params).subscribe({
        next: result => resolve(result),
        error: err => resolve(err)
      })
    })
  }

  fetchAnalysis(body) {
    return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
      method: 'POST',
      path: '/autoDA/analysis_based_on_selection',
      body: body
    })
  }
}
