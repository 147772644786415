import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Toast } from 'src/app/_helpers/toast';
import { AuthService } from 'src/app/_services/auth.service';
import { DbConnectionsService } from 'src/app/_services/db-connections.service';

@Component({
    selector: 'app-configure-db',
    templateUrl: './configure-db.component.html'
})
export class ConfigureDbComponent implements OnInit, OnChanges {

    configureDBForm: FormGroup;
    driverOptions: Object[] = [
        { name: 'MYSQL', value: 'mysql' },
        { name: 'MSSQL', value: 'mssql' },
        { name: 'Oracle', value: 'oracle' },
        { name: 'PostgreSQL', value: 'postgresql' },
        { name: 'IBM DB2', value: 'ibmdb2' },
        { name: 'Salesforce', value: 'salesforce' }
    ];
    disableSave: boolean = true;
    @Output() dbConfigurationsEmitter = new EventEmitter<Object>()
    @Input() dbDetails: any;
    @Input() clear: boolean;
    @Input() projectId: string = '';
    submitted = false;

    constructor(
        private dbConnectionsService: DbConnectionsService,
        private authService: AuthService
    ) {
        this.configureDBForm = new FormGroup({
            driver_name: new FormControl(null),
            database_name: new FormControl(null),
            connection_name: new FormControl(null),
            host_name: new FormControl(null),
            port_no: new FormControl(null),
            username_name: new FormControl(null, Validators.required),
            password: new FormControl(null, Validators.required),
            security_token: new FormControl(null)
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['dbDetails']) {
            if (this.dbDetails && this.dbDetails['id']) {
                this.configureDBForm.patchValue(this.dbDetails);
                this.disableSave = true;
            }
        }

        if (changes['clear']) {
            if (this.clear) {
                this.dbDetails = null;
                this.configureDBForm.reset();
            }
        }
    }

    ngOnInit(): void {
        this.dbDetails = null;
    }

    formEdit() {
        this.disableSave = true;
    }

    dbTestConnection() {
        if (this.configureDBForm.invalid) {
            this.configureDBForm.markAllAsTouched();
            return;
        }
        if (this.projectId != '') {
            let body = Object.assign({}, this.configureDBForm.value);
            body['project_id'] = this.projectId
            const userDetails = this.authService.currentUserValue;
            body['user_id'] = userDetails.id;
            body['org_id'] = userDetails.orgId;
            console.log('test', body)
            this.dbConnectionsService.testConnection(body).subscribe({
                next: res => {
                    if (res['message'] && res['message'] === "connection tested successfully") {
                        this.disableSave = false;
                        Toast.fire({
                            icon: 'success',
                            html: 'Connection Tested Successfully'
                        });
                    } else {
                        Toast.fire({
                            icon: 'error',
                            title: 'Error',
                            html: res['message']
                        });
                    }
                },
                error: err => {
                    Toast.fire({
                        icon: 'error',
                        html: err.error.text
                    });
                }
            })
        }
    }

    saveDBConnection() {
        if (this.dbDetails && this.dbDetails['id']) {
            Object.assign(this.dbDetails, this.configureDBForm.value)
            this.dbConnectionsService.updateDBConnection(this.dbDetails).subscribe({
                next: (res) => {
                    // connection successfully updated
                    this.dbConfigurationsEmitter.emit(res);
                    this.disableSave = true;

                },
                error: (err) => {
                    Toast.fire({
                        icon: 'error',
                        html: err.error.text
                    });
                }
            })
        } else {
            if (this.projectId != '') {
                let body = Object.assign({}, this.configureDBForm.value);
                body['project_id'] = this.projectId
                let userDetails = this.authService.currentUserValue;
                body['user_id'] = userDetails.id;;
                body['org_id'] = userDetails.orgId;
                if (body['driver_name'] == 'salesforce') {
                    body['database_name'] = "-";
                    body['host_name'] = "-";
                }
                console.log('save', body)
                this.dbConnectionsService.addDBConnection(body).subscribe({
                    next: (res) => {
                        this.dbConfigurationsEmitter.emit(res)
                        this.disableSave = true;
                    },
                    error: (err) => {
                        Toast.fire({
                            icon: 'error',
                            html: err.error.text
                        });
                    }
                })
            }
        }
    }

}
