<h2 class="popup-title text-center">{{ 'editprofile.header2popuptitle' | translate }}</h2>
<div class="design-modal modal-body edit-profile-popup">

    <form [formGroup]="profileForm" class="idForm"
        aria-label="'profile Form All required input feilds are denoted by star'">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <h4 class="label-title"> {{ 'userprofile.title3org' | translate }} </h4>
                    <p>{{orgData['name']}}</p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <h4 class="label-title"> {{ 'userprofile.title3email' | translate }} </h4>
                    <p>{{userData['email']}}</p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label> {{ 'inputforms.labels.name' | translate }}<span class="mendatory">*</span></label>
                    <input id="txtFirstName" type="text" pInputText class="form-control"
                        [placeholder]="'inputforms.placeholder.name' | translate" formControlName="firstName"
                        [ngClass]="{'is-invalid': f.firstName.errors}" aria-label="'Please enter your Name *'">
                    <ng-container *ngIf="f.firstName.touched && f.firstName.invalid">
                        <span *ngIf="f.firstName.errors.required" class="p-error">{{
                            'inputforms.formerrors.requiredname' | translate }}
                        </span>
                    </ng-container>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label> {{ 'inputforms.labels.phone' | translate }}<span class="mendatory">*</span></label>
                    <input id="txtPhoneNo" type="text" pInputText class="form-control"
                        [placeholder]="'inputforms.placeholder.phone' | translate" formControlName="phoneNo"
                        [ngClass]="{'is-invalid': f.phoneNo.errors}" aria-label="'Please enter your Name *'">
                    <ng-container *ngIf="f.phoneNo.touched && f.phoneNo.invalid">
                        <span *ngIf="f.phoneNo.errors.required" class="p-error">{{
                            'inputforms.formerrors.requiredphone' | translate }}
                        </span>
                    </ng-container>
                </div>
            </div>
            <div class="col-md-12 text-right">
                <button id="btnUpdateProfil" type="button" class="btn btn-raised btn-warning btn-small"
                    (click)="updateProfile()">
                    <span *ngIf="isLoading" class="load-text">{{ 'buttontitle.updatingbutton' | translate }}</span>
                    <span *ngIf="!isLoading" class="btn-text">{{ 'buttontitle.savebutton' | translate }}</span>
                </button>
            </div>
        </div>
    </form>
</div>