import { Component, EventEmitter, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-configure-api-tool',
  templateUrl: './add-configure-api-tool.component.html',
})
export class AddConfigureApiToolComponent {

  toolName;
  toolDescription;
  apiEndpoint;
  apiMethods = [
    {name: 'GET', value: 'GET'},
    {name: 'POST', value: 'POST'},
    {name: 'PUT', value: 'PUT'},
    {name: 'PATCH', value: 'PATCH'},
    {name: 'DELETE', value: 'DELETE'},
  ]
  paramsForm: FormGroup;
  callType;
  @Output() apiEmitter = new EventEmitter();

  constructor (
    private fb: FormBuilder
  ) {
    this.paramsForm = this.fb.group({
      params: this.fb.array([])
    });
  }

  get params() {
    return this.paramsForm.get('params') as FormArray;
  }

  addParam() {
    const paramGroup = this.fb.group({
      key: ['', Validators.required],
      description: ['', Validators.required]
    });
    this.params.push(paramGroup);
  }

  removeParam(index: number) {
    this.params.removeAt(index);
  }

  shouldShowCancelButton(index: number): boolean {
    const param = this.params.at(index);
    return param.get('key')?.value && param.get('description')?.value;
  }

  saveAPITool() {
    console.log(this.paramsForm.value);
    this.apiEmitter.emit({
      toolName: this.toolName,
      toolDescription: this.toolDescription,
      apiEndpoint: this.apiEndpoint,
      callType: this.callType,
      params : this.paramsForm.value['params']
    })
  }
}
