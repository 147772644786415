import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-org-model-setup',
  templateUrl: './org-model-setup.component.html',
  styleUrls: ['./org-model-setup.component.scss']
})

export class OrgModelSetupComponent {
  setupJobForm: FormGroup;
  createnewmodel: boolean;

  constructor(
    private formBuilder: FormBuilder,
  ) {
    this.setupJobForm = this.formBuilder.group({
      selectpolicy: new FormControl(null),
      selectworkspace: new FormControl(null),
      finetunepurpose: new FormControl(null),
      charragpurpose: new FormControl(null)
    });
  }

  addnewModal() {
    this.createnewmodel = true;
  }

}
