import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Toast } from "src/app/_helpers/toast";
import { AuthService } from "src/app/_services/auth.service";
import { CustomStrategyService } from "src/app/_services/custom-strategy.service";
import { SyntheticDataService } from "src/app/_services/synthetic-data.service";
import { PatternService } from "src/app/_services/pattern.service";
import {
  customStrategyDefaultSchema,
  enUS,
  enZA,
  customStrategyFixedSchema,
  strategySchema,
  customStrategyVariableSchema,
  loc,
} from "../../../_helpers/schemas";



@Component({
  selector: "app-custom-synthesis-pattern",
  templateUrl: "./custom-synthesis-pattern.component.html",
})
export class CustomSynthesisPatternComponent implements OnInit, OnChanges {
  @Input() jobDetails: Object = {};
  @Input() finalColumnsData = [];
  checked: boolean = false;
  topRecords: string[] = [];
  fieldLength: string = "fixed";
  customStrategyForm: FormGroup;
  categories: Object[] = [
    { name: "fixed", key: "F" },
    { name: "variable", key: "V" },
  ];
  showStrategyHandler: boolean;
  public formSchema: Object[] = [];
  showIndex: number = 0;
  @Input() selectedColumnDetails;
  formIndex: number = 0;
  tabs: Object[] = [];
  positionForm: Object[][] = [];
  variableForm: Object[][] = [];
  @Input() projectId: string = "";
  strategySchema: Object[] = [];
  strategyHandlerStatus: string = "";
  selectedAssignValue: string = "";
  selectedColumn: string = "";
  columnsData: Object[] = [];
  @Output() jobColumns = new EventEmitter<Object[]>();
  splitArray: string[] = [];
  strategies: Object[] = [];
  showTest: boolean = false;
  confirmSyntheticData: Object = {};
  @Output() clearColumn = new EventEmitter<Object>();
  showPattern: boolean = false;
  @Output() patternsData = new EventEmitter<Object>();
  patternList: Object[] = [];
  selectPattern: string = null;
  semanticGroupStrategies: Object = {};
  patternData: Object[] = [];
  disabledTest: boolean = false;
  @Input() PIIData: Object[] = [];
  @Input() fileJob: {};
  showFileTest: boolean = false;
  fileTest: {} = {};
  setFocusAddNewPattern: boolean = false;
  setFocusTestButton: boolean = false;
  clearDependentField: boolean = false;
  @Output() dependentFieldEmitter = new EventEmitter<number>();
  @Output() checkedStatusChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  disableTokenize: boolean = false;
  positionRange: Object = {};
  numCatStrat: Object[] = [];
  sk: string = null;
  showGetKey: boolean = false;

  constructor(
    private syntheticDataService: SyntheticDataService,
    private customStrategy: CustomStrategyService,
    private authService: AuthService,
    private patternService: PatternService,
    private cdr: ChangeDetectorRef,
  ) {
    this.customStrategyForm = new FormGroup({
      tokenize: new FormControl(false, Validators.required),
      category: new FormControl("fixed"),
      delimiter: new FormControl(null),
      pattern: new FormControl(null),
    });
  }


  async ngOnChanges(changes: SimpleChanges) {
    if (changes["selectedColumnDetails"]) {
      this.topRecords = [];
      this.customStrategyForm.patchValue({ delimiter: null });
      this.splitArray = [];
      this.getRecords();
      this.checked = false;
      this.fieldLength = 'fixed'
      await this.fetchNumCatStrategies();
      this.selectedColumn = "";
      this.columnsData = [];
      // Need to be changed
    }
  }

  async ngOnInit(): Promise<void> {
    this.fetchPatterns();
    await this.fetchNumCatStrategies();
    // this.fetchStrategiesBySemantic();
  }

  async patchFormValues() {
    for (let i = 0; i < this.finalColumnsData.length; i++) {
      let record = this.finalColumnsData[i];
      if (
        this.selectedColumnDetails["selectedColumn"] == record["source_column"]
      ) {
        if (record["columnlet_type"] == "NA") {
          this.checked = false;
          this.customStrategyForm.get("tokenize").patchValue(false);
          this.formSchema = customStrategyDefaultSchema.map((obj) => ({
            ...obj,
          }));
          // this.strategies.forEach(r => {
          //   this.formSchema[1]['options'].push({
          //     name: r['strategy_name'],
          //     value: r['id'],
          //     id: r['id']
          //   })
          // })
          if (this.jobDetails["locale"] === "en_US") {
            this.formSchema[0]["options"] = enUS.map((obj) => ({ ...obj }));
          } else if (this.jobDetails["locale"] == "en_ZA") {
            this.formSchema[0]["options"] = enZA.map((obj) => ({ ...obj }));
          } else {
            this.formSchema[0]["options"] = loc.map((obj) => ({ ...obj }));
          }
          this.formSchema[0]["value"] = record["columnlets_data"][0]["semantic_group"];
          this.formSchema[1]["value"] = record["columnlets_data"][0]["strategy_id"];
          this.formSchema[2]["value"] = record["columnlets_data"][0]["dependent_fields"];
          await this.fetchStrats(
            record["columnlets_data"][0]["semantic_group"],
            this.formSchema[1]
          );
          const currentUser = this.authService.currentUserValue;
          let st = await this.customStrategy.getStrategiesAsync({ isbias: true, locale: this.jobDetails["locale"], project_id: this.projectId, org_id: currentUser.orgId, id: record["columnlets_data"][0]["strategy_id"] })
          if (["NoGAN", "Transformer Neural Network", "CopulaGAN Neural Network", "TVAE Neural Network", "CTGAN Neural Network"].includes(st[0]['strategy_name'])) {
            this.formSchema[3]['name'][1]['disabled'] = true;
          } else {
            this.formSchema[3]['name'][1]['disabled'] = false;
          }
          this.formSchema[2]["options"] = this.selectedColumnDetails;
          this.formSchema[3]['name'][2]['disabled'] = true;
          let semType = record["columnlets_data"][0]["semantic_group"];
          if (
            semType == "name" ||
            semType == "address" ||
            semType == "street_name" ||
            semType == "city" ||
            semType == "state" ||
            semType == "random_number" ||
            semType == "random_letters" ||
            semType == "date" ||
            semType == "credit_card" ||
            semType == "list" ||
            semType == "phone_number"
          ) {
            this.showAddNewStrategy();
          } else {
            this.hideAddNewStrategy();
          }
        } else if (record["columnlet_type"].toLowerCase() == "fixed") {
          this.patchFixedSchema(record);
        } else if (record["columnlet_type"].toLowerCase() == "variable") {
          this.patchVariableSchema(record, record["delimiter_value"]);
        } else {
          // TODO: check
        }
        break;
      } else {
        this.checked = false;
        this.positionForm = [];
        this.variableForm = [];
        this.tabs = [];
        this.customStrategyForm.get("tokenize").patchValue(false);
        this.customStrategyForm.get("category").patchValue("fixed");
        this.fieldLength = "fixed";
      }
    }
  }

  async assignSemanticsOptions() {
    this.formSchema = customStrategyDefaultSchema.map((obj) => ({ ...obj }));
    if (this.jobDetails["locale"] === "en_US") {
      this.formSchema[0]["options"] = enUS.map((obj) => ({ ...obj }));
    } else if (this.jobDetails["locale"] == "en_ZA") {
      this.formSchema[0]["options"] = enZA.map((obj) => ({ ...obj }));
    } else {
      this.formSchema[0]["options"] = loc.map((obj) => ({ ...obj }));
    }
    if (Array.isArray(this.finalColumnsData) && this.finalColumnsData.findIndex(
      (obj) =>
        obj["source_column"] == this.selectedColumnDetails["selectedColumn"]
    ) == -1
    ) {
      if (Array.isArray(this.PIIData) && this.PIIData.length > 0) {
        let index = this.PIIData.findIndex(
          (r) =>
            r["column_name"].toLowerCase() ==
            this.selectedColumnDetails["selectedColumn"].toLowerCase()
        );
        if (this.PIIData && this.PIIData[index] && this.PIIData[index]["semantic_type"]) {
          this.formSchema[0]["value"] = this.PIIData[index]["semantic_type"];
          this.selectedAssignValue = this.PIIData[index]["semantic_type"];
          await this.fetchStrategiesBasedAssignSemantics(
            this.PIIData[index]["semantic_type"]
          );
        }
      }
    }
    this.formSchema[2]["options"] = this.selectedColumnDetails;
  }

  handleDependentFieldEvent(changed) {
    if (this.formSchema.length) {
      this.formSchema[3]["name"][2]["disabled"] = false;
    }
  }

  patchStrategy() {
    if (Array.isArray(this.PIIData) && this.PIIData.length > 0) {
      let index = this.PIIData.findIndex(
        (r) =>
          r["column_name"].toLowerCase() ==
          this.selectedColumnDetails["selectedColumn"].toLowerCase()
      );
      if (
        this.formSchema &&
        this.formSchema[1] &&
        this.formSchema[1]["options"].length > 0
      ) {
        for (let i = 0; i < this.formSchema[1]["options"].length; i++) {
          if (
            this.formSchema[1]["options"][i]["name"] ==
            this.PIIData[index]["semantic_strategy"]
          ) {
            let update = false;
            if (
              Array.isArray(this.finalColumnsData) &&
              this.finalColumnsData.length > 0
            ) {
              for (let j = 0; j < this.finalColumnsData.length; j++) {
                if (
                  this.finalColumnsData[j]["source_column"] ===
                  this.selectedColumnDetails["selectedColumn"]
                ) {
                  // Nothing updated here
                  update = true;
                  break;
                }
              }
              if (!update) {
                this.formSchema[1]["value"] =
                  this.formSchema[1]["options"][i]["value"];
                this.formSchema[3]["name"][2]["disabled"] = false;
                let indexValidator = -1;
                if (Array.isArray(this.numCatStrat)) {
                  for (let j = 0; j < this.numCatStrat.length; j++) {
                    if (
                      this.formSchema[1]["value"] == this.numCatStrat[j]["id"]
                    ) {
                      indexValidator = 1;
                      break;
                    }
                  }
                }
                if (indexValidator == 1) {
                  this.formSchema[3]["name"][1]["disabled"] = true;
                } else {
                  this.formSchema[3]["name"][1]["disabled"] = false;
                }
                update = false;
              }
            } else {
              this.formSchema[1]["value"] =
                this.formSchema[1]["options"][i]["value"];
              this.formSchema[3]["name"][2]["disabled"] = false;
              let indexValidator = -1;
              if (Array.isArray(this.numCatStrat)) {
                for (let j = 0; j < this.numCatStrat.length; j++) {
                  if (
                    this.formSchema[1]["value"] == this.numCatStrat[j]["id"]
                  ) {
                    indexValidator = 1;
                    break;
                  }
                }
              }
              if (indexValidator == 1) {
                this.formSchema[3]["name"][1]["disabled"] = true;
              } else {
                this.formSchema[3]["name"][1]["disabled"] = false;
              }
            }
          }
        }
      }
    }
  }

  getRecords() {
    this.showFileTest = false;
    if (this.fileJob && this.fileJob["job_id"]) {
      this.syntheticDataService
        .getTopRecordsFile({
          column: this.selectedColumnDetails["selectedColumn"],
          job_id: this.fileJob["job_id"],
        })
        .subscribe({
          next: (res) => {
            this.topRecords = res["top_records"];
            if (Array.isArray(this.topRecords) && this.topRecords.length == 0) {
              this.disableTokenize = true;
              this.cdr.detectChanges();
            } else {
              this.disableTokenize = false;
              this.cdr.detectChanges();
            }
            this.selectPattern = null;
            this.disabledTest = true;
            this.customStrategyForm.get("pattern").patchValue(null);
            this.assignSemanticsOptions();
            this.customStrategyForm.get("tokenize").patchValue(false);
            if (this.finalColumnsData.length > 0) {
              this.patchFormValues();
            } else {
              this.checked = false;
            }
          },
        });
    } else {
      this.syntheticDataService
        .getTopRecords({
          connection_id: this.jobDetails["selectSource"]["id"],
          schema_name: this.jobDetails["selectSchema"],
          table_name: this.selectedColumnDetails["table"]["tableName"],
          column_name: this.selectedColumnDetails["selectedColumn"],
        })
        .subscribe({
          next: (res) => {
            if (res && !res["message"]) {
              this.selectPattern = null;
              this.disabledTest = true;
              this.customStrategyForm.get("pattern").patchValue(null);
              if (res && res[this.selectedColumnDetails["selectedColumn"]]) {
                this.topRecords = Object.values(
                  res[this.selectedColumnDetails["selectedColumn"]]
                );
              }
              this.assignSemanticsOptions();
              this.customStrategyForm.get("tokenize").patchValue(false);
              if (this.finalColumnsData.length > 0) {
                this.patchFormValues();
              } else {
                this.checked = false;
              }
            } else {
              Toast.fire({
                icon: "error",
                html: "Fetching top 10 records is failed",
              });
            }
          },
          error: (err) => {
            Toast.fire({
              icon: "error",
              html: err.error.text,
            });
          },
        });
    }
  }

  validate(data) {
    if (data.toLowerCase() == "fixed") {
      return Array.isArray(this.positionForm[this.formIndex]) &&
        this.positionForm[this.formIndex].length > 0
        ? true
        : false;
    } else {
      return Array.isArray(this.variableForm[this.formIndex]) &&
        this.variableForm[this.formIndex].length > 0
        ? true
        : false;
    }
  }

  breakDown(data) {
    let arr = [];
    Array.from(data.toString()).forEach((r) => arr.push(r));
    return arr;
  }

  slideData(index, slide) {
    if (slide === "prev") {
      this.showIndex = index === 0 ? 0 : index - 1;
    } else if (slide === "next") {
      this.showIndex = index === 9 ? 0 : index + 1;
    }
  }

  nonTokenize(event) {
    Toast.fire({
      icon: "success",
      html: `Column configuration saved`,
    });
    let obj = {
      columnlet_name: null,
      semantic_group: event["value"]["assignSemantics"]
        ? event["value"]["assignSemantics"]
        : null,
      strategy_id: event["value"]["synthesisStrategy"]
        ? event["value"]["synthesisStrategy"]
        : null,
      position_range_start: null,
      position_range_end: null,
      variable_position: null,
      sk: this.sk ? this.sk : '',
      dependent_fields: event["value"]["dependentField"]
        ? event["value"]["dependentField"]
        : null,
    };
    this.formSchema[3]["name"][2]["disabled"] = true;
    this.jobColumnHandler(obj, "NA");
  }

  getKey(inputField) {
    this.sk = inputField.value;
    this.showGetKey = false;
  }

  async checkedStatus() {
    this.columnsData = [];
    this.checked = !this.checked;
    if (this.checked) {
      // await this.formDecider(null)
    } else {
      this.assignSemanticsOptions();
    }
    this.checkedStatusChange.emit(this.checked);
  }

  async fieldLengthStatus(status) {
    this.clearDependentField = false;
    status.value ? (this.fieldLength = status.value) : (this.fieldLength = "");
    this.columnsData = [];
    if (status.value == "fixed") {
      this.tabs = [];
      this.variableForm = [];
      if (!(status && status.reload)) {
        this.formDecider(null);
      }
    } else {
      this.tabs = [];
      this.positionForm = [];
    }
  }

  async formDecider(data) {
    if (this.checked && this.fieldLength.toLowerCase() === "fixed") {
      let arr = customStrategyFixedSchema.map((obj) => ({ ...obj }));
      if (this.tabs.length == 0) {
        this.tabs.push({
          label: `Columnlet ${this.positionForm.length + 1}`,
          value: this.positionForm.length,
        });
        arr[1]["lowerValue"] = 1;
        arr[1]["upperValue"] = this.topRecords[0].length;
        if (this.jobDetails["locale"] === "en_US") {
          // TODO: check
        } else if (this.jobDetails["locale"] === "en_ZA") {
          // TODO: check
        }
        arr[4]["options"] = this.selectedColumnDetails;
        this.positionForm.push([...arr]);
        this.formIndex = 0;
        if (data) {
          if (
            data["upper"] &&
            data["lower"] &&
            data["upper"] >= data["lower"]
          ) {
            this.tokenize(data, "fixed");
          } else {
            Toast.fire({
              icon: "warning",
              title: "Warning",
              html: "Position Range Upper Value Should be Greater Than the Lower Value",
            });
            return;
          }
        }
      } else {
        if (
          this.tabs.length === this.positionForm.length &&
          this.tabs[this.formIndex]["label"] ===
          this.positionForm[this.formIndex][0]["value"]
        ) {
          // Modify the position range validations
          if (
            this.positionForm[this.formIndex][1]["upperValue"] <=
            this.topRecords[0].length &&
            this.positionForm[this.formIndex][1]["lowerValue"] <=
            this.topRecords[0].length
          ) {
            if (data) {
              if (
                data["upper"] &&
                data["lower"] &&
                data["upper"] >= data["lower"]
              ) {
                this.tokenize(data, "fixed");
              } else {
                Toast.fire({
                  icon: "warning",
                  title: "Warning",
                  html: "Position Range Upper Value Should be Greater Than the Lower Value",
                });
                return;
              }
            }
            if (this.positionForm.length - this.formIndex == 1) {
              if (
                !(
                  this.positionForm[this.formIndex][1]["upperValue"] + 1 >
                  this.topRecords[0].length
                )
              ) {
                arr[1]["lowerValue"] =
                  this.positionForm[this.formIndex][1]["upperValue"] + 1;
                arr[1]["upperValue"] = this.topRecords[0].length;
                arr[2]["value"] = null;
                if (this.jobDetails["locale"] === "en_US") {
                  // TODO: check
                } else if (this.jobDetails["locale"] === "en_ZA") {
                  // TODO: check
                }
                arr[4]["options"] = this.selectedColumnDetails;
                this.tabs.push({
                  label: `Columnlet ${this.positionForm.length + 1}`,
                  value: this.positionForm.length,
                });
                this.positionForm.push([...arr]);
                this.formIndex = this.positionForm.length - 1;
              }
            }
          } else {
            Toast.fire({
              icon: "warning",
              title: "Warning",
              html: "Position Range Exceeds the Length of Data",
            });
            return;
          }
        } else {
          this.tokenize(data, "fixed");
        }
        // postion range should validate here
      }
    } else if (this.checked && this.fieldLength.toLowerCase() === "variable") {
      if (data) {
        this.tokenize(data, "variable");
        if (this.formIndex < this.variableForm.length - 1) {
          this.formIndex += 1;
        } else {
          this.formIndex = 0;
        }
      }
    }
  }

  async fixedTokenize(event) {
    if (event?.value) {
      this.positionForm[this.formIndex].forEach((record) => {
        if (record["name"] == "columnlet") {
          record["value"] = event["value"]["columnlet"];
        } else if (record["type"] == "range") {
          record["lowerValue"] = event["value"]["lower"];
          record["upperValue"] = event["value"]["upper"];
        } else if (record["name"] == "assignSemantics") {
          record["value"] = event["value"]["assignSemantics"];
        } else if (record["name"] == "synthesisStrategy") {
          record["value"] = event["value"]["synthesisStrategy"];
        } else if (record["name"] == "dependentField") {
          record["value"] = event["value"]["dependentField"];
        }
      });
    }
    this.tabs[this.formIndex]["label"] = event["value"]["columnlet"];
    await this.formDecider(event.value);
    Toast.fire({
      icon: "success",
      html: `Columnlet configuration saved`,
    });
    this.positionForm[this.formIndex][5]['name'][2]['disabled'] = true;
  }

  async variableTokenize(event) {
    if (event.value) {
      this.variableForm[this.formIndex].forEach((record) => {
        if (record["name"] == "columnlet") {
          record["value"] = event["value"]["columnlet"];
        } else if (record["name"] == "assignSemantics") {
          record["value"] = event["value"]["assignSemantics"];
        } else if (record["name"] == "synthesisStrategy") {
          record["value"] = event["value"]["synthesisStrategy"];
        } else if (record["name"] == "dependentField") {
          record["value"] = event["value"]["dependentField"];
        }
      });
    }
    this.tabs[this.formIndex]["label"] = event["value"]["columnlet"];
    await this.formDecider(event.value);
    Toast.fire({
      icon: "success",
      html: `Columnlet configuration saved`,
    });
    this.variableForm[this.formIndex][4]['name'][2]['disabled'] = true;
  }

  tokenize(data, type) {
    let obj = {
      columnlet_name: data["columnlet"],
      semantic_group: data["assignSemantics"],
      strategy_id: data["synthesisStrategy"],
      position_range_start: data["lower"] ? data["lower"] : null,
      position_range_end: data["upper"] ? data["upper"] : null,
      variable_position: type == "variable" ? this.formIndex + 1 : null,
      dependent_fields: data["dependentField"] ? data["dependentField"] : null,
      sk: ''
    };
    /* Disabling the button
     if (type == 'fixed') {
       this.positionForm[this.formIndex][5]['name'][2]['disabled'] = true;
     } else {
       this.variableForm[this.formIndex][4]['name'][2]['disabled'] = true;
     }
     */
    this.jobColumnHandler(obj, type);
  }

  tabDecider(i) {
    this.formIndex = i;
  }

  emitterHandler(r) {
    /* Disabling the button
    if (this.positionForm.length > 0) {
      this.positionForm[this.formIndex][5]['name'][2]['disabled'] = true;
    }
    if (this.variableForm.length > 0) {
      this.variableForm[this.formIndex][4]['name'][2]['disabled'] = true;
    } 
    */
    if (r["synthesisStrategy"]) {
      // this.selectedStrategy(r)
      if (r['selected'] && r['selected']['name'] && (r['selected']['name'] == 'AES-GCM(256 bit) Decryption' || r['selected']['name'] == 'AES-GCM(256 bit) Encryption')) {
        this.showGetKey = true;
      } else {
        this.sk = null
      }

    } else if (r["assignSemantic"]) {
      if (this.formSchema && this.formSchema[0]) {
        this.formSchema[0]["value"] = r["value"];
      }
      this.fetchStrategiesBasedAssignSemantics(r["value"]);
    } else if (r["new"]) {
      this.newStrategyFormSchemaBuilder();
    }
    if (this.formSchema.length > 0) {
      this.formSchema[3]["name"][2]["disabled"] = false;
      if ((r["refresh"] && r["value"]) || r["synthesisStrategy"]) {
        let arr = this.formSchema[1]["options"];
        let res = {};
        for (const obj of arr) {
          if (obj?.value === r["value"]) {
            res = obj;
          }
        }
        if (
          res["name"] == "CTGAN Neural Network" ||
          res["name"] == "TVAE Neural Network" ||
          res["name"] == "CopulaGAN Neural Network" ||
          res["name"] == "Transformer Neural Network" ||
          res["name"] == "NoGAN"
        ) {
          this.formSchema[3]["name"][1]["disabled"] = true;
        } else {
          this.formSchema[3]["name"][1]["disabled"] = false;
        }
      }
    }
    if (Array.isArray(this.positionForm) && this.positionForm.length && this.positionForm[this.formIndex]) {
      this.positionForm[this.formIndex][5]['name'][2]['disabled'] = false;
    }
    if (Array.isArray(this.variableForm) && this.variableForm.length && this.variableForm[this.formIndex]) {
      this.variableForm[this.formIndex][4]['name'][2]['disabled'] = false;
    }
  }

  async fetchNumCatStrategies() {
    const currentUser = this.authService.currentUserValue;
    let arr = ["numeric", "categorical"];
    this.numCatStrat = [];
    for (let i = 0; i < arr.length; i++) {
      let paramsAsync = {};
      if (this.fileJob && this.fileJob["job_id"]) {
        paramsAsync = {
          semantic_group: arr[i],
          org_id: currentUser.orgId,
          user_id: currentUser.id,
          locale: this.jobDetails["locale"],
          job_id: this.fileJob["job_id"],
          source_column: this.selectedColumnDetails["selectedColumn"],
        };
      } else {
        paramsAsync = {
          semantic_group: arr[i],
          org_id: currentUser.orgId,
          user_id: currentUser.id,
          locale: this.jobDetails["locale"],
          connection_id: this.jobDetails["selectSource"]["id"],
          source_schema: this.jobDetails["selectSchema"],
          source_table: this.selectedColumnDetails["table"]["tableName"],
          source_column: this.selectedColumnDetails["selectedColumn"],
        };
      }
      let data = await this.customStrategy.getStrategiesAsync(paramsAsync);
      this.numCatStrat = this.numCatStrat.concat(data);
    }
  }

  async fetchStrategiesBasedAssignSemantics(data) {
    this.selectedAssignValue = data;
    if (
      this.selectedAssignValue == "name" ||
      this.selectedAssignValue == "address" ||
      this.selectedAssignValue == "street_name" ||
      this.selectedAssignValue == "city" ||
      this.selectedAssignValue == "state" ||
      this.selectedAssignValue == "random_number" ||
      this.selectedAssignValue == "random_letters" ||
      this.selectedAssignValue == "date" ||
      this.selectedAssignValue == "credit_card" ||
      this.selectedAssignValue == "list" ||
      this.selectedAssignValue == "phone_number" ||
      this.selectedAssignValue == "data_privacy"
    ) {
      this.showAddNewStrategy();
    } else {
      this.hideAddNewStrategy();
    }
    const currentUser = this.authService.currentUserValue;
    let params = {};
    if (this.fileJob && this.fileJob["job_id"]) {
      params = {
        semantic_group: data,
        org_id: currentUser.orgId,
        user_id: currentUser.id,
        locale: this.jobDetails["locale"],
        job_id: this.fileJob["job_id"],
        source_column: this.selectedColumnDetails["selectedColumn"],
      };
    } else {
      params = {
        semantic_group: data,
        org_id: currentUser.orgId,
        user_id: currentUser.id,
        locale: this.jobDetails["locale"],
        connection_id: this.jobDetails["selectSource"]["id"],
        source_schema: this.jobDetails["selectSchema"],
        source_table: this.selectedColumnDetails["table"]["tableName"],
        source_column: this.selectedColumnDetails["selectedColumn"],
      };
    }
    this.customStrategy.getStrategies(params).subscribe({
      next: async (res) => {
        this.strategies = res;
        this.semanticGroupStrategies[data] = this.strategies;
        if (this.fieldLength.toLowerCase() == "fixed" && this.checked) {
          this.positionForm[this.formIndex][3]["options"] = [];
          res.forEach((r) => {
            this.positionForm[this.formIndex][3]["options"].push({
              name: r.strategy_name,
              value: r.id,
            });
          });
        } else if (this.fieldLength.toLowerCase() == "variable" && this.checked) {
          this.variableForm[this.formIndex][2]["options"] = [];
          res.forEach((r) => {
            this.variableForm[this.formIndex][2]["options"].push({
              name: r.strategy_name,
              value: r.id,
              id: r.id,
            });
          });
        } else {
          this.formSchema[1]["options"] = [];
          let stratArr = [];
          let exist: boolean = false;
          for (let p = 0; p < res.length; p++) {
            let r = res[p];
            if (Array.isArray(this.finalColumnsData) && this.finalColumnsData.length > 0 && (data == "numeric" || data == "categorical")) {
              for (let i = 0; i < this.finalColumnsData.length; i++) {
                if (this.finalColumnsData[i]["columnlet_type"] == "NA") {
                  if ((r["type"] == "numeric" || r["type"] == "categorical") &&
                    [
                      "NoGAN",
                      "Transformer Neural Network",
                      "CopulaGAN Neural Network",
                      "TVAE Neural Network",
                      "CTGAN Neural Network",
                    ].includes(r["strategy_name"])
                  ) {
                    let index = -1;
                    if (Array.isArray(this.numCatStrat)) {
                      for (let j = 0; j < this.numCatStrat.length; j++) {
                        const item = this.numCatStrat[j];
                        const strategyId = parseInt(item["id"]);
                        const columnStrategyId = parseInt(
                          this.finalColumnsData[i]["columnlets_data"][0][
                          "strategy_id"
                          ]
                        );
                        if (strategyId === columnStrategyId) {
                          index = j;
                          break; // Exit the loop once a match is found
                        }
                      }
                    }
                    if (index != -1 && this.numCatStrat[index]["strategy_name"] == r["strategy_name"]) {
                      exist = true;
                      this.formSchema[1]["options"] = [];
                      this.formSchema[1]["options"].push({
                        name: r.strategy_name,
                        value: r.id,
                        id: r.id,
                      });
                    } else {
                      exist = false

                    }
                  }
                }
              }
              if (exist) {
                break;
              } else {
                this.formSchema[1]["options"].push({
                  name: r.strategy_name,
                  value: r.id,
                  id: r.id,
                });
              }
            } else {
              this.formSchema[1]["options"].push({
                name: r.strategy_name,
                value: r.id,
                id: r.id,
              });
            }
          }
          this.patchStrategy();
        }
      },
      error: (err) => {
        Toast.fire({
          icon: "error",
          html: err.error.error.message,
        });
      },
    });
  }

  showAddNewStrategy() {
    if (this.customStrategyForm.get("tokenize").value) {
      if (this.fieldLength.toLowerCase() == "fixed") {
        this.positionForm[this.formIndex][3]["group"] = true;
      } else {
        this.variableForm[this.formIndex][2]["group"] = true;
      }
    } else {
      this.formSchema[1]["group"] = true;
    }
  }

  hideAddNewStrategy() {
    if (this.customStrategyForm.get("tokenize").value) {
      if (this.fieldLength.toLowerCase() == "fixed") {
        this.positionForm[this.formIndex][3]["group"] = false;
      } else {
        this.variableForm[this.formIndex][2]["group"] = false;
      }
    } else {
      this.formSchema[1]["group"] = false;
    }
  }

  newStrategyFormSchemaBuilder() {
    if (this.selectedAssignValue) {
      this.setFocusAddNewPattern = false;
      if (this.formSchema.length > 0) {
        this.formSchema[2]["tabIndex"] = -1;
      }
      if (this.positionForm.length > 0) {
        this.positionForm[this.formIndex][4]["tabIndex"] = -1;
      }
      if (this.variableForm.length > 0) {
        this.variableForm[this.formIndex][3]["tabIndex"] = -1;
      }
      this.showStrategyHandler = true;
      this.strategyHandlerStatus = "add";
      this.strategySchema = strategySchema;
    } else {
      Toast.fire({
        icon: "warning",
        title: "Warning",
        html: "Please Assign the Semantic Group",
      });
    }
  }

  closeStrategyHandler() {
    this.strategyHandlerStatus = null;
    this.strategySchema = [];
    this.showStrategyHandler = false;
    this.setFocusAddNewPattern = true;
    if (this.formSchema.length > 0) {
      this.formSchema[2]["tabIndex"] = 0;
    }
    if (this.positionForm.length > 0) {
      this.positionForm[this.formIndex][4]["tabIndex"] = 0;
    }
    if (this.variableForm.length > 0) {
      this.variableForm[this.formIndex][3]["tabIndex"] = 0;
    }
  }

  strategyHandlerEvent(data) {
    if (data.strategy_id) {
      this.showStrategyHandler = false;
      Toast.fire({
        icon: "success",
        html: data.message,
      });
      this.closeStrategyHandler();
      this.fetchStrategiesBasedAssignSemantics(this.selectedAssignValue);
    }
  }

  jobColumnHandler(data, type) {
    if (!this.selectedColumn) {
      let execute: boolean = false;
      for (let i = 0; i < this.columnsData.length; i++) {
        if (
          this.columnsData[i]["source_column"] ==
          this.selectedColumnDetails["selectedColumn"]
        ) {
          if (
            Array.isArray(this.columnsData[i]["columnlets_data"]) &&
            this.formIndex == this.columnsData[i]["columnlets_data"].length
          ) {
            this.columnsData[i]["columnlets_data"].push(data);
          } else {
            this.columnsData[i]["columnlets_data"][this.formIndex] = data;
          }
          execute = true;
        } else {
          // TODO: check
        }
      }
      if (!execute) {
        this.selectedColumn = this.selectedColumnDetails["selectedColumn"];
        let obj = {
          source_column: this.selectedColumnDetails["selectedColumn"],
          destination_column: this.selectedColumnDetails["selectedColumn"],
          columnlet_type: type,
          delimiter_value: this.customStrategyForm.get("delimiter").value
            ? this.customStrategyForm.get("delimiter").value
            : null,
          columnlets_data: [],
        };
        obj["columnlets_data"].push(data);
        this.columnsData.push(obj);
      }
    } else {
      if (this.selectedColumn == this.selectedColumnDetails["selectedColumn"]) {
        for (let i = 0; i < this.columnsData.length; i++) {
          if (
            this.columnsData[i]["source_column"] ==
            this.selectedColumnDetails["selectedColumn"]
          ) {
            if (
              Array.isArray(this.columnsData[i]["columnlets_data"]) &&
              this.formIndex == this.columnsData[i]["columnlets_data"].length
            ) {
              this.columnsData[i]["columnlets_data"].push(data);
            } else {
              this.columnsData[i]["columnlets_data"][this.formIndex] = data;
            }
          } else {
            // Nothing here
          }
        }
      } else {
        this.selectedColumn = this.selectedColumnDetails["selectedColumn"];
        let obj = {
          source_column: this.selectedColumnDetails["selectedColumn"],
          destination_column: this.selectedColumnDetails["selectedColumn"],
          delimiter_value:
            type != "NA"
              ? type == "fixed"
                ? null
                : this.customStrategyForm.get("delimiter").value
              : null,
          columnlet_type: type,
          columnlets_data: [],
        };
        obj["columnlets_data"].push(data);
        this.columnsData.push(obj);
      }
    }
    this.disabledTest = this.columnTest();
    this.jobColumns.emit(this.columnsData);
  }

  async delimiterHandler() {
    if (this.customStrategyForm.get("delimiter").value !== "") {
      if (this.topRecords[this.showIndex].includes(this.customStrategyForm.get("delimiter").value)) {
        this.splitArray = this.topRecords[this.showIndex].split(
          this.customStrategyForm.get("delimiter").value
        );
      } else {
        Toast.fire({
          icon: "warning",
          title: "Warning",
          html: "Please choose valid delimiter character",
        });
      }
    } else {
      this.splitArray = [];
      this.variableForm = [];
      this.tabs = [];
    }
    if (this.splitArray.length > 0) {
      await this.delimiterColumnletsHandler();
    }
  }

  async delimiterColumnletsHandler() {
    this.tabs = [];
    for (let i = 0; i < this.splitArray.length; i++) {
      let arr = customStrategyVariableSchema.map((obj) => ({ ...obj }));
      if (this.jobDetails["locale"] === "en_US") {
        // TODO: check
      } else if (this.jobDetails["locale"] == "en_ZA") {
        // TODO: check
      }
      arr[3]["options"] = this.selectedColumnDetails;
      arr[1]["value"] = null;
      this.variableForm.push([...arr]);
      this.tabs.push({
        label: `Columnlet ${this.variableForm.length}`,
        value: this.variableForm.length,
      });
    }
    this.formIndex = 0;
  }

  testSynthesisData(event) {
    this.positionRange = event["value"];
    this.setFocusTestButton = false;
    if (this.formSchema.length > 0) {
      this.formSchema[3]["name"][2]["tabIndex"] = -1;
    }
    if (this.positionForm.length > 0) {
      this.positionForm[this.formIndex][5]["name"][2]["tabIndex"] = -1;
    }
    if (this.variableForm.length > 0) {
      this.variableForm[this.formIndex][4]["name"][2]["tabIndex"] = -1;
    }
    if (this.fileJob["job_id"]) {
      if (this.customStrategyForm.get("tokenize").value) {
        this.testColumnletForFile(event);
      } else {
        let body = {
          job_id: this.fileJob["job_id"],
          source_column: this.selectedColumnDetails["selectedColumn"],
          destination_column: this.selectedColumnDetails["selectedColumn"],
          pattern: null,
          pattern_id: null,
          strategy_id: event["value"]["synthesisStrategy"],
          dependent_fields: event["value"]["dependentField"],
          tokenization_type: "NA",
          sk: this.sk
        };
        this.syntheticDataService.syntheticTestDataFile(body).subscribe({
          next: (res) => {
            if (res && !res["message"]) {
              this.fileTest = res;
              this.showFileTest = true;
            } else {
              Toast.fire({
                icon: "error",
                html: res["message"],
              });
            }
          },
          error: (err) => { },
        });
      }
    } else {
      if (this.customStrategyForm.get("tokenize").value) {
        if (
          event &&
          event["value"] &&
          event["value"]["lower"] &&
          event["value"]["upper"] &&
          event["value"]["upper"] < event["value"]["lower"]
        ) {
          Toast.fire({
            icon: "warning",
            title: "Warning",
            html: "Position Range Upper Value Should be Greater Than the Lower Value",
          });
          return;
        }
        let pat = {};
        pat["tokenization_type"] = this.fieldLength;
        pat["semantic_group"] = event["value"]["assignSemantics"];
        pat["delimiter_value"] = this.customStrategyForm.get("delimiter").value;
        pat["columnlets_pattern"] = [
          {
            columnlet_name: event["value"]["columnlet"]
              ? event["value"]["columnlet"]
              : null,
            strategy_id: event["value"]["synthesisStrategy"],
            position_range_start: event["value"]["lower"]
              ? event["value"]["lower"]
              : null,
            position_range_end: event["value"]["upper"]
              ? event["value"]["upper"]
              : null,
            variable_position:
              this.fieldLength == "variable" ? this.formIndex + 1 : null,
            dependent_fields: event["value"]["dependentField"]
              ? event["value"]["dependentField"]
              : null,
          },
        ];
        let obj = {
          source_column: this.selectedColumnDetails["selectedColumn"],
          destination_column: this.selectedColumnDetails["selectedColumn"],
          pattern: pat,
          strategy_id: null,
          dependent_fields: null,
          sk: this.sk
        };
        let body = {
          locale: this.jobDetails["locale"],
          source_connection: this.jobDetails["selectSource"]["id"],
          source_database: this.jobDetails["selectSource"]["database_name"],
          source_schema: this.jobDetails["selectSchema"],
          source_table: this.selectedColumnDetails["table"]["tableName"],
          table_data: null,
          columns_data: [obj],
        };
        this.testCall(body);
      } else {
        if (event["value"]) {
          let obj = {
            source_column: this.selectedColumnDetails["selectedColumn"],
            destination_column: this.selectedColumnDetails["selectedColumn"],
            pattern: null,
            semantic_group: event["value"]["assignSemantics"]
              ? event["value"]["assignSemantics"]
              : null,
            strategy_id: event["value"]["synthesisStrategy"]
              ? event["value"]["synthesisStrategy"]
              : null,
            dependent_fields: event["value"]["dependentField"]
              ? event["value"]["dependentField"]
              : null,
            sk: this.sk
          };
          let body = {
            locale: this.jobDetails["locale"],
            source_connection: this.jobDetails["selectSource"]["id"],
            source_database: this.jobDetails["selectSource"]["database_name"],
            source_schema: this.jobDetails["selectSchema"],
            source_table: this.selectedColumnDetails["table"]["tableName"],
            table_data: null,
            columns_data: [obj],
          };
          this.testCall(body);
        }
      }
    }
  }

  testCall(body) {
    this.syntheticDataService.syntheticTestData(body).subscribe({
      next: (res) => {
        if (Array.isArray(res["message"])) {
          this.confirmSyntheticData = res.message;
          this.showTest = true;
        } else {
          Toast.fire({
            icon: "error",
            html: res["message"],
          });
        }
      },
      error: (err) => {
        Toast.fire({
          icon: "error",
          html: err.message ? err.message : err.error.text,
        });
      },
    });
  }

  close(data) {
    if (data) {
      this.showTest = false;
      this.setFocusTestButton = true;
      if (this.formSchema.length > 0) {
        this.formSchema[3]["name"][2]["tabIndex"] = 0;
      }
      if (this.positionForm.length > 0) {
        this.positionForm[this.formIndex][5]["name"][2]["tabIndex"] = 0;
      }
      if (this.variableForm.length > 0) {
        this.variableForm[this.formIndex][4]["name"][2]["tabIndex"] = 0;
      }
    }
  }

  clearForm(data, type) {
    this.clearDependentField = true;
    if (type === "NA") {
      this.formSchema.forEach((r) => {
        if (r["value"]) {
          r["value"] = null;
        }
      });
      this.clearColumn.emit({ clear: true, index: 0 });
      this.selectedColumn = "";
    } else if (type === "fixed") {
      this.clearColumn.emit({ clear: true, index: this.formIndex });
      this.clearColumnletForm("fixed");
      this.positionForm[this.formIndex][3]["options"] = [];
      this.selectedColumn = "";
    } else if (type === "variable") {
      this.clearColumn.emit({ clear: true, index: this.formIndex });
      this.selectedColumn = "";
      this.variableForm[this.formIndex][2]["options"] = [];
      this.clearColumnletForm("variable");
    }
  }

  clearColumnletForm(type) {
    if (type == "fixed") {
      this.positionForm[this.formIndex].forEach((r) => (r["value"] = null));
      this.tabs[this.formIndex]["label"] = `Columnlet ${this.tabs[this.formIndex]["value"]
        }`;
    } else if (type == "variable") {
      this.variableForm[this.formIndex].forEach((r) => (r["value"] = null));
      this.tabs[this.formIndex]["label"] = `Columnlet ${this.tabs[this.formIndex]["value"]
        }`;
    }
  }

  displayPattern() {
    this.showPattern = true;
  }

  patternHandler(result) {
    this.patternsData.emit({
      column: this.selectedColumn,
      pattern_id: result["pattern_id"],
    });
    this.showPattern = false;
    Toast.fire({
      icon: "success",
      html: result["message"],
    });
  }

  fetchPatterns() {
    const currentUser = this.authService.currentUserValue;
    if (this.projectId) {
      this.patternService
        .getPatterns({ org_id: currentUser.orgId, project_id: this.projectId })
        .subscribe({
          next: (result) => {
            this.patternData = result;
            this.patternList.push({
              name: "Create New",
              value: "none",
            });
            result.forEach((res) => {
              if (res.pattern_name) {
                this.patternList.push({
                  name: res.pattern_name,
                  value: res.id,
                  id: res.id,
                });
              }
            });
          },
          error: (err) => {
            Toast.fire({
              icon: "error",
              html: err.message,
            });
          },
        });
    }
  }

  async patternDecider(event) {
    this.selectPattern = event.value;
    if (event.value !== "none") {
      let data = {};
      if (this.patternData.length > 0) {
        data = this.patternData.find((ob) => ob["id"] == event.value);
        if (data["tokenization_type"] === "variable") {
          this.patchVariableSchema(
            { columnlets_data: JSON.parse(data["columnlets_pattern"]) },
            data["delimiter_value"]
          );
        } else if (data["tokenization_type"] === "fixed") {
          this.patchFixedSchema({
            columnlets_data: JSON.parse(data["columnlets_pattern"]),
          });
        }
      }
    } else {
      this.customStrategyForm.get("category").patchValue("fixed");
      this.fieldLength = "fixed";
      this.positionForm = [];
      this.variableForm = [];
      this.tabs = [];
      await this.fieldLengthStatus({ value: "fixed", reload: true });
      this.formDecider(null);
    }
  }

  async patchVariableSchema(record, delimiter) {
    this.variableForm = [];
    this.positionForm = [];
    this.customStrategyForm.get("tokenize").patchValue(true);
    this.checked = true;
    this.customStrategyForm.get("category").patchValue("variable");
    this.fieldLength = "variable";
    this.customStrategyForm.get("delimiter").patchValue(delimiter);
    this.splitArray = this.topRecords[this.showIndex].split(delimiter);
    this.selectPattern = "none";
    await this.delimiterColumnletsHandler();
    for (let k = 0; k < record["columnlets_data"].length; k++) {
      this.variableForm[
        record["columnlets_data"][k]["variable_position"] - 1
      ][0]["value"] = record["columnlets_data"][k]["columnlet_name"];
      this.variableForm[
        record["columnlets_data"][k]["variable_position"] - 1
      ][1]["value"] = record["columnlets_data"][k]["semantic_group"];
      let strat_name = "";
      if (
        this.semanticGroupStrategies[
        record["columnlets_data"][k]["semantic_group"]
        ]
      ) {
        let d = this.semanticGroupStrategies[
          record["columnlets_data"][k]["semantic_group"]
        ].find((obj) => obj.id == record["columnlets_data"][k]["strategy_id"]);
        strat_name = d["strategy_name"];
      }
      await this.fetchStrats(
        record["columnlets_data"][k]["semantic_group"],
        this.variableForm[
        record["columnlets_data"][k]["variable_position"] - 1
        ][2]
      );
      this.variableForm[
        record["columnlets_data"][k]["variable_position"] - 1
      ][2]["value"] = record["columnlets_data"][k]["strategy_id"];
      this.tabs[record["columnlets_data"][k]["variable_position"] - 1][
        "label"
      ] = record["columnlets_data"][k]["columnlet_name"];
    }
  }

  async patchFixedSchema(record) {
    this.positionForm = [];
    this.variableForm = [];
    this.formSchema = [];
    this.customStrategyForm.get("tokenize").patchValue(true);
    this.checked = true;
    this.customStrategyForm.get("category").patchValue("fixed");
    this.fieldLength = "fixed";
    this.tabs = [];
    this.selectPattern = "none";
    for (let j = 0; j < record["columnlets_data"].length; j++) {
      let arr = customStrategyFixedSchema.map((obj) => ({ ...obj }));
      this.positionForm.push([...arr, ...this.formSchema]);
      this.positionForm[j][0]["value"] =
        record["columnlets_data"][j]["columnlet_name"];
      this.positionForm[j][1]["lowerValue"] =
        record["columnlets_data"][j]["position_range_start"];
      this.positionForm[j][1]["upperValue"] =
        record["columnlets_data"][j]["position_range_end"];
      this.positionForm[j][2]["value"] =
        record["columnlets_data"][j]["semantic_group"];
      this.positionForm[j][3]["value"] =
        record["columnlets_data"][j]["strategy_id"];
      this.tabs.push({
        label: `${record["columnlets_data"][j]["columnlet_name"]}`,
        value: this.positionForm.length,
      });
      this.positionForm[j][5]["name"][1]["diabled"] = false;
      this.positionForm[j][5]["name"][2]["diabled"] = false;
    }
    this.formIndex = 0;
    let arr = customStrategyFixedSchema.map((obj) => ({ ...obj }));
    this.positionForm.push([...arr, ...this.formSchema]);
    for (let k = 0; k < this.positionForm.length; k++) {
      for (let l = 0; l < this.positionForm[k].length; l++) {
        let data = this.positionForm[k][l];
        if (
          data["name"] == "assignSemantics" &&
          data["value"] &&
          data["value"] != ""
        ) {
          await this.fetchStrats(data["value"], this.positionForm[k][l + 1]);
        }
      }
    }
  }

  async fetchStrats(data, pointer) {
    const currentUser = this.authService.currentUserValue;
    let params = {};
    if (this.fileJob && this.fileJob["job_id"]) {
      params = {
        semantic_group: data,
        org_id: currentUser.orgId,
        user_id: currentUser.id,
        locale: this.jobDetails["locale"],
        job_id: this.fileJob["job_id"],
        source_column: this.selectedColumnDetails["selectedColumn"],
      };
    } else {
      params = {
        semantic_group: data,
        org_id: currentUser.orgId,
        user_id: currentUser.id,
        locale: this.jobDetails["locale"],
        connection_id: this.jobDetails["selectSource"]["id"],
        source_schema: this.jobDetails["selectSchema"],
        source_table: this.selectedColumnDetails["table"]["tableName"],
        source_column: this.selectedColumnDetails["selectedColumn"],
      };
    }
    let result = await this.customStrategy.getStrategiesAsync(params);
    pointer["options"] = [];

    // Logic
    if (data == 'numeric' || data == 'categorical') {
      if (Array.isArray(this.finalColumnsData) && this.finalColumnsData.length && Array.isArray(result)) {
        console.log('final', this.finalColumnsData)
        console.log('options', result)
        let index = -1
        for (let i = 0; i < this.finalColumnsData.length; i++) {
          for (let j = 0; j < result.length; j++) {
            if (this.finalColumnsData[i]['columnlets_data'][0]['strategy_id'] == result[j]['id']) {
              index = j
              break;
            }
          }
          if (index != -1) {
            break
          }
        }
        if (index != -1) {
          pointer["options"].push({
            name: result[index]['strategy_name'],
            value: result[index]['id'],
            id: result[index]['id'],
          })
        } else {
          if (result && Array.isArray(result)) {
            result.forEach((r) => {
              pointer["options"].push({
                name: r.strategy_name,
                value: r.id,
                id: r.id,
              });
            });
          }
        }
      } else {
        if (result && Array.isArray(result)) {
          result.forEach((r) => {
            pointer["options"].push({
              name: r.strategy_name,
              value: r.id,
              id: r.id,
            });
          });
        }
      }
    } else {
      if (result && Array.isArray(result)) {
        result.forEach((r) => {
          pointer["options"].push({
            name: r.strategy_name,
            value: r.id,
            id: r.id,
          });
        });
      }
    }
  }

  columnTest() {
    if (this.columnsData.length > 0) {
      for (let i = 0; i < this.columnsData.length; i++) {
        if (
          this.columnsData[i]["source_column"] ==
          this.selectedColumnDetails["selectedColumn"]
        ) {
          if (this.columnsData[i]["columnlets_data"].length > 1) {
            return false;
          }
        }
      }
    }
  }

  testSynthesisColumnlet() {
    if (this.fileJob && this.fileJob["job_id"]) {
      this.testColumnletForFile(null);
    } else {
      let pat = {};
      if (this.columnsData.length > 0) {
        for (let i = 0; i < this.columnsData.length; i++) {
          if (
            this.columnsData[i]["source_column"] ==
            this.selectedColumnDetails["selectedColumn"]
          ) {
            pat["tokenization_type"] = this.columnsData[i]["columnlet_type"];
            pat["semantic_group"] =
              this.columnsData[i]["columnlets_data"]["semantic_group"];
            pat["delimiter_value"] = this.columnsData[i]["delimiter_value"];
            pat["columnlets_pattern"] = this.columnsData[i]["columnlets_data"];
          }
        }
      }
      let obj = {
        source_column: this.selectedColumnDetails["selectedColumn"],
        destination_column: this.selectedColumnDetails["selectedColumn"],
        pattern: pat,
        strategy_id: null,
        dependent_fields: null,
      };
      let body = {
        locale: this.jobDetails["locale"],
        source_connection: this.jobDetails["selectSource"]["id"],
        source_database: this.jobDetails["selectSource"]["database_name"],
        source_schema: this.jobDetails["selectSchema"],
        source_table: this.selectedColumnDetails["table"]["tableName"],
        table_data: null,
        columns_data: [obj],
      };
      this.syntheticDataService.syntheticTestData(body).subscribe({
        next: (res) => {
          if (typeof res["message"] != "string") {
            this.confirmSyntheticData = res.message;
            this.showTest = true;
          } else {
            Toast.fire({
              icon: "error",
              html: res.message ? res.message : res.error.text,
            });
          }
        },
        error: (err) => {
          Toast.fire({
            icon: "error",
            html: err.message ? err.message : err.error.text,
          });
        },
      });
    }
  }

  testColumnletForFile(event) {
    if (event === null) {
      let pat = {};
      if (this.columnsData.length > 0) {
        for (let i = 0; i < this.columnsData.length; i++) {
          if (
            this.columnsData[i]["source_column"] ==
            this.selectedColumnDetails["selectedColumn"]
          ) {
            pat["tokenization_type"] = this.columnsData[i]["columnlet_type"];
            pat["semantic_group"] =
              this.columnsData[i]["columnlets_data"]["semantic_group"];
            pat["delimiter_value"] = this.columnsData[i]["delimiter_value"];
            pat["columnlets_pattern"] = this.columnsData[i]["columnlets_data"];
          }
        }
      }
      let obj = {
        source_column: this.selectedColumnDetails["selectedColumn"],
        destination_column: this.selectedColumnDetails["selectedColumn"],
        pattern: pat,
        sk: this.sk,
        pattern_id: null,
        strategy_id: null,
        dependent_fields: null,
        locale: this.jobDetails["locale"],
        table_data: null,
        job_id: this.fileJob["job_id"],
      };
      this.syntheticDataService.syntheticTestDataFile(obj).subscribe({
        next: (res) => {
          if (res && !res["message"]) {
            this.fileTest = res;
            this.showFileTest = true;
          } else {
            Toast.fire({
              icon: "error",
              html: res["message"],
            });
          }
        },
        error: (err) => { },
      });
    } else {
      let pat = {};
      pat["tokenization_type"] = this.fieldLength;
      pat["semantic_group"] = event["value"]["assignSemantics"];
      pat["delimiter_value"] = this.customStrategyForm.get("delimiter").value;
      pat["columnlets_pattern"] = [
        {
          columnlet_name: event["value"]["columnlet"]
            ? event["value"]["columnlet"]
            : null,
          strategy_id: event["value"]["synthesisStrategy"],
          position_range_start: event["value"]["lower"]
            ? event["value"]["lower"]
            : null,
          position_range_end: event["value"]["upper"]
            ? event["value"]["upper"]
            : null,
          variable_position:
            this.fieldLength == "variable" ? this.formIndex + 1 : null,
          dependent_fields: event["value"]["dependentField"]
            ? event["value"]["dependentField"]
            : null,
          sk: this.sk
        },
      ];
      let obj = {
        source_column: this.selectedColumnDetails["selectedColumn"],
        destination_column: this.selectedColumnDetails["selectedColumn"],
        locale: this.jobDetails["locale"],
        pattern: pat,
        strategy_id: null,
        dependent_fields: null,
        job_id: this.fileJob["job_id"],
        table_data: null,
        pattern_id: null,
        sk: this.sk
      };
      this.syntheticDataService.syntheticTestDataFile(obj).subscribe({
        next: (res) => {
          if (res && !res["message"]) {
            this.fileTest = res;
            this.showFileTest = true;
          } else {
            Toast.fire({
              icon: "error",
              html: res["message"],
            });
          }
        },
        error: (err) => { },
      });
    }
  }
}
