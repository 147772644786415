import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-range-field',
  templateUrl: './range-field.component.html'
})
export class RangeFieldComponent implements OnChanges {

  @Input() field: any = {};
  @Input() form !: FormGroup;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['field']) {
      if (this.field['lowerValue'] !== null || this.field['upperValue'] !== null) {
        this.form.get(this.field['lowerName']).patchValue(this.field.lowerValue);
        this.form.get(this.field['upperName']).patchValue(this.field.upperValue);
      } else {
        // TODO document why this block is empty
      }
    }
  }


}
