<div class="card-content change-pwd">
    <div class="card-body text-center">
        <!-- <h2 class="popup-title text-center"></h2> -->
        <h1 title="Login" class="log-head">{{ 'changepasswordform.popuptitle' | translate }} </h1>
    </div>
    <form class="form-horizontal" [formGroup]="changePasswordForm" (ngSubmit)="submit()"
        aria-label="'Change Password Form'">
        <div class="row">
            <div class="col-md-12" *ngIf="!key">
                <div class="form-group">
                    <label>{{ 'inputforms.labels.currentpassword' | translate }} <span
                            class="mendatory">*</span></label>
                    <span class="p-fluid">
                        <div class="password-field">
                            <input type="{{ showCurrentPassword ? 'text' : 'password' }}" class="form-control mw-100p"
                                formControlName="old"
                                [placeholder]="'inputforms.placeholder.currentpassword' | translate "
                                [ngClass]="{'is-invalid': f.old.errors && (f.old.touched || f.old.dirty)}">

                            <a href="javascript:void(0)" class="eye-icon " tabindex="0"
                                (click)="toggleCurrentPasswordVisibility()" aria-label="Show Password">
                                <i class="eye-icon feather"
                                    [ngClass]="{ ' ft-eye': !showCurrentPassword, ' ft-eye-off': showCurrentPassword }"></i>
                            </a>
                        </div>
                        <ng-container *ngIf="f.old.touched && f.old.invalid">
                            <span *ngIf="f.old.touched">
                                <span *ngIf="!f.old.valid" class="p-error">{{ 'inputforms.formerrors.requiredpass' |
                                    translate
                                    }}</span>
                            </span>
                        </ng-container>
                        <ng-container>
                            <span *ngIf="currentPasswordValidator && changePasswordForm.get('old').value != ''"
                                class="p-error">{{ 'inputforms.formerrors.incorrectpassword' | translate }}</span>
                        </ng-container>
                    </span>
                </div>
            </div>
            <!-- <div class="col-md-12"></div> -->
            <div class="col-md-12">
                <div class="form-group">
                    <label>{{ 'inputforms.labels.newpassword' | translate }} <span class="mendatory">*</span></label>
                    <span class="p-fluid">
                        <div class="password-field">
                            <input type="{{ showNewPassword ? 'text' : 'password' }}" class="form-control mw-100p"
                                formControlName="new" [placeholder]="'inputforms.placeholder.newpassword' | translate"
                                [ngClass]="{'is-invalid': f.new.errors && (f.new.touched || f.new.dirty)}">

                            <a href="javascript:void(0)" class="eye-icon " tabindex="0"
                                (click)="toggleNewPasswordVisibility()" aria-label="Show Password">
                                <i class="eye-icon feather"
                                    [ngClass]="{ ' ft-eye': !showNewPassword, ' ft-eye-off': showNewPassword }"></i>
                            </a>
                        </div>
                        <div *ngIf="(isSubmit || f.new.touched) && f.new.errors" class="invalid-feedback">
                            <div *ngIf="f.new.errors.required">{{ 'inputforms.formerrors.requiredpass' | translate }}
                            </div>
                            <div *ngIf="f.new.errors.minlength">{{ 'inputforms.formerrors.minpassword' | translate }}
                            </div>
                            <div *ngIf="f.new.errors.maxlength">{{ 'inputforms.formerrors.maxpassword' | translate }}
                            </div>
                            <div *ngIf="f.new.errors.pattern">{{ 'inputforms.formerrors.passwordpattern' | translate }}
                            </div>
                        </div>
                    </span>
                </div>
            </div>

            <div class="col-md-12">
                <div class="form-group">
                    <label>{{ 'inputforms.labels.confirmpassword' | translate }} <span
                            class="mendatory">*</span></label>
                    <span class="p-fluid">
                        <div class="password-field">
                            <input type="{{ showPassword ? 'text' : 'password' }}" class="form-control mw-100p"
                                formControlName="confirmNew"
                                [placeholder]="'inputforms.placeholder.confirmpassword' | translate "
                                [ngClass]="{'is-invalid': f.confirmNew.errors && (f.confirmNew.touched || f.confirmNew.dirty)}">

                            <a href="javascript:void(0)" class="eye-icon " tabindex="0"
                                (click)="togglePasswordVisibility()" aria-label="Show Password">
                                <i class="eye-icon feather"
                                    [ngClass]="{ ' ft-eye': !showPassword, ' ft-eye-off': showPassword }"></i>
                            </a>
                        </div>
                        <div *ngIf="inValid(f.confirmNew, isSubmit)">
                            <span *ngIf="f.confirmNew.errors.required" id="err_CofirmPassword" class="p-error">
                                {{ 'inputforms.formerrors.requiredconfirmpass' | translate }}
                            </span>
                            <span *ngIf="f.confirmNew.errors.NoPassswordMatch" id="err_PasswordMatch" class="p-error">
                                {{ 'inputforms.formerrors.matchpasswords' | translate }}
                            </span>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-md-12 d-flex justify-content-end">
                <button type="submit" [disabled]="!changePasswordForm.valid"
                    class="btn btn-raised btn-warning btn-small">
                    <span *ngIf="!isLoading" class="btn-text">{{ 'buttontitle.confirmbutton' | translate }}</span>
                    <span *ngIf="isLoading" class="btn-text">{{ 'buttontitle.updatingbutton' | translate }}</span>
                </button>
            </div>
        </div>
    </form>
</div>