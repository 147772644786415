import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-bias',
  templateUrl: './add-bias.component.html'
})
export class AddBiasComponent implements OnInit, OnChanges {

  addBiasForm: FormGroup;
  @Input() biasAnalysisData: Object = {}
  sensitiveAttrsOptions: Object[] = []
  underprivilegedOptions: Object[] = []
  desirableOptions: Object[] = []
  addBias: Object = {}
  @Output() addBiasEmitter = new EventEmitter()
  @Input() columnsData: Object[] = []
  columns: string[] = []

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (Object.keys(this.biasAnalysisData).length) {
      this.sensitiveAttrsOptionsHandler();
    }
  }

  ngOnInit(): void {
    this.addBiasForm = this.fb.group({
      sensitiveAttribute: ['', Validators.required],
      underprivilegedValue: ['', Validators.required],
      desirableValue: ['', Validators.required],
    });
  }

  sensitiveAttrsOptionsHandler() {
    if (Array.isArray(this.columnsData) && this.columnsData.length) {
      for (let i = 0; i < this.columnsData.length; i++) {
        if (this.columnsData[i]['columnlet_type'] == 'NA' && Array.isArray(this.columnsData[i]['columnlets_data']) && this.columnsData[i]['columnlets_data'][0]['semantic_group'] == 'categorical') {
          this.columns.push(this.columnsData[i]['source_column'])
        }
      }
    }
    if (Object.keys(this.biasAnalysisData).length) {
      this.sensitiveAttrsOptions = [];
      this.biasAnalysisData['sensitive_attrs'].forEach((element: any) => {
        // Only selected ones need to show in options of Sensitive
        if (this.columns.includes(element)) {
          this.sensitiveAttrsOptions.push({
            name: element,
            value: element
          })
        }
      });
    }
  }

  underprivilegedOptionsHandler(event) {
    if (event && event['value']) {
      if (this.biasAnalysisData && this.biasAnalysisData['groups_per_attr'] && this.biasAnalysisData['groups_per_attr'][event['value']]) {
        this.underprivilegedOptions = []
        this.biasAnalysisData['groups_per_attr'][event['value']].forEach((element: any) => {
          this.underprivilegedOptions.push({
            name: element,
            value: element
          })
        });
      };
      this.addBias['S'] = event['value'];
      this.addBiasEmitter.emit(this.addBias)
    }
  }

  desirableValueOptionsHandler(event) {
    if (this.biasAnalysisData && this.biasAnalysisData['group_target_attr']) {
      this.desirableOptions = []
      this.biasAnalysisData['group_target_attr'].forEach((element: any) => {
        this.desirableOptions.push({
          name: element,
          value: element
        })
      })
      this.addBias['underprivileged_value'] = event['value']
      this.addBiasEmitter.emit(this.addBias)
    }
  }

  selectedDesirableValue(event) {
    if (event && event['value']) {
      this.addBias['desirable_value'] = event['value']
      this.addBiasEmitter.emit(this.addBias)
    }
  }
}
