<div *ngIf="occurenceCol['attribute_type']">
    <div class="row occurence">
        <div class="col-5 semantic-type ">
            <div class="row customer-destination">
                <div class=" ">{{ 'occurence.updates' | translate }}</div>
            </div>
            <div class="table-data">
                <div>
                    <ul style="list-style: none;padding: 0;">
                        <li>{{ selectedColumnDetails['table']['tableName']}}<i class="feather ft-arrow-right"></i> {{
                            occurenceCol['column_name']}}
                            <span class="title">{{ 'occurence.current' | translate }}</span>
                        </li>
                    </ul>
                </div>
                <div *ngIf="occurence['parent'] != ''">
                    <ul style="list-style: none;padding: 0;" *ngFor="let record of occurence['parent']">
                        <li>{{ record['table_name']}}<i class="feather ft-arrow-right"></i> {{ record['column_name']}}
                            <span class="title">{{ 'occurence.parent' | translate }}</span>
                        </li>
                    </ul>
                </div>
                <div *ngIf="occurence['child'] != ''">
                    <ul style="list-style: none;padding: 0;" *ngFor="let record of occurence['child']">
                        <li> {{ record['table_name']}}<i class="feather ft-arrow-right"></i> {{ record['column_name']}}
                            <span class="title">{{ 'occurence.child' | translate }}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>