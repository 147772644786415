<div class="d-flex text-center role-management" *ngIf="settings">
    <ng-container *ngFor="let member of members; let i = index">
        <div>
            <div class="team" pTooltip="{{ member?.user?.name }}" tooltipPosition="bottom" [tabindex]="0" ngbDropdown>
                <span class="pointer" ngbDropdownToggle>
                    {{ getInitials(member?.user?.name) }}
                    <!-- <img src="../../../../../assets/images/alt-user-pic.png" alt=""> -->
                </span>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem id="updateMemberButton{{member?.id}}" (click)="updateMember(member)"
                        class="d-flex justify-content-between approve">
                        {{ 'buttontitle.updatememberbutton' | translate }}
                    </button>
                    <button ngbDropdownItem (click)="removeMember(member)"
                        class="d-flex justify-content-between decline">
                        {{ 'buttontitle.removebutton' | translate }}
                    </button>
                </div>
            </div>
            <!-- <p class="username m-0">{{ member?.user?.name }}</p>
            <p class="userrole m-0">{{ member?.role }}</p> -->
        </div>
    </ng-container>
</div>

<div class="avatars" *ngIf="projectdetail">
    <ul>
        <li *ngFor="let member of members; let i = index" ngbDropdown>
            <p ngbDropdownToggle pTooltip="{{ member?.user?.name }}" tooltipPosition="top" [tabindex]="0"> {{
                getInitials(member?.user?.name) }}</p>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button ngbDropdownItem id="updateMemberButton{{member?.id}}" (click)="updateMember(member)"
                    class="d-flex justify-content-between approve">
                    {{ 'buttontitle.updatememberbutton' | translate }}
                </button>
                <button ngbDropdownItem (click)="removeMember(member)" class="d-flex justify-content-between decline">
                    {{ 'buttontitle.removebutton' | translate }}
                </button>
            </div>
        </li>
        <li class="add" (click)="addTeamClicked()"> <em class="feather ft-user-plus"></em> </li>
    </ul>
</div>

<p-dialog [(visible)]="displayManageMember" [resizable]="false" [draggable]="false" [modal]="true" [blockScroll]="true"
    [style]="{width: '45vw'}">
    <div class="p-dialog-titlebar">
        <button class="p-dialog-titlebar-icon p-dialog-titlebar-close" aria-label="Close Dialog" (click)="close()">
            <span class="pi pi-times"></span>
        </button>
    </div>
    <div *ngIf="displayManageMember && selectedMember['id']" class="add-project-popup-data">
        <app-manage-member [selectedMember]="selectedMember" [type]="type"
            (manageMemberEmit)="updateMemberDetails($event)"></app-manage-member>
    </div>
</p-dialog>