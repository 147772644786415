import { Component, OnInit, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomValidators } from 'src/app/_helpers/custom-validators';
import { Toast } from 'src/app/_helpers/toast';
import { AuthService } from 'src/app/_services/auth.service';



@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent implements OnInit, OnChanges {
  visible = false;
  isLoading: boolean;
  isSubmit: boolean;
  changePasswordForm: FormGroup;
  @Input()
  key;
  @Input() resetForm: boolean;
  @Output() display = new EventEmitter<string>();
  currentPasswordValidator: boolean;
  password: string = '';
  showPassword: boolean = false;
  showCurrentPassword: boolean = false;
  showNewPassword: boolean = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) { }


  ngOnChanges(changes: SimpleChanges): void {
    if (this.resetForm) {
      this.changePasswordForm.reset();
      this.currentPasswordValidator = false;
    }

    this.route.queryParams.subscribe((params) => {
      this.key = params.key;
    });
  }

  ngOnInit(): void {
    let validatorArray = [
      Validators.required, CustomValidators.removeSpaces,
      Validators.minLength(8),
      Validators.maxLength(20),
      Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[^\w\s]).{8,}$/)
    ];

    if (this.key) {
      this.changePasswordForm = this.fb.group({
        new: new FormControl('', validatorArray),
        confirmNew: ['', Validators.required],
      }, {
        validator: CustomValidators.passwordMatchValidator('new', 'confirmNew')
      });
    } else {
      this.changePasswordForm = this.fb.group({
        old: ['', Validators.required],
        new: new FormControl('', validatorArray),
        confirmNew: ['', Validators.required],
      }, {
        validator: CustomValidators.passwordMatchValidator('new', 'confirmNew'),
        notMatch: CustomValidators.passwordNotMatchValidator('old', 'new')
      });
    }

  }
  get f() {
    return this.changePasswordForm.controls;
  }

  inValid(control: any, submitted: any) {
    return control.invalid && (control.dirty || submitted || control.touched);
  }
  submit() {
    this.isLoading = true;
    if (this.changePasswordForm.invalid) {
      return;
    }
    let passwordData = this.changePasswordForm.value;
    if (this.key) {
      let frm = {
        resetKey: this.key,
        password: passwordData.new,
        confirmPassword: passwordData.confirmNew
      }

      this.authService.resetPasswordFinish(frm).subscribe({
        next: res => {
          this.isLoading = false;
          this.router.navigate(['/']);
        },
        error: err => {
          this.isLoading = false;
        }
      })
    } else {
      delete passwordData.confirmNew;
      this.authService.changePassword(passwordData).subscribe({
        next: res => {
          this.isLoading = false;
          this.display.emit('close')
        },
        error: err => {
          this.isLoading = false;
          Toast.fire({
            icon: 'error',
            html: err.error?.error?.message
          });
        }
      })
    }
    this.changePasswordForm.reset();
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  toggleCurrentPasswordVisibility() {
    this.showCurrentPassword = !this.showCurrentPassword;
  }

  toggleNewPasswordVisibility() {
    this.showNewPassword = !this.showNewPassword
  }

  checkPassword(event) {
    this.resetForm = false;
    if (event.target.value == "") {
      this.currentPasswordValidator = false;
    } else {
      this.authService.checkingPassword({ password: event.target.value }).subscribe(res => {
        if (res) {
          this.currentPasswordValidator = false;
        } else {
          this.currentPasswordValidator = true;
        }
      })
    }
  }

}
