<div class="row">
    <div class="col-md-12 configur-db">
        <h2 class="popup-title label-control text-center" for="projectinput1"><!-- Kiran -->
            {{ 'configuredb.popuptitle' | translate }}
        </h2>
        <p>{{ 'configuredb.spantext1' | translate }}</p>

        <p>{{ 'configuredb.spantext2' | translate }}</p>

        <p>{{ 'configuredb.spantext3' | translate }}</p>
    </div>
</div>

<div class="form-group sub-title mx-auto">
    <div class="page-content   ">
        <form [formGroup]="configureDBForm"
            aria-label="'configureDBForm All required input feilds are denoted by star'">
            <div class="row">
                <div class=" col-md-6 col-sm-12 mb-3">
                    <label class=" label-control" for="projectinput1">
                        {{ 'inputforms.labels.driver' | translate }}
                        <span class="mendatory">*</span>
                    </label>
                    <p-dropdown [filter]="true" styleClass="allDropdown" [options]="driverOptions" optionLabel="name"
                        [placeholder]="('inputforms.placeholder.driver' | translate) ? ('inputforms.placeholder.driver' | translate) : ' '"
                        filterBy="name" optionLabel="name" optionValue="value" (onChange)="formEdit()"
                        [ngClass]="{ 'is-invalid': (submitted || configureDBForm.get('driver_name').touched) && configureDBForm.get('driver_name').invalid  }"
                        formControlName="driver_name">
                        <ng-template let-country pTemplate="item">
                            <div class="country-item" aria-label="country.name">
                                <div>{{country.name}}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                    <div
                        *ngIf="configureDBForm.get('driver_name').invalid &&  configureDBForm.get('driver_name').touched">
                        <div class="invalid-feedback">{{ 'inputforms.formerrors.requireddriver' | translate }}</div>
                    </div>
                </div>
                <!-- 1 -->
            </div>

            <div *ngIf="configureDBForm.get('driver_name').value !== 'salesforce'; else salesforceForm">
                <div class="row">
                    <div class="col-md-6 col-sm-12 mb-3">
                        <label class=" label-control" for="projectinput1">
                            {{ 'inputforms.labels.dbname' | translate }}
                            <span class="mendatory">*</span>
                        </label>
                        <input type="text" class="form-control" formControlName="database_name"
                            [placeholder]="'inputforms.placeholder.dbname' | translate" (change)="formEdit()"
                            [ngClass]="{ 'is-invalid': (submitted || configureDBForm.get('database_name').touched) && configureDBForm.get('database_name').invalid  }">
                        <div
                            *ngIf="configureDBForm.get('database_name').invalid &&  configureDBForm.get('database_name').touched">
                            <div class="invalid-feedback">{{ 'inputforms.formerrors.requireddbname' | translate }}</div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 mb-3">
                        <label class=" label-control" for="projectinput1">
                            {{ 'inputforms.labels.connectionname' | translate }}
                            <span class="mendatory">*</span>
                        </label>
                        <input type="text" class="form-control" formControlName="connection_name"
                            [placeholder]="'inputforms.placeholder.connectionname' | translate" (change)="formEdit()"
                            [ngClass]="{ 'is-invalid': (submitted || configureDBForm.get('connection_name').touched) && configureDBForm.get('connection_name').invalid  }">
                        <div
                            *ngIf="configureDBForm.get('connection_name').invalid &&  configureDBForm.get('connection_name').touched">
                            <div class="invalid-feedback">{{ 'inputforms.formerrors.requiredconnectionname' | translate
                                }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-sm-12 mb-3">
                        <label class=" label-control" for="projectinput1">
                            {{ 'inputforms.labels.hostname' | translate }}
                            <span class="mendatory">*</span>
                        </label>
                        <!-- 2 -->
                        <input type="text" class="form-control" formControlName="host_name"
                            [placeholder]="'inputforms.placeholder.hostname' | translate" (input)="formEdit()"
                            [ngClass]="{ 'is-invalid': (submitted || configureDBForm.get('host_name').touched) && configureDBForm.get('host_name').invalid  }">
                        <div
                            *ngIf="configureDBForm.get('host_name').invalid &&  configureDBForm.get('host_name').touched">
                            <div class="invalid-feedback">{{ 'inputforms.formerrors.requirdehostname' | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 mb-3">
                        <label class=" label-control" for="projectinput1">
                            {{ 'inputforms.labels.port' | translate }}
                            <span class="mendatory">*</span>
                        </label>
                        <!-- 3 -->
                        <input type="text" class="form-control" formControlName="port_no"
                            [placeholder]="'inputforms.placeholder.port' | translate" (input)="formEdit()"
                            [ngClass]="{ 'is-invalid': (submitted || configureDBForm.get('port_no').touched) && configureDBForm.get('port_no').invalid  }">
                        <div *ngIf="configureDBForm.get('port_no').invalid &&  configureDBForm.get('port_no').touched">
                            <div class="invalid-feedback">{{ 'inputforms.formerrors.requiredportno' | translate }}</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-sm-12 mb-3">
                        <label class=" label-control" for="projectinput1">{{ 'inputforms.labels.username' | translate }}
                            <span class="mendatory">*</span></label>
                        <input type="text" class="form-control" formControlName="username_name"
                            [placeholder]="'inputforms.placeholder.username' | translate" (input)="formEdit()"
                            [ngClass]="{ 'is-invalid': (submitted || configureDBForm.get('username_name').touched) && configureDBForm.get('username_name').invalid  }">
                        <div
                            *ngIf="configureDBForm.get('username_name').invalid &&  configureDBForm.get('username_name').touched">
                            <div class="invalid-feedback">{{ 'inputforms.formerrors.requiredusername' | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 mb-3">
                        <label class=" label-control" for="projectinput1">{{ 'inputforms.labels.dbpassword' | translate
                            }}
                            <span class="mendatory">*</span></label>
                        <input type="password" class="form-control" formControlName="password"
                            [placeholder]="'inputforms.placeholder.dbpassword' | translate" (input)="formEdit()"
                            [ngClass]="{ 'is-invalid': (submitted || configureDBForm.get('password').touched) && configureDBForm.get('password').invalid  }">
                        <div
                            *ngIf="configureDBForm.get('password').invalid &&  configureDBForm.get('password').touched">
                            <div class="invalid-feedback">{{ 'inputforms.formerrors.requiredpassword' | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ng-template #salesforceForm>
                <div class="row">
                    <div class="col-md-6 col-sm-12 mb-3">
                        <label class=" label-control" for="projectinput1">{{ 'inputforms.labels.username' | translate }}
                            <span class="mendatory">*</span></label>
                        <input type="text" class="form-control" formControlName="username_name"
                            [placeholder]="'inputforms.placeholder.username' | translate" (input)="formEdit()"
                            [ngClass]="{ 'is-invalid': (submitted || configureDBForm.get('username_name').touched) && configureDBForm.get('username_name').invalid  }">
                        <div
                            *ngIf="configureDBForm.get('username_name').invalid &&  configureDBForm.get('username_name').touched">
                            <div class="invalid-feedback">{{ 'inputforms.formerrors.requiredusername' | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 mb-3">
                        <label class=" label-control" for="projectinput1">{{ 'inputforms.labels.dbpassword' | translate
                            }}
                            <span class="mendatory">*</span></label>
                        <input type="password" class="form-control" formControlName="password"
                            [placeholder]="'inputforms.placeholder.dbpassword' | translate" (input)="formEdit()"
                            [ngClass]="{ 'is-invalid': (submitted || configureDBForm.get('password').touched) && configureDBForm.get('password').invalid  }">
                        <div
                            *ngIf="configureDBForm.get('password').invalid &&  configureDBForm.get('password').touched">
                            <div class="invalid-feedback">{{ 'inputforms.formerrors.requiredpassword' | translate }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-sm-12 mb-3">
                        <label class=" label-control" for="projectinput1">
                            {{ 'inputforms.labels.connectionname' | translate }}
                            <span class="mendatory">*</span>
                        </label>
                        <input type="text" class="form-control" formControlName="connection_name"
                            [placeholder]="'inputforms.placeholder.connectionname' | translate" (input)="formEdit()"
                            [ngClass]="{ 'is-invalid': (submitted || configureDBForm.get('connection_name').touched) && configureDBForm.get('connection_name').invalid  }">
                        <div
                            *ngIf="configureDBForm.get('connection_name').invalid &&  configureDBForm.get('connection_name').touched">
                            <div class="invalid-feedback">{{ 'inputforms.formerrors.requiredconnectionname' | translate
                                }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 mb-3">
                        <label class=" label-control" for="projectinput1">{{ 'Security Token' | translate }}
                            <span class="mendatory">*</span></label>
                        <input type="text" class="form-control" formControlName="security_token"
                            [placeholder]="'Security Token'" (input)="formEdit()"
                            [ngClass]="{ 'is-invalid': (submitted || configureDBForm.get('security_token').touched) && configureDBForm.get('security_token').invalid  }">
                        <div
                            *ngIf="configureDBForm.get('security_token').invalid &&  configureDBForm.get('security_token').touched">
                            <div class="invalid-feedback">{{ 'Security Token is Required' }}</div>
                        </div>
                    </div>
                </div>
            </ng-template>

            <div class="row">

                <div class="col-md-6 col-sm-12 test"><i class="feather ft-zap"></i>
                    <a class="pointer" href="javascript:void(0)" tabindex="0" aria-label="Click For Test connection"
                        (keydown.enter)="dbTestConnection()" (click)="dbTestConnection()">
                        {{ 'configuredb.testconnection' | translate }}
                    </a>
                </div>
                <div class="col-md-6 col-sm-12">
                    <button uiSref="address" type="button" class=" btn btn-raised btn-warning float-right btn-small"
                        [disabled]="disableSave" (click)="saveDBConnection()">
                        {{ dbDetails ? ('buttontitle.updatebutton' | translate) :
                        ('buttontitle.savebutton' | translate) }}
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>