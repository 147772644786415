import { Component, AfterViewChecked, ElementRef, ViewChild, OnInit, Input, OnChanges, SimpleChanges } from "@angular/core";
import { BrewChatService } from "src/app/_services/brew-chat.service";
import { AuthService } from "src/app/_services/auth.service";
import { v4 as uuidv4 } from 'uuid';
import { UserService } from "src/app/_services/user.service";
import { OrgService } from "src/app/_services/org.service";
import { Toast } from 'src/app/_helpers/toast';
import { SyntheticDataService } from "src/app/_services/synthetic-data.service";
import { NgbDropdown, NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { Router, RouterState } from "@angular/router";
import { ProjectService } from "src/app/_services/project.service";

@Component({
  selector: 'app-live-chat',
  templateUrl: './live-chat.component.html'
})
export class LiveChatComponent implements OnInit, AfterViewChecked {
  statuses
  activeUser = '';
  threads = []
  expandStatuses = false;
  expanded = false;
  queriesData: Object[] = []
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  latestQues: string = '';
  loading: boolean = false;
  latestThread: string = '';
  @ViewChild('msgInput', { static: false }) msgInput: ElementRef;
  isTyping = false;
  @ViewChild('messageInputHolder') messageInputHolder: ElementRef;
  userData: Object = {};
  orgData: Object = {};
  routeType: string = '';
  jobId = '';
  files: File[] = [];
  showContext: boolean = true;
  jobsList = []
  workspaceList = [];
  showJobsSelection: boolean = true;
  disabledTextArea: boolean = false;
  loadThreads: boolean = true;
  activeThreadsByThreadId = []
  @ViewChild('myDropdown', { static: false }) myDropdown: NgbDropdown;
  contextProjectId: string = '';
  contextJobId = ''
  modelList = [
    { name: 'Mixtral', value: 'mixtral' },
    // { name: 'Llama', value: 'llama7' },
    { name: 'DBRX', value: 'DBRX' },
    { name: 'OpenAI', value: 'openai' },
  ];
  llm = 'mixtral'
  @Input() mode: string;
  @Input() llmData;
  @Input() chatbotData;
  loadingMessage: string = 'Generating Response'
  msg = [
    "Extracting the data",
    "Indexing your data",
    "Generating Response"
  ]
  messageInterval;
  hideIcon: boolean = true;
  accept: string = ''
  fileErrorMessage = ''
  showFileMessage: boolean = false;
  @Input() analytixData;
  @Input() cfoDetails; 

  constructor(
    private authService: AuthService,
    private brewchatService: BrewChatService,
    private userService: UserService,
    private orgService: OrgService,
    private projectService: ProjectService,
    private syntheticDataService: SyntheticDataService,
    private router: Router,
    private elementRef: ElementRef,
    config: NgbDropdownConfig
  ) {
    config.autoClose = 'outside';
  }

  ngOnInit() {
    this.scrollToBottom();
    this.getprofile()
    this.fetchQuestionsAndAnswers();
    this.getIdsfromRouter();
    this.fetchWorkspaces();
  }

  ngOnDestroy(): void {
    if (this.activeUser) {
      this.clearSessions(this.activeUser);
    }
  }

  getIdsfromRouter() {
    const state: RouterState = this.router.routerState;
    const regex = /\/projects(?:\/([^\/]+))(?:\/jobs(?:\/([^\/]+)))?/;
    const matches = regex.exec(state['snapshot']['url']);
    if (matches) {
      this.contextProjectId = matches[1] || null;
      if (this.contextProjectId) {
        let obj = {
          value: this.contextProjectId
        }
        this.fetchJobs(obj)
      }
      this.contextJobId = matches[2] || null;
    }
  }

  onTyping(event: Event) {
    this.isTyping = true;
  }

  fetchWorkspaces() {
    this.projectService.getAllProject({}).subscribe({
      next: res => {
        this.workspaceList = []
        res['records'].forEach(e => {
          this.workspaceList.push({
            name: e['projectName'],
            value: e['id']
          })
        })
      },
      error: err => {
        Toast.fire({
          icon: 'error',
          html: err.error.error.message
        });
      }
    });
  }

  fetchJobs(event) {
    const currentUser = this.authService.currentUserValue;
    let params = { org_id: currentUser.orgId }
    if (event.value) {
      params['project_id'] = event.value;
    }
    this.syntheticDataService.getSynthesisData(params).subscribe({
      next: res => {
        if (res && res['records']) {
          this.jobsList = []
          res['records'].forEach(r => {
            if (r && r['column_data'] && r['column_data'].length) {
              this.jobsList.push({
                name: r['job_name'],
                value: r['id'].toString()
              });
            }
          })
          if (this.contextJobId) {
            this.jobId = this.contextJobId.toString();
          }
        }
      }, error: err => {

      }
    })
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  fetchQuestionsAndAnswers() {
    this.loadThreads = true;
    this.brewchatService.getQueries(this.routeType ? this.routeType : 'all').subscribe({
      next: async res => {
        // this.loading = false;
        this.loadThreads = false;
        let filterArray = res.filter(obj => obj && obj['type'] !== 'trainChatBot')
        this.queriesData = filterArray;
        this.threads = await this.findUniqueThreads(filterArray)
      }, error: err => {
        // this.loading = false;
      }
    })
  }

  newChat(event) {
    if (this.activeUser) {
      this.clearSessions(this.activeUser);
    }
    this.activeUser = ''
    this.routeType = '';
    this.activeThreadsByThreadId = []
    this.latestQues = '';
    this.disabledTextArea = false;
    this.loading = false;
    this.showJobsSelection = true;
    this.files = [];
    this.showContext = true;
    this.hideIcon = true;
    this.showFileMessage = false;
    this.contextProjectId = '';
    this.jobId = '';
    this.fetchQuestionsAndAnswers();
    if (event) {
      event.stopPropagation();
    }
  }

  jobSelection(event) {
    this.jobId = event.value
  }

  async findUniqueThreads(data) {
    const uniqueThreadIdsSet = new Set();
    await data.forEach(async obj => {
      uniqueThreadIdsSet.add(obj.threadId);
    });
    return Array.from(uniqueThreadIdsSet);
  }

  findObjectByThreadId(threadId, mode: string) {
    const filteredObjects = this.queriesData.filter(obj => obj['threadId'] === threadId);
    const sortedObjects = filteredObjects.sort((a, b) => a['created'] - b['created']);
    if (mode == 'single') {
      return sortedObjects[0]['question'];
    } else {
      return sortedObjects
    }
  }

  activeThread(threadId) {
    this.brewchatService.getTheardInfo(threadId).subscribe({
      next: res => {
        this.scrollDown();
        this.activeThreadsByThreadId = res;
        if (res && res.length) {
          this.routeType = res[0]['type']
        }

        if (this.messageInterval) {
          clearInterval(this.messageInterval)
        }
      }, error: err => {

      }
    })
  }

  getprofile() {
    this.userService.getUserProfile().subscribe({
      next: (res) => {
        if (res) {
          this.userData = res;
          this.orgService.getOrgById(res.orgId).subscribe({
            next: r => {
              this.orgData = r
            },
            error: err => {
              Toast.fire({
                icon: 'error',
                html: err.error.error.message
              });
            }
          })
        }
      },
      error: err => {
        Toast.fire({
          icon: 'error',
          html: err.error.error.message
        });
      }
    })
  }

  async addNewMessage(inputField) {
    if (inputField) {
      this.disabledTextArea = true;
      this.scrollDown();
      this.showJobsSelection = false;
      this.latestQues = inputField;
      const currentUser = this.authService.currentUserValue;

      if (this.activeUser == '') {
        this.latestThread = await uuidv4()
      }
      const formData = new FormData();
      formData.append('orgId', currentUser.orgId);
      formData.append('userId', currentUser.id.toString());
      formData.append('threadId', this.activeUser == '' ? this.latestThread : this.activeUser);
      formData.append('question', this.latestQues);
      formData.append('type', this.routeType ? this.routeType : 'general');
      formData.append('llm', this.llm)

      if (this.routeType == 'jobs') {
        formData.append('jobId', this.jobId);
      } else if (this.routeType == 'files') {
        if (this.activeUser == '') {
          formData.append('jobId', await uuidv4());
        } else {
          // let index = -1
          // for (let i = 0; i < this.queriesData.length; i++) {
          //   if (this.activeUser == this.queriesData[i]['threadId']) {
          //     index = i;
          //     break;
          //   }
          // }
          // formData.append('jobId', this.queriesData[index]['jobId']);
          if (this.activeThreadsByThreadId && this.activeThreadsByThreadId.length) {
            formData.append('jobId', this.activeThreadsByThreadId[0]['jobId']);
          }
        }
      } else {
        formData.append('jobId', '');
      }

      if (this.routeType == 'files') {
        for (let i = 0; i < this.files.length; i++) {
          formData.append('files', this.files[i]);
        }
        formData.append('is_custom_rag', '1')
      } else if (this.routeType == '' || this.routeType == 'general') {
        formData.append('is_custom_rag', '-1');
      } else if (this.routeType == 'csv') {
        formData.append('files', this.files[0]);
        formData.append('is_custom_rag', '-2');
      } else {
        formData.append('is_custom_rag', '0');
      }
      setTimeout(() => {
        const textarea = this.msgInput.nativeElement;
        textarea.style.height = '52px';
        const parentDiv = textarea.parentElement;
        parentDiv.style.height = '52px';
        this.msgInput.nativeElement.value = '';
        this.files = []
      }, 100)
      if (this.routeType == 'files' || this.routeType == 'csv') {
        this.messageHandler();
      } else {
        this.loadingMessage = 'Generating Response'
      }
      this.loading = true;
      this.brewchatService.postQuery(formData).subscribe({
        next: res => {
          if (this.latestThread && this.activeUser == '') {
            this.activeUser = this.latestThread;
          }
          this.activeThread(this.activeUser);
          this.disabledTextArea = false;
          this.loading = false;
          this.latestQues = ''
          // this.scrollDown();
        }, error: err => {
          this.disabledTextArea = false;
          this.loading = false;
        }
      })
      this.adjustTextareaHeight();
    }
  }

  // scrollDown(): void {
  //   const container = this.elementRef.nativeElement.querySelector('.chat-msg-block');
  //   container.scrollTop = container.scrollHeight;
  // }

  scrollDown(): void {
    const container = this.elementRef.nativeElement.querySelector('.chat-msg-block');
    container.scrollTo({
      top: container.scrollHeight,
      behavior: 'smooth'
    });
  }


  fileHandler(event) {
    this.showFileMessage = false;
    this.hideIcon = true;
    const fileList = event.target.files
    if (fileList.length) {
      if (this.routeType == 'csv' && fileList[0].type == 'text/csv') {
        this.files = fileList
        setTimeout(() => {
          this.hideIcon = false;
        }, 5000)
      } else if (this.routeType == 'files' && ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain'].includes(fileList[0].type)) {
        this.files = fileList
        setTimeout(() => {
          this.hideIcon = false;
        }, 5000)
      } else {
        if (this.routeType == 'csv') {
          this.hideIcon = true;
          this.showFileMessage = true;
          this.fileErrorMessage = 'Please upload a file in CSV format'
          setTimeout(() => {
            this.showFileMessage = false;
          }, 5000)
        } else {
          this.hideIcon = true;
          this.showFileMessage = true;
          this.fileErrorMessage = 'Please upload a file in PDF, DOCX or TXT format'
          setTimeout(() => {
            this.showFileMessage = false;
          }, 5000)
        }
      }
    }
  }

  messageHandler() {
    let i = 0
    this.messageInterval = setInterval(() => {
      if (i < 3 && !this.activeUser) {
        this.loadingMessage = this.msg[0]
      } else if (i >= 3 && i < 9 && !this.activeUser) {
        this.loadingMessage = this.msg[1]
      } else {
        this.loadingMessage = this.msg[2]
      }
      i++
    }, 3000)
  }

  predefinedQuery(q: string, event: Event) {
    this.addNewMessage(q);
    event.stopPropagation();
  }

  adjustTextareaHeight() {
    const textarea = this.msgInput.nativeElement;
    textarea.style.height = '52px';
    textarea.style.height = Math.min(textarea.scrollHeight, 60) + 'px'; // Limit to 100px
    const parentDiv = textarea.parentElement;
    parentDiv.style.height = textarea.style.height;
  }


  scrollToBottom(): void {
    try {
      if (this.myScrollContainer) {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
      }
    } catch (err) {

    }
  }

  setUserActive(user) {
    this.activeUser = user;
    this.activeThread(this.activeUser);
    this.disabledTextArea = false;
    this.loading = false;
    this.myDropdown.close();
  }

  contextHandler(type, event) {
    this.routeType = type;
    this.disabledTextArea = false;
    this.showContext = false;
    if (type == 'jobs') {
      var sidebarElement = document.getElementById('frame');
      // Set overflow to hidden for the specific element
      sidebarElement.style.overflow = "hidden";
    }
    if (type == 'files') {
      this.accept = '.pdf,.docx,.txt'
    } else if (type == 'csv') {
      this.accept = '.csv'
    }
    this.fetchQuestionsAndAnswers();
    event.stopPropagation();
  }

  validateImg(data) {
    if (typeof data == 'string' && data.startsWith('i')) {
      return true
    } else {
      return false
    }
  }

  deleteThread(event, id) {
    this.brewchatService.delThread(id).subscribe({
      next: res => {
        this.threads = this.threads.filter(thread => thread !== id);
        if (id == this.activeUser) {
          this.activeUser = ''
          this.routeType = '';
          this.activeThreadsByThreadId = []
          this.latestQues = '';
          this.disabledTextArea = false;
          this.loading = false;
          this.showJobsSelection = true;
          this.files = [];
          this.showContext = true;
          this.hideIcon = true;
        }

      }, error: err => {

      }
    })
  }

  clearSessions(id) {
    this.brewchatService.clearSession(id).subscribe({
      next: res => {
      }, error: err => {

      }
    })
  }
}