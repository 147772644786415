<form [formGroup]="ruleForm" aria-label="'Rule handler Form'" *ngIf="!showCreateRule">
    <div class="row">
        <div class="col-lg-6 col-md-6 set-job">
            <div class="form-group">
                <label>{{ 'inputforms.labels.selectrule' | translate }}</label>
                <p-dropdown [filter]="true" styleClass="allDropdown" formControlName="selectRule" filterBy="name"
                    [options]="rulesList" [(ngModel)]="selectRule"
                    [placeholder]="('inputforms.placeholder.selectrule' | translate) ? ('inputforms.placeholder.selectrule' | translate) : ' '"
                    optionLabel="name" optionValue="value" (onChange)="inputFieldHandler()">
                </p-dropdown>
                <div class="rule-icon-container" *ngIf="ruleForm.get('selectRule').value">
                    <a href="javascript:void(0)" aria-label="Edit Rule"
                        (click)="editRule(ruleForm.get('selectRule').value)">
                        <i class="feather ft-edit mr-1"></i>
                    </a>

                    <a href="javascript:void(0)" aria-label="Delete Rule"
                        (click)="deleteRule(ruleForm.get('selectRule').value)">
                        <i class="feather ft-trash"></i>
                    </a>
                </div>
                <div class="d-flex newStrategy mt-1">
                    <a href="javascript:void(0)" (click)="emitAddRuleEvent()" class="a">
                        <i class="feather ft-settings"></i>
                        Add New Rule
                    </a>
                </div>
            </div>
        </div>
        <div class="col-lg-12 col-md-12 set-job" *ngIf="ruleAttributes.length > 0">
            <div class="form-group">
                <label>{{ 'inputforms.labels.outputtablename' | translate }}</label>
                <input type="text" class="form-control" formControlName="outputTableName"
                    [placeholder]="'inputforms.placeholder.outputtablename' | translate">
            </div>
        </div>
        <div class="col-lg-12 col-md-12" *ngIf="ruleAttributes.length > 0">
            <div class="mb-3 row">
                <div class="col-lg-4 col-md-4 col-sm-12 set-job">{{ 'rulehandler.inputfields' | translate }}</div>
                <div class="col-lg-8 col-md-8 col-sm-12 att-container">
                    <div class="dependent-field d-flex mb-1" (dragover)="onDragOver($event)" (drop)="onDrop($event, i)"
                        *ngFor="let attribute of ruleAttributes; let i = index">
                        {{ attribute | key }}
                        <p class="dependent-filed-pills mx-1">
                            <i class="feather ft-x mr-1 field-delete" (click)="removeDragItem(attribute, i)"></i>
                            {{ attribute[attribute | key] ? attribute[attribute | key] : "Drag Item Here" }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-12 col-md-12">
            <div class="d-flex justify-content-end">
                <div class="justify-content-end dynamic-form mt-2">
                    <button uiSref="address" type="button" class=" btn btn-test btn-small" (click)="clearForm()">
                        {{ 'buttontitle.clearbutton' | translate }}
                    </button>
                </div>
                <div class="justify-content-end dynamic-form mt-2" *ngIf="ruleAttributes.length > 0">
                    <button uiSref="address" type="button" class="btn btn-test btn-small" (click)="ruleTest()">
                        <i class="feather ft-zap"></i>
                        {{ 'buttontitle.testbutton' | translate }}
                    </button>
                </div>
                <div class="justify-content-end dynamic-form mt-2">
                    <button uiSref="address" type="button" class=" btn btn-raised btn-warning btn-small"
                        [disabled]="!ruleForm.valid" (click)="saveTest()">
                        {{ 'buttontitle.savebutton' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>
<app-rule-editor (closeRuleEditor)="receiveBooleanFromChild($event)" [projectId]="projectId" [jobDetails]="jobDetails"
    [tableInfo]="tableInfo" *ngIf="showCreateRule" [jsonData]="jsonData" [editRuleEvent]="editRuleEvent"
    [fileJob]="fileJob"></app-rule-editor>

<p-dialog [(visible)]="showRuleTest" [resizable]="false" [draggable]="false" [modal]="true" [blockScroll]="true"
    [style]="{width: '45vw'}" [styleClass]="'thirdtestpop'">
    <div *ngIf="showRuleTest" class="test-popup rule-handler ">
        <div class="p-dialog-titlebar">
            <button class="p-dialog-titlebar-icon p-dialog-titlebar-close" aria-label="Close Dialog" (click)="close()">
                <span class="pi pi-times"></span>
            </button>
        </div>
        <app-rule-test [messageData]="testData"></app-rule-test>
    </div>
</p-dialog>

<p-dialog [(visible)]="showCreateRuleModal && !showCreateRuleModal" [resizable]="false" [draggable]="false"
    [modal]="true" [blockScroll]="true" [style]="{width: '45vw'}" [styleClass]="'thirdtestpop'">
    <div *ngIf="showCreateRuleModal && !showCreateRuleModal" class="test-popup">
        <div class="p-dialog-titlebar">
            <button class="p-dialog-titlebar-icon p-dialog-titlebar-close" aria-label="Close Dialog" (click)="close()">
                <span class="pi pi-times"></span>
            </button>
        </div>
        <app-add-rule [jobDetails]="jobDetails" [projectId]="projectId" [tableInfo]="tableInfo"
            (addRuleEvent)="addRuleEmitter($event)"></app-add-rule>
    </div>
</p-dialog>