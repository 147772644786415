import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/_services/auth.service';
import { PatternService } from 'src/app/_services/pattern.service';

@Component({
  selector: 'app-add-pattern',
  templateUrl: './add-pattern.component.html'
})
export class AddPatternComponent implements OnChanges {

  @Input() columnData: Object[] = []
  @Input() projectId: string = ''
  patternForm: FormGroup;
  @Output() patternEvent = new EventEmitter<Object>()
  enUS = [
    { name: 'National ID', value: 'national_id' },
    { name: 'date', value: 'date' },
    { name: 'name', value: 'name' },
    { name: 'address', value: 'address' },
    { name: 'phone number', value: 'phone_number' },
    { name: 'random number', value: 'random_number' },
    { name: 'random letters', value: 'random_letters' },
    { name: 'street name', value: 'street_name' },
    { name: 'city', value: 'city' },
    { name: 'state', value: 'state' },
    { name: 'job', value: 'job' },
    { name: 'personal email', value: 'personal_email' },
    { name: 'company email', value: 'company_email' },
    { name: 'ip address', value: 'ip_address' },
    { name: 'domain name', value: 'domain_name' },
    { name: 'device address', value: 'device_address' },
    { name: 'bank account', value: 'bank_account' },
    { name: 'swift code', value: 'swift_code' },
    { name: 'license plate', value: 'license_plate' },
  ];

  constructor(
    private authService: AuthService,
    private patternService: PatternService
  ) {
    this.patternForm = new FormGroup({
      pattern_name: new FormControl(null, Validators.required),
      semantic_group: new FormControl(null, Validators.required)
    });
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    console.log(this.columnData);
  }


  savePattern() {
    if (this.patternForm.valid) {
      const currentUser = this.authService.currentUserValue;
      let body = {
        org_id: currentUser.orgId,
        project_id: this.projectId,
        user_id: currentUser.id,
        pattern_name: this.patternForm['value']['pattern_name'],
        semantic_group: this.patternForm['value']['semantic_group'],
        tokenization_type: this.columnData[0]['columnlet_type'],
        delimiter_value: this.columnData[0]['delimiter_value'],
        columnlets_pattern: this.columnData[0]['columnlets_data']
      }
      this.patternService.addPattern(body).subscribe({
        next: r => {
          this.patternEvent.emit(r);
        },
        error: err => {

        }
      })
    }
  }

  isFormValid(): boolean {
    return this.patternForm.valid;
  }
  format_heading(data: string): string {
    if (typeof data == "string" && data !== "") {
      const withoutUnderscore = data.replace(/_/g, ' ');
      return withoutUnderscore.replace(/(^|\s)(\w)/g, (match, p1, p2) => p1 + p2.toUpperCase());
    } else {
      return data
    }
  }

}
