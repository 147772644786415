import { CurrencyPipe, DatePipe, UpperCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Message } from 'primeng/api';
import { Subscription } from 'src/app/_models/subscription.model';
import { SubscriptionService } from 'src/app/_services/subscription.service';

@Component({
  selector: 'app-subscription-billing',
  templateUrl: './subscription-billing.component.html',
  providers: [DatePipe, CurrencyPipe, UpperCasePipe]
})
export class SubscriptionBillingComponent implements OnInit {
  products = [
    { products: 'Users plan', quantity: '4', total: '$8.00 USD/ month' },
    { products: 'ML Models', quantity: '5', total: '$5.00 USD/ month' },
    { products: 'Data Synthesizer plan', quantity: 'View usage', total: 'Varies with usage' },
  ];
  messages1: Message[] | undefined;
  subscriptionData: Subscription;
  loggedInUser = JSON.parse(sessionStorage.getItem('currentUser'));
  upcomingInvoice: any;
  priceModel: boolean = false;


  constructor(
    private route: ActivatedRoute,
    private subscriptionService: SubscriptionService,
    private datePipe: DatePipe,
    private currencyPipe: CurrencyPipe,
    private upperCasePipe: UpperCasePipe) {
    this.route.queryParams.subscribe(params => {
      console.log(params);

      if (params.success == 'true') {
        this.messages1 = [
          { severity: 'success', summary: 'Thank you for subscribing', detail: 'Confirmation email has been sent' },
        ];
      } else if (params.success == 'false') {
        this.messages1 = [
          { severity: 'error', summary: 'Payment process cancelled/interrupted', detail: 'Please retry again.' },
        ];
      }

    });
  }

  ngOnInit(): void {
    /*
    this.messages1 = [
      { severity: 'success', summary: 'Thank you for subscribing', detail: 'Confirmation email has been sent' },
    ];
    */
    // this.loggedInUser.orgId
    this.subscriptionService.subscriptionDetailsById(this.loggedInUser.orgId).subscribe({
      next: (res: Subscription) => {
        if (res && !res.errno) {
          this.subscriptionData = res;
        }
        if (res.items && res.items.length > 0) {
          this.products = res.items;
          this.subscriptionService.upcomingInvoice(this.loggedInUser.orgId).subscribe({
            next: (res) => {
              this.upcomingInvoice = res;
            },
            error: (err) => {

            }
          });
        }
      },
      error: (err) => {
        console.log(err);
      }
    });
    // this.loggedInUser.orgId


  }

  priceDialog() {
    this.priceModel = true;
  }

  proceedToCheckout(interval: string) {
    if (!this.loggedInUser.orgId) {
      alert("User not logged in");
    }
    this.subscriptionService.initialCheckoutPage({ customerId: this.loggedInUser.orgId, interval }).subscribe({
      next: (data) => {
        if (data.url) {
          location.href = data.url;
        }
      },
      error: (error) => {
        console.log(error);
      }
    });
  }



}
