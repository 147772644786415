<form [formGroup]="anomalyForm">
    <div class="d-flex align-items-center form-group"
        *ngIf="!(finalAnomalies && finalAnomalies['anomalies'] && finalAnomalies['anomalies']['target'] && finalAnomalies['anomalies']['target'] !== selectedColumnDetails?.selectedColumn)">
        <label class="mb-0 mr-1">{{ 'inputforms.labels.anomalyClassColumn' | translate }}</label>
        <p-checkbox [binary]="true" (onChange)="validateClassColumn()" inputId="binary" formControlName="anomalyClass" tooltipPosition="right"
            pTooltip="If the column comprises binary values indicating anomalies and non-anomalies, choose it. The values are represented as (yes, no), (0, 1), or (1, -1)."></p-checkbox>
    </div>
    <div class="row" *ngIf="!anomalyForm.get('anomalyClass').value">
        <div class="col-lg-6">
            <div class="form-group">
                <label>{{ 'inputforms.labels.confirmSemanticType' | translate }}</label>
                <p-dropdown formControlName="confirmSemanticType" [filter]="true" styleClass="allDropdown"
                    filterBy="name" [options]="semanticTypeOptions" optionLabel="name" optionValue="value"
                    [placeholder]="'inputforms.placeholder.confirmSemanticType' | translate">
                    <ng-template let-option pTemplate="item">
                        <div class="country-item">
                            <div class="d-flex justify-content-between">
                                {{option.name}}
                            </div>
                        </div>
                    </ng-template>
                </p-dropdown>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="form-group">
                <ng-container *ngIf="anomalyForm.get('confirmSemanticType').value === 'numeric'">
                    <label>{{ 'inputforms.labels.columnNameRange' | translate }}</label>
                    <input type="text" class="form-control" formControlName="columnRange"
                        [placeholder]="'inputforms.placeholder.columnNameRange' | translate"
                        [ngClass]="{ 'is-invalid': anomalyForm.get('columnRange').invalid && anomalyForm.get('columnRange').touched }">
                        <div *ngIf="anomalyForm.get('columnRange').invalid && anomalyForm.get('columnRange').touched" class="invalid-feedback">
                            Invalid range format. Please enter a valid range in the format [10, 300].
                          </div>
                </ng-container>
                <ng-container *ngIf="anomalyForm.get('confirmSemanticType').value === 'categorical'">
                    <!-- <label>{{ 'inputforms.labels.columnName' | translate }}</label> -->

                    <div *ngIf="getOptions().length; else text">
                        <label>{{ 'inputforms.labels.columnName' | translate }}</label>
                        <p-dropdown [filter]="true" [options]="getOptions()" styleClass="allDropdown" filterBy="name"
                            formControlName="columnRecord"
                            [placeholder]="'inputforms.placeholder.columnName' | translate" optionLabel="name"
                            optionValue="value">
                        </p-dropdown>
                    </div>
                    <ng-template #text>
                        <label>{{ 'inputforms.labels.enterValue' | translate }}</label>
                        <input type="text" class="form-control" formControlName="columnRecord"
                            [placeholder]="'inputforms.placeholder.enterValue' | translate">
                    </ng-template>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="row"
        *ngIf="anomalyForm.get('confirmSemanticType').value === 'others' && !anomalyForm.get('anomalyClass').value">
        <div class="col-lg-6">
            <div class="form-group">
                <label>{{ 'inputforms.labels.semanticType' | translate }}</label>
                <p-dropdown [filter]="true" [options]="otherTypes" styleClass="allDropdown" filterBy="name"
                    formControlName="semanticType" [placeholder]="'inputforms.placeholder.semanticType' | translate"
                    optionLabel="name" optionValue="value" (onChange)="fetchPatterns($event.value)">
                </p-dropdown>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="form-group">
                <label>{{ 'inputforms.labels.pattern' | translate }}</label>
                <p-dropdown [filter]="true" styleClass="allDropdown" [options]="patterns" filterBy="name"
                    formControlName="pattern" [placeholder]="'inputforms.placeholder.pattern' | translate"
                    optionLabel="name" optionValue="value">
                </p-dropdown>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12 col-md-12">
            <div class="d-flex justify-content-end">
                <div class="justify-content-end dynamic-form mt-2">
                    <button uiSref="address" type="button" class=" btn btn-test btn-small" (click)="resetForm()">
                        {{ 'buttontitle.clearbutton' | translate }}
                    </button>
                </div>
                <div class="justify-content-end dynamic-form mt-2">
                    <button uiSref="address" type="button" [disabled]="validateSubmit()" class=" btn btn-raised btn-warning btn-small"
                        (click)="saveAnomalies()">
                        {{ 'buttontitle.savebutton' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>