import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-setup-parameters-llm',
  templateUrl: './setup-parameters-llm.component.html'
})
export class SetupParametersLlmComponent implements OnInit, OnChanges {
  setupParametersForm: FormGroup
  files = [undefined, undefined]
  @Output() llmParamsEvent = new EventEmitter();
  @Input() formBody = {};
  @Output() formValidity: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private formBuilder: FormBuilder
  ) {
    this.setupParametersForm = this.formBuilder.group({
      lora_rank: new FormControl(null, Validators.required),
      learning_rate: new FormControl(null, Validators.required),
      batch: new FormControl(null, Validators.required),
      epochs: new FormControl(null, Validators.required)
    });

    this.setupParametersForm.valueChanges.subscribe(() => {
      this.formValidity.emit(this.setupParametersForm.valid && this.files[0] && this.files[1]);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.formBody && (this.formBody['lora_rank'] || this.formBody['learning_rate'] || this.formBody['batch'] || this.formBody['epochs'])) {
      this.setupParametersForm.patchValue({
        lora_rank: this.formBody['lora_rank'],
        learning_rate: this.formBody['learning_rate'],
        batch: this.formBody['batch'],
        epochs: this.formBody['epochs']
      })
    }
  }

  ngOnInit(): void {

  }

  trainingUploader(event) {
    this.files[0] = event.files[0];
    this.emitterHandler()
  }

  evalUploader(event) {
    this.files[1] = event.files[0]
    this.emitterHandler()
  }

  clearTrainingData() {
    this.files[0] = undefined
    this.emitterHandler()
  }

  clearEvalData() {
    this.files[1] = undefined
    this.emitterHandler()
  }

  emitterHandler() {
    let obj = {
      ...this.setupParametersForm.value,
      trainData: this.files[0],
      valData: this.files[1]
    }
    this.llmParamsEvent.emit(obj)
  }
}
