import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-org-new-model',
  templateUrl: './org-new-model.component.html',
  styleUrls: ['./org-new-model.component.scss']
})
export class OrgNewModelComponent {
  orgForm: FormGroup;

  constructor(
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.orgForm = this.fb.group({
      selectsource: new FormControl(''),
      huggingfacetoken: new FormControl(''),
      modelname: new FormControl(''),
    });
  }
}
