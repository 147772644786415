
<div class="app-content ">
    <div class="content-wrapper">
        <div class="container-xxl mt-2">
            <div class="page-main project-job-list job-list-page pages">

                <p-tabView>
                    <p-tabPanel>
                        <ng-template pTemplate="header">
                            <span>Product Analysis Report</span>
                            <!-- <i class="pi pi-refresh ml-1"></i> -->
                        </ng-template>

                        <div class="analytix-box">
                            <div class="d-flex justify-content-between align-items-center">
                                <h4>Product Analysis Report</h4>
                                <i class="feather ft-message-square msg-icon" (click)="invokeChatSliding('cfo-chat')"></i>
                            </div>
                            <div class="dashboard-info d-flex">
                                <div class="dashboard-filter">
                                    <label for="" class="label-control">Brand</label>
                                    <span class="p-fluid">
                                        <p-dropdown (onChange)="changeInFilter($event, 'brand')" [(ngModel)]="brand" styleClass="allDropdown" appendTo="body" filterBy="name" [options]="brandOptions" optionLabel="name" optionValue="value" [placeholder]="'Select Brand'">
                                        </p-dropdown>
                                    </span>
                                </div>
                                <div class="dashboard-filter">
                                    <label for="" class="label-control">Product</label>
                                        <span class="p-fluid">
                                            <p-dropdown (onChange)="changeInFilter($event, 'product')" [(ngModel)]="product" styleClass="allDropdown" appendTo="body" filterBy="name" [options]="productOptions" optionLabel="name" optionValue="value" [placeholder]="'Select Product'">
                                            </p-dropdown>
                                        </span>
                                </div>
                            </div>
                        
                        
                            <div class="loader-wrapper circular-spinners text-center"
                                *ngIf="!productDetails || !productDetails['cards']; else dataLoaded">
                                <i class="pi pi-spin pi-spinner"></i>
                            </div>
                        
                            <ng-template #dataLoaded>
                            <div class="dashboard-info d-flex">
                                <div class="dashboard-card" *ngIf="productDetails && productDetails['cards']">
                                    <h5>Sales Value</h5>
                                    <p>{{ productDetails['cards']['Sales value'] | currency: jobDetails[0]['locale']+ ' ' :'symbol':'1.0-0' }}</p>
                                </div>
                                <div class="dashboard-card" *ngIf="productDetails && productDetails['cards']">
                                    <h5>Received Qty</h5>
                                    <p>{{ productDetails['cards']['Recieved Quantity'] | currency: jobDetails[0]['locale']+ ' ' :'symbol':'1.0-0' }}</p>
                                </div>
                                <div class="dashboard-card" *ngIf="productDetails && productDetails['cards']">
                                    <h5>Sales Qty</h5>
                                    <p>{{ productDetails['cards']['Sales Quantity'] | currency: jobDetails[0]['locale']+ ' ' :'symbol':'1.0-0' }}</p>
                                </div>
                                <div class="dashboard-card" *ngIf="productDetails && productDetails['cards']">
                                    <h5>Inventory Qty</h5>
                                    <p>{{ productDetails['cards']['Inventory Quantity'] | currency: jobDetails[0]['locale']+ ' ' :'symbol':'1.0-0' }}</p>
                                </div>
                            </div>
                        
                        
                        
                        
                            <div *ngIf="productChartDetails.length"> 
                                <div class="chart-area mt-2" *ngFor="let chart of productChartDetails">
                                    <!-- <h5>{{ chart['title'] }}</h5>
                                    <em *ngIf="chart['preType'] != 'table'" class="pi pi-times" (click)="getInsights(chart)"></em> -->

                                    <div class="d-flex justify-content-between align-items-center mb-2">
                                        <h5 class="m-0">{{ chart['title'] }}</h5>
                                        <img class="pointer bulb-light" *ngIf="chart['preType'] != 'table'"
                                            src="../../../../assets/images/lightbulb.png" width="32" height="32"
                                            alt="" (click)="getInsights(chart)">
                                    </div>

                                   <span class="p-fluid">
                                    <p-chart [type]="chart && chart['type'] ? chart['type'] : 'line'" [data]="chart['datasets']" [options]="chart['options']" />
                                   </span>
                                </div>
                            </div>
                            </ng-template>
                        </div> 
                    </p-tabPanel>

                    <p-tabPanel>
                        <ng-template pTemplate="header">
                            <span>Sales Forecasting</span>
                        </ng-template>
                        <div class="analytix-box">
                            <div class="dashboard-info d-flex">
                                <div class="dashboard-filter">
                                    <label for="" class="label-control">Product</label>
                                        <span class="p-fluid">
                                            <p-dropdown [(ngModel)]="product" styleClass="allDropdown" appendTo="body" filterBy="name" [options]="productOptions" optionLabel="name" optionValue="value" [placeholder]="'Select Product'">
                                            </p-dropdown>
                                        </span>
                                </div>
                                <div class="dashboard-filter">
                                    <label for="" class="label-control">Events</label>
                                    <span class="p-fluid">
                                        <p-multiSelect [options]="eventsOptions" optionLabel="name" [(ngModel)]="eventsValue" optionValue="value" appendTo="body"  placeholder="Select Events"/>
                                    </span>
                                </div>
                                <div class="dashboard-filter">
                                    <label for="" class="label-control">Timeline</label>
                                    <span class="p-fluid">
                                        <p-dropdown [(ngModel)]="timeline" styleClass="allDropdown" appendTo="body" filterBy="name" [options]="timelineOptions" optionLabel="name" optionValue="value" [placeholder]="'Select Brand'">
                                        </p-dropdown>
                                    </span>
                                </div>
                                <div class="dashboard-filter" *ngIf="timeline">
                                    <label for="" class="label-control">Forecast Period</label>
                                    <span class="p-fluid">
                                        <p-inputNumber 
                                        inputId="integeronly" 
                                        [(ngModel)]="number" /> 
                                    </span>
                                </div>
                                <div class="mt-3"  *ngIf="product && eventsValue && timeline && number">
                                    <button uiSref="address" type="button"
                                    class=" btn btn-raised btn-warning btn-small" (click)="submit()">
                                    {{ 'Submit' }}
                                </button>
                                </div>

                               
                            </div>

                            <div *ngIf="salesForecastingPlot['datasets']"> 
                                <div class="chart-area-full mt-2">
                                    <h5>{{ 'Forecasting of Out Quantity' }}</h5>
                                   <span class="p-fluid">
                                    <p-chart [type]="'line'" [data]="salesForecastingPlot['datasets']" [options]="salesForecastingPlot['options']" />
                                   </span>
                                </div>
                            </div>
                        </div>
                        </p-tabPanel>
                </p-tabView>
            </div>
        </div>
    </div>
</div>

<p-dialog [(visible)]="displayInsights" [resizable]="false" [draggable]="false" [modal]="true" [blockScroll]="true"
    [style]="{width: '55vw'}" appendTo="body">
    <div class="p-dialog-titlebar">
        <button class="p-dialog-titlebar-icon p-dialog-titlebar-close" autofocus="true" aria-label="Close Dialog"
            (click)="displayInsights=false">
            <span class="pi pi-times"></span>
        </button>
    </div>
    <div class="row subscription-plans info-cards">
        <div class="col-12 mb-2">
            <div class="d-flex justify-content-center align-items-center">
                <h3 class="m-0 ml-1"> {{'Insights Based on ' + insightsTitle }}</h3>
            </div>
        </div>
    </div>

    <div *ngIf="loading" class="d-flex justify-content-center mt-5">
        <i class="pi pi-spin pi-spinner" style="font-size: 1.5rem"></i>
    </div>

    <ul *ngIf="!loading" style="list-style: none;">
        <li class="mb-1" *ngFor="let ins of insights"> {{ins}} </li>
    </ul>
</p-dialog>