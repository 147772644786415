<h2 class="popup-title text-center">{{ 'reports.header2popuptitle' | translate }}</h2> <!-- Kiran have to work -->
<p-tabView>
    <p-tabPanel header="Data Quality Reports">
        <div *ngIf="acc != {} && acc != undefined">
            <div *ngFor="let item of individualChartContainer" class="report-card">
                <h4 class="label-title">{{item['labels'][0] + ' Accuracy'}}</h4>
                <p-chart type="doughnut" [data]="item" [options]="chartOptions" [style]="{'width': '10%'}"
                    [ariaLabel]="item['labels'][0] + ' Accuracy - ' + item['datasets'][0]['data'][0]"
                    [tabIndex]="0"></p-chart>
                <p class="report-text">{{item['labels'][0] + ' Accuracy - ' + (item['datasets'][0]['data'][0] *
                    1).toFixed(2)
                    + '%'}}</p>
                <p class="text-center">
                    {{ 'reports.paraaccuracytext' | translate }}
                </p>

            </div>
            <div class="report-card" *ngIf="overallAcc != {}">
                <h4 class="label-title">{{ 'reports.header3overall' | translate }}</h4>
                <p-chart type="doughnut" [data]="overallAcc" [options]="chartOptions" [style]="{'width': '10%'}"
                    [tabIndex]="0" [ariaLabel]="'Overall' + ' Accuracy - ' +
            overallAcc['datasets'][0]['data'][0]"></p-chart>
                <p class="report-text" *ngIf="overallAcc && overallAcc['datasets'][0]['data'][0]">{{'Overall' + '
                    Accuracy -
                    ' +
                    (overallAcc['datasets'][0]['data'][0]* 1).toFixed(2) + '%'}}</p>
                <p class="text-center">
                    {{ 'reports.overallaccuracytext' | translate }}
                </p>
            </div>
        </div>
        <a (click)="goToDoc()" href="javascript:void(0)" class="text-center">{{ 'reports.informationtext' | translate }}
            <span class="underline">{{ 'reports.doclink' | translate }}</span>
        </a>
        <div class="download-pdf">
            <button (click)="downloadPDF('Data Quality Reports')" class="btn btn-raised refresh-btn mr-2"><i
                    class="feather ft-download"></i>
                Download PDF</button>
        </div>
    </p-tabPanel>
    <p-tabPanel header="Correlation Report ">
        <div *ngIf="reportsData !== {}">
            <div *ngFor="let item of reportsData | keyvalue">
                <div [ngClass]="item.value[0]['img_result'] != null ? 'report-card': ''"
                    *ngIf="(item.key.toString().startsWith('Correlation') || item.key.toString().startsWith('Pair')) && item.value[0]['img_result'] !== null">
                    <h4 class="label-title">{{ item.value[0]['name'] }}</h4>
                    <div class="report-image" *ngIf="item.value[0]['img_result'] !== null">
                        <iframe title="correlationReport" [srcdoc]="item.value[0]['img_result']"></iframe>
                    </div>
                    <p class="text-center" *ngIf="item.value[0]['text']">{{ item.value[0]['text'] }}</p>
                </div>
            </div>
        </div>
        <a (click)="goToDoc()" href="javascript:void(0)" class="text-center">{{ 'reports.informationtext' | translate }}
            <span class="underline">{{ 'reports.doclink' | translate }}</span>
        </a>
        <div class="download-pdf">
            <button (click)="downloadPDF('Correlation')" class="btn btn-raised refresh-btn mr-2"><i *ngIf="!downloadpdf"
                    class="feather ft-download"></i>
                <span class="spinner" *ngIf="downloadpdf"></span>
                Download PDF</button>
        </div>
    </p-tabPanel>
    <p-tabPanel header="Distribution Reports">
        <div *ngIf="reportsData !== {}">
            <div *ngFor="let item of reportsData | keyvalue">
                <div [ngClass]="item.value[0]['img_result'] != null ? 'report-card': ''"
                    *ngIf="(item.key.toString().startsWith('Distribution')) && item.value[0]['img_result'] !== null">
                    <h4 class="label-title">{{ item.value[0]['name'] }}</h4>
                    <div class="report-image" *ngIf="item.value[0]['img_result'] !== null">
                        <iframe title="distributionReports" [srcdoc]="item.value[0]['img_result']"></iframe>
                    </div>
                    <p class="text-center" *ngIf="item.value[0]['text']">{{ item.value[0]['text'] }}</p>
                </div>
            </div>
        </div>
        <a (click)="goToDoc()" href="javascript:void(0)" class="text-center">{{ 'reports.informationtext' | translate }}
            <span class="underline">{{ 'reports.doclink' | translate }}</span>
        </a>
        <div class="download-pdf">
            <button (click)="downloadPDF('Distribution')" class="btn btn-raised refresh-btn mr-2"><i
                    class="feather ft-download"></i>
                Download PDF</button>
        </div>
    </p-tabPanel>
    <p-tabPanel header="Clustering Reports"
        *ngIf="reportsData && reportsData['NNDR (Nearest Neighbor Distance Ratio) Report'] && reportsData['NNDR (Nearest Neighbor Distance Ratio) Report'][0] && reportsData['NNDR (Nearest Neighbor Distance Ratio) Report'][0]['img_result'] != null">
        <div *ngFor="let item of reportsData | keyvalue">
            <div [ngClass]="item.value[0]['img_result'] != null ? 'report-card': ''"
                *ngIf="(item.key.toString().startsWith('NNDR') || item.key.toString().startsWith('DCR')) && item.value[0]['img_result'] !== 'null'">
                <h4 class="label-title">{{ item.value[0]['name'] }}</h4>
                <div class="report-image" *ngIf="item.value[0]['img_result'] !== null">
                    <iframe title="clusteringReports" [srcdoc]="item.value[0]['img_result']"></iframe>
                </div>
                <p class="text-center" *ngIf="item.value[0]['text']">{{ item.value[0]['text'] }}</p>
            </div>
        </div>
        <a (click)="goToDoc()" href="javascript:void(0)" class="text-center">{{ 'reports.informationtext' |
            translate }}
            <span class="underline">{{ 'reports.doclink' | translate }}</span>
        </a>
        <div class="download-pdf">
            <button (click)="downloadPDF('Clustering')" class="btn btn-raised refresh-btn mr-2"><i
                    class="feather ft-download"></i>
                Download PDF</button>
        </div>
    </p-tabPanel>
</p-tabView>