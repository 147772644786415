import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-add-configure-image-rag-tool',
  templateUrl: './add-configure-image-rag-tool.component.html',
})
export class AddConfigureImageRagToolComponent {

  toolName;
  toolDescription;
  imageRAGFiles = [];
  @Output() imageRAGEmitter = new EventEmitter();

  constructor () {

  }

  myUploader(event) {
    this.imageRAGFiles = event.files;
  }

  clearFile(file) {
    this.imageRAGFiles = [];
  }

  saveImageRAGTool() {
    this.imageRAGEmitter.emit({
      toolName: this.toolName,
      toolDescription: this.toolDescription,
      file: this.imageRAGFiles
    })
  }
}
