import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../_services/auth.service';
import { Toast } from 'src/app/_helpers/toast';
import { PerfectScrollbarConfigInterface, PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';

@Component({
    templateUrl: 'register.component.html',
    styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
    public config: PerfectScrollbarConfigInterface = {};

    @ViewChild(PerfectScrollbarComponent, { static: false }) componentRef?: PerfectScrollbarComponent;
    @ViewChild(PerfectScrollbarDirective, { static: false }) directiveRef?: PerfectScrollbarDirective;

    registerForm: FormGroup;
    loading = false;
    submitted = false;
    displayModal: boolean;
    errorMessage = '';
    successMessage = '';
    password: string = '';
    showPassword: boolean = false;
    questions: string[] = [
        "In what city were you born?",
        "What is the name of your favorite pet?",
        "What is your mother's maiden name?",
        "What high school did you attend?",
        "What was the name of your elementary school?",
        "What was the make of your first car?",
        "What was your favorite food as a child?",
        "Where did you meet your spouse?",
        "What year was your father (or mother) born?"
    ]

    constructor(
        private router: Router,
        private authService: AuthService
    ) { }

    ngOnInit() {
        this.registerForm = new FormGroup({
            name: new FormControl(null, [Validators.required]),
            email: new FormControl(null, [Validators.required, Validators.email]),
            password: new FormControl(null, [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[^\w\s]).{8,}$/)]),
            // confirmpassword: new FormControl(null, [Validators.required, Validators.minLength(8)]),
            phoneNumber: new FormControl(null, [Validators.required, Validators.pattern(/^\+?\d{1,3}[- ]?\d{3,}-?\d{4}$/)]),
            selectQuestion: new FormControl(null),
            answer: new FormControl(null)
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.registerForm.controls; }

    tryRegister() {
        this.submitted = true;
        if (this.registerForm.invalid) {
            return;
        }
        this.loading = true;

        this.authService.doRegister(this.registerForm.value).subscribe({
            next: res => {
                this.loading = false;
                Toast.fire({
                    icon: 'success',

                    title: 'You have signed up successfully!.',
                    html: 'Please check your email to verify your account'
                })
                setTimeout(() => {
                    this.router.navigate(['/finalize-registration'], { queryParams: { id: res.id, email: res.email }, skipLocationChange: true });
                }, 5000)
            },
            error: err => {

                this.loading = false;
                Toast.fire({
                    icon: 'error',
                    html: err.error.error.message
                })
            }
        });
    }
    togglePasswordVisibility() {
        this.showPassword = !this.showPassword;
    }
    showModalDialog() {
        this.displayModal = true;
    }

}
