import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AgentsService } from 'src/app/_services/agents.service';

@Component({
  selector: 'app-add-agent',
  templateUrl: './add-agent.component.html',
})
export class AddAgentComponent {

  agentTemplateOptions = [
    { name: 'Testing Compliance Agent', value: 'testing' }
  ];
  toolOptions = [];
  agentsFiles = []
  agentDescription = '';
  selectedTool = '';
  agentName = '';
  showToolSection: boolean = false;
  showTool: boolean = false;
  tools = [];
  @Input() workflowId
  @Output() addAgentEmitter = new EventEmitter();
  @Output() closeEmitter = new EventEmitter();
  checked: boolean;

  constructor(
    private agentsService: AgentsService
  ) {

  }

  ngOnInit() {
    this.fetchAgentTemplateOptions();
    this.fetchToolOptions();
  }

  fetchAgentTemplateOptions() {
    this.agentsService.getAvailableAgentTemplates().subscribe({
      next: res => {
        console.log('agent template', res);
        // this.agentTemplateOptions = []
        if (res["status"] == "OK") {
          for (let i = 0; i < res['templates'].length; i++) {
            this.agentTemplateOptions.push({
              name: res['templates'][i],
              value: res['templates'][i]
            })
          }
        }
      },
      error: err => {
        console.log('err', err)
      }
    })
  }

  fetchToolOptions() {
    this.agentsService.getAvailableToolName().subscribe({
      next: res => {
        console.log('tool names', res)
        this.toolOptions = []
        // {"templates":["API Tool","Image-RAG Tool","RAG Tool"],"status":"OK"}
        if (res["status"] == "OK") {
          for (let i = 0; i < res['templates'].length; i++) {
            this.toolOptions.push({
              name: res['templates'][i],
              value: res['templates'][i]
            })
          }
        }
      },
      error: err => {
        console.log('err', err)
      }
    })
  }

  myUploader(event) {
    this.agentsFiles = event.files;
  }

  clearFile(file) {
    this.agentsFiles = [];
  }

  clearTool(index) {
    this.tools.splice(index, 1)
  }

  ragEvent(event) {
    let obj = event;
    obj['toolType'] = this.selectedTool
    this.tools.push(obj)
    this.showTool = false;
    this.selectedTool = ''
    console.log('tool', this.tools)
  }

  imageRAGEvent(event) {
    let obj = event;
    obj['toolType'] = this.selectedTool
    this.tools.push(obj)
    this.showTool = false;
    this.selectedTool = ''
    console.log('tool', this.tools)
  }

  apiEvent(event) {
    let obj = event;
    obj['toolType'] = this.selectedTool
    this.tools.push(obj)
    this.showTool = false;
    this.selectedTool = ''
    console.log('tool', this.tools)
  }

  async saveAgentsnTools() {
    let addedToolsIds = []
    this.agentsService.getId('agent').subscribe({
      next: resAgents => {
        if (resAgents['id']) {
          let agentId = resAgents['id']
          if (Array.isArray(this.tools) && this.tools.length) {
            for (let i = 0; i < this.tools.length; i++) {
              this.agentsService.getId('tool').subscribe({
                next: async resTools => {
                  if (resTools['id']) {
                    switch (this.tools[i]['toolType']) {
                      case "RAG Tool":
                        let formDataRAG = new FormData();
                        formDataRAG.append('tool_file', this.tools[i]['file'][0])
                        let resToolFile = await this.agentsService.addToolFileAsync(resTools['id'], 0, agentId, formDataRAG)
                        console.log('res tool file', resToolFile)
                        if (resToolFile['status'] == 'OK') {
                          this.agentsService.addTool(
                            {
                              'tool_id': resTools['id'],
                              "agent_id": agentId,
                              "workflow_id": this.workflowId,
                              "tool_name": this.tools[i]['toolName'],
                              "tool_description": this.tools[i]['toolDescription'],
                              "tool_type": "RAG Tool",
                              "tool_specific_config": {

                              }
                            }
                          ).subscribe({
                            next: resAddTool => {
                              console.log('tool added', resAddTool)
                              if (resAddTool['status'] == 'OK') {
                                addedToolsIds.push(resTools['id'])
                              }
                              if (i == (this.tools.length - 1)) {
                                this.addAgentEmitter.emit({
                                  is_ceo: this.checked,
                                  agentId: agentId,
                                  agentName: this.agentName,
                                  agentDescription: this.agentDescription,
                                  promptFile: this.agentsFiles,
                                  tools: this.tools,
                                  addedTools: addedToolsIds
                                })
                                this.close();
                              }
                            },
                            error: err => { }
                          })
                        }
                        break;
                      case "API Tool":
                        this.agentsService.addTool(
                          {
                            'tool_id': resTools['id'],
                            "agent_id": agentId,
                            "tool_name": this.tools[i]['toolName'],
                            "workflow_id": this.workflowId,
                            "tool_description": this.tools[i]['toolDescription'],
                            "tool_type": "API Tool",
                            "tool_specific_config": {
                              "apiEndpoint": this.tools[i]['apiEndpoint'],
                              "callType": this.tools[i]['callType'],
                              "params": this.tools[i]['params']
                            }
                          }
                        ).subscribe({
                          next: resAddTool => {
                            console.log('tool added', resAddTool)
                            if (resAddTool['status'] == 'OK') {
                              addedToolsIds.push(resTools['id'])
                            }
                            if (i == (this.tools.length - 1)) {
                              this.addAgentEmitter.emit({
                                is_ceo: this.checked,
                                agentId: agentId,
                                agentName: this.agentName,
                                agentDescription: this.agentDescription,
                                promptFile: this.agentsFiles,
                                tools: this.tools,
                                addedTools: addedToolsIds
                              })
                              this.close();
                            }
                          },
                          error: err => { }
                        })
                        break;
                      case "Image-RAG Tool":
                        let formDataImageRAG = new FormData();
                        formDataImageRAG.append('tool_file', this.tools[i]['file'][0])
                        let resToolImageFile = await this.agentsService.addToolFileAsync(resTools['id'], 1, agentId, formDataImageRAG)
                        console.log('res tool file', resToolImageFile)
                        if (resToolImageFile['status'] == 'OK') {
                          this.agentsService.addTool(
                            {
                              'tool_id': resTools['id'],
                              "agent_id": agentId,
                              "workflow_id": this.workflowId,
                              "tool_name": this.tools[i]['toolName'],
                              "tool_description": this.tools[i]['toolDescription'],
                              "tool_type": "Image-RAG Tool",
                              "tool_specific_config": {

                              }
                            }
                          ).subscribe({
                            next: resAddTool => {
                              console.log('tool added', resAddTool)
                              if (resAddTool['status'] == 'OK') {
                                addedToolsIds.push(resTools['id'])
                              }
                              if (i == (this.tools.length - 1)) {
                                this.addAgentEmitter.emit({
                                  is_ceo: this.checked,
                                  agentId: agentId,
                                  agentName: this.agentName,
                                  agentDescription: this.agentDescription,
                                  promptFile: this.agentsFiles,
                                  tools: this.tools,
                                  addedTools: addedToolsIds
                                })
                                this.close();
                              }
                            },
                            error: err => { }
                          })
                        }

                        break;
                    }
                  }
                },
                error: err => { }
              })
              
            }
          } else {
            this.addAgentEmitter.emit({
              is_ceo: this.checked,
              agentId: agentId,
              agentName: this.agentName,
              agentDescription: this.agentDescription,
              promptFile: this.agentsFiles,
              tools: this.tools,
              addedTools: addedToolsIds
            })
            this.close();
          }
        }
      },
      error: err => { }
    })
  }

  close() {
    this.closeEmitter.emit({ close: true })
  }
}
