<div class="analytix-box">
    <div class="d-flex justify-content-between align-items-center">
        <h4>Inventory Dashboard</h4>
        <i class="feather ft-message-square msg-icon" (click)="invokeChatSliding('cfo-chat')"></i>
    </div>
    <div class="dashboard-info d-flex">
        <div class="dashboard-filter">
            <label for="" class="label-control">Year</label>
            <span class="p-fluid">
                <p-dropdown (onChange)="changeInFilter($event, 'year')" [(ngModel)]="year" styleClass="allDropdown"
                    appendTo="body" filterBy="name" [options]="yearOptions" optionLabel="name" optionValue="value"
                    [placeholder]="'Select Year'">
                </p-dropdown>
            </span>
        </div>
        <div class="dashboard-filter">
            <label for="" class="label-control">Month</label>
            <span class="p-fluid">
                <p-dropdown (onChange)="changeInFilter($event, 'month')" [(ngModel)]="month" styleClass="allDropdown"
                    appendTo="body" filterBy="name" [options]="monthOptions" optionLabel="name" optionValue="value"
                    [placeholder]="'Select Month'">
                </p-dropdown>
            </span>
        </div>
        <!-- <div class="dashboard-filter">
            <label for="" class="label-control">Location</label>
                <span class="p-fluid">
                    <p-dropdown styleClass="allDropdown" appendTo="body" filterBy="name" [options]="" optionLabel="name"
                        optionValue="value" [showClear]="true" [placeholder]="'Select Location'">
                    </p-dropdown>
                </span>
        </div> -->
    </div>

    <div class="loader-wrapper circular-spinners text-center"
        *ngIf="!inventoryDetails || !inventoryDetails['cards']; else dataLoaded">
        <i class="pi pi-spin pi-spinner"></i>
    </div>

    <ng-template #dataLoaded>
        <div class="dashboard-info d-flex">
            <div class="dashboard-card" *ngIf="inventoryDetails && inventoryDetails['cards']">
                <h5>Inventory</h5>
                <p>{{ inventoryDetails['cards']['Inventory'] | currency: jobDetails[0]['locale'] + ' '
                    :'symbol':'1.0-0'}}</p>
            </div>
            <div class="dashboard-card" *ngIf="inventoryDetails && inventoryDetails['cards']">
                <h5>Stock Available</h5>
                <p>{{ inventoryDetails['cards']['Stock Available'] | currency: jobDetails[0]['locale']+ ' '
                    :'symbol':'1.0-0'}}</p>
            </div>
            <div class="dashboard-card" *ngIf="inventoryDetails && inventoryDetails['cards']">
                <h5>Turnover Ratio</h5>
                <p>{{ inventoryDetails['cards']['Inventory_Turnover'] | currency: jobDetails[0]['locale']+ ' '
                    :'symbol':'1.0-0'}}</p>
            </div>
            <div class="dashboard-card" *ngIf="inventoryDetails && inventoryDetails['cards']">
                <h5>Inventory to Sales Ratio</h5>
                <p>{{ inventoryDetails['cards']['Inventory_to_Sales_Ratio'] | currency: jobDetails[0]['locale']+ ' '
                    :'symbol':'1.0-0'}}</p>
            </div>
            <div class="dashboard-card" *ngIf="inventoryDetails && inventoryDetails['cards']">
                <h5>Average age of Inventory</h5>
                <p>{{ inventoryDetails['cards']['Average_Age_of_Inventory'] | currency: jobDetails[0]['locale']+ ' '
                    :'symbol':'1.0-0'}}</p>
            </div>
        </div>

        <div *ngIf="inventoryChartDetails.length">
            <div class="chart-area mt-2" *ngFor="let chart of inventoryChartDetails">
                <!-- <h5>{{ chart['title'] }}</h5> -->
                <!-- <em *ngIf="chart['preType'] != 'table'" class="pi pi-times" (click)="getInsights(chart)"></em> -->
                <div class="d-flex justify-content-between align-items-center mb-2">
                    <h5 class="m-0">{{ chart['title'] }}</h5>
                    <img class="pointer bulb-light" *ngIf="chart['preType'] != 'table'" src="../../../../assets/images/lightbulb.png" width="32" height="32" alt=""  (click)="getInsights(chart)">
                </div>
                <span class="p-fluid">
                    <p-chart [type]="chart && chart['type'] ? chart['type'] : 'line'" [data]="chart['datasets']"
                        [options]="chart['options']" />
                </span>
            </div>
        </div>
    </ng-template>
</div>

<p-dialog [(visible)]="displayInsights" [resizable]="false" [draggable]="false" [modal]="true" [blockScroll]="true"
    [style]="{width: '55vw'}" appendTo="body">
    <div class="p-dialog-titlebar">
        <button class="p-dialog-titlebar-icon p-dialog-titlebar-close" autofocus="true" aria-label="Close Dialog"
            (click)="displayInsights=false">
            <span class="pi pi-times"></span>
        </button>
    </div>
    <div class="row subscription-plans info-cards">
        <div class="col-12 mb-2">
            <div class="d-flex justify-content-center align-items-center">
                <h3 class="m-0 ml-1"> {{'Insights Based on ' + insightsTitle }}</h3>
            </div>
        </div>
    </div>

    <div *ngIf="loading" class="d-flex justify-content-center mt-5">
        <i class="pi pi-spin pi-spinner" style="font-size: 1.5rem"></i>
    </div>

    <ul *ngIf="!loading" style="list-style: none;">
        <li class="mb-1" *ngFor="let ins of insights"> {{ins}} </li>
    </ul>
</p-dialog>