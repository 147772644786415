import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AutoDashboardService {
  private NodeAPIUrl = environment.apiAuthUrl;
  private autoDash = environment.apiAutoDash;

  constructor(
    private http: HttpClient
  ) { }

  saveAndCreateDashboard(body) {
    return this.http.post<any>(`${this.autoDash}/save_and_create_dashboard`, body)
  }

  getDashboardInfo(params) {
    return this.http.get<any>(`${this.autoDash}/get_dashboard/`, {params})
  }

  postDashJob(body) {
    return this.http.post<any>(`${this.NodeAPIUrl}/dashjob`, body)
  }

  getDashJob(id) {
    return this.http.get<any>(`${this.NodeAPIUrl}/dashjob/${id}`)
  }

}
