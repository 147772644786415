<span aria-label="Zero to Sixty Login Section">
</span>
<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-body">
            <section class="flexbox-container">
                <div class="card-title text-center mb-4 mt-5">
                    <img src="../../../assets/images/logo/new-logo.svg" alt="Zero to Sixty logo" [tabIndex]="1"
                        style="height: 60px;">
                </div>
                <div class="row">
                    <div class="col-12 d-flex align-items-center justify-content-center login-box">
                        <div class="card login border-grey border-lighten-3 box-shadow-2">
                            <div class="card-content">
                                <div class="card-body">
                                    <form class="form-horizontal" [formGroup]="loginForm" (ngSubmit)="tryLogin()"
                                        aria-label="'Login form'">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <h1 class="log-head" title="Login">{{'login.loginheading' | translate}}
                                                </h1>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label>{{ 'inputforms.labels.email' | translate}}<span
                                                            class="mendatory">*</span></label>
                                                    <input type="text" formControlName="email"
                                                        class="form-control mw-100p"
                                                        [ngClass]="{ 'is-invalid': (submitted || f.email.touched) && f.email.errors }"
                                                        id="user-name"
                                                        [placeholder]="'inputforms.placeholder.email' | translate"
                                                        required [tabIndex]="2"
                                                        aria-label="'Please enter your email address in the format name@example.com'">
                                                    <div *ngIf="(submitted || f.email.touched) && f.email.errors"
                                                        class="invalid-feedback" aria-live="assertive">
                                                        <div *ngIf="f.email.errors.required">
                                                            {{ 'inputforms.formerrors.requiredemail' | translate }}
                                                        </div>
                                                        <div *ngIf="f.email.errors.email">
                                                            {{ 'inputforms.formerrors.invalidemail' | translate }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <span class="p-fluid">
                                                        <label>{{ 'inputforms.labels.password' | translate }}<span
                                                                class="mendatory">*</span></label>
                                                        <div class="password-field">
                                                            <input type="{{ showPassword ? 'text' : 'password' }}"
                                                                class="form-control mw-100p" formControlName="password"
                                                                [placeholder]="'inputforms.placeholder.password' | translate "
                                                                [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                                                            <a href="javascript:void(0)" class="eye-icon "
                                                                (click)="togglePasswordVisibility()"
                                                                aria-label="Show Password">
                                                                <i class="eye-icon feather"
                                                                    [ngClass]="{ ' ft-eye': !showPassword, ' ft-eye-off': showPassword }"></i>
                                                            </a>
                                                            <div *ngIf="(submitted || f.password.touched) && f.password.errors"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.password.errors.required">
                                                                    {{ 'inputforms.formerrors.requiredpassword' |
                                                                    translate }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <button type="submit"
                                                        class="btn btn-raised btn-warning login-btn">{{
                                                        'buttontitle.signin' | translate }}
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <div class="card-body">
                                                        <div class="create-id d-flex justify-content"><a
                                                                [routerLink]="['/register']" class="">
                                                                {{'login.createlink' | translate }}</a><a
                                                                href="recover-password.html"
                                                                [routerLink]="['/forgot-password']" class="card-link">{{
                                                                'login.forgotpassword' | translate }}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                    <span class="copyright text-center">{{ 'footer.copyright' | translate }} &copy; {{
                        'footer.brudatainc' |
                        translate }}</span>
                </div>

            </section>

        </div>
    </div>
</div>