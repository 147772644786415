<div class="form-group max-300" [formGroup]="form">
    <div class="d-flex d-flex justify-content-between">
        <label>{{ field['label'] }}</label>
        <div>
            <a href="javascript:void(0)" (click)="displaydependentSelection = true" aria-label="add new dependent field"
                #aAddNew [tabIndex]="field['tabIndex']"
                [attr.data-autofocus]="field['tabIndex'] === 0 ? 'true' : 'false'"
                class="cursor-pointer mb-0 add-new-button">
                {{ 'dependentfield.addnew' | translate }}
            </a>
        </div>
    </div>
    <div class="dependent-add">
        <div *ngIf="dF.length > 0" class="dependent-field">
            <span *ngFor="let item of dF; index as i" class="dependent-filed-pills">
                <i class="feather ft-x mr-1 field-delete" (click)="removeDependentField(item, i)"
                    (keydown.enter)="removeDependentField(item, i)" [tabIndex]="0"></i>
                {{item && item['column_name'] ? item['column_name'] : '' }}
                <i class="feather ft-arrow-right mx-1"></i>
                {{item['type']}}
            </span>
        </div>
    </div>
</div>

<p-dialog [(visible)]="displaydependentSelection" [resizable]="false" [draggable]="false" [modal]="true"
    [blockScroll]="true" [style]="{width: '45vw'}" [autoZIndex]="9999">
    <div class="p-dialog-titlebar">
        <button class="p-dialog-titlebar-icon p-dialog-titlebar-close" aria-label="Close Dialog"
            (click)="displaydependentSelection=false">
            <span class="pi pi-times"></span>
        </button>
    </div>
    <div *ngIf="displaydependentSelection">
        <app-add-semantic-dependent [table]="tableData" (dependentFieldsEmitter)="dependentFields($event)"
            [value]="dF"></app-add-semantic-dependent>
    </div>
</p-dialog>