import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/_services/auth.service';
import { RuleService } from 'src/app/_services/rule.service';

@Component({
  selector: 'app-add-rule',
  templateUrl: './add-rule.component.html',
})
export class AddRuleComponent implements OnInit {

  ruleForm: FormGroup;
  uploadedFiles: File[] = [];
  @Input() jobDetails: Object = {}
  @Input() projectId: string = ''
@Input() tableInfo;
@Output() addRuleEvent = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private ruleService: RuleService
  ) {
    this.ruleForm = this.fb.group({
      ruleName: ['', Validators.required]
    });
  }

  ngOnInit(): void {

  }

  get f() {
    return this.ruleForm.controls;
  }

  // Function to handle file selection and add files to the uploadedFiles array
  handleFileUpload(event: any) {
    const files: FileList = event.files;
    for (let i = 0; i < files.length; i++) {
      this.uploadedFiles.push(files[i]);
    }
  }

  // Function to clear a selected file from the uploadedFiles array
  clearFile(file: File) {
    const index = this.uploadedFiles.indexOf(file);
    if (index !== -1) {
      this.uploadedFiles.splice(index, 1);
    }
  }

  submitRule() {
    const currentUser = this.authService.currentUserValue
    const formData = new FormData();
    formData.append('org_id', currentUser.orgId);
    formData.append('user_id', currentUser.id.toString());
    formData.append('ruleName', this.ruleForm.get('ruleName').value);
    formData.append('project_id', this.projectId);
    formData.append('tableName', this.tableInfo && this.tableInfo['tableName'] ? this.tableInfo['tableName'] : 'File');
    formData.append('file', this.uploadedFiles[0]);
    this.ruleService.addDRLFile(formData).subscribe({
      next: res => {
        this.addRuleEvent.emit(res)
      }
    })

  }
}
