import { Component, ElementRef, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild, inject } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { WizardComponent } from 'angular-archwizard';
import { Toast } from 'src/app/_helpers/toast';
import { AuthService } from 'src/app/_services/auth.service';
import { CustomStrategyService } from 'src/app/_services/custom-strategy.service';
import { OrgService } from 'src/app/_services/org.service';
import { PatternService } from 'src/app/_services/pattern.service';
import { ProjectService } from 'src/app/_services/project.service';
import { SyntheticDataService } from 'src/app/_services/synthetic-data.service';
import { DocumentationSlideService } from 'src/app/_services/documentation-slide.service';

@Component({
    selector: 'app-add-job',
    templateUrl: './add-job.component.html'
})
export class AddJobComponent implements OnInit {
    testConnection: boolean = false;
    jobDetails: Object = {};
    columnsData: Object[] = [];
    displayOccurence: boolean = false;
    symbol: string = '1';
    selectedColumnDetails: Object = {};
    selectedTable: string = '';
    public breadcrumb: any;
    projectId: string = ''
    limit: number = 0;
    patterns: Object[] = [];
    projectName: string;
    orgName: string;
    showTest: boolean;
    tableTestData: Object = {}
    disabledSubmit: boolean = false;
    showRuleTest: boolean = false;
    ruleData: { [x: string]: { [x: string]: any; }; };
    showRuleTestResults: boolean = false;
    testData: any;
    selTable: {} = {}
    fileJob: {} = {};
    showFileTestResult: boolean = false;
    fileTestResult: string;
    fileDecision: boolean = false;
    jobLoading: boolean = false;
    isButtonDisabled = true;
    fileBody: Object = {};
    empty: string = '';
    piiData: Object[] = [];
    routeType: string = '';
    hideViewReport: boolean = true;
    displayPIIReports: boolean = false;
    piiReportData: Object[] = [];
    displayRulesinThird: boolean = false;
    step: string = 'first';
    bias: Object = {}
    @ViewChild(WizardComponent) public wizard: WizardComponent;
    statuses;
    activeUser = '';
    threads = []
    expandStatuses = false;
    expanded = false;
    queriesData: Object[] = []
    @ViewChild('scrollMe') private myScrollContainer: ElementRef;
    latestQues: string = '';
    loading: boolean = false;
    latestThread: string = '';
    @ViewChild('msgInput', { static: false }) msgInput: ElementRef;
    isTyping = false;
    @ViewChild('messageInputHolder') messageInputHolder: ElementRef;
    showChat = false;
    fragment: string = ''
    queryParamNull: boolean;
    anomalyData = {};
    autoMLData = {};
    showConfirmJob: boolean = false;
    jobId: string = "";
    editJobProfile: Object = {};
    isEdit: boolean = false;
    clearSetupJob: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private customStrategyService: CustomStrategyService,
        private syntheticDataService: SyntheticDataService,
        private documentSlideService: DocumentationSlideService,
        private router: Router,
        private authService: AuthService,
        private patternService: PatternService,
        private orgService: OrgService,
        private projectService: ProjectService,
    ) {


        this.route.fragment.subscribe((fragment: string) => {
            console.log('ff', fragment)
            this.fragment = fragment;
            this.clearSetupJob = true;
            if (fragment == 'bias') {
                this.routeType = 'synthesis'
            } else {
                this.routeType = fragment;
            }
            this.jobDetails = {}
            this.step = 'first'
            this.isButtonDisabled = true;
        });

        this.route.params.subscribe((params) => {
            this.projectId = params.projectId
            if (params.projectId == 'p') {
                this.projectId = null;
                this.queryParamNull = true;
            } else {
                this.projectId = params.projectId
                this.queryParamNull = false;
            }
            this.jobId = params.jobId
            if (this.jobId && this.jobId != "") {
                this.getJobDetails();
            }
        })
    }

    ngOnInit() {
        this.getOrgName();
        this.getProjectName();
        this.hitLLM();
        this.documentSlideService.customDocumentName("AddJob");
        this.documentSlideService.setDocumentNameInStorage("AddJob");
    }

    // ngAfterViewInit(): void {
    //     console.log("On ngAfterViewInit");
    // }

    newChat() {
        this.activeUser = ''
    }

    toggleChatContainer() {
        this.showChat = !this.showChat;
    }

    getJobDetails() {
        const currentUser = this.authService.currentUserValue;
        this.syntheticDataService.getSynthesisData({ project_id: this.projectId, job_id: this.jobId, org_id: currentUser.orgId }).subscribe({
            next: r => {
                if (typeof r['records'][0] == "object") {
                    this.editJobProfile = r['records'][0];
                    this.isButtonDisabled = false;
                    this.isEdit = true;
                }
            },
            error: err => {

            }
        })
    }


    previousbuttonNavigation() {
        this.documentSlideService.customDocumentName("AddJob");
        this.step = 'first'
    }

    backButtonNavigation() {
        this.documentSlideService.customDocumentName(this.routeType);
        this.step = 'second'
    }

    nextButtonNavigation() {
        this.documentSlideService.customDocumentName("ConfirmJob");
    }

    hitLLM() {
        // http://127.0.0.1:5000/v1/wakeup_llm
        // this.syntheticDataService.wokeUpLLM().subscribe({
        //     next: res => {

        //     }
        // })
    }

    breadcrumbDecider() {
        let title = ''
        switch (this.routeType) {
            case 'pii':
                title = 'Check PII';
                break;
            case 'synthesis':
                title = 'Synthesis Data';
                break;
            case 'rules':
                title = 'Rules';
                break;
            case 'anomaly':
                title = 'Anomaly';
                break;
            case 'automl':
                title = 'AutoML';
                break;
        }

        this.breadcrumb = {
            'mainlabel': 'Circle Style',
            'links': [
                // {
                //     'name': title,
                //     'isLink': false
                // }, 
                // {
                //     'name': this.projectName ? this.projectName : '',
                //     'isLink': true,
                //     'link': `/projects/${this.projectId}/jobs`
                // },
                // {
                //     'name': '',
                //     'isLink': false
                // }
            ]
        };
    }

    getProjectName() {
        if (this.projectId) {
            this.projectService.getProject(this.projectId).subscribe({
                next: r => {
                    this.projectName = r['projectName']
                    this.breadcrumbDecider();
                }
            })
        }
    }

    getOrgName() {
        const currentUser = this.authService.currentUserValue;
        this.orgService.getOrgById(currentUser.orgId).subscribe({
            next: r => {
                this.orgName = r['name']
                this.breadcrumbDecider();
            }
        })
    }

    jobSetupDetails(event) {
        if (this.isEdit) {
            this.isButtonDisabled = false
            this.jobDetails = event;
        } else {
            if (event && event['projectId'] && this.queryParamNull) {
                this.projectId = event['projectId']
                this.getProjectName();
            }
            // this.routeType = ""
            this.piiReportData = []
            this.hideViewReport = true;
            if (this.jobDetails && this.jobDetails['sourceType'] && this.jobDetails['sourceType'] === event['sourceType']) {
                this.fileDecision = false;
                this.fileJob = {};
            }
            this.jobDetails = event;
            this.displayRulesinThird = false;
            this.showConfirmJob = false;
            this.isButtonDisabled = !(this.jobDetails['locale'] !== null && (this.routeType !== 'anomaly' ? this.projectId !== null : true) && this.jobDetails['jobName'] !== null && this.jobDetails['jobName'] !== '' && ((this.jobDetails['selectSource'] !== null && this.jobDetails['selectSchema'] !== null) || (this.jobDetails['file'].length > 0 || (this.jobDetails['xmlFile'].length > 0 && this.jobDetails['xsdFile'].length > 0))));
            // console.log(this.isButtonDisabled)
        }
        this.clearSetupJob = false;
    }

    goToNextStep() {
        this.documentSlideService.customDocumentName(this.routeType);
        this.routeHandler(this.routeType)
    }

    stepNavigation(data) {
        this.symbol = data
    }

    showtest() {
        this.showTest = false
        this.jobLoading = false
    }
    displayshowRuleTest() {
        this.showRuleTestResults = false
        this.jobLoading = false
    }
    showtfileTest() {
        this.showFileTestResult = false
        this.jobLoading = false
    }

    selectedTableDetails(event) {
        if (this.isEdit) {
            this.selectedColumnDetails = event;
            this.selectedTable = this.selectedColumnDetails['table']['tableName'];
        } else {
            this.selectedColumnDetails = event;
            this.selectedTable = this.selectedColumnDetails['table']['tableName'];
            this.validateFileDBEmpty();
        }
    }

    emitedColumnsDataDetails(event) {
        this.columnsData = event;
    }

    async syntheticDataHandler() {
        this.disabledSubmit = true
        const currentUser = this.authService.currentUserValue;
        if (this.showRuleTest && typeof this.fileJob == 'object' && Object.keys(this.fileJob).length == 0) {
            const currentUser = this.authService.currentUserValue;
            let dt = structuredClone(this.ruleData);
            delete dt?.table
            delete dt?.output_table_name
            let ruleBody = {
                job_name: this.jobDetails['jobName'],
                org_id: currentUser.orgId,
                project_id: this.projectId,
                user_id: currentUser.id,
                locale: this.jobDetails['locale'],
                source_connection: this.jobDetails['selectSource']['id'],
                destination_connection: this.jobDetails['selectSource']['id'],
                source_database: this.jobDetails['selectSource']['database_name'],
                destination_database: this.jobDetails['selectSource']['database_name'],
                source_schema: this.jobDetails['selectSchema'],
                destination_schema: this.jobDetails['selectSchema'],
                source_table: this.ruleData['table']['tableName'],
                destination_table: this.ruleData['table']['tableName'],
                row_limit: this.limit,
                table_data: dt,
                columns_data: null,
                extra_info: null,
                bias: this.bias
            }
            this.saveSyntheticData(ruleBody)

        } else if (this.fileJob && this.fileJob['job_id']) {
            let type = 'file'
            if (this.routeType == 'anomaly') {
                type = 'anomaly'
            } else if (this.routeType == 'automl') {
                type = 'automl'
            }
            this.syntheticDataService.setRowLimit({ job_id: this.fileJob['job_id'], row_limit: this.limit, bias: this.bias }).subscribe({
                next: res => {
                    if (res['message'] == "Row limit entered successfully") {
                        setTimeout(() => {
                            this.syntheticDataService.generateSyntheticData({ job_id: this.fileJob['job_id'], project_id: this.projectId, org_id: currentUser.orgId, type: type }).subscribe({
                                next: result => {
                                    this.showConfirmJob = false;
                                    if (typeof result['message'] == 'string' && result['message'].startsWith("The")) {
                                        Toast.fire({
                                            icon: 'success',
                                            title: "Success",
                                            html: 'Workflow Submission Successful.'
                                        });
                                    } else {
                                        Toast.fire({
                                            icon: 'info',
                                            html: result['message']
                                        });
                                    }
                                    this.router.navigate([`/projects/${this.projectId}/jobs`])
                                }
                            })
                        }, 500)
                    }
                }
            })
        } else {
            let colReqBody = this.columnsData.map(obj => ({ ...obj }));
            let index = 0
            for (let i = 0; i < colReqBody.length; i++) {
                index = this.patterns.findIndex((col) => col['column'] == colReqBody[i]['source_column'])
                if (index == -1) {
                    if (colReqBody[i]['columnlet_type'] != 'NA') {
                        let reqBody = {
                            org_id: currentUser.orgId,
                            project_id: this.projectId,
                            user_id: currentUser.id,
                            pattern_name: null,
                            semantic_group: null,
                            tokenization_type: colReqBody[i]['columnlet_type'],
                            delimiter_value: colReqBody[i]['delimiter_value'],
                            columnlets_pattern: colReqBody[i]['columnlets_data']
                        }
                        let result = await this.patternService.addPatternAsync(reqBody);
                        if (result['pattern_id']) {
                            colReqBody[i]['pattern_id'] = result['pattern_id']
                            colReqBody[i]['dependent_fields'] = null;
                            colReqBody[i]['strategy_id'] = null;
                            colReqBody[i]['is_set_strategy'] = 1
                            colReqBody[i]['sk'] = ''
                            delete colReqBody[i]['columnlets_data'];
                            delete colReqBody[i]['columnlet_type'];
                            delete colReqBody[i]['delimiter_value'];
                        } else {
                            // TODO document why this block is empty
                        }
                    } else {

                        colReqBody[i]['pattern_id'] = null
                        colReqBody[i]['strategy_id'] = colReqBody[i]['columnlets_data'][0]['strategy_id'];
                        colReqBody[i]['dependent_fields'] = colReqBody[i]['columnlets_data'][0]['dependent_fields'];
                        colReqBody[i]['is_set_strategy'] = 1
                        colReqBody[i]['sk'] = colReqBody[i]['columnlets_data'][0]['sk'] ? colReqBody[i]['columnlets_data'][0]['sk'] : '';
                        delete colReqBody[i]['columnlets_data'];
                        delete colReqBody[i]['columnlet_type'];
                        delete colReqBody[i]['delimiter_value'];
                    }
                } else {

                    colReqBody[i]['pattern_id'] = this.patterns[index]['pattern_id']
                    colReqBody[i]['dependent_fields'] = null;
                    colReqBody[i]['strategy_id'] = null;
                    colReqBody[i]['is_set_strategy'] = 1
                    colReqBody[i]['sk'] = ''
                    delete colReqBody[i]['columnlets_data'];
                    delete colReqBody[i]['columnlet_type'];
                    delete colReqBody[i]['delimiter_value'];
                }
            }
            let body = {
                job_name: this.jobDetails['jobName'],
                org_id: currentUser.orgId,
                project_id: this.projectId,
                user_id: currentUser.id,
                locale: this.jobDetails['locale'],
                source_connection: this.jobDetails['selectSource']['id'],
                destination_connection: this.jobDetails['selectSource']['id'],
                source_database: this.jobDetails['selectSource']['database_name'],
                destination_database: this.jobDetails['selectSource']['database_name'],
                source_schema: this.jobDetails['selectSchema'],
                destination_schema: this.jobDetails['selectSchema'],
                source_table: this.selectedTable,
                destination_table: this.selectedTable,
                row_limit: this.limit,
                table_data: null,
                bias: this.bias,
                extra_info: this.routeType == 'synthesis' ? null : this.routeType == 'anomaly' ? { anomalies: this.anomalyData['anomalies'] } : this.routeType == 'automl' ? { auto_ml: this.autoMLData } : null,
                columns_data: this.routeType == 'synthesis' ? colReqBody : this.routeType == 'anomaly' ? this.anomalyData['columnData'] : null,
            }
            this.saveSyntheticData(body)
        }
    }

    saveSyntheticData(body) {
        const currentUser = this.authService.currentUserValue;
        if (this.isEdit) {
            body['id'] = this.jobId;
            this.syntheticDataService.updateSyntheticData(body).subscribe({
                next: res => {
                    Toast.fire({
                        icon: 'success',
                        html: res.message
                    });
                    let type = 'database'
                    if (this.routeType == 'anomaly') {
                        type = 'anomaly'
                    } else if (this.routeType == 'automl') {
                        type = 'automl'
                    }
                    if (res['message'] == 'Job updated successfully') {
                        this.syntheticDataService.generateSyntheticData({ job_id: this.jobId, project_id: this.projectId, org_id: currentUser.orgId, type: type }).subscribe({
                            next: result => {
                                this.showConfirmJob = false;
                                if (typeof result['message'] == 'string' && result['message'].startsWith("The")) {
                                    Toast.fire({
                                        icon: 'success',
                                        title: "Success",
                                        html: 'Workflow Submission Successful.'
                                    });
                                    this.disabledSubmit = true
                                } else {
                                    Toast.fire({
                                        icon: 'info',
                                        html: result['message']
                                    });
                                    this.disabledSubmit = true
                                }
                                this.router.navigate([`/projects/${this.projectId}/jobs`])
                            }, error: err => { }
                        })
                    }
                }
            })
        } else {
            this.syntheticDataService.addSyntheticData(body).subscribe({
                next: res => {
                    Toast.fire({
                        icon: 'success',
                        html: res.message
                    });
                    let type = 'database'
                    if (this.routeType == 'anomaly') {
                        type = 'anomaly'
                    } else if (this.routeType == 'automl') {
                        type = 'automl'
                    }
                    if (res['job_id']) {
                        this.syntheticDataService.generateSyntheticData({ job_id: res['job_id'], project_id: this.projectId, org_id: currentUser.orgId, type: type }).subscribe({
                            next: result => {
                                this.showConfirmJob = false;
                                if (typeof result['message'] == 'string' && result['message'].startsWith("The")) {
                                    Toast.fire({
                                        icon: 'success',
                                        title: "Success",
                                        html: 'Workflow Submission Successful.'
                                    });
                                    this.disabledSubmit = true
                                } else {
                                    Toast.fire({
                                        icon: 'info',
                                        html: result['message']
                                    });
                                    this.disabledSubmit = true
                                }
                                this.router.navigate([`/projects/${this.projectId}/jobs`])
                            },
                            error: err => {
                                Toast.fire({
                                    icon: 'error',
                                    html: err.error.error.text
                                });
                            }
                        })
                    }
                },
                error: err => {
                    Toast.fire({
                        icon: 'error',
                        html: err.error.error.text
                    });
                }
            })
        }
    }

    routerToJobs() {
        this.router.navigate([`/projects/${this.projectId}/jobs`])
    }

    openLink() {
        // this.documentSlideService.customDocumentName(this.routeType);
    }

    opensetLink(event) {
        // this.documentSlideService.customDocumentName("document");
    }

    limitData(r) {
        this.limit = r;
    }

    patternHandler(event) {
        this.patterns.push(event)
    }

    async tableTest() {
        this.jobLoading = true
        if (this.showRuleTest && Object.keys(this.fileJob).length == 0) {
            let tableData = {
                rule_id: this.ruleData['rule_id'],
                input_fields: this.ruleData['input_fields']
            }
            let body = {
                locale: this.jobDetails['locale'],
                source_connection: this.jobDetails['selectSource']['id'],
                source_database: this.jobDetails['selectSource']['database_name'],
                source_schema: this.jobDetails['selectSchema'],
                source_table: this.ruleData['table']['tableName'],
                row_limit: 10,
                table_data: tableData,
                extra_info: null,
                columns_data: null
            }
            this.syntheticDataService.syntheticTableTestData(body).subscribe({
                next: res => {
                    if (typeof res['message'] === 'string') {
                        Toast.fire({
                            icon: 'error',
                            html: res['message']
                        });
                        this.jobLoading = false;
                    } else {
                        // this.tableTestData = res['message']
                        // this.showTest = true;
                        this.showRuleTestResults = true;
                        this.testData = res['message']
                        this.jobLoading = false;
                    }
                }, error: err => {
                    this.jobLoading = false;
                }   

            })
        } else if (this.fileJob['job_id']) {
            await this.enterThirdStep('test');
            setTimeout(() => {
                this.syntheticDataService.syntheticTableTestDataFile({ job_id: this.fileJob['job_id'], test_rows: 10 }).subscribe({
                    next: res => {
                        this.fileTestResult = res;
                        this.showFileTestResult = true;
                        this.jobLoading = false;
                    }, error: err => {
                    }
                })
            }, 3000)
        } else {
            let colObj = []
            if (this.columnsData.length > 0) {
                for (let i = 0; i < this.columnsData.length; i++) {
                    let obj = {};
                    if (this.columnsData[i]['columnlet_type'] == 'NA') {
                        obj['source_column'] = this.columnsData[i]['source_column']
                        obj['destination_column'] = this.columnsData[i]['destination_column']
                        obj['pattern_id'] = null
                        obj['strategy_id'] = this.columnsData[i]['columnlets_data'][0]['strategy_id']
                        obj['dependent_fields'] = this.columnsData[i]['columnlets_data'][0]['dependent_fields']
                        obj['sk'] = ''
                    } else {
                        obj['source_column'] = this.columnsData[i]['source_column']
                        obj['destination_column'] = this.columnsData[i]['destination_column']
                        obj['strategy_id'] = null
                        obj['dependent_fields'] = null
                        obj['sk'] = ''
                        let d = {}
                        d['tokenization_type'] = this.columnsData[i]['columnlet_type']
                        d['semantic_group'] = this.columnsData[i]['columnlets_data'][0]['semantic_group']
                        d['delimiter_value'] = this.columnsData[i]['delimiter_value']
                        d['columnlets_pattern'] = this.columnsData[i]['columnlets_data']
                        obj['pattern_id'] = d
                    }
                    colObj.push(obj);
                }
                this.syntheticDataService.syntheticTableTestData({
                    source_connection: this.jobDetails['selectSource']['id'],
                    source_database: this.jobDetails['selectSource']['database_name'],
                    source_schema: this.jobDetails['selectSchema'],
                    source_table: this.selectedTable,
                    locale: this.jobDetails['locale'],
                    row_limit: 10,
                    table_data: null,
                    extra_info: null,
                    columns_data: colObj
                }).subscribe({
                    next: res => {
                        if (typeof res['message'] === 'string') {
                            Toast.fire({
                                icon: 'error',
                                html: res['message']
                            });
                            this.jobLoading = false;
                        } else {
                            this.tableTestData = res['message']
                            this.showTest = true;
                            this.jobLoading = false;
                        }
                    }, error: err => {

                    }
                })
            }
        }
    }

    onDeleteCascade() {
        this.disabledSubmit = false;
        let colObj = []
        if (this.columnsData.length > 0) {
            for (let i = 0; i < this.columnsData.length; i++) {
                let obj = {};
                if (this.columnsData[i]['columnlet_type'] == 'NA') {
                    obj['source_column'] = this.columnsData[i]['source_column']
                    obj['destination_column'] = this.columnsData[i]['destination_column']
                    obj['pattern'] = null;
                    obj['strategy_id'] = this.columnsData[i]['columnlets_data'][0]['strategy_id']
                    obj['dependent_fields'] = this.columnsData[i]['columnlets_data'][0]['dependent_fields']
                } else {
                    obj['source_column'] = this.columnsData[i]['source_column']
                    obj['destination_column'] = this.columnsData[i]['destination_column']
                    obj['strategy_id'] = null
                    obj['dependent_fields'] = null
                    let d = {}
                    d['tokenization_type'] = this.columnsData[i]['columnlet_type']
                    d['semantic_group'] = this.columnsData[i]['columnlets_data'][0]['semantic_group']
                    d['delimiter_value'] = this.columnsData[i]['delimiter_value']
                    d['columnlets_pattern'] = this.columnsData[i]['columnlets_data']
                    obj['pattern'] = d
                }
                colObj.push(obj);
            }
            if (this.jobDetails && this.jobDetails['selectSource'] && this.jobDetails['selectSource']['id'] && this.jobDetails['selectSource'] && this.jobDetails['selectSource']['database_name'] && this.jobDetails['selectSchema'] && this.selectedTable && this.jobDetails['locale']) {
                this.syntheticDataService.validateCascade({
                    source_connection: this.jobDetails['selectSource']['id'],
                    source_database: this.jobDetails['selectSource']['database_name'],
                    source_schema: this.jobDetails['selectSchema'],
                    source_table: this.selectedTable,
                    locale: this.jobDetails['locale'],
                    columns_data: colObj
                }).subscribe({
                    next: res => {
                        if (res['message']) {
                            this.disabledSubmit = true;
                            Toast.fire({
                                icon: 'warning',
                                title: 'Warning',
                                html: res['message']
                            });
                        }
                    }, error: err => {

                    }
                })
            }
        }
    }

    scrollToTop() {
        window.scrollTo(0, 0);
    }

    async enterThirdStep(buttonType) {
        if (buttonType == 'submit') {
            this.step = 'third'
        }
        // this.scrollToTop();
        const currentUser = this.authService.currentUserValue;
        if (this.fileJob['job_id'] && this.routeType == 'synthesis') {
            let colReqBody = this.columnsData.map(obj => ({ ...obj }));
            let index = 0
            for (let i = 0; i < colReqBody.length; i++) {
                index = this.patterns.findIndex((col) => col['column'] == colReqBody[i]['source_column'])
                if (index == -1) {
                    if (colReqBody[i]['columnlet_type'] != 'NA') {
                        let reqBody = {
                            org_id: currentUser.orgId,
                            project_id: this.projectId,
                            user_id: currentUser.id,
                            pattern_name: null,
                            semantic_group: null,
                            tokenization_type: colReqBody[i]['columnlet_type'],
                            delimiter_value: colReqBody[i]['delimiter_value'],
                            columnlets_pattern: colReqBody[i]['columnlets_data']
                        }
                        let result = await this.patternService.addPatternAsync(reqBody);
                        if (result['pattern_id']) {
                            colReqBody[i]['pattern_id'] = result['pattern_id']
                            colReqBody[i]['dependent_fields'] = null;
                            colReqBody[i]['strategy_id'] = null;
                            colReqBody[i]['is_set_strategy'] = 1;
                            colReqBody[i]['sk'] = ''
                            delete colReqBody[i]['columnlets_data'];
                            delete colReqBody[i]['columnlet_type'];
                            delete colReqBody[i]['delimiter_value'];
                        } else {
                            // TODO document why this block is empty
                        }
                    } else {
                        colReqBody[i]['pattern_id'] = null
                        colReqBody[i]['strategy_id'] = colReqBody[i]['columnlets_data'][0]['strategy_id'];
                        colReqBody[i]['dependent_fields'] = colReqBody[i]['columnlets_data'][0]['dependent_fields'];
                        colReqBody[i]['is_set_strategy'] = 1
                        colReqBody[i]['sk'] = colReqBody[i]['columnlets_data'][0]['sk'] ? colReqBody[i]['columnlets_data'][0]['sk'] : '';
                        delete colReqBody[i]['columnlets_data'];
                        delete colReqBody[i]['columnlet_type'];
                        delete colReqBody[i]['delimiter_value'];
                    }
                } else {
                    colReqBody[i]['pattern_id'] = this.patterns[index]['pattern_id']
                    colReqBody[i]['dependent_fields'] = null;
                    colReqBody[i]['strategy_id'] = null;
                    colReqBody[i]['sk'] = ''
                    colReqBody[i]['is_set_strategy'] = 1
                    delete colReqBody[i]['columnlets_data'];
                    delete colReqBody[i]['columnlet_type'];
                    delete colReqBody[i]['delimiter_value'];
                }
            }
            let body = {
                job_name: this.jobDetails['jobName'],
                org_id: currentUser.orgId,
                project_id: this.projectId,
                user_id: currentUser.id,
                locale: this.jobDetails['locale'],
                table_data: null,
                columns_data: colReqBody,
                extra_info: null,
                job_id: this.fileJob['job_id'],
            }
            // fileBody is for check the empty csv 
            this.fileBody = body;
            if (this.empty == 'empty') {
                let strategies = await this.customStrategyService.getStrategiesAsync({ org_id: currentUser.orgId, project_id: this.projectId, locale: this.jobDetails['locale'] })
                if (Array.isArray(this.piiData) && this.piiData.length > 0) {
                    for (let i = 0; i < this.piiData.length; i++) {
                        if (this.fileBody && Array.isArray(this.fileBody['columns_data'])) {
                            if (this.fileBody['columns_data'].findIndex(r => r['source_column'] == this.piiData[i]['column_name']) == -1) {
                                let strat = null;
                                if (Array.isArray(strategies)) {
                                    strat = strategies.findIndex(r => r['strategy_name'] == this.piiData[i]['semantic_strategy'])
                                }
                                if (strat != null && strat != -1) {
                                    this.fileBody['columns_data'].push({
                                        dependent_fields: [],
                                        destination_column: this.piiData[i]['column_name'],
                                        is_set_strategy: 0,
                                        pattern_id: null,
                                        sk: '',
                                        source_column: this.piiData[i]['column_name'],
                                        strategy_id: strategies[strat]['id']
                                    })
                                }
                            }
                        }
                    }
                }
            }
            this.syntheticDataService.addColumnConfig(this.fileBody).subscribe({
                next: res => {
                    if (buttonType == 'submit') {
                        this.syntheticDataHandler()
                    }
                }, error: err => {

                }
            })
        } else if (this.fileJob['job_id'] && this.routeType == 'rules') {
            this.displayRulesinThird = true;
            let dt = structuredClone(this.ruleData);
            delete dt?.table
            delete dt?.output_table_name
            let body = {
                job_name: this.jobDetails['jobName'],
                org_id: currentUser.orgId,
                project_id: this.projectId,
                user_id: currentUser.id,
                locale: this.jobDetails['locale'],
                table_data: dt,
                columns_data: null,
                extra_info: null,
                job_id: this.fileJob['job_id'],
            }
            this.fileBody = body
            this.syntheticDataService.addColumnConfig(this.fileBody).subscribe({
                next: res => {
                    if (buttonType == 'submit') {
                        this.syntheticDataHandler()
                    }
                }, error: err => {

                }
            })
        } else if (this.fileJob['job_id'] && this.routeType == 'anomaly') {
            let body = {
                job_name: this.jobDetails['jobName'],
                org_id: currentUser.orgId,
                project_id: this.projectId,
                user_id: currentUser.id,
                locale: this.jobDetails['locale'],
                table_data: null,
                columns_data: this.anomalyData['columnData'],
                job_id: this.fileJob['job_id'],
                extra_info: { anomalies: this.anomalyData['anomalies'] }
            }
            this.fileBody = body
            this.syntheticDataService.addColumnConfig(this.fileBody).subscribe({
                next: res => {
                    if (buttonType == 'submit') {
                        this.syntheticDataHandler()
                    }
                }, error: err => {

                }
            })
        } else if (this.fileJob['job_id'] && this.routeType == 'automl') {
            let body = {
                job_name: this.jobDetails['jobName'],
                org_id: currentUser.orgId,
                project_id: this.projectId,
                user_id: currentUser.id,
                locale: this.jobDetails['locale'],
                table_data: null,
                columns_data: null,
                job_id: this.fileJob['job_id'],
                extra_info: { auto_ml: this.autoMLData }
            }
            this.fileBody = body
            this.syntheticDataService.addColumnConfig(this.fileBody).subscribe({
                next: res => {
                    if (buttonType == 'submit') {
                        this.syntheticDataHandler()
                    }
                }, error: err => {

                }
            })
        } else {
            if (buttonType == 'submit') {
                this.syntheticDataHandler()
                this.onDeleteCascade();
            }
        }
        // this.nextButtonNavigation();
    }


    async fileBodyUpdate() {
        const currentUser = this.authService.currentUserValue;
        let strategies = await this.customStrategyService.getStrategiesAsync({ org_id: currentUser.orgId, project_id: this.projectId, locale: this.jobDetails['locale'] })
        if (Array.isArray(this.piiData) && this.piiData.length > 0) {
            for (let i = 0; i < this.piiData.length; i++) {
                if (this.fileBody && Array.isArray(this.fileBody['columns_data'])) {
                    if (this.fileBody['columns_data'].findIndex(r => r['source_column'] == this.piiData[i]['column_name']) == -1) {
                        let strat = null;
                        if (Array.isArray(strategies)) {
                            strat = strategies.findIndex(r => r['strategy_name'] == this.piiData[i]['semantic_strategy'])
                        }
                        if (strat != null && strat != -1) {
                            this.fileBody['columns_data'].push({
                                dependent_fields: [],
                                destination_column: this.piiData[i]['column_name'],
                                is_set_strategy: 0,
                                pattern_id: null,
                                sk: '',
                                source_column: this.piiData[i]['column_name'],
                                strategy_id: strategies[strat]['id']
                            })
                        }
                    }
                }
            }
        }
    }

    ruleEvent(event) {
        this.showRuleTest = true;
        this.ruleData = event;
    }

    tableInfo(event) {
        this.selTable = event
    }

    enterSecondStep() {
        this.step = 'second'
        // For PII
        if (Array.isArray(this.piiReportData) && this.piiReportData.length > 0) {
            this.hideViewReport = false;
        }
        this.scrollToTop();
        this.step1();
    }

    step1() {
        this.documentSlideService.customDocumentName(this.routeType);
    }

    validateFileDBEmpty() {
        const currentUser = this.authService.currentUserValue
        let params = {}
        if (this.fileJob && this.fileJob['job_id']) {
            params = {
                job_id: this.fileJob['job_id'],
                org_id: currentUser.orgId
            }
        } else {
            params = {
                org_id: currentUser.orgId,
                connection_id: this.jobDetails['selectSource']['id'],
                source_schema: this.jobDetails['selectSchema'],
                source_table: this.selectedTable,
            }
        }
        this.syntheticDataService.isFileDBEmpty(params).subscribe({
            next: res => {
                this.empty = res['message']
            }, error: err => {

            }
        })
    }

    piiInfoHandler(event) {
        this.piiData = event;
    }

    routeHandler(type) {
        if (this.isEdit) {
            if (this.editJobProfile && this.editJobProfile['file_extension']) {
                this.fileJob = { job_id: this.jobId };
                this.fileDecision = true;
            }
            this.enterSecondStep()
        } else {
            this.hideViewReport = true;
            this.routeType = type;
            this.documentSlideService.customDocumentName(this.routeType);
            const currentUser = this.authService.currentUserValue
            if (Object.keys(this.fileJob).length === 0 && !this.fileDecision) {
                if (this.jobDetails['sourceType'] !== 'database') {
                    const formData = new FormData();
                    formData.append('org_id', currentUser.orgId);
                    formData.append('locale', this.jobDetails['locale']);
                    formData.append('project_id', this.projectId);
                    formData.append('path', '/add_file');
                    formData.append('job_name', this.jobDetails['jobName']);
                    formData.append('user_id', currentUser.id.toString())
                    if (this.jobDetails['sourceType'] === 'xml') {
                        formData.append('source_file', this.jobDetails['xmlFile'][0]);
                        if (this.jobDetails['xsdFile'].length > 0) {
                            formData.append('xsd', this.jobDetails['xsdFile'][0]);
                            formData.append('is_xsd', '1')
                        } else {
                            formData.append('is_xsd', '0')
                        }
                    } else {
                        formData.append('source_file', this.jobDetails['file'][0]);
                    }
                    this.syntheticDataService.addfile(formData).subscribe({
                        next: (res) => {
                            // Handle success response
                            if (res['message'] && res['job_id']) {
                                this.fileJob = res;
                                this.fileDecision = true;
                                this.enterSecondStep()
                                this.validateFileDBEmpty();
                                // this.wizard.goToStep(1);
                            } else {
                                Toast.fire({
                                    icon: 'error',
                                    html: res['message']
                                });
                            }
                        },
                        error: (error) => {
                            console.error('Error:', error);
                            // Handle error response
                        }
                    });
                } else {
                    // this.wizard.goToStep(1);
                    this.enterSecondStep()
                }
            } else {
                // this.wizard.goToStep(1);
                this.enterSecondStep()
            }
        }
    }

    reportHandler(data) {
        this.hideViewReport = Array.isArray(data) && data.length > 0 ? false : true;
        this.piiReportData = data;
    }

    closePIIReport() {
        this.displayPIIReports = false;
    }

    biasEvent(event) {
        this.bias = event
    }

    setUserActive(user) {
        this.activeUser = user;

    }

    anomalyEvent(event) {
        this.anomalyData = event;
        if (event && event['anomalies'] && (event['anomalies']['changes'] || event['anomalies']['target'] || (event['columnData'] && event['columnData'].length))) {
            this.showConfirmJob = true;
        } else {
            this.showConfirmJob = false;
        }
    }

    anomalyObjectProps() {
        return Object.keys(this.anomalyData).length
    }

    autoMLEvent(event) {
        this.autoMLData = event;
        if ((event['meta'] && Object.keys(event['meta']).length) || event['target']) {
            this.showConfirmJob = true;
        } else {
            this.showConfirmJob = false;
        }
    }

    modelTypeEmitter(event) {
        this.autoMLData['type'] = event;
    }
}
