import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { locales } from 'src/app/_helpers/schemas';
import { Toast } from 'src/app/_helpers/toast';
import { AuthService } from 'src/app/_services/auth.service';
import { DbConnectionsService } from 'src/app/_services/db-connections.service';
import { DocumentationSlideService } from 'src/app/_services/documentation-slide.service';
import { ProjectService } from 'src/app/_services/project.service';
import { SyntheticDataService } from 'src/app/_services/synthetic-data.service';

@Component({
  selector: 'app-data-cleansing',
  templateUrl: './data-cleansing.component.html'
})
export class DataCleansingComponent {
  connections: Object[] = [];
  displayDBModal: boolean = false;
  setupJobForm: FormGroup;
  dbDetails: Object = {};
  clearDBForm: boolean = false;
  localeList: Object[] = locales.map((obj) => ({ ...obj }));
  @Input() projectId: string = null;
  showSelectSchema: boolean = false;
  schemaList: string[] = [];
  submitted = false;
  selectedConnection: any;
  sourceTypeList = [
    { name: "Database", value: "database" },
    // { name: "XML File", value: "xml" },
    { name: "JSON File", value: "json" },
    { name: "CSV/XLS File", value: "csv" },
  ];
  type: string;
  uploadedFiles: any[] = [];
  xsdFiles: File[] = [];
  xmlFiles: File[] = [];
  filteredOptions: any[];
  selectedOption: any;
  spinner: boolean = false;
  selectedKey: string;
  workspacesOptions = [];
  hideWorkspaces: boolean;
  @Input() queryParamNull: boolean;
  showSelectTable: boolean = false;
  tableList: string[] = [];
  basicJob = {}
  showLoading: boolean = false;
  isButtonDisabled: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private dbConnectionsService: DbConnectionsService,
    private documentSlideService: DocumentationSlideService,
    private authService: AuthService,
    private projectService: ProjectService,
    private syntheticDataService: SyntheticDataService,
    private router: Router
  ) {
    this.setupJobForm = this.formBuilder.group({
      jobName: new FormControl(null, Validators.required),
      selectSource: new FormControl(null, Validators.required),
      projectId: new FormControl(null, Validators.required),
      selectSchema: new FormControl(),
      selectTable: new FormControl(),
      sourceType: new FormControl(null),
    });
  }
  ngOnDestroy(): void {
    this.setupJobForm.reset();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.projectId) {
      this.hideWorkspaces = false;
      this.setupJobForm.patchValue({ projectId: this.projectId })
    } else {
      this.hideWorkspaces = true;
    }

    if (changes['routeType']) {
      this.setupJobForm.reset();
      this.uploadedFiles = [];
      this.xmlFiles = [];
      this.xsdFiles = [];
      this.type = ''
    }

    // console.log(changes, this.queryParamNull, "changes");
  }

  ngOnInit(): void {
    this.getDBConnections();
    this.getWorkspaces();
  }

  fetchDBs() {
    this.dbConnectionsService.getDBConnections({ project_id: this.setupJobForm['value']['projectId'], org_id: this.authService.currentUserValue.orgId }).subscribe({
      next: (res: any) => {
        if (res) {
          this.connections = res;
          this.setupJobForm.patchValue({ selectSource: null })
          this.emitter();
        }
      },
      error: err => {
        Toast.fire({
          icon: 'error',
          html: err.error.text
        });
      }
    })
  }

  getWorkspaces() {
    this.projectService.getAllProject({}).subscribe({
      next: res => {
        if (res && res['records']) {
          this.workspacesOptions = []
          res['records'].forEach(ele => {
            this.workspacesOptions.push({
              name: ele['projectName'],
              value: ele['id']
            })
          })
        }
      }, error: err => {

      }
    })
  }


  typeHandler() {
    this.type = this.setupJobForm.value['sourceType']
    this.setupJobForm.patchValue({ selectSchema: null, selectSource: null })
    this.uploadedFiles = []
    this.xmlFiles = []
    this.xsdFiles = []
    this.showSelectSchema = false;
    this.showSelectTable = false;
    this.emitter()
    this.displayDocument(this.type);
  }

  displayDocument(type: string) {
    if (type == "database") {
      this.documentSlideService.customDocumentName("AddJobWithDB");
    } else {
      this.documentSlideService.customDocumentName("AddJob");
    }
  }

  selectedOptionChanged(event: any) {
    if (event && typeof event === 'object') {
      this.setupJobForm.get('locale').setValue(event.value, { emitEvent: false });
    } else {
      this.setupJobForm.get('locale').setValue(null, { emitEvent: false });
    }
    this.setupJobEmitter('');
  }

  filterOptions(event: any) {
    if (event.query) {
      const filteredValues = this.localeList.filter((option) =>
        option['name'].toLowerCase().includes(event.query.toLowerCase())
      );
      this.filteredOptions = filteredValues;
    } else {
      this.filteredOptions = this.localeList;
    }
  }

  getDBConnections() {
    if (this.projectId) {
      this.dbConnectionsService.getDBConnections({ project_id: this.projectId, org_id: this.authService.currentUserValue.orgId }).subscribe({
        next: (res: any) => {
          if (res) {
            this.connections = res;
          }
        },
        error: err => {
          Toast.fire({
            icon: 'error',
            html: err.error.text
          });
        }
      })
    }
  }

  onDropdownClick(): void {
    if (this.connections.length === 0) {
      // Perform click operations or other actions
      // console.log('Dropdown clicked, but options are empty.');
      this.showDialog()
    }
  }

  setupJobEmitter(data) {
    if (data == 'change') {
      this.setupJobForm.value['selectSchema'] = null
    }
    if (this.setupJobForm.value['selectSchema'] == undefined) {
      this.showSelectSchema = false;
      if (this.setupJobForm.value['selectSource'] && (this.setupJobForm.value['selectSource']['driver_name'] == 'mssql' || this.setupJobForm.value['selectSource']['driver_name'] == 'postgresql' || this.setupJobForm.value['selectSource']['driver_name'] == 'ibmdb2')) {
        this.schemaList = []
        this.dbConnectionsService.getSchemas(this.setupJobForm.value['selectSource']['id']).subscribe({
          next: (res: any) => {
            this.setupJobForm.patchValue({ selectSchema: null })
            this.schemaList = res;
            this.showSelectSchema = true;
          }
        })
      } else if (this.setupJobForm.value['selectSource'] && this.setupJobForm.value['selectSource']['driver_name'] == 'oracle') {
        this.schemaList = []
        this.dbConnectionsService.getSchemas(this.setupJobForm.value['selectSource']['id']).subscribe({
          next: (res: any) => {
            this.setupJobForm.patchValue({ selectSchema: null })
            this.schemaList = res;
            this.showSelectSchema = true;
          }
        })
        // this.setupJobForm.get('selectSchema').patchValue(this.setupJobForm.value['selectSource']['username_name'])
      } else {
        if (this.setupJobForm.value['selectSource'] && this.setupJobForm.value['selectSource']['database_name']) {
          this.setupJobForm.get('selectSchema').patchValue(this.setupJobForm.value['selectSource']['database_name'])
        }
      }
    }

    if (
      this.setupJobForm.value["selectSource"] &&
      this.setupJobForm.value["selectSource"]["driver_name"] == "salesforce"
    ) {
      this.showSelectSchema = false;
      this.setupJobForm.patchValue({ selectSchema: "-" });
    }

    if (!this.setupJobForm.value["selectSource"]) {
      this.setupJobForm.get("selectSchema").patchValue(null);
    }

    if (this.setupJobForm.value['selectSchema']) {
      this.fetchTables();
    }

    this.emitter();
  }

  showDialog() {
    // (onHide)="onDropdownClick()"
    this.dbDetails = null;
    this.clearDBForm = true;
    this.displayDBModal = true;
  }

  close() {
    this.clearDBForm = false;
    this.displayDBModal = false;
    this.dbDetails = null;
  }

  selectConnection(option: any) {
    this.selectedConnection = option;
    this.setupJobForm.get("selectSource").setValue(option.connection_name);
  }

  savedDBConnections(event) {
    if (event.message === "new connection successfully added") {
      Toast.fire({
        icon: "success",
        html: event.message,
      });
      this.getDBConnections();
      this.close();
    } else if (event.message === "connection successfully updated") {
      this.getDBConnections();
      this.close();
    }
  }

  editDB(option) {
    this.dbDetails = option;
    this.clearDBForm = false;
    this.displayDBModal = true;
  }

  deleteDB(option) {
    if (option["id"]) {
      this.dbConnectionsService
        .deleteDBConnection({
          ids: option["id"],
          project_id: this.projectId,
          org_id: this.authService.currentUserValue.orgId,
        })
        .subscribe({
          next: (res) => {
            if (
              res.message ==
              "Cannot perform the requested action. The record cannot be deleted or updated because it is referenced by other jobs"
            ) {
              Toast.fire({
                icon: "warning",
                html: res.message,
              });
            } else {
              Toast.fire({
                icon: "success",
                html: res.message,
              });
            }
            this.setupJobForm.get("selectSource").patchValue(null);
            this.setupJobForm.get("selectSchema").patchValue(null);
            this.showSelectSchema = false;
            this.showSelectTable = false;
            this.getDBConnections();
          },
          error: (err) => {
            Toast.fire({
              icon: "error",
              html: err.error.text,
            });
          },
        });
    }
  }

  async fetchTables() {
    this.showSelectTable = false;
    if (this.setupJobForm.value["selectSource"] && this.setupJobForm.value["jobName"]) {
      this.syntheticDataService.getTables({
        connection_id: this.setupJobForm.value["selectSource"]["id"],
        schema_name: this.setupJobForm.value["selectSchema"],
      })
        .subscribe({
          next: (res) => {
            this.showSelectTable = true;
            this.tableList = res;
          },
          error: (err) => {

          },
        });
    }
  }

  myUploader(event: any) {
    this.uploadedFiles = [event.files[0]];
    this.emitter();
  }

  xmlUploader(event: any) {
    this.xmlFiles = [event.files[0]];
    this.emitter();
  }

  clearFile(file: File) {
    this.uploadedFiles = [];
    this.emitter();
  }

  xsdUploader(event: any) {
    this.xsdFiles = [event.files[0]];
    this.emitter();
  }

  clearXSDFile(file: File) {
    this.xsdFiles = this.xsdFiles.filter((f: File) => f !== file);
    this.emitter();
  }

  clearXMLFile(file: File) {
    this.xmlFiles = this.xmlFiles.filter((f: File) => f !== file);
    this.emitter();
  }

  emitter() {
    this.basicJob = { ...this.setupJobForm.value };
    this.basicJob["file"] =
      this.uploadedFiles.length > 0 ? [this.uploadedFiles[0]] : [];
    this.basicJob["xmlFile"] = this.xmlFiles.length > 0 ? [this.xmlFiles[0]] : [];
    this.basicJob["xsdFile"] = this.xsdFiles.length > 0 ? [this.xsdFiles[0]] : [];

    this.isButtonDisabled = (
      (this.basicJob['projectId'] !== null) &&
      this.basicJob['jobName'] !== null &&
      (
        (this.basicJob['selectSource'] !== null && this.basicJob['selectSchema'] !== null && this.basicJob['selectTable'] !== null) ||
        (
          this.basicJob['file'].length > 0 ||
          (this.basicJob['xmlFile'].length > 0 && this.basicJob['xsdFile'].length > 0)
        )
      ));
  }

  submit() {
    // job_id=
    this.showLoading = true;
    const formData = new FormData();
    formData.append('org_id', this.authService.currentUserValue.orgId);
    formData.append('locale', 'en-US');
    formData.append('project_id', this.projectId ? this.projectId : this.setupJobForm.value['projectId']);
    formData.append('job_name', this.basicJob['jobName']);
    formData.append('user_id', this.authService.currentUserValue.id.toString())
    if (this.setupJobForm.value['sourceType'] == 'database') {
      formData.append('connection_id', this.setupJobForm.value["selectSource"]["id"])
      formData.append('schema_name', this.setupJobForm.value["selectSchema"])
      formData.append('source_table', this.setupJobForm.value['selectTable'])
      formData.append('type', 'database')
    } else {
      formData.append('type', 'file')
      if (this.basicJob['sourceType'] === 'xml') {
        formData.append('source_file', this.basicJob['xmlFile'][0]);
        if (this.basicJob['xsdFile'].length > 0) {
          formData.append('xsd', this.basicJob['xsdFile'][0]);
          formData.append('is_xsd', '1')
        } else {
          formData.append('is_xsd', '0')
        }
      } else {
        formData.append('source_file', this.basicJob['file'][0]);
      }
    }
    this.syntheticDataService.cleansing(formData).subscribe({
      next: res => {
        this.showLoading = false;
        if (res['message'] === 'data cleansing process is success') {
          Toast.fire({
            icon: 'success',
            html: res['message']
          });
        } else {
          Toast.fire({
            icon: 'error',
            html: res['message']
          });
        }
        this.router.navigate([`/projects/${this.projectId ? this.projectId : this.setupJobForm.value['projectId']}/jobs`])
      }, error: err => {
        this.showLoading = false;
      }
    })
  }
}
