<!-- Kiran changed 13/12/23 <div class="row confirm-job confirm-job-row" *ngIf="columnsData.length > 0 || (ruleData && ruleData['rule_id'])"> -->
<!-- Kiran changed 13/12/23 <div class="col-lg-4 col-md-5 col-sm-6 selected-field customer">
        <div class="row customer-add mb-1">
            <div class=" ">{{ 'confirmjob.selectedfields' | translate }}</div>
        </div>
        <perfect-scrollbar class="" [style.max-height]="'447px'" fxFlex="auto">
            <ul class="select-field-ul" *ngIf="!showRuleTest">
                <div *ngIf="columnsData.length > 0">
                    <li *ngFor="let col of columnsData; let i=index" (click)="showOccurence(col, i)"
                        (keydown.enter)="showOccurence(col, i); navigateToDiv('confirmDiv')"
                        (keydown.arrowDown)="navigate('down', i)" (keydown.arrowUp)="navigate('up', i)"
                        class="cursor-pointer" [ngClass]="{'highlighted': i === highlightedIndex}" [tabIndex]="i"
                        [attr.aria-labelledby]="'selectedFieldLabel_' + i">
                        <span aria-hidden="true">
                            {{selectedColumnDetails['table']['tableName']}}
                            <i class="feather ft-arrow-right"></i>
                            {{ col['source_column'] }}
                            <i [ngClass]="{'feather ft-chevron-right float-right': i === highlightedIndex}"
                                style="margin-top:5px"></i>
                        </span>
                        <span class="sr-only">
                            Selected Field: {{col['source_column']}} in Table:
                            {{selectedColumnDetails['table']['tableName']}}
                        </span>
                    </li>
                </div>
            </ul>
            <div class="rule-data-tablename">
                <p *ngIf="showRuleTest">{{ ruleData && ruleData['table'] && ruleData['table']['tableName'] ?
                    ruleData['table']['tableName'] : 'File'}}</p>
            </div>
        </perfect-scrollbar>
    </div>
 -->
<!-- Kiran changed 13/12/23 <div id="confirmDiv" *ngIf="!showRuleTest" class="col-lg-8 col-md-7 col-sm-12 sub-title showdata-box ml-0"> -->
<!-- Kiran changed 13/12/23 <div class="row" *ngIf="showData"> -->
<!-- Kiran changed 13/12/23 <div class="col-md-12 custom-synthesis">
                <h3 class="confirm-job-title">
                    {{ 'confirmjob.header2customsynthesis' | translate }}
                </h3>
                <p class="mt-2 text-italic">{{ 'confirmjob.synthesispara1' | translate }}</p>
                <p class="text-italic">{{ 'confirmjob.synthesispara2' | translate }} <a href="javascript:void(0)"
                        class="underline a">{{ 'confirmjob.doclink' | translate }}</a></p>
            </div> -->
<!-- Kiran changed 13/12/23 <div class="col-md-12 mt-2 mb-1">
                <div class="row set-job">
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-6">
                                <label for="float-input" class=" bold-label label-control">{{ 'confirmjob.tokenize' |
                                    translate
                                    }}</label>
                            </div>
                            <div class="col-md-6">
                                <p>{{ displayColumnData['columnlet_type'] == 'NA' ? "No" : "Yes" }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row set-job">
                            <div class="col-md-6">
                                <label for="float-input" class=" bold-label label-control">{{ 'confirmjob.fieldlength' |
                                    translate
                                    }}</label>
                            </div>
                            <div class="col-md-6">
                                <p>{{ displayColumnData['columnlet_type'] == ' ' ? "(SPACE)" :
                                    displayColumnData['columnlet_type']
                                    }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="columlet set-job">
                    <div
                        [ngClass]="displayColumnData['columnlet_type'] == 'NA' ? '' : 'd-flex columlet-btn third-step-columnlets'">
                        <div class="" *ngFor="let item of displayColumnData['columnlets_data']; index as i"
                            (click)="tabDecider(i)">
                            <h6 [ngClass]="tabIndex==i ?'clicked-columnlets tab-highlighted':''"
                                *ngIf="item && item['columnlet_name']">
                                {{item['columnlet_name']}}</h6>
                        </div>
                    </div>
                    <div class="confirm-synthesis">
                        <div class="row mb-1 set-job"
                            *ngIf="displayColumnData && displayColumnData['columnlets_data'] && displayColumnData['columnlets_data'][tabIndex] && displayColumnData['columnlets_data'][tabIndex]['semantic_group']">
                            <div class="col-4">
                                <label class=" bold-label label-control">{{ 'confirmjob.assignsemantics' | translate
                                    }}</label>
                            </div>
                            <div class="col-8">
                                <p>{{ displayColumnData['columnlets_data'][tabIndex]['semantic_group']}}</p>
                            </div>
                        </div>
                        <div class="row mb-1 set-job"
                            *ngIf="displayColumnData && displayColumnData['columnlets_data'] && displayColumnData['columnlets_data'][tabIndex] && displayColumnData['columnlets_data'][tabIndex]['strategy_name']">
                            <div class="col-4">
                                <label class=" bold-label label-control">{{ 'confirmjob.synthesisstrategy' | translate
                                    }}</label>
                            </div>
                            <div class="col-8">
                                <p>{{displayColumnData['columnlets_data'][tabIndex]['strategy_name']}}</p>
                            </div>
                        </div>
                        <div class="row mb-1 set-job"
                            *ngIf="displayColumnData && displayColumnData['columnlets_data'] && displayColumnData['columnlets_data'][tabIndex] && displayColumnData['columnlets_data'][tabIndex]['dependent_fields'].length > 0">
                            <div class="col-4">
                                <label class=" bold-label label-control">{{ 'confirmjob.dependentfield' | translate
                                    }}</label>
                            </div>
                            <div class="col-8">
                                <div class="">
                                    <p *ngFor="let item of displayColumnData['columnlets_data'][tabIndex]['dependent_fields']"
                                        class="dependent-filed-pills">
                                        {{item['column_name'] }}
                                        <i class="feather ft-arrow-right mx-1"></i>
                                        {{item['type']}}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-1 set-job"
                            *ngIf="displayColumnData && displayColumnData['columnlets_data'] && displayColumnData['columnlets_data'][tabIndex] && displayColumnData['columnlets_data'][tabIndex]['position_range_start']">
                            <div class="col-4">
                                <label class=" bold-label label-control">{{ 'confirmjob.positionrangstart' | translate
                                    }}</label>
                            </div>
                            <div class="col-8">
                                <p>{{displayColumnData['columnlets_data'][tabIndex]['position_range_start']}}</p>
                            </div>
                        </div>
                        <div class="row mb-1 set-job"
                            *ngIf="displayColumnData && displayColumnData['columnlets_data'] && displayColumnData['columnlets_data'][tabIndex] && displayColumnData['columnlets_data'][tabIndex]['position_range_end']">
                            <div class="col-4">
                                <label class=" bold-label label-control">{{ 'confirmjob.positionrangeend' | translate
                                    }}</label>
                            </div>
                            <div class="col-8">
                                <p>{{displayColumnData['columnlets_data'][tabIndex]['position_range_end']}}</p>
                            </div>
                        </div>
                        <div class="row mb-1 set-job"
                            *ngIf="displayColumnData && displayColumnData['columnlets_data'] && displayColumnData['columnlets_data'][tabIndex] && displayColumnData['columnlets_data'][tabIndex]['delimiter_value']">
                            <div class="col-4">
                                <label class=" bold-label label-control">{{ 'confirmjob.delimitervalue' | translate
                                    }}</label>
                            </div>
                            <div class="col-8">
                                <p>{{displayColumnData['columnlets_data'][tabIndex]['delimiter_value']}}</p>
                            </div>
                        </div>
                        <div class="row mb-1 set-job"
                            *ngIf="displayColumnData && displayColumnData['columnlets_data'] && displayColumnData['columnlets_data'][tabIndex] && displayColumnData['columnlets_data'][tabIndex]['variable_position']">
                            <div class="col-4">
                                <label class=" bold-label label-control">{{ 'confirmjob.variableposition' | translate
                                    }}</label>
                            </div>
                            <div class="col-8">
                                <p>{{displayColumnData['columnlets_data'][tabIndex]['variable_position']}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
<!-- Phani please recheck the code <div class="col-md-12 mt-2">
                <div class="row set-job">
                    <div class="col-4">
                        <label for="float-input" class=" bold-label label-control">{{ 'confirmjob.columnupdates' |
                            translate
                            }}</label>
                    </div>
                    <div class="col-8">
                        <div>
                            <ul style="list-style: none;padding: 0;">
                                <li [tabIndex]="0">
                                    <span aria-hidden="true">{{ selectedColumnDetails['table']['tableName']}}<i
                                            class="feather ft-arrow-right"></i> {{
                                        occurenceCol['column_name']}}
                                        <span class="">{{ 'confirmjob.current' | translate }}</span></span>
                                    <span class="sr-only">
                                        Column: {{occurenceCol['column_name']}} in Table:
                                        {{selectedColumnDetails['table']['tableName']}}
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div *ngIf="occurence['parent'] != ''">
                            <ul style="list-style: none;padding: 0;" *ngFor="let record of occurence['parent']">
                                <li>{{ record['table_name']}}<i class="feather ft-arrow-right"></i> {{
                                    record['column_name']}}
                                    <span class="">{{ 'confirmjob.parent' | translate }}</span>
                                </li>
                            </ul>
                        </div>
                        <div *ngIf="occurence['child'] != ''">
                            <ul style="list-style: none;padding: 0;" *ngFor="let record of occurence['child']">
                                <li> {{ record['table_name']}}<i class="feather ft-arrow-right"></i> {{
                                    record['column_name']}}
                                    <span class="">{{ 'confirmjob.child' | translate }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> -->
<!-- Kiran <div class="row limit">
                <div class="col-4 limit-label">
                    <label for="float-input" class=" bold-label label-control">{{ 'confirmjob.limits' | translate
                        }}</label>
                </div>
                <div class="col-8">
                    <div class="row">
                        <p class="mb-2 text-italic">{{ 'confirmjob.para1setup' | translate }}
                            <a (click)="setUpLimit()" href="javascript:void(0)" tabindex="0" class="underline">{{
                                'confirmjob.linkdocument' | translate }}</a>
                        </p>
                    </div>
                    <div class="row count-limit">
                        <div class="col-lg-6 col-md-12">
                            <form [formGroup]="limitForm">
                                <label for="" class="label-control count-row-limit">{{ 'inputforms.labels.rowcountlimit'
                                    | translate }}</label>
                                <div class="d-flex toast-container-box">
                                    <input type="number" class="form-control"
                                        [placeholder]="'inputforms.placeholder.rowcountlimit' | translate " min="1"
                                        oninput="validity.valid||(value='');" formControlName="limit"
                                        (change)="limitEvent()">
                                    <div class="toast-pop">
                                        <div id="custom-target"></div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="row">
                        <p class="text-italic">{{ 'confirmjob.nodatadescription' | translate }}</p>
                    </div>
                </div>
            </div> -->
<!-- Kiran changed 13/12/23 <div class="col-md-12 limit-label bias-label mt-1" *ngIf="showBias">
                <label for="float-input" class=" bold-label label-control mb-0"> <a href="javascript:void(0)"
                        (click)="displayCheckBias = true" class="a"> {{ 'Check for Bias' }}<i
                            class=" feather ft-arrow-right"></i></a></label>
                <p class="text-italic p-0">Select the bias that needs to be fixed </p>
            </div> -->
<!-- Kiran changed 13/12/23 </div> -->
<!-- Kiran changed 13/12/23 </div> -->
<!-- Kiran changed 13/12/23 <div *ngIf="showRuleTest || (ruleData && ruleData['rule_id'])"
        class="col-lg-8 col-md-7 col-sm-12 sub-title showdata-box ml-0">
        <div class="row">
            <div class="col-md-12 custom-synthesis">
                <h3 class="confirm-job-title">{{ 'confirmjob.header2customsynthesis' | translate }}</h3>
                <p class="  mb-0 text-italic">{{ 'confirmjob.synthesispara1' | translate }}</p>
                <p class="  text-italic">{{ 'confirmjob.synthesispara2' | translate }} <a href="javascript:void(0)"
                        (click)="showDoc()" class="underline a">{{ 'confirmjob.doclink' | translate }}</a></p>
            </div>
            <div class="col-md-12 mt-2 mb-1">
                <div class="columlet mb-2 mt-2">
                    <div class="confirm-synthesis">
                        <div class="my-2 row set-job">
                            <div class="col-4">
                                <label for="float-input" class="bold-label label-control">{{ 'confirmjob.selectedrule' |
                                    translate
                                    }}</label>
                            </div>
                            <div class="col-8">
                                <p *ngIf="ruleName != ''">{{ ruleName }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
<!-- Kiran changed 13/12/23  </div> -->

<!-- <div class="row confirm-job confirm-job-row">
    <div class="col-lg-4 col-md-5 col-sm-6 selected-field customer">
        <div class="row customer-add mb-1">
            <div class=" ">{{ 'confirmjob.selectedfields' | translate }}</div>
        </div>
        <perfect-scrollbar class="" [style.max-height]="'447px'" fxFlex="auto">
            <ul class="select-field-ul">
                <div>
                    <li *ngFor="let col of dataArray; let i=index" (click)="showAnomalies(col, i)"
                        (keydown.enter)="showAnomalies(col, i); navigateToDiv('confirmDiv')"
                        (keydown.arrowDown)="navigate('down', i)" (keydown.arrowUp)="navigate('up', i)"
                        class="cursor-pointer" [ngClass]="{'highlighted': i === highlightedIndex}" [tabIndex]="i"
                        [attr.aria-labelledby]="'selectedFieldLabel_' + i">
                        {{ col }}
                    </li>
                </div>
            </ul>
        </perfect-scrollbar>
    </div>

    <div class="col-lg-8 col-md-7 col-sm-12 sub-title showdata-box ml-0">
        <div class="row">
            <div class="col-md-12 custom-synthesis">
                <h3 class="confirm-job-title">{{ 'confirmjob.header2customsynthesis' | translate }}</h3>
                <p class="  mb-0 text-italic">{{ 'confirmjob.synthesispara1' | translate }}</p>
                <p class="  text-italic">{{ 'confirmjob.synthesispara2' | translate }} <a href="javascript:void(0)"
                        class="underline a">{{ 'confirmjob.doclink' | translate }}</a></p>
            </div>
            <div class="col-md-12 mt-2 mb-1">
                <div class="columlet mb-2 mt-2">
                    <div class="confirm-synthesis">
                        <div class="my-2 row set-job" *ngIf="showAnomalyData && showAnomalyData['type']">
                            <div class="col-4">
                                {{showAnomalyData['type']=='Anomaly Class Column' ? 'Type': 'Semantic Type'}}
                            </div>
                            <div class="col-8">
                                {{showAnomalyData['type']=='column' ? showAnomalyData['value']['type']: showAnomalyData['type']}}
                            </div>
                        </div>
                        <div class="my-2 row set-job" *ngIf="showAnomalyData && showAnomalyData['value'] && showAnomalyData['type']!='column'">
                            <div class="col-4">
                                {{showAnomalyData['type']=='numeric' ? 'Range': 'Element'}}
                            </div>
                            <div class="col-8">
                                {{showAnomalyData['value']}}
                            </div>
                        </div>
                        <div class="my-2 row set-job" *ngIf="showAnomalyData && showAnomalyData['value'] && showAnomalyData['type']=='column'">
                            <div class="col-4">
                                {{'Strategy ID'}}
                            </div>
                            <div class="col-8">
                                {{showAnomalyData['value']['strategy_id']}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div> -->

<div class="row confirm-job confirm-job-row"
    *ngIf="columnsData.length > 0 || (ruleData && ruleData['rule_id']) || anomalyObjectProps()">


    <!-- Don't Delete This -->
    <!-- <div class="my-2 row set-job">
            <div class="col-4">
                <label for="float-input" class="bold-label label-control">{{ 'confirmjob.outputtablename' | translate
                    }}</label>
            </div>
            <div class="col-8">
                <p>{{ ruleData['output_table_name'] }}</p>
            </div>
        </div> 
        <div class="my-2 row set-job">
            <div class="col-4">
                <label for="float-input" class="bold-label label-control">{{ 'confirmjob.inputfields' | translate
                    }}</label>
            </div>
            <div class="col-8">
                <div *ngFor="let item of ruleData['input_fields']; index as i">
                    <ng-container *ngFor="let exec of item | keyvalue">
                        <div class="dependent-field d-flex">
                            <h6 class="mb-0 mt-1"> {{exec.key}} </h6>
                            <p class="dependent-filed-pills mx-1">{{exec.value }}</p>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div> -->
    <!-- Kiran <div class="col-md-4"></div>
    <div class="col-md-8 mt-2">
        <div class="row limit">
            <div class="col-4 limit-label">
                <label for="float-input" class=" bold-label label-control">{{ 'confirmjob.limits' | translate }}</label>
            </div>
            <div class="col-8" *ngIf="showRuleTest || (ruleData && ruleData['rule_id'])">
                <div class="row">
                    <p class="mb-2 text-italic">{{ 'confirmjob.para1setup' | translate }}
                        <a (click)="setUpRuleLimit()" href="javascript:void(0)" class="underline">{{
                            'confirmjob.linkdocument' | translate }}</a>
                    </p>
                </div>
                <div class="row count-limit">
                    <div class="col-lg-6 col-md-12">
                        <form [formGroup]="limitForm" aria-label="'Confirm Job Form'">
                            <label for="" class="label-control count-row-limit">{{ 'inputforms.labels.rowcountlimit' |
                                translate }}</label>
                            <div class="d-flex toast-container-box">
                                <input type="number" class="form-control" min="1" oninput="validity.valid||(value='');"
                                    [placeholder]="'inputforms.placeholder.rowcountlimit' | translate "
                                    formControlName="limit" (change)="limitEvent()">
                                <div class="toast-pop">
                                    <div id="custom-target"></div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="row">
                    <p class="text-italic">{{ 'confirmjob.nodatadescription' | translate }}</p>
                </div>
            </div>
        </div>
    </div> -->



    <!-- Kiran changed 13/12/23 <div class="col-md-4"></div> -->
    <div class="col-md-4 mt-2"></div>
    <div class="col-md-8 mt-2">
        <div class="mb-2" *ngIf="showBias">
            <label for="float-input" class=" bold-label label-control mb-0"> <a href="javascript:void(0)"
                    (click)="displayCheckBias = true" class="a"> {{ 'Check for Bias' }}<i
                        class=" feather ft-arrow-right"></i></a></label>
            <p class="text-italic p-0">Select the bias that needs to be fixed </p>
        </div>
        <div class="row limit">
            <div class="col-4 limit-label">
                <label for="float-input" class=" bold-label label-control">{{ routeType == 'anomaly' ? 'Add rows' : 'confirmjob.limits' | translate }}</label>
            </div>
            <div class="col-8">
                <!-- <p class="text-italic">{{ 'confirmjob.para1setup' | translate }}
                    <a (click)="setUpRuleLimit()" href="javascript:void(0)" class="underline a">{{
                        'confirmjob.linkdocument' | translate }}</a>
                </p> -->
                <div class="form-group count-limit">
                    <form [formGroup]="limitForm">
                        <label for="" class="label-control count-row-limit">{{ routeType == 'anomaly' ? 'Expand with extra anomalies.': 'inputforms.labels.rowcountlimit'
                            | translate }}</label>
                        <div class="d-flex toast-container-box">
                            <input type="number" class="form-control"
                                [placeholder]="'inputforms.placeholder.rowcountlimit' | translate " min="1"
                                oninput="validity.valid||(value='');" formControlName="limit" (change)="limitEvent()">
                            <!-- Phany please check <div class="toast-pop">
                                <div id="custom-target"></div>
                            </div> -->
                        </div>
                    </form>
                </div>
                <p class="text-italic">{{ routeType == 'anomaly' ? 'The system will generate data for the specified number of anomaly rows.': 'confirmjob.nodatadescription' | translate }}</p>
            </div>
        </div>
    </div>
</div>

<div class="row confirm-job confirm-job-row" *ngIf="routeType == 'automl'">
    <div class="col-4"></div>
    <div class="col-8 model-type">
        <h3 class="header-type mb-2"> Select Model Type</h3>
        <div class="field-radiobutton">
            <p-radioButton name="modelType" value="regression" [(ngModel)]="modelType" (onClick)="modelSelection()"
                inputId="modelType1"></p-radioButton>
            <label for="modelType1">Regression</label>
        </div>
        <p class="btn-discription">Predicting numerical outcomes or understanding relationships with continuous
            variables in data.</p>
        <div class="field-radiobutton">
            <p-radioButton name="modelType" value="classification" [(ngModel)]="modelType" inputId="modelType2"
                (onClick)="modelSelection()"></p-radioButton>
            <label for="modelType2">Classification</label>
        </div>
        <p class="btn-discription">Categorizing data into classes; predicting discrete outcomes with distinct
            categories.</p>
    </div>
</div>


<p-dialog [(visible)]="displayCheckBias" [draggable]="false" [modal]="true" [blockScroll]="false"
    [style]="{width: '80vw'}" styleClass="thirdtestpop check-popup">
    <div class="p-dialog-titlebar">
        <button class="p-dialog-titlebar-icon p-dialog-titlebar-close" aria-label="Close Dialog"
            (click)="displayCheckBias=false">
            <span class="pi pi-times"></span>
        </button>
    </div>
    <app-check-bias *ngIf="displayCheckBias" [columnsData]="columnsData" [jobDetails]="jobDetails"
        [selectedColumnDetails]="selectedColumnDetails" [fileJob]="fileJob"
        (biasEmitter)="biasEvent($event)"></app-check-bias>
</p-dialog>