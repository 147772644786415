<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-body">
            <section class="flexbox-container">
                <div class="card-title text-center mb-3 mt-5">
                    <img src="../../../assets/images/logo/new-logo.svg" alt="0to60 logo" [tabIndex]="3"
                        style="height: 60px;">
                </div>
                <div class="row">
                    <div class="col-12 d-flex align-items-center justify-content-center login-box">
                        <div class="card login border-grey border-lighten-3 box-shadow-2 register" role="region"
                            aria-live="assertive">
                            <div class="card-content">
                                <div class="card-body">
                                    <h1 class="log-head">{{ 'register.registerheading' | translate }}</h1>
                                    <form class="form-horizontal" [formGroup]="registerForm"
                                        aria-label="'registerForm All required input feilds are denoted by star'"
                                        (ngSubmit)="tryRegister()">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>{{ 'inputforms.labels.name' | translate }}<span
                                                            class="mendatory">*</span></label>
                                                    <input type="text" formControlName="name" class="form-control"
                                                        [ngClass]="{ 'is-invalid': (submitted || f.name.touched) && f.name.errors }"
                                                        id="name"
                                                        [placeholder]="'inputforms.placeholder.name' | translate">
                                                    <div *ngIf="(submitted || f.name.touched) && f.name.errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.name.errors.required">
                                                            {{ 'inputforms.formerrors.requiredname' | translate }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>{{ 'inputforms.labels.phone' | translate
                                                        }}<span class="mendatory">*</span></label>
                                                    <input type="number"
                                                        [placeholder]="'inputforms.placeholder.phone' | translate"
                                                        formControlName="phoneNumber"
                                                        onkeydown="return event.keyCode !== 69" class="form-control"
                                                        id="phoneNumber" required
                                                        [ngClass]="{ 'is-invalid': (submitted || f.phoneNumber.touched) && f.phoneNumber.errors }" />
                                                    <div *ngIf="(submitted || f.phoneNumber.touched) && f.phoneNumber.errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.phoneNumber.errors.required">
                                                            {{ 'inputforms.formerrors.requiredphonenumber' | translate
                                                            }}
                                                        </div>
                                                        <div *ngIf="f.phoneNumber.errors.pattern">
                                                            {{ 'inputforms.formerrors.invalidphone' | translate }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>{{ 'inputforms.labels.email' | translate }}<span
                                                            class="mendatory">*</span></label>
                                                    <input type="text"
                                                        [placeholder]="'inputforms.placeholder.email' | translate"
                                                        formControlName="email" id="email" class="form-control"
                                                        aria-label="'Please enter your email address in the format name@example.com'"
                                                        [ngClass]="{ 'is-invalid': (submitted || f.email.touched) && f.email.errors }" />
                                                    <div *ngIf="(submitted || f.email.touched) && f.email.errors"
                                                        class="invalid-feedback" aria-live="assertive">
                                                        <div *ngIf="f.email.errors.required">
                                                            {{ 'inputforms.formerrors.requiredemail' | translate }}
                                                        </div>
                                                        <div *ngIf="f.email.errors.email">
                                                            {{ 'inputforms.formerrors.entervalidemail' | translate }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <span class="p-fluid">
                                                        <label>{{ 'inputforms.labels.password' | translate
                                                            }}<span class="mendatory">*</span></label>
                                                        <div class="password-field">
                                                            <input type="{{ showPassword ? 'text' : 'password' }}"
                                                                class="form-control" formControlName="password"
                                                                [placeholder]="'inputforms.placeholder.password' | translate"
                                                                [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                                                            <a href="javascript:void(0)" class="eye-icon "
                                                                (click)="togglePasswordVisibility()"
                                                                aria-label="Show Password">
                                                                <i class="eye-icon feather"
                                                                    [ngClass]="{ ' ft-eye': !showPassword, ' ft-eye-off': showPassword }"></i>
                                                            </a>
                                                        </div>
                                                    </span>
                                                    <div *ngIf="(submitted || f.password.touched) && f.password.errors"
                                                        class="invalid-feedback" aria-live="assertive">
                                                        <div *ngIf="f.password.errors.required">
                                                            {{ 'inputforms.formerrors.requiredpassword' | translate }}
                                                        </div>
                                                        <div *ngIf="f.password.errors.minlength">
                                                            {{ 'inputforms.formerrors.minpassword' | translate }}</div>
                                                        <div *ngIf="f.password.errors.pattern">
                                                            {{ 'inputforms.formerrors.passwordpattern' | translate }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>{{ 'inputforms.labels.selectquestion' | translate
                                                        }}</label>
                                                    <p-dropdown [filter]="true" styleClass="allDropdown"
                                                        formControlName="selectQuestion" [options]="questions"
                                                        filterBy="name"
                                                        [placeholder]="('inputforms.placeholder.selectquestion' | translate) ? ('inputforms.placeholder.selectquestion' | translate) : ' '">
                                                    </p-dropdown>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>{{ 'inputforms.labels.answer' | translate }}</label>
                                                    <input type="text"
                                                        [placeholder]="'inputforms.placeholder.answer' | translate"
                                                        formControlName="answer" class="form-control" />
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <p class="text-center">{{ 'register.policytext' | translate }} <a
                                                            href="javascript:void(0)" class="underline"
                                                            (click)="showModalDialog()">{{ 'register.policylink' |
                                                            translate
                                                            }}</a>
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="form-group text-center">
                                                    <button type=" submit" class="btn btn-raised btn-warning"
                                                        [disabled]="registerForm.invalid || loading">{{
                                                        'buttontitle.submit'
                                                        |
                                                        translate}}
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <div class="card-body d-flex justify-content-center existing-user">
                                                        <p>{{ 'register.accountexisttext' | translate }} <a
                                                                [routerLink]="['/login']" class=" underline">
                                                                {{ 'register.logintext' | translate }}</a></p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <span class="copyright text-center">{{ 'footer.copyright' | translate }} &copy; {{
                        'footer.brudatainc' | translate }}</span>
                </div>


            </section>
        </div>
    </div>
</div>

<p-dialog [modal]="true" [blockScroll]="true" [resizable]="false" [(visible)]="displayModal" [style]="{width: '45vw'}"
    [draggable]="false">
    <div class="p-dialog-titlebar">
        <button class="p-dialog-titlebar-icon p-dialog-titlebar-close" aria-label="Close Dialog"
            (click)="displayModal=false">
            <span class="pi pi-times"></span>
        </button>
    </div>
    <app-privacy-policy></app-privacy-policy>
</p-dialog>