import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-add-semantic-dependent',
  templateUrl: './add-semantic-dependent.component.html'
})
export class AddSemanticDependentComponent implements OnChanges {

  @Input() table: Object = {};
  showTableData: boolean = true;
  options: Object[] = [
    { name: "Gender AutoDetect", value: "gender_autodetect" },
    { name: "Date Auto Assign", value: "date_autoassign" },
    { name: "Custom Expression", value: "expression" }
  ]
  displayHighlight: string[] = []
  showDropdown: boolean = false;
  dependentFields: any[] = [];
  selectedColumn: string = '';
  @Output() dependentFieldsEmitter = new EventEmitter()
  selectValue = '';
  @Input() value: Object[] = []
  showExpression: boolean = false;
  expression: string = ""
  exp = ''

  constructor(

  ) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['value']) {
      this.dependentFields = JSON.parse(JSON.stringify(this.value))
    }
    if (this.dependentFields.length > 0) {
      this.toggle();
    }
  }

  toggle() {
    this.displayHighlight = [];
    this.dependentFields.forEach(r => {
      this.displayHighlight.push(r['column_name'])
    })
  }

  columnsHandler(column: string) {
    this.showExpression = false;
    this.exp = ''
    this.showDropdown = true;
    this.toggle();
    if (this.dependentFields.length > 0) {
      let pushOrNot = true;
      for (let i = 0; i < this.dependentFields.length; i++) {
        if (this.dependentFields[i]['column_name'] == column) {
          this.selectValue = this.dependentFields[i]['type']
          if (this.selectValue && this.selectValue == 'expression') { 
            this.showExpression = true;
            this.exp = this.dependentFields[i]['expression'][0]
          }
          this.displayHighlight.push(column);
          pushOrNot = false;
          this.selectedColumn = column;
        }
      }
      if (pushOrNot) {
        this.selectValue = '';
        this.selectedColumn = column;
        this.displayHighlight.push(column);
      }
    } else {
      this.selectValue = '';
      this.selectedColumn = column;
      this.displayHighlight.push(column);
    }
    setTimeout(() => {
      this.navigateToDiv('dependence');
    }, 0)
  }

  navigateToDiv(divId: string): void {
    const mainContentElement = document.getElementById(divId);
    if (mainContentElement) {
      mainContentElement.focus();
    }
  }

  dependentHandler(data) {
    if (data && data['value'] && data['value'] === 'expression') {
      this.showExpression = true;
    } else if (data.value !== null) {
      this.showExpression = false;
      if (this.dependentFields.length > 0) {
        let pushOrNot = true;
        for (let i = 0; i < this.dependentFields.length; i++) {
          if (this.dependentFields[i]['column_name'] == this.selectedColumn) {
            pushOrNot = false;
            this.dependentFields[i]['type'] = data.value;
            this.showExpression = false;
          }
        }
        if (pushOrNot) {
          this.dependentFields.push({
            column_name: this.selectedColumn,
            type: data.value
          })
        }
      } else {
        this.dependentFields.push({
          column_name: this.selectedColumn,
          type: data.value
        })
      }
    } else {
      let index = this.dependentFields.findIndex(r => {
        return r['column_name'] == this.selectedColumn
      })
      if (index != -1) {
        this.dependentFields.splice(index, 1)
      }
      this.showExpression = false;
    }
  }

  saveDependentFields() {
    let arr = JSON.parse(JSON.stringify(this.dependentFields))
    this.dependentFieldsEmitter.emit(arr)
  }

  expressionHandler(event) {
    let index = this.dependentFields.findIndex(item => item['column_name'] == this.selectedColumn)
    if (index !== -1) {
      this.dependentFields[index] = { column_name: this.selectedColumn, type: 'expression', expression: [event.target.value] }
    } else {
      this.dependentFields.push({ column_name: this.selectedColumn, type: 'expression', expression: [event.target.value] })
    }
  }

}
