<div (window:resize)="onResize($event)"></div>
<a href="javascript:void(0)" (click)="skipToMainContent()" class="skip-link">Skip to main content</a>
<app-navigation role="navigation"></app-navigation>
<main id="main" tabindex="-1">
    <router-outlet></router-outlet>
</main>
<div class="sidenav-overlay d-none" id="sidenav-overlay" (click)="rightbar($event)"></div>
<div *ngIf="chat === 'on'">
    <app-chat *ngIf="layout === 'vertical'"></app-chat>
</div>
<div *ngIf="customizer === 'on'">
    <app-customizer *ngIf="layout === 'vertical'"></app-customizer>
    <app-horizontal-customizer *ngIf="layout === 'horizontal'"></app-horizontal-customizer>
</div>