<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-header row mb-1">
        </div>
        <div class="content-body">
            <section class="flexbox-container">
                <div class="col-12 d-flex align-items-center justify-content-center">
                    <div class="col-md-4 login-box col-10 box-shadow-2  ">
                        <div class="card login border-grey border-lighten-3   py-1 m-0">
                            <div class="d-flex justify-content-end icon-cross">
                                <i class="feather ft-x" (click)="goTo()"></i>
                            </div>
                            <div class="card-content">

                                <div class="card-body">
                                    <h2>{{ 'inviteteam.header2invite' | translate }}</h2>
                                </div>

                                <form class="form-horizontal" [formGroup]="inviteTeamMemberForm"
                                    aria-label="'Invite Member form All required input feilds are denoted by star'">
                                    <fieldset class="form-group position-relative has-icon-left">
                                        <legend></legend>
                                        <label>{{ 'inputforms.labels.email' | translate }}<span
                                                class="mendatory">*</span></label>
                                        <input type="text" formControlName="email" class="form-control"
                                            id="txtInviteMemberEmail"
                                            [placeholder]="'inputforms.placeholder.emailusers' | translate" required>
                                    </fieldset>

                                    <fieldset class="form-group position-relative has-icon-left">
                                        <legend></legend>
                                        <span class="p-fluid">
                                            <label>{{ 'inputforms.labels.assignrole' | translate }}<span
                                                    class="mendatory">*</span></label>
                                            <p-dropdown [filter]="true" styleClass="allDropdown"
                                                [placeholder]="'inputforms.placeholder.assignrole' | translate"
                                                formControlName="assignrole" filterBy="name" optionLabel="name"
                                                id="drpdAssignRole"></p-dropdown>
                                        </span>
                                    </fieldset>

                                    <div class="card-body d-flex justify-content-center ">
                                        <button type="submit" class="btn btn-raised btn-warning btn-small">
                                            {{ 'buttontitle.invitebutton' | translate }}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div class="d-flex justify-content-around invite-members">
                            <div class="invite-members-link">
                                <h5>{{ 'inviteteam.header5inviteteam' | translate }}</h5>
                                <p>{{ 'inviteteam.inviteteamtext' | translate }}</p>
                            </div>
                            <div class="invite-members-btn">
                                <button class="btn">
                                    <i class="feather ft-link"></i>
                                    {{ 'buttontitle.copylinkbutton' | translate }}
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    </div>
</div>