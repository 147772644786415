import { HttpClient, HttpEventType, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AgentsService {
  private NodeAPIUrl = environment.apiAuthUrl;
  private AgentsURL = environment.apiAgents;

  constructor(
    private http: HttpClient
  ) { }
  
  uploadFilesToPython(formData): Observable<Object> {
    const req = new HttpRequest('POST', `${this.AgentsURL}/validate`, formData, {
      reportProgress: true,
      responseType: 'text'
    });

    return this.http.request(req).pipe(
      map(event => {
        if (event['partialText']) {
          return  {data: event['partialText'], complete: false };
        }
        if (event.type === HttpEventType.Response && event instanceof HttpResponse) {
          return { data: event.body as string, complete: true};
        }
        return '';
      })
    );
  }

  getId(type) {
    // http://127.0.0.1:8000/get-id?type_=workflow
    const params = new HttpParams().set('type_', type);
    return this.http.get<any>(`${this.AgentsURL}/get-id`, { params });
  }

  getAvailableAgentTemplates() {
    return this.http.get<any>(`${this.AgentsURL}/get-available-agent-templates`);
  }

  getAvailableToolName() {
    return this.http.get<any>(`${this.AgentsURL}/get-available-tool-name`);
  }

  addPromptFile(id, formData) {
    // http://127.0.0.1:8000/add-prompt-file/?agent_id=Agent123
    // prompt_file
    const params = new HttpParams().set('agent_id', id);
    return this.http.post<any>(`${this.AgentsURL}/add-prompt-file`, formData, {params})
  }

  async addPromptFileAsync(id, formData) {
    return new Promise(resolve => {
      this.addPromptFile(id, formData).subscribe({
        next: result => resolve(result),
        error: err => resolve(err)
      })
    })
  }

  addToolFile(id, isImage, agentId, formData) {
    // http://127.0.0.1:8000/add-tool-file/?tool_id=3&is_image=0
    // tool_file
    const params = new HttpParams().set('tool_id', id).set('is_image', isImage ? '1' : '0').set('agent_id', agentId);;
    return this.http.post<any>(`${this.AgentsURL}/add-tool-file`, formData, {params})
  }

  async addToolFileAsync(id, isImage, agentId, formData) {
    return new Promise(resolve => {
      this.addToolFile(id, isImage, agentId, formData).subscribe({
        next: result => resolve(result),
        error: err => resolve(err)
      })
    })
  }

  addTool(body) {
    // http://127.0.0.1:8000/add-tool
    return this.http.post<any>(`${this.AgentsURL}/add-tool`, body)
  }

  addAgent(body) {
    // http://127.0.0.1:8000/add-agent
    return this.http.post<any>(`${this.AgentsURL}/add-agent`, body)
  }

  addWorkflow(body) {
    // http://127.0.0.1:8000/add-workflow
    return this.http.post<any>(`${this.AgentsURL}/add-workflow`, body)
  }

  getAvailableAgents(id) {
    // http://127.0.0.1:8000/get-available-agents?user_id=user1
    const params = new HttpParams().set('user_id', id)
    return this.http.get<any>(`${this.AgentsURL}/get-available-agents`, {params});
  }
 
  getToolDetailsByToolId(id) {
    // http://127.0.0.1:8000/get-tool-details?tool_id=tool1
    const params = new HttpParams().set('tool_id', id)
    return this.http.get<any>(`${this.AgentsURL}/get-tool-details`, {params});
  }

  deleteAgent(workflow_id, tool_id) {
    // http://127.0.0.1:8000/delete-agent?workflow_id=workflow_1234&agent_run_id=agent_run5867
    const params = new HttpParams().set('workflow_id', workflow_id).set('tool_id', tool_id)
    return this.http.delete<any>(`${this.AgentsURL}/delete-agent`, {params});
  }
  
  addAgentRun(body) {
    // http://127.0.0.1:8000/add-agent-run
    return this.http.post<any>(`${this.AgentsURL}/add-agent-run`, body);
  }
  
  getWorkflows(userId) {
    // http://127.0.0.1:8000/get-user-workflows?user_id=user1
    const params = new HttpParams().set('user_id', userId)
    return this.http.get<any>(`${this.AgentsURL}/get-user-workflows`, {params});
  }
  
  getWorkflowsDetails(workflowId) {
    // http://127.0.0.1:8000/get-workflow-details?workflow_id=workflow1
    const params = new HttpParams().set('workflow_id', workflowId)
    return this.http.get<any>(`${this.AgentsURL}/get-workflow-details`, {params});
  }

  message(body) {
    return this.http.post<any>(`${this.AgentsURL}/message`, body);
  }

  getMessageStream(id) {
    const req = new HttpRequest('GET', `${this.AgentsURL}/message/${id}`, {
      reportProgress: true,
      responseType: 'text'
    });

    return this.http.request(req).pipe(
      map(event => {
        if (event['partialText']) {
          return  {data: event['partialText'], complete: false };
        }
        if (event.type === HttpEventType.Response && event instanceof HttpResponse) {
          return { data: event.body as string, complete: true};
        }
        return '';
      })
    );
  }

}
