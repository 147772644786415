import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LazyLoadEvent } from 'primeng/api';
import { Toast } from 'src/app/_helpers/toast';
import { DocumentationSlideService } from 'src/app/_services/documentation-slide.service';
import { OrgService } from 'src/app/_services/org.service';
import { ProjectService } from 'src/app/_services/project.service';
import { UserService } from 'src/app/_services/user.service';

interface City {
  name: string,
  code: string
}
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html'
})
export class UserProfileComponent implements OnInit {

  status: City[];
  userData: Object = {};
  orgData: Object = {};
  selectedCity: City;
  products: Object[] = [{
    projectName: 'Project A',
    environment: 102,
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at porttitor sem.',
    status: 'Succesful'
  }];
  orgId: '';
  cols: any[];
  projects: Object[] = [];
  _selectedColumns: any[];
  projectId: any;
  loading: boolean;
  totalProjectRecords: any;
  changePassword: boolean;
  displayChangePassword: boolean;
  resetForm: boolean;
  userId: string;
  displayEditProfile: boolean;
  name: string;
  email: string;
  profile: any;
  orgName: string;
  loader: boolean;
  profileData: any;
  clearForm: boolean;
  @ViewChild('profileEdit', { static: false }) profileEdit: ElementRef;
  @ViewChild('profileEditpwd', { static: false }) profileEditpwd: ElementRef;


  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private userService: UserService,
    private orgService: OrgService,
    private documentSlideService: DocumentationSlideService,
    private projectService: ProjectService,
    private route: ActivatedRoute
  ) {
    this.status = [
      { name: 'Succesful', code: 'NY' },
      { name: 'Pending', code: 'RM' },
      { name: 'Fail', code: 'LDN' },
    ];
  }

  ngOnInit(): void {
    this.cols = [
      { field: 'projectName', header: 'Workspaces' },
      { field: 'description', header: 'Description' }
    ];

    this._selectedColumns = this.cols;
    this.route.params.subscribe(data => {
      this.userId = data.userId;
      if (this.userId) {

        this.userService.getUserById(this.userId).subscribe(res => {

          this.profile = res;

          this.name = this.profile.firstName + ' ' + this.profile.lastName;
          this.email = this.profile.email;
          this.orgService.getOrgById(this.profile.orgId).subscribe(data => {
            this.orgName = data.name;
          })
        })
      } else {

        this.userId = undefined;
      }
    })

    this.getprofile();
    this.documentSlideService.setDocumentNameInStorage("UserProfile");
  }
  getprofile() {
    this.userService.getUserProfile().subscribe({
      next: (res) => {
        if (res) {
          this.userData = res;
          this.orgService.getOrgById(res.orgId).subscribe({
            next: r => {
              this.orgData = r
            },
            error: err => {
              Toast.fire({
                icon: 'error',
                html: err.error.error.message
              });
            }
          })
        }
      },
      error: err => {
        Toast.fire({
          icon: 'error',
          html: err.error.error.message
        });
      }
    })
  }

  closeprofile() {
    this.displayEditProfile = false
    if (this.profileEdit && this.profileEdit.nativeElement) {
      this.profileEdit.nativeElement.focus();
    }
  }

  closeprofilePwd() {
    this.displayChangePassword = false
    if (this.profileEditpwd && this.profileEditpwd.nativeElement) {
      this.profileEditpwd.nativeElement.focus();
    }
  }

  fetchProjects(event: LazyLoadEvent) {
    this.loading = true;
    setTimeout(() => {
      this.projectService.getAllProject({ limit: event['rows'], skip: event['first'] }).subscribe({
        next: res => {
          this.projects = res['records'];
          this.totalProjectRecords = res['totalRecords']
          this.loading = false;
        },
        error: err => {
          Toast.fire({
            icon: 'error',
            html: err.error.error.message
          });
        }
      })
    }, 1000);
  };

  goToProject(data, col) {
    if (col !== 'actions') {
      if (data?.id) {
        this.router.navigate([`projects/${data?.id}`]);
      }
    }
  }


  close(): void {
    this.clearForm = true
    this.getprofile();
  }
  closeChangePassword() {
    this.resetForm = true
  }

  changePasswordModal() {
    this.displayChangePassword = true;
    this.resetForm = false;
  }

  editProfileModal() {
    this.clearForm = false
    this.displayEditProfile = true
  }

  displayControl(event) {
    if (event) {
      if (event == 'close') {
        this.displayChangePassword = false;
        this.closeChangePassword();
        Toast.fire({
          icon: 'success',
          html: 'Password Changed Successfully'
        });
      } else {
        Toast.fire({
          icon: 'error',
          html: event
        });

      }
    }
  }

  profileEvent(event) {
    if (event['updated']) {
      Toast.fire({
        icon: 'success',
        html: `Profile Details Updated Successfully`
      });
      this.displayEditProfile = false;
      this.getprofile();
    } else {
      Toast.fire({
        icon: 'error',
        title: 'Error',
        html: `${event['error']['message']}`
      });
    }
  }
}
