// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// WARNING - Values under "firebase" and value of "googleApiKey" needs to be replaced from your own accounts
// If left as is, it firbase and google map related functionality will not work on LIVE instance.
export const environment = {
    production: false,
    firebase: {
        apiKey: 'AIzaSyA_i8r4TcmhbPohun9U379WtTt_mvEt_vc', // AIzaSyA_i8r4TcmhbPohun9U379WtTt_mvEt_vc
        projectId: 'kiran-64e4c', // kiran-64e4c
        authDomain: 'kiran-64e4c.firebaseapp.com', // kiran-64e4c.firebaseapp.com
        appId: '1:4501087420:web:9e1fd1fcfc74bef6e0dcb9' // 1:4501087420:web:9e1fd1fcfc74bef6e0dcb9
    },
    googleApiKey: 'AIzaSyAIIYOxA7qeetFz6TuR1Qewc0Rrjhzx7ZU',
    apiUrl: "http://127.0.0.1:5000",
    // apiAuthUrl: "https://go.0to60.ai/api"
    apiAuthUrl: "/api",
    apiAgents: "/agents",
    apiAutoDash: "/dash",
    apiPinnacle: "/pinnacle",
    apiForecast: "/forecast-api"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
