<div class="container-xxl mt-2">
    <div class="page-main project-job-list job-list-page pages">
        <div class="flex flex-column md:flex-row md:justify-content-between">
            <div class=" page-title title d-flex justify-content-between align-items-center  ">
                <h1>AI Agent Hub</h1>
                <button type="button" class="btn btn-primary config-agents-btn d-flex p-0">
                    <div class="click-btn" (click)="emitter()"> <i class="pi pi-plus"></i> Create Agent</div>
                </button>
            </div>
        </div>

        <div class="row" *ngIf="agentsCont.length">
            <div class="col-4 mb-2" *ngFor="let agent of agentsCont">
                <div class="model-card agent">
                   <div class="d-flex justify-content-between">
                    <h4 class="header link mb-2">
                        {{agent['agent_name']}}
                    </h4>
                    <i class="pi pi-trash" (click)="deleteAgent(agent)"></i>
                   </div>
                    <div class="model-info mb-1" style="display: flex; align-items: baseline;">
                        <h4 class="label-title m-0">Description :</h4>
                        <p style="height: 50px;">
                            {{agent['description']}}
                        </p>
                    </div>
                    <!-- <div class="model-info mb-1">
                        <h4 class="label-title m-0">Created by :</h4>
                        <p>
                            {{'heading'}}
                        </p>
                    </div>
                    <div class="model-info mb-1">
                        <h4 class="label-title m-0">Created on :</h4>
                        <p>
                            {{"heading"}}
                        </p>
                    </div> -->
                    <div class="model-info mb-1">
                        <h4 class="label-title m-0">Tools</h4>
                        <div class="selected-tool" >
                            <span class="selected-tool-text pointer" 
                            *ngFor="let tool of agent['tools']"
                            > {{tool | slice:0:8 }} <span *ngIf="tool.length>8" >...</span> </span>
                        </div>
                    </div>

                    <div class="text-end">
                        <button class="btn btn-secondary" (click)="selectedAgent(agent)">Select</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
