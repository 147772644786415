<div class="row semantic-constrains">
    <div class="col-4 address add-col mx-5" style="background-color: white; border-radius: 8px;height: 512px;">
        <div class="row customer-add">{{selectedColumnDetails['table']['tableName']}}</div>
        <div class="row address-add">
            <div class=" ">{{selectedColumnDetails['selectedColumn']}} <i class="ficon feather ft-filter float-right"
                    style="margin-top: 4px;"></i>
            </div>
        </div>
        <div *ngIf="topRecords.length > 0" class="address-data">
            <ul class="pt-2 address-col  ">
                <li class="mb-1" *ngFor="let record of topRecords">{{record}}</li>
            </ul>
        </div>
    </div>

    <div class="col-4 semantic-type ml-5 pl-3" style="background-color: white; ">
        <div class="row mb-2 pl-1">
            {{ 'semanticsconstraints.assigntext' | translate }} <a href="javascript:void(0)" (click)="setUp()"
                class="underline">{{ 'semanticsconstraints.doclink' | translate }}</a>
        </div>

        <form [formGroup]="assignSemanticsForm"
            aria-label="'Assign Semeantic Form All required input feilds are denoted by star'">
            <div class="row mb-2">
                <label class=" label-control" for="projectinput1">{{ 'inputforms.labels.semantictype' | translate }}
                    <span class="mendatory">*</span></label>
                <p-dropdown [filter]="true" styleClass="allDropdown" formControlName="attribute_type"
                    [placeholder]="('inputforms.placeholder.semantictype' | translate) ? ('inputforms.placeholder.semantictype' | translate) : ' '"
                    filterBy="name" [options]="validAttributeTypes" (onChange)="preField()" optionLabel="name"
                    optionValue="value">
                </p-dropdown>
            </div>
            <div class="row ">
                <label class="label-control mb-1" for="projectinput1">{{ 'inputforms.labels.constraints' | translate
                    }}</label>
                <div *ngIf="assignSemanticsForm.get('attribute_type').value == 'date'">


                    <div formGroupName="constraints">
                        <div class="mb-2">
                            <label class=" label-control" for="projectinput1">
                                {{ 'inputforms.labels.daysbefore' | translate }}
                            </label>
                            <input type="number" [placeholder]="'inputforms.placeholder.daysbefore' | translate "
                                id="projectinput1" class="form-control" formControlName="days_before">
                        </div>
                        <div class="mb-2">
                            <label class=" label-control" for="projectinput1">
                                {{ 'inputforms.labels.daysafter' | translate }}
                            </label>
                            <input type="number" [placeholder]="'inputforms.placeholder.daysafter' | translate"
                                id="projectinput1" class="form-control" formControlName="days_after">
                        </div>
                        <div class="mb-2">
                            <label class=" label-control" for="projectinput1">
                                {{ 'inputforms.labels.limittocurrent' | translate }}
                            </label>
                            <input type="checkbox" id="projectinput1" class="ml-1"
                                formControlName="limit_to_current_date">
                        </div>
                    </div>
                </div>
                <div *ngIf="assignSemanticsForm.get('attribute_type').value == 'said'; else other_constraints">
                    <form [formGroup]="saidConstraintsForm">
                        <div class="d-flex align-items-center">
                            <label class=" label-control" for="projectinput1">
                                {{ 'inputforms.labels.dobcolumn' | translate }}
                            </label>
                            <input class="apple-switch" formControlName="dobColumnExists" type="checkbox">
                        </div>
                        <div class="mb-2"
                            *ngIf="saidConstraintsForm.get('dobColumnExists').value && selectedColumnDetails['table']['columns']">
                            <p-dropdown [filter]="true" styleClass="allDropdown" formControlName="dobColumn"
                                [placeholder]="('inputforms.placeholder.dobcolumn' | translate) ? ('inputforms.placeholder.dobcolumn' | translate) : ' '"
                                filterBy="name" [options]="selectedColumnDetails['table']['columns']">
                            </p-dropdown>
                        </div>
                        <div class=" d-flex align-items-center">
                            <label class=" label-control" for="projectinput1">
                                {{ 'inputforms.labels.changegender' | translate }}
                            </label>
                            <input class="apple-switch" formControlName="changeGender" type="checkbox">
                        </div>
                        <div class="d-flex align-items-center" *ngIf="saidConstraintsForm.get('changeGender').value">
                            <label class=" label-control" for="projectinput1">
                                {{ 'inputforms.labels.gendercolumn' | translate }}
                            </label>
                            <input class="apple-switch" formControlName="genderColumnExists" type="checkbox">
                        </div>
                        <div class="mb-2" *ngIf="saidConstraintsForm.get('genderColumnExists').value">
                            <p-dropdown [filter]="true" styleClass="allDropdown" formControlName="genderColumn"
                                [placeholder]="('inputforms.placeholder.gendercolumn' | translate) ? ('inputforms.placeholder.gendercolumn' | translate) : ' '"
                                filterBy="name" [options]="selectedColumnDetails['table']['columns']">
                            </p-dropdown>
                        </div>
                    </form>
                </div>
                <ng-template #other_constraints>
                    <div class="text-center">
                        ---
                    </div>
                </ng-template>
            </div>
            <div class="row my-2 pl-1">{{'semanticsconstraints.zipcodetext' | translate }}</div>
            <div class="row mb-3">
                <label class=" label-control" for="projectinput1">{{ 'inputforms.labels.strategy' | translate}}<span
                        style="color:#f68c4f;">*</span></label>
                <p-dropdown [filter]="true" styleClass="allDropdown" formControlName="strategy"
                    [placeholder]="('inputforms.placeholder.strategy' | translate) ? ('inputforms.placeholder.strategy' | translate) : ' '"
                    [options]="strategies" filterBy="name" optionLabel="name" optionValue="value">
                </p-dropdown>
            </div>
            <div class="row">
                <div class="col-4  ">
                    <button type="button" class=" btn btn-raised btn-warning" (click)="testMethod()">
                        <i class="ficon feather ft-zap" style="color:  #fff; font-size:16px"></i>
                        <span uiSref="address" style="cursor: pointer;  margin-left: 2px;">
                            {{ 'buttontitle.testbutton' | translate }}
                        </span>
                    </button>
                </div>
                <div class="col-4 pr-0 text-center">
                    <button type="button" class=" btn btn-raised btn-warning" (click)="saveSemantic()">
                        {{ 'buttontitle.savebutton' | translate }}
                    </button>
                </div>
                <div class="col-4 pr-0 text-center">
                    <button type="button" class=" btn btn-raised btn-secondary mr-1 " (click)="clearSemantic()">
                        {{ 'buttontitle.clearbutton' | translate }}
                    </button>
                </div>
            </div>

        </form>
    </div>
</div>

<p-dialog [(visible)]="showTest" [resizable]="false" [draggable]="false" [modal]="true" [blockScroll]="true"
    [style]="{width: '45vw'}">
    <div class="p-dialog-titlebar">
        <button class="p-dialog-titlebar-icon p-dialog-titlebar-close" aria-label="Close Dialog" (click)="close(true)">
            <span class="pi pi-times"></span>
        </button>
    </div>
    <div *ngIf="showTest">
        <app-confirm-synthesis-data [testData]="confirmSyntheticData" (closeConfirm)="close($event)">
        </app-confirm-synthesis-data>
    </div>
</p-dialog>