import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  private apiUrl = environment.apiAuthUrl;

  constructor(
    private http: HttpClient
  ) { }

  createProject(body) {
    return this.http.post<any>(`${this.apiUrl}/projects`, body);
  }

  getAllProject(data) {
    let params = new HttpParams().append('filter', JSON.stringify({
      "limit": data['limit'],
      "skip": data['skip']
    }))
    return this.http.get<any>(`${this.apiUrl}/projects`, { params });
  }

  deleteProject(id: string) {
    return this.http.delete<any>(`${this.apiUrl}/projects/${id}`);
  }

  updateProjectById(body, id) {
    return this.http.patch<any>(`${this.apiUrl}/projects/${id}`, body);
  }

  getProject(id) {
    return this.http.get<any>(`${this.apiUrl}/projects/${id}`);
  }
}
