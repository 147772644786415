<!--begin::Card-->
<div mCard #mCard [options]="options" class="card">
    <div class="card-content collpase show" #mCardContent>
        <ng-content select="[mCardContent]"></ng-content>
        <div class="card-body" #mCardBody>
            <ng-content select="[mCardBody]"></ng-content>
        </div>
    </div>
    <div class="card-footer" #mCardFooter>
        <ng-content select="[mCardFooter]"></ng-content>
    </div>
</div>