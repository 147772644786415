<h2 class="popup-title text-center mb-1">{{ 'addorgmemberform.header2popuptitle' | translate }}</h2>
<div class="design-modal modal-body edit-profile-popup">
    <form [formGroup]="inviteForm" class="idForm" aria-label="'Add Org Form'">
        <div class="user-input">
            <label for="name">{{ 'inputforms.labels.name' | translate }}</label>
            <input type="text" id="name" name="name" class="form-control" formControlName="name"
                [placeholder]="'inputforms.placeholder.name' | translate "
                [ngClass]="{ 'is-invalid': inviteForm.controls['name'].invalid && (inviteForm.controls['name'].dirty || inviteForm.controls['name'].touched) }">
            <div
                *ngIf="inviteForm.controls['name'].invalid && (inviteForm.controls['name'].dirty || inviteForm.controls['name'].touched)">
                <div *ngIf="inviteForm.controls['name'].errors.required" class="invalid-feedback">
                    {{ 'inputforms.formerrors.requiredname' | translate }}
                </div>
            </div>
        </div>

        <div class="user-input">
            <label for="email">{{ 'inputforms.labels.email' | translate }}</label>
            <input type="email" id="email" name="email" class="form-control" formControlName="email"
                [placeholder]="'inputforms.placeholder.email' | translate " formControlName="email"
                [ngClass]="{ 'is-invalid': inviteForm.controls['email'].invalid && (inviteForm.controls['email'].dirty || inviteForm.controls['email'].touched) }">
            <div
                *ngIf="inviteForm.controls['email'].invalid && (inviteForm.controls['email'].dirty || inviteForm.controls['email'].touched)">
                <div *ngIf="inviteForm.controls['email'].errors.required" class="invalid-feedback">
                    {{ 'inputforms.formerrors.requiredname' | translate }}
                </div>
                <div *ngIf="inviteForm.controls['email'].errors.email" class="invalid-feedback">
                    {{ 'inputforms.formerrors.invalidemail' | translate }}
                </div>
            </div>
        </div>

        <div class="user-input">
            <label for="phone">{{ 'inputforms.labels.phone' | translate }}</label>
            <input type="tel" id="phone" name="phone" class="form-control" formControlName="phone"
                [placeholder]="'inputforms.placeholder.phone' | translate "
                [ngClass]="{ 'is-invalid': inviteForm.controls['phone'].invalid && (inviteForm.controls['phone'].dirty || inviteForm.controls['phone'].touched) }">
            <div
                *ngIf="inviteForm.controls['phone'].invalid && (inviteForm.controls['phone'].dirty || inviteForm.controls['phone'].touched)">
                <div *ngIf="inviteForm.controls['phone'].errors.required" class="invalid-feedback">
                    {{ 'inputforms.formerrors.requiredphone' | translate }}
                </div>
                <div *ngIf="inviteForm.controls['phone'].errors.pattern" class="invalid-feedback">
                    {{ 'inputforms.formerrors.invalidphone' | translate }}</div>
            </div>
        </div>

    </form>
    <div class="col-md-12 text-right bottom-end-btn">
        <button id="btnUpdateProfil" type="button" class="btn btn-raised btn-warning btn-small"
            (click)="sendInvitation()" [disabled]="!inviteForm.valid">{{ 'buttontitle.invitebutton' | translate }}
        </button>
    </div>
</div>