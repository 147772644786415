import { Component, Input } from '@angular/core';
import { FinancialAnalytixService } from 'src/app/_services/financial-analytix.service';
import { SharedService } from 'src/app/_services/shared.service';

@Component({
    selector: 'app-inventory',
    templateUrl: './inventory.component.html',
    styleUrls: ['./inventory.component.scss']
})
export class InventoryComponent {

    @Input() jobId;
    inventoryDetails: Object = {}
    inventoryChartDetails = []
    year = '';
    month = '';
    yearOptions = [];
    monthOptions = [];
    @Input() jobDetails;
    displayInsights: boolean = false;
    insights = []
    insightsTitle = ''
    loading: boolean = false

    constructor(
        private financialService: FinancialAnalytixService,
        private sharedService: SharedService
    ) {

    }
    ngOnInit() {
        this.fetchDashboard("", "");
    }

    fetchDashboard(year, month) {
        this.financialService.financialDashboard({
            job_id: this.jobId,
            year: year,
            month: month,
            sublevel: ''
        }).subscribe({
            next: res => {
                this.inventoryDetails = res;
                if (this.inventoryDetails && this.inventoryDetails['filter-values'] && this.inventoryDetails['filter-values']['Year'] && this.yearOptions.length == 0) {
                    this.yearOptions.push({
                        name: "All", value: ""
                    })
                    this.inventoryDetails['filter-values']['Year'].forEach(e => {
                        this.yearOptions.push({
                            name: e, value: e
                        });
                    })
                }
                if (this.inventoryDetails && this.inventoryDetails['filter-values'] && this.inventoryDetails['filter-values']['Month'] && this.monthOptions.length == 0) {
                    this.monthOptions.push({
                        name: "All", value: ""
                    })
                    this.inventoryDetails['filter-values']['Month'].forEach(e => {
                        this.monthOptions.push({
                            name: e, value: e
                        });
                    })
                }
                console.log('resu', res)
                this.chartMapping();
            }, error: err => {

            }
        })
    }

    changeInFilter(event, type) {
        if (type == 'year') {
            this.year = event.value
        } else {
            this.month = event.value
        }
        this.fetchDashboard(this.year, this.month)
    }

    chartMapping() {
        this.inventoryChartDetails = []
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

        let arr = [
            {
                name: 'Inventory Value Over Time', plot: 'plot1', type: 'line', options: {
                    maintainAspectRatio: false,
                    aspectRatio: 0.6,
                    plugins: {
                        legend: {
                            labels: {
                                color: textColor
                            }
                        }
                    },
                    scales: {
                        x: {
                            ticks: {
                                color: textColorSecondary
                            },
                            grid: {
                                color: surfaceBorder
                            }
                        },
                        y: {
                            ticks: {
                                color: textColorSecondary
                            },
                            grid: {
                                color: surfaceBorder
                            }
                        },
                        y1: {
                            type: 'linear',
                            display: true,
                            position: 'right',
                            ticks: {
                                color: textColorSecondary
                            },
                            grid: {
                                drawOnChartArea: false,
                                color: surfaceBorder
                            }
                        }
                    }
                }
            },
            {
                name: 'Turnover (Days) by Month', plot: 'plot2', type: 'line', options: {
                    maintainAspectRatio: false,
                    aspectRatio: 0.6,
                    plugins: {
                        legend: {
                            labels: {
                                color: textColor
                            }
                        }
                    },
                    scales: {
                        x: {
                            ticks: {
                                color: textColorSecondary
                            },
                            grid: {
                                color: surfaceBorder,
                                drawBorder: false
                            }
                        },
                        y: {
                            ticks: {
                                color: textColorSecondary
                            },
                            grid: {
                                color: surfaceBorder,
                                drawBorder: false
                            }
                        }
                    }
                }
            },
            {
                name: 'Top 20 Items Based on Value', plot: 'plot3', type: 'bar', options: {
                    indexAxis: 'y',
                    maintainAspectRatio: false,
                    aspectRatio: 0.8,
                    plugins: {
                        legend: {
                            labels: {
                                color: textColor
                            }
                        }
                    },
                    scales: {
                        x: {
                            ticks: {
                                color: textColorSecondary,
                                font: {
                                    weight: 500
                                }
                            },
                            grid: {
                                color: surfaceBorder,
                                drawBorder: false
                            }
                        },
                        y: {
                            ticks: {
                                color: textColorSecondary
                            },
                            grid: {
                                color: surfaceBorder,
                                drawBorder: false
                            }
                        }
                    }
                }
            },
            {
                name: 'Inventory Movement', plot: 'plot4', type: 'line', options: {
                    maintainAspectRatio: false,
                    aspectRatio: 0.8,
                    plugins: {
                        legend: {
                            labels: {
                                color: textColor
                            }
                        }
                    },
                    scales: {
                        x: {
                            ticks: {
                                color: textColorSecondary,
                                font: {
                                    weight: 500
                                }
                            },
                            grid: {
                                color: surfaceBorder,
                                drawBorder: false
                            }
                        },
                        y: {
                            ticks: {
                                color: textColorSecondary
                            },
                            grid: {
                                color: surfaceBorder,
                                drawBorder: false
                            }
                        }

                    }
                }
            },
            {
                name: 'Inventory to Sales Analysis', plot: 'plot5', type: 'line', options: {
                    maintainAspectRatio: false,
                    aspectRatio: 0.6,
                    plugins: {
                        legend: {
                            labels: {
                                color: textColor
                            }
                        }
                    },
                    scales: {
                        x: {
                            ticks: {
                                color: textColorSecondary
                            },
                            grid: {
                                color: surfaceBorder
                            }
                        },
                        y: {
                            ticks: {
                                color: textColorSecondary
                            },
                            grid: {
                                color: surfaceBorder
                            }
                        },
                        y1: {
                            type: 'linear',
                            display: true,
                            position: 'right',
                            ticks: {
                                color: textColorSecondary
                            },
                            grid: {
                                drawOnChartArea: false,
                                color: surfaceBorder
                            }
                        }
                    }
                }
            },
            {
                name: 'Sales Forecast', plot: 'plot6', type: 'line', options: {
                    maintainAspectRatio: false,
                    aspectRatio: 0.6,
                    plugins: {
                        legend: {
                            labels: {
                                color: textColor
                            }
                        }
                    },
                    scales: {
                        x: {
                            ticks: {
                                color: textColorSecondary
                            },
                            grid: {
                                color: surfaceBorder,
                                drawBorder: false
                            }
                        },
                        y: {
                            ticks: {
                                color: textColorSecondary
                            },
                            grid: {
                                color: surfaceBorder,
                                drawBorder: false
                            }
                        }
                    }
                }
            },
        ]
        for (let i = 0; i < arr.length; i++) {
            let obj = {
                title: arr[i]['name'],
                datasets: this.inventoryDetails['charts'][arr[i]['plot']],
                options: arr[i]['options'],
                type: arr[i]['type']
            }
            this.inventoryChartDetails.push(obj)
        }
    }

    invokeChatSliding(type) {
        this.sharedService.sendClickEvent({ jobId: this.jobId, type: 'inventory' }, type);
    }

    getInsights(chart) {
        this.loading= true;
        let body = {}
        this.displayInsights = true;
        this.insightsTitle = chart['title']
        body['plot_data'] = chart['datasets']

        body['filters'] = { year: this.year, month: this.month }

        this.financialService.fetchInsights(body).subscribe({
            next: res => {
                this.loading= false;
                console.log('insights', res)
                this.insights = res['insight']
            },
            error: err => { 
                this.loading= false;
            }
        })
    }
}

