<div class="automl-handler">
    <div class="field-radiobutton"
        *ngIf="automl['target'] == null || automl['target'] == selectedColumnDetails['selectedColumn']">
        <p-radioButton name="type" value="label" (onClick)="changeDetected()" [(ngModel)]="type"
            inputId="type1"></p-radioButton>
        <label for="type1">Select as Label</label>
    </div>
    <p class="btn-discription"
        *ngIf="automl['target'] == null || automl['target'] == selectedColumnDetails['selectedColumn']">Choosing this
        option indicates predictive column</p>
    <div class="field-radiobutton">
        <p-radioButton name="type" value="include" (onClick)="changeDetected()" [(ngModel)]="type"
            inputId="type2"></p-radioButton>
        <label for="type2">Include as a Feature</label>
    </div>
    <p class="btn-discription">Selecting this option signifies the inclusion of feature columns</p>
    <!-- <div class="field-radiobutton">
        <p-radioButton name="type" value="exclude" [(ngModel)]="type" inputId="type3"></p-radioButton>
        <label for="type3">Exclude Feature</label>
    </div> 
    <p class="btn-discription">Selecting this option signifies excluding the column as a feature or label.</p>-->

    <form [formGroup]="autoMLForm">
        <div class="" *ngIf="type == 'include'">
            <div class="btn-discription mt-2">
                <label>{{ 'Confirm Data Type' }}</label>
                <p-dropdown [filter]="true" [options]="dataTypeOptions" styleClass="allDropdown"
                    (onChange)="changeDetected()" filterBy="name" formControlName="dataType"
                    [placeholder]="'inputforms.placeholder.columnName' | translate" optionLabel="name"
                    optionValue="value">
                </p-dropdown>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="d-flex justify-content-end">
                    <div class="justify-content-end dynamic-form mt-2">
                        <button uiSref="address" type="button" class=" btn btn-test btn-small" (click)="resetForm()">
                            {{ 'buttontitle.clearbutton' | translate }}
                        </button>
                    </div>
                    <div class="justify-content-end dynamic-form mt-2">
                        <button uiSref="address" type="button" class=" btn btn-raised btn-warning btn-small"
                            [disabled]="disable" (click)="saveAutoML()">
                            {{ 'buttontitle.savebutton' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>