<div class="form-group" [formGroup]="form">
    <label>{{ field['label'] }}<span *ngIf="field['label']=='Workspace Name'" class="mendatory">*</span></label>

    <input [ngClass]="'form-control'" [type]="field['type']" [formControlName]="field['name']"
        placeholder="{{ field['placeholder'] ? field['placeholder'] : field['label'] }}">
    <ng-container [class.invalid]="f[field.name].touched && f[field.name].invalid">
        <div *ngIf="f[field.name].errors?.required && f[field.name].touched" class="invalid-feedback">
            {{ getErrorMessage('required') }}
        </div>
        <div *ngIf="f[field.name].errors?.minlength && f[field.name].touched" class="invalid-feedback">
            {{ getErrorMessage('minlength') }}
        </div>
        <div *ngIf="f[field.name].errors?.maxlength && f[field.name].touched" class="invalid-feedback">
            {{ getErrorMessage('maxlength') }}
        </div>
    </ng-container>
</div>