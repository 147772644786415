import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DbConnectionsService {

    private apiUrl = environment.apiUrl + '/v1';
    private NodeAPIUrl = environment.apiAuthUrl;

    constructor(
        private http: HttpClient
    ) { }

    getDBConnections(params) {
        return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
            params: params,
            method: 'GET',
            path: '/db/get_db_connections'
        })
    }

    // Need to Implement
    testConnection(body) {
        return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
            body: body,
            path: '/db/add_db_connection',
            method: 'POST',
            params: { test_connection: true }
        })
    }

    addDBConnection(body) {
        return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
            body: body,
            path: '/db/add_db_connection',
            method: 'POST'
        })
    }

    updateDBConnection(body) {
        return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
            path: '/db/update_db_connection',
            method: 'POST',
            body: body
        })
    }

    deleteDBConnection(params) {
        return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
            path: '/db/delete_db_connections',
            method: 'DELETE',
            params: params
        })
    }

    getSchemas(id) {
        return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
            path: "/db/get_schemas",
            method: 'GET',
            params: { connection_id: id }
        })
    }
}
