import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Toast } from 'src/app/_helpers/toast';

@Component({
  selector: 'app-automl-handler',
  templateUrl: './automl-handler.component.html'
})
export class AutomlHandlerComponent implements OnInit, OnChanges {

  autoMLForm: FormGroup;
  categories: any[] = [
    { name: 'Select as Label', key: 'label' },
    { name: 'Include as a Feature', key: 'include' },
    { name: 'Exclude Feature', key: 'exclude' }
  ];
  dataTypeOptions = [
    { name: 'Numeric', value: 'numeric' },
    { name: 'Categorical', value: 'categorical' },
    { name: 'Date', value: 'date' },
    { name: 'ID', value: 'id' }
  ];
  type: string = null;
  @Input() projectId: string = '';
  @Input() jobDetails = {};
  @Input() fileJob: any = {};
  @Input() selectedColumnDetails: any = {};
  @Input() PIIData = [];
  automl = { meta: null, target: null }
  @Output() autoMLEvent = new EventEmitter();
  disable: boolean = false;

  constructor(
    private fb: FormBuilder,
  ) {
    this.autoMLForm = this.fb.group({
      dataType: new FormControl('', Validators.required),
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedColumnDetails']) {
      this.patchTheFields();
      this.saved = true;
      this.validateSaveButton();
    }
  }

  ngOnInit(): void {

  }

  patchTheFields() {
    const selectedColumn = this.selectedColumnDetails?.selectedColumn;
    if (selectedColumn) {
      if (this.automl['target'] == selectedColumn) {
        this.type = 'label'
      } else if (this.automl['meta'] && this.automl['meta'][selectedColumn]) {
        this.type = 'include'
        this.autoMLForm.patchValue({ dataType: this.automl['meta'][selectedColumn] });
      } else {
        this.autoMLForm.reset();
        this.type = null;
      }
    }
  }

  changeDetected() {
    this.saved = false;
    this.validateSaveButton();
  }

  saved: boolean = false;
  saveAutoML() {
    const selectedColumn = this.selectedColumnDetails['selectedColumn'];
    switch (this.type) {
      case 'include':
        // Ensure that this.automl['meta'] is initialized
        if (!this.automl['meta']) {
          this.automl['meta'] = {};
        }
        this.automl['meta'][selectedColumn] = this.autoMLForm['value']['dataType'];
        break;
      case 'label':
        this.automl['target'] = selectedColumn;
        break;
      case 'exclude':
        break;
    }
    Toast.fire({
      icon: "success",
      html: `Column configuration saved`,
    });
    this.autoMLEvent.emit(this.automl);
    this.disable = true;
    this.saved = true;
  }

  validateSaveButton() {
    if ((this.automl['meta'] && this.automl['meta'][this.selectedColumnDetails['selectedColumn']]) || (this.automl['target'] && this.automl['target'] == this.selectedColumnDetails['selectedColumn'])) {
      if (this.saved) {
        this.disable = true;
      } else {
        if (this.type == 'label') {
          this.disable = false;
        } else if (this.type == 'include' && this.autoMLForm.valid) {
          this.disable = false;
        } else {
          this.disable = true;
        }
      }
    } else {
      if (this.type == 'label') {
        this.disable = false;
      } else if (this.type == 'include' && this.autoMLForm.valid) {
        this.disable = false;
      } else {
        this.disable = true;
      }
    }
  }

  resetForm() {
    this.autoMLForm.reset();
    this.type = null;

    const selectedColumn = this.selectedColumnDetails['selectedColumn'];

    if (this.automl['target'] == selectedColumn) {
      this.automl['target'] = null;
    }

    if (this.automl['meta'] && this.automl['meta'].hasOwnProperty(selectedColumn)) {
      delete this.automl['meta'][selectedColumn];
    }

    this.autoMLEvent.emit(this.automl);
  }
}
