<div class="row">
    <div class="col-md-12">
        <h2 class="popup-title label-control text-center">
            {{ status ? ('strategieshandler.createpattern' | translate ) :
            ('strategieshandler.pattern' | translate) }}
        </h2>
    </div>
</div>
<div class="row  " *ngIf="strategySchema.length > 0">
    <div class="col-12 p-0 " *ngIf="selectedAssignValue">
        <div class="row semantic-group">
            <div class="col-4 group-label"><label for="" class="label-control">{{ 'strategieshandler.semanticgroup' |
                    translate }}</label></div>
            <div class="col-8 value">
                <p>{{selectedAssignValue}}</p>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <app-form-builder [formSchema]="base" (popup)="emitterHandler($event)" [layout]="'grid'"></app-form-builder>
</div>

<div *ngIf="showSchema && schema.length" class="show-const">
    <div class="d-flex mb-1 mt-1">
        <p class="apply-const">{{ 'strategieshandler.para1constraints' | translate }}</p>
    </div>
    <app-form-builder [formSchema]="schema" (onSubmit)="strategyHandler($event)" [layout]="'grid'"
        [layoutStsHandler]="'layoutStsHandler'"></app-form-builder>
</div>