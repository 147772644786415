<div class="form-builder">
    <div class="row">
        <div class="form-group">
            <label>{{ 'Name' }}<span class="mendatory">*</span></label>
            <input [ngClass]="'form-control'" [(ngModel)]="toolName" placeholder="{{'Enter here'}}">
        </div>

        <div class="form-group">
            <label>{{ 'Description' }}<span class="mendatory">*</span></label>
            <input [ngClass]="'form-control'" [(ngModel)]="toolDescription" placeholder="{{ 'Enter here'}}">
        </div>

        <!--  -->

        <div class="form-group">
            <label>{{ 'API Endpoint' }}<span class="mendatory">*</span></label>
            <input [ngClass]="'form-control'" [(ngModel)]="apiEndpoint" placeholder="{{ 'Enter here'}}">
        </div>

        <div class="form-group">
            <label>{{ 'Call Type' }}<span class="mendatory">*</span></label>
            <p-dropdown [filter]="true" styleClass="allDropdown" filterBy="name" optionLabel="name" optionValue="value"
                [options]="apiMethods" [(ngModel)]="callType" [placeholder]="'Select Tool'">
            </p-dropdown>
        </div>

        <div class="form-group">
            <label>{{ 'Params' }}<span class="mendatory"> <i class="pi pi-plus" (click)="addParam()" style="    font-size: 10px;
                font-weight: 800;
                padding: 0px 6px; cursor: pointer;
            "></i> </span></label>
            <form [formGroup]="paramsForm">
                <div formArrayName="params">
                    <div *ngFor="let param of params.controls; let i = index" [formGroupName]="i" class="row mb-2">
                        <div class="col-4">
                            <input [ngClass]="'form-control'" formControlName="key" placeholder="Key">
                        </div>
                        <div class="col-5 p-0">
                            <input [ngClass]="'form-control'" formControlName="description" placeholder="Description">
                        </div>
                        <div class="col-3 text-end">
                            <button *ngIf="shouldShowCancelButton(i)" (click)="removeParam(i)"
                                class="btn btn-primary">Cancel</button>
                        </div>
                    </div>
                </div>
                <div class="">
                    <!-- <button (click)="addParam()" class="btn btn-primary">Add</button> -->
                </div>
            </form>
        </div>

        <!-- [disabled]="!(toolName && toolDescription && callType && apiEndpoint)" -->
        <div class="text-end">
            <button class="btn btn-primary mt-2 text-end" (click)="saveAPITool()">Save</button>
        </div>
    </div>
</div>