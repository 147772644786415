<div class="app-content content">
    <div class="content-wrapper">
        <div class="container-xxl mt-2">
            <div class="page-main pages" aria-label="Zero to Sixty Profile Section">
                <div class="d-flex align-items-center justify-content-between project-details-heading py-0 mb-0">
                    <div class="d-flex align-items-center">
                        <div class="companyLogo">
                            <!-- <img src="../../../../../assets/images/logo/company-logo.png" alt=""> -->
                            <i class="pi pi-user"></i>
                        </div>
                        <div>
                            <h1>{{userData['name']}}
                                <span class="edit-user pointer"><a (click)="editProfileModal()" #profileEdit
                                        href="javascript:void(0)" aria-label="Edit User Profile">
                                        <i *ngIf="!userId" class="feather ft-edit"></i>
                                    </a></span>
                            </h1>
                            <p>Project Manager</p>
                        </div>
                    </div>
                </div>

                <div class="personal-details" *ngIf="orgData['name']">
                    <h4 class="label-title"><i class="pi pi-building"></i>{{orgData['name']}}</h4>
                    <h4 class="label-title"> <i class="pi pi-envelope"></i>{{userData['email']}}</h4>
                    <h4 class="label-title"><i class="pi pi-phone"></i>{{userData['phoneNumber']}}</h4>
                    <h4 class="label-title change-password" (click)="changePasswordModal()">Change password</h4>
                </div>

                <div class="mt-3">
                    <h2 class="sub-title">Workspaces</h2>
                    <p-table #dt1 [columns]="selectedColumns" [value]="projects"
                        [paginator]="totalProjectRecords > 10 ? true : false" [totalRecords]="totalProjectRecords"
                        [rows]="10" [showCurrentPageReport]="true" [scrollable]="true"
                        [globalFilterFields]="['projectName','description']" [lazy]="true"
                        (onLazyLoad)="fetchProjects($event)" styleClass="prime-table ">
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th *ngFor="let col of columns; let i=index" [id]="'userData' + i"
                                    style="min-width:150px">
                                    {{col.header}}
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-product let-columns="columns">
                            <tr>
                                <td *ngFor="let col of columns" style="min-width:150px" [tabindex]="0"
                                    (keydown.enter)="goToProject(product, col.field)"
                                    (click)="goToProject(product, col.field)">
                                    <div *ngIf="col.field == 'viewJobs'"
                                        [ariaLabel]="col.header +'of'+product[col.field]">
                                        <div class="allprojectcard">
                                            <div id="drpdAllProjectCard" class="dropdown d-inline-block"
                                                (click)="goToJobs(product)">
                                                <button type="button" class="btn header-item"
                                                    id="btnPageHeaderUserDropdown">
                                                    <i class="feather ft-arrow-right"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="col.field != 'created' && col.field != 'modified'">
                                        {{product[col.field]}}
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="footer">
                            <tr>
                                <td *ngIf="loading">
                                    <div class="circle">
                                        <div class="circle__item">
                                            <svg class="circle__spinner" viewBox="0 0 50 50">
                                                <circle class="path" cx="25" cy="25" r="10" fill="none" stroke="#93bfec"
                                                    stroke-width="1"></circle>
                                            </svg>
                                        </div>
                                    </div>
                                </td>
                                <td *ngIf="loading">
                                    <div class="circle">
                                        <div class="circle__item">
                                            <svg class="circle__spinner" viewBox="0 0 50 50">
                                                <circle class="path" cx="25" cy="25" r="10" fill="none" stroke="#93bfec"
                                                    stroke-width="1"></circle>
                                            </svg>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>



                <!-- <div class=" ">
                    <div class="row">
                        <div class="col-12">
                            <div class="page-title">
                                <div class="d-flex   pb-2">
                                    <h1>{{userData['name']}}</h1>
                                    <span class="edit-user pointer"><a (click)="editProfileModal()" #profileEdit
                                            href="javascript:void(0)" aria-label="Edit User Profile">
                                            <i *ngIf="!userId" class="feather ft-edit"></i>
                                        </a></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 ">
                            <div class="page-data">
                                <div class="row">
                                    <div class="col-lg-4 col-md-12">
                                        <h2>{{'userprofile.title2information' | translate }}</h2>
                                    </div>
                                    <div class="col-lg-8 col-md-12">
                                        <div class="row">
                                            <div class="col-md-4 col-sm-6">
                                                <h4 class="label-title"> {{ 'userprofile.title3org' | translate }} </h4>
                                                <p>{{orgData['name']}}</p>
                                            </div>
                                            <div class="col-md-4 col-sm-6">
                                                <h4 class="label-title"> {{ 'userprofile.title3phone' | translate }}
                                                </h4>
                                                <p>{{userData['phoneNumber']}}</p>
                                            </div>
                                            <div class="col-md-4 col-sm-6">
                                                <h4 class="label-title"> {{ 'userprofile.title3email' | translate }}
                                                </h4>
                                                <p>{{userData['email']}}</p>
                                            </div>

                                            <div class="col-md-4 col-sm-6">
                                                <h4 class="label-title"> {{ 'userprofile.title3password' | translate }}
                                                </h4>
                                                <p><a href="javascript:void(0)" #profileEditpwd
                                                        (click)="changePasswordModal()">{{
                                                        'userprofile.changepwlink' | translate }}</a>
                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-lg-4 col-md-12">
                                                <h2 class="project-title">{{'userprofile.title2projects' | translate }}
                                                </h2>
                                            </div>
                                            <div class="col-lg-8 col-md-12">
                                                <div class=" ">
                                                    <div class=" project-table profile-table">
                                                        <p-table #dt1 [columns]="selectedColumns" [value]="projects"
                                                            [paginator]="totalProjectRecords > 10 ? true : false"
                                                            [totalRecords]="totalProjectRecords" [rows]="10"
                                                            [showCurrentPageReport]="true" [scrollable]="true"
                                                            [globalFilterFields]="['projectName','description']"
                                                            [lazy]="true" (onLazyLoad)="fetchProjects($event)"
                                                            styleClass="prime-table ">
                                                            <ng-template pTemplate="header" let-columns>
                                                                <tr>
                                                                    <th *ngFor="let col of columns; let i=index"
                                                                        [id]="'userData' + i" style="min-width:150px">
                                                                        {{col.header}}
                                                                    </th>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-product
                                                                let-columns="columns">
                                                                <tr>
                                                                    <td *ngFor="let col of columns"
                                                                        style="min-width:150px" [tabindex]="0"
                                                                        (keydown.enter)="goToProject(product, col.field)"
                                                                        (click)="goToProject(product, col.field)">
                                                                        <div *ngIf="col.field == 'viewJobs'"
                                                                            [ariaLabel]="col.header +'of'+product[col.field]">
                                                                            <div class="allprojectcard">
                                                                                <div id="drpdAllProjectCard"
                                                                                    class="dropdown d-inline-block"
                                                                                    (click)="goToJobs(product)">
                                                                                    <button type="button"
                                                                                        class="btn header-item"
                                                                                        id="btnPageHeaderUserDropdown">
                                                                                        <i
                                                                                            class="feather ft-arrow-right"></i>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            *ngIf="col.field != 'created' && col.field != 'modified'">
                                                                            {{product[col.field]}}
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="footer">
                                                                <tr>
                                                                    <td *ngIf="loading"
                                                                        style="background-color: #fdf8f4;">
                                                                        <div class="circle">
                                                                            <div class="circle__item">
                                                                                <svg class="circle__spinner"
                                                                                    viewBox="0 0 50 50">
                                                                                    <circle class="path" cx="25" cy="25"
                                                                                        r="10" fill="none"
                                                                                        stroke="#93bfec"
                                                                                        stroke-width="1"></circle>
                                                                                </svg>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>
                                                        </p-table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>

        </div>
    </div>
</div>
<p-dialog header="" [(visible)]="displayEditProfile" [resizable]="false" [modal]="true" [style]="{width: '45vw'}"
    [draggable]="false" [resizable]="false" [blockScroll]="true">
    <div class="p-dialog-titlebar">
        <button class="p-dialog-titlebar-icon p-dialog-titlebar-close" autofocus="true" aria-label="Close Dialog"
            (click)="closeprofile()">
            <span class="pi pi-times"></span>
        </button>
    </div>
    <app-edit-profile [orgData]="orgData" (updatedProfile)=profileEvent($event)></app-edit-profile>
</p-dialog>

<p-dialog header="" [(visible)]="displayChangePassword" [resizable]="false" [modal]="true" [style]="{width: '45vw'}"
    [draggable]="false" [resizable]="false" styleClass="user-change-pwd-pop" [blockScroll]="true">
    <div class="p-dialog-titlebar">
        <button class="p-dialog-titlebar-icon p-dialog-titlebar-close" autofocus="true" aria-label="Close Dialog"
            (click)="closeprofilePwd()">
            <span class="pi pi-times"></span>
        </button>
    </div>
    <div class="width-70p margin-auto">
        <app-change-password (display)="displayControl($event)" *ngIf="displayChangePassword"></app-change-password>
    </div>
</p-dialog>