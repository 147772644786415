import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Toast } from 'src/app/_helpers/toast';
import { AuthService } from 'src/app/_services/auth.service';
import { OrgService } from 'src/app/_services/org.service';
import { UserService } from 'src/app/_services/user.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.scss']
})
export class SetupComponent {

  setupOrgForm: FormGroup;
  displayFindOrg: boolean = true;
  displayInviteMembers: boolean = false;


  constructor(
    private orgService: OrgService,
    private userService: UserService,
    private authService: AuthService,
    private router: Router
  ) {
    this.setupOrgForm = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      address: new FormControl(null, [Validators.required]),
      website: new FormControl(null, [Validators.required]),
    })
  }


  setupOrg() {

    let tokenData = this.authService.currentUserValue;
    this.orgService.createOrg(this.setupOrgForm.value).subscribe({
      next: res => {

        this.userService.updateUserProfile({ orgId: res.id, isOrgVerified: true }, tokenData.id).subscribe({
          next: res => {
            Swal.fire({
              title: 'You have successfully created your organization',
              showDenyButton: true,
              confirmButtonText: 'Invite Team Members',
              denyButtonText: `Skip`,
            }).then((result) => {
              if (result.isConfirmed) {
                this.displayFindOrg = false;
                this.displayInviteMembers = true;
              } else if (result.isDenied) {
                this.router.navigate(['/projects']);
              }
            })
          },
          error: err => {
            Toast.fire({
              icon: 'error',
              html: err.error.error.message
            });
          }
        })
      },
      error: err => {
        Toast.fire({
          icon: 'error',
          html: err.error.error.message
        });
      }
    })
  }

  changeStep(event) {
    switch (event) {
      case 1:
        this.displayFindOrg = false;

        break;
      case 2:
        this.displayFindOrg = false;
        this.displayInviteMembers = true;

        break;
      default:
        this.displayInviteMembers = false;
        this.displayFindOrg = true;
    }
  }
}
