import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, Renderer2, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-dependent-field',
  templateUrl: './dependent-field.component.html'
})
export class DependentFieldComponent implements OnChanges, OnDestroy {

  @Input() field: any = {};
  @Input() form !: FormGroup;
  displaydependentSelection: boolean;
  @Output() dFEmitter = new EventEmitter();
  dF: Object[] = [];
  tableData: any;
  @Input() clearDependentField: boolean = false;

  constructor(private renderer: Renderer2, private cdRef: ChangeDetectorRef, private elementRef: ElementRef) {

  }

  ngOnDestroy(): void {
    this.dF = [];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.field['value'] !== null) {
      this.dF = this.field['value'].map(obj => ({ ...obj }));
    } else {
      this.dF = [];
    }

    setInterval(() => {
      if (this.clearDependentField) {
        this.dF = []
        this.cdRef.detectChanges();
        this.clearDependentField = false;
      }
    }, 1000)

    if (this.field && this.field['tabIndex'] && this.field['tabIndex'] == 0) {
      const aElement = this.elementRef.nativeElement.querySelector('a');
      if (aElement.getAttribute('data-autofocus') === 'true') {
        this.renderer.selectRootElement(aElement).focus();
      }
    }

    this.tableData = Object.assign({}, this.field['options']['table']);
  }

  removeDependentField(item, index) {
    this.dF.splice(index, 1);
    this.dFEmitter.emit(this.dF)
  }

  dependentFields(data: any) {
    this.displaydependentSelection = false;
    this.dF = data.map(obj => ({ ...obj }))
    this.dFEmitter.emit(data)
  }

}
