import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { AuthService } from 'src/app/_services/auth.service';
import { PdfService } from 'src/app/_services/pdf.service';
import { ReportsService } from 'src/app/_services/reports.service';
@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html'
})
export class ReportsComponent implements OnChanges, OnDestroy {

  @Input() reportsData = {}
  @Input() acc = {}
  data: {} = {}
  individualChartContainer: {}[] = []
  overallAcc: {} = {}
  @Input() jobReportMetaData = {};
  downloadpdf: boolean;

  constructor(
    private pdfService: PdfService,
    private reportsService: ReportsService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.reportsData = {}
    this.acc = {}
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['acc'] && this.acc !== undefined) {
      Object.entries(this.acc['ind_acc']).forEach(([key, value]) => {
        this.data = {}
        if (typeof value == 'number') {
          this.data = {
            labels: [key],
            datasets: [
              {
                data: [value * 100, 100 - value * 100],
                backgroundColor: [
                  "#90EE90",
                  "#FA4160"
                ],
              }
            ]
          };
          this.individualChartContainer.push(this.data)
        }
      });
      this.overallAcc = {
        labels: ['Overall Accuracy'],
        datasets: [
          {
            data: [this.acc['mean_acc'] * 100, 100 - this.acc['mean_acc'] * 100],
            backgroundColor: [
              "#90EE90",
              "#FA4160"
            ],
          }
        ]
      };
    }
  }

  goToDoc() {
    const url = 'https://docs.0to60.ai/reports/correlation-matrices-report';
    window.open(url, '_blank', 'noopener,noreferrer');
  }

  async downloadPDF(type) {
    const currentUser = this.authService.currentUserValue;
    this.downloadpdf = true;
    let finalRepoData = structuredClone(this.reportsData)
    switch (type) {
      case 'Data Quality Reports':
        let r = await this.reportsService.getAccuracyChartsAsync({ job_id: this.jobReportMetaData['jobId'], execution_id: this.jobReportMetaData['executionId'], org_id: currentUser.orgId, return_mode:'png' });
        let results = []
        for (const item of this.jobReportMetaData['columns']) {
          let result = await this.reportsService.getInviAccuracyChartsAsync({ job_id: this.jobReportMetaData['jobId'], execution_id: this.jobReportMetaData['executionId'], org_id: currentUser.orgId, column: item.replace("_source_col", ""), return_mode:'png' });
          results.push({
            column: item,
            img: result
          })
        }
        this.pdfService.accuracyPDF({
          overrall: r,
          invi: results,
          jobMeta: this.jobReportMetaData
        })
        break;
      case 'Correlation':
        let col = {};
        for (const key in finalRepoData) {
          if (key.startsWith('Correlation') || key.startsWith('Pair')) {
            col[key] = finalRepoData[key];
          }
        }
        this.reportsService.correlationMatricesReport({ job_id: this.jobReportMetaData['jobId'], execution_id: this.jobReportMetaData['executionId'], org_id: currentUser.orgId, return_mode:'png' }).subscribe({
          next: async res => {
            col['Correlation Matrices Report'][0]['img_result'] = res['img_result']
            let result: Object = await this.reportsService.pairPlotReportAsync({ job_id: this.jobReportMetaData['jobId'], execution_id: this.jobReportMetaData['executionId'], org_id: currentUser.orgId, return_mode:'png' })
            col['Pair Plot Report'][0]['img_result'] = result['img_result']
            this.pdfService.correlationPDF({
              res: col,
              jobMeta: this.jobReportMetaData
            })
          },
          error: err => {
  
          }
        })
        break;
      case 'Distribution':
        let dis = {};
        for (const key in finalRepoData) {
          if (key.startsWith('Distribution')) {
            dis[key] = finalRepoData[key];
          }
        }
        for (let i = 0; i < this.jobReportMetaData['columns'].length; i++) {
          let r = await this.reportsService.singleFeatureDistributionReportAsync({ job_id: this.jobReportMetaData['jobId'], execution_id: this.jobReportMetaData['executionId'], org_id: currentUser.orgId, feature1_name: this.jobReportMetaData['columns'][i], return_mode:'png'})
          dis[`Distribution of ${this.jobReportMetaData['columns'][i]}`][0]['img_result'] = r['img_result'];
        }
        this.pdfService.distributionPDF({
          res: dis,
          jobMeta: this.jobReportMetaData
        })
        break;
      case 'Clustering':
        let clus = {};
        for (const key in finalRepoData) {
          if (key.startsWith('NNDR') || key.startsWith('DCR')) {
            clus[key] = finalRepoData[key];
          }
        }
        let result: Object = await this.reportsService.NNDRReportAsync({job_id: this.jobReportMetaData['jobId'], execution_id: this.jobReportMetaData['executionId'],  org_id: currentUser.orgId, return_mode:'png' })
        result = result['message']
        clus['NNDR (Nearest Neighbor Distance Ratio) Report'][0]['img_result'] = result['image']['nndr']
        clus['DCR (Density-Based Clustering Report)'][0]['img_result'] = result['image']['dcr']
        this.pdfService.clusteringPDF({
          res: clus,
          jobMeta: this.jobReportMetaData
        })
        break;
    }
    this.downloadpdf = false
  }
}
