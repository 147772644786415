<div class="pop-up-title">
    <h2 class="popup-title text-center">{{ 'Create New model' | translate }}</h2>
</div>

<div class="design-modal modal-body edit-profile-popup add-projct-pop-up">
    <form [formGroup]="orgForm" class="idForm" aria-label="'Org Form All required input feilds are denoted by star'">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label>{{ 'Select Source' | translate }} <span class="mendatory">*</span></label>
                    <input id="txtOrgName" type="text" [placeholder]="'Select source' | translate " pInputText
                        class="p-inputtext form-control" aria-label="'Please enter your Org Name'"
                        formControlName="selectsource">
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>{{ 'Hugging Face Token' | translate }} <span class="mendatory">*</span></label>
                    <input id="txtOrgName" type="text" [placeholder]="'Hugging Face Token' | translate " pInputText
                        class="p-inputtext form-control" aria-label="'Please enter your Org Name'"
                        formControlName="huggingfacetoken">
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>{{ 'Model Name' | translate }} <span class="mendatory">*</span></label>
                    <input id="txtOrgName" type="text" [placeholder]="'Model name' | translate " pInputText
                        class="p-inputtext form-control" aria-label="'Please enter your Org Name'"
                        formControlName="modelname">
                </div>
            </div>
        </div>

        <div class="row user-img-add">
            <div class="col-md-12 text-right bottom-end-btn">
                <button class="btn btn-raised btn-warning btn-small">Save</button>
            </div>
        </div>
    </form>
</div>