import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SyntheticDataService } from 'src/app/_services/synthetic-data.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Toast } from 'src/app/_helpers/toast';

@Component({
  selector: 'app-check-bias',
  templateUrl: './check-bias.component.html'
})

export class CheckBiasComponent implements OnInit, OnChanges {

  checkBiasForm: FormGroup;
  addbias: boolean = false;
  @Input() columnsData: Object[] = []
  @Input() jobDetails: Object = {}
  @Input() selectedColumnDetails: Object = {};
  @Input() fileJob: Object = {}
  decisionAttrsOptions: Object[] = []
  biasAnalysisData: Object = {}
  addBiasData: Object = {}
  @Output() biasEmitter = new EventEmitter()
  showCharts: boolean = false;
  private htmlContent: string = '';
  public trustedHtml: SafeHtml;
  tableHeaders = [];
  loading: boolean = false;
  tableData = [];
  selectedColumns = []
  desirableValueOptions = []
  desirableForm: FormGroup;
  showSave: boolean = false;
  public showClear: boolean = false;

  constructor(
    private fb: FormBuilder,
    private syntheticDataService: SyntheticDataService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['columnsData']) {
      this.getDecisionAttrs()
    }
  }

  ngOnInit(): void {
    this.checkBiasForm = this.fb.group({
      decisionAttribute: ['', Validators.required]
    });

    this.desirableForm = this.fb.group({
      desirable: [null, Validators.required], // Adjust the validation as needed
    });
  }

  getDecisionAttrs() {
    this.decisionAttrsOptions = []
    if (Array.isArray(this.columnsData) && this.columnsData.length) {
      for (let i = 0; i < this.columnsData.length; i++) {
        if (this.columnsData[i]['columnlet_type'] == 'NA' && Array.isArray(this.columnsData[i]['columnlets_data']) && this.columnsData[i]['columnlets_data'][0]['semantic_group'] == 'categorical') {
          this.selectedColumns.push(this.columnsData[i]['source_column'])
          this.decisionAttrsOptions.push({
            name: this.columnsData[i]['source_column'],
            value: this.columnsData[i]['source_column']
          })
        }
      }
    }
  }

  fetchBiasAnalysis() {
    this.showSave = false
    this.loading = true;
    let body = {}
    if (this.fileJob && this.fileJob['job_id']) {
      body = {
        decision_attribute: this.checkBiasForm['value']['decisionAttribute'],
        job_id: this.fileJob['job_id'],
        selected_columns: this.selectedColumns.join(', '),
        max_rows: 20
      }
    } else {
      body = {
        connection_id: this.jobDetails['selectSource']['id'],
        schema_name: this.jobDetails['selectSchema'],
        table_name: this.selectedColumnDetails['table']['tableName'],
        decision_attribute: this.checkBiasForm['value']['decisionAttribute'],
        selected_columns: this.selectedColumns.join(', '),
        max_rows: 20
      }
    }
    this.syntheticDataService.getBiasAnaylsis(body).subscribe({
      next: res => {
        this.biasAnalysisData = res;
        if (this.biasAnalysisData && typeof this.biasAnalysisData == 'object' && Object.keys(this.biasAnalysisData).length > 1 && this.biasAnalysisData['distribution']) {
          this.htmlContent = this.biasAnalysisData['distribution'];
          this.trustedHtml = this.sanitizer.bypassSecurityTrustHtml(this.htmlContent);
          this.tableHeaders = Object.keys(this.biasAnalysisData['analysis'])
          this.tableHeaders.push('Desirable Value')
          this.biasAnalysisData['group_target_attr'].forEach(r =>
            this.desirableValueOptions.push({
              name: r,
              value: r
            })
          );
          this.tableData = this.convertDataToObjects(this.biasAnalysisData['analysis']);
          this.showCharts = true;
          this.loading = false;
        } else {
          Toast.fire({
            icon: 'error',
            title: 'Error',
            html: res.message
          });
          this.loading = false;
        }
      }, error: err => {

      }
    })
  }

  // src/app/sample-data.ts

  convertDataToObjects(data: { [key: string]: any }): any[] {
    const keys = Object.keys(data);
    const values = keys.map((key) => data[key]);

    // Check if all values have the same length
    const valid = values.every((value) => Array.isArray(value) && value.length === values[0].length);

    if (!valid) {
      throw new Error('All properties in the data object must have the same length.');
    }

    return values[0].map((_, index) => {
      const obj: { [key: string]: any } = {};
      keys.forEach((key) => {
        obj[key] = data[key][index];
      });
      return obj;
    });
  }


  addBiasEvent(event) {
    this.addBiasData = event
  }

  saveBiasConfig() {
    this.addBiasData['Y'] = this.checkBiasForm['value']['decisionAttribute'];
    this.biasEmitter.emit(this.addBiasData)
  }

  saveDV(item: any) {
    // Set the selected "Desirable Value" in the form
    // this.desirableForm.patchValue({ desirable: item['Desirable Value'] });

    // Disable other form controls
    this.showClear = true;
    this.tableData.forEach((dataItem) => {
      if (dataItem !== item) {
        dataItem.disabled = true;
      }
    });
    this.showSave = true;
    this.addBiasData['S'] = item['Column']
    this.addBiasData['underprivileged_value'] = item['Group']
    this.addBiasData['desirable_value'] = this.desirableForm['value']['desirable']
  }

  clearDV(item: any) {
    this.showClear = false;
    // Clear the form and enable other form controls
    this.desirableForm.reset();
    this.showSave = false;
    this.tableData.forEach((dataItem) => {
      dataItem.disabled = false;
    });
  }

}
