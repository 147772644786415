<div class="setup-job">
    <form [formGroup]="setupJobForm" *ngIf="setupJobForm"
        [ariaLabel]="'Step 1 out of 3 setup job form All required input feilds are denoted by star'">
        <div class="row">
            <div class="col-lg-12">
                <div class="form-group page-content row mx-auto" *ngIf="queryParamNull">
                    <h2 class="col-md-3 label-control" for="projectinput1">{{ 'Workspace' }}
                    </h2>
                    <div class="col-lg-8 col-md-7 setup-info">
                        <div class="row">
                            <div class="col-lg-6">
                                <label class=" label-control" for="projectinput1">{{ 'Select Workspace' }} <span
                                        class="mendatory">*</span></label>
                                <span class="p-fluid">
                                    <p-dropdown [filter]="true" formControlName="projectId" appendTo="body"
                                        [options]="workspacesOptions" filterBy="name" optionLabel="name"
                                        optionValue="value" (onFilter)="filterOptions($event)"
                                        [placeholder]="('inputforms.placeholder.selectworkspace' | translate) ? ('inputforms.placeholder.selectworkspace' | translate) : ' '"
                                        (onChange)="fetchDBs()" [resetFilterOnHide]="true"
                                        [ngClass]="{ 'is-invalid': (submitted || setupJobForm.get('projectId').touched) && setupJobForm.get('projectId').invalid }">
                                        <ng-template let-option pTemplate="item">
                                            <div class="country-item">
                                                <div class="d-flex justify-content-between">
                                                    {{option.name}}
                                                </div>
                                            </div>
                                        </ng-template>
                                    </p-dropdown>

                                    <div
                                        *ngIf="setupJobForm.get('projectId').invalid &&  setupJobForm.get('projectId').touched">
                                        <div class="invalid-feedback">
                                            {{ 'inputforms.formerrors.requiredselectworkspace' | translate }}</div>
                                    </div>
                                </span>
                            </div>
                            <div class="col-lg-6"> </div>
                        </div>
                    </div>
                </div>
                <div class="form-group page-content row mx-auto">
                    <h2 class="col-md-3 label-control" for="projectinput1">{{ 'setupjob.header2general' | translate }}
                    </h2>
                    <div class="col-lg-8 col-md-7 setup-info">
                        <div class="row">
                            <div class="col-lg-6">
                                <label class=" label-control" for="projectinput1">{{ 'inputforms.labels.jobname' |
                                    translate }}
                                    <span class="mendatory">*</span></label>
                                <input type="text" id="projectinput1" class="form-control" formControlName="jobName"
                                    [placeholder]="'inputforms.placeholder.jobname' | translate"
                                    (change)="setupJobEmitter('')"
                                    [ngClass]="{ 'is-invalid': (submitted || setupJobForm.get('jobName').touched) && setupJobForm.get('jobName').invalid  }">
                                <div
                                    *ngIf="setupJobForm.get('jobName').invalid &&  setupJobForm.get('jobName').touched">
                                    <div class="invalid-feedback">{{ 'inputforms.formerrors.requiredjobname' | translate
                                        }}
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-lg-6">
                                <label class=" label-control" for="projectinput1">{{ 'inputforms.labels.selectlocale' |
                                    translate }} <span class="mendatory">*</span></label>
                                <span class="p-fluid">
                                    <p-dropdown [filter]="true" formControlName="locale" [options]="localeList"
                                        appendTo="body" filterBy="name" optionLabel="name" optionValue="value"
                                        (onFilter)="filterOptions($event)"
                                        [placeholder]="('inputforms.placeholder.selectlocale' | translate) ? ('inputforms.placeholder.selectlocale' | translate) : ' '"
                                        (onChange)="setupJobEmitter('')" [resetFilterOnHide]="true"
                                        [ngClass]="{ 'is-invalid': (submitted || setupJobForm.get('locale').touched) && setupJobForm.get('locale').invalid }">
                                        <ng-template let-option pTemplate="item">
                                            <div class="country-item">
                                                <div class="d-flex justify-content-between">
                                                    {{option.name}}
                                                </div>
                                            </div>
                                        </ng-template>
                                    </p-dropdown>

                                    <div
                                        *ngIf="setupJobForm.get('locale').invalid &&  setupJobForm.get('locale').touched">
                                        <div class="invalid-feedback">
                                            {{ 'inputforms.formerrors.requiredselectlocale' | translate }}</div>
                                    </div>
                                </span>
                            </div> -->
                        </div>

                    </div>
                </div>


                <div class="form-group page-content row mx-auto"
                    *ngIf="(queryParamNull && setupJobForm.get('projectId').value) || projectId">
                    <h2 class="col-md-3 label-control" for="projectinput1">
                        {{ 'setupjob.header2sourcetype' | translate }}</h2>
                    <div class=" col-lg-8 col-md-7 setup-info">
                        <div class="row">
                            <div class="col-lg-6">
                                <label class=" label-control" for="projectinput1">{{ 'inputforms.labels.sourcetype' |
                                    translate
                                    }} <span class="mendatory">*</span></label>
                                <span class="p-fluid">
                                    <p-dropdown formControlName="sourceType" [filter]="true" styleClass="allDropdown"
                                        appendTo="body" filterBy="name" [options]="sourceTypeList" optionLabel="name"
                                        optionValue="value"
                                        [placeholder]="('inputforms.placeholder.sourcetype' | translate) ? ('inputforms.placeholder.sourcetype' | translate) : ' '"
                                        (onChange)="typeHandler()"
                                        [ngClass]="{ 'is-invalid': (submitted || setupJobForm.get('sourceType').touched) && setupJobForm.get('sourceType').invalid  }">
                                        <ng-template let-option pTemplate="item">
                                            <div class="country-item">
                                                <div class="d-flex justify-content-between">
                                                    {{option.name}}
                                                </div>
                                            </div>
                                        </ng-template>
                                    </p-dropdown>
                                    <div
                                        *ngIf="setupJobForm.get('sourceType').invalid &&  setupJobForm.get('sourceType').touched">
                                        <div class="invalid-feedback">
                                            {{ 'inputforms.formerrors.requiredsourcetype' | translate }}</div>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="orm-group page-content row mx-auto" *ngIf="type == 'database'">
                    <h2 class="col-md-3 label-control" for=" projectinput1">
                        {{ 'setupjob.header2selectdb' | translate }}
                    </h2>
                    <div class="col-lg-8 col-md-7 setup-info">
                        <div class="row">
                            <div class="col-md-12 mb-2">
                                <p>{{ 'setupjob.para1text' | translate }}</p>
                                <p>{{ 'setupjob.para2text' | translate }}</p>
                                <p> {{ 'setupjob.para3text' | translate }}</p>
                            </div>
                            <div class="col-lg-6">
                                <label class=" label-control" for="projectinput1">
                                    {{ 'inputforms.labels.connection' | translate }}<span class="mendatory">*</span>
                                </label>
                                <span class="p-fluid">
                                    <p-dropdown [filter]="true" styleClass="allDropdown" filterBy="name" appendTo="body"
                                        formControlName="selectSource" [options]="connections"
                                        optionLabel="connection_name"
                                        [placeholder]="('inputforms.placeholder.connection' | translate) ? ('inputforms.placeholder.connection' | translate) : ' '"
                                        (onChange)="setupJobEmitter('change')"
                                        [ngClass]="{ 'is-invalid': (submitted || setupJobForm.get('selectSource').touched) && setupJobForm.get('selectSource').invalid  }"
                                        emptyMessage="No records.">
                                        <ng-template let-option pTemplate="item">
                                            <div class="country-item">
                                                <div class="d-flex justify-content-between">
                                                    {{option.connection_name}}
                                                </div>
                                            </div>
                                        </ng-template>
                                    </p-dropdown>
                                    <div class="icon-container" *ngIf="setupJobForm.get('selectSource').value">
                                        <a href="javascript:void(0)" aria-label="Edit Connection"
                                            (click)="editDB(setupJobForm.get('selectSource').value)">
                                            <i class="feather ft-edit mr-1"></i>
                                        </a>

                                        <a href="javascript:void(0)" aria-label="Delete Connection"
                                            (click)="deleteDB(setupJobForm.get('selectSource').value)">
                                            <i class="feather ft-trash"></i>
                                        </a>
                                    </div>
                                    <div
                                        *ngIf="setupJobForm.get('selectSource').invalid &&  setupJobForm.get('selectSource').touched">
                                        <div class="invalid-feedback"> {{ 'inputforms.formerrors.requireddbconnection' |
                                            translate }}</div>
                                    </div>
                                </span>
                                <div class="ngb-dp-day new-connection ">
                                    <a href="javascript:void(0)" (click)="showDialog()" class="pointer"> <i
                                            class=" feather ft-settings"></i> {{ 'setupjob.configurelink' | translate
                                        }}</a>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="loader-wrapper mt-5" *ngIf="spinner">
                                    <div class="loader-container">
                                        <div class="ball-pulse loader-primary">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                    </div>
                                </div>
                                <ng-container *ngIf="showSelectSchema">
                                    <label class="label-control">
                                        {{ 'inputforms.labels.schema' | translate }} <span class="mendatory">*</span>
                                    </label>
                                    <p-dropdown [filter]="true" styleClass="allDropdown" formControlName="selectSchema"
                                        appendTo="body" [options]="schemaList" (onChange)="setupJobEmitter('')"
                                        [placeholder]="('inputforms.placeholder.schema' | translate ) ? ('inputforms.placeholder.schema' | translate) : ' '"
                                        [ngClass]="{ 'is-invalid': (submitted || setupJobForm.get('selectSchema').touched) && setupJobForm.get('selectSchema').invalid  }">
                                    </p-dropdown>
                                    <div
                                        *ngIf="setupJobForm.get('selectSchema').invalid &&  setupJobForm.get('selectSchema').touched">
                                        <div class="invalid-feedback">
                                            {{ 'inputforms.formerrors.requiredschema' | translate }}</div>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="col-lg-6"></div>
                            <div class="col-lg-6">
                                <ng-container *ngIf="showSelectSchema && showSelectTable">
                                    <label class="label-control">
                                        {{ 'Select Table' }} <span class="mendatory">*</span>
                                    </label>
                                    <p-dropdown [filter]="true" styleClass="allDropdown" formControlName="selectTable"
                                        appendTo="body" [options]="tableList" [placeholder]="'Select Table'"
                                        (onChange)="setupJobEmitter('')"
                                        [ngClass]="{ 'is-invalid': (submitted || setupJobForm.get('selectTable').touched) && setupJobForm.get('selectTable').invalid  }">
                                    </p-dropdown>
                                    <div
                                        *ngIf="setupJobForm.get('selectTable').invalid &&  setupJobForm.get('selectTable').touched">
                                        <div class="invalid-feedback">
                                            {{ 'inputforms.formerrors.requiredschema' | translate }}</div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="type === 'json' || type === 'csv' || type === 'xml'">
                    <div class="form-group page-content row mx-auto">
                        <h2 class="col-md-3  label-control" for="projectinput1">
                            {{ 'setupjob.header2upload' | translate }}</h2>
                        <div class="col-lg-8 col-md-7 setup-info">
                            <div *ngIf="!uploadedFiles.length && (!xmlFile || !xsdFile)">
                                <p-fileUpload *ngIf="type !== 'xml'" #fubauto mode="basic" name="demo[]"
                                    [ariaLabel]="'please upload csv file'"
                                    [accept]="type === 'csv' ? '.csv, application/vnd.ms-excel' : '.json'"
                                    maxFileSize="20000000" [customUpload]="true" (uploadHandler)="myUploader($event)"
                                    [auto]="true"
                                    [chooseLabel]="type === 'csv' ? 'Upload CSV/XLS' : 'Upload JSON'"></p-fileUpload>
                                <div *ngIf="type === 'xml'" class="d-flex mr-3">
                                    <div class="mr-2">
                                        <p-fileUpload *ngIf="xmlFiles.length === 0" #xmlUpload mode="basic" name="xml[]"
                                            [ariaLabel]="'please upload xml file'" accept=".xml" maxFileSize="20000000"
                                            [customUpload]="true" (uploadHandler)="xmlUploader($event)" [auto]="true"
                                            chooseLabel="Upload XML"></p-fileUpload>
                                    </div>
                                    <p-fileUpload *ngIf="xsdFiles.length === 0" #xsdUpload mode="basic" name="xsd[]"
                                        accept=".xsd" maxFileSize="20000000" [customUpload]="true"
                                        [ariaLabel]="'please upload xsd file'" (uploadHandler)="xsdUploader($event)"
                                        [auto]="true" chooseLabel="Upload XSD"></p-fileUpload>
                                </div>
                            </div>
                            <ul *ngIf="uploadedFiles.length ||  xmlFiles.length || xsdFiles.length"
                                style="padding-left: 0px;">
                                <li *ngFor="let file of uploadedFiles" style="list-style: none;
                                word-break: break-all;">{{file.name}} -
                                    {{file.size}}
                                    {{ 'setupjob.libytes' | translate }} <a href="javascript:void(0)"
                                        [ariaLabel]="'clear your selected file'" (click)="clearFile(file)"><i
                                            class="feather ft-x cursor-pointer ml-1"></i></a>
                                </li>
                                <li *ngFor="let f of xmlFiles" style="list-style: none;">{{f.name}} - {{f.size}}
                                    {{ 'setupjob.libytes' | translate }} <a href="javascript:void(0)"
                                        [ariaLabel]="'clear your selected file'" (click)="clearXMLFile(f)"><i
                                            class="feather ft-x cursor-pointer ml-1"></i></a>
                                </li>
                                <li *ngFor="let file of xsdFiles" style="list-style: none;">{{file.name}} -
                                    {{file.size}} {{ 'setupjob.libytes' | translate }}
                                    <a href="javascript:void(0)" [ariaLabel]="'clear your selected file'"
                                        (click)="clearXSDFile(file)"><i
                                            class="feather ft-x cursor-pointer ml-1"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="orm-group page-content row mx-auto" *ngIf="setupJobForm.valid">
                <h2 class="col-md-3"> </h2>
                <div class="col-lg-8 col-md-7 setup-info">
                    <div class="row">
                        <div class="col-lg-6"></div>
                        <div class="col-lg-6 text-right mt-2">
                            <button class="btn btn-secondary"
                                [disabled]="!setupJobForm.valid">Confirm</button>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </form>
</div>

<div class="text-right first-step-button space-20 mb-5 first-step-button row" *ngIf="isButtonDisabled">
    <h2 class="col-md-3"> </h2>
    <div class="col-lg-9 col-md-9 setup-info">
        <div class="row">
            <div class="col-lg-6"></div>
            <div class="col-lg-6 text-right mt-2">
                <!-- <button class="btn btn-primary" (click)="submit()">Submit</button> -->
                <button type="button" class="btn btn-primary" (click)="submit()">
                    <span class="spinner" *ngIf="showLoading"></span>
                    <span>Submit</span>
                </button>
            </div>
        </div>
    </div>
</div>

<p-dialog [(visible)]="displayDBModal" [resizable]="false" [draggable]="false" [modal]="true" [blockScroll]="true"
    (onHide)="close()" [style]="{width: '45vw'}">
    <div class="p-dialog-titlebar">
        <button class="p-dialog-titlebar-icon p-dialog-titlebar-close" autofocus="true" aria-label="Close Dialog"
            (click)="displayDBModal=false">
            <span class="pi pi-times"></span>
        </button>
    </div>
    <div *ngIf="projectId != ''">
        <app-configure-db [projectId]="projectId" [clear]="clearDBForm" [dbDetails]="dbDetails"
            (dbConfigurationsEmitter)="savedDBConnections($event)"></app-configure-db>
    </div>
</p-dialog>