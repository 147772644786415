import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BrewChatService {
  private NodeAPIUrl = environment.apiAuthUrl;

  constructor(
    private http: HttpClient
  ) { }

  postQuery(body: FormData) {
    return this.http.post<any>(`${this.NodeAPIUrl}/brew-chats`, body)
  }

  getQueries(type) {
    return this.http.get<any>(`${this.NodeAPIUrl}/brew-chats/${type}`)
  }

  clearSession(threadId) {
    return this.http.post<any>(`${this.NodeAPIUrl}/brew-chats/clear-sessions/${threadId}`, null)
  }

  ingestData(threadId, jobId) {
    return this.http.post<any>(`${this.NodeAPIUrl}/brew-chats/ingest-data/${threadId}/${jobId}`, null)
  }

  delThread(threadId) {
    return this.http.delete<any>(`${this.NodeAPIUrl}/brew-chats/${threadId}`);
  }

  getTheardInfo(threadId) {
    return this.http.get<any>(`${this.NodeAPIUrl}/chats/${threadId}`)
  }
}
