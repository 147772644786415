import { NgModule } from "@angular/core";
import { CommonModule, CurrencyPipe, DatePipe, UpperCasePipe } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
} from "ngx-perfect-scrollbar";
import { ButtonModule } from "primeng/button";
import { CardModule } from "../content/partials/general/card/card.module";
import { ArchwizardModule } from "angular-archwizard";
import {
  NgbDatepickerModule,
  NgbDropdownModule,
} from "@ng-bootstrap/ng-bootstrap";
import { BreadcrumbModule } from "src/app/_layout/breadcrumb/breadcrumb.module";
import { MatchHeightModule } from "../content/partials/general/match-height/match-height.module";
import { AddJobComponent } from "./components/add-job/add-job.component";
import { BlockUIModule } from "ng-block-ui";
import { BlockTemplateComponent } from "../_layout/blockui/block-template.component";
import { DialogModule } from "primeng/dialog";
import { TooltipModule } from "primeng/tooltip";
import { DropdownModule } from "primeng/dropdown";
import { ConfigureDbComponent } from "./components/configure-db/configure-db.component";
import { SetupJobComponent } from "./components/setup-job/setup-job.component";
import { TreeTableModule } from "primeng/treetable";
import { SemanticsConstraintsComponent } from "./components/semantics-constraints/semantics-constraints.component";
import { ToastModule } from "primeng/toast";
import { ConfirmSynthesisDataComponent } from "./components/confirm-synthesis-data/confirm-synthesis-data.component";
import { OccurenceComponent } from "./components/occurence/occurence.component";
import { TableModule } from "primeng/table";
import { NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { MultiSelectModule } from "primeng/multiselect";
import { AddProjectComponent } from "./components/add-project/add-project.component";
import { SkeletonModule } from "primeng/skeleton";
import { AssignSemanticsComponent } from "./components/assign-semantics/assign-semantics.component";
import { ConfirmJobComponent } from "./components/confirm-job/confirm-job.component";
import { AccordionModule } from "primeng/accordion";
import { CheckboxModule } from "primeng/checkbox";
import { PasswordModule } from "primeng/password";
import { FormBuilderComponent } from "./components/form/form-builder/form-builder.component";
import { ButtonComponent } from "./components/form/field-types/button/button.component";
import { DropdownComponent } from "./components/form/field-types/dropdown/dropdown.component";
import { InputComponent } from "./components/form/field-types/input/input.component";
import { TextAreaComponent } from "./components/form/field-types/text-area/text-area.component";
import { CustomSynthesisPatternComponent } from "./components/custom-synthesis-pattern/custom-synthesis-pattern.component";
import { RadioComponent } from "./components/form/field-types/radio/radio.component";
import { RadioButtonModule } from "primeng/radiobutton";
import { ManageMemberComponent } from "./components/roles/manage-member/manage-member.component";
import { JoinRequestComponent } from "./components/roles/join-request/join-request.component";
import { RoleManagementComponent } from "./components/roles/role-management/role-management.component";
import { TabMenuModule } from "primeng/tabmenu";
import { RangeFieldComponent } from "./components/form/field-types/range-field/range-field.component";
import { StrategiesHandlerComponent } from "./components/strategies-handler/strategies-handler.component";
import { AddPatternComponent } from "./components/add-pattern/add-pattern.component";
import { DependentFieldComponent } from "./components/form/field-types/dependent-field/dependent-field.component";
import { AddSemanticDependentComponent } from "./components/add-semantic-dependent/add-semantic-dependent.component";
import { LoadingSpinnerComponent } from "./components/loading-spinner/loading-spinner.component";
import { NumberComponent } from "./components/form/field-types/number/number.component";
import { InputNumberModule } from "primeng/inputnumber";
import { CheckboxComponent } from "./components/form/field-types/checkbox/checkbox.component";
import { UpdateOrgComponent } from "./components/update-org/update-org.component";
import { EditProfileComponent } from "./components/edit-profile/edit-profile.component";
import { AddOrgMemberComponent } from "./components/add-org-member/add-org-member.component";
import { ReportsComponent } from "./components/reports/reports.component";
import { RegexFieldComponent } from "./components/form/field-types/regex-field/regex-field.component";
import { ChartModule } from "primeng/chart";
import { ClipboardModule } from "ngx-clipboard";
import { RuleHandlerComponent } from "./components/rule-handler/rule-handler.component";
import { DataViewModule } from "primeng/dataview";
import { KeyPipe } from "src/app/_pipes/key.pipe";
import { RuleTestComponent } from "./components/rule-test/rule-test.component";
import { FileUploadModule } from "primeng/fileupload";
import { FileTestComponent } from "./components/file-test/file-test.component";
import { KeysPipe } from "src/app/_pipes/keys.pipe";
import { CheckBiasComponent } from "./components/check-bias/check-bias.component";
import { AddBiasComponent } from "./components/add-bias/add-bias.component";
import { AutoCompleteModule } from "primeng/autocomplete";
import { TranslateModule } from "@ngx-translate/core";
import { LanguageService } from "src/app/_services/language.service";
import { TruncatePipe } from "src/app/_pipes/truncate.pipe";
import { TabViewModule } from "primeng/tabview";
import { PiiPhiReportComponent } from "./components/pii-phi-report/pii-phi-report.component";
import { AddRuleComponent } from "./components/add-rule/add-rule.component";
import { LiveChatComponent } from './components/live-chat/live-chat.component';
import { RouteStateService } from "../_services/route-state.service";
import { RuleEditorComponent } from "./components/rule-editor/rule-editor.component";
import { AnomalyComponent } from './components/anomaly/anomaly.component';
import { SidebarModule } from 'primeng/sidebar';
import { AutomlHandlerComponent } from './components/automl-handler/automl-handler.component';
import { ConfigureLlmComponent } from './components/configure-llm/configure-llm.component';
import { SetupParametersLlmComponent } from './components/setup-parameters-llm/setup-parameters-llm.component';
import { OrgModelSetupComponent } from './components/org-model-setup/org-model-setup.component';
import { OrgNewModelComponent } from './components/org-new-model/org-new-model.component';
import { InvoiceTemplateComponent } from "./components/invoice-template/invoice-template.component";
import { SubscriptionBillingComponent } from './components/subscription-billing/subscription-billing.component';
import { MessagesModule } from 'primeng/messages';
import { DataCleansingComponent } from './components/data-cleansing/data-cleansing.component';
import { LlmChatComponent } from './components/llm-chat/llm-chat.component';
import { BotChatComponent } from './components/bot-chat/bot-chat.component';
import { AnalytixPlotsComponent } from './components/analytix-plots/analytix-plots.component';
import { FieldsetModule } from 'primeng/fieldset';
import { PanelModule } from 'primeng/panel';
import { MapsComponent } from './components/maps/maps.component';
import { InventoryComponent } from './components/inventory/inventory.component';
import { FinancialComponent } from './components/financial/financial.component';
import { ProductComponent } from './components/product/product.component';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SliderModule } from 'primeng/slider';
import { CfoChatsComponent } from './components/cfo-chats/cfo-chats.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { AddAgentComponent } from './components/add-agent/add-agent.component';
import { AddConfigureRagToolComponent } from './components/add-configure-rag-tool/add-configure-rag-tool.component';
import { AddConfigureApiToolComponent } from './components/add-configure-api-tool/add-configure-api-tool.component';
import { AddConfigureImageRagToolComponent } from './components/add-configure-image-rag-tool/add-configure-image-rag-tool.component';
import { AgentsTestComponent } from './components/agents-test/agents-test.component';
import { AgentHubComponent } from './components/agent-hub/agent-hub.component';
import { AccountComponent } from './components/account/account.component';
import { AccountPayablesComponent } from './components/account-payables/account-payables.component';
import { BankReconComponent } from './components/bank-recon/bank-recon.component';
import { InterCompanyReconComponent } from './components/inter-company-recon/inter-company-recon.component';
import { LedgerReconComponent } from './components/ledger-recon/ledger-recon.component';
import { DataDrivenDetailsComponent } from './components/data-driven-details/data-driven-details.component';
import { CalendarModule } from 'primeng/calendar';
import { ForecastModelInfoComponent } from './components/forecast-model-info/forecast-model-info.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

@NgModule({
  imports: [
    CommonModule,
    PerfectScrollbarModule,
    FormsModule,
    CardModule,
    BreadcrumbModule,
    ArchwizardModule,
    NgbDatepickerModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatchHeightModule,
    ButtonModule,
    NgbTooltipModule,
    DialogModule,
    DropdownModule,
    TreeTableModule,
    ToastModule,
    TableModule,
    NgbDropdownModule,
    AccordionModule,
    DropdownModule,
    MultiSelectModule,
    SkeletonModule,
    PasswordModule,
    CheckboxModule,
    RadioButtonModule,
    TabMenuModule,
    InputNumberModule,
    AutoCompleteModule,
    ChartModule,
    ClipboardModule,
    DataViewModule,
    FileUploadModule,
    TranslateModule,
    TabViewModule,
    TooltipModule,
    SidebarModule,
    MessagesModule,
    FieldsetModule,
    InputTextareaModule,
    SliderModule,
    PanelModule,
    ProgressSpinnerModule,
    BlockUIModule.forRoot({
      template: BlockTemplateComponent,
    }),
    CalendarModule
  ],
  exports: [
    CommonModule,
    CardModule,
    ConfigureDbComponent,
    ArchwizardModule,
    PerfectScrollbarModule,
    FormsModule,
    BreadcrumbModule,
    NgbDatepickerModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatchHeightModule,
    ButtonModule,
    NgbTooltipModule,
    TooltipModule,
    TreeTableModule,
    AddJobComponent,
    AddProjectComponent,
    TableModule,
    DropdownModule,
    NgbDropdownModule,
    MultiSelectModule,
    SkeletonModule,
    AccordionModule,
    DialogModule,
    CheckboxModule,
    CustomSynthesisPatternComponent,
    PasswordModule,
    RadioButtonModule,
    ManageMemberComponent,
    JoinRequestComponent,
    RoleManagementComponent,
    TabMenuModule,
    InputNumberModule,
    AutoCompleteModule,
    UpdateOrgComponent,
    EditProfileComponent,
    AddOrgMemberComponent,
    ReportsComponent,
    ChartModule,
    ClipboardModule,
    DataViewModule,
    FileUploadModule,
    KeysPipe,
    TabViewModule,
    TruncatePipe,
    TranslateModule,
    LiveChatComponent,
    RuleEditorComponent,
    SidebarModule,
    ConfigureLlmComponent,
    SetupParametersLlmComponent,
    OrgModelSetupComponent,
    OrgNewModelComponent,
    InvoiceTemplateComponent,
    SubscriptionBillingComponent,
    MessagesModule,
    FieldsetModule,
    PanelModule,
    MapsComponent,
    InventoryComponent,
    FinancialComponent,
    ProductComponent,
    InputTextareaModule,
    SliderModule,
    ProgressSpinnerModule,
    AddAgentComponent,
    AddConfigureRagToolComponent,
    AddConfigureApiToolComponent,
    AddConfigureImageRagToolComponent,
    AgentsTestComponent,
    AgentHubComponent,
    AccountComponent,
    AccountPayablesComponent,
    BankReconComponent,
    InterCompanyReconComponent,
    LedgerReconComponent,
    DataDrivenDetailsComponent,
    CalendarModule,
    ForecastModelInfoComponent
  ],
  declarations: [
    KeyPipe,
    AddJobComponent,
    ConfigureDbComponent,
    SetupJobComponent,
    SemanticsConstraintsComponent,
    ConfirmSynthesisDataComponent,
    OccurenceComponent,
    AddProjectComponent,
    AssignSemanticsComponent,
    ConfirmJobComponent,
    FormBuilderComponent,
    ButtonComponent,
    DropdownComponent,
    InputComponent,
    TextAreaComponent,
    CustomSynthesisPatternComponent,
    RadioComponent,
    ManageMemberComponent,
    JoinRequestComponent,
    RoleManagementComponent,
    RangeFieldComponent,
    StrategiesHandlerComponent,
    AddPatternComponent,
    DependentFieldComponent,
    AddSemanticDependentComponent,
    LoadingSpinnerComponent,
    NumberComponent,
    CheckboxComponent,
    UpdateOrgComponent,
    EditProfileComponent,
    AddOrgMemberComponent,
    ReportsComponent,
    RegexFieldComponent,
    RuleHandlerComponent,
    RuleTestComponent,
    FileTestComponent,
    KeysPipe,
    CheckBiasComponent,
    AddBiasComponent,
    TruncatePipe,
    PiiPhiReportComponent,
    AddRuleComponent,
    LiveChatComponent,
    RuleEditorComponent,
    AnomalyComponent,
    AutomlHandlerComponent,
    ConfigureLlmComponent,
    SetupParametersLlmComponent,
    OrgModelSetupComponent,
    OrgNewModelComponent,
    InvoiceTemplateComponent,
    SubscriptionBillingComponent,
    DataCleansingComponent,
    LlmChatComponent,
    BotChatComponent,
    AnalytixPlotsComponent,
    MapsComponent,
    InventoryComponent,
    FinancialComponent,
    ProductComponent,
    CfoChatsComponent,
    AddAgentComponent,
    AddConfigureRagToolComponent,
    AddConfigureApiToolComponent,
    AddConfigureImageRagToolComponent,
    AgentsTestComponent,
    AgentHubComponent,
    AccountComponent,
    AccountPayablesComponent,
    BankReconComponent,
    InterCompanyReconComponent,
    LedgerReconComponent,
    DataDrivenDetailsComponent,
    ForecastModelInfoComponent
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    LanguageService,
    RouteStateService,
    DatePipe,
    CurrencyPipe,
    UpperCasePipe
  ],
})
export class SharedModule { }
