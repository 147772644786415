<div id="frame">
    <div class="content-chat m-0" *ngIf="mode == 'general'">
        <div class="contact-profile">
            <div class="social-media">
                <h2 class="chat-logo">Chat</h2>
            </div>
        </div>

        <div class="new-chat">
            <ng-container>
                <div class="dropdown-user " ngbDropdown title="User-action" #myDropdown="ngbDropdown">
                    <a class="nav-link dropdown-user-link dropdown-toggle" href="javascript:void(0)" ngbDropdownToggle
                        id="dropdownProfileMenu">
                        <span class="user-name">
                            Review Chat Log</span>
                    </a>
                    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right"
                        aria-labelledby="dropdownProfileMenu">
                        <ng-container *ngIf="!loadThreads">
                            <ng-container *ngFor="let thread of threads.reverse()">
                                <ng-container *ngIf="findObjectByThreadId(thread, 'single').trim() !== ''">
                                    <div class="dropdown-item d-flex justify-content-between align-items-center">
                                        <a class="text-truncate" style="max-width: 18vw;" href="javascript:void(0)"
                                            (click)="setUserActive(thread)">
                                            {{findObjectByThreadId(thread, 'single')}}
                                        </a>
                                        <i class="feather ft-trash-2" (click)="deleteThread($event, thread)"></i>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </ng-container>

                        <div class="d-flex justify-content-center align-items-center" style="height: 100px;"
                            *ngIf="loadThreads">
                            <i class="pi pi-spin pi-spinner" style="font-size: 1rem;"></i>
                        </div>
                    </div>
                </div>
                <div class="dropdown-user" *ngIf="routeType != '' || activeUser != ''">
                    <a class="nav-link dropdown-user-link a" href="javascript:void(0)">
                        <span class="user-name" (click)="newChat($event)">New Chat</span>
                    </a>
                </div>
            </ng-container>
        </div>


        <div class="chat-msg-block">
            <div class="chat-msg-btns" *ngIf="!loading && !this.activeThreadsByThreadId.length">
                <div class="chat-logo-block">
                    <div class="chat-logo">
                        <img src="assets/images/logo/logo.svg">
                    </div>
                    <p>
                        Ask anything related to data, documents and files</p>
                </div>
            </div>
            <ul>
                <li class="thread mx-0" [class]="msg.type" *ngFor="let msg of activeThreadsByThreadId">
                    <div *ngIf="msg?.question">
                        <div class="d-flex align-items-baseline">
                            <div class="pl-0 user-logo">{{userData['name'].charAt(0)}}</div>
                            <h3>{{msg?.question}}</h3>
                        </div>
                        <div class="d-flex1">
                            <div class="chat-msg">
                                {{msg?.answer ? msg?.answer : 'Something went wrong. Please retry'}}
                            </div>
                            <!-- <div *ngIf="msg['attachment'] !== undefined && typeof msg['attachment'] === 'string' && msg['attachment'].startsWith('i')"> -->

                            <img *ngIf="validateImg(msg['attachment'])"
                                [src]="'data:image/png;base64,' + msg['attachment']" alt="Attachment">

                            <!-- </div> -->

                        </div>
                    </div>
                </li>
                <li class="thread mx-0" *ngIf="loading">
                    <div class="d-flex align-items-baseline">
                        <div class="pl-0 user-logo">{{userData['name'].charAt(0)}}</div>
                        <h3>{{ latestQues }}</h3>
                    </div>
                </li>
                <li class="thread text-center" *ngIf="loading">
                    <p style="color: #0073ff;font-size:14px !important;">{{loadingMessage}}</p>
                    <div class="loader-wrapper">
                        <div class="loader-container">
                            <div class="ball-pulse loader-primary">
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <div class="" class="{{routeType == 'jobs' ? 'jobs-dropdown' : 'suggestion-btns'}}">
            <div class="btns-block">
                <ng-container *ngIf="!loading && !activeUser">
                    <div class="row" *ngIf="showContext">
                        <div class="col-6">
                            <button class="btn btn-chat" (click)="contextHandler('docs', $event)">
                                <span>0to60.ai Help</span>
                                <span>Request info from the documentation</span>
                            </button>
                        </div>
                        <div class="col-6">
                            <button class="btn btn-chat" (click)="contextHandler('jobs', $event)">
                                <span>Query Workflows</span>
                                <span>Fetch Information from Jobs</span>
                            </button>
                        </div>
                        <div class="col-6">
                            <button class="btn btn-chat" (click)="contextHandler('files', $event)">
                                <span>Query Knowledge Base</span>
                                <span>Ingest documents to extract info</span>
                            </button>
                        </div>
                        <div class="col-6">
                            <button class="btn btn-chat" (click)="contextHandler('csv', $event)">
                                <span>Query Data (Upload CSV)</span>
                                <span>Ingest csv to extract info</span>
                            </button>
                        </div>
                    </div>
                </ng-container>

                <!-- Need to implement -->
                <div class=" row"
                    *ngIf="(routeType == '' || routeType == 'general' || routeType == 'files') && !activeUser && !loading">
                    <div style="height: 170px; margin-right: 20px;" *ngIf="!showContext"></div>
                    <div class="col-6"></div>
                    <div class="col-6">
                        <span class="p-fluid chat-dropdown job-selection">
                            <p-dropdown styleClass="modelList" [filter]="true" [placeholder]="'Select the Model'"
                                filterBy="name" [options]="modelList" [(ngModel)]="llm" optionLabel="name"
                                appendTo="body" optionValue="value">
                                <ng-template let-option pTemplate="item">
                                    <div class="country-item">
                                        <div class="d-flex justify-content-between">
                                            {{option.name}}
                                        </div>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                        </span>
                    </div>
                </div>

                <div class="d-flex justify-content-around" *ngIf="activeUser == ''">
                    <span class="p-fluid chat-dropdown">
                        <p-dropdown *ngIf="routeType == 'jobs' && showJobsSelection" [filter]="true"
                            [placeholder]="'Select the Workspace'" [(ngModel)]="contextProjectId" filterBy="name"
                            [options]="workspaceList" (onChange)="fetchJobs($event)" optionLabel="name" appendTo="body"
                            optionValue="value">
                            <ng-template let-option pTemplate="item">
                                <div class="country-item">
                                    <div class="d-flex justify-content-between">
                                        {{option.name}}
                                    </div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </span>

                    <span class="p-fluid chat-dropdown">
                        <p-dropdown *ngIf="routeType == 'jobs' && showJobsSelection" [filter]="true"
                            [placeholder]="'Select the Job'" [(ngModel)]="jobId" filterBy="name" [options]="jobsList"
                            appendTo="body" (onChange)="jobSelection($event)" optionLabel="name" optionValue="value">
                            <ng-template let-option pTemplate="item">
                                <div class="country-item">
                                    <div class="d-flex justify-content-between">
                                        {{option.name}}
                                    </div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </span>
                </div>
            </div>
        </div>

        <div class="message-area">

            <div class="message-input-block">
                <div class="message-input-holder">
                    <ng-container *ngIf="routeType=='files' || routeType == 'csv'">
                        <input type="file" #fileInput (change)="fileHandler($event)" multiple="multiple"
                            style="display: none;" [accept]="accept">
                        <div class="pointer text-center upload-area">
                            <span>
                                <i *ngIf="!files.length && !activeUser && !loading" class="pi pi-plus-circle"
                                    (click)="fileInput.click()"></i>
                                <i *ngIf="files.length && !loading && hideIcon" class="pi pi-spin pi-spinner"></i>
                                <i *ngIf="activeUser || !hideIcon" class="pi pi-file"></i>
                            </span>
                        </div>
                    </ng-container>
                    <textarea [disabled]="disabledTextArea || showFileMessage"
                        placeholder="{{routeType=='files'? !files.length ? 'Upload documents in PDF or doc format' : 'Ask away!' : 'Questions on data, docs or 0to60.ai? Ask away!'}}"
                        #msgInput (keyup.enter)="addNewMessage(msgInput.value?.trim())"
                        (input)="adjustTextareaHeight(); onTyping($event); latestQues = $event.target.value.trim()"
                        [(ngModel)]="latestQues"></textarea>
                    <button class="submit" (click)="addNewMessage(msgInput.value?.trim())"
                        [disabled]="!latestQues.trim() && !disabledTextArea"
                        [ngClass]="{'btn-enable': latestQues && !disabledTextArea}">
                        <i class="fa fa-arrow-up" aria-hidden="true"></i>
                    </button>
                </div>
            </div>
            <div *ngIf="showFileMessage" class="d-flex justify-content-center text-danger">{{fileErrorMessage}}</div>
        </div>
    </div>

    <div class="content-chat m-0" *ngIf="mode == 'llm'">
        <div class="contact-profile">
            <div class="social-media">
                <h2 class="chat-logo">Chat</h2>
            </div>
        </div>
        <app-llm-chat [llmData]="llmData"></app-llm-chat>
    </div>

    <div class="content-chat m-0" *ngIf="mode == 'chatbot'">
        <div class="contact-profile">
            <div class="social-media">
                <h2 class="chat-logo">Chat</h2>
            </div>
        </div>
        <app-bot-chat [chatbotData]="chatbotData"></app-bot-chat>
    </div>

    <div class="content-chat m-0" *ngIf="mode == 'analytix'">
        <div class="contact-profile">
            <div class="social-media">
                <h2 class="chat-logo">Chat</h2>
            </div>
        </div>
        <!-- <app-bot-chat [chatbotData]="chatbotData"></app-bot-chat> -->
        <app-analytix-plots [analytixData]="analytixData"></app-analytix-plots>
    </div>

    <div class="content-chat m-0" *ngIf="mode == 'cfo-chat'">
        <div class="contact-profile">
            <div class="social-media">
                <h2 class="chat-logo">Chat</h2>
            </div>
        </div>
        <app-cfo-chats [cfoDetails]="cfoDetails"></app-cfo-chats>
    </div>
</div>