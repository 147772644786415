import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class DocumentationSlideService {
  public toggleCustomizerSource = new Subject<string>();

  constructor(private http: HttpClient) {
  }

  customDocumentName$ = this.toggleCustomizerSource.asObservable();

  customDocumentName(value: any) {
    this.toggleCustomizerSource.next(value);
  }

  getDocumentContent() {
    return this.http.get('assets/i18n/en.json');
  }

  getDocumentNameInStorage() {
    let viewObj = JSON.parse(localStorage.getItem('documentName'));
    if (viewObj == undefined) {
      return undefined;
    } else {
      return viewObj.documentName;
    }
  }

  setDocumentNameInStorage(docName: any) {
    localStorage.setItem('documentName', JSON.stringify({
      documentName: ((docName == null) ? "empty" : docName)
    }));
  }
}