import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-add-org-member',
  templateUrl: './add-org-member.component.html'
})
export class AddOrgMemberComponent implements OnInit {
  inviteForm: FormGroup;
  constructor() {
    this.inviteForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      phone: new FormControl('', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")])
    });
  }

  ngOnInit(): void {
  }
  sendInvitation(): void {

  }

}
