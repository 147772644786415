<div class="confirm-synthesis-data">
    <div class="d-flex justify-content-between mb-2">
        <h1>{{ 'filetest.header2title' | translate }}</h1><!-- Kiran -->
        <div class="pop-action d-flex align-items-center">
            <span>{{ 'filetest.span1text' | translate }}</span>
            <span class="before-after"><label class="toggle">
                    <input class="toggle-input" type="checkbox" checked [(ngModel)]="showBefore">
                    <div class="toggle-track">
                        <div class="toggle-control"></div>
                    </div>
                </label>
            </span>
            <span>{{ 'filetest.span2text' | translate }}</span>
        </div>
    </div>
    <div *ngIf="type == 'json'">
        <pre *ngIf="!showBefore && data[0]">{{ data[0] | json }}</pre>
        <pre *ngIf="showBefore && data[1]">{{ data[1] | json }}</pre>
    </div>
    <div *ngIf="type == 'xml'">
        <pre *ngIf="!showBefore && data[0]">{{ data[0] }}</pre>
        <pre *ngIf="showBefore && data[1]">{{ data[1] }}</pre>
    </div>
    <div *ngIf="type == 'csv'" class="test-table">
        <!-- Display 'before' data table -->
        <p-table [value]="dataBefore" *ngIf="!showBefore">
            <ng-template pTemplate="header">
                <tr>
                    <th style="min-width: 300px;" *ngFor="let key of keysBefore" scope="col">{{ key }}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
                <tr>
                    <td style="min-width: 300px;" [ngStyle]="{ 'height': '100px' }" *ngFor="let key of keysBefore">
                        <div class="bigdata">{{ rowData[key] }}</div>
                    </td>
                </tr>
            </ng-template>
        </p-table>

        <!-- Display 'after' data table -->
        <p-table [value]="dataAfter" *ngIf="showBefore">
            <ng-template pTemplate="header">
                <tr>
                    <th style="min-width: 300px;" *ngFor="let key of keysAfter" scope="col">{{ key }}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
                <tr>
                    <td style="min-width: 300px;" [ngStyle]="{ 'height': '100px' }" *ngFor="let key of keysAfter">
                        <div class="bigdata">{{ rowData[key] }}</div>
                    </td>
                </tr>
            </ng-template>
        </p-table>

    </div>
</div>