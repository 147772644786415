import { Component, Input, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Toast } from 'src/app/_helpers/toast';
import { AuthService } from 'src/app/_services/auth.service';
import { FinancialAnalytixService } from 'src/app/_services/financial-analytix.service';
import { OrgService } from 'src/app/_services/org.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-cfo-chats',
  templateUrl: './cfo-chats.component.html'
})
export class CfoChatsComponent {
  @Input() cfoDetails;
  jobId;
  userData: Object = {};
  orgData: Object = {};
  chatMessages = [];
  loading: boolean = false;
  question: string = ''
  latestQues: string = ''

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private orgService: OrgService,
    private sanitizer: DomSanitizer,
    private financialService: FinancialAnalytixService
  ) {
   
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.cfoDetails) {
      this.jobId = this.cfoDetails['jobId']
    }
  }

  ngOnInit(): void {
    this.fetchLLMs();
    this.getprofile();
  }

  goback() {
    window.history.back();
  }

  getprofile() {
    this.userService.getUserProfile().subscribe({
      next: (res) => {
        if (res) {
          this.userData = res;
          this.orgService.getOrgById(res.orgId).subscribe({
            next: r => {
              this.orgData = r
            },
            error: err => {
              Toast.fire({
                icon: 'error',
                html: err.error.error.message
              });
            }
          })
        }
      },
      error: err => {
        Toast.fire({
          icon: 'error',
          html: err.error.error.message
        });
      }
    })
  }

  validateImg(data) {
    if (typeof data == 'string' && data.startsWith('i')) {
      return true
    } else {
      return false
    }
  }

  fetchLLMs() {
    const currentUser = this.authService.currentUserValue;
    
  }

  postQuery() {
    this.loading = true;
    const currentUser = this.authService.currentUserValue;
    this.latestQues = this.question;
    setTimeout(()=>{
      this.question = ''
    }, 1000)
    this.financialService.fetchChatResponse({job_id: this.jobId, query: this.question, user_id: currentUser.id, chat_session_id: currentUser.orgId}).subscribe({
      next: res => {
        console.log('che res', res)
        this.chatMessages.push({
          question: this.latestQues,
          answer: res['answer']['text_response'],
          plot:  res['answer']['plot_html'] && res['answer']['plot_html'] != 'null' ? this.sanitizer.bypassSecurityTrustHtml(res['answer']['plot_html']) : null
        })
        this.loading = false;
      }, 
      error: err => {
        this.loading = false;
      }
    })
  }

}
