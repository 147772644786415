import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-confirm-synthesis-data',
  templateUrl: './confirm-synthesis-data.component.html'
})
export class ConfirmSynthesisDataComponent implements OnChanges {

  @Input() testData: Object;
  @Input() selectedColumn;
  @Input() positionRange;
  @Output() closeConfirm = new EventEmitter<boolean>();
  @Input() top: boolean = false;
  @Input() tableTestData: Object = {};
  showAfter: boolean = false;

  constructor(
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('pop up test data', this.testData)
  }

  ngOnInit(): void {

  }

  closeModal() {
    this.closeConfirm.emit(true)
  }
  
  breakDown(data) {
    let arr = [];
    Array.from(data).forEach(r => arr.push(r));
    return arr
  }


}
