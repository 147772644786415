<div class="form-builder">
    <div class="row">
        <div class="form-group">
            <label>{{ 'Name' }}<span class="mendatory">*</span></label>
            <input [ngClass]="'form-control'" [(ngModel)]="toolName" placeholder="{{'Enter here'}}">
        </div>

        <div class="form-group">
            <label>{{ 'Description' }}<span class="mendatory">*</span></label>
            <input [ngClass]="'form-control'" [(ngModel)]="toolDescription" placeholder="{{ 'Enter here'}}">
        </div>

        <div class="form-group">
            <label>{{ 'Upload File' }}<span class="mendatory">*</span></label>
            <p-fileUpload *ngIf="!ragFiles.length" mode="basic" name="demo[]"
                [ariaLabel]="'please upload pdf file'" accept=".pdf" maxFileSize="20000000"
                [customUpload]="true" (uploadHandler)="myUploader($event)" [auto]="true" [chooseLabel]="'Upload File'">
            </p-fileUpload>
            <ul *ngIf="ragFiles.length" style="padding-left: 0px;">
                <li *ngFor="let file of ragFiles" style="list-style: none;word-break: break-all;">
                    {{file.name}} -
                    {{file.size}}
                    {{ 'setupjob.libytes' | translate }} <a href="javascript:void(0)"
                        [ariaLabel]="'clear your selected file'" (click)="clearFile(file)"><i
                            class="feather ft-x cursor-pointer ml-1"></i></a>
                </li>

            </ul>
        </div>

        <div class="text-end">
            <button class="btn btn-primary mt-2 text-end" 
            [disabled]="!(toolName && toolDescription && ragFiles.length)"
            (click)="saveRagTool()">Save</button>
        </div>
    </div>
</div>