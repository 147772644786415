import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'src/app/_helpers/custom-validators';
import { NavigationStart, Router } from "@angular/router";


@Component({
  selector: 'app-form-builder',
  templateUrl: './form-builder.component.html'
})
export class FormBuilderComponent implements OnInit, OnChanges {


  form !: FormGroup;
  @Input() formSchema: any[] = [];
  @Output() onSubmit = new EventEmitter();
  @Output() onTest = new EventEmitter();
  @Output() onClear = new EventEmitter();
  @Input() typeClass
  @Output() popup = new EventEmitter<any>()
  @Input() assignStyle: boolean = false;
  clickedButton: string = '';
  dependentField: Object[] = []
  @Input() setFocus: boolean = false;
  @Input() setFocusTestButton: boolean = false;
  @Output() dependentFieldEvent = new EventEmitter<boolean>();
  clearDependentField: boolean = false;
  @Input() layout: string = '';
  @Input() variableLayout: any;
  @Input() layoutStsHandler: any;
  Fieldlengt: string = '';

  constructor(
    private router: Router,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['formSchema'] && this.formSchema && this.formSchema.length) {
      this.validatesField();
    }
  }

  ngOnInit(): void {
    this.clearDependentField = false;
  }



  validatesField() {
    let fieldControls: any = {};
    if (this.formSchema.length > 0) {
      for (let field of this.formSchema) {
        let Valids = []
        for (let props in field['validations']) {
          Valids = []
          switch (props) {
            case 'required':
              Valids.push(Validators.required);
              break;
            case 'email':
              Valids.push(Validators.email);
              break;
            case 'maxLength':
              Valids.push(Validators.maxLength(field['validations'][props]));
              break;
            case 'minLength':
              Valids.push(Validators.minLength(field['validations'][props]));
              break;
            default:
              Valids = null
          }
        }
        if (field['type'] == 'range') {
          fieldControls[field['lowerName']] = new FormControl(Valids)
          fieldControls[field['upperName']] = new FormControl(Valids)
        } else {
          fieldControls[field['name']] = new FormControl(null, Valids)
        }
      }
      this.form = new FormGroup(fieldControls);
    }
  }

  buttonStatus(status) {
    status ? this.clickedButton = status['name'] : this.clickedButton = '';
  }

  submitEmitter() {
    if (this.clickedButton === 'Save') {
      let data = CustomValidators.removeNullFromObject(this.form.value)
      if (data['assignSemantics']) {
        data['dependentField'] = this.dependentField
      }

      this.onSubmit.emit({ form: this.form, value: data })
      this.dependentField = [];
      if (data['strategyName']) {
        this.form.reset();
      }
    } else if (this.clickedButton === 'Test') {
      let data = CustomValidators.removeNullFromObject(this.form.value)
      data['dependentField'] = this.dependentField
      // Based on clicked button status logic need to be implemented here
      this.onTest.emit({ value: data })
    } else if (this.clickedButton === 'Clear') {
      this.form.reset();
      this.dependentField = []
      this.clearDependentField = true;
      this.onClear.emit(true)
      setInterval(() => {
        this.clearDependentField = false
      }, 5000)
    }
  }

  popupEvent(data) {
    this.popup.emit(data)
  }

  dFHandler(event) {
    this.dependentField = event.map(obj => ({ ...obj }));
    this.dependentFieldEvent.emit(true);
  }
}
