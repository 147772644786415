<div class="add-pattern">
    <div class="row">
        <div class="col-md-12 text-center add-ptn">
            <h2 class="popup-title">{{ 'addpatternform.header2popuptitle' | translate }}</h2>
        </div>
        <div class="field_length col-md-12" *ngIf="columnData.length > 0">
            <h3>{{ 'addpatternform.fieldlength' | translate }}{{ columnData[0]['columnlet_type']}}</h3>
        </div>
        <div class="col-md-12">
            <form [formGroup]="patternForm"
                aria-label="'Add pattern form All required input feilds are denoted by star'">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="label-control pattern-name">
                                {{ 'inputforms.labels.semanticgroup' | translate }}
                                <span class="mendatory">*</span>
                            </label>
                            <p-dropdown [filter]="true" styleClass="allDropdown" [options]="enUS" optionLabel="name"
                                optionValue="value" formControlName="semantic_group" filterBy="name"
                                [placeholder]="('inputforms.placeholder.semanticgroup' | translate) ? ('inputforms.placeholder.semanticgroup' | translate) : ' '"></p-dropdown>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label class=" label-control pattern-name">
                                {{ 'inputforms.labels.patternname' | translate }}
                                <span class="mendatory">*</span>
                            </label>
                            <input type="text" class="form-control" formControlName="pattern_name"
                                placeholder="Enter pattern name">
                        </div>
                    </div>
                    <div class="col-md-12 text-right">
                        <button uiSref="address" type="button" class=" btn btn-raised btn-warning  btn-small mb-3"
                            (click)="savePattern()" [disabled]="!isFormValid()">
                            {{ 'buttontitle.savepatternbutton' | translate }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <p-tabView>
        <p-tabPanel *ngFor="let item of columnData[0]['columnlets_data']; index as i" [selected]="i == 0">
            <ng-template pTemplate="header">{{ "columnlet " + (i+1)}}</ng-template>
            <ng-template pTemplate="content">
                <div class="row">
                    <div class="col-md-4" *ngIf="item['columnlet_name']">
                        <h5 class="label-title">{{ 'COLUMNLET NAME' }}</h5>
                        <p class="mb-2">{{format_heading(item['columnlet_name'])}}</p>
                    </div>
                    <div class="col-md-4" *ngIf="item['semantic_group']">
                        <h5 class="label-title">{{ 'SEMANTIC GROUP' }}</h5>
                        <p class="mb-2">{{format_heading(item['semantic_group'])}}</p>
                    </div>
                    <div class="col-md-4" *ngIf="item['position_range_start']">
                        <h5 class="label-title">{{ 'POSITION RANGE START' }}</h5>
                        <p class="mb-2">{{format_heading(item['position_range_start'])}}</p>
                    </div>
                    <div class="col-md-4" *ngIf="item['position_range_end']">
                        <h5 class="label-title">{{ 'POSITION RANGE END' }}</h5>
                        <p class="mb-2">{{format_heading(item['position_range_end'])}}</p>
                    </div>
                    <div class="col-md-4" *ngIf="item['variable_position']">
                        <h5 class="label-title">{{ 'VARIABLE POSITION' }}</h5>
                        <p class="mb-2">{{format_heading(item['variable_position'])}}</p>
                    </div>
                    <div class="col-md-4" *ngIf="item['dependent_fields'].length > 0">
                        <h5 class="label-title">{{ 'DEPENDENT FIELDS' }}</h5>
                        <ul>
                            <li *ngFor="let field of item.dependent_fields">
                                <p>Column Name: {{ field.column_name }}</p>
                                <p>Type: {{ field.type }}</p>
                                <p *ngIf="field.type == 'expression'">Expression: {{ field.expression[0] }}</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </ng-template>
        </p-tabPanel>
    </p-tabView>
</div>