<h1 class="user-title text-center" *ngIf="!addbias">Check for Bias</h1>
<div class="design-modal modal-body check-bias-popup">
    <form [formGroup]="checkBiasForm" aria-label="'profile Form All required input feilds are denoted by star'"
        *ngIf="!addbias">
        <div class="row">
            <div class="col-md-12">
                <div class="form-group border-0">
                    <label> Decision Attribute</label>
                    <div class="d-flex">
                        <p-dropdown formControlName="decisionAttribute" [options]="decisionAttrsOptions" appendTo="body"
                            optionLabel="name" optionValue="value" placeholder="Select" class="w-100 mr-1">
                        </p-dropdown>
                        <button id="btnUpdateProfil" type="button"
                            class="btn btn-raised btn-warning btn-small flex-shrink-1" (click)="fetchBiasAnalysis()"
                            [disabled]="checkBiasForm.invalid || loading">
                            <span class="spinner" *ngIf="loading"></span>
                            <span class="btn-text" *ngIf="!loading">Check</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="row" *ngIf="loading">
        <div class="col-md-12">
            <div class="loader-wrapper spinners">
                <div class="loader-container">
                    <div class="ball-grid-pulse loader-primary">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="showCharts && !addbias">
        <div class="row">
            <div class="col-md-12">
                <iframe title="Charts" [srcdoc]="trustedHtml"></iframe>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <p-table [value]="tableData">
                    <ng-template pTemplate="header">
                        <tr>
                            <!-- Loop through the columnNames array to generate headers -->
                            <th id="columnName" *ngFor="let columnName of tableHeaders">{{ columnName }}</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-item>
                        <tr>
                            <ng-container *ngFor="let columnName of tableHeaders">
                                <td *ngIf="columnName !== 'Desirable Value'">{{ item[columnName] }}</td>
                                <td *ngIf="columnName === 'Desirable Value'">
                                    <ng-container *ngIf="columnName === 'Desirable Value'">
                                        <form [formGroup]="desirableForm" class="d-flex check-bias-btns">
                                            <p-dropdown [options]="desirableValueOptions" optionLabel="name"
                                                appendTo="body" optionValue="value" placeholder="Select Desirable Value"
                                                formControlName="desirable" [disabled]="item.disabled"
                                                class="p-drop"></p-dropdown>
                                            <div *ngIf="!item.disabled">
                                                <button [disabled]="item.disabled" uiSref="address" type="button"
                                                    (click)="saveDV(item)" class="btn btn-secondary" *ngIf="!showClear">
                                                    <em class="pi pi-check"></em>
                                                </button>
                                                <button [disabled]="item.disabled" uiSref="address" type="button"
                                                    (click)="clearDV(item)" class="btn btn-secondary" *ngIf="showClear">
                                                    <em class="pi pi-times-circle"></em>
                                                </button>
                                            </div>
                                        </form>
                                    </ng-container>
                                </td>
                            </ng-container>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
            <div class="col-md-12 text-right mt-2" *ngIf="showSave">
                <button id="btnUpdateProfil" type="button" class="btn btn-raised btn-warning btn-small"
                    (click)="saveBiasConfig()">
                    <span class="btn-text">Save</span>
                </button>
            </div>
        </div>
    </div>
</div>