<div class="app-content ">
    <div class="content-wrapper">
        <div class="container-xxl mt-2">
            <div class="page-main project-job-list job-list-page pages">

                <p-tabView (onChange)="onTabChange($event)" [(activeIndex)]="index">
                    <!-- P & L Dashboard Overview -->
                    <p-tabPanel>
                        <ng-template pTemplate="header">
                            <span>P & L Dashboard Overview</span>
                            <i class="pi pi-refresh ml-1"></i>
                        </ng-template>

                        <div class="analytix-box">
                            <div class="d-flex justify-content-between align-items-center">
                                <h4>P & L Dashboard Overview</h4>
                                <i class="feather ft-message-square msg-icon"
                                    (click)="invokeChatSliding('cfo-chat')"></i>
                            </div>
                            <div class="dashboard-info d-flex">
                                <div class="dashboard-filter">
                                    <label for="" class="label-control">Year</label>
                                    <span class="p-fluid">
                                        <p-dropdown (onChange)="changeInFilter($event, 'year')" [(ngModel)]="year"
                                            styleClass="allDropdown" appendTo="body" filterBy="name"
                                            [placeholder]="'Select Year'" [options]="yearOptions" optionLabel="name"
                                            optionValue="value">
                                        </p-dropdown>
                                    </span>
                                </div>
                            </div>

                            <div class="loader-wrapper circular-spinners text-center"
                                *ngIf="!pnlOverviewDetails || !pnlOverviewDetails['cards']; else dashboard">
                                <i class="pi pi-spin pi-spinner"></i>
                            </div>

                            <ng-template #dashboard>
                                <div class="dashboard-info d-flex">
                                    <div class="dashboard-card"
                                        *ngIf="pnlOverviewDetails && pnlOverviewDetails['cards'] && pnlOverviewDetails['cards']['Revenue']">
                                        <h5>Revenue</h5>
                                        <p>{{pnlOverviewDetails['cards']['Revenue'] | currency: jobDetails[0]['locale']+
                                            ' ' :'symbol':'1.0-0'}}</p>
                                    </div>
                                    <div class="dashboard-card"
                                        *ngIf="pnlOverviewDetails && pnlOverviewDetails['cards'] && pnlOverviewDetails['cards']['COGS']">
                                        <h5>COGS</h5>
                                        <p>{{pnlOverviewDetails['cards']['COGS'] | currency: jobDetails[0]['locale']+ '
                                            ' :'symbol':'1.0-0'}}</p>
                                    </div>
                                    <div class="dashboard-card"
                                        *ngIf="pnlOverviewDetails && pnlOverviewDetails['cards'] && pnlOverviewDetails['cards']['Gross Profit']">
                                        <h5>Gross Profit</h5>
                                        <p>{{pnlOverviewDetails['cards']['Gross Profit'] | currency:
                                            jobDetails[0]['locale']+ ' ' :'symbol':'1.0-0'}}</p>
                                    </div>
                                    <div class="dashboard-card"
                                        *ngIf="pnlOverviewDetails && pnlOverviewDetails['cards'] && pnlOverviewDetails['cards']['Expenses']">
                                        <h5>Expenses</h5>
                                        <p>{{pnlOverviewDetails['cards']['Expenses'] | currency:
                                            jobDetails[0]['locale']+ ' ' :'symbol':'1.0-0'}}</p>
                                    </div>
                                    <div class="dashboard-card"
                                        *ngIf="pnlOverviewDetails && pnlOverviewDetails['cards'] && pnlOverviewDetails['cards']['Net Profit']">
                                        <h5>Net Profit</h5>
                                        <p>{{pnlOverviewDetails['cards']['Net Profit']| currency:
                                            jobDetails[0]['locale']+ ' ' :'symbol':'1.0-0'}}</p>
                                    </div>
                                </div>

                                <div *ngIf="pnlOverviewChartDetails.length">
                                    <div class="chart-area mt-2" *ngFor="let chart of pnlOverviewChartDetails">
                                        <!-- <h5>{{ chart['title']}}</h5>
                                        <em *ngIf="chart['preType'] != 'table'" class="pi pi-times"
                                            (click)="getInsights(chart)"></em> -->

                                        <div class="d-flex justify-content-between align-items-center mb-2">
                                            <h5 class="m-0">{{ chart['title'] }}</h5>
                                            <img class="pointer bulb-light" *ngIf="chart['preType'] != 'table'"
                                                src="../../../../assets/images/lightbulb.png" width="32" height="32"
                                                alt="" (click)="getInsights(chart)">
                                        </div>

                                        <p-tabView *ngIf="chart['title'] == 'Top Revenue Accounts'">
                                            <p-tabPanel>
                                                <ng-template pTemplate="header">
                                                    <span>Graph</span>
                                                </ng-template>
                                                <div class="p-fluid">
                                                    <p-chart styleClass="pie"
                                                        [type]="chart && chart['type'] ? chart['type'] : 'line'"
                                                        [data]="chart['datasets']" [options]="chart['options']" />
                                                </div>
                                            </p-tabPanel>
                                            <p-tabPanel>
                                                <ng-template pTemplate="header">
                                                    <span>Info</span>
                                                </ng-template>

                                                <div *ngIf="chart['title'] == 'Top Revenue Accounts'">

                                                    <div class="d-flex">
                                                        <span class="mr-3">
                                                            <p class="head"
                                                                *ngFor="let label of chart['datasets']['labels']">
                                                                {{label}}</p>
                                                        </span>
                                                        <span>
                                                            <p class="sub-head"
                                                                *ngFor="let label of chart['datasets']['datasets'][0]['data']">
                                                                {{label}}</p>
                                                        </span>
                                                    </div>
                                                </div>
                                            </p-tabPanel>
                                        </p-tabView>

                                        <div class="p-fluid" *ngIf="chart['title'] !== 'Top Revenue Accounts'">

                                            <p-chart [type]="chart && chart['type'] ? chart['type'] : 'line'"
                                                [data]="chart['datasets']" [options]="chart['options']" />
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                    </p-tabPanel>

                    <p-tabPanel>
                        <ng-template pTemplate="header">
                            <span>Year Over Year</span>
                            <i class="pi pi-refresh ml-1"></i>
                        </ng-template>

                        <div class="analytix-box">
                            <div class="d-flex justify-content-between align-items-center">
                                <h4>{{'Year over Year (YoY) Analysis - Revenue & Expenses'}}</h4>
                                <i class="feather ft-message-square msg-icon"
                                    (click)="invokeChatSliding('cfo-chat')"></i>
                            </div>

                            <div class="dashboard-info d-flex">
                                <div class="dashboard-filter">
                                    <label for="" class="label-control">Year</label>
                                    <span class="p-fluid">
                                        <p-dropdown (onChange)="changeInFilter($event, 'year')" [(ngModel)]="year"
                                            styleClass="allDropdown" appendTo="body" filterBy="name"
                                            [placeholder]="'Select Year'" [options]="yearOptions" optionLabel="name"
                                            optionValue="value">
                                        </p-dropdown>
                                    </span>
                                </div>
                            </div>

                            <div class="loader-wrapper circular-spinners text-center"
                                *ngIf="!yoyOverviewDetails || !yoyOverviewDetails['cards']; else overyear">
                                <i class="pi pi-spin pi-spinner"></i>
                            </div>

                            <ng-template #overyear>
                                <div class="dashboard-info d-flex">
                                    <div class="dashboard-card"
                                        *ngIf="yoyOverviewDetails && yoyOverviewDetails['cards'] && yoyOverviewDetails['cards']['Revenue']">
                                        <h5>Revenue</h5>
                                        <p>{{yoyOverviewDetails['cards']['Revenue'] | currency: jobDetails[0]['locale']+
                                            ' '
                                            :'symbol':'1.0-0' }}</p>
                                    </div>
                                    <div class="dashboard-card"
                                        *ngIf="yoyOverviewDetails && yoyOverviewDetails['cards'] && yoyOverviewDetails['cards']['COGS']">
                                        <h5>COGS</h5>
                                        <p>{{yoyOverviewDetails['cards']['COGS'] | currency: jobDetails[0]['locale']+ '
                                            '
                                            :'symbol':'1.0-0'}}</p>
                                    </div>
                                    <div class="dashboard-card"
                                        *ngIf="yoyOverviewDetails && yoyOverviewDetails['cards'] && yoyOverviewDetails['cards']['Gross Profit']">
                                        <h5>Gross Profit</h5>
                                        <p>{{yoyOverviewDetails['cards']['Gross Profit'] | currency:
                                            jobDetails[0]['locale']+ ' ' :'symbol':'1.0-0'}}</p>
                                    </div>
                                    <div class="dashboard-card"
                                        *ngIf="yoyOverviewDetails && yoyOverviewDetails['cards'] && yoyOverviewDetails['cards']['Expenses']">
                                        <h5>Expenses</h5>
                                        <p>{{yoyOverviewDetails['cards']['Expenses'] | currency:
                                            jobDetails[0]['locale']+ '
                                            ' :'symbol':'1.0-0'}}</p>
                                    </div>
                                    <div class="dashboard-card"
                                        *ngIf="yoyOverviewDetails && yoyOverviewDetails['cards'] && yoyOverviewDetails['cards']['Net Profit']">
                                        <h5>Net Profit</h5>
                                        <p>{{yoyOverviewDetails['cards']['Net Profit'] | currency:
                                            jobDetails[0]['locale']+
                                            ' ' :'symbol':'1.0-0'}}</p>
                                    </div>
                                </div>

                                <div *ngIf="yoyOverviewChartDetails.length">
                                    <div class="chart-area mt-2" *ngFor="let chart of yoyOverviewChartDetails">
                                        <!-- <h5>{{ chart['title']}}</h5>
                                        <em *ngIf="chart['preType'] != 'table'" class="pi pi-times"
                                            (click)="getInsights(chart)"></em> -->

                                        <div class="d-flex justify-content-between align-items-center mb-2">
                                            <h5 class="m-0">{{ chart['title'] }}</h5>
                                            <img class="pointer bulb-light" *ngIf="chart['preType'] != 'table'"
                                                src="../../../../assets/images/lightbulb.png" width="32" height="32"
                                                alt="" (click)="getInsights(chart)">
                                        </div>
                                        <span class="p-fluid">
                                            <p-chart [type]="chart && chart['type'] ? chart['type'] : 'line'"
                                                [data]="chart['datasets']" [options]="chart['options']" />
                                        </span>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                    </p-tabPanel>

                    <p-tabPanel>
                        <ng-template pTemplate="header">
                            <span>Actual vs Budget Analysis</span>
                            <i class="pi pi-refresh ml-1"></i>
                        </ng-template>


                        <div class="analytix-box">
                            <div class="d-flex justify-content-between align-items-center">
                                <h4>Actual vs Budget Analysis</h4>
                                <i class="feather ft-message-square msg-icon"
                                    (click)="invokeChatSliding('cfo-chat')"></i>
                            </div>
                            <div class="dashboard-info d-flex">
                                <div class="dashboard-filter">
                                    <label for="" class="label-control">Year</label>
                                    <span class="p-fluid">
                                        <p-dropdown (onChange)="changeInFilter($event, 'year')" [(ngModel)]="year"
                                            styleClass="allDropdown" appendTo="body" filterBy="name"
                                            [placeholder]="'Select Year'" [options]="yearOptions" optionLabel="name"
                                            optionValue="value">
                                        </p-dropdown>
                                    </span>
                                </div>
                            </div>

                            <div class="loader-wrapper circular-spinners text-center"
                                *ngIf="!actvsbudAnalysisOverviewDetails || !actvsbudAnalysisOverviewDetails['cards']; else analysis">
                                <i class="pi pi-spin pi-spinner"></i>
                            </div>

                            <ng-template #analysis>
                                <div class="dashboard-info d-flex">
                                    <div class="dashboard-card"
                                        *ngIf="actvsbudAnalysisOverviewDetails && actvsbudAnalysisOverviewDetails['cards'] && actvsbudAnalysisOverviewDetails['cards']['Revenue']">
                                        <h5>Revenue</h5>
                                        <p>{{actvsbudAnalysisOverviewDetails['cards']['Revenue'] | currency:
                                            jobDetails[0]['locale']+ ' ' :'symbol':'1.0-0' }}</p>
                                    </div>
                                    <div class="dashboard-card"
                                        *ngIf="actvsbudAnalysisOverviewDetails && actvsbudAnalysisOverviewDetails['cards'] && actvsbudAnalysisOverviewDetails['cards']['COGS']">
                                        <h5>COGS</h5>
                                        <p>{{actvsbudAnalysisOverviewDetails['cards']['COGS'] | currency:
                                            jobDetails[0]['locale']+ ' ' :'symbol':'1.0-0' }}</p>
                                    </div>
                                    <div class="dashboard-card"
                                        *ngIf="actvsbudAnalysisOverviewDetails && actvsbudAnalysisOverviewDetails['cards'] && actvsbudAnalysisOverviewDetails['cards']['Gross Profit']">
                                        <h5>Gross Profit</h5>
                                        <p>{{actvsbudAnalysisOverviewDetails['cards']['Gross Profit'] | currency:
                                            jobDetails[0]['locale']+ ' ' :'symbol':'1.0-0' }}</p>
                                    </div>
                                    <div class="dashboard-card"
                                        *ngIf="actvsbudAnalysisOverviewDetails && actvsbudAnalysisOverviewDetails['cards'] && actvsbudAnalysisOverviewDetails['cards']['Expenses']">
                                        <h5>Expenses</h5>
                                        <p>{{actvsbudAnalysisOverviewDetails['cards']['Expenses'] | currency:
                                            jobDetails[0]['locale']+ ' ' :'symbol':'1.0-0' }}</p>
                                    </div>
                                    <div class="dashboard-card"
                                        *ngIf="actvsbudAnalysisOverviewDetails && actvsbudAnalysisOverviewDetails['cards'] && actvsbudAnalysisOverviewDetails['cards']['Net Profit']">
                                        <h5>Net Profit</h5>
                                        <p>{{actvsbudAnalysisOverviewDetails['cards']['Net Profit'] | currency:
                                            jobDetails[0]['locale']+ ' ' :'symbol':'1.0-0' }}</p>
                                    </div>
                                </div>

                                <div *ngIf="actvsbudAnalysisOverviewChartDetails.length">
                                    <div class="chart-area mt-2" [ngClass]="chart['preType'] == 'table' ? 'table' : ''"
                                        *ngFor="let chart of actvsbudAnalysisOverviewChartDetails">
                                        <!-- <h5 *ngIf="chart['preType'] == 'chart'">{{ chart['title']}}</h5>
                                        <em *ngIf="chart['preType'] != 'table'" class="pi pi-times"
                                            (click)="getInsights(chart)"></em> -->

                                        <div class="d-flex justify-content-between align-items-center mb-2">
                                            <h5 class="m-0">{{ chart['title'] }}</h5>
                                            <img class="pointer bulb-light" *ngIf="chart['preType'] != 'table'"
                                                src="../../../../assets/images/lightbulb.png" width="32" height="32"
                                                alt="" (click)="getInsights(chart)">
                                        </div>
                                        <span *ngIf="chart['preType'] == 'chart'" class="p-fluid">
                                            <p-chart [type]="chart && chart['type'] ? chart['type'] : 'line'"
                                                [data]="chart['datasets']" [options]="chart['options']" />
                                        </span>

                                        <h5 *ngIf="chart['preType'] == 'table'">{{ chart['title']}}</h5>
                                        <p-treeTable *ngIf="chart['preType'] == 'table'" [value]="chart['datasets']"
                                            [scrollable]="true" scrollHeight="320px">
                                            <ng-template pTemplate="header">
                                                <tr>
                                                    <th style="width: 32%;"></th>
                                                    <th>Actuals</th>
                                                    <th>Budget</th>
                                                    <th>Variance</th>
                                                    <th>Var %</th>
                                                    <th>Ratio</th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
                                                <tr [ttRow]="rowNode">
                                                    <td style="width: 32%;"> <p-treeTableToggler
                                                            [rowNode]="rowNode"></p-treeTableToggler> {{ rowData?.level
                                                        }}
                                                    </td>
                                                    <td>{{ rowData?.Actual }}</td>
                                                    <td>{{ rowData?.Budget }}</td>
                                                    <td>{{ rowData?.Variance }}</td>
                                                    <td>{{ rowData['%Var'].toFixed(3) }}</td>
                                                    <td>{{ rowData?.Ratio.toFixed(3) }}</td>
                                                </tr>
                                                <ng-container *ngIf="rowData.children">
                                                    <ng-container *ngFor="let child of rowData.children">
                                                        <ng-container
                                                            *ngTemplateOutlet="body; context:{ $implicit: child, rowNode: rowNode }"></ng-container>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-template>
                                        </p-treeTable>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                    </p-tabPanel>

                    <p-tabPanel>
                        <ng-template pTemplate="header">
                            <span>Actual vs Budget Details</span>
                            <i class="pi pi-refresh ml-1"></i>
                        </ng-template>



                        <div class="analytix-box">
                            <div class="d-flex justify-content-between align-items-center">
                                <h4>Actual vs Budget Analysis</h4>
                                <i class="feather ft-message-square msg-icon"
                                    (click)="invokeChatSliding('cfo-chat')"></i>
                            </div>
                            <div class="dashboard-info d-flex">
                                <div class="dashboard-filter">
                                    <label for="" class="label-control">Year</label>
                                    <span class="p-fluid">
                                        <p-dropdown (onChange)="changeInFilter($event, 'year')" [(ngModel)]="year"
                                            styleClass="allDropdown" appendTo="body" filterBy="name"
                                            [placeholder]="'Select Year'" [options]="yearOptions" optionLabel="name"
                                            optionValue="value">
                                        </p-dropdown>
                                    </span>
                                </div>

                                <div class="dashboard-filter">
                                    <label for="" class="label-control">Category 4</label>
                                    <span class="p-fluid">
                                        <p-dropdown (onChange)="changeInFilter($event, 'cat4')" [(ngModel)]="cat4"
                                            styleClass="allDropdown" appendTo="body" filterBy="name"
                                            [placeholder]="'Select Category'" [options]="cat4Options" optionLabel="name"
                                            optionValue="value">
                                        </p-dropdown>
                                    </span>
                                </div>
                            </div>

                            <div class="loader-wrapper circular-spinners text-center"
                                *ngIf="!AvsBDetails || !AvsBDetails['cards']; else budgetdetails">
                                <i class="pi pi-spin pi-spinner"></i>
                            </div>

                            <ng-template #budgetdetails>
                                <div class="dashboard-info d-flex">
                                    <div class="dashboard-card"
                                        *ngIf="AvsBDetails && AvsBDetails['cards'] && AvsBDetails['cards']['Revenue']">
                                        <h5>Revenue</h5>
                                        <p>{{AvsBDetails['cards']['Revenue'] | currency: jobDetails[0]['locale']+ ' '
                                            :'symbol':'1.0-0' }}
                                        </p>
                                    </div>
                                    <div class="dashboard-card"
                                        *ngIf="AvsBDetails && AvsBDetails['cards'] && AvsBDetails['cards']['COGS']">
                                        <h5>COGS</h5>
                                        <p>{{AvsBDetails['cards']['COGS'] | currency: jobDetails[0]['locale']+ ' '
                                            :'symbol':'1.0-0'}}
                                        </p>
                                    </div>
                                    <div class="dashboard-card"
                                        *ngIf="AvsBDetails && AvsBDetails['cards'] && AvsBDetails['cards']['Gross Profit']">
                                        <h5>Gross Profit</h5>
                                        <p>{{AvsBDetails['cards']['Gross Profit'] | currency: jobDetails[0]['locale']+ '
                                            '
                                            :'symbol':'1.0-0' }}
                                        </p>
                                    </div>
                                    <div class="dashboard-card"
                                        *ngIf="AvsBDetails && AvsBDetails['cards'] && AvsBDetails['cards']['Expenses']">
                                        <h5>Expenses</h5>
                                        <p>{{AvsBDetails['cards']['Expenses'] | currency: jobDetails[0]['locale']+ ' '
                                            :'symbol':'1.0-0' }}
                                        </p>
                                    </div>
                                    <div class="dashboard-card"
                                        *ngIf="AvsBDetails && AvsBDetails['cards'] && AvsBDetails['cards']['Net Profit']">
                                        <h5>Net Profit</h5>
                                        <p>{{AvsBDetails['cards']['Net Profit'] | currency: jobDetails[0]['locale']+ ' '
                                            :'symbol':'1.0-0' }}
                                        </p>
                                    </div>
                                </div>

                                <div *ngIf="AvsBChartDetails.length">
                                    <div class="chart-area mt-2"
                                        [ngClass]="chart['preType'] == 'table' ? 'table static' : ''"
                                        *ngFor="let chart of AvsBChartDetails">
                                        <!-- <h5 *ngIf="chart['preType'] == 'chart'">{{ chart['title']}}</h5>
                                        <em *ngIf="chart['preType'] != 'table'" class="pi pi-times"
                                            (click)="getInsights(chart)"></em> -->

                                        <div class="d-flex justify-content-between align-items-center mb-2">
                                            <h5 class="m-0">{{ chart['title'] }}</h5>
                                            <img class="pointer bulb-light" *ngIf="chart['preType'] != 'table'"
                                                src="../../../../assets/images/lightbulb.png" width="32" height="32"
                                                alt="" (click)="getInsights(chart)">
                                        </div>
                                        <span *ngIf="chart['preType'] == 'chart'" class="p-fluid">
                                            <p-chart [type]="chart && chart['type'] ? chart['type'] : 'line'"
                                                [data]="chart['datasets']" [options]="chart['options']" />
                                        </span>

                                        <h5 *ngIf="chart['preType'] == 'table' && chart['datasets'][0]['data'].length">
                                            {{
                                            chart['title']}}</h5>
                                        <p-table
                                            *ngIf="chart['preType'] == 'table' && chart['datasets'][0]['data'].length"
                                            [value]="chart['datasets'][0]['data']">
                                            <ng-template pTemplate="header">
                                                <tr>
                                                    <th>Account</th>
                                                    <th>Actuals</th>
                                                    <th>Budget</th>
                                                    <th>Category1</th>
                                                    <th>Category2</th>
                                                    <th>Category3</th>
                                                    <th>Category4</th>
                                                    <th>LY_Var</th>
                                                    <th>LY</th>
                                                    <th>Ratio</th>
                                                    <th>Id</th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-rowData>
                                                <tr>
                                                    <td>{{ rowData?.Account }}</td>
                                                    <td>{{ rowData?.Actual.toFixed(2) }}</td>
                                                    <td>{{ rowData?.Budget.toFixed(2) }}</td>
                                                    <td>{{ rowData?.Category1 }}</td>
                                                    <td>{{ rowData?.Category2 }}</td>
                                                    <td>{{ rowData?.Category3 }}</td>
                                                    <td>{{ rowData?.Category4 }}</td>
                                                    <td>{{ rowData['LY_Var'].toFixed(2) }}</td>
                                                    <td>{{ rowData['Last year'].toFixed(2) }}</td>
                                                    <td>{{ rowData?.Ratio.toFixed(2) }}</td>
                                                    <td>{{ rowData?.id }}</td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                    </p-tabPanel>

                    <p-tabPanel>
                        <ng-template pTemplate="header">
                            <span>P & L Statement</span>
                            <i class="pi pi-refresh ml-1"></i>
                        </ng-template>


                        <div class="analytix-box">
                            <div class="d-flex justify-content-between align-items-center">
                                <h4>Profit & Loss Statement</h4>
                                <i class="feather ft-message-square msg-icon"
                                    (click)="invokeChatSliding('cfo-chat')"></i>
                            </div>

                            <div class="dashboard-info d-flex">
                                <div class="dashboard-filter">
                                    <label for="" class="label-control">Year</label>
                                    <span class="p-fluid">
                                        <p-dropdown (onChange)="changeInFilter($event, 'year')" [(ngModel)]="year"
                                            styleClass="allDropdown" appendTo="body" filterBy="name"
                                            [placeholder]="'Select Year'" [options]="yearOptions" optionLabel="name"
                                            optionValue="value"></p-dropdown>
                                    </span>
                                </div>
                            </div>

                            <div class="loader-wrapper circular-spinners text-center"
                                *ngIf="!plStatement || !plStatement['cards']; else statement">
                                <i class="pi pi-spin pi-spinner"></i>
                            </div>

                            <ng-template #statement>
                                <div class="dashboard-info d-flex">
                                    <div class="dashboard-card"
                                        *ngIf="plStatement && plStatement['cards'] && plStatement['cards']['Revenue']">
                                        <h5>Revenue</h5>
                                        <p>{{plStatement['cards']['Revenue'] | currency: jobDetails[0]['locale']+ ' '
                                            :'symbol':'1.0-0'}}</p>
                                    </div>
                                    <div class="dashboard-card"
                                        *ngIf="plStatement && plStatement['cards'] && plStatement['cards']['COGS']">
                                        <h5>COGS</h5>
                                        <p>{{plStatement['cards']['COGS'] | currency: jobDetails[0]['locale']+ ' '
                                            :'symbol':'1.0-0'}}</p>
                                    </div>
                                    <div class="dashboard-card"
                                        *ngIf="plStatement && plStatement['cards'] && plStatement['cards']['Gross Profit']">
                                        <h5>Gross Profit</h5>
                                        <p>{{plStatement['cards']['Gross Profit'] | currency: jobDetails[0]['locale']+ '
                                            '
                                            :'symbol':'1.0-0'}}</p>
                                    </div>
                                    <div class="dashboard-card"
                                        *ngIf="plStatement && plStatement['cards'] && plStatement['cards']['Expenses']">
                                        <h5>Expenses</h5>
                                        <p>{{plStatement['cards']['Expenses'] | currency: jobDetails[0]['locale']+ ' '
                                            :'symbol':'1.0-0'}}</p>
                                    </div>
                                    <div class="dashboard-card"
                                        *ngIf="plStatement && plStatement['cards'] && plStatement['cards']['Net Profit']">
                                        <h5>Net Profit</h5>
                                        <p>{{plStatement['cards']['Net Profit'] | currency: jobDetails[0]['locale']+ ' '
                                            :'symbol':'1.0-0'}}</p>
                                    </div>
                                </div>

                                <div *ngIf="plStatementChartDetails">
                                    <div class=" mt-2 chart-area-full">
                                        <p-table [value]="plStatementChartDetails">
                                            <ng-template pTemplate="header">
                                                <tr>
                                                    <th></th>
                                                    <th>Jan</th>
                                                    <th>Feb</th>
                                                    <th>Mar</th>
                                                    <th>Apr</th>
                                                    <th>May</th>
                                                    <th>Jun</th>
                                                    <th>Jul</th>
                                                    <th>Aug</th>
                                                    <th>Sep</th>
                                                    <th>Oct</th>
                                                    <th>Nov</th>
                                                    <th>Dec</th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-rowData>
                                                <tr>
                                                    <td>{{ rowData?.Level }}</td>
                                                    <td>{{ rowData?.January.toFixed(2) }}</td>
                                                    <td>{{ rowData?.February.toFixed(2) }}</td>
                                                    <td>{{ rowData?.March.toFixed(2) }}</td>
                                                    <td>{{ rowData?.April.toFixed(2) }}</td>
                                                    <td>{{ rowData?.May.toFixed(2) }}</td>
                                                    <td>{{ rowData?.June.toFixed(2) }}</td>
                                                    <td>{{ rowData?.July.toFixed(2) }}</td>
                                                    <td>{{ rowData?.August.toFixed(2) }}</td>
                                                    <td>{{ rowData?.September.toFixed(2) }}</td>
                                                    <td>{{ rowData?.October.toFixed(2) }}</td>
                                                    <td>{{ rowData?.November.toFixed(2) }}</td>
                                                    <td>{{ rowData?.December.toFixed(2) }}</td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                    </div>
                                </div>
                            </ng-template>
                        </div>

                    </p-tabPanel>

                    <p-tabPanel>
                        <ng-template pTemplate="header">
                            <span> What If Analysis</span>
                            <i class="pi pi-refresh ml-1"></i>
                        </ng-template>

                        <div class="analytix-box">
                            <div class="d-flex justify-content-between align-items-center">
                                <h4>What if Analysis</h4>
                                <i class="feather ft-message-square msg-icon"
                                    (click)="invokeChatSliding('cfo-chat')"></i>
                            </div>

                            <div class="dashboard-info d-flex">
                                <div class="dashboard-filter">
                                    <label for="" class="label-control">Year</label>
                                    <span class="p-fluid">
                                        <p-dropdown (onChange)="changeInFilter($event, 'year')" [(ngModel)]="year"
                                            styleClass="allDropdown" appendTo="body" filterBy="name"
                                            [placeholder]="'Select Year'" [options]="yearOptions" optionLabel="name"
                                            optionValue="value"></p-dropdown>
                                    </span>
                                </div>
                            </div>

                            <div class="row mb-2">
                                <div class="col-4">
                                    <div class="d-flex justify-content-between align-items-center mb-1">
                                        <label for="" class="label-control">Sales % Change</label>
                                        <input type="text" pInputText [(ngModel)]="sales_percent"
                                            class="w-full slider-input" />
                                    </div>
                                    <p-slider [min]="-100" [max]="100" [(ngModel)]="sales_percent"
                                        (onSlideEnd)="onChangleSlider()"></p-slider>
                                </div>
                                <div class="col-4">
                                    <div class="d-flex justify-content-between align-items-center mb-1">
                                        <label for="" class="label-control">COGS % Change</label>
                                        <input type="text" pInputText [(ngModel)]="cogs_percent"
                                            class="w-full slider-input" />
                                    </div>
                                    <p-slider [min]="-100" [max]="100" [(ngModel)]="cogs_percent"
                                        (onSlideEnd)="onChangleSlider()"></p-slider>
                                </div>
                                <div class="col-4">
                                    <div class="d-flex justify-content-between align-items-center mb-1">
                                        <label for="" class="label-control">Expenses % Change</label>
                                        <input type="text" pInputText [(ngModel)]="expense_percent"
                                            class="w-full slider-input" />
                                    </div>
                                    <p-slider [min]="-100" [max]="100" [(ngModel)]="expense_percent"
                                        (onSlideEnd)="onChangleSlider()"></p-slider>
                                </div>
                            </div>

                            <div class="dashboard-info d-flex">
                                <div class="dashboard-card"
                                    *ngIf="whatIf && whatIf['cards'] && whatIf['cards']['Revenue']">
                                    <h5>Revenue</h5>
                                    <p>{{whatIf['cards']['Revenue'] | currency: jobDetails[0]['locale']+ ' '
                                        :'symbol':'1.0-0' }}
                                    </p>
                                </div>

                                <div class="dashboard-card"
                                    *ngIf="whatIf && whatIf['cards'] && whatIf['cards']['Revenue (Adj)']">
                                    <h5>Revenue (Adj)</h5>
                                    <p>{{whatIf['cards']['Revenue (Adj)'] | currency: jobDetails[0]['locale']+ ' '
                                        :'symbol':'1.0-0' }}
                                    </p>
                                </div>

                                <div class="dashboard-card"
                                    *ngIf="whatIf && whatIf['cards'] && whatIf['cards']['COGS']">
                                    <h5>COGS</h5>
                                    <p>{{whatIf['cards']['COGS'] | currency: jobDetails[0]['locale']+ ' '
                                        :'symbol':'1.0-0' }}
                                    </p>
                                </div>

                                <div class="dashboard-card"
                                    *ngIf="whatIf && whatIf['cards'] && whatIf['cards']['COGS (Adj)']">
                                    <h5>COGS (Adj)</h5>
                                    <p>{{whatIf['cards']['COGS (Adj)'] | currency: jobDetails[0]['locale']+ ' '
                                        :'symbol':'1.0-0' }}
                                    </p>
                                </div>

                                <div class="dashboard-card"
                                    *ngIf="whatIf && whatIf['cards'] && whatIf['cards']['Expense']">
                                    <h5>Expense</h5>
                                    <p>{{whatIf['cards']['Expense'] | currency: jobDetails[0]['locale']+ ' '
                                        :'symbol':'1.0-0' }}
                                    </p>
                                </div>

                                <div class="dashboard-card"
                                    *ngIf="whatIf && whatIf['cards'] && whatIf['cards']['Expense  (Adj)']">
                                    <h5>Expense (Adj)</h5>
                                    <p>{{whatIf['cards']['Expense (Adj)'] | currency: jobDetails[0]['locale']+ ' '
                                        :'symbol':'1.0-0' }}
                                    </p>
                                </div>

                                <!-- <div class="dashboard-card"
                                    *ngIf="whatIf && whatIf['cards'] && whatIf['cards']['Gross Profit']">
                                    <h5>Gross Profit</h5>
                                    <p>{{whatIf['cards']['Gross Profit'] | currency: jobDetails[0]['locale']+ ' '
                                        :'symbol':'1.0-0' }}
                                    </p>
                                </div>

                                <div class="dashboard-card"
                                    *ngIf="whatIf && whatIf['cards'] && whatIf['cards']['Gross Profit (Adj)']">
                                    <h5>Gross Profit (Adj)</h5>
                                    <p>{{whatIf['cards']['Gross Profit (Adj)'] | currency: jobDetails[0]['locale']+ ' '
                                        :'symbol':'1.0-0' }}
                                    </p>
                                </div>

                                <div class="dashboard-card"
                                    *ngIf="whatIf && whatIf['cards'] && whatIf['cards']['Net Proft']">
                                    <h5>Net Proft</h5>
                                    <p>{{whatIf['cards']['Net Proft'] | currency: jobDetails[0]['locale']+ ' '
                                        :'symbol':'1.0-0' }}
                                    </p>
                                </div>

                                <div class="dashboard-card"
                                    *ngIf="whatIf && whatIf['cards'] && whatIf['cards']['Net Profit (Adj)']">
                                    <h5>Net Profit (Adj)</h5>
                                    <p>{{whatIf['cards']['Net Profit (Adj)'] | currency: jobDetails[0]['locale']+ ' '
                                        :'symbol':'1.0-0' }}
                                    </p>
                                </div> -->

                            </div>

                            <div class="d-flex" *ngIf="whatIfChartDetails.length">
                                <div class="chart-area-full half mt-2" *ngFor="let chart of whatIfChartDetails">
                                    <!-- <h5>{{ chart['title']}}</h5>
                                    <em *ngIf="chart['preType'] != 'table'" class="pi pi-times"
                                        (click)="getInsights(chart)"></em> -->

                                    <div class="d-flex justify-content-between align-items-center mb-2">
                                        <h5 class="m-0">{{ chart['title'] }}</h5>
                                        <img class="pointer bulb-light" *ngIf="chart['preType'] != 'table'"
                                            src="../../../../assets/images/lightbulb.png" width="32" height="32" alt=""
                                            (click)="getInsights(chart)">
                                    </div>
                                    <span class="p-fluid">
                                        <p-chart [type]="chart && chart['type'] ? chart['type'] : 'line'"
                                            [data]="chart['datasets']" [options]="chart['options']" />
                                    </span>
                                </div>
                                <div class="dashboard-info">
                                    <div class="dashboard-card side-card mt-2"
                                        *ngIf="whatIf && whatIf['cards'] && whatIf['cards']['Gross Profit']">
                                        <h5>Gross Profit</h5>
                                        <p>{{whatIf['cards']['Gross Profit'] | currency: jobDetails[0]['locale']+ ' '
                                            :'symbol':'1.0-0' }}
                                        </p>
                                    </div>

                                    <div class="dashboard-card side-card mt-2"
                                        *ngIf="whatIf && whatIf['cards'] && whatIf['cards']['Gross Profit (Adj)']">
                                        <h5>Gross Profit (Adj)</h5>
                                        <p>{{whatIf['cards']['Gross Profit (Adj)'] | currency: jobDetails[0]['locale']+
                                            ' '
                                            :'symbol':'1.0-0' }}
                                        </p>
                                    </div>

                                    <div class="dashboard-card side-card mt-2"
                                        *ngIf="whatIf && whatIf['cards'] && whatIf['cards']['Net Proft']">
                                        <h5>Net Proft</h5>
                                        <p>{{whatIf['cards']['Net Proft'] | currency: jobDetails[0]['locale']+ ' '
                                            :'symbol':'1.0-0' }}
                                        </p>
                                    </div>

                                    <div class="dashboard-card side-card mt-2"
                                        *ngIf="whatIf && whatIf['cards'] && whatIf['cards']['Net Profit (Adj)']">
                                        <h5>Net Profit (Adj)</h5>
                                        <p>{{whatIf['cards']['Net Profit (Adj)'] | currency: jobDetails[0]['locale']+ '
                                            '
                                            :'symbol':'1.0-0' }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </p-tabPanel>

                    <p-tabPanel>
                        <ng-template pTemplate="header">
                            <span>Balance Sheet</span>
                            <i class="pi pi-refresh ml-1"></i>
                        </ng-template>

                        <div class="analytix-box">
                            <div class="d-flex justify-content-between align-items-center">
                                <h4>Balance Sheet</h4>
                                <i class="feather ft-message-square msg-icon"
                                    (click)="invokeChatSliding('cfo-chat')"></i>
                            </div>

                            <div class="dashboard-info d-flex">
                                <div class="dashboard-filter">
                                    <label for="" class="label-control">Year</label>
                                    <span class="p-fluid">
                                        <p-dropdown (onChange)="changeInFilter($event, 'year')" [(ngModel)]="year"
                                            styleClass="allDropdown" appendTo="body" filterBy="name"
                                            [placeholder]="'Select Year'" [options]="yearOptions" optionLabel="name"
                                            optionValue="value"></p-dropdown>
                                    </span>
                                </div>
                            </div>

                            <div class="dashboard-info d-flex">
                                <div class="dashboard-card" *ngIf="bs && bs['cards'] && bs['cards']['Current Assets']">
                                    <h5> Current Assets </h5>
                                    <p>{{bs['cards']['Current Assets'] | currency: jobDetails[0]['locale']+ ' '
                                        :'symbol':'1.0-0' }}
                                    </p>
                                    <p>{{
                                        'Goal: ' + bs['cards']['Current Assets LY']
                                        + '(' + bs['cards']['Current Assets Percent'] + '%)' }}
                                    </p>
                                </div>

                                <div class="dashboard-card"
                                    *ngIf="bs && bs['cards'] && bs['cards']['Non Current Assets']">
                                    <h5> Non Current Assets </h5>
                                    <p>{{bs['cards']['Non Current Assets'] | currency: jobDetails[0]['locale']+ ' '
                                        :'symbol':'1.0-0' }}
                                    </p>
                                    <p>{{
                                        'Goal: ' + bs['cards']['Non Current Assets LY']
                                        + ' (' + bs['cards']['Non Current Assets Percent'] + '%)' }}
                                    </p>
                                </div>



                                <div class="dashboard-card"
                                    *ngIf="bs && bs['cards'] && bs['cards']['Current Liabilities']">
                                    <h5> Current Liabilities </h5>
                                    <p>{{bs['cards']['Current Liabilities'] | currency: jobDetails[0]['locale']+ ' '
                                        :'symbol':'1.0-0' }}
                                    </p>
                                    <p>
                                        {{
                                        'Goal: ' + bs['cards']['Current Liabilities LY']
                                        + ' (' + bs['cards']['Current Liabilities Percent'] + '%)' }}
                                    </p>
                                </div>

                                <div class="dashboard-card"
                                    *ngIf="bs && bs['cards'] && bs['cards']['Non Current Liabilities']">
                                    <h5> Non Current Liabilities </h5>
                                    <p>{{bs['cards']['Non Current Liabilities'] | currency: jobDetails[0]['locale']+ ' '
                                        :'symbol':'1.0-0' }}
                                    </p>
                                    <p>
                                        {{'Goal: ' + bs['cards']['Non Current Liabilities LY'] +
                                        ' (' + bs['cards']['Non Current Liabilities Percent'] + '%)'}}
                                    </p>
                                </div>

                                <div class="dashboard-card" *ngIf="bs && bs['cards'] && bs['cards']['Current Ratio']">
                                    <h5> Current Ratio </h5>
                                    <p>{{bs['cards']['Current Ratio'] }}</p>
                                </div>

                            </div>

                            <div *ngIf="bsChartDetails.length">
                                <ng-container *ngFor="let chart of bsChartDetails">
                                    <div
                                        [ngClass]="chart['preType'] === 'chart' ? 'chart-area mt-2' : 'chart-area-full mt-2'">
                                        <!-- <h5 *ngIf="chart['preType'] == 'chart'">{{ chart['title']}}</h5>
                                        <em *ngIf="chart['preType'] != 'table'" class="pi pi-times"
                                            (click)="getInsights(chart)"></em> -->
                                        <div class="d-flex justify-content-between align-items-center mb-2">
                                            <h5 class="m-0" *ngIf="chart['preType'] == 'chart'">{{ chart['title'] }}
                                            </h5>
                                            <img class="pointer bulb-light" *ngIf="chart['preType'] != 'table'"
                                                src="../../../../assets/images/lightbulb.png" width="32" height="32"
                                                alt="" (click)="getInsights(chart)">
                                        </div>

                                        <span class="p-fluid" *ngIf="chart['preType'] == 'chart'">
                                            <p-chart [type]="chart && chart['type'] ? chart['type'] : 'line'"
                                                [data]="chart['datasets']" [options]="chart['options']" />
                                        </span>



                                        <h5 *ngIf="chart['preType'] == 'table' && chart['datasets'][0]['data'].length">
                                            {{
                                            chart['title']}}</h5>
                                        <p-table
                                            *ngIf="chart['preType'] == 'table' && chart['datasets'][0]['data'].length"
                                            [value]="chart['datasets'][0]['data']">
                                            <ng-template pTemplate="header">
                                                <tr>
                                                    <th>Category</th>
                                                    <th>Act YTD</th>
                                                    <th>LY YTD</th>
                                                    <th>Var %</th>
                                                    <th>Id</th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-rowData>
                                                <tr>
                                                    <td>{{ rowData?.Category }}</td>
                                                    <td>{{ rowData?.['Act YTD'] }}</td>
                                                    <td>{{ rowData['LY YTD'] }}</td>
                                                    <td>{{ rowData['Var %'] }}</td>
                                                    <td>{{ rowData?.id }}</td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                    </div>
                                </ng-container>
                            </div>
                        </div>


                    </p-tabPanel>


                    <p-tabPanel>
                        <ng-template pTemplate="header">
                            <span>Cash Flow</span>
                            <i class="pi pi-refresh ml-1"></i>
                        </ng-template>

                        <div class="analytix-box">
                            <div class="d-flex justify-content-between align-items-center">
                                <h4>{{'Cash Flow'}}</h4>
                                <i class="feather ft-message-square msg-icon"
                                    (click)="invokeChatSliding('cfo-chat')"></i>
                            </div>

                            <div class="dashboard-info d-flex">
                                <div class="dashboard-filter">
                                    <label for="" class="label-control">Year</label>
                                    <span class="p-fluid">
                                        <p-dropdown (onChange)="changeInFilter($event, 'year')" [(ngModel)]="year"
                                            styleClass="allDropdown" appendTo="body" filterBy="name"
                                            [placeholder]="'Select Year'" [options]="yearOptions" optionLabel="name"
                                            optionValue="value">
                                        </p-dropdown>
                                    </span>
                                </div>
                            </div>



                                <div class="dashboard-info d-flex">
                                    <div class="dashboard-card details"
                                        *ngIf="cf && cf['cards'] && cf['cards']['Beginning Cash on Hand']">
                                        <h5>Beginning Cash on Hand</h5>
                                        <p>{{cf['cards']['Beginning Cash on Hand'] | currency: jobDetails[0]['locale']+
                                            ' '
                                            :'symbol':'1.0-0' }}</p>
                                    </div>

                                    <div class="dashboard-card details"
                                        *ngIf="cf && cf['cards'] && cf['cards']['Cash going In']">
                                        <h5>Cash going In</h5>
                                        <p>{{cf['cards']['Cash going In'] | currency: jobDetails[0]['locale']+
                                            ' '
                                            :'symbol':'1.0-0' }}</p>
                                    </div>
                                    <div class="dashboard-card details"
                                        *ngIf="cf && cf['cards'] && cf['cards']['Cash going Out']">
                                        <h5>Cash going Out</h5>
                                        <p>{{cf['cards']['Cash going Out'] | currency: jobDetails[0]['locale']+
                                            ' '
                                            :'symbol':'1.0-0' }}</p>
                                    </div>
                                    <div class="dashboard-card details"
                                        *ngIf="cf && cf['cards'] && cf['cards']['Ending Cash on Hand']">
                                        <h5>Ending Cash on Hand</h5>
                                        <p>{{cf['cards']['Ending Cash on Hand'] | currency: jobDetails[0]['locale']+
                                            ' '
                                            :'symbol':'1.0-0' }}</p>
                                    </div>
                                </div>

                                <div *ngIf="cfChartDetails.length">
                                   
                                        <ng-container *ngFor="let chart of cfChartDetails">
                                            <div
                                                [ngClass]="chart['preType'] === 'chart' && chart['type'] != 'line' ? 'chart-area mt-2' : 'chart-area mt-2'">
                                                <div class="d-flex justify-content-between align-items-center mb-2">
                                                    <h5 class="m-0" *ngIf="chart['preType'] == 'chart'">{{ chart['title'] }}
                                                    </h5>
                                                    <img class="pointer bulb-light" *ngIf="chart['preType'] != 'table'"
                                                        src="../../../../assets/images/lightbulb.png" width="32" height="32"
                                                        alt="" (click)="getInsights(chart)">
                                                </div>
        
                                                <span class="p-fluid" *ngIf="chart['preType'] == 'chart'">
                                                    <p-chart [type]="chart && chart['type'] ? chart['type'] : 'line'"
                                                        [data]="chart['datasets']" [options]="chart['options']" />
                                                </span>
        
        
        
                                                <h5 *ngIf="chart['preType'] == 'table' && chart['datasets'][0]['data'].length">
                                                    {{
                                                    chart['title']}}</h5>
                                                <p-table
                                                    *ngIf="chart['preType'] == 'table' && chart['datasets'][0]['data'].length"
                                                    [value]="chart['datasets'][0]['data']">
                                                    <ng-template pTemplate="header">
                                                        <tr>
                                                            <th>Category</th>
                                                            <th>Amount</th>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-rowData>
                                                        <tr>
                                                            <td>{{ rowData?.Category }}</td>
                                                            <td>{{ rowData?.Amount }}</td>
                                                        </tr>
                                                    </ng-template>
                                                </p-table>
                                            </div>
                                        </ng-container>
                                
                                </div>

                        </div>
                    </p-tabPanel>
                </p-tabView>
            </div>
        </div>
    </div>

    <p-dialog [(visible)]="displayInsights" [resizable]="false" [draggable]="false" [modal]="true" [blockScroll]="true"
        [style]="{width: '55vw'}" appendTo="body">
        <div class="p-dialog-titlebar">
            <button class="p-dialog-titlebar-icon p-dialog-titlebar-close" autofocus="true" aria-label="Close Dialog"
                (click)="displayInsights=false">
                <span class="pi pi-times"></span>
            </button>
        </div>
        <div class="row subscription-plans info-cards">
            <div class="col-12 mb-2">
                <div class="d-flex justify-content-center align-items-center">
                    <h3 class="m-0 ml-1"> {{'Insights Based on ' + insightsTitle }}</h3>
                </div>
            </div>
        </div>

        <div *ngIf="loading" class="d-flex justify-content-center mt-5">
            <i class="pi pi-spin pi-spinner" style="font-size: 1.5rem"></i>
        </div>

        <ul *ngIf="!loading" style="list-style: none;">
            <li class="mb-1" *ngFor="let ins of insights"> {{ins}} </li>
        </ul>
    </p-dialog>