<div *ngIf="!top" class="confirm-synthesis-data">
    <div class="text-center">
        <h2 class="popup-title">{{ 'confirmsynthesisdata.popuptitle' | translate }}</h2>
    </div>
    <div class="sample-result">
        <div *ngIf="testData[0]['SourceColumn1']['table_name']">
            <div class="table-col">
                <span class="table-col-box">
                    <span>{{testData[0]['SourceColumn1']['table_name'] ? testData[0]['SourceColumn1']['table_name'] :
                        '--'}}
                    </span> <i class="feather ft-arrow-right mx-1"></i>
                    <span>{{testData[0]['SourceColumn1']['column_name'] ? testData[0]['SourceColumn1']['column_name'] :
                        '--'}}</span>
                </span>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-12 col-sm-12 confirm-data-content">
                    <div class="row sythesizedData">
                        <div class="col-lg-5 col-md-4">
                            <h4 class="label-title">{{ 'confirmsynthesisdata.before' | translate }}</h4>
                        </div>
                        <div class="col-7">
                            <p class="text-warp">{{testData[0]['SourceColumn1']['synthesized']['source_before']}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 confirm-data-content">
                    <div class="row sythesizedData">
                        <div class="col-lg-5 col-md-4">
                            <h4 class="label-title">{{ 'confirmsynthesisdata.synthesized' | translate }}</h4>
                        </div>
                        <div class="col-7 d-flex flex-wrap">
                            <p class="text-warp" [ngClass]="{'space': item === ' ','mismatch': i < positionRange.upper}"
                                *ngFor="let item of  breakDown(testData[0]['SourceColumn1']['synthesized']['source_after']) ; let i = index">
                                {{item}}</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="row" *ngIf="testData[0]['SourceColumn1']['synthesized']['dependent_col'].length > 0">
            <p class="dependent-title">{{ 'confirmsynthesisdata.dependentcolumns' | translate }}</p>
            <div class="col-lg-12 col-md-12 col-sm-12"
                *ngFor="let item of testData[0]['SourceColumn1']['synthesized']['dependent_col']">
                <div class="table-col" *ngFor="let itm of testData[0]['SourceColumn1']['strategy']['dependent_fields']">
                    <span *ngIf="itm['column_name']==item['dependent_col']" class="table-col-box">
                        <span>{{itm['column_name'] }}</span>
                        <i class="feather ft-arrow-right mx-1"></i>
                        <span>{{itm['type']}}</span>
                    </span>
                </div>
                <div class="row">
                    <div class="col-lg-6 confirm-data-content">
                        <div class="row">
                            <div class="col-lg-4 col-md-4 col-sm-12">
                                <p class="name">{{ 'confirmsynthesisdata.before' | translate }}</p>
                            </div>
                            <div class="col-lg-7 col-md-7 col-sm-12   synthesized-running-text">
                                <p class="text-warp">{{item['dep_before']}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 confirm-data-content">
                        <div class="row">
                            <div class="col-lg-4 col-md-4 col-sm-12 ">
                                <p class="name">{{ 'confirmsynthesisdata.synthesized' | translate }}</p>
                            </div>
                            <div class="col-lg-7 col-md-7 col-sm-12">
                                <p class="text-warp">{{item['dep_after']}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row border-bottom-1 sample-data-state" *ngIf="testData[0]['SourceColumn1']['table_name']">
        <!-- <h3>{{ 'confirmsynthesisdata.title3jobconfiguration' | translate }}</h3> -->
        <!-- <div class="col-6">
            <div class="row confirm-data-content" *ngIf="testData[0]['SourceColumn1']['strategy']['strategy_name']">
                <div class="col-lg-4 col-md-4">
                    <h4 class="label-title">{{ 'confirmsynthesisdata.strategy' | translate }}</h4>
                </div>
                <div class="col-7   synthesized-running-text">
                    <p class="text-warp">{{testData[0]['SourceColumn1']['strategy']['strategy_name']}}</p>
                </div>
            </div>
        </div> -->
        <!-- <div class="col-lg-6 col-md-12">
            <div class="row confirm-data-content" *ngIf="testData[0]['SourceColumn1']['primary_key'].length > 0">
                <div class="col-lg-4 col-md-4">
                    <p class="name">{{ 'confirmsynthesisdata.primarykey' | translate }}</p>
                </div>
                <div class="col-7   synthesized-running-text">
                    <p class="text-warp">{{testData[0]['SourceColumn1']['primary_key'][0]}}</p>
                </div>
            </div>
        </div> -->
    </div>
</div>

<div *ngIf="top" class="confirm-synthesis-data">
    <div *ngIf="top">
        <div class="confirm-data-title">
            <h2 class="text-center mb-2">{{ 'confirmsynthesisdata.popuptitle' | translate }}</h2>
            <p class="text-center">{{ 'confirmsynthesisdata.para1rowtext' | translate }}</p>
        </div>
        <div class="d-flex justify-content-between align-items-center mb-2">
            <div class="pop-ttl" *ngIf="tableTestData[0]['table']">
                <p class="mb-0">{{tableTestData[0]['table']}}</p>
            </div>
            <div class="pop-action d-flex align-items-center">
                <span>{{ 'confirmsynthesisdata.span1' | translate }}</span>
                <span class="before-after"><label class="toggle">
                        <input class="toggle-input" type="checkbox"
                            aria-label="Please click space bar to check and uncheck" checked [(ngModel)]="showAfter">
                        <div class="toggle-track">
                            <div class="toggle-control"></div>
                        </div>
                    </label>
                </span>
                <span>{{ 'confirmsynthesisdata.span2' | translate }}</span>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="test-table ">
                    <p-table [value]="tableTestData" [paginator]="false" [rows]="10" [showCurrentPageReport]="true"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [rowsPerPageOptions]="[10,25,50]" styleClass="after-before-table" [scrollable]="true">
                        <ng-template pTemplate="header">
                            <tr *ngIf="tableTestData[0]['keys'].length > 0">
                                <th *ngFor="let item of tableTestData[0]['keys']; let i=index" [id]="'confirmData' + i"
                                    style="min-width: 300px;" [tabIndex]="i">{{item}}</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-customer>
                            <ng-container *ngIf="!showAfter">
                                <tr *ngFor="let item of tableTestData[0]['before']">
                                    <td *ngFor="let i of item" style="min-width: 300px;" [tabIndex]="i">
                                        <div class="bigdata">{{ i }}</div>
                                    </td>
                                </tr>
                            </ng-container>
                            <ng-container *ngIf="showAfter">
                                <tr *ngFor="let item of tableTestData[0]['after']">
                                    <td style="min-width: 300px;" *ngFor="let i of item" [tabIndex]="i">
                                        <div class="bigdata">{{ i }}</div>
                                    </td>
                                </tr>
                            </ng-container>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>