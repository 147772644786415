import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownComponent implements OnInit, OnChanges, OnDestroy {

  @Input() field: any = {};
  @Input() form!: FormGroup;
  @Output() popupEmitter = new EventEmitter<any>();
  @Input() assignStyle: boolean = false;
  @ViewChild('aAddNewPattern', { static: false }) aAddNewPattern: ElementRef;
  @Input() setFocus: boolean = false;
  private intervalId: any;
  private intervalTime = 2000;
  value: any = '';

  constructor(private cdRef: ChangeDetectorRef) { }

  ngOnDestroy(): void {
    this.field = {};
    this.stopInterval();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.startInterval();
    }

    if (changes['setFocus'] && this.setFocus) {
      this.focusHandler();
    }
  }

  focusHandler(): void {
    if (this.aAddNewPattern && this.aAddNewPattern.nativeElement) {
      this.aAddNewPattern.nativeElement.focus();
    }
  }

  private startInterval(): void {
    // Create the interval and store the ID for later cancellation
    this.intervalId = setInterval(() => {
      // Put your code that needs to be executed at each interval here
      this.cdRef.detectChanges();
      if (this.field && this.field['value'] != undefined && this.field['value'] != null) {
        this.form.get(this.field.name).patchValue(this.field.value);
      } else {
        this.form.get(this.field.name).patchValue(null);
      }
      this.stopInterval();
    }, this.intervalTime);
  }

  private stopInterval(): void {
    // Clear the interval using the ID stored during creation
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  sort() {
    if (this.field && this.field['options'] && this.field['options'].length > 0) {
      this.field['options'].sort((a, b) => a.name.localeCompare(b.name));
    }
  }

  async ngOnInit(): Promise<void> {
    this.value = this.field.value;

    setInterval(() => {
      this.sort();
      // update your component's data here
      this.cdRef.detectChanges(); // trigger change detection
    }, 1000); // update the view every second
  }

  patternDialogs(): void {
    if (this.field['group']) {
      this.popupEmitter.emit({
        synthesisStrategy: true,
        value: true,
        selected: this.field['options'].find((r) => r.value === this.form.value['synthesisStrategy'])
      });
    } else if (this.field['callbackAssignEvent']) {
      this.popupEmitter.emit({
        assignSemantic: true,
        value: this.form.value[this.field['name']]
      });
    } else if (this.field['callbackPatternEvent']) {
      // Constraints Callback
      this.popupEmitter.emit({
        pattern: true,
        value: this.form.value[this.field['name']]
      });
    } else if (this.field['name'] == 'dateDelimiter') {
      this.popupEmitter.emit({
        delimiterValue: this.form.value[this.field['name']]
      })
    } else {
      this.popupEmitter.emit({
        refresh: true,
        value: this.form.value[this.field['name']]
      });
    }
  }

  newStrategyEmitter(data: string): void {
    if (data === 'new') {
      this.popupEmitter.emit({
        new: true,
        value: true
      });
    }
  }
}
