import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SyntheticDataService {

    private apiUrl = environment.apiUrl + '/v1';
    private NodeAPIUrl = environment.apiAuthUrl;

    constructor(
        private http: HttpClient
    ) { }

    getTables(body) {
        return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
            method: 'GET',
            path: '/db/get_tables',
            params: { connection_id: body.connection_id, schema_name: body.schema_name }
        })
    }

    getViews(body) {
        return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
            method: 'GET',
            path: '/db/get_views',
            params: { connection_id: body.connection_id, schema_name: body.schema_name }
        })
    }

    getColumns(params) {
        return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
            method: 'GET',
            path: '/db/get_columns',
            params: params
        })
    }

    getRowCount(params) {
        return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
            method: 'GET',
            path: '/db/get_table_rows_count',
            params: params
        })
    }

    getTopRecords(body) {

        return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
            method: 'GET',
            path: '/db/get_top_records',
            params: { connection_id: body.connection_id, schema_name: body.schema_name, table_name: body.table_name, column_name: body.column_name }
        })
    }

    syntheticTestData(body) {
        return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
            method: 'POST',
            path: '/db/synthetic_test_data',
            body: body
        })
    }

    getColumnRelations(body) {
        return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
            method: 'GET',
            path: '/db/get_column_relations',
            params: { connection_id: body.connection_id, schema_name: body.schema_name, table_name: body.table_name, column_name: body.column_name, requesting_relationship: body.requesting_relationship }
        })
    }

    addSyntheticData(body) {
        return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
            method: 'POST',
            path: '/add_synthetic_data_job',
            body: body
        })
    }

    updateSyntheticData(body) {
        return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
            method: 'POST',
            path: '/update_synthetic_data_job',
            body: body
        })
    }

    generateSyntheticData(params) {
        return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
            method: 'GET',
            path: '/db/generate_synthetic_data',
            params: params
        })
    }

    getSynthesisData(params) {
        return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
            method: 'GET',
            path: '/get_synthetic_data_job',
            params: params
        })
    }

    deleteJob(params) {
        return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
            method: 'DELETE',
            path: '/delete_synthetic_data_job',
            params: params
        })
    }

    // get_job_execution_details_by_jobid
    getJobExecutionDetailsByJobId(params) {
        return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
            method: 'GET',
            path: '/get_job_execution_details_by_jobId',
            params: params
        })
    }

    // get_execution_monitor_details_by_executionId
    getExecutionMonitorDetailsByExecutionId(params) {
        return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
            method: 'GET',
            path: '/get_execution_monitor_details_by_executionId',
            params: params
        })
    }

    rollbackByExecutionId(params) {
        return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
            method: 'GET',
            path: '/db/rollback_job_by_execution_id',
            params: params
        })
    }

    flush(params) {
        return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
            method: 'DELETE',
            path: '/db/clean_audit_tables',
            params: params
        })
    }

    getPIIInfo(params) {
        return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
            method: 'GET',
            path: '/get_pii',
            params: params
        })
    }

    validateCascade(body) {
        return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
            method: 'POST',
            path: '/db/validate_ondeletecascade',
            body: body
        })
    }

    syntheticTableTestData(body) {
        return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
            method: 'POST',
            path: '/db/synthetic_table_test_data',
            body: body
        })
    }

    addfile(formData: FormData) {
        return this.http.post<any>(`${this.NodeAPIUrl}/upload`, formData)
    }

    addFinancialFile(formData: FormData) {
        return this.http.post<any>(`${this.NodeAPIUrl}/upload-financial-files`, formData)
    }

    updatedWorkflowInfo(params) {
        return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
            method: 'GET',
            path: '/upgrade_workflow',
            params: params
        })
    }

    addColumnConfig(body) {
        return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
            method: 'POST',
            path: '/add_column_config_file',
            body: body
        })
    }

    syntheticTableTestDataFile(params) {
        return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
            method: 'GET',
            path: '/synthetic_table_test_data_file',
            params: params
        })
    }

    syntheticTestDataFile(body) {
        return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
            method: 'POST',
            path: '/generate_test_data_file',
            body: body
        })
    }

    getTopRecordsFile(params) {
        return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
            method: 'GET',
            path: '/get_top_records_file',
            params: params
        })
    }

    getFileRowCount(params) {
        return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
            method: 'GET',
            path: '/get_row_count',
            params: params
        })
    }

    setRowLimit(body) {
        return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
            method: 'POST',
            path: '/db/set_final_information',
            body: body
        })
    }

    getJobsStatusByProjectId(params) {
        return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
            method: 'GET',
            path: '/get_jobs_status_by_project_id',
            params: params
        })
    }

    async getJobsStatusByProjectIdAsync(params) {
        return new Promise(resolve => {
            this.getJobsStatusByProjectId(params).subscribe({
                next: result => resolve(result),
                error: err => resolve(err)
            })
        })
    }

    // http://127.0.0.1:5000/v1/get_job_status?job_id=3&project_id=P1&org_id=O1
    getJobStatus(params) {
        return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
            method: 'GET',
            path: '/get_job_status',
            params: params
        })
    }

    async getJobStatusAsync(params) {
        return new Promise(resolve => {
            this.getJobStatus(params).subscribe({
                next: result => resolve(result),
                error: err => resolve(err)
            })
        })
    }

    getFile(params) {
        return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
            method: 'GET',
            path: '/get_file',
            params: params,
            file: true
        })
    }

    isFileDBEmpty(params) {
        return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
            method: 'GET',
            path: '/is_file_db_empty',
            params: params
        })
    }

    getPIIReport(params) {
        return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
            method: 'GET',
            path: '/pii_report',
            params: params
        })
    }

    getBiasAnaylsis(params) {
        return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
            method: 'GET',
            path: '/get_bias_analysis',
            params: params
        })
    }

    wokeUpLLM() {
        return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
            method: 'GET',
            path: '/wakeup_llm'
        })
    }

    getColumnTypes(params) {
        return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
            method: 'GET',
            path: '/db/get_table_metadata',
            params: params
        })
    }

    getUniqueRecords(params) {
        return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
            method: 'GET',
            path: '/get_unique_records',
            params: params
        })
    }

    getJobsByOrgId(params) {
        return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
            method: 'GET',
            path: '/get_jobs_list_by_org_id',
            params: params
        })
    }

    testModelWithOld(body) {
        return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
            method: 'POST',
            path: '/test_model',
            body: body
        })
    }

    validateAnomalyColumn(params) {
        return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
            method: 'GET',
            path: '/db/check_anomaly_class_column',
            params: params
        })
    }

    testModelWithNew(formdata) {
        return this.http.post<any>(`${this.NodeAPIUrl}/test-model`, formdata)
    }

    getAutoMLFiles(params): Observable<HttpResponse<Blob>> {
        return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
            method: 'GET',
            path: '/get_automl_files',
            params: params
        })
    }

    cleansing(formdata) {
        return this.http.post<any>(`${this.NodeAPIUrl}/data-cleansing`, formdata)
    }

    getColDescriptions(params): Observable<HttpResponse<Blob>> {
        return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
            method: 'GET',
            path: '/get_column_description',
            params: params
        })
    }
}