<strong>{{ 'termscondition.termstitle' | translate }}</strong>
<p *ngFor="let paragraph of 'termscondition.termsparalist1' | translate">
  {{ paragraph }}
</p>
<div>
  <p *ngFor="let paragraph of 'termscondition.termsparalist2' | translate">
    {{ paragraph }}
  </p>
</div>
<p *ngFor="let paragraph of 'termscondition.termsparalist3' | translate">
  {{ paragraph }}
</p>
<p><strong>{{ 'termscondition.changesterms' | translate }}</strong></p>
<p *ngFor="let paragraph of 'termscondition.changestermspara' | translate">
  {{ paragraph }}
</p>
<p><strong>{{ 'termscondition.contactus' | translate }}</strong></p>
<p>
  {{ 'termscondition.contactuspara' | translate }}
</p>