<div class="correct-bias">
    <form [formGroup]="addBiasForm" class="idForm"
        aria-label="'profile Form All required input feilds are denoted by star'">
        <div class="row">
            <div class="col-md-12">
                <div class="user-input">
                    <label for="float-input"> Sensitive Attribute</label>
                    <div class=" row">
                        <div class="col-12">
                            <p-dropdown formControlName="sensitiveAttribute" [options]="sensitiveAttrsOptions"
                                optionLabel="name" optionValue="value" placeholder="Select"
                                (onChange)="underprivilegedOptionsHandler($event)">
                            </p-dropdown>
                        </div>
                    </div>

                </div>
                <div class="user-input">
                    <label for="float-input"> Underprivileged Value</label>
                    <div class=" row">
                        <div class="col-12">
                            <p-dropdown formControlName="underprivilegedValue" [options]="underprivilegedOptions"
                                optionLabel="name" optionValue="value" placeholder="Select"
                                (onChange)="desirableValueOptionsHandler($event)">
                            </p-dropdown>
                        </div>
                    </div>

                </div>
                <div class="user-input">
                    <label for="float-input"> Desirable Value</label>
                    <div class=" row">
                        <div class="col-12">
                            <p-dropdown formControlName="desirableValue" [options]="desirableOptions" optionLabel="name"
                                optionValue="value" placeholder="Select" (onChange)="selectedDesirableValue($event)">
                            </p-dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row user-img-add">

        </div>
    </form>
</div>