import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FinancialAnalytixService {

  private NodeAPIUrl = environment.apiAuthUrl;

  constructor(
    private http: HttpClient
  ) { }

  financialDashboard(params) {
    return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
      method: 'GET',
      path: '/financial_dashboard',
      params: params
    })
  }

  salesForecasting(params) {
    return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
      method: 'GET',
      path: '/financial_dashboard/forecast',
      params: params
    })
  }

  fetchChatResponse(params) {
    return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
      method: 'GET',
      path: '/financial_dashboard/chat',
      params: params
    })
  }

  fetchInsights(body) {
    return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
      method: 'POST',
      path: '/financial_dashboard/insight',
      body: body,
      params: {},
    })
  }

  getCFOFilter(params) {
    return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
      method: 'GET',
      path: '/cfo_default_filters',
      params: params
    })
  }
  
  getCFODashboardData(params) {
    return this.http.post<any>(`${this.NodeAPIUrl}/p`, {
      method: 'GET',
      path: '/get_cfo_dashboard_data',
      params: params
    })
  }
}
