import { FormGroup, ValidationErrors, ValidatorFn, AbstractControl, FormControl } from '@angular/forms';

export class CustomValidators {

  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }

      const valid = regex.test(control.value);

      return valid ? null : error;
    };
  }

  static passwordMatchValidator(controlName: string, matchingControlName: string): any {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.NoPassswordMatch) {
        return;
      }

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ NoPassswordMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  static passwordNotMatchValidator(controlName: string, matchingControlName: string): any {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.notMatch) {
        return;
      }

      if (control.value === matchingControl.value) {
        matchingControl.setErrors({ notMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  static removeSpaces(control: AbstractControl) {
    if (control && control.value && /^\s/.test(control.value)) {
      try {
        control.setValue(control.value.trim());
      } catch (err) { }
    }
    return null;
  }

  static unCamelCase(str) {
    return str
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3')
      .replace(/^./, function (str) { return str.toUpperCase(); })
  }

  static removeNullFromObject(obj) {
    Object.entries(obj).forEach(([key, value]) => {
      if (value === null) {
        delete obj[key];
      }
    });
    return obj
  }

  static rangeValidator(control: FormControl): ValidationErrors | null {
    // Regular expression to match the desired formats
    const pattern = /^\[\d+,\s*\d+\]$/; // with space after comma
    const patternNoSpace = /^\[\d+,\d+\]$/; // without space after comma
    
    if (!pattern.test(control.value) && !patternNoSpace.test(control.value)) {
      // Return error if the pattern does not match either format
      return { invalidRange: true };
    }
    // Return null if validation passes
    return null;
  }

}
