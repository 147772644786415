import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Toast } from 'src/app/_helpers/toast';
import { RoleService } from 'src/app/_services/role.service';

@Component({
  selector: 'app-manage-member',
  templateUrl: './manage-member.component.html'
})
export class ManageMemberComponent implements OnInit, OnChanges {

  @Input() type: string = ''
  @Input() selectedMember: Object = {};
  orgUsers: Object[] = [];
  public formSchema: Object[] = [
    {
      type: "dropdown",
      name: "selectUser",
      label: "User",
      selectLabel: "Select User",
      value: null,
      class: 'list',
      validations: {
        required: true,
      },
    },
    {
      type: "dropdown",
      name: "assignRole",
      label: "Assign Role",
      selectLabel: "Select Role",
      value: null,
      class: 'list',
      validations: {
        required: true,
      },
      options: [
        { name: "Project Administrator", value: "projectAdministrator" },
        { name: "Member", value: "member" }
      ]
    },
    {
      type: 'btn',
      name: [
        { name: 'Save', disabled: false }
      ]
    },
  ];
  @Output() manageMemberEmit = new EventEmitter<any>();

  constructor(
    private roleService: RoleService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['type'] && this.type !== 'Project') {
      this.formSchema[1]['options'][0] = { name: "Org Administrator", value: "orgAdministrator" }
    }

    if (changes['selectedMember']) {
      this.formSchema[0]['value'] = this.selectedMember['userId'];
      this.formSchema[1]['value'] = this.selectedMember['role'];
    }
  }

  ngOnInit(): void {
    this.getUsers();
  }

  getUsers() {
    this.roleService.getOrgUsers().subscribe({
      next: res => {
        this.orgUsers = res.map(r => {
          return {
            name: r.name,
            value: r.id
          }
        });
        this.formSchema[0]['options'] = this.orgUsers;
      },
      error: err => {
        Toast.fire({
          icon: 'error',
          html: err.error.error.message
        });
      }
    })
  }

  memberData(data) {
    let body = {
      role: data['value']['assignRole'],
      status: "Approved",
      userId: data['value']['selectUser']
    }
    this.manageMemberEmit.emit(body)
  }

}
