import { Component, Input } from '@angular/core';
import { FinancialAnalytixService } from 'src/app/_services/financial-analytix.service';
import { SharedService } from 'src/app/_services/shared.service';

@Component({
  selector: 'app-inter-company-recon',
  templateUrl: './inter-company-recon.component.html'
})
export class InterCompanyReconComponent {


  @Input() jobId;
  @Input() jobDetails;
  loading: boolean = false;
  sublevel = 'inter_company'
  bankDetails = {};
  bankChartDetails = []

  constructor (
    private financialService: FinancialAnalytixService,
    private sharedService: SharedService
  ) {

  }

  ngOnInit() {
    this.onTabChange({ index: 0 });
  }

  onTabChange(e) {
    this.sublevel = 'inter_company';
    this.fetchDashboard({ });
  }


  fetchDashboard(obj) {
    obj['job_id'] = this.jobId;
    obj['sublevel'] = this.sublevel;
    this.financialService.financialDashboard(obj).subscribe({
      next: res => {
        this.bankDetails = res;
        this.chartMappingForBank();
      }, error: err => {

      }
    })
  }

  chartMappingForBank() {
    this.bankChartDetails = []

    if (this.bankDetails['charts']['plot1']['data1']['data'].length) {
      this.bankChartDetails.push({
        title: 'Inter Company Reconciliation',
        data: this.bankDetails['charts']['plot1']['data1']['data']
      })
    } else {
      this.bankChartDetails.push({
        title: 'Inter Company Reconciliation',
        data: this.bankDetails['charts']['plot1']['data1']['data'],
        message: '100 % Reconcilation'
      })
    }

    if (this.bankDetails['charts']['plot1']['data2']['data'].length) {
      this.bankChartDetails.push({
        title: 'Company1 Unreconciliation Details',
        data: this.bankDetails['charts']['plot1']['data2']['data']
      })
    } else {
      this.bankChartDetails.push({
        title: 'Company1 Unreconciliation Details',
        data: this.bankDetails['charts']['plot1']['data2']['data'],
        message: '100 % Reconcilation'
      })
    }
 
    if (this.bankDetails['charts']['plot1']['data3']['data'].length) {
      this.bankChartDetails.push(
        {
          title: 'Company2 Unreconciliation Details',
          data: this.bankDetails['charts']['plot1']['data3']['data']
        }
      )
     } else {
      this.bankChartDetails.push(
        {
          title: 'Company2 Unreconciliation Details',
          data: this.bankDetails['charts']['plot1']['data3']['data'],
          message: '100 % Reconcilation'
        }
      )
    }
   
  }

  invokeChatSliding(type) {
    this.sharedService.sendClickEvent({ jobId: this.jobId, type: 'account' }, type);
  }

}
