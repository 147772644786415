import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-configure-llm',
  templateUrl: './configure-llm.component.html'
})
export class ConfigureLlmComponent implements OnInit, OnChanges {

  configLLMForm: FormGroup;
  dropdownOptions = [
    { label: 'LLAMA-2-CHAT', value: 'llama7' },
    { label: 'Phi2', value: 'phi2' }
  ];
  @Output() configureLLMEvent = new EventEmitter();
  @Input() formBody = {};
  @Output() formValidity: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private formBuilder: FormBuilder
  ) {
    this.configLLMForm = this.formBuilder.group({
      model_name: new FormControl(null, Validators.required),
      base_model: new FormControl(null, Validators.required)
    });

    this.configLLMForm.valueChanges.subscribe(() => {
      this.formValidity.emit(this.configLLMForm.valid);
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.formBody && (this.formBody['model_name'] || this.formBody['base_model'])) {
      this.configLLMForm.patchValue({
        model_name: this.formBody['model_name'],
        base_model: this.formBody['base_model']
      })
    }
  }

  ngOnInit(): void {

  }

  emitterHandler() {
    this.configureLLMEvent.emit(this.configLLMForm.value)
  }

}
