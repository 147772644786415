import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html'
})
export class InputComponent implements OnChanges {

  @Input() field: any = {};
  @Input() form!: FormGroup;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['field']) {
      if (this.field['value'] !== null) {
        this.form.get(this.field.name).patchValue(this.field.value);
      } else {
        // TODO document why this block is empty
      }
      this.updateValidations();
    }
  }

  get f() {
    return this.form.controls;
  }

  inValid(control: any) {
    return control.invalid && (control.dirty || control.touched);
  }

  getErrorMessage(error: string): string {
    const errorMessages = {
      required: `${this.field['label']} is required.`,
      minlength: `${this.field['label']} should be at least ${this.field['validations']['minLength']} characters.`,
      maxlength: `${this.field['label']} should not exceed ${this.field['validations']['maxLength']} characters.`,
      // Add more error messages as needed
    };

    return errorMessages[error] || 'has an invalid value.';
  }

  private updateValidations(): void {
    const formControl = this.form.get(this.field.name);
    const validations = [];

    if (this.field['validations']) {
      if (this.field['validations']['required']) {
        validations.push(Validators.required);
      }
      if (this.field['validations']['minLength']) {
        validations.push(Validators.minLength(this.field['validations']['minLength']));
      }
      if (this.field['validations']['maxLength']) {
        validations.push(Validators.maxLength(this.field['validations']['maxLength']));
      }
      // Add more validations if needed
    }

    formControl.setValidators(validations);
    formControl.updateValueAndValidity();
  }
}
