<form [formGroup]="form" (ngSubmit)="submitEmitter()" aria-label="'Form Builder Form'" *ngIf="formSchema.length > 0">
    <div class="row">
        <ng-container *ngFor="let field of formSchema; let index = index">
            <div class="col-md-12" *ngIf="field['name'] === 'strategyName' && layoutStsHandler === 'layoutStsHandler'">
                <p class="apply-const mb-1 mt-1">{{ 'Save Pattern' | translate }}</p>
            </div>
            <div [ngClass]="field['name'] == 'columnlet' && variableLayout == 'variableLayout' ? 'col-md-6': layout == 'grid' ? 'col-md-6': 'col-md-12'"
                *ngIf="['email', 'password', 'text'].indexOf(field['type'].toLowerCase())>-1">
                <app-input [field]="field" [form]="form"></app-input>
            </div>
            <div class="col-md-6" *ngIf="field['name'] === 'columnlet' && variableLayout === 'variableLayout'">
            </div>
            <div [ngClass]="layout == 'grid' ? 'col-md-6': 'no grid'" *ngIf="field['type'].toLowerCase() === 'number'">
                <app-number [field]="field" [form]="form"></app-number>
            </div>
            <div [ngClass]="layout == 'grid' ? 'col-md-6': 'no grid'"
                *ngIf="field['type'].toLowerCase() === 'checkbox'">
                <app-checkbox [field]="field" [form]="form"></app-checkbox>
            </div>
            <div [ngClass]="layout == 'grid' ? 'col-md-6': 'no grid'"
                *ngIf="field['type'].toLowerCase() === 'textarea'">
                <app-text-area [field]="field" [form]="form"></app-text-area>
            </div>
            <div [ngClass]="field['name'] == 'country' && layoutStsHandler == 'layoutStsHandler' ? 'col-md-6': layout == 'grid' ? 'col-md-6': 'col-md-12'"
                *ngIf="field['type'].toLowerCase() === 'dropdown'">
                <app-dropdown [field]="field" [form]="form" [setFocus]="setFocus" [assignStyle]="assignStyle"
                    (popupEmitter)="popupEvent($event)"></app-dropdown>
            </div>
            <div [ngClass]="layout == 'grid' ? 'col-md-6': 'no grid'" *ngIf="field['type'].toLowerCase() === 'radio'">
                <app-radio [field]="field" [form]="form"></app-radio>
            </div>
            <div [ngClass]="layout == 'grid' ? 'col-md-6': 'no grid'" *ngIf="field['type'].toLowerCase() === 'range'">
                <app-range-field [field]="field" [form]="form"></app-range-field>
            </div>
            <div [ngClass]="layout == 'grid' ? 'col-md-6': 'no grid'" *ngIf="field['type'] === 'dependentField'">
                <app-dependent-field [field]="field" [form]="form" (dFEmitter)="dFHandler($event)"
                    [clearDependentField]="clearDependentField"></app-dependent-field>
            </div>
            <div [ngClass]="layout == 'grid' ? 'col-md-6': 'no grid'" *ngIf="field['type'] === 'regex'">
                <app-regex-field [field]="field" [form]="form"></app-regex-field>
            </div>
            <div *ngIf="field['type'].toLowerCase() === 'btn'" class="d-flex justify-content-end">
                <div *ngFor="let item of field['name']" class="bottom-right">
                    <app-button [field]="item" [setFocusTestButton]="setFocusTestButton" [class]="btnChange"
                        [form]="form" (status)="buttonStatus($event)"></app-button>
                </div>
            </div>
        </ng-container>
    </div>
</form>

<!-- 
    By default, Angular will return the value of an input tag as a string, regardless of whether the input type is number. However, you can use the NgModel or FormsModule directive to convert the string value into a number.
 -->