import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class RoleService {

    private NodeAPIUrl = environment.apiAuthUrl;

    constructor(
        private http: HttpClient
    ) { }

    getOrgUsers() {
        return this.http.get<any>(`${this.NodeAPIUrl}/roles/users`)
    }

    createProjectMember(body) {
        return this.http.post<any>(`${this.NodeAPIUrl}/roles/project-member`, body)
    }

    getProjectMembers(id) {
        return this.http.get<any>(`${this.NodeAPIUrl}/roles/project-member/by-project/${id}`)
    }

    getPendingProjectMembers(id) {
        return this.http.get<any>(`${this.NodeAPIUrl}/roles/project-member/by-project/pending/${id}`)
    }

    updateProjectMember(id, body) {
        return this.http.patch<any>(`${this.NodeAPIUrl}/roles/project-member/${id}`, body);
    }

    removeProjectMember(id) {
        return this.http.delete<any>(`${this.NodeAPIUrl}/roles/project-member/${id}`);
    }

    getOrgMembers(orgId) {
        return this.http.get<any>(`${this.NodeAPIUrl}/roles/org-member/by-org/${orgId}`)
    }

    getPendingOrgMembers(id) {
        return this.http.get<any>(`${this.NodeAPIUrl}/roles/org-member/by-org/pending/${id}`)
    }

    updateOrgMember(id, body) {
        return this.http.patch<any>(`${this.NodeAPIUrl}/roles/org-member/${id}`, body);
    }

    removeOrgMember(id) {
        return this.http.delete<any>(`${this.NodeAPIUrl}/roles/org-member/${id}`);
    }

    getOrgAdminById(id) {
        return this.http.get<any>(`${this.NodeAPIUrl}/roles/org-admin/${id}`)
    }

}